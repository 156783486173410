import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {
  AccordionButtonProps,
  Accordion as BSAccordion,
  AccordionProps as BSAccordionProps,
} from 'react-bootstrap'
import {WithChildren} from '../../_metronic/helpers'
import {AccordionBodyProps} from 'react-bootstrap/esm/AccordionBody'
import * as RiIcons from 'react-icons/ri'
import ReactIcon from './ReactIcon'

type AccordionProps = {
  border?: boolean
  enabled?: boolean
  error?: boolean
  title?: string
  accordionProps?: BSAccordionProps
  props?: {
    body?: AccordionBodyProps
    button?: AccordionButtonProps
    leadingIcon?: null | keyof typeof RiIcons
  }
  expanded?: boolean
  items?: {
    title?: string
    children?: ReactNode
  }[]
}

const Accordion: FC<AccordionProps & WithChildren> = ({
  title,
  enabled = false,
  error = false,
  accordionProps,
  props = {
    leadingIcon: 'RiArrowRightLine',
  },
  children,
  border = false,
  expanded,
  items,
}) => {
  const [showKey, setShowKey] = useState<string>('')
  const toggleAccordion = (key: string) => {
    setShowKey((p) => (p === key ? '' : key))
  }
  useEffect(() => {
    setShowKey(expanded ? '0' : '')
  }, [expanded])
  return (
    <>
      {enabled ? (
        <BSAccordion {...accordionProps} className='accordion-icon-toggle' activeKey={showKey}>
          {(items?.length || 0) > 0 ? (
            <>
              {items?.map((l, i) => {
                return (
                  <BSAccordion.Item key={i} eventKey={`${i}`}>
                    <BSAccordion.Button
                      onClick={() => toggleAccordion(`${i}`)}
                      className={`rounded border-opacity-10 ${border ? 'border border-dark' : ''} ${
                        error && border ? 'border border-danger' : ''
                      }`}
                      {...props?.button}
                    >
                      <div className={`fs-4 fw-bold ${error ? 'text-danger' : ''}`}>
                        <div className='d-flex gap-2 align-items-center'>
                          {props.leadingIcon && (
                            <span className='accordion-icon'>
                              <ReactIcon icon='RiArrowRightLine' />
                            </span>
                          )}
                          {l.title || 'Judul'}
                        </div>
                        {error && <div className='fs-7 fw-normal'>Beberapa data masih kosong</div>}
                      </div>
                    </BSAccordion.Button>
                    <BSAccordion.Body className='px-0 pb-0 border-bottom' {...props?.body}>
                      {l.children}
                    </BSAccordion.Body>
                  </BSAccordion.Item>
                )
              })}
            </>
          ) : (
            <BSAccordion.Item eventKey={`0`}>
              <BSAccordion.Button
                onClick={() => toggleAccordion(`0`)}
                className={`rounded border-opacity-10 ${border ? 'border border-dark' : ''} ${
                  error && border ? 'border border-danger' : ''
                }`}
                {...props?.button}
              >
                <div className={`fs-4 fw-bold ${error ? 'text-danger' : ''}`}>
                  <div className='d-flex gap-2 align-items-center'>
                    {props.leadingIcon && (
                      <span className='accordion-icon'>
                        <ReactIcon icon='RiArrowRightLine' />
                      </span>
                    )}
                    {title || 'Judul'}
                  </div>
                  {error && <div className='fs-7 fw-normal'>Beberapa data masih kosong</div>}
                </div>
              </BSAccordion.Button>
              <BSAccordion.Body className='px-0 pb-0 border-bottom' {...props?.body}>
                {children}
              </BSAccordion.Body>
            </BSAccordion.Item>
          )}
        </BSAccordion>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default Accordion
