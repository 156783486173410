import {FC, Fragment, useEffect, useState} from 'react'
import {TotalDashboardV2, TotalDashboardV2DataProps} from '../../helper/DashboardComponent'
import ReactLeaflet from '../../helper/ReactLeaflet'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProtectComponent, hasAccessRoutes} from '../../helper/UserRolesValidation'
import {capitalizeEachWordV2, NullProof, numberlistPagination} from '../../helper/AppFunction'
import {useDispatch, useSelector} from 'react-redux'
import {useAuth} from '../../modules/auth'
import {post as postNotif} from '../../redux/notifSlice'
import Modal from '../../helper/Modal'
import indonesiaGeodata from '../../helper/geodata/indonesia-geodata.json'
import {TableAction, TableColumn, TableInput, TableRow} from '../../helper/TableInput'
import {WithChildren} from '../../../_metronic/helpers'
import {ta} from 'date-fns/locale'

const PetaPenugasanTimItjen: FC<{query?: string; onComplete?: (e: any) => void}> = ({
  onComplete,
  query,
}) => {
  const [leafletData, setLeafletData] = useState<any>({
    leaflet1: {},
    leaflet2: {},
    leaflet3: {},
  })
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <ReactLeaflet
      geodata={indonesiaGeodata.data}
      title='Peta Penugasan Tim ITJEN'
      typeMap='pin'
      route={{
        api: '/pengawasan_internal/dashboard-peta-tim-audit',
      }}
      id='leaflet1'
      onComplete={(e) => {
        let data = e.data || []
        let apiData: any[] = e.api
        apiData.forEach((l: any) => {
          const count = l?.count || 0
          const nama = l?.nama
          const targetIndex = data.findIndex(
            (a) => a.location.name.toLowerCase() === nama.toLowerCase()
          )
          if (targetIndex > -1) {
            const targetData = data[targetIndex]
            if (count > 13) {
              targetData.color = '#e74c3c'
            } else if (count > 6) {
              targetData.color = '#f1c40f'
            } else {
              targetData.color = '#2ecc71'
            }
          }
        })
        e.update(data)
        onComplete && onComplete(apiData)
        setLeafletData((p: any) => ({...p, leaflet1: {...p.leaflet1, data: e}}))
      }}
      onOpenDetail={(e: any) => {
        setLeafletData((p: any) => ({...p, leaflet1: {...p.leaflet1, id: e}}))
      }}
    >
      {leafletData.leaflet1?.id && (
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          route={{
            url: `/pengawasan_internal/dashboard-peta-tim-audit/${leafletData.leaflet1?.id}`,
            query: query,
          }}
          headerData={[
            {
              title: 'No',
              props: {
                className: 'mw-100px',
              },
            },
            {
              title: 'Nomor Surat',
            },
            {
              title: 'Tujuan',
            },
            {
              title: 'Tanggal',
            },
          ]}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nomor Surat' props={{style: {minWidth: '300px'}}}>
                    {NullProof({
                      input: l,
                      params: 'nomor_surat',
                    })}
                  </TableColumn>
                  <TableColumn label='Nomor Surat' props={{style: {minWidth: '300px'}}}>
                    {NullProof({
                      input: l,
                      params: 'tujuan',
                      isMap: true,
                    })?.map((m: any, i: number) => (
                      <Fragment key={i}>
                        <div>
                          {NullProof({
                            input: m,
                            params: 'satker.nama_satker',
                          })}
                        </div>
                      </Fragment>
                    ))}
                  </TableColumn>
                  <TableColumn label='Nomor Surat' props={{style: {minWidth: '300px'}}}>
                    {`${NullProof({
                      input: l,
                      params: 'tanggal_mulai',
                      type: 'date',
                    })} - ${NullProof({
                      input: l,
                      params: 'tanggal_selesai',
                      type: 'date',
                    })}`}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      )}
    </ReactLeaflet>
  )
}

const MainWrapper: FC = () => {
  const dispatch = useDispatch()
  const routeData = useSelector((state: any) => state.route.value)
  const notifData = useSelector((state: any) => state.notif.data)
  const [modalUltah, setModalUltah] = useState<boolean>(false)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const [data, setData] = useState<any>({
    pengawasan_internal: {},
  })
  const [dashboardKey, setDashboardKey] = useState<number>(0)
  const [filterInput, setFilterInput] = useState<any>({
    year: new Date().getFullYear(),
  })
  const queryBuilder = (defaultQuery?: string) => {
    let jsonQuery: any = {...filterInput}
    const parseQuery: string[] = defaultQuery?.split('&') || []
    parseQuery.forEach((l: string) => {
      const splitQuery = l.split('=')
      jsonQuery = {...jsonQuery, [splitQuery[0]]: splitQuery[1]}
    })
    const jsonQueryString = Object.entries(jsonQuery)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return {input: jsonQuery, result: jsonQueryString}
  }
  const [totalRealisasiAnggaran, setTotalRealisasiAnggaran] = useState<number>(0)
  const [totalPersenRealisasiAnggaran, setTotalPersenRealisasiAnggaran] = useState<any>()
  const [totalPKPT, setTotalPKPT] = useState<number>(0)
  const [totalPersenPKPT, setTotalPersenPKPT] = useState<any>()
  const [leafletData, setLeafletData] = useState<any>({
    leaflet1: {},
    leaflet2: {},
    leaflet3: {},
  })
  const checkProtect = (search: string, value: string) => {
    return hasAccessRoutes(routeData?.route, search, roleId === 1, true) ? value : ''
  }

  const sortPetaTimAudit = () => {
    let result: TotalDashboardV2DataProps[] = []
    let dataPeta: any[] =
      NullProof({input: data, params: 'dashboard_wilayah_st', isLabel: false}) || []
    dataPeta.sort((a: any, b: any) => b.count - a.count)
    dataPeta.forEach((l: any) => {
      result.push({
        name: `${result.length + 1}. ${capitalizeEachWordV2({
          sentence: l?.provinsi,
          filter: 'DKI,D.I,UIN',
        })}`,
        icon: 'RiArrowRightLine',
        props: {className: 'col-12'},
        value: l?.count,
      })
    })
    return result.slice(0, 10)
  }

  const hitungTotalRealisasiAnggaran = () => {
    const total =
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_1', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_2', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_3', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_4', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_investigasi', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_sekretariat', isLabel: false})
    return total
  }

  const hitungTotalPersenRealisasiAnggaran = () => {
    const ra_wil_1 = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_1', isLabel: false})
    )
    const ra_wil_2 = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_2', isLabel: false})
    )
    const ra_wil_3 = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_3', isLabel: false})
    )
    const ra_wil_4 = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_4', isLabel: false})
    )
    const ra_wil_investigasi = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_investigasi', isLabel: false})
    )
    const ra_wil_sekretariat = persenRealisasiAnggaran(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_sekretariat', isLabel: false})
    )
    const total = {
      ...totalPersenRealisasiAnggaran,
      wilayah_1: ra_wil_1,
      wilayah_2: ra_wil_2,
      wilayah_3: ra_wil_3,
      wilayah_4: ra_wil_4,
      wilayah_investigasi: ra_wil_investigasi,
      wilayah_sekretariat: ra_wil_sekretariat,
    }
    return total
  }

  const hitungTotalPKPT = () => {
    const total =
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_1', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_2', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_3', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_4', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_investigasi', isLabel: false}) +
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_sekretariat', isLabel: false})
    return total
  }

  const hitungTotalPersenPKPT = () => {
    const ra_wil_1 = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_1', isLabel: false})
    )
    const ra_wil_2 = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_2', isLabel: false})
    )
    const ra_wil_3 = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_3', isLabel: false})
    )
    const ra_wil_4 = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_4', isLabel: false})
    )
    const ra_wil_investigasi = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_investigasi', isLabel: false})
    )
    const ra_wil_sekretariat = persenPKPT(
      NullProof({input: data, params: 'realisasi_anggaran_wilayah_sekretariat', isLabel: false})
    )
    const total = {
      ...totalPersenPKPT,
      wilayah_1: ra_wil_1,
      wilayah_2: ra_wil_2,
      wilayah_3: ra_wil_3,
      wilayah_4: ra_wil_4,
      wilayah_investigasi: ra_wil_investigasi,
      wilayah_sekretariat: ra_wil_sekretariat,
    }
    return total
  }

  const getDataListTipePemohon = () => {
    const result: any[] = NullProof({input: data, params: 'pemohon.data', isMap: true})
    let json: TotalDashboardV2DataProps[] = []
    result.forEach((l: any) => {
      json.push({
        name: l?.jenis,
        props: {className: 'col-12 col-xl-6'},
        value: l?.count,
      })
    })
    return json
  }

  const persenRealisasiAnggaran = (n: number = 0) => {
    let persen: number = 0
    if (n) {
      persen = parseInt(((n / totalRealisasiAnggaran) * 100).toFixed())
    }
    return persen
  }

  const persenPKPT = (n: number = 0) => {
    let persen: number = 0
    if (n) {
      persen = parseInt(((n / totalPKPT) * 100).toFixed())
    }
    return persen
  }

  useEffect(() => {
    if (notifData?.ultah) {
      setModalUltah(true)
      dispatch(postNotif({...notifData, ultah: false}))
    }
  }, [notifData?.ultah])

  useEffect(() => {
    setTotalRealisasiAnggaran(hitungTotalRealisasiAnggaran())
    setTotalPersenRealisasiAnggaran(hitungTotalPersenRealisasiAnggaran())
    setTotalPKPT(hitungTotalPKPT())
    setTotalPersenPKPT(hitungTotalPersenPKPT())
  }, [data])

  const HeaderSticky: FC<WithChildren> = ({children}) => {
    return (
      <div
        className='fs-2 fw-bold mx-n4 my-4 sticky-top shadow-none bg-light px-4 py-2'
        style={{zIndex: 20}}
      >
        <div className='px-4'>{children}</div>
      </div>
    )
  }

  return (
    <div>
      <Modal
        id='modal_ultah'
        title='Notifikasi'
        isShow={modalUltah}
        onClose={() => setModalUltah(false)}
        className='mw-500px'
      >
        <div className='d-flex flex-column gap-4 justify-content-center align-items-center'>
          <img src='./happy_birthday.gif' className='rounded-circle' style={{maxWidth: '200px'}} />
          <div
            className='d-flex flex-column align-items-center justify-content-center'
            style={{lineHeight: 1.2}}
          >
            <div className='fs-4'>Selamat Ulang Tahun</div>
            <div className='fw-bold fs-1 text-capitalize'>
              {NullProof({input: auth, params: 'data.pegawai.nama'})?.toLowerCase()}
            </div>
          </div>
          <button className='btn btn-primary' onClick={() => setModalUltah(false)}>
            Terima kasih!
          </button>
        </div>
      </Modal>
      {/* <PageTitle breadcrumbs={[]}>Dashboard</PageTitle> */}
      <div className='row g-2 flex-end'>
        <div className='col-12 col-md-4'>
          <select
            className='form-select'
            defaultValue={filterInput?.year}
            onChange={(e) => {
              setFilterInput((p: any) => ({...p, year: e.target.value}))
              setDashboardKey((p) => (p += 1))
            }}
          >
            {[...Array(new Date().getFullYear() - 2024 + 1).keys()].map((i) => (
              <option key={2024 + i} value={2024 + i}>
                Tahun: {2024 + i}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row g-4' key={dashboardKey}>
        {/* Uncategory */}
        <ProtectComponent
          id={[
            'pengawasan_internal-form_st-wilayah_1',
            'pengawasan_internal-form_st-wilayah_2',
            'pengawasan_internal-form_st-wilayah_3',
            'pengawasan_internal-form_st-wilayah_4',
            'pengawasan_internal-form_st-wilayah_inves',
            'pengawasan_internal-form_st-wilayah_sekretariat',
            'pengawasan_internal-form_usulan-wilayah_1',
            'pengawasan_internal-form_usulan-wilayah_2',
            'pengawasan_internal-form_usulan-wilayah_3',
            'pengawasan_internal-form_usulan-wilayah_4',
            'pengawasan_internal-form_usulan-wilayah_inves',
            'pengawasan_internal-form_usulan-wilayah_sekretariat',
            'pengawasan_internal-jumlah_usulan',
            'pengawasan_internal-jumlah_st_terbit',
            'pengawasan_internal-jumlah_laporan_terbit',
            'pengawasan_internal-peta_penugasan_itjen',
            'pengawasan_internal-peta_profil_saldo_temuan',
            'pengawasan_internal-peta_posisi_realtime_pejabat',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengawasan Internal</HeaderSticky>
            <div className='row row-cols-3 g-2 mt-2'>
              <ProtectComponent id='pengawasan_internal-jumlah_usulan'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan',
                    // Contoh Query get=all
                    query: queryBuilder(
                      checkProtect('pengawasan/persiapan/form_usulan/$query=get=all', 'get=all')
                    ).result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e: any) => {
                    setData((p: any) => ({
                      ...p,
                      pengawasan_internal: {
                        ...p.pengawasan_internal,
                        total_usulan: NullProof({input: e, params: 'total', isLabel: false}) || 0,
                        usulan_persetujuan:
                          NullProof({input: e, params: 'persetujuan', isLabel: false}) || 0,
                        usulan_paraf: NullProof({input: e, params: 'paraf', isLabel: false}) || 0,
                        usulan_tanda_tangan:
                          NullProof({input: e, params: 'tanda_tangan', isLabel: false}) || 0,
                        usulan_ditolak:
                          NullProof({input: e, params: 'ditolak', isLabel: false}) || 0,
                        usulan_selesai:
                          NullProof({input: e, params: 'selesai', isLabel: false}) || 0,
                        usulan_revisi: NullProof({input: e, params: 'revisi', isLabel: false}) || 0,
                      },
                    }))
                  }}
                  data={[
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Usulan',
                      icon: 'RiFile2Line',
                      value: 'total',
                      link: '/pengawasan/persiapan/form_usulan?filter=jenis-usulan',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Persetujuan',
                      icon: 'RiTodoLine',
                      value: 'persetujuan',
                      link: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Paraf',
                      icon: 'RiListCheck3',
                      value: 'paraf',
                      link: '/pengawasan/persiapan/daftar_status_usulan/paraf',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Penandatanganan',
                      icon: 'RiCheckDoubleLine',
                      value: 'tanda_tangan',
                      link: '/pengawasan/persiapan/daftar_status_usulan/tanda_tangan',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      value: 'ditolak',
                      link: '/pengawasan/persiapan/daftar_status_usulan/ditolak',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      value: 'selesai',
                      link: '/pengawasan/persiapan/daftar_status_usulan/selesai',
                    },
                    {
                      props: {
                        className: 'col-12 col-xl-6',
                      },
                      name: 'Revisi',
                      icon: 'RiFileCloseLine',
                      value: 'revisi',
                      link: '/pengawasan/persiapan/daftar_status_usulan/revisi',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-jumlah_st_terbit'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e: any) => {
                    setData((p: any) => ({
                      ...p,
                      pengawasan_internal: {
                        ...p.pengawasan_internal,
                        total_surat_tugas:
                          NullProof({input: e, params: 'total', isLabel: false}) || 0,
                        surat_tugas_pending:
                          NullProof({input: e, params: 'pending', isLabel: false}) || 0,
                        surat_tugas_berjalan:
                          NullProof({input: e, params: 'berjalan', isLabel: false}) || 0,
                        surat_tugas_selesai:
                          NullProof({input: e, params: 'selesai', isLabel: false}) || 0,
                      },
                    }))
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-jumlah_laporan_terbit'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-pelaporan-st',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e: any) => {
                    setData((p: any) => ({
                      ...p,
                      pengawasan_internal: {
                        ...p.pengawasan_internal,
                        laporan_surat_tugas:
                          NullProof({input: e, params: 'surat_tugas', isLabel: false}) || 0,
                        laporan_belum: NullProof({input: e, params: 'belum', isLabel: false}) || 0,
                        laporan_sudah: NullProof({input: e, params: 'sudah', isLabel: false}) || 0,
                      },
                    }))
                  }}
                  data={[
                    {
                      name: 'Laporan yang terbit',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'surat_tugas',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-peta_penugasan_itjen'>
                <div className='col-12'>
                  <PetaPenugasanTimItjen
                    query={queryBuilder().result}
                    onComplete={(e: any) => {
                      setData((p: any) => ({...p, dashboard_peta_tim_audit: e}))
                    }}
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_profil_saldo_temuan'>
                <div className='col-12'>
                  <ReactLeaflet
                    geodata={indonesiaGeodata.data}
                    title='Profil Saldo Temuan'
                    typeMap='area'
                    id='leaflet2'
                  />
                </div>
              </ProtectComponent>
              {/* <ProtectComponent id='dashboard_irjen-peta_posisi_realtime_pejabat'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Realtime Pejabat'
                    typeMap='pin'
                    dataProps={{
                      api: '',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet3'
                  />
                </div>
              </ProtectComponent> */}
            </div>
            <div className='row g-2 mt-2'>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_1'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-1',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah I',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_2'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-2',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'secondary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah II',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_3'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-3',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah III',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_4'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-4',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah IV',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_inves'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-inves',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Investigasi',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_st-wilayah_sekretariat'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-surat-tugas/wilayah-sekretariat',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Pelaksanaan Surat Tugas',
                      icon: 'RiFile2Line',
                      desc: 'Sekretariat',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Menunggu',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Berjalan',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
            <div className='row g-2 mt-2'>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_1'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-1',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah I',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_2'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-2',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'secondary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah II',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_3'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-3',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah III',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_4'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-4',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Wilayah IV',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_inves'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-inves',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Investigasi',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-form_usulan-wilayah_sekretariat'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-jumlah-usulan/wilayah-sekretariat',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4 col-xl-3',
                  }}
                  data={[
                    {
                      name: 'Form Usulan',
                      icon: 'RiFile2Line',
                      desc: 'Sekretariat',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Persetujuan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'persetujuan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'ditolak',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                    {
                      name: 'Paraf',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'paraf',
                      // link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Revisi',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'revisi',
                      // link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'tanda_tangan',
                      // link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        <ProtectComponent
          id={[
            'pengawasan_internal-anggaran_perjadin-wilayah_1',
            'pengawasan_internal-anggaran_perjadin-wilayah_2',
            'pengawasan_internal-anggaran_perjadin-wilayah_3',
            'pengawasan_internal-anggaran_perjadin-wilayah_4',
            'pengawasan_internal-anggaran_perjadin-wilayah_inves',
            'pengawasan_internal-anggaran_perjadin-wilayah_sekretariat',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Keuangan</HeaderSticky>
            <div className='row g-2'>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_1'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-1',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_1: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah I',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_1',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_2'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-2',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'secondary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_2: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah II',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_2',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_3'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-3',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_3: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah III',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_3',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_4'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-4',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_4: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah IV',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_4',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_inves'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-inves',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_investigasi: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Investigasi',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_investigasi',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengawasan_internal-anggaran_perjadin-wilayah_sekretariat'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-anggaran-perjadin/wilayah-sekretariat',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      realisasi_anggaran_wilayah_sekretariat: NullProof({
                        input: e.data,
                        params: 'realisasi',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      desc: 'Sekretariat',
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenRealisasiAnggaran,
                        params: 'wilayah_sekretariat',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'Realisasi Anggaran',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'realisasi',
                    },
                    {
                      name: 'Total Uang Muka',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'uangmuka',
                      link: '/pengawasan/persiapan/form_st?filter=status-menunggu',
                    },
                    {
                      name: 'Transfer Verifikasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'verifikasi',
                      link: '/pengawasan/persiapan/form_st?filter=status-berjalan',
                    },
                    {
                      name: 'Pengembalian',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'pengembalian',
                      link: '/pengawasan/persiapan/form_st?filter=status-selesai',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        <ProtectComponent id={['pengawasan_internal-wilayah_st', 'tata_usaha-count_naskah'].join()}>
          <div className='col-12'>
            <HeaderSticky>Tata Usaha</HeaderSticky>
            <div className='row g-2'>
              <ProtectComponent id='pengawasan_internal-wilayah_st'>
                <TotalDashboardV2
                  api={{
                    url: '/pengawasan_internal/dashboard-wilayah-st',
                    query: queryBuilder().result,
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({...p, dashboard_wilayah_st: e.data}))
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Penugasan by Provinsi',
                      icon: 'RiDashboardLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    ...sortPetaTimAudit(),
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='tata_usaha-count_naskah'>
                <TotalDashboardV2
                  api={{
                    url: '/tata_usaha/dashboard-count-naskah',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      desc: 'below',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6 col-xl-4',
                  }}
                  onComplete={(e) => {
                    const data = e.data
                    const total = data?.dumas + data?.biasa + data?.surat_tugas
                    e.update({total: total})
                  }}
                  data={[
                    {
                      name: 'Jumlah Surat Masuk',
                      icon: 'RiFileLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                    },
                    {
                      name: 'Surat DUMAS',
                      icon: 'RiFileLine',
                      props: {className: 'col-12'},
                      value: 'biasa',
                    },
                    {
                      name: 'Surat Biasa',
                      icon: 'RiFileLine',
                      props: {className: 'col-12'},
                      value: 'dumas',
                    },
                    {
                      name: 'Surat Tugas',
                      icon: 'RiFileLine',
                      props: {className: 'col-12'},
                      value: 'surat_tugas',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Perencanaan Anggaran */}
        <ProtectComponent id={['perencanaan_anggaran-profil_anggaran'].join()}>
          <div className='col-12'>
            <HeaderSticky>Perencanaan Anggaran</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='perencanaan_anggaran-profil_anggaran'>
                <TotalDashboardV2
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  data={[
                    {
                      name: 'Informasi Keuangan',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 50000,
                      link: '/pengawasan/persiapan/form_usulan?filter=type-saldo',
                    },
                    {
                      name: 'Saldo',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 50000,
                      link: '/pengawasan/persiapan/form_usulan?filter=type-saldo',
                    },
                    {
                      name: 'Realisasi',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 50000,
                      link: '/pengawasan/persiapan/form_usulan?filter=type-realisasi',
                    },
                  ]}
                />
                {/* <div className='col-12'>
                  <ChartCircle
                    props={{
                      className: 'col-6',
                    }}
                    id='profil_anggaran_itjen'
                    title='Profil Anggaran ITJEN'
                    color='info'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
              </ProtectComponent>
            </div>
            <div className='row g-2 mt-4'>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_1'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-1',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_1: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah I',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_1',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_2'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-2',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'secondary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_2: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah II',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_2',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_3'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-3',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_3: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah III',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_3',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_4'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-4',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_4: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Wilayah IV',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_4',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_inves'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-inves',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_investigasi: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      desc: 'Investigasi',
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_investigasi',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='perencanaan_anggaran-dashboard-pkpt-wilayah_sekretariat'>
                <TotalDashboardV2
                  api={{
                    url: '/perencanaan_anggaran/dashboard-pkpt/wilayah-sekretariat',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                      desc: 'left',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  onComplete={(e) => {
                    setData((p: any) => ({
                      ...p,
                      pkpt_wilayah_sekretariat: NullProof({
                        input: e.data,
                        params: 'total_nilai_anggaran',
                        isLabel: false,
                      }),
                    }))
                  }}
                  data={[
                    {
                      descProps: {
                        className: 'fw-bold fs-2',
                      },
                      desc: 'Sekretariat',
                      type: 'percent',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: NullProof({
                        input: totalPersenPKPT,
                        params: 'wilayah_sekretariat',
                        isLabel: false,
                      }),
                    },
                    {
                      name: 'TOTAL ALOKASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_nilai_anggaran',
                    },
                    {
                      name: 'REALISASI ANGGARAN',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_realisasi',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (sebelum realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran_sebelum',
                    },
                    {
                      name: 'SISA ALOKASI ANGGARAN (setelah realisasi)',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12'},
                      value: 'total_sisa_anggaran',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Penyusunan Peraturan */}
        <ProtectComponent id={['penyusunan_peraturan-daftar_legal_drafting_itjen'].join()}>
          <div className='col-12'>
            <HeaderSticky>Penyusunan Peraturan</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='penyusunan_peraturan-daftar_legal_drafting_itjen'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  button={{
                    link: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
                  }}
                  data={[
                    {
                      name: 'Legal Drafting yang di Inisiasi ITJEN',
                      icon: 'RiFileLine',
                      props: {className: 'col-12'},
                      value: 100,
                    },
                    {
                      name: 'Legal 1',
                      desc: '12 Mei 2024',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Legal 2',
                      desc: '12 Mei 2024',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Legal 3',
                      desc: '12 Mei 2024',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Legal 4',
                      desc: '12 Mei 2024',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Legal 5',
                      desc: '12 Mei 2024',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  data={[
                    {
                      name: 'Status Legal Drafting yang di inisiasi ITJEN',
                      icon: 'RiFileLine',
                      props: {className: 'col-12 col-md-4'},
                      value: '#',
                    },
                    {
                      name: 'Mulai',
                      icon: 'RiTimerLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'berjalan',
                    },
                    {
                      name: 'Progress',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pending',
                    },
                    {
                      name: 'Selesai',
                      icon: 'RiCheckboxCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'selesai',
                    },
                  ]}
                />
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Legal Drafting yang di inisiasi ITJEN'
                  icon='RiFileTextLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={100}
                  data={[
                    {title: 'Mulai', total: 30},
                    {title: 'Progress', total: 20},
                    {title: 'Selesai', total: 50},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Legal 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Legal 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Legal 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Legal 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Legal 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Keuangan */}
        <ProtectComponent
          id={[
            'pengelolaan_keuangan-profil_realisasi_anggaran',
            'pengelolaan_keuangan-informasi_keuangan',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Keuangan</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_keuangan-profil_realisasi_anggaran'>
                {/* <div className='col-12'>
                  <ChartCircle
                    id='profil_realisasi_anggaran'
                    title='Profil Realisasi Anggaran'
                    color='warning'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-xl-6',
                  }}
                  data={[
                    {
                      name: 'Informasi Keuangan',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-md-6'},
                      value: 50000,
                    },
                    {
                      name: 'Masuk',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-md-6'},
                      value: 50000,
                    },
                    {
                      name: 'Keluar',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-md-6'},
                      value: 50000,
                    },
                    {
                      name: 'Total',
                      type: 'currency',
                      icon: 'RiCoinLine',
                      props: {className: 'col-12 col-md-6'},
                      value: 50000,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_keuangan-profil_realisasi_anggaran'>
                {/* <div className='col-12'>
                  <ChartCircle
                    id='profil_realisasi_anggaran'
                    title='Profil Realisasi Anggaran'
                    color='warning'
                    isCurrency
                    data={[
                      {color: '#2ecc71', title: 'Realisasi', value: 1000000},
                      {color: '#e74c3c', title: 'Saldo', value: 2000000},
                      {color: '#EFEFEF', title: 'Pagu', value: 3000000},
                    ]}
                    filter={{
                      data: [
                        {label: '2023', value: '2023'},
                        {label: '2022', value: '2022'},
                        {label: '2021', value: '2021'},
                      ],
                      value: '2023',
                      placeholder: 'Pilih Tahun',
                    }}
                  />
                </div> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_keuangan-informasi_keuangan'>
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'success'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    // slug: {
                    //   data: '/surat/:id',
                    //   id: 'id',
                    // },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>

              <ProtectComponent id='pengelolaan_keuangan-informasi_keuangan'>
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'primary'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'warning'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                    {title: 'Total', total: 50000},
                  ]}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    // slug: {
                    //   data: '/surat/:id',
                    //   id: 'id',
                    // },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
                {/* <TotalDashboard
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  title='Informasi Keuangan'
                  icon='RiMoneyDollarCircleLine'
                  themeConfig={{color: {value: 'danger'}}}
                  total={50000}
                  type='currency'
                  data={[
                    {title: 'Masuk', total: 50000},
                    {title: 'Keluar', total: 50000},
                  ]}
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Uang Masuk 1',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 2,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 3,
                        title: 'Uang Masuk 2',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 4,
                        title: 'Uang Masuk 4',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                      {
                        id: 5,
                        title: 'Uang Masuk 5',
                        desc: '15 Mei 2023',
                        value: 10000,
                      },
                    ],
                    slug: {
                      data: '/surat/:id',
                      id: 'id',
                    },
                    config: [{id: 'nama'}, {id: 'tanggal', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Dashboard IRJEN */}
        {/* <ProtectComponent
          id={[
            'dashboard_irjen-aduan_masyarakat',
            'dashboard_irjen-ajuan_cuti',
            'dashboard_irjen-notifikasi',
            'dashboard_irjen-peta_posisi_tim_audit',
            'dashboard_irjen-peta_profil_saldo_temuan',
            'dashboard_irjen-peta_posisi_realtime_pejabat',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Dashboard IRJEN</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='dashboard_irjen-aduan_masyarakat'>
                <MixedCardDashboard
                  title='Aduan Masyarakat'
                  className='col-12 col-md-4'
                  color='warning'
                  total={100}
                  icon='RiSpeakLine'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-ajuan_cuti'>
                <MixedCardDashboard
                  title='Ajuan Cuti'
                  className='col-12 col-md-4'
                  color='success'
                  total={100}
                  icon='RiSuitcaseLine'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-notifikasi'>
                <MixedCardDashboard
                  title='Notifikasi Approval LHA'
                  className='col-12 col-md-4'
                  total={10}
                  icon='RiNotification3Line'
                />
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_posisi_tim_audit'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Tim Audit'
                    typeMap='pin'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet1'
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_profil_saldo_temuan'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Profil Saldo Temuan'
                    typeMap='area'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet2'
                  />
                </div>
              </ProtectComponent>
              <ProtectComponent id='dashboard_irjen-peta_posisi_realtime_pejabat'>
                <div className='col-12'>
                  <ReactLeaflet
                    title='Posisi Realtime Pejabat'
                    typeMap='pin'
                    dataProps={{
                      api: '/pengawasan_internal/surat-tugas',
                      id: 'id',
                      header: 'Nama,Kota',
                      params: 'nama,kota',
                      result: '$nama,$kota',
                    }}
                    id='leaflet3'
                  />
                </div>
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent> */}
        {/* Pengelolaan Hasil Pengawasan */}
        <ProtectComponent
          id={[
            'pengelolaan_hasil_pengawasan-hasil_pengawasan_itjen',
            'pengelolaan_hasil_pengawasan-hasil_pemeriksaan_bpk',
            'pengelolaan_hasil_pengawasan-hasil_pengawasan_bpkp',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Hasil Pengawasan</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pengawasan_itjen'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Hasil Pengawasan ITJEN',
                      icon: 'RiCheckLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pemeriksaan_bpk'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Hasil Pemeriksaan BPK',
                      icon: 'RiFileSearchLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_hasil_pengawasan-hasil_pengawasan_bpkp'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Hasil Pengawasan BPKP',
                      icon: 'RiCheckLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan DUMAS */}
        <ProtectComponent
          id={['pengelolaan_dumas-profil_dumas', 'pengelolaan_dumas-dumas_proses'].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan DUMAS</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_dumas-profil_dumas'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Profil DUMAS',
                      icon: 'RiUserSharedLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_dumas-dumas_proses'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'DUMAS dalam proses telaah',
                      icon: 'RiFileListLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Gratifikasi */}
        <ProtectComponent id={['pengelolaan_gratifikasi-profil_ppg'].join()}>
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Gratifikasi</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_gratifikasi-profil_ppg'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Profil PPG',
                      icon: 'RiToolsLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Pengelolaan Barang */}
        <ProtectComponent
          id={['pengelolaan_barang-profil_bmn', 'pengelolaan_barang-pengguna_bmn'].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Barang</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='pengelolaan_barang-profil_bmn'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Profil BMN',
                      icon: 'RiBarcodeLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_barang-pengguna_bmn'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Pengguna BMN',
                      icon: 'RiUserReceivedLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Kepegawaian */}
        <ProtectComponent
          id={[
            'kepegawaian-profil_kepegawaian',
            'kepegawaian-pengajuan_cuti',
            'kepegawaian-pengajuan_izin',
            'kepegawaian-kediklatan',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Kepegawaian</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='kepegawaian-cuti_pegawai_pemohon'>
                <TotalDashboardV2
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Jenis Cuti',
                      icon: 'RiFolder2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    ...getDataListTipePemohon(),
                  ]}
                />
                <TotalDashboardV2
                  api={{
                    url: '/kepegawaian/dasboard/pemohon',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e) => {
                    let result = {
                      ...data.pemohon,
                      ...e.data,
                    }
                    setData((p: any) => ({
                      ...p,
                      pemohon: {
                        ...p.pemohon,
                        ...result,
                      },
                    }))
                    e.update({
                      pemohon: result,
                    })
                  }}
                  data={[
                    {
                      name: 'Sisa Cuti',
                      icon: 'RiDoorOpenLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'N-2',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pemohon.sisa.n2',
                    },
                    {
                      name: 'N-1',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pemohon.sisa.n1',
                    },
                    {
                      name: 'N',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'pemohon.sisa.n',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-cuti_pegawai_atasan'>
                <TotalDashboardV2
                  api={{
                    url: '/kepegawaian/dasboard/atasan',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e) => {
                    let result = {
                      ...data.cuti_atasan,
                      ...e.data,
                    }
                    setData((p: any) => ({
                      ...p,
                      cuti_atasan: {
                        ...p.cuti_atasan,
                        ...result,
                      },
                    }))
                    e.update({
                      ...result,
                    })
                  }}
                  data={[
                    {
                      name: 'Persetujuan Cuti',
                      icon: 'RiGroupLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Total',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/semua',
                    },
                    {
                      name: 'Belum',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/persetujuan',
                    },
                    {
                      name: 'Sudah',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/selesai',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-pengajuan_cuti'>
                <TotalDashboardV2
                  api={{
                    url: '/kepegawaian/dasboard/pengajuan-cuti',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  onComplete={(e) => {
                    let result = {
                      ...data.cuti_atasan,
                      ...e.data,
                    }
                    setData((p: any) => ({
                      ...p,
                      cuti_atasan: {
                        ...p.cuti_atasan,
                        ...result,
                      },
                    }))
                    e.update({
                      ...result,
                    })
                  }}
                  data={[
                    {
                      name: 'Proses Pengajuan Cuti',
                      icon: 'RiGroupLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Total',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'total',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/semua',
                    },
                    {
                      name: 'Belum',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/semua',
                    },
                    {
                      name: 'Selesai',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                      link: '/kepegawaian/cuti-pegawai/permohonan-cuti/semua',
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-profil_kepegawaian'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Profil Kepegawaian',
                      icon: 'RiUserSettingsLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-pengajuan_cuti'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Proses Pengajuan Cuti',
                      icon: 'RiCalendarEventLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-pengajuan_izin'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Proses Pengajuan Izin / Tugas Belajar',
                      icon: 'RiBookLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
              <ProtectComponent id='kepegawaian-kediklatan'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Informasi Kediklatan',
                      icon: 'RiBookOpenLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Tata Usaha */}
        <ProtectComponent id={['tatausaha-surat_memo'].join()}>
          <div className='col-12'>
            <HeaderSticky>Tata Usaha</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='tatausaha-surat_memo'>
                {/* <MixedCardDashboard
                  title='Total Surat / Memo'
                  className='col-12 col-md-4'
                  total={100}
                  icon='RiMailLine'
                  data={[
                    {title: 'Masuk', total: 50, className: 'col-6'},
                    {title: 'Keluar', total: 50, className: 'col-6'},
                  ]}
                /> */}
                <TotalDashboardV2
                  api={{
                    url: '/tata_usaha/dashboard-count-naskah',
                    query: queryBuilder().result,
                  }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Tandatangan Naskah',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum diberi Tanda Tangan',
                      icon: 'RiEdit2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'surat_tugas',
                    },
                    {
                      name: 'Belum Dikirim',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Keluar',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum Dikirim',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Verifikasi Naskah',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum Diverifikasi',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Ditolak',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Masuk',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum Dibaca',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Ditindaklanjuti',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'info'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Disposisi',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum Dibaca',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Ditindaklanjuti',
                      icon: 'RiCloseCircleLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'sudah',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Tembusan',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-xl-6'},
                      value: '#',
                    },
                    {
                      name: 'Belum Dibaca',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-xl-6'},
                      value: 'belum',
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Naskah Dinas */}
        <ProtectComponent id={['naskahdinas-surat_memo'].join()}>
          <div className='col-12'>
            <HeaderSticky>Naskah Dinas</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='tatausaha-surat_memo'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Keluar',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-md-4'},
                      value: '#',
                    },
                    {
                      name: 'Belum Diverifikasi',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Ditandatangan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Dikirim',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Keluar Yang Belum Diberikan',
                      icon: 'RiSendPlaneFill',
                      props: {className: 'col-12 col-md-4'},
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Masuk Yang Belum Diberikan',
                      icon: 'RiSendPlaneFill',
                      props: {className: 'col-12 col-md-4'},
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Naskah Log */}
        <ProtectComponent id={['naskahdinas-surat_memo'].join()}>
          <div className='col-12'>
            <HeaderSticky>Log Naskah</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='tatausaha-surat_memo'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'warning'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Keluar',
                      icon: 'RiFile2Line',
                      props: {className: 'col-12 col-md-4'},
                      value: '#',
                    },
                    {
                      name: 'Belum Diverifikasi',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Ditandatangan',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                    {
                      name: 'Belum Dikirim',
                      icon: 'RiHourglassLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 'belum',
                    },
                  ]}
                />
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'success'}},
                    layout: {
                      banner: 'default',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6 col-xl-4',
                  }}
                  data={[
                    {
                      name: 'Naskah Masuk Yang Belum Diberikan',
                      icon: 'RiSendPlaneFill',
                      props: {className: 'col-12 col-md-4'},
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>

        {/* Pengelolaan Kehumasan */}
        <ProtectComponent
          id={[
            'pengelolaan_kehumasan-link_website_itjen',
            'pengelolaan_kehumasan-informasi_kegiatan_itjen',
          ].join()}
        >
          <div className='col-12'>
            <HeaderSticky>Pengelolaan Kehumasan</HeaderSticky>
            <div className='row row-eq-height g-4'>
              <ProtectComponent id='pengelolaan_kehumasan-link_website_itjen'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  button={{
                    link: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
                  }}
                  data={[
                    {
                      name: 'Link Website ITJEN',
                      icon: 'RiExternalLinkLine',
                      props: {className: 'col-12'},
                      value: 100,
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                  ]}
                />
                {/* <TotalDashboard
                  title='Link Website ITJEN'
                  total={20}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  themeConfig={{
                    color: {value: 'danger'},
                  }}
                  icon='RiExternalLinkLine'
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 2,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 3,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 4,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 5,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                    ],
                    slug: {
                      data: '#',
                      id: 'link',
                      external: true,
                      label: 'Lihat URL',
                    },
                    config: [{id: 'nama'}, {id: 'link', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>
              <ProtectComponent id='pengelolaan_kehumasan-informasi_kegiatan_itjen'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'danger'}},
                    layout: {
                      banner: 'default-vertical',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  button={{
                    link: '/pengawasan/persiapan/daftar_status_usulan/persetujuan',
                  }}
                  data={[
                    {
                      name: 'Informasi Kegiatan ITJEN',
                      icon: 'RiExternalLinkLine',
                      props: {className: 'col-12'},
                      value: 100,
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                    {
                      name: 'Kemenag',
                      desc: 'https://www.kemenag.go.id/',
                      type: 'button',
                      props: {className: 'col-12'},
                      value: 'Lihat',
                    },
                  ]}
                />
                {/* <TotalDashboard
                  title='Informasi Kegiatan ITJEN'
                  total={20}
                  props={{
                    className: 'col-12 col-md-6',
                  }}
                  themeConfig={{
                    color: {value: 'primary'},
                  }}
                  icon='RiNewspaperLine'
                  list={{
                    show: true,
                    data: [
                      {
                        id: 1,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 2,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 3,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 4,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                      {
                        id: 5,
                        title: 'Kemenag',
                        desc: 'https://www.kemenag.go.id/',
                      },
                    ],
                    slug: {
                      data: '#',
                      id: 'link',
                      external: true,
                    },
                    config: [{id: 'nama'}, {id: 'link', className: 'fs-7'}],
                  }}
                /> */}
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
        {/* Evaluasi Pelaporan */}
        <ProtectComponent id={['evaluasi_pelaporan-jumlah_laporan'].join()}>
          <div className='col-12'>
            <HeaderSticky>Evaluasi Pelaporan</HeaderSticky>
            <div className='row g-4'>
              <ProtectComponent id='evaluasi_pelaporan-jumlah_laporan'>
                <TotalDashboardV2
                  // api={{
                  //   url: '/pengawasan_internal/dashboard-pelaporan-st',
                  //   query: queryBuilder().result,
                  // }}
                  options={{
                    themeConfig: {color: {value: 'primary'}},
                    layout: {
                      banner: 'default-vertical',
                      subbanner: 'column',
                    },
                  }}
                  props={{
                    className: 'col-12 col-md-4',
                  }}
                  data={[
                    {
                      name: 'Jumlah Laporan',
                      icon: 'RiFilePaperLine',
                      props: {className: 'col-12 col-md-4'},
                      value: 100,
                    },
                  ]}
                />
              </ProtectComponent>
            </div>
          </div>
        </ProtectComponent>
      </div>
    </div>
  )
}

export {MainWrapper}
