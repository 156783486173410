import {DataRoutesProps} from '../UserRolesValidation'
import Dashboard from './Dashboard'
import KebijakanTeknis from './kebijakan-teknis/KebijakanTeknis'
import EvaluasiPelaporan from './evaluasi-pelaporan/EvaluasiPelaporan'
import Kepegawaian from './kepegawaian/Kepegawaian'
import PengawasanInternal from './pengawasan-internal/PengawasanInternal'
import PerencanaanAnggaran from './perencanaan-anggaran/MainRoutes'
import PengelolaanHasilPengawasan from './pengelolaan-hasil-pengawasan/MainRoutes'
import TataUsaha from './tata-usaha/TataUsaha'
import DashboardIrjen from './irjen/MainRoutes'
import Akun from './akun/Akun'
import {MainRoutes as DataPenggunaRoutes} from './pengaturan/DataPengguna'
import {MainRoutes as DataMenuRoutes} from './pengaturan/DataMenu'
import {MainRoutes as DataRolesRoutes} from './pengaturan/DataRoles'
import {DataSatkerUnitRoutes} from './pengaturan/DataSatkerUnit'
import KomunikasiHasilPengawasan from './komunikasi-hasil-pengawasan/KomunikasiHasilPengawasan'
import OrganisasiTataLaksana from './organisasi-tata-laksana/OrganisasiTataLaksana'
import PenyusunanPerarturan from './penyusunan-perarturan/MainRoutes'
import PengelolaanBarang from './pengelolaan-barang/PengelolaanBarang'
import PengelolaanKehumasan from './pengelolaan-kehumasan/PengelolaanKehumasan'
import PengelolaanGratifikasi from './pengelolaan-gratifikasi/PengelolaanGratifikasi'
import PengelolaanKeuangan from './pengelolaan-keuangan/PengelolaanKeuangan'
import Messenger from './messenger/Messenger'
import Notifikasi from './notifikasi/Notifikasi'
import {SatuanKerjaRoutes} from './pengaturan/SatuanKerja'
import {PenandatanganSTRoutes} from './pengaturan/PenandatanganST'
import ASampleRoutes from './Sample'
import {DataPegawaiPPKRoutes} from './pengaturan/DataPegawaiPPK'
import {DataPegawaiBendaharaRoutes} from './pengaturan/DataPegawaiBendahara'
import {DataPanduanPenggunaanRoutes} from './pengaturan/DataPanduanPenggunaan'
import {DataKotaRoutes} from './pengaturan/DataKota'
import {DataProvinsiRoutes} from './pengaturan/DataProvinsi'

const OtherRoutes: DataRoutesProps[] = [
  {...Akun},
  // {...Messenger},
  {...Notifikasi},
]

const DataPengaturan: DataRoutesProps = {
  title: 'Pengaturan',
  icon: 'element-10',
  route: 'pengaturan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Data Panduan Penggunaan',
      icon: 'element-10',
      route: 'data_panduan_penggunaan',
      elements: <></>,
      subroute: DataPanduanPenggunaanRoutes,
    },
    {
      isPage: true,
      title: 'Data Penandatangan ST',
      icon: 'element-10',
      route: 'penandatangan_st',
      elements: <></>,
      subroute: PenandatanganSTRoutes,
    },
    {
      isPage: true,
      title: 'Data Pegawai Bendahara',
      icon: 'element-10',
      route: 'data_pegawai_bendahara',
      elements: <></>,
      subroute: DataPegawaiBendaharaRoutes,
    },
    {
      isPage: true,
      title: 'Data Menu',
      icon: 'element-10',
      route: 'data_menu',
      elements: <></>,
      subroute: DataMenuRoutes,
    },
    {
      isPage: true,
      title: 'Data Roles',
      icon: 'element-10',
      route: 'data_roles',
      elements: <></>,
      subroute: DataRolesRoutes,
    },
    {
      isPage: true,
      title: 'Data Pegawai PPK',
      icon: 'element-10',
      route: 'data_pegawai_ppk',
      elements: <></>,
      subroute: DataPegawaiPPKRoutes,
    },
    {
      isPage: true,
      title: 'Data Satker Unit',
      icon: 'element-10',
      route: 'data_satker_unit',
      elements: <></>,
      subroute: DataSatkerUnitRoutes,
    },
    // {
    //   isPage: true,
    //   title: 'Satuan Kerja',
    //   icon: 'element-10',
    //   route: 'satuan_kerja',
    //   elements: <></>,
    //   subroute: SatuanKerjaRoutes,
    // },
    {
      isPage: true,
      title: 'Data Pengguna',
      icon: 'element-10',
      route: 'data_pengguna',
      elements: <></>,
      subroute: DataPenggunaRoutes,
    },
    {
      isPage: true,
      title: 'Data Kota',
      icon: 'element-10',
      route: 'data-kota',
      elements: <></>,
      subroute: DataKotaRoutes,
    },
    {
      isPage: true,
      title: 'Data Provinsi',
      icon: 'element-10',
      route: 'data-provinsi',
      elements: <></>,
      subroute: DataProvinsiRoutes,
    },
  ],
}

const SampleRoutes: DataRoutesProps = {
  isPage: true,
  title: 'Sample',
  icon: 'element-10',
  route: 'sample',
  elements: <></>,
  subroute: ASampleRoutes,
}

const Routes: DataRoutesProps[] = [
  {...Dashboard},
  // {...DashboardIrjen},
  {
    title: 'Core',
    icon: 'element-10',
    route: '#',
    isLabel: true,
    elements: <></>,
    subroute: [],
  },
  {...KebijakanTeknis},
  {...PengawasanInternal},
  {
    ...KomunikasiHasilPengawasan,
  },
  {
    title: 'Support',
    icon: 'element-10',
    route: '#',
    isLabel: true,
    elements: <></>,
    subroute: [],
  },
  {...PerencanaanAnggaran},
  {
    ...OrganisasiTataLaksana,
  },
  {
    ...PenyusunanPerarturan,
  },
  {
    ...PengelolaanKeuangan,
  },
  {...PengelolaanHasilPengawasan},
  {
    title: 'Pengelolaan DUMAS',
    icon: 'element-10',
    route: 'pengelolaan_dumas',
    elements: <></>,
    subroute: [],
  },
  {
    ...PengelolaanGratifikasi,
  },
  {
    ...PengelolaanBarang,
  },
  {...Kepegawaian},
  {...TataUsaha},
  {...PengelolaanKehumasan},
  {...EvaluasiPelaporan},
  {
    title: 'Option',
    icon: 'element-10',
    route: '#',
    isLabel: true,
    elements: <></>,
    subroute: [],
  },
  {...DataPengaturan},
  ...OtherRoutes,
  // {...SampleRoutes},
]

export {Routes}
