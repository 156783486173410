/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableColumn, TableInput, TableRow} from '../../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
import FormInput, {BaseInputOnChangeProps} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import ReactIcon from '../../../../helper/ReactIcon'
import {hasAccessRoutes, ProtectComponent} from '../../../../helper/UserRolesValidation'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../../modules/auth'

const MainWrapper: FC = () => {
  const query = useParams()
  console.log(query)
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id

  const checkProtect = (search: string, value: string) => {
    return hasAccessRoutes(routeData?.route, search, roleId === 1, true) ? value : ''
  }

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={setModal}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          route={{
            query: checkProtect(
              `perencanaan_anggaran/pok/kode-kegiatan/:id/$query=get=all`,
              'get=all'
            ),
            url: InfoData.api,
          }}
          onFeedback={(e) => {
            if (e?.id === 'lihat') {
              console.log(e)
            }
          }}
          sidebar={
            <>
              <ProtectComponent id='tambah'>
                <button
                  type='button'
                  onClick={() => navigate('./tambah')}
                  className='btn btn-primary d-flex align-items-center gap-1'
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                  Tambah
                </button>
              </ProtectComponent>
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn label='Kegiatan' props={{style: {width: '400px'}}}>
                    {`${NullProof({input: l, params: 'kode_kegiatan'})} - ${NullProof({
                      input: l,
                      params: 'nama_kegiatan',
                    })}`}
                  </TableColumn>
                  <TableColumn label='Komponen' props={{style: {width: '400px'}}}>
                    {`${NullProof({input: l, params: 'kode_komponen'})} - ${NullProof({
                      input: l,
                      params: 'nama_komponen',
                    })}`}
                  </TableColumn>
                  <TableColumn label='Jumlah Biaya' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'jumlah_harga', type: 'currency'})}
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:kode_kegiatan`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  console.log(query)
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Kode'}}>
              {NullProof({input: data, params: 'kode_kegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'nama_kegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jumlah Harga'}}>
              {NullProof({input: data, params: 'jumlah_harga', type: 'currency'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
