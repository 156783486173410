import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  PdfWrapper,
} from '../../../../pages/kepegawaian/cuti-pegawai/permohonan-cuti/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'

const MainRoutes = (type: 'semua' | 'persetujuan' | 'ditolak' | 'selesai' = 'semua') => {
  const DataRoutes: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper type={type} />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper type={type} />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'pdf/:id',
      elements: <PdfWrapper type={type} />,
      subroute: [],
    },
    ...((type === 'semua' && [
      {
        icon: '',
        title: '',
        route: 'tambah',
        elements: <TambahWrapper type={type} />,
        subroute: [],
      },
      {
        icon: '',
        title: '',
        route: 'ubah/:id',
        elements: <UbahWrapper type={type} />,
        subroute: [],
      },
    ]) ||
      []),
  ]
  return DataRoutes
}

export {MainRoutes}
