import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInputV2'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'provinsi_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data_provinsi',
        params: 'nama',
        result: '$nama',
        id: 'id',
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
]

export {formInputList}
