/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'
import {CardUi, DropdownUiV2 as DropdownUi} from '../../../helper/AppUi'
import {
  TableActionProps,
  TableColumn,
  TableInput,
  TableInputCheckboxDataProps,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  onLoadProps as onLoadPropsForm,
} from '../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {useIntl} from 'react-intl'
import {intlMessage} from '../../../helper/ReactIntl'
import ReactIcon from '../../../helper/ReactIcon'

const MainWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [modal, setModal] = useState<any>([])
  const [modalPopup, setModalPopup] = useState<boolean>(false)

  const [action, setAction] = useState<TableActionProps[]>(TableInputData.action || [])

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {`${intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.breadcrumb.title, intl)}`}
      </PageTitle>
      <CardUi title={intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.title, intl)}>
        <TableInput
          {...TableInputData}
          action={action}
          modal={setModal}
          checkbox={setCheckbox}
          onFeedback={(e) => {
            if (e?.idMenu === 'modal') {
              setModalPopup(true)
            }
          }}
          onSuccess={setData}
          sidebar={
            <>
              <button
                type='button'
                onClick={() => navigate('./tambah')}
                className='btn btn-primary d-flex align-items-center gap-1'
              >
                <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                {intlMessage(`component.tableinput.button.add`, 'Add', intl)}
              </button>
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn label='Nama' className='min-w-150px'>
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const onChangeForm = (e: BaseInputOnChangeProps) => {
  const {resetForm, clickId, type} = e
  const data = e?._data.get
  const apiData = e?._apiData?.get
  if (clickId === 'pegawai_id') {
    const nip = apiData?.pegawai_id_detail?.nip
    resetForm({values: {...data, nip: nip}})
  }
}

const UbahWrapper: FC = () => {
  const query = useParams()
  const intl = useIntl()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Edit {page}`, id: `component.infopage.edit_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, InfoData.title, intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Add {page}`, id: `component.infopage.add_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, InfoData.title, intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Import Data {page}`, id: `component.infopage.import_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, InfoData.title, intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: false,
            isFeedback: true,
            onFeedback({status, message, data}) {
              console.log(status, message, data)
            },
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
        />
      </CardUi>
    </>
  )
}
const DetailWrapper: FC = () => {
  const intl = useIntl()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Provinsi'}}>
              {NullProof({input: data, params: 'provinsi.nama'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, ImportWrapper}
