/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Yup from 'yup'
import {FC, Fragment, ReactNode, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDetailData,
  TableInputUsulan,
  TableInputST,
  TableInputSTSatker,
  TableInputUsulanSatker,
} from './PageData'
import {
  formInputPass,
  formInputStatus,
  formInputStatus2,
  formInputStatusIrwil,
  formInputStatusPass,
  formInputStatusdisposisi,
  inputListEnd,
  inputListAll,
  inputListAdjusment,
} from './InputLayout'
import FormInput, {
  BaseInputOnChangeProps,
  FormInputProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../helper/FormInput'
import {CardUi, ModalButtonUi, TabsUi} from '../../../../helper/AppUi'
import {
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  ConvertCurrency,
  NullProofProps,
  objectListSelector,
  getStatusColor,
  numberlistPagination,
  BreadcrumbQueryParams,
} from '../../../../helper/AppFunction'
import {TableAction, TableColumn, TableInput, TableRow} from '../../../../helper/TableInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formatDate} from '../../../../helper/DateFns'
import Modal from '../../../../helper/Modal'
import {useAuth} from '../../../../modules/auth'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayout, PdfLayoutPreviewST} from './PdfLayout'
import {getData} from '../../../../helper/FormAxios'
import {getObjectDifferences} from '../../../../helper/KemenagFunction'
import {WithChildren} from '../../../../../_metronic/helpers'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {
  TableListUsulanByDate,
  TableListSTByDate,
  TableListTujuanSatker,
  TableListStTujuanSatker,
  ButtonKirimNaskah,
} from './TableWrapper'
import Accordion from '../../../../helper/Accordion'
import {useDispatch, useSelector} from 'react-redux'
import {clear, post} from '../../../../redux/pageSlice'

const cekTahun = (tahun: string) => {
  return tahun && Number(tahun) < 2025
}

const checkOnChange = (e: BaseInputOnChangeProps) => {
  let _inputList: FormInputProps[] = [...inputListAll]
  const dataObj = {...e._data.getObject}
  let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
  // Trigger when e is defined only
  if (e) {
    const updateProps = (l: FormInputProps, status: boolean) => {
      l.disabled = status
      if (l.options?.input?.props) {
        l.options.input.props.disabled = status
      }
      if (l.options?.select?.props) {
        l.options.select.props.disabled = status
      }
    }
    let isHidePKPT = false
    const createdAt = formatDate({date: e._apiData.server?.createdAt, dateFormat: 'yyyy'})
    const sumber_penugasan = formInputListSelector({
      formInputList: _inputList,
      name: 'sumber_penugasan',
    })
    const lainnya = formInputListSelector({formInputList: _inputList, name: 'lainnya'})
    const jenis_id = formInputListSelector({formInputList: _inputList, name: 'jenis'})
    const kategori_id = formInputListSelector({formInputList: _inputList, name: 'kategori_id'})
    const pok_id = formInputListSelector({formInputList: _inputList, name: 'pok_id'})
    const kode_mak = formInputListSelector({formInputList: _inputList, name: 'kode_mak'})
    const mak = formInputListSelector({formInputList: _inputList, name: 'mak'})
    const ppk_id = formInputListSelector({formInputList: _inputList, name: 'ppk_id'})
    const nama_kegiatan = formInputListSelector({formInputList: _inputList, name: 'nama_kegiatan'})
    const pkptSelector = [pok_id, kode_mak, mak, ppk_id, jenis_id, kategori_id, nama_kegiatan]
    if (cekTahun(createdAt)) {
      const api = '/pengawasan_internal/kategori-kegiatan'
      const protect = '/pengawasan/persiapan/data_tambahan/kategori_kegiatan'
      sumber_penugasan.listData = [
        // {title: 'Form APP', value: 'Form APP'},
        {title: 'Form PKPT', value: 'Form PKPT'},
        {title: 'Lainnya', value: 'Lainnya'},
      ]
      if (kategori_id.options?.datalist) {
        kategori_id.options.datalist.api = api
        if (kategori_id.options.datalist.table) {
          kategori_id.options.datalist.table.protect = protect
          kategori_id.options.datalist.table.props.route.url = api
        }
      }
      if (lainnya.options?.input?.props) {
        lainnya.options.input.props = {
          title: 'Lainnya',
          placeholder: 'Lainnya',
        }
      }
    } else {
      const api = '/pengawasan_internal/kategori-penugasan'
      const protect = '/pengawasan/persiapan/data_tambahan/kategori_penugasan'
      sumber_penugasan.listData = [
        {title: 'Form PKPT', value: 'Form PKPT'},
        {title: 'Non SPD', value: 'Lainnya'},
      ]
      if (kategori_id.options?.datalist) {
        kategori_id.options.datalist.api = api
        if (kategori_id.options.datalist.table) {
          kategori_id.options.datalist.table.protect = protect
          kategori_id.options.datalist.table.props.route.url = api
        }
      }
      if (lainnya.options?.input?.props) {
        lainnya.options.input.props = {
          title: 'Non SPD',
          placeholder: 'Non SPD',
        }
      }
    }
    //
    if (sumberPenugasan === 'Form PKPT') {
      isHidePKPT = true
      pkptSelector.forEach((l) => {
        updateProps(l, isHidePKPT)
        l.validator = Yup.string()
      })
      // if (jenis_id.options?.select?.props) {
      //   jenis_id.options.select.props.disabled = false
      // }
    } else if (sumberPenugasan === 'Lainnya') {
      pkptSelector.forEach((l) => {
        updateProps(l, isHidePKPT)
        l.validator = Yup.string().required('Mohon untuk diisi.')
      })
      if (jenis_id.options?.select?.props) {
        jenis_id.options.select.props.disabled = true
      }
    } else {
      pkptSelector.forEach((l) => {
        updateProps(l, isHidePKPT)
        l.validator = Yup.string().required('Mohon untuk diisi.')
      })
      if (jenis_id.options?.select?.props) {
        jenis_id.options.select.props.disabled = false
      }
    }
    //
  }
  return _inputList
}

const dynamicInputList = ({
  data,
  id,
  param,
  e,
}: {
  data?: any
  param?: string
  id?: number
  e?: BaseInputOnChangeProps
}) => {
  const dataObj = {...e?._data.getObject}
  let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
  //
  let _inputList: FormInputProps[] = [...inputListAll]
  let _inputList2: any = [...inputListEnd].filter((l) => l?.name === 'usulan_tim')
  const form_app_id = formInputListSelector({formInputList: _inputList, name: 'form_app_id'})
  const form_app_detail = formInputListSelector({
    formInputList: _inputList,
    name: 'form_app_detail',
  })
  const pkpt_id = formInputListSelector({formInputList: _inputList, name: 'pkpt_id'})
  const lainnya = formInputListSelector({formInputList: _inputList, name: 'lainnya'})
  const statusReview = formInputListSelector({formInputList: _inputList, name: 'passphrase'})
  const pok_id = formInputListSelector({formInputList: _inputList, name: 'pok_id'})
  const mak = formInputListSelector({formInputList: _inputList, name: 'mak'})
  const kode_mak = formInputListSelector({formInputList: _inputList, name: 'kode_mak'})
  const ppk_id = formInputListSelector({formInputList: _inputList, name: 'ppk_id'})
  const deskripsi_kegiatan = formInputListSelector({
    formInputList: _inputList,
    name: 'deskripsi_kegiatan',
  })
  if (data === 'Form APP') {
    form_app_id.hide = false
    form_app_detail.hide = false
    pkpt_id.hide = true
    lainnya.hide = true
    form_app_id.validator = Yup.number().required('Mohon untuk diisi.')
    form_app_detail.validator = Yup.string().required('Mohon untuk diisi.')
    pkpt_id.validator = Yup.number()
    lainnya.validator = Yup.string()
  } else if (data === 'Form PKPT') {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = false
    lainnya.hide = true
    form_app_id.validator = Yup.number()
    form_app_detail.validator = Yup.string()
    pkpt_id.validator = Yup.number().required('Mohon untuk diisi.')
    lainnya.validator = Yup.string()
  } else if (data === 'Lainnya') {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = true
    lainnya.hide = false
    form_app_id.validator = Yup.number()
    form_app_detail.validator = Yup.string()
    pkpt_id.validator = Yup.number()
    lainnya.validator = Yup.string().required('Mohon untuk diisi.')
  } else if (data === 'Ditolak') {
    if (statusReview) {
      statusReview.hide = true
      statusReview.validator = Yup.string()
    }
  } else if (data === 'Menunggu') {
    if (statusReview) {
      statusReview.hide = true
      statusReview.validator = Yup.string()
    }
  } else if (data === 'Disetujui') {
    if (statusReview) {
      statusReview.hide = false
      statusReview.validator = Yup.string().required('Mohon untuk diisi.')
    }
  } else if (param == 'jenis') {
    if (data === 'Non SPD' || data === '') {
      pok_id.validator = Yup.number()
      pok_id.hide = true
      mak.validator = Yup.string()
      mak.hide = true
      kode_mak.validator = Yup.string()
      kode_mak.hide = true
      ppk_id.validator = Yup.string()
      ppk_id.hide = true
    } else if (data === 'SPD' || data === 'SPD DKI Jakarta') {
      pok_id.validator = Yup.number().required('Mohon untuk diisi.')
      mak.validator = Yup.string().required('Mohon untuk diisi.')
      kode_mak.validator = Yup.string().required('Mohon untuk diisi.')
      ppk_id.validator = Yup.string().required('Mohon untuk diisi.')
      pok_id.hide = false
      mak.hide = false
      kode_mak.hide = false
      ppk_id.hide = false
    }
  } else if (param === 'tipe tujuan') {
    const jenis_tujuan = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'jenis_tujuan',
    })
    const jenis_tujuan_2 = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'jenis_tujuan_2',
    })
    const jenis_tujuan_3 = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'jenis_tujuan_3',
    })
    const provinsi = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'provinsi',
    })
    const kota = formInputListSelector({formInputList: _inputList2[0].component, name: 'kota'})
    const kota2 = formInputListSelector({formInputList: _inputList2[0].component, name: 'kota2'})
    const kota3 = formInputListSelector({formInputList: _inputList2[0].component, name: 'kota3'})
    const negara = formInputListSelector({formInputList: _inputList2[0].component, name: 'negara'})
    const satker_id = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'satker_id',
    })
    const satker_id_2 = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'satker_id_2',
    })
    const satker_id_3 = formInputListSelector({
      formInputList: _inputList2[0].component,
      name: 'satker_id_3',
    })
    data?.map((l: any, i: number) => {
      if (l?.tipe_tujuan === 'Luar negeri') {
        jenis_tujuan.validator = Yup.string()
        jenis_tujuan_2.validator = Yup.string()
        jenis_tujuan_3.validator = Yup.string()
        jenis_tujuan.hide = true
        jenis_tujuan_2.hide = true
        jenis_tujuan_3.hide = true
        provinsi.validator = Yup.string()
        provinsi.hide = true
        negara.validator = Yup.string().required('Negara wajib diisi')
        negara.hide = false
        kota.type = 'input'
        kota.options = {
          input: {
            props: {
              title: 'Kota',
              placeholder: 'Kota',
            },
          },
        }
        kota2.validator = Yup.string()
        kota2.hide = true
        kota3.validator = Yup.string()
        kota3.hide = true
        satker_id.validator = Yup.string()
        satker_id.hide = true
        satker_id_2.validator = Yup.string()
        satker_id_2.hide = true
        satker_id_3.validator = Yup.string()
        satker_id_3.hide = true
      } else {
        jenis_tujuan.validator = Yup.string().required('Jenis Tujuan wajib diisi')
        jenis_tujuan_2.validator = Yup.string()
        jenis_tujuan_3.validator = Yup.string()
        jenis_tujuan.hide = false
        jenis_tujuan_2.hide = false
        jenis_tujuan_3.hide = false
        provinsi.validator = Yup.string().required('Mohon untuk diisi.')
        provinsi.hide = false
        negara.validator = Yup.string()
        negara.hide = true
        kota.type = 'datalist'
        kota.options = {
          input: {
            props: {
              title: 'Kota',
              placeholder: 'Kota',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            query: 'provinsi=$provinsi',
            useOnLoad: true,
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        }
        kota2.validator = Yup.string()
        kota2.hide = false
        kota3.validator = Yup.string()
        kota3.hide = false
        satker_id.validator = Yup.string().required('Mohon untuk diisi.')
        satker_id.hide = false
        satker_id_2.validator = Yup.string()
        satker_id_2.hide = false
        satker_id_3.validator = Yup.string()
        satker_id_3.hide = false
      }
    })
  } else if (param === 'update kode_mak') {
    kode_mak.options = {
      input: {
        props: {
          title: 'Kode MAK',
          placeholder: 'Kode MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: `listkegiatan=true&usulan_tim=$usulan_tim${id ? `&id_usulan=${id}` : ''}`,
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'listkegiatan=true&usulan_tim=$usulan_tim',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'pok_id,usulan_tim',
      },
    }
    mak.options = {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: `kodekegiatan=$kode_mak&usulan_tim=$usulan_tim&jenis_tujuan=$jenis_tujuan${
          id ? `&id_usulan=${id}` : ''
        }`,
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'kodekegiatan=$kode_mak&usulan_tim=$usulan_tim',
              singleLoad: true,
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'usulan_tim,pok_id,kode_mak',
      },
    }
  } else {
    form_app_id.hide = true
    form_app_detail.hide = true
    pkpt_id.hide = true
    lainnya.hide = true
    form_app_id.validator = Yup.number()
    form_app_detail.validator = Yup.string()
    pkpt_id.validator = Yup.number()
    lainnya.validator = Yup.string()
  }

  //
  return _inputList
}

const onChange = async (e: BaseInputOnChangeProps, lastData: any, _inputList: FormInputProps[]) => {
  const data = {...e._data.get}
  const dataObj = {...e._data.getObject}
  const api = e._apiData.get
  const reviewer2ket = NullProof({input: api, params: 'penandatangan_st_2_detail.keterangan'})
  const pok_id = formInputListSelector({formInputList: _inputList, name: 'pok_id'})
  const mak = formInputListSelector({formInputList: _inputList, name: 'mak'})
  const kode_mak = formInputListSelector({formInputList: _inputList, name: 'kode_mak'})
  const ppk_id = formInputListSelector({formInputList: _inputList, name: 'ppk_id'})
  const nama_kegiatan = formInputListSelector({formInputList: _inputList, name: 'nama_kegiatan'})
  const usulan_tim = formInputListSelector({formInputList: _inputList, name: 'usulan_tim'})
  let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
  let result = {...data}
  if (e.type === 'onChange') {
    if (e.clickId === 'pkpt_id') {
      try {
        NullProof({input: dataObj, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => {
          const provinsiName = `usulan_tim_provinsi_${i + 1}`
          const provinsi = formInputListSelector({
            formInputList: usulan_tim.component || [],
            name: 'provinsi',
          })
          if (api?.pkpt_id_detail?.jenis === 'SPD DKI Jakarta') {
            provinsi.disabled = true
            result = {
              ...result,
              [`${provinsiName}`]: 'DKI JAKARTA',
              [`usulan_tim_kota_${i + 1}`]: ``,
              [`usulan_tim_satker_id_${i + 1}`]: ``,
              [`usulan_tim_kota2_${i + 1}`]: ``,
              [`usulan_tim_satker_id_2_${i + 1}`]: ``,
              [`usulan_tim_kota3_${i + 1}`]: ``,
              [`usulan_tim_satker_id_3_${i + 1}`]: ``,
            }
          } else {
            provinsi.disabled = false
            result = {
              ...result,
              [`${provinsiName}`]: ``,
              [`usulan_tim_kota_${i + 1}`]: ``,
              [`usulan_tim_satker_id_${i + 1}`]: ``,
              [`usulan_tim_kota2_${i + 1}`]: ``,
              [`usulan_tim_satker_id_2_${i + 1}`]: ``,
              [`usulan_tim_kota3_${i + 1}`]: ``,
              [`usulan_tim_satker_id_3_${i + 1}`]: ``,
            }
          }
        })
      } catch (_) {}
    }
    if (e.clickId === 'sumber_penugasan') {
      if (sumberPenugasan === 'Lainnya') {
        result = {
          ...result,
          jenis: 'Non SPD',
          pok_id: '',
          mak: '',
          kode_mak: '',
          ppk_id: '',
          kategori_id: '',
          lainnya: '',
          nama_kegiatan: '',
          form_app_id: '',
          form_app_detail: '',
          pkpt_id: '',
        }
      } else {
        result = {
          ...result,
          jenis: '',
          pok_id: '',
          mak: '',
          kode_mak: '',
          ppk_id: '',
          kategori_id: '',
          lainnya: '',
          nama_kegiatan: '',
        }
      }
    }
    if (sumberPenugasan === 'Form PKPT') {
      if (e.clickId === 'pkpt_id') {
        const pkptDetail = api?.pkpt_id_detail
        if (pkptDetail) {
          result = {
            ...result,
            jenis: pkptDetail.jenis,
            pok_id: pkptDetail.pok_id,
            mak: pkptDetail.mak,
            kode_mak: pkptDetail.kode_mak,
            ppk_id: pkptDetail.ppk_id,
            kategori_id: pkptDetail.kategori_id,
            nama_kegiatan: pkptDetail.nama_kegiatan,
          }
        }
      }
    }
  }
  if (e.type === 'onChange' || e.type === 'onInit') {
    const formAppId = dataObj?.form_app_id
    if (dataObj?.form_app_id && lastData?.form_app_id !== data?.form_app_id) {
      const formInputDetail = formInputListSelector({
        formInputList: _inputList,
        name: 'form_app_detail',
      })
      formInputDetail.disabled = true
      formInputDetail.listData = []
      let listOptionResult: any[] = []
      const result = await getData('optionList=true', `/pengawasan_internal/form-app/${formAppId}`)
      const listOption = [...NullProof({input: result?.data, params: 'listOption', isMap: true})]
      for (const l of listOption) {
        listOptionResult.push({
          title: l?.label,
          value: l?.value,
        })
      }
      formInputDetail.listData = [...listOptionResult]
      formInputDetail.disabled = false
    }
    if (NullProof({input: dataObj, params: 'usulan_tim', isMap: true})?.length > 0) {
      e.setError({field: 'kode_mak', message: ''})
    } else {
      const jenisUsulan = NullProof({input: dataObj, params: 'jenis'})
      if (jenisUsulan === 'SPD' || jenisUsulan === 'SPD DKI Jakarta') {
        e.setError({
          field: 'kode_mak',
          message: 'Harap untuk mengisi Usulan Tim dan POK terlebih dahulu.',
        })
      } else {
        e.setError({field: 'kode_mak', message: ''})
      }
    }

    // NullProof({input: dataObj, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => {
    //   const pNamaKota1 = `usulan_tim_kota_${i + 1}`
    //   const pNamaKota2 = `usulan_tim_kota2_${i + 1}`
    //   const pNamaKota3 = `usulan_tim_kota3_${i + 1}`
    //   if (!data[pNamaKota2] && !data[pNamaKota3]) {
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: ''})
    //   } else if (
    //     data[pNamaKota1] !== data[pNamaKota2] &&
    //     data[pNamaKota1] !== data[pNamaKota3] &&
    //     data[pNamaKota2] !== data[pNamaKota3]
    //   ) {
    //     e.setError({field: pNamaKota1, message: ''})
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: ''})
    //     console.log('!')
    //   } else if (data[pNamaKota1] === data[pNamaKota2]) {
    //     e.setError({field: pNamaKota1, message: 'Kota tidak boleh sama Kota 2'})
    //     e.setError({field: pNamaKota2, message: 'Kota 2 tidak boleh sama Kota'})
    //     e.setError({field: pNamaKota3, message: ''})
    //     console.log('!!')
    //   } else if (data[pNamaKota2] === data[pNamaKota3]) {
    //     e.setError({field: pNamaKota1, message: ''})
    //     e.setError({field: pNamaKota2, message: 'Kota 2 tidak boleh sama Kota 3'})
    //     e.setError({field: pNamaKota3, message: 'Kota 3 tidak boleh sama Kota 2'})
    //     console.log('!!!')
    //   } else {
    //     e.setError({field: pNamaKota1, message: 'Kota tidak boleh sama Kota 3'})
    //     e.setError({field: pNamaKota2, message: ''})
    //     e.setError({field: pNamaKota3, message: 'Kota 3 tidak boleh sama Kota 1'})
    //     console.log('!!!!')
    //   }
    // })
    // console.log(dataObj)
    if (lastData?.pkpt_id !== data?.pkpt_id) {
      const pkpt_detail = api?.pkpt_id_detail
      let kepada: any[] = []
      let kepadaResult: any = {}
      for (let index = 0; index < data?.kepada || 0; index++) {
        let kepadaJson: any = {}
        formInputListSelector({formInputList: _inputList, name: 'kepada'}).component?.map((l) => {
          kepadaJson[l.name] = data[`kepada_${l.name}_${index + 1}`]
          delete data[`kepada_${l.name}_${index + 1}`]
        })
        if (!(kepada.filter((l: any) => l?.pegawai_id === kepadaJson?.pegawai_id)?.length > 0)) {
          kepada.push(kepadaJson)
        }
      }
      NullProof({input: pkpt_detail, params: 'list_auditor', isMap: true})?.map(
        (l: any, i: number) => {
          let kepadaJson: any = {
            pegawai_id: l?.pegawai_id,
            jabatan: l?.jawaban,
            hari: 0,
          }
          if (!(kepada.filter((l: any) => l?.pegawai_id === kepadaJson?.pegawai_id)?.length > 0)) {
            kepada.push(kepadaJson)
          }
        }
      )
      kepada.forEach((l, i) => {
        kepadaResult[`kepada_pegawai_id_${i + 1}`] = l.pegawai_id
        kepadaResult[`kepada_jabatan_${i + 1}`] = l.jabatan
        kepadaResult[`kepada_hari_${i + 1}`] = l.hari
      })
      result = {
        ...result,
        tanggal_mulai: pkpt_detail?.tgl_mulai,
        tanggal_selesai: pkpt_detail?.tgl_selesai,
        kepada: kepada.length || 0,
        ...kepadaResult,
      }
    }
    result = {...result, jabatan_penandatangan: reviewer2ket}
    e?.resetForm({values: result})
    if (data?.jenis === 'Non SPD') {
      pok_id.validator = Yup.string()
      mak.validator = Yup.string()
      kode_mak.validator = Yup.string()
      ppk_id.validator = Yup.string()
    }
  }
  return sumberPenugasan
}

const onLoadForm = (e: onLoadPropsForm) => {
  const data = e.data?._data.getObject
  // console.log("dataaa", data)
  const loadDataMember = async () => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.kodekegiatan = data?.kode_mak
    let usulan_tim: any = []
    data.usulan_tim.map((l: any) => {
      usulan_tim.push({
        kepada: l?.kepada || [],
        provinsi: l?.provinsi,
        kota: l?.kota,
      })
    })
    eQuery.usulan_tim = JSON.stringify(usulan_tim)
    let result = await getData(
      e.query?.post(eQuery) || '',
      e.id === 'pok_id' ? '/keuangan/form-pok' : `/keuangan/form-pok/${data?.pok_id}`
    )
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  const loadDataKota = async (data: any) => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.provinsi = data
    const result = await getData(e.query?.post(eQuery) || '', '/pengawasan_internal/data-kota')
    e.load({query: eQuery, result: result})
  }

  const loadDataSatker = async (provinsi: any, jenis_tujuan: any) => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.provinsi = provinsi
    eQuery.jenis_tujuan = jenis_tujuan.toLowerCase()
    const result = await getData(e.query?.post(eQuery) || '', '/kepegawaian/data-satker-unit')
    console.log('result', result)
    e.load({query: eQuery, result: result})
  }

  const loadDataIrwil = async () => {
    e.initLoading()
    const eQuery = e.query?.get
    eQuery.orderby = 'asc'
    eQuery.jabatan = 'inspektur'
    const result = await getData(e.query?.post(eQuery) || '', '/kepegawaian/data-tenaga-audit')
    // console.log("result", result)
    e.load({query: eQuery, result: result})
  }

  e.id === 'kode_mak' && loadDataMember()
  e.id === 'mak' && loadDataMember()
  e.id === 'pok_id' && loadDataMember()
  e.id === 'irwil_id' && loadDataIrwil()
  e.id === 'disposisi_id' && loadDataIrwil()
  data?.usulan_tim.map((l: any, i: number) => {
    const kota = `usulan_tim_kota_${i + 1}`
    const kota2 = `usulan_tim_kota2_${i + 1}`
    const kota3 = `usulan_tim_kota3_${i + 1}`

    e.id === kota && loadDataKota(l.provinsi)
    e.id === kota2 && loadDataKota(l.provinsi)
    e.id === kota3 && loadDataKota(l.provinsi)

    const satker = `usulan_tim_satker_id_${i + 1}`
    const satker2 = `usulan_tim_satker_id_2_${i + 1}`
    const satker3 = `usulan_tim_satker_id_3_${i + 1}`

    e.id === satker && loadDataSatker(l.provinsi, l.jenis_tujuan)
    e.id === satker2 && loadDataSatker(l.provinsi, l.jenis_tujuan_2)
    e.id === satker3 && loadDataSatker(l.provinsi, l.jenis_tujuan_3)
  })
  // NullProof({input: data, params: 'custom_api_components', isMap: true})?.map(
  //   (l: any, i: number) => {
  //     const name = `custom_api_components_custom_api_onload_${i + 1}`
  //     e.id === name && loadDataMember()
  //   }
  // )
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const reduxDispatch = useDispatch()
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => {
            setData(val)
            reduxDispatch(clear())
            // console.log("vallutama", val)
          }}
          {...TableInputData}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '200px'}}}>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Jenis Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Sumber Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'sumber_penugasan'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Nama Kegiatan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                        {NullProof({input: l, params: 'nama_kegiatan'})}
                      </div>
                    </div>
                  </TableColumn>
                  <TableColumn label='Status'>
                    {NullProof({input: l, params: 'tipe_usulan'}) === 'Draft' ? (
                      'Draft'
                    ) : (
                      <>
                        {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                          <div>
                            <div className='d-flex justify-content-center gap-2 mb-2'>
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: getStatusColor(
                                    NullProof({input: l, params: 'status_irwil', isLabel: false})
                                  ),
                                }}
                              ></div>
                              <div className='w-50'>
                                Riviu irwil : <br />
                                <span
                                  style={{
                                    color: getStatusColor(
                                      NullProof({input: l, params: 'status_irwil'})
                                    ),
                                  }}
                                >
                                  {'  '} {NullProof({input: l, params: 'status_irwil'})}
                                </span>
                              </div>
                            </div>
                            <br />
                            <div className='d-flex justify-content-center gap-2 mb-2'>
                              <div
                                className='rounded-circle'
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  minWidth: '20px',
                                  backgroundColor: getStatusColor(
                                    NullProof({
                                      input: l,
                                      params: 'status_disposisi',
                                      isLabel: false,
                                    })
                                  ),
                                }}
                              ></div>
                              <div className='w-50'>
                                Riviu Insp/Irjen :
                                <br />
                                <span
                                  style={{
                                    color: getStatusColor(
                                      NullProof({input: l, params: 'status_disposisi'})
                                    ),
                                  }}
                                >
                                  {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                                </span>
                                <br />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status', isLabel: false})
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>Riviu Ses/Kasub : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>Riviu 1 : </span>
                            )}
                            <br />
                            <span
                              style={{
                                color: getStatusColor(NullProof({input: l, params: 'status'})),
                              }}
                            >
                              {'  '} {NullProof({input: l, params: 'status'})}
                            </span>
                            <br />
                          </div>
                        </div>
                        {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_paraf', isLabel: false}) ===
                                    true
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Paraf Ses/Kasub :
                              <br />
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_paraf'}) === true
                                      ? 'Disetujui'
                                      : 'Ditolak'
                                  ),
                                }}
                              >
                                {'  '}{' '}
                                {NullProof({input: l, params: 'status_paraf'}) === true
                                  ? 'Sudah'
                                  : 'Belum'}
                              </span>
                              <br />
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status_2', isLabel: false})
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>Riviu Insp/Irjen : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>Riviu 2 : </span>
                            )}
                            <br />
                            <span
                              style={{
                                color: getStatusColor(NullProof({input: l, params: 'status_2'})),
                              }}
                            >
                              {'  '} {NullProof({input: l, params: 'status_2'})}
                            </span>
                            <br />
                          </div>
                        </div>
                        <div className='d-flex justify-content-center gap-2 mb-2'>
                          <div
                            className='rounded-circle'
                            style={{
                              width: '20px',
                              height: '20px',
                              minWidth: '20px',
                              backgroundColor: getStatusColor(
                                NullProof({input: l, params: 'status_tte', isLabel: false}) === true
                                  ? 'Disetujui'
                                  : 'Ditolak'
                              ),
                            }}
                          ></div>
                          <div className='w-50'>
                            {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                              <span>TTE Irjen : </span>
                            )}
                            {NullProof({input: l, params: 'type_pengusul'}) !== 'auditor' && (
                              <span>TTE : </span>
                            )}
                            <br />
                            <span
                              style={{
                                color: getStatusColor(
                                  NullProof({input: l, params: 'status_tte'}) === true
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            >
                              {'  '}{' '}
                              {NullProof({input: l, params: 'status_tte'}) === true
                                ? 'Sudah'
                                : 'Belum'}
                            </span>
                            <br />
                          </div>
                        </div>
                        {NullProof({input: l, params: 'status_revisi'}) === 'Revisi' && (
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_revisi'}) === 'Revisi'
                                    ? 'Disetujui'
                                    : 'Ditolak'
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              <span>Revisi : </span>
                              <span>
                                {formatDate({
                                  date: NullProof({input: l, params: 'revisi_tgl'}),
                                  dateFormat: 'dd MMMM yyyy',
                                })}{' '}
                                <br />
                                {formatDate({
                                  date: NullProof({input: l, params: 'revisi_tgl'}),
                                  dateFormat: 'HH:mm:ss',
                                })}
                              </span>
                              <br />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </TableColumn>
                  <TableColumn label='Tujuan' props={{style: {minWidth: '200px'}}}>
                    <ol className='m-0 ps-6'>
                      {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                        (ll: any, ii: number) => {
                          const listKota = (): string[] => {
                            let kota: string[] = []
                            if (ll?.kota) {
                              kota.push(ll?.kota)
                            }
                            if (ll?.kota2) {
                              kota.push(ll?.kota2)
                            }
                            if (ll?.kota3) {
                              kota.push(ll?.kota3)
                            }
                            if (ll?.negara) {
                              kota.push(ll?.negara)
                            }
                            return kota
                          }
                          return (
                            <Fragment key={ii}>
                              <li>
                                <div className='d-flex flex-column gap-1'>
                                  <div className='d-flex gap-2 justify-content-center'>
                                    <div className='text-capitalize'>
                                      {`${
                                        NullProof({
                                          input: ll,
                                          params: 'tipe_tujuan',
                                        })?.toLowerCase() !== 'luar negeri'
                                          ? `${NullProof({
                                              input: ll,
                                              params: 'provinsi',
                                            })} - `
                                          : ``
                                      }${listKota().join(' - ')}`.toLowerCase()}
                                    </div>
                                  </div>
                                  <div className='d-flex flex-column'>
                                    <div className='text-capitalize fw-bold'>Jenis Tujuan : </div>
                                    {NullProof({
                                      input: ll,
                                      params: 'tipe_tujuan',
                                      isLabel: false,
                                    })?.toLowerCase() === 'luar negeri' && (
                                      <div className='text-capitalize'>
                                        {NullProof({input: ll, params: 'tipe_tujuan'})}
                                      </div>
                                    )}
                                    {NullProof({
                                      input: ll,
                                      params: 'jenis_tujuan',
                                      isLabel: false,
                                    }) && (
                                      <div className='text-capitalize'>
                                        - {NullProof({input: ll, params: 'jenis_tujuan'})}
                                      </div>
                                    )}
                                    {NullProof({
                                      input: ll,
                                      params: 'jenis_tujuan_2',
                                      isLabel: false,
                                    }) && (
                                      <div className='text-capitalize'>
                                        - {NullProof({input: ll, params: 'jenis_tujuan_2'})}
                                      </div>
                                    )}
                                    {NullProof({
                                      input: ll,
                                      params: 'jenis_tujuan_3',
                                      isLabel: false,
                                    }) && (
                                      <div className='text-capitalize'>
                                        - {NullProof({input: ll, params: 'jenis_tujuan_3'})}
                                      </div>
                                    )}
                                  </div>
                                  {NullProof({
                                    input: ll,
                                    params: 'tipe_tujuan',
                                  })?.toLowerCase() !== 'luar negeri' && (
                                    <div className='d-flex flex-column'>
                                      <div className='text-capitalize fw-bold'>Satuan Kerja : </div>
                                      <div className='text-capitalize'>
                                        {`${NullProof({
                                          input: ll,
                                          params: 'satker.kode',
                                        })} - ${NullProof({
                                          input: ll,
                                          params: 'satker.nama_satker',
                                        })}`}
                                      </div>
                                    </div>
                                  )}

                                  <div className='d-flex flex-column'>
                                    <div className='text-capitalize fw-bold'>Tanggal : </div>
                                    <div>
                                      {`${NullProof({
                                        input: ll,
                                        params: 'tanggal_mulai',
                                        type: 'date',
                                      })} s/d ${NullProof({
                                        input: ll,
                                        params: 'tanggal_selesai',
                                        type: 'date',
                                      })}`}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </Fragment>
                          )
                        }
                      )}
                    </ol>
                  </TableColumn>
                  <TableColumn label='Dibuat Pada' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}{' '}
                    <br />
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'HH:mm:ss',
                    })}
                  </TableColumn>
                  {/* <td style={{minWidth: '200px'}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </td>
                  <td style={{minWidth: '300px'}}>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Jenis Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'jenis'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Sumber Penugasan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'sumber_penugasan'})}
                      </div>
                    </div>
                    <div style={{width: '100%'}} className='d-flex'>
                      <div className='fw-bold' style={{width: '50%'}}>
                        Nama Kegiatan
                      </div>
                      <div className='fw-bold' style={{width: '5%'}}>
                        :
                      </div>
                      <div style={{width: '60%', textAlign: 'left'}}>
                          {NullProof({input: l, params: 'nama_kegiatan'})}
                      </div>
                    </div>
                  </td>
                  <td style={{minWidth: '300px'}}>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'> Riviu 1 :
                          <span style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}>
                           {'  '} {NullProof({input: l, params: 'status'})}
                          </span>
                        <br /></div>
                      </div>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status_2', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'> Riviu 2 :
                          <span style={{color: getStatusColor(NullProof({input: l, params: 'status_2'}))}}>
                           {'  '} {NullProof({input: l, params: 'status_2'})}
                          </span>
                        <br /></div>
                      </div> */}
                  {/* Riviu 1 : {NullProof({input: l, params: 'status'})} <br />
                    Riviu 2 : {NullProof({input: l, params: 'status_2'})} */}
                  {/* </td> */}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapperRevisi: FC = () => {
  const [lastData, setLastData] = useState<any>({})
  const query = useParams()
  let _inputList = [...inputListAll]
  for (const l of _inputList) {
    l.disabled = false
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.revisi.page.breadcrumb.data}>
        {PageData.revisi.page.breadcrumb.title}
      </PageTitle>
      <div className='d-flex flex-column gap-4'>
        <CardUi title={PageData.revisi.page.title}>
          <FormInput
            name='revisi'
            input={[...inputListAll].map((e) => {
              return {...e, disabled: true}
            })}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id`, query),
              type: 'put',
            }}
            isLocked={true}
          />
        </CardUi>
        <CardUi title={PageData.revisi.page.title}>
          <FormInput
            input={_inputList}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id`, query),
              urlSubmit: RouterQueryParams(`${InfoData.api_revisi}/:id`, query),
              type: 'put',
              redirect: true,
              useServerMessage: true,
            }}
            onChange={async (e: BaseInputOnChangeProps) => {
              const dataObj = {...e._data.getObject}
              let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
              let jenis: string = dataObj?.jenis || ''
              _inputList = dynamicInputList({data: dataObj?.usulan_tim, param: 'tipe tujuan'})
              _inputList = dynamicInputList({data: sumberPenugasan})
              _inputList = dynamicInputList({data: jenis, param: 'jenis'})
              _inputList = dynamicInputList({data: dataObj.deskripsi_kegiatan, param: 'revisi ST'})
              _inputList = checkOnChange(e)
              if (e.type === 'onInit') {
                await onChange(e, lastData, _inputList)
              }
              if (e.type === 'onChange') {
                await onChange(e, lastData, _inputList)
              }
              setLastData((p: any) => ({...p, ...e._data.get}))
            }}
            onLoad={onLoadForm}
          />
        </CardUi>
      </div>
    </>
  )
}

const UbahWrapper: FC = () => {
  const [lastData, setLastData] = useState<any>({})
  const [formLock, setFormLock] = useState<boolean>(false)
  let _inputList = [...inputListAll]
  const query = useParams()
  const {auth} = useAuth()
  const dataUser: any = auth?.data
  const isSuperAdmin = (data: any) => {
    return data?.roles?.id === 1
  }
  const onInputChange = (e: any) => {
    let allStatus: boolean = false
    if (e?.type === 'onLoad') {
      const apiData = e?._apiData?.server
      const status = NullProof({input: apiData, params: 'status'})
      const status2 = NullProof({input: apiData, params: 'status_2'})
      if (isSuperAdmin(dataUser)) {
        for (const l of _inputList) {
          l.disabled = false
        }
      } else if (status === 'Disetujui' && status2 === 'Disetujui') {
        allStatus = true
        for (const l of _inputList) {
          l.disabled = true
        }
      } else if (
        status === 'Revisi' ||
        status2 === 'Revisi' ||
        status === 'Disetujui' ||
        status2 === 'Disetujui'
      ) {
        allStatus = false
        for (const l of _inputList) {
          l.disabled = true
        }
        formInputListSelector({formInputList: _inputList, name: 'usulan_tim'}).disabled = false
      } else {
        allStatus = false
        for (const l of _inputList) {
          l.disabled = false
        }
      }
    }
    return allStatus
  }
  // const onLoadUpdate = async (e: any) => {
  //   if(e._data?.get){
  //     const data = {...e._data.get}
  //     const dataObj = e._data.getObject
  //     let usulan_tim:any = []
  //     dataObj?.usulan_tim.map((l: any, i: number) => {
  //       usulan_tim.push({kepada: l?.kepada|| []})
  //     })

  //     const query_mak = `listkegiatan=true&usulan_tim=${JSON.stringify(usulan_tim)}`
  //     let mak_id = await getData(query_mak, `/keuangan/form-pok/${dataObj?.pok_id}`)
  //     console.log(e);
  //     console.log(mak_id?.data?.filter((l: any) => l?.kode === dataObj?.kode_mak)[0]);
  //     const result = {
  //       ...data,
  //      kode_mak: mak_id?.data?.filter((l: any) => l?.kode === dataObj?.kode_mak)[0].kode
  //     }
  //     e?.resetForm({values: result})
  //   }
  // }
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={_inputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
            useServerMessage: true,
          }}
          isLocked={formLock}
          onChange={async (e: BaseInputOnChangeProps) => {
            const dataObj = {...e._data.getObject}
            let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
            let jenis: string = dataObj?.jenis || ''
            let usulan_tim: string = dataObj?.usulan_tim || ''
            if (e.type === 'onInit') {
              _inputList = dynamicInputList({
                data: dataObj?.kode_mak,
                param: 'update kode_mak',
                id: parseInt(query.id || '0'),
              })
              await onChange(e, lastData, _inputList)
            }
            if (e.type === 'onLoad') {
              const lock = onInputChange(e)
              const _lock = () => {
                let isLock = false
                if (isSuperAdmin(dataUser)) {
                } else {
                  isLock = lock
                }
                return isLock
              }
              setFormLock(_lock())
            }
            _inputList = dynamicInputList({data: usulan_tim, param: 'tipe tujuan'})
            _inputList = dynamicInputList({data: sumberPenugasan})
            _inputList = dynamicInputList({data: jenis, param: 'jenis'})
            _inputList = checkOnChange(e)
            if (e.type === 'onChange') {
              await onChange(e, lastData, _inputList)
            }
            setLastData((p: any) => ({...p, ...e._data.get}))
          }}
          onLoad={async (e: onLoadPropsForm) => {
            onLoadForm(e)
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const [lastData, setLastData] = useState<any>({})
  const [idProv, setIdProv] = useState('')
  const [detailDataProv, setDetailDataProv] = useState<any>([])
  const [_inputList, _setInputList] = useState<FormInputProps[]>([...inputListAll])
  const [isModalProvinsiUsulan, setIsModalProvinsiUsulan] = useState<boolean>(false)
  const [isModalProvinsiST, setIsModalProvinsiST] = useState<boolean>(false)
  const [isModalKotaST, setIsModalKotaST] = useState<boolean>(false)
  const [isModalKotaUsulan, setIsModalKotaUsulan] = useState<boolean>(false)
  const [isModalUsulanSatker, setIsModalUsulanSatker] = useState<boolean>(false)
  const [isModalSTSatker, setIsModalSTSatker] = useState<boolean>(false)
  const [isModalPegawaiUsulan, setIsModalPegawaiUsulan] = useState<boolean>(false)
  const [isModalPegawaiST, setIsModalPegawaiST] = useState<boolean>(false)

  const [modal, setModal] = useState<any>([])
  const [type, setType] = useState<any>('')
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  for (const l of _inputList) {
    l.disabled = false
  }
  return (
    <>
      <Modal
        id='modal_usulan'
        isShow={
          type === 'usulan_provinsi'
            ? isModalProvinsiUsulan
            : type === 'usulan_kota'
            ? isModalKotaUsulan
            : type === 'usulan_pegawai'
            ? isModalPegawaiUsulan
            : false
        }
        onClose={() => {
          if (type === 'usulan_provinsi') {
            setIsModalProvinsiUsulan(false)
          } else if (type === 'usulan_kota') {
            setIsModalKotaUsulan(false)
          } else if (type === 'usulan_pegawai') {
            setIsModalPegawaiUsulan(false)
          }
        }}
        title={
          type === 'usulan_provinsi'
            ? 'List Detail Usulan Provinsi'
            : type === 'usulan_kota'
            ? 'List Detail Usulan Kota'
            : type === 'usulan_pegawai'
            ? 'List Detail Usulan Pegawai'
            : ''
        }
      >
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setDetailDataProv(val)}
          {...TableInputUsulan}
        >
          {NullProof({input: detailDataProv, params: 'data', isMap: true}).map(
            (l: any, i: number) => {
              const tableDataBuilder = {
                modal: modalConfig,
                input: {data: l, index: i},
                action: TableInputUsulan.link?.action || [],
              }
              return (
                <Fragment key={i}>
                  <TableRow {...tableDataBuilder}>
                    <TableColumn label='No.' props={{style: {width: '50px'}}}>
                      {i + 1 + ((NullProof({input: detailDataProv, params: 'page'}) || 1) - 1) * 10}
                    </TableColumn>
                    <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'pegawai.nama'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Usulan' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'HH:mm:ss',
                      })}
                    </TableColumn>
                    <TableColumn label='Deskripsi' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'deskripsi_kegiatan'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                        (ll: any, i: number) => {
                          return (
                            <div key={i}>
                              {formatDate({
                                date: NullProof({input: ll, params: 'tanggal_mulai'}),
                                dateFormat: 'dd MMMM yyyy',
                              })}{' '}
                              <br />
                              {formatDate({
                                date: NullProof({input: ll, params: 'tanggal_selesai'}),
                                dateFormat: 'dd MMMM yyyy',
                              })}
                            </div>
                          )
                        }
                      )}
                    </TableColumn>
                    <TableColumn label='Status' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                        <div>
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_irwil', isLabel: false})
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Riviu irwil :{' '}
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_irwil'})
                                  ),
                                }}
                              >
                                {'  '} {NullProof({input: l, params: 'status_irwil'})}
                              </span>
                              <br />
                            </div>
                          </div>
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_disposisi', isLabel: false})
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Riviu disposisi :{' '}
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_disposisi'})
                                  ),
                                }}
                              >
                                {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                              </span>
                              <br />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'>
                          Riviu 1 :{' '}
                          <span
                            style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}
                          >
                            {'  '} {NullProof({input: l, params: 'status'})}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status_2', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'>
                          Riviu 2 :{' '}
                          <span
                            style={{
                              color: getStatusColor(NullProof({input: l, params: 'status_2'})),
                            }}
                          >
                            {'  '} {NullProof({input: l, params: 'status_2'})}
                          </span>
                          <br />
                        </div>
                      </div>
                    </TableColumn>
                    <TableAction {...tableDataBuilder} />
                  </TableRow>
                </Fragment>
              )
            }
          )}
        </TableInput>
      </Modal>

      <Modal
        id='modal_usulan_satker'
        isShow={isModalUsulanSatker}
        onClose={() => {
          setIsModalUsulanSatker(false)
        }}
        title='List Detail Usulan Satker'
      >
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setDetailDataProv(val)}
          {...TableInputUsulan}
        >
          {NullProof({input: detailDataProv, params: 'data', isMap: true}).map(
            (l: any, i: number) => {
              const tableDataBuilder = {
                modal: modalConfig,
                input: {data: l, index: i},
                action: TableInputUsulan.link?.action || [],
              }
              return (
                <Fragment key={i}>
                  <TableRow {...tableDataBuilder}>
                    <TableColumn label='No.' props={{style: {width: '50px'}}}>
                      {i + 1 + ((NullProof({input: detailDataProv, params: 'page'}) || 1) - 1) * 10}
                    </TableColumn>
                    <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'pegawai.nama'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Usulan' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'HH:mm:ss',
                      })}
                    </TableColumn>
                    <TableColumn label='Deskripsi' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'deskripsi_kegiatan'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                        (ll: any, i: number) => {
                          return (
                            <div key={i}>
                              {formatDate({
                                date: NullProof({input: ll, params: 'tanggal_mulai'}),
                                dateFormat: 'dd MMMM yyyy',
                              })}{' '}
                              <br />
                              {formatDate({
                                date: NullProof({input: ll, params: 'tanggal_selesai'}),
                                dateFormat: 'dd MMMM yyyy',
                              })}
                            </div>
                          )
                        }
                      )}
                    </TableColumn>
                    <TableColumn label='Status' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                        <div>
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_irwil', isLabel: false})
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Riviu irwil :{' '}
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_irwil'})
                                  ),
                                }}
                              >
                                {'  '} {NullProof({input: l, params: 'status_irwil'})}
                              </span>
                              <br />
                            </div>
                          </div>
                          <div className='d-flex justify-content-center gap-2 mb-2'>
                            <div
                              className='rounded-circle'
                              style={{
                                width: '20px',
                                height: '20px',
                                minWidth: '20px',
                                backgroundColor: getStatusColor(
                                  NullProof({input: l, params: 'status_disposisi', isLabel: false})
                                ),
                              }}
                            ></div>
                            <div className='w-50'>
                              Riviu disposisi :{' '}
                              <span
                                style={{
                                  color: getStatusColor(
                                    NullProof({input: l, params: 'status_disposisi'})
                                  ),
                                }}
                              >
                                {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                              </span>
                              <br />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'>
                          Riviu 1 :{' '}
                          <span
                            style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}
                          >
                            {'  '} {NullProof({input: l, params: 'status'})}
                          </span>
                          <br />
                        </div>
                      </div>
                      <div className='d-flex justify-content-center gap-2 mb-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'status_2', isLabel: false})
                            ),
                          }}
                        ></div>
                        <div className='w-50'>
                          Riviu 2 :{' '}
                          <span
                            style={{
                              color: getStatusColor(NullProof({input: l, params: 'status_2'})),
                            }}
                          >
                            {'  '} {NullProof({input: l, params: 'status_2'})}
                          </span>
                          <br />
                        </div>
                      </div>
                    </TableColumn>
                    <TableAction {...tableDataBuilder} />
                  </TableRow>
                </Fragment>
              )
            }
          )}
        </TableInput>
      </Modal>
      <Modal
        id='modal_st'
        isShow={
          type === 'st_provinsi'
            ? isModalProvinsiST
            : type === 'st_kota'
            ? isModalKotaST
            : type === 'st_pegawai'
            ? isModalPegawaiST
            : false
        }
        onClose={() => {
          if (type === 'st_provinsi') {
            setIsModalProvinsiST(false)
          } else if (type === 'st_kota') {
            setIsModalKotaST(!isModalKotaST)
          } else if (type === 'st_pegawai') {
            setIsModalPegawaiST(false)
          }
        }}
        title={type === 'st_provinsi' ? 'List Detail ST Provinsi' : 'List Detail ST Kota'}
      >
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setDetailDataProv(val)}
          {...TableInputST}
        >
          {NullProof({input: detailDataProv, params: 'data', isMap: true}).map(
            (l: any, i: number) => {
              const tableDataBuilder = {
                modal: modalConfig,
                input: {data: l, index: i},
                action: TableInputST.link?.action || [],
              }
              return (
                <Fragment key={i}>
                  <TableRow {...tableDataBuilder}>
                    <TableColumn label='No.' props={{style: {width: '50px'}}}>
                      {i + 1 + ((NullProof({input: detailDataProv, params: 'page'}) || 1) - 1) * 10}
                    </TableColumn>
                    <TableColumn label='Nomor ST' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'nomor_surat'})}
                    </TableColumn>
                    <TableColumn label='Tanggal ST' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'HH:mm:ss',
                      })}
                    </TableColumn>
                    <TableColumn label='Kegiatan' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'nama_kegiatan'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_mulai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_selesai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}
                    </TableColumn>
                    <TableAction {...tableDataBuilder} />
                  </TableRow>
                </Fragment>
              )
            }
          )}
        </TableInput>
      </Modal>
      <Modal
        id='modal_st'
        isShow={isModalSTSatker}
        onClose={() => {
          setIsModalSTSatker(false)
        }}
        title='List Detail ST Satker'
      >
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setDetailDataProv(val)}
          {...TableInputST}
        >
          {NullProof({input: detailDataProv, params: 'data', isMap: true}).map(
            (l: any, i: number) => {
              const tableDataBuilder = {
                modal: modalConfig,
                input: {data: l, index: i},
                action: TableInputST.link?.action || [],
              }
              return (
                <Fragment key={i}>
                  <TableRow {...tableDataBuilder}>
                    <TableColumn label='No.' props={{style: {width: '50px'}}}>
                      {i + 1 + ((NullProof({input: detailDataProv, params: 'page'}) || 1) - 1) * 10}
                    </TableColumn>
                    <TableColumn label='Nomor ST' props={{style: {minWidth: '200px'}}}>
                      {NullProof({input: l, params: 'nomor_surat'})}
                    </TableColumn>
                    <TableColumn label='Tanggal ST' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'createdAt'}),
                        dateFormat: 'HH:mm:ss',
                      })}
                    </TableColumn>
                    <TableColumn label='Kegiatan' props={{style: {minWidth: '300px'}}}>
                      {NullProof({input: l, params: 'nama_kegiatan'})}
                    </TableColumn>
                    <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_mulai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}{' '}
                      <br />
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_selesai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}
                    </TableColumn>
                    <TableAction {...tableDataBuilder} />
                  </TableRow>
                </Fragment>
              )
            }
          )}
        </TableInput>
      </Modal>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={_inputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
            useServerMessage: true,
          }}
          onChange={async (e: BaseInputOnChangeProps) => {
            const dataObj = {...e._data.getObject}
            let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
            let jenis: string = dataObj?.jenis || ''
            _setInputList(dynamicInputList({data: dataObj?.usulan_tim, param: 'tipe tujuan'}))
            _setInputList(dynamicInputList({data: sumberPenugasan}))
            _setInputList(dynamicInputList({data: jenis, param: 'jenis'}))
            _setInputList(checkOnChange(e))
            if (e.type === 'onChange') {
              await onChange(e, lastData, _inputList)
              setLastData({...e._data.get})
            }
          }}
          onFeedback={(e: any) => {
            setType(e?.data?.protect)
            if (e?.data?.protect === 'st_provinsi') {
              setIsModalProvinsiST(true)
              TableInputST.route.url = `${InfoData.apiST}/${e?.data?.data?.nama}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'st_kota') {
              setIsModalKotaST(true)
              TableInputST.route.url = `${InfoData.apiST}/${e?.data?.data?.nama}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'usulan_provinsi') {
              // console.log("testt", e?.data?.data?.nama)
              setIsModalProvinsiUsulan(true)
              TableInputUsulan.route.url = `${InfoData.apiUsulan}/${e?.data?.data?.nama}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'usulan_kota') {
              setIsModalKotaUsulan(true)
              TableInputUsulan.route.url = `${InfoData.apiUsulan}/${e?.data?.data?.nama}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'st_satker') {
              setIsModalSTSatker(true)
              TableInputST.route.url = `${InfoData.apiST}/${e?.data?.data?.kode}?type=${e?.data?.protect}`
              // console.log("TableInputSTSatker", TableInputST.route.url)
            } else if (e?.data?.protect === 'usulan_satker') {
              setIsModalUsulanSatker(true)
              TableInputUsulan.route.url = `${InfoData.apiUsulan}/${e?.data?.data?.kode}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'usulan_pegawai') {
              setIsModalUsulanSatker(true)
              TableInputUsulan.route.url = `${InfoData.apiUsulan}/${e?.data?.data?.id}?type=${e?.data?.protect}`
            } else if (e?.data?.protect === 'st_pegawai') {
              setIsModalPegawaiST(true)
              TableInputST.route.url = `${InfoData.apiST}/${e?.data?.data?.id}?type=${e?.data?.protect}`
            }
          }}
          onLoad={onLoadForm}
        />
      </CardUi>
    </>
  )
}

interface HighlightRevisiOptions extends Omit<NullProofProps, 'input' | 'params'> {}

const HighlightRevisi: FC<
  {
    id: string
    list?: any[]
    options?: HighlightRevisiOptions
    revisiChild?: ReactNode
  } & WithChildren
> = ({id, list = [], children, options, revisiChild}) => {
  const [_list, _setList] = useState<any[]>([])
  useEffect(() => {
    _setList([...list])
  }, [list])
  const _id = id.split('.')
  const data = {..._list.find((e: any) => e?.id === _id[0])}
  return (
    <div className={`d-flex flex-column gap-2`}>
      <div
        className={`${
          _list?.some((e: any) => e?.id === _id[0]) ? 'bg-success text-white px-4 py-2' : ''
        }`}
      >
        {children}
      </div>
      {_list?.some((e: any) => e?.id === _id[0]) && (
        <div className='bg-danger text-white px-4 py-2'>
          {revisiChild ? (
            revisiChild
          ) : (
            <>{NullProof({input: data, params: 'revisi', ...options})}</>
          )}
        </div>
      )}
    </div>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const navigate = useNavigate()
  const reduxData = useSelector((state: any) => state.page.data)
  const reduxDispatch = useDispatch()
  const [data, setData] = useState<any>(reduxData?.form_usulan_detail || {})
  // const [modal, setModal] = useState<boolean>(false)
  const [_inputStatus, _setInputStatus] = useState<FormInputProps[]>(inputListAll)
  const [modal_irwil, setModal_irwil] = useState<boolean>(false)
  const [modal_disposisi, setModal_disposisi] = useState<boolean>(false)
  const [modal_status, setModal_status] = useState<boolean>(false)
  const [modal_status_2, setModal_status_2] = useState<boolean>(false)
  const [modal_pass, setModal_pass] = useState<boolean>(false)
  const [modal_pass_tte, setModal_pass_tte] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0)
  const {auth} = useAuth()
  const idPegawai = NullProof({input: auth?.data, params: 'pegawai_id'})
  const idUser = NullProof({input: auth?.data, params: 'id'})
  const [dataRevisi, setDataRevisi] = useState<any[]>([])

  useEffect(() => {
    reduxDispatch(clear())
    console.log(reduxData)
  }, [key])

  return (
    <TableInputDetail
      componentKey={key}
      isDummy={reduxData?.form_usulan_detail ? true : false}
      onSuccess={(val: any) => {
        setData(val)
        reduxDispatch(post({...reduxData, form_usulan_detail: val}))
      }}
      {...TableInputDetailData}
    >
      <Modal
        id='modal_status_pass'
        isShow={modal_pass}
        onClose={() => {
          setModal_pass(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputPass}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_pass(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_pass_tte'
        isShow={modal_pass_tte}
        onClose={() => {
          setModal_pass_tte(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputPass}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_pass_tte(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_irwil'
        isShow={modal_irwil}
        onClose={() => {
          setModal_irwil(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusIrwil}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_irwil(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_disposisi'
        isShow={modal_disposisi}
        onClose={() => {
          setModal_disposisi(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusdisposisi}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_disposisi(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status'
        isShow={modal_status}
        onClose={() => {
          setModal_status(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={
            NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
              ? formInputStatusPass
              : formInputStatus
          }
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_status(false)
            },
          }}
          onChange={async (e) => {
            let getStatus = e._data.getObject?.status
            _setInputStatus(dynamicInputList(getStatus))
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_2'
        isShow={modal_status_2}
        onClose={() => {
          setModal_status_2(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus2}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_status_2(false)
            },
          }}
          onChange={async (e) => {
            let getStatus = e._data.getObject?.status_2
            _setInputStatus(dynamicInputList(getStatus))
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <div className='d-flex flex-column gap-4'>
        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          toolbars={
            <>
              {NullProof({input: data, params: 'tipe_usulan'}) === 'Draft' ? (
                <ButtonKirimNaskah data={data} />
              ) : null}
              {NullProof({input: data, params: 'list_revisi', isMap: true}).length ? (
                <div className='d-flex gap-2'>
                  <select
                    className='form-select'
                    onChange={(e) => {
                      let result: any[] = []
                      if (Number(e.target.value || -1) !== -1) {
                        result = [...result, ...getObjectDifferences(data, data?.list_revisi)]
                      }
                      setDataRevisi(result)
                    }}
                  >
                    <option value={''}>Pilih Versi...</option>
                    {NullProof({input: data, params: 'list_revisi', isMap: true})?.map(
                      (l: any, i: number) => (
                        <Fragment key={i}>
                          <option value={i}>{`Versi ${i + 1}.0`}</option>
                        </Fragment>
                      )
                    )}
                  </select>
                </div>
              ) : null}
            </>
          }
        >
          <CardUi>
            <TableInputDetailColumn title={{value: 'Pengusul'}}>
              <HighlightRevisi id='pengusul.nama' list={dataRevisi}>
                {NullProof({input: data, params: 'pengusul.nama'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Role Pengusul'}}>
              <HighlightRevisi id='pengusul.user.roles.name' list={dataRevisi}>
                {NullProof({input: data, params: 'pengusul.user.roles.name'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jenis Penugasan'}}>
              <HighlightRevisi id='jenis' list={dataRevisi}>
                {NullProof({input: data, params: 'jenis'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Sumber Penugasan'}}>
              <HighlightRevisi id='sumber_penugasan' list={dataRevisi}>
                {NullProof({input: data, params: 'sumber_penugasan'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
              <HighlightRevisi id='nama_kegiatan' list={dataRevisi}>
                {NullProof({input: data, params: 'nama_kegiatan'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Deskripsi Kegiatan'}}>
              <HighlightRevisi id='deskripsi_kegiatan' list={dataRevisi}>
                {NullProof({input: data, params: 'deskripsi_kegiatan'})}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tujuan Usulan Tim'}}>
              <ol className='m-0 ps-6'>
                {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                  (l: any, i: number) => {
                    const listKota = (): string[] => {
                      let kota: string[] = []
                      if (l?.kota) {
                        kota.push(l?.kota)
                      }
                      if (l?.kota2) {
                        kota.push(l?.kota2)
                      }
                      if (l?.kota3) {
                        kota.push(l?.kota3)
                      }
                      return kota
                    }
                    return (
                      <Fragment key={i}>
                        <li>
                          <div className='d-flex flex-column gap-1'>
                            <div className='d-flex gap-2'>
                              <div className='text-capitalize'>
                                {`${NullProof({
                                  input: l,
                                  params: 'provinsi',
                                })} - ${listKota().join(' - ')}`.toLowerCase()}
                              </div>
                            </div>
                            <div className='d-flex flex-column'>
                              <div className='text-capitalize fw-bold'>Jenis Tujuan : </div>
                              {NullProof({input: l, params: 'jenis_tujuan', isLabel: false}) && (
                                <div className='text-capitalize'>
                                  - {NullProof({input: l, params: 'jenis_tujuan'})}
                                </div>
                              )}
                              {NullProof({
                                input: l,
                                params: 'jenis_tujuan_2',
                                isLabel: false,
                              }) && (
                                <div className='text-capitalize'>
                                  - {NullProof({input: l, params: 'jenis_tujuan_2'})}
                                </div>
                              )}
                              {NullProof({
                                input: l,
                                params: 'jenis_tujuan_3',
                                isLabel: false,
                              }) && (
                                <div className='text-capitalize'>
                                  - {NullProof({input: l, params: 'jenis_tujuan_3'})}
                                </div>
                              )}
                            </div>
                            <div className='d-flex flex-column'>
                              <div className='text-capitalize fw-bold'>Satuan Kerja : </div>
                              <div className='text-capitalize'>
                                {`${NullProof({input: l, params: 'satker.kode'})} - ${NullProof({
                                  input: l,
                                  params: 'satker.nama_satker',
                                })}`}
                              </div>
                            </div>
                            <div className='d-flex flex-column'>
                              <div className='text-capitalize fw-bold'>Tanggal : </div>
                              <div>
                                {`${NullProof({
                                  input: l,
                                  params: 'tanggal_mulai',
                                  type: 'date',
                                })} s/d ${NullProof({
                                  input: l,
                                  params: 'tanggal_selesai',
                                  type: 'date',
                                })}`}
                              </div>
                            </div>
                          </div>
                        </li>
                      </Fragment>
                    )
                  }
                )}
              </ol>
            </TableInputDetailColumn>
            {NullProof({input: data, params: 'file_pendukung_json', isMap: true}).map(
              (l: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <TableInputDetailColumn title={{value: `File Pendukung ${i + 1}`}}>
                      {NullProof({input: l, params: 'file_pendukung', isLabel: false}) ? (
                        <ModalButtonUi
                          options={{
                            modal: {
                              id: `modal_file_pendukung ${i + 1}`,
                              title: `File Pendukung ${i + 1}`,
                            },
                            button: {title: 'Lihat Dokumen'},
                          }}
                        >
                          <ReactPdfViewer
                            url={`/${NullProof({
                              input: l,
                              params: 'file_pendukung',
                              isLabel: false,
                            })}`}
                          />
                        </ModalButtonUi>
                      ) : (
                        '_'
                      )}
                    </TableInputDetailColumn>
                  </Fragment>
                )
              }
            )}
            {NullProof({input: data, params: 'file_pendukung_json', isMap: true}).map(
              (l: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <TableInputDetailColumn title={{value: `Keterangan File Pendukung ${i + 1}`}}>
                      <HighlightRevisi id='file_pendukung_keterangan' list={dataRevisi}>
                        {NullProof({input: l, params: 'file_pendukung_keterangan'})}
                      </HighlightRevisi>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              }
            )}
            <TableInputDetailColumn title={{value: 'Dasar'}}>
              <HighlightRevisi
                id='dasarnya'
                list={dataRevisi}
                revisiChild={
                  <>
                    {NullProof({
                      input: objectListSelector({
                        data: dataRevisi,
                        name: 'id',
                        search: 'dasarnya',
                      }),
                      params: 'dasarnya',
                      isMap: true,
                    })?.map((l: any, i: number) => (
                      <Fragment key={i}>
                        <div>
                          <span className='fw-bold'>- </span>
                          {NullProof({input: l, params: 'detail'})}
                        </div>
                      </Fragment>
                    ))}
                  </>
                }
              >
                {NullProof({input: data, params: 'dasarnya', isMap: true})?.map(
                  (l: any, i: number) => (
                    <Fragment key={i}>
                      <div>
                        <span className='fw-bold'>- </span>
                        {NullProof({input: l, params: 'detail'})}
                      </div>
                    </Fragment>
                  )
                )}
              </HighlightRevisi>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Menimbang'}}>
              {NullProof({input: data, params: 'menimbang', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div>
                      <span className='fw-bold'>- </span>
                      {NullProof({input: l, params: 'detailMenimbang'})}
                    </div>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Preview ST'}}>
              <button
                className='btn btn-primary btn-sm'
                onClick={() => navigate(RouterQueryParams(`./previewst-pdf`, query))}
              >
                Lihat Preview
              </button>
            </TableInputDetailColumn>
          </CardUi>
        </CardUi>

        <CardUi title={PageData.detail?.anggotaTim.title}>
          <TableInputDetailColumn title={{value: 'Anggota'}}>
            <button
              className='btn btn-primary btn-sm'
              onClick={() => navigate(RouterQueryParams(`./anggota-pdf`, query))}
            >
              Lihat Dokumen
            </button>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Adjustment RAB'}}>
            <button
              className='btn btn-primary btn-sm'
              onClick={() => navigate(RouterQueryParams(`./adjustment-rab`, query))}
            >
              Adjustment RAB
            </button>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Daftar Usulan Terkait'}}>
            <div className='d-flex flex-column gap-2'>
              <div>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => navigate(RouterQueryParams(`./list-usulan-pegawai`, query))}
                >
                  Pegawai yang sama
                </button>
              </div>
              <div>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => navigate(RouterQueryParams(`./list-usulan-tujuan`, query))}
                >
                  Tujuan Satker yang sama
                </button>
              </div>
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Daftar Surat Tugas Terkait'}}>
            <div className='d-flex flex-column gap-2'>
              <div>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => navigate(RouterQueryParams(`./list-usulan-tugas-pegawai`, query))}
                >
                  Pegawai yang sama
                </button>
              </div>
              <div>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => navigate(RouterQueryParams(`./list-usulan-tugas-tujuan`, query))}
                >
                  Tujuan Satker yang sama
                </button>
              </div>
            </div>
          </TableInputDetailColumn>
        </CardUi>
        {/* <CardUi title='List Usulan dan ST dengan Nama dan tujuan yang Sama'>
            <Accordion
              enabled
              title='List Usulan Pegawai Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div className='mt-4'>
                      <TableListUsulanByDate
                        dataInput={l}
                        pegawaiId={data?.pegawai_id}
                        indexs={i}
                      />
                    </div>
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Usulan Tujuan Satker Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Surat Tugas Pegawai Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
            <Accordion
              enabled
              title='List Surat Tugas Tujuan Satker Sama'
              props={{
                body: {
                  className: 'pb-4',
                },
              }}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListStTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </Accordion>
          </CardUi> */}
        {/* <TabsUi
          title='List Usulan dan ST dengan Nama dan tujuan yang Sama'
          tabs={[
            {
              id: 'list_usulan_pegaawai_sama',
              title: 'List Usulan Pegawai Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <div className='mt-4'>
                          <TableListUsulanByDate
                            dataInput={l}
                            pegawaiId={data?.pegawai_id}
                            indexs={i}
                          />
                        </div>
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_usulan_tujuan_satker_sama',
              title: 'List Usulan Tujuan Satker Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListTujuanSatker dataInput={l} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_st_pegawai_sama',
              title: 'List Surat Tugas Pegawai Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
            {
              id: 'list_st_tujuan_satker_sama',
              title: 'List Surat Tugas Tujuan Satker Sama',
              children: (
                <>
                  {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                    (l: any, i: number) => (
                      <Fragment key={i}>
                        <TableListStTujuanSatker dataInput={l} indexs={i} />
                      </Fragment>
                    )
                  )}
                </>
              ),
            },
          ]}
        /> */}
        {/* <CardUi title='List Usulan Pegawai Sama'>
            {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <TableListUsulanByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
              </Fragment>
            ))}
        </CardUi>
        <CardUi title='List Usulan Tujuan Satker Sama'>
          {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TableListTujuanSatker dataInput={l} indexs={i} />
            </Fragment>
          ))}
        </CardUi>
        <CardUi title='List Surat Tugas Pegawai Sama'>
            {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
              <Fragment key={i}>
                <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
              </Fragment>
              ))}
        </CardUi>
        <CardUi title='List Surat Tugas Tujuan Satker Sama'>
          {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TableListStTujuanSatker dataInput={l} indexs={i} />
            </Fragment>
          ))}
        </CardUi> */}

        <CardUi title={PageData.detail?.anggaranTim.title}>
          <TableInputDetailColumn title={{value: 'Anggaran Tim'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'usulan_tim', isMap: true})
                ?.map((l: any, i: number) => l.totalRAB)
                ?.reduce((acc: any, curr: any) => acc + curr, 0)
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'MAK'}}>
            <HighlightRevisi id='mak' list={dataRevisi}>
              {NullProof({input: data, params: 'mak'})}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama Program'}}>
            {NullProof({input: data, params: 'detail_mak.namaProgram'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
            {NullProof({input: data, params: 'detail_mak.namaKegiatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Sisa Anggaran'}}>
            {ConvertCurrency(
              NullProof({input: data, params: 'detail_mak.anggaran'}) -
                NullProof({input: data, params: 'usulan_tim', isMap: true})
                  ?.map((l: any, i: number) => l.totalRAB)
                  ?.reduce((acc: any, curr: any) => acc + curr, 0)
            )}
          </TableInputDetailColumn>
          {/* <TableInputDetailColumn title={{value: 'Sisa Anggaran'}}>
                {ConvertCurrency(
                  NullProof({input: data, params: 'anggaran'}) -
                    NullProof({input: data, params: 'usulan_tim', isMap: true})
                      ?.map((l: any, i: number) => l.totalRAB)
                      ?.reduce((acc: any, curr: any) => acc + curr, 0)
                )}
              </TableInputDetailColumn> */}
        </CardUi>

        <CardUi title={PageData.detail?.review.title}>
          {NullProof({input: data, params: 'type_pengusul'}) === 'auditor' && (
            <div>
              <TableInputDetailColumn title={{value: 'Status Inspektur Wilayah'}}>
                <div className='d-flex align-items-center gap-2'>
                  <HighlightRevisi id='status_irwil' list={dataRevisi}>
                    <div>{NullProof({input: data, params: 'status_irwil'})}</div>
                  </HighlightRevisi>
                  <div>
                    {NullProof({input: data, params: 'tipe_usulan'}) === 'Terbit' &&
                      (idPegawai === NullProof({input: data, params: 'irwil_id'}) ||
                        idPegawai === NullProof({input: data, params: 'irwil.pegawai_id'}) ||
                        idUser === 1) && (
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal_irwil(true)
                          }}
                        >
                          Ubah Status
                        </button>
                      )}
                  </div>
                </div>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'PenandaTangan Inspektur Wilayah'}}>
                <HighlightRevisi id='irwil.nama' list={dataRevisi}>
                  {NullProof({input: data, params: 'irwil.nama'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Inspektur Wilayah'}}>
                <HighlightRevisi
                  id='tgl_ttd_irwil'
                  list={dataRevisi}
                  options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
                >
                  {formatDate({
                    date: NullProof({input: data, params: 'tgl_ttd_irwil'}),
                    dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                  })}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Catatan Inspektur Wilayah'}}>
                <HighlightRevisi id='catatan' list={dataRevisi}>
                  {NullProof({input: data, params: 'catatan_irwil'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Status Disposisi'}}>
                <div className='d-flex align-items-center gap-2'>
                  <HighlightRevisi id='status_disposisi' list={dataRevisi}>
                    <div>{NullProof({input: data, params: 'status_disposisi'})}</div>
                  </HighlightRevisi>
                  {(idPegawai === NullProof({input: data, params: 'disposisi_id'}) ||
                    idPegawai === NullProof({input: data, params: 'disposisi.pegawai_id'}) ||
                    idUser === 1) &&
                    NullProof({input: data, params: 'status_irwil', isLabel: false}) &&
                    data.status_irwil !== 'Ditolak' && (
                      <div>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={() => {
                            setModal_disposisi(true)
                          }}
                        >
                          Ubah Status
                        </button>
                      </div>
                    )}
                </div>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'PenandaTangan Disposisi'}}>
                <HighlightRevisi id='disposisi.nama' list={dataRevisi}>
                  {NullProof({input: data, params: 'disposisi.nama'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan Disposisi'}}>
                <HighlightRevisi
                  id='tgl_ttd_disposisi'
                  list={dataRevisi}
                  options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
                >
                  {formatDate({
                    date: NullProof({input: data, params: 'tgl_ttd_disposisi'}),
                    dateFormat: 'dd MMMM yyyy, HH:mm:ss',
                  })}
                </HighlightRevisi>
              </TableInputDetailColumn>
              <TableInputDetailColumn title={{value: 'Catatan Disposisi'}}>
                <HighlightRevisi id='catatan_disposisi' list={dataRevisi}>
                  {NullProof({input: data, params: 'catatan_disposisi'})}
                </HighlightRevisi>
              </TableInputDetailColumn>
            </div>
          )}
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Status Reviu Ses/Kasub'
                  : 'Status Reviu 1'
              }`,
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              <HighlightRevisi id='status' list={dataRevisi}>
                <div>{NullProof({input: data, params: 'status'})}</div>
              </HighlightRevisi>
              <div>
                {NullProof({input: data, params: 'tipe_usulan'}) === 'Terbit' &&
                  (idPegawai === NullProof({input: data, params: 'penandatangan_st'}) ||
                    idPegawai === NullProof({input: data, params: 'penandatangan.pegawai_id'}) ||
                    idUser === 1) && (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setKey((p) => p + 1)
                        setModal_status(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  )}
              </div>
            </div>
          </TableInputDetailColumn>
          {NullProof({input: data, params: 'status', isLabel: false}) &&
            data.status !== 'Ditolak' &&
            NullProof({input: data, params: 'type_pengusul'}) === 'auditor' && (
              <TableInputDetailColumn title={{value: 'Paraf Usulan'}}>
                <div className='d-flex align-items-center gap-2'>
                  <HighlightRevisi id='status' list={dataRevisi}>
                    <div>
                      {NullProof({input: data, params: 'status_paraf'}) === true
                        ? 'Sudah'
                        : 'Belum'}
                    </div>
                  </HighlightRevisi>
                  <div>
                    {(idPegawai === NullProof({input: data, params: 'penandatangan_st'}) ||
                      idPegawai === NullProof({input: data, params: 'penandatangan.pegawai_id'}) ||
                      idUser === 1) && (
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setModal_pass(true)
                        }}
                      >
                        Ubah
                      </button>
                    )}
                  </div>
                </div>
              </TableInputDetailColumn>
            )}
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'PenandaTangan Ses/Kasub'
                  : 'PenandaTangan Reviu 1'
              }`,
            }}
          >
            <HighlightRevisi id='penandatangan.nama' list={dataRevisi}>
              {NullProof({input: data, params: 'penandatangan.nama'})}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Tanggal Tanda Tangan  Ses/Kasub'
                  : 'Tanggal Tanda Tangan  Reviu 1'
              }`,
            }}
          >
            <HighlightRevisi
              id='tgl_ttd'
              list={dataRevisi}
              options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
            >
              {formatDate({
                date: NullProof({input: data, params: 'tgl_ttd'}),
                dateFormat: 'dd MMMM yyyy, HH:mm:ss',
              })}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Catatan Reviu 1 Sekretaris'
                  : 'Catatan Reviu 1'
              }`,
            }}
          >
            <HighlightRevisi id='catatan' list={dataRevisi}>
              {NullProof({input: data, params: 'catatan'})}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Status Insp/Irjen'
                  : 'Status Reviu 2'
              }`,
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              <HighlightRevisi id='status_2' list={dataRevisi}>
                <div>{NullProof({input: data, params: 'status_2'})}</div>
              </HighlightRevisi>
              {(idPegawai === NullProof({input: data, params: 'penandatangan_st_2'}) ||
                idPegawai === NullProof({input: data, params: 'penandatangan_2.pegawai_id'}) ||
                idUser === 1) &&
                data.status === 'Disetujui' && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal_status_2(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'TTE Insp/Irjen'
                  : 'TTE Usulan'
              }`,
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              <HighlightRevisi id='status' list={dataRevisi}>
                <div>
                  {NullProof({input: data, params: 'status_tte'}) === true ? 'Sudah' : 'Belum'}
                </div>
              </HighlightRevisi>
              <div>
                {(idPegawai === NullProof({input: data, params: 'penandatangan_st_2'}) ||
                  idPegawai === NullProof({input: data, params: 'penandatangan_2.pegawai_id'}) ||
                  idUser === 1) &&
                  data.status_2 === 'Disetujui' && (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal_pass_tte(true)
                      }}
                    >
                      Ubah
                    </button>
                  )}
              </div>
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'PenandaTangan Irjen'
                  : 'PenandaTangan Reviu 2'
              }`,
            }}
          >
            <HighlightRevisi id='penandatangan_2.nama' list={dataRevisi}>
              {NullProof({input: data, params: 'penandatangan_2.nama'})}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Tanggal Tanda Tangan Irjen'
                  : 'Tanggal Tanda Tangan Reviu 2'
              }`,
            }}
          >
            <HighlightRevisi
              id='tgl_ttd_2'
              list={dataRevisi}
              options={{type: 'date', option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}}}}
            >
              {formatDate({
                date: NullProof({input: data, params: 'tgl_ttd_2'}),
                dateFormat: 'dd MMMM yyyy, HH:mm:ss',
              })}
            </HighlightRevisi>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: `${
                NullProof({input: data, params: 'type_pengusul'}) === 'auditor'
                  ? 'Catatan Irjen'
                  : 'Catatan Reviu 2'
              }`,
            }}
          >
            <HighlightRevisi id='catatan_2' list={dataRevisi}>
              {NullProof({input: data, params: 'catatan_2'})}
            </HighlightRevisi>
          </TableInputDetailColumn>
        </CardUi>
      </div>
    </TableInputDetail>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

const PdfWrapperPreviewST: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [result, setResult] = useState<any>([])
  // let resultArray: any[] = []
  // console.log("result", result)

  return (
    <>
      <CardUi title={''}>
        <TableInputDetail
          onSuccess={(val: any) => {
            setData(val)
            // console.log("vallPDFFF", val)
            const resultArray = NullProof({input: val, params: 'usulan_tim', isMap: true})?.map(
              (l: any, i: number) => ({
                ...val,
                usulan_tim: l,
              })
            )
            setResult(resultArray)
            // console.log("result", result)
          }}
          {...TableInputDetailData}
        >
          {result?.map((l: any, i: number) => {
            // console.log('isi l', l)
            return (
              <div key={i}>
                <PdfPageViewer
                  // route={RouterQueryParams(`${InfoData.api}/:id`, query)}
                  PageData={{
                    breadcrumb: PageData.pdfPreviewST?.page.breadcrumb.data || [],
                    title: PageData.pdfPreviewST?.page.breadcrumb.title || '',
                  }}
                  PdfLayout={PdfLayoutPreviewST}
                  PdfData={l}
                  justPreview={true}
                />
              </div>
            )
          })}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const AdjustmentRabWrapper: FC = () => {
  const query = useParams()
  console.log('usePrams', query)
  const [data, setData] = useState<any>({})
  const [formLock, setFormLock] = useState<boolean>(false)
  let _inputList = [...inputListAll]
  const onInputChange = (e: any) => {
    let allStatus: boolean = false
    if (e?.type === 'onLoad') {
      const apiData = e?._apiData?.server
      // console.log("apiData", apiData)
      // console.log("usulan_tim_inputttttttttttt", formInputListSelector({formInputList: _inputList, name: 'usulan_tim'}))
      // const status = NullProof({input: apiData, params: 'status'})
      // const status2 = NullProof({input: apiData, params: 'status_2'})
      // if (status === 'Disetujui' && status2 === 'Disetujui') {
      //   allStatus = true
      //   for (const l of _inputList) {
      //     l.disabled = true
      //   }
      // } else if (
      //   status === 'Revisi' ||
      //   status2 === 'Revisi' ||
      //   status === 'Disetujui' ||
      //   status2 === 'Disetujui'
      // ) {
      //   allStatus = false
      //   for (const l of _inputList) {
      //     l.disabled = true
      //   }
      //   formInputListSelector({formInputList: _inputList, name: 'usulan_tim'}).disabled = false
      // } else {
      //   allStatus = false
      //   for (const l of _inputList) {
      //     l.disabled = false
      //   }
      // }
    }
    return allStatus
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.adjusmentRAB.page.breadcrumb.data}>
        {PageData.adjusmentRAB.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.adjusmentRAB.page.breadcrumb.title} backButton>
        <FormInput
          input={inputListAdjusment}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id?typeform=rab`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={(e) => {
            const getObject = e._data.getObject
            const apiData = e._apiData.server
            let data = {...e._data.get}
            let listPegawai: any = {}
            getObject?.usulan_tim[0]?.kepada?.forEach((l: any, i: number) => {
              const infoPegawai = apiData?.usulan_tim[0]?.kepada[i]
              const namaPegawai = infoPegawai?.pegawai?.nama
              listPegawai = {
                ...listPegawai,
                [`usulan_tim_kepada_1_nama_pegawai_${i + 1}`]: namaPegawai,
              }
            })
            e.resetForm({values: {...data, ...listPegawai}})
          }}
          // isLocked={formLock}
          // onChange={async (e: BaseInputOnChangeProps) => {
          //   const dataObj = {...e._data.getObject}
          //   let sumberPenugasan: string = dataObj?.sumber_penugasan || ''
          //   let jenis: string = dataObj?.jenis || ''
          //   console.log("eeeeeeeeeeeeee", e)
          //   // let jenis_tujuan: string = dataObj?.jenis_tujuan || ''

          //   // if (e.type === 'onInit') {
          //   //   await onChange(e, lastData, _inputList)
          //   // }
          //   if (e.type === 'onLoad') {
          //     const lock = onInputChange(e)
          //     setFormLock(lock)
          //   }
          // }}
        />
      </CardUi>
    </>
  )
}

const MainWrapperListUsulan: FC<{
  type: 'pegawai' | 'tujuan' | 'tugas-pegawai' | 'tugas-tujuan'
}> = ({type = 'pegawai'}) => {
  const reduxData = useSelector((state: any) => state.page.data)
  const reduxDispatch = useDispatch()
  const [data, setData] = useState<any>(reduxData?.form_usulan_detail || {})
  const pageDataList = {
    pegawai: PageData.listUsulanPegawai.page,
    tujuan: PageData.listUsulanTujuan.page,
    'tugas-pegawai': PageData.listUsulanTugasPegawai.page,
    'tugas-tujuan': PageData.listUsulanTugasTujuan.page,
  }
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(pageDataList[type].breadcrumb.data)}>
        {pageDataList[type].breadcrumb.title}
      </PageTitle>
      <CardUi title={pageDataList[type].title} backButton>
        <TableInputDetail
          isDummy={reduxData?.form_usulan_detail ? true : false}
          onSuccess={(val: any) => {
            setData(val)
            reduxDispatch(post({...reduxData, form_usulan_detail: val}))
          }}
          {...TableInputDetailData}
        >
          {type === 'pegawai' && (
            <>
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div className='mt-4'>
                      <TableListUsulanByDate
                        dataInput={l}
                        pegawaiId={data?.pegawai_id}
                        indexs={i}
                      />
                    </div>
                  </Fragment>
                )
              )}
            </>
          )}
          {type === 'tujuan' && (
            <>
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </>
          )}
          {type === 'tugas-pegawai' && (
            <>
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListSTByDate dataInput={l} pegawaiId={data?.pegawai_id} indexs={i} />
                  </Fragment>
                )
              )}
            </>
          )}
          {type === 'tugas-tujuan' && (
            <>
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableListStTujuanSatker dataInput={l} indexs={i} />
                  </Fragment>
                )
              )}
            </>
          )}
        </TableInputDetail>
      </CardUi>
    </>
  )
}

export {
  MainWrapper,
  UbahWrapper,
  UbahWrapperRevisi,
  TambahWrapper,
  DetailWrapper,
  PdfWrapper,
  PdfWrapperPreviewST,
  AdjustmentRabWrapper,
  MainWrapperListUsulan,
}
