import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {ID, Response} from '../../_metronic/helpers'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL

const axiosInstance = axios

axiosInstance.interceptors.response.use(
  (response) => {
    AxiosState(response)
    return response
  },
  (error) => {
    return error
  }
)
axiosInstance.interceptors.request.use(
  (response) => {
    return response
  },
  (error) => {
    return error
  }
)

const popupAlertSession = async ({refresh = false}: {refresh?: boolean}) => {
  await Swal.fire({
    icon: 'error',
    title: 'Sesi login sudah habis',
  })
  if (refresh) {
    window.location.replace('/')
    window.location.reload()
  }
}

const AxiosState = (result: any) => {
  let _result = {data: {status: false, message: ''}}
  if (result?.status === 401 || result?.message?.includes('code 401')) {
    popupAlertSession({refresh: true})
  } else if (result?.code === 'ERR_NETWORK' || result?.code === 'ERR_BAD_REQUEST') {
  } else {
    _result = result
  }
  return _result
}

const getData = async (query: string, url: string, options?: AxiosRequestConfig): Promise<any> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axiosInstance.get(`${requestUrl}?${query}`, options)
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result.data
}

const getDetail = async (id: ID, url: string): Promise<any | undefined> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axios
      .get(`${requestUrl}`)
      .then((response: AxiosResponse<Response<any>>) => response)
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result.data
}

const postData = async (data: any, url: string): Promise<any | undefined> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axios
      .post(requestUrl, data)
      .then((response: AxiosResponse<Response<any>>) => response)
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result.data
}

const postGenerateUploadUrl = async (data: any, url: string): Promise<any | undefined> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axiosInstance.post(requestUrl, data).then((response: any) => response)
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result.data
}

const putData = async (data: any, url: string): Promise<any | undefined> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axios
      .put(`${requestUrl}`, data)
      .then((response: AxiosResponse<Response<any>>) => response)
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result.data
}

const deleteData = async (id: ID, url: string): Promise<void> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    result = await axiosInstance.delete(`${requestUrl}/${id}`).then(() => {})
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result
}

const deleteArrayData = async (dataId: Array<ID>, url: string): Promise<void> => {
  const requestUrl = API_URL + url
  let result: any
  try {
    const requests = dataId.map((id) => axiosInstance.delete(`${requestUrl}/${id}`))
    result = await axios.all(requests).then(() => {})
  } catch (e) {
    // popupAlertSession({refresh: true})
  }
  AxiosState(result)
  return result
}

export {getData, getDetail, postData, putData, deleteData, deleteArrayData, postGenerateUploadUrl}
