import React, {
  createContext,
  FC,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import ReactIcon, {ReactIconList} from './ReactIcon'
import * as Icons from 'react-icons/ri'
import {Link, LinkProps, useLocation, useNavigate} from 'react-router-dom'
import {
  NullProof,
  getApiPath,
  getPaginationStaticData,
  getRandomColor,
  letterAvatar,
} from './AppFunction'
import Modal, {ModalUiProps} from './Modal'
import {ReactPdfViewer} from './ReactPdf'
import {getData} from './FormAxios'
import Pagination from './Pagination'
import {DrawerComponent, MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {debounce} from 'lodash'

interface CardUiProps {
  title?: string
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  propsHeader?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  propsBody?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  toolbars?: ReactNode
  backButton?: boolean
}

const CardUi: FC<CardUiProps & WithChildren> = ({
  title,
  props,
  propsBody,
  propsHeader,
  children,
  toolbars,
  backButton,
}) => {
  const navigate = useNavigate()
  return (
    <div {...props} className={`card card-flush h-100 ${props?.className}`}>
      <div {...propsHeader} className={`card-header py-5 ${propsHeader?.className || ''}`}>
        {(title || toolbars || backButton) && (
          <div className='card-title d-flex flex-row justify-content-between w-100'>
            <div className='d-flex gap-2 align-items-center'>
              {(backButton || title?.toLowerCase().includes('detail')) && (
                <button
                  type='button'
                  className='btn btn-sm btn-primary btn-icon'
                  onClick={() => navigate(-1)}
                >
                  <ReactIcon icon='RiArrowLeftLine' props={{className: 'fs-2'}} />
                </button>
              )}
              {title}
            </div>
            {toolbars}
          </div>
        )}
        <div {...propsBody} className={`card-body px-0 w-100 ${propsBody?.className || ''}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export interface DropdownUiMenuPropsV1 {
  id?: string
  title?: string
  link?: string
  info?: {
    icon?: keyof typeof Icons
    title?: string
  }
  isFeedback?: boolean
  isHeader?: boolean
  submenu?: DropdownUiMenuPropsV1[]
}
interface DropdownUiPropsV1 {
  iconOnly?: boolean
  icon?: keyof typeof Icons
  title?: string
  onFeedback?: any
  submenu?: DropdownUiMenuPropsV1[]
  buttonClassName?: string
}

const SubmenuV1: FC<{l: DropdownUiMenuPropsV1; onFeedback?: any}> = ({l, onFeedback}) => {
  return (
    <>
      {(l.submenu?.length || 0) > 0 ? (
        <>
          <div
            className='menu-item px-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='center, top'
          >
            <Link to='#' className='menu-link px-3'>
              <span className='menu-title'>{l.title}</span>
              <span className='menu-arrow'></span>
            </Link>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
              data-kt-menu='true'
            >
              {l.submenu?.map((lsub, isub) => (
                <Fragment key={isub}>
                  <SubmenuV1 l={lsub} onFeedback={onFeedback} />
                </Fragment>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='menu-item px-3'>
            {l.isFeedback ? (
              <div
                className='menu-link flex-stack px-3'
                onClick={() => {
                  onFeedback && onFeedback({props: l})
                }}
              >
                <SubmenuItemContentV1 l={l} />
              </div>
            ) : l.isHeader ? (
              <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                {l.title || 'Menu'}
              </div>
            ) : (
              <Link to={l.link || '#'} className='menu-link px-3'>
                <SubmenuItemContentV1 l={l} />
              </Link>
            )}
          </div>
        </>
      )}

      {l.submenu?.map((lsub, isub) => (
        <Fragment key={isub}>
          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
            {(lsub.submenu?.length || 0) > 0 ? (
              <>{<SubmenuV1 l={lsub} onFeedback={onFeedback} />}</>
            ) : (
              <div className='menu-item px-3'>
                {lsub.isFeedback ? (
                  <div
                    className='menu-link flex-stack px-3'
                    onClick={() => {
                      onFeedback && onFeedback({props: lsub})
                    }}
                  >
                    <SubmenuItemContentV1 l={l} />
                  </div>
                ) : lsub.isHeader ? (
                  <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                    {lsub.title || 'Menu'}
                  </div>
                ) : (
                  <Link to={lsub.link || '#'} className='menu-link px-3'>
                    <SubmenuItemContentV1 l={lsub} />
                  </Link>
                )}
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </>
  )
}

const SubmenuItemContentV1: FC<{l: DropdownUiMenuPropsV1}> = ({l}) => {
  return (
    <>
      {l.title}
      {l.info && (
        <ReactIcon icon={l.info?.icon || 'RiInformationLine'} props={{title: l.info?.title}} />
      )}
    </>
  )
}

const DropdownUiV1: FC<DropdownUiPropsV1> = ({
  title,
  iconOnly,
  icon = 'RiMoreLine',
  submenu,
  onFeedback,
  buttonClassName,
}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <>
      {(submenu?.length || 0) > 0 ? (
        <div>
          <button
            className={`btn ${iconOnly ? 'btn-icon' : ''} ${
              buttonClassName ? buttonClassName : 'btn-bg-light btn-active-color-primary text-dark'
            }`}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <ReactIcon icon={icon} props={{className: 'fs-2'}} />
            {!iconOnly && <span className='ms-3'>{title || 'Menu'}</span>}
          </button>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                {title || 'Menu'}
              </div>
            </div>
            {submenu?.map((l, i) => (
              <Fragment key={i}>
                {(l.submenu?.length || 0) > 0 ? (
                  <>
                    <div
                      className='menu-item px-3'
                      data-kt-menu-trigger='hover'
                      data-kt-menu-placement='left-start'
                      data-kt-menu-flip='center, top'
                    >
                      <Link to='#' className='menu-link px-3'>
                        <span className='menu-title'>{l.title}</span>
                        <span className='menu-arrow'></span>
                      </Link>
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
                        data-kt-menu='true'
                      >
                        {l.submenu?.map((lsub, isub) => (
                          <Fragment key={isub}>
                            {<SubmenuV1 l={lsub} onFeedback={onFeedback} />}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='menu-item px-3'>
                    {l.isFeedback ? (
                      <div
                        className='menu-link flex-stack px-3'
                        onClick={() => {
                          onFeedback && onFeedback({props: l})
                        }}
                      >
                        <SubmenuItemContentV1 l={l} />
                      </div>
                    ) : l.isHeader ? (
                      <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                        {l.title || 'Menu'}
                      </div>
                    ) : (
                      <Link to={l.link || '#'} className='menu-link flex-stack px-3'>
                        <SubmenuItemContentV1 l={l} />
                      </Link>
                    )}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

interface DropdownUiMenuClassNameProps {
  menuSub?: string
  menuContent?: string
  menuItem?: string
  menuLink?: string
  menuIcon?: string
}

export interface DropdownUiMenuPropsV2 {
  id?: string
  idMenu?: string
  i18n?: string
  protect?: string
  hide?: boolean
  active?: boolean
  className?: string
  title?: string
  type?: 'link' | 'header' | 'feedback'
  route?: string
  info?: {
    icon?: keyof typeof Icons
    iconProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    children?: ReactNode
    title?: string
  }
  linkProps?: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>
  submenu?: DropdownUiMenuPropsV2[]
}
interface DropdownUiPropsV2 {
  loading?: boolean
  iconOnly?: boolean
  icon?: keyof typeof Icons
  iconClassName?: string
  title?: string
  onFeedback?: ({props}: {props: DropdownUiMenuPropsV2}) => void
  submenu?: DropdownUiMenuPropsV2[]
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  buttonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  menuClassName?: DropdownUiMenuClassNameProps
  menuProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  children?: ReactNode
  ktMenuProps?: {
    [key: string]: string
  }
  onMenuLoad?: () => void
}

const defaultMenuClassName = {
  menuSub: 'menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3',
  menuLink: 'flex-stack px-3 fs-6',
  menuItem: 'px-3',
  menuContent: 'text-muted pb-2 px-3 fs-7 text-uppercase',
  menuIcon: 'fs-2',
}

const SubmenuV2: FC<{
  l: DropdownUiMenuPropsV2
  menuClassName?: DropdownUiMenuClassNameProps
  onFeedback?: any
}> = ({l, onFeedback, menuClassName}) => {
  const _type = l.type || 'link'
  const _isFeedback = _type === 'feedback'
  const _isHeader = _type === 'header'
  return (
    <>
      {(l.submenu?.length || 0) > 0 ? (
        <>
          <div
            className={`menu-item ${menuClassName?.menuItem || defaultMenuClassName.menuItem}`}
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='center, top'
          >
            <Link
              to='#'
              className={`menu-link flex-stack px-3 ${
                menuClassName?.menuLink || defaultMenuClassName.menuLink
              }`}
            >
              <span className='menu-title'>{l.title}</span>
              <ReactIcon
                icon='RiArrowRightSLine'
                props={{className: menuClassName?.menuIcon || defaultMenuClassName.menuIcon}}
              />
            </Link>
            <div
              className={`menu menu-sub menu-sub-dropdown menu-column ${
                menuClassName?.menuSub || defaultMenuClassName.menuSub
              }`}
              data-kt-menu='true'
            >
              {l.submenu?.map((lsub, isub) => (
                <Fragment key={isub}>
                  <SubmenuV2 l={lsub} onFeedback={onFeedback} />
                </Fragment>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`menu-item ${menuClassName?.menuItem || defaultMenuClassName.menuItem}`}>
            {_isFeedback ? (
              <div
                className={clsx(
                  `menu-link ${menuClassName?.menuLink || defaultMenuClassName.menuLink}`,
                  {
                    active: l.active,
                  }
                )}
                onClick={() => {
                  onFeedback && onFeedback({props: l})
                }}
              >
                <SubmenuItemContentV2 l={l} />
              </div>
            ) : _isHeader ? (
              <div
                className={`menu-content ${
                  menuClassName?.menuContent || defaultMenuClassName.menuContent
                }`}
              >
                {l.title || 'Menu'}
              </div>
            ) : (
              <Link
                to={l.route || '#'}
                className={`menu-link ${menuClassName?.menuLink || defaultMenuClassName.menuLink}}`}
              >
                <SubmenuItemContentV2 l={l} />
              </Link>
            )}
          </div>
        </>
      )}

      {l.submenu?.map((lsub, isub) => {
        const _typeSub = lsub.type || 'link'
        const _isFeedbackSub = _typeSub === 'feedback'
        const _isHeaderSub = _typeSub === 'header'
        return (
          <Fragment key={isub}>
            <div
              className={`menu-sub menu-sub-dropdown ${
                menuClassName?.menuSub || defaultMenuClassName.menuSub
              }`}
            >
              {(lsub.submenu?.length || 0) > 0 ? (
                <>{<SubmenuV2 l={lsub} onFeedback={onFeedback} />}</>
              ) : (
                <div
                  className={`menu-item ${
                    menuClassName?.menuItem || defaultMenuClassName.menuItem
                  }}`}
                >
                  {_isFeedbackSub ? (
                    <div
                      className={clsx(
                        `menu-link ${menuClassName?.menuLink || defaultMenuClassName.menuLink}`,
                        {
                          active: l.active,
                        }
                      )}
                      onClick={() => {
                        onFeedback && onFeedback({props: lsub})
                      }}
                    >
                      <SubmenuItemContentV2 l={l} />
                    </div>
                  ) : _isHeaderSub ? (
                    <div
                      className={`menu-content ${
                        menuClassName?.menuContent || defaultMenuClassName.menuContent
                      }`}
                    >
                      {lsub.title || 'Menu'}
                    </div>
                  ) : (
                    <Link
                      to={lsub.route || '#'}
                      className={`menu-link ${
                        menuClassName?.menuLink || defaultMenuClassName.menuLink
                      }}`}
                    >
                      <SubmenuItemContentV2 l={lsub} />
                    </Link>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        )
      })}
    </>
  )
}

const SubmenuItemContentV2: FC<{
  l: DropdownUiMenuPropsV2
  menuClassName?: DropdownUiMenuClassNameProps
}> = ({l, menuClassName}) => {
  return (
    <>
      <div className={`${l.className || 'fs-6'}`}>{l.title}</div>
      {l.info && (
        <>
          {l.info.children ? (
            <>{l.info.children}</>
          ) : (
            <>
              {l.info?.icon && (
                <div>
                  <ReactIcon
                    icon={l.info?.icon || 'RiInformationLine'}
                    props={{
                      title: l.info?.title,
                      className:
                        l.info.iconProps?.className ||
                        menuClassName?.menuIcon ||
                        defaultMenuClassName.menuIcon,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

const DropdownUiV2: FC<DropdownUiPropsV2> = ({
  title,
  iconOnly,
  icon = 'RiMoreLine',
  iconClassName,
  submenu,
  onFeedback,
  props,
  buttonProps,
  menuProps,
  onMenuLoad,
  children,
  loading,
  menuClassName,
  ktMenuProps,
}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [submenu])
  const _menuClassName = menuClassName || defaultMenuClassName
  const _ktMenuProps = ktMenuProps || {}
  return (
    <div {...props}>
      <ButtonLoading
        loading={loading}
        ktMenuProps={{
          'data-kt-menu-trigger': _ktMenuProps['data-kt-menu-trigger'] || 'click',
          'data-kt-menu-placement': _ktMenuProps['data-kt-menu-placement'] || 'bottom-end',
          'data-kt-menu-flip': _ktMenuProps['data-kt-menu-flip'] || 'top-end',
          ..._ktMenuProps,
        }}
        disabled={buttonProps?.disabled}
        props={{
          className: `btn ${iconOnly ? 'btn-icon' : ''} ${
            buttonProps?.className ||
            'btn-bg-light btn-active-color-primary d-flex align-items-center'
          }`,
          style: buttonProps?.style,
          onClick: () => {
            onMenuLoad && onMenuLoad()
          },
        }}
      >
        <div>
          <ReactIcon icon={icon} props={{className: iconClassName || 'fs-2'}} />
        </div>
        <div>{!iconOnly && <span className='ms-3'>{title || 'Menu'}</span>}</div>
      </ButtonLoading>
      <div
        className={`menu menu-sub menu-sub-dropdown menu-column ${
          _menuClassName.menuSub || defaultMenuClassName.menuSub
        }`}
        data-kt-menu='true'
      >
        <div className={`menu-item ${_menuClassName.menuItem || defaultMenuClassName.menuItem}`}>
          {(!iconOnly || (iconOnly && title)) && (
            <div
              className={`menu-content ${
                _menuClassName.menuContent || defaultMenuClassName.menuContent
              }`}
            >
              {title || 'Menu'}
            </div>
          )}
        </div>
        {children ? (
          <div className={`menu-item ${_menuClassName.menuItem || defaultMenuClassName.menuItem}`}>
            {children}
          </div>
        ) : (
          <>
            {(submenu?.length || 0) > 0 &&
              submenu?.map((l, i) => {
                const _type = l.type || 'link'
                const _isFeedback = _type === 'feedback'
                const _isHeader = _type === 'header'
                return (
                  <Fragment key={i}>
                    <>
                      {Object.keys(l)?.length > 0 && (
                        <>
                          {(l.submenu?.length || 0) > 0 ? (
                            <>
                              <div
                                className={`menu-item ${
                                  _menuClassName.menuItem || defaultMenuClassName.menuItem
                                }`}
                                data-kt-menu-trigger='hover'
                                data-kt-menu-placement='left-start'
                                data-kt-menu-flip='center, top'
                              >
                                <Link
                                  to='#'
                                  className={`menu-link ${
                                    _menuClassName.menuLink || defaultMenuClassName.menuLink
                                  }`}
                                >
                                  <span className='menu-title'>{l.title}</span>
                                  <ReactIcon
                                    icon='RiArrowRightSLine'
                                    props={{
                                      className:
                                        menuClassName?.menuIcon || defaultMenuClassName.menuIcon,
                                    }}
                                  />
                                </Link>
                                <div
                                  className={`menu menu-sub menu-sub-dropdown menu-column ${
                                    _menuClassName?.menuSub || defaultMenuClassName.menuSub
                                  }`}
                                  data-kt-menu='true'
                                >
                                  {l.submenu?.map((lsub, isub) => (
                                    <Fragment key={isub}>
                                      {
                                        <SubmenuV2
                                          l={lsub}
                                          onFeedback={onFeedback}
                                          menuClassName={_menuClassName}
                                        />
                                      }
                                    </Fragment>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className='menu-item px-3'>
                              {_isFeedback ? (
                                <div
                                  className={clsx(
                                    `menu-link ${
                                      _menuClassName.menuLink || defaultMenuClassName.menuLink
                                    }`,
                                    {
                                      active: l.active,
                                    }
                                  )}
                                  onClick={() => {
                                    onFeedback && onFeedback({props: l})
                                  }}
                                >
                                  <SubmenuItemContentV2 l={l} menuClassName={_menuClassName} />
                                </div>
                              ) : _isHeader ? (
                                <div
                                  className={`menu-content ${
                                    _menuClassName.menuContent || defaultMenuClassName.menuContent
                                  }`}
                                >
                                  {l.title || 'Menu'}
                                </div>
                              ) : (
                                <Link
                                  to={l.route || '#'}
                                  className={`menu-link ${
                                    _menuClassName.menuLink || defaultMenuClassName.menuLink
                                  }`}
                                  {...l.linkProps}
                                >
                                  <SubmenuItemContentV2 l={l} menuClassName={_menuClassName} />
                                </Link>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </Fragment>
                )
              })}
          </>
        )}
      </div>
    </div>
  )
}

interface SelectUiProps {
  title: string
  props?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
  data: {
    title: string
    value: string | number
  }[]
}

const SelectUi: FC<SelectUiProps> = ({data, title, props}) => {
  return (
    <div>
      <select
        {...props}
        className={`form-select ${props?.className}`}
        data-control='select2'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        data-kt-user-table-filter='role'
        data-hide-search='true'
      >
        <option value=''>{title}</option>
        {data.map((l, i) => (
          <Fragment key={i}>
            <option value={l.value}>{l.title}</option>
          </Fragment>
        ))}
      </select>
    </div>
  )
}

const Divider: FC<{color?: string}> = ({color}) => {
  return <div className='h-1px bg-dark opacity-10 my-2' style={{backgroundColor: color}}></div>
}

const ButtonLoading: FC<{
  icon?: keyof typeof Icons
  iconClassName?: string
  title?: {
    button?: string
    loading?: string
  }
  loading?: boolean
  disabled?: boolean
  props?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  ktMenuProps?: {
    [key: string]: string
  }
  children?: ReactNode
}> = ({
  icon,
  iconClassName,
  loading,
  disabled,
  props,
  title = {
    button: 'Simpan',
    loading: 'Tunggu Sebentar...',
  },
  ktMenuProps,
  children,
}) => {
  return (
    <button
      type='button'
      className='btn btn-primary'
      data-kt-users-modal-action='submit'
      data-kt-indicator={loading ? 'on' : 'off'}
      disabled={loading || disabled}
      {...ktMenuProps}
      {...props}
    >
      {loading ? (
        <>
          <span className='indicator-progress'>
            {title?.loading || 'Tunggu Sebentar...'}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </>
      ) : (
        <>
          {children ? (
            <>{children}</>
          ) : (
            <>
              <span className='indicator-label'>
                <span className='d-flex gap-2 align-items-center'>
                  {icon && <ReactIcon icon={icon} props={{className: `fs-3 ${iconClassName}`}} />}
                  <span>{title?.button || 'Simpan'}</span>
                </span>
              </span>
            </>
          )}
        </>
      )}
    </button>
  )
}

const AvatarUi: FC<{url: string; name: string; error?: boolean}> = ({name, url, error = false}) => {
  const [_error, _setError] = useState<boolean>(error)
  const [modal, setModal] = useState<boolean>(false)
  let data: any = {
    name: letterAvatar(name),
    backgroundColor: getRandomColor(name).backgroundColor,
    color: getRandomColor(name).textColor,
    url: url,
  }
  useEffect(() => {
    _setError(error)
  }, [error])
  return (
    <div
      className='cursor-pointer overflow-hidden min-w-60px min-h-60px w-60px h-60px rounded-circle fs-2 fw-bold text-uppercase d-flex justify-content-center align-items-center position-relative mx-auto'
      style={{
        backgroundColor: data.backgroundColor,
        color: data.color,
      }}
      onClick={() => setModal(true)}
    >
      {!_error && NullProof({input: data, params: 'url', isLabel: false}) ? (
        <img
          className='w-100 h-100 object-cover'
          src={`${getApiPath()}/${NullProof({input: data, params: 'url'})}`}
          onError={(e) => {
            _setError(true)
          }}
        />
      ) : (
        <>{data?.name}</>
      )}
    </div>
  )
}

interface TabsUiProps {
  id?: string
  title?: string
  active?: string
  onChange?: (data?: any) => void
  tabs?: {
    id: string
    title: string
    children: ReactNode
  }[]
  options?: {
    hasCard?: boolean
    tabVerticalScroll?: boolean
  }
}

const TabsUi: FC<TabsUiProps & WithChildren> = ({
  id: tabId = 'tabs-ui',
  tabs = [],
  title,
  active,
  onChange,
  children,
  options = {
    hasCard: true,
  },
}) => {
  const [activeId, setActiveId] = useState<string>(active || tabs[0]?.id || '')
  const tableRef = useRef<HTMLDivElement>(null)
  const scrollbarTopContentRef = useRef<HTMLDivElement>(null)
  const tableScrollRef = useRef<HTMLDivElement>(null)
  const [isMouseDrag, setMouseDrag] = useState<boolean>(false)
  const [mouseData, setMouseData] = useState<{offset: number; position: number}>({
    offset: 0,
    position: 0,
  })
  const updateSize = () => {
    if (tableRef.current) {
      if (scrollbarTopContentRef.current) {
        scrollbarTopContentRef.current.style.width = `${tableRef.current?.clientWidth}px`
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])
  const handleMouseDrag = ({
    e,
    type,
  }: {
    e: React.MouseEvent<HTMLDivElement>
    type: 'drag' | 'up' | 'down'
  }) => {
    updateSize()
    if (type === 'down') {
      setMouseDrag(true)
      setMouseData({
        offset: e.pageX - (tableScrollRef.current?.offsetLeft ?? 0),
        position: tableScrollRef.current?.scrollLeft ?? 0,
      })
    } else if (type === 'up') {
      setMouseDrag(false)
    } else {
      if (isMouseDrag && tableScrollRef.current) {
        const {offset, position} = mouseData
        const x = e.pageX - (tableScrollRef.current.offsetLeft ?? 0)
        const walk = x - offset
        tableScrollRef.current.scrollLeft = position - walk
      }
    }
  }
  const tabListUi = (
    <div
      className='d-flex overflow-x-scroll pb-2'
      style={{cursor: isMouseDrag ? 'grab' : 'unset'}}
      ref={tableScrollRef}
      onMouseDown={(e) => handleMouseDrag({e, type: 'down'})}
      onMouseMove={(e) => handleMouseDrag({e, type: 'drag'})}
      onMouseUp={(e) => handleMouseDrag({e, type: 'up'})}
      onMouseLeave={(e) => handleMouseDrag({e, type: 'up'})}
    >
      <ul
        className={`nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 ${
          options?.tabVerticalScroll ? 'flex-nowrap' : ''
        }`}
      >
        {tabs.map((l, i) => (
          <Fragment key={i}>
            <li className='nav-item'>
              <a
                draggable={false}
                className={`nav-link text-black ${active === l.id || i === 0 ? 'active' : ''}`}
                style={{
                  whiteSpace: 'nowrap',
                }}
                data-bs-toggle='tab'
                href={`#${l.id}`}
                onClick={() => {
                  setActiveId(l.id)
                  onChange && onChange(l.id)
                }}
              >
                {l.title}
              </a>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  )
  const tabContentUi = (
    <div className='tab-content' id={tabId}>
      {tabs.map((l, i) => (
        <Fragment key={i}>
          <div
            className={`tab-pane fade ${active === l.id || i === 0 ? 'show active' : ''}`}
            id={l.id}
            role='tabpanel'
          >
            {l.children}
          </div>
        </Fragment>
      ))}
    </div>
  )
  return (
    <>
      {options?.hasCard ? (
        <div className='card card-custom'>
          <div className='card-header py-5'>
            {title && <h3 className='card-title'>{title}</h3>}
            <div className='card-toolbar'>{tabListUi}</div>
          </div>
          <div className='card-body'>
            {children}
            {tabContentUi}
          </div>
        </div>
      ) : (
        <>
          <div className='my-2'>{tabListUi}</div>
          <div className='my-2'>{tabContentUi}</div>
        </>
      )}
    </>
  )
}

interface TimelineUiProps {
  date?: string
  title?: string
  isEnd?: boolean
}

const TimelineUi: FC<TimelineUiProps & WithChildren> = ({title, date, isEnd, children}) => {
  return (
    <>
      <div className={`d-flex gap-4`}>
        <div className='flex-1'>
          <div className='fs-6 mb-2 text-end min-w-50px'>{date}</div>
        </div>
        <div
          className={`pb-6 border-start ${
            !isEnd ? 'border-primary' : 'border-transparent'
          } ps-4 position-relative w-100`}
        >
          <div
            className='position-absolute rounded-circle bg-primary'
            style={{left: '-6px', top: '7px', width: '10px', height: '10px'}}
          ></div>
          <div className='fs-4 fw-bold mb-2'>{title}</div>
          <div className='row g-4'>
            <div className='col-12'>{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

interface ListdataUiProps {
  id: string
  title?: string
  data: {title?: string; value?: string}[]
}

const ListdataUi: FC<ListdataUiProps> = ({id, title, data}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const checkUploadFileType = (url: string) => {
    let type: string = 'image'
    if (url?.toLowerCase()?.indexOf('.pdf') > -1) {
      type = 'pdf'
    }
    return type
  }
  const [modal, setModal] = useState<any>()
  return (
    <>
      <Modal
        id={`modal_${id}`}
        title={title}
        isShow={modal?.status && modal?.data}
        onClose={() => {
          setModal({status: false})
        }}
      >
        {checkUploadFileType(modal?.data) === 'pdf' ? (
          <>
            <ReactPdfViewer url={`/${modal?.data}`} />
          </>
        ) : (
          <img className='ratio ratio-1x1' src={`${API_URL}/${modal?.data}`} alt={`Image ${id}`} />
        )}
      </Modal>
      <div className='d-flex flex-wrap gap-2'>
        {data?.map((l: any, i: number) => (
          <Fragment key={i}>
            <div className='bg-light px-4 py-2 d-flex align-items-center gap-2'>
              <div>{NullProof({input: l, params: 'name'})}</div>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='fs-2 cursor-pointer'
                  onClick={() => {
                    setModal({status: true, data: l?.file})
                  }}
                >
                  <ReactIcon icon='RiEyeLine' />
                </div>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  )
}

interface XFiniteDataUiProps {
  title?: string
  scrollTargetId?: 'window' | string
  route?: {
    api?: string
    query?: string
    data?: any[]
  }
  pagination?: 'infinite' | 'page' | 'none'
  onSuccess?: (data?: any) => void
  refresh?: boolean
}

const XfiniteDataUi: FC<XFiniteDataUiProps & WithChildren> = ({
  title = 'Data',
  scrollTargetId,
  route,
  onSuccess,
  children,
  pagination = 'infinite',
  refresh = false,
}) => {
  const [isBottomPage, setIsBottomPage] = useState(false)
  const [data, setData] = useState<any>([])
  const [status, setStatus] = useState<{
    page: {loading: boolean; error: boolean}
    more: {loading: boolean; error: boolean}
    data?: {currentPage?: number; totalPages?: number; totalItems?: number}
  }>({
    page: {
      loading: true,
      error: false,
    },
    more: {
      loading: false,
      error: false,
    },
  })
  const isEndTotalPage = (status.data?.currentPage || 0) >= (status.data?.totalPages || 0)
  const delay = (ms: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }
  const loadData = async ({type = 'page', page = 1}: {type?: 'page' | 'more'; page?: number}) => {
    let json = {
      status: false,
      data: [],
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
    }
    let queryList: any = {
      page: page,
    }
    try {
      for (const l of route?.query?.split('&') || []) {
        const params = l.split('=')
        queryList[params[0]] = params[1]
      }
    } catch (error) {}
    const queryString = Object.keys(queryList)
      .map((e) => `${e}=${queryList[e]}`)
      .join('&')
    try {
      setStatus((p) => ({
        ...p,
        [type]: {loading: true, error: false},
        data: {...p.data, currentPage: (type === 'page' ? page : p.data?.currentPage) || 0},
      }))
      await delay(1000)
      let result: any
      if (route?.data) {
        result = getPaginationStaticData({data: route.data, page: page})
      } else if (route?.api) {
        result = await getData(queryString, route?.api || '')
      }
      if (result?.status) {
        json = {
          ...result,
          status: result?.status || false,
          data: result?.data || [],
          currentPage: result?.currentPage || 0,
          totalItems: result?.totalItems || 0,
          totalPages: result?.totalPages || 0,
        }
        setStatus((p) => ({
          ...p,
          [type]: {loading: false, error: false},
          data: {
            ...p.data,
            currentPage: json?.currentPage || 0,
            totalItems: json?.totalItems || 0,
            totalPages: json?.totalPages || 0,
          },
        }))
      } else {
        setStatus((p) => ({...p, [type]: {loading: false, error: true}}))
      }
    } catch (error) {
      setStatus((p) => ({...p, [type]: {loading: false, error: true}}))
    }
    return json
  }

  const initData = async (page: number = 1) => {
    const result = await loadData({page: page})
    let json: any = {...result}
    let _data: any = [...data]
    if (result?.status) {
      _data = result?.data
      json = {...result, data: _data}
      setData(_data)
    }
    onSuccess && onSuccess(json)
  }

  const moreData = async (refresh?: boolean) => {
    if (!isEndTotalPage || status.page.error) {
      const result = await loadData({
        page: (status.data?.currentPage || 0) + (refresh ? 0 : 1),
        type: 'more',
      })
      let json: any = {...result}
      let _data: any = [...data]
      if (result?.status) {
        _data = [..._data, ...result?.data]
        json = {...result, data: _data}
        setData(_data)
      }
      onSuccess && onSuccess(json)
    }
  }

  useEffect(() => {
    if (route?.api || route?.data) {
      initData()
    }
  }, [route?.api])

  useEffect(() => {
    if (refresh) {
      initData()
    }
  }, [refresh])

  useEffect(() => {
    function handleScroll() {
      let windowHeight: number = 0
      let documentHeight: number = 1
      let scrollTop: number = 0
      if (scrollTargetId && scrollTargetId !== 'window') {
        const target = document.getElementById(scrollTargetId)
        if (target) {
          windowHeight = target.clientHeight
          documentHeight = target.scrollHeight
          scrollTop = target.scrollTop
        }
      } else if (scrollTargetId === 'window') {
        windowHeight = window.innerHeight
        documentHeight = document.documentElement.scrollHeight
        scrollTop =
          window.scrollY ||
          window.pageYOffset ||
          document.body.scrollTop +
            ((document.documentElement && document.documentElement.scrollTop) || 0)
      }
      // Check if the user has scrolled to the bottom of the page
      if (windowHeight + scrollTop >= documentHeight) {
        setIsBottomPage(true)
      } else {
        setIsBottomPage(false)
      }
    }
    if (scrollTargetId && scrollTargetId !== 'window') {
      const target = document.getElementById(scrollTargetId)
      target && target.addEventListener('scroll', handleScroll)
    } else if (scrollTargetId === 'window') {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollTargetId && scrollTargetId !== 'window') {
        const target = document.getElementById(scrollTargetId)
        target && target.removeEventListener('scroll', handleScroll)
      } else if (scrollTargetId === 'window') {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (isBottomPage && !status.more.error && !status.more.loading) {
      moreData()
    }
  }, [isBottomPage])
  return (
    <div className='position-relative'>
      {!status.page.loading && !status.page.error && <div>{children}</div>}
      <div className='py-4 px-2 d-flex flex-column align-items-center justify-content-center'>
        {(data?.length || 0) < 1 && !status.page.loading && !status.page.error && (
          <div>
            <div className='text-capitalize'>{title} masih kosong</div>
          </div>
        )}
        {status.page.loading && (
          <div className='d-flex gap-2 align-items-center justify-content-center w-100 h-100'>
            <div className='spinner-border spinner-border-sm' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
            <div>Memuat {title}...</div>
          </div>
        )}
        {((!status.page.loading && !isEndTotalPage) || status.page.error || status.more.error) && (
          <div className='d-flex gap-2 flex-column align-items-center justify-content-center'>
            {(status.more.error || status.page.error) && (
              <div className='text-capitalize'>Gagal memuat {title}.</div>
            )}
            {(pagination === 'infinite' || status.more.error) && (
              <ButtonLoading
                title={{button: !status.page.error ? 'Muat lebih banyak' : 'Muat ulang'}}
                loading={status.more.loading}
                props={{
                  className: 'btn btn-sm btn-primary',
                  onClick: () => moreData(status.more.error || status.page.error),
                  disabled: status.more.loading || (isEndTotalPage && !status.page.error),
                }}
              />
            )}
          </div>
        )}
      </div>
      {pagination === 'page' && (
        <Pagination
          currentPage={status.data?.currentPage || 1}
          maxButtons={5}
          totalPages={status.data?.totalPages || 1}
          onPageChange={(e) => {
            if (status.data?.currentPage !== e) {
              initData(e)
            }
          }}
        />
      )}
    </div>
  )
}

interface ModalButtonUi {
  options: {
    modal: ModalUiProps
    button?: {
      title?: string
      props?: React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >
    }
  }
}

const ModalButtonUi: FC<ModalButtonUi & WithChildren> = ({children, options}) => {
  const [modal, setModal] = useState<boolean>(false)
  return (
    <>
      <Modal
        {...options.modal}
        onClose={() => setModal(false)}
        isShow={modal}
        title={options?.modal?.title || 'Modal'}
      >
        {children}
      </Modal>
      <button
        className='btn btn-sm btn-primary'
        {...options.button?.props}
        onClick={() => setModal(true)}
      >
        {options?.button?.title || 'Button'}
      </button>
    </>
  )
}

interface StateMessageUiProps {
  title?: {
    default?: string
    loading?: string
    error?: string
    empty?: string
    refresh?: string
  }
  state?: {
    loading?: boolean
    error?: boolean
    empty?: boolean
  }
  component?: {
    loading?: ReactNode
    error?: ReactNode
    empty?: ReactNode
  }
  children?: ReactNode
  onFeedback?: (e: {id: string}) => void
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

const StateMessageUi: FC<StateMessageUiProps> = ({
  title = {},
  state = {
    loading: true,
    empty: false,
    error: false,
  },
  component,
  children,
  onFeedback,
  props,
}) => {
  return (
    <>
      <div className='min-h-300px position-relative' {...props}>
        {state.loading ? (
          <>
            {component?.loading ? (
              component.loading
            ) : (
              <div className='d-flex fs-5 gap-4 align-items-center justify-content-center w-100 h-100 position-absolute'>
                <div className='spinner-border w-20px h-20px' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
                <div>
                  {`${title.loading ? title.loading : `Memuat ${title?.default || 'Data'}`}`}...
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {state.error ? (
              <>
                {component?.error ? (
                  component.error
                ) : (
                  <div className='d-flex flex-column gap-2 align-items-center justify-content-center w-100 h-100 position-absolute'>
                    <div>{`${
                      title.error ? title.error : `Gagal memuat ${title?.default || 'Data'}`
                    }`}</div>
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        onFeedback && onFeedback({id: 'refresh'})
                      }}
                    >
                      {title?.refresh || 'Muat ulang'}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                {state.empty ? (
                  <>
                    {component?.empty ? (
                      component.empty
                    ) : (
                      <div className='d-flex flex-column gap-2 align-items-center justify-content-center w-100 h-100 position-absolute'>
                        <div>{`${
                          title.empty ? title.empty : `${title?.default || 'Data'} masih kosong`
                        }`}</div>
                      </div>
                    )}
                  </>
                ) : (
                  <>{children}</>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

interface DrawerUiProps {
  name?: string
  children?: ReactNode
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  config?: DrawerUiConfig
}

interface DrawerUiConfig {
  drawer?: string
  activate?: string
  overlay?: string
  width?: string
  direction?: 'start' | 'end'
  props?: any
}

const DrawerUi: FC<DrawerUiProps> = ({name = 'drawer', children, props, config}) => {
  const _config: DrawerUiConfig = {
    drawer: 'true',
    activate: 'true',
    overlay: 'true',
    direction: 'start',
    width: '',
    props: {},
    ...config,
  }
  const drawerProps: any = {
    id: `kt_${name}`,
    'data-kt-drawer': _config.drawer,
    'data-kt-drawer-activate': _config.activate,
    'data-kt-drawer-toggle': `#kt_${name}_button`,
    'data-kt-drawer-close': `#kt_${name}_close`,
    'data-kt-drawer-name': `${name}`,
    'data-kt-drawer-overlay': _config.overlay,
    'data-kt-drawer-width': _config.width,
    'data-kt-drawer-direction': _config.direction,
    ..._config.props,
  }
  useEffect(() => {
    DrawerComponent.reinitialization()
  }, [config])
  return (
    <>
      <div {...props} {...drawerProps}>
        {children}
      </div>
    </>
  )
}

interface FormPaginationProps {
  lock?: {
    next?: boolean
    prev?: boolean
  }
  loading?: {
    page?: boolean
    next?: boolean
    prev?: boolean
  }
  page?: number
  layout?: {
    children: ReactNode
  }[]
  onPageChange?: ({
    loading,
    totalPage,
    page,
    commit,
    type,
  }: {
    loading: () => void
    type: 'prev' | 'next' | 'finish'
    totalPage: number
    page: number
    commit: (page?: number) => void
  }) => void
  useProvider?: boolean
  children?: ReactNode
}

interface FormPaginationContextModel {
  layout?: {
    children: ReactNode
  }[]
  getLayout: (page?: number) => void
  setLayout: (layout: {children: ReactNode}[]) => void
  page: number
  setPage: (page: number) => void
  totalPage: number
  setTotalPage: (page: number) => void
  commit: (page?: number) => void
}

const FormPaginationContext = createContext<FormPaginationContextModel>({
  page: 1,
  setPage(page: number) {},
  totalPage: 1,
  setTotalPage(page: number) {},
  getLayout(page?: number) {},
  setLayout(layout: {children: ReactNode}[]) {},
  commit(page?: number) {},
})

export const FormPaginationProvider: FC<WithChildren> = ({children}) => {
  const [layout, setLayout] = useState<{children: ReactNode}[]>([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const commit = (e?: number) => {
    setPage(e || page)
  }

  const getLayout = (e?: number) => {
    let result = {}
    try {
      result = layout[e || 0]
    } catch (_) {}
    return result
  }
  return (
    <FormPaginationContext.Provider
      value={{page, setPage, commit, layout, setLayout, getLayout, totalPage, setTotalPage}}
    >
      {children}
    </FormPaginationContext.Provider>
  )
}

const FormPaginationConfig: FC<FormPaginationProps> = ({layout, page}) => {
  const {commit, setLayout, setTotalPage} = useFormPagination()
  useEffect(() => {
    if (layout) {
      setLayout(layout)
      setTotalPage(layout.length)
    }
  }, [])
  useEffect(() => {
    commit(page)
  }, [])
  return <></>
}

export const useFormPagination = () => {
  return useContext(FormPaginationContext)
}

const FormPagination: FC<FormPaginationProps> = ({
  layout,
  onPageChange,
  page,
  useProvider = true,
  children,
  lock,
  loading,
}) => {
  const props = {
    layout,
    onPageChange,
    page,
    children,
    useProvider,
    lock,
    loading,
  }
  const providerProps = {
    layout,
    page,
  }
  return (
    <>
      {useProvider ? (
        <FormPaginationProvider>
          <FormPaginationConfig {...providerProps} />
          <FormPaginationBase {...props} />
        </FormPaginationProvider>
      ) : (
        <>
          <FormPaginationBase {...props} />
        </>
      )}
    </>
  )
}

const FormPaginationBase: FC<FormPaginationProps> = ({
  lock,
  layout,
  onPageChange,
  page,
  useProvider = true,
  children,
  loading: _loading,
}) => {
  const [loading, setLoading] = useState<{
    page?: boolean
    next?: boolean
    prev?: boolean
  }>({
    ...(_loading || {
      page: true,
      next: false,
      prev: false,
    }),
  })
  const [key, setKey] = useState<number>(0)
  const valueDebouce = useRef(debounce((e) => e(), 100)).current

  useEffect(() => {
    return () => {
      valueDebouce.cancel()
    }
  }, [valueDebouce])

  const {page: appPage, totalPage: appTotalPage} = useFormPagination()
  const [infoPage, setInfoPage] = useState<any>({
    page: 1,
    total: 1,
  })
  const appConfig = {
    layout,
    page,
  }
  useEffect(() => {
    setInfoPage((p: any) => ({
      ...p,
      total: appTotalPage,
    }))
  }, [appTotalPage])

  useEffect(() => {
    setInfoPage((p: any) => ({
      ...p,
      page: appPage,
    }))
  }, [appPage])

  useEffect(() => {
    setLoading((p) => ({...p, page: false}))
  }, [])
  return (
    <Fragment key={key}>
      {!useProvider && <FormPaginationConfig {...appConfig} />}
      {children ? (
        <>{children}</>
      ) : (
        <>
          {layout?.map((l: any, i: number) => (
            <Fragment key={i}>{i === infoPage.page - 1 && l.children}</Fragment>
          ))}
          <div className='d-flex justify-content-end gap-4 align-items-center justify-content-center mt-4'>
            <ButtonLoading
              loading={loading?.prev || loading?.page}
              title={{
                loading: '',
                button: 'Prev',
              }}
              disabled={infoPage.page === 1 || lock?.prev}
              props={{
                onClick: () => {
                  const commit = (e?: number) => {
                    valueDebouce(() => {
                      setInfoPage((p: any) => ({...p, page: e || p.page - 1}))
                      setLoading((p) => ({...p, page: false}))
                    })
                  }
                  if (onPageChange) {
                    onPageChange({
                      loading: () => setLoading((p) => ({...p, page: true})),
                      type: 'prev',
                      page: infoPage.page,
                      totalPage: infoPage.total,
                      commit: commit,
                    })
                  } else {
                    commit()
                  }
                },
              }}
            />
            <div className='w-100 d-flex flex-column gap-2 align-items-center'>
              <div>
                Page {infoPage.page} dari {infoPage.total}
              </div>
              <div className='w-100'>
                <div
                  className='progress h-10px'
                  role='progressbar'
                  aria-label='Basic example'
                  aria-valuenow={0}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className='progress-bar'
                    style={{width: `${Math.round((infoPage.page / infoPage.total) * 100)}%`}}
                  ></div>
                </div>
              </div>
            </div>
            <ButtonLoading
              loading={loading?.next || loading?.page}
              title={{
                button: infoPage.page === infoPage.total ? 'Finish' : 'Next',
              }}
              disabled={lock?.next}
              props={{
                onClick: () => {
                  const commit = (e?: number) => {
                    valueDebouce(() => {
                      setInfoPage((p: any) => ({...p, page: e || p.page + 1}))
                      setLoading((p) => ({...p, page: false}))
                    })
                  }
                  if (onPageChange) {
                    onPageChange({
                      loading: () => setLoading((p) => ({...p, page: true})),
                      type: infoPage.page === infoPage.total ? 'finish' : 'next',
                      page: infoPage.page,
                      totalPage: infoPage.total,
                      commit: commit,
                    })
                  } else {
                    commit()
                  }
                },
              }}
            />
          </div>
        </>
      )}
    </Fragment>
  )
}

const ScrollDraggingUi: FC<{
  children?: ReactNode
  className?: string
  layoutClassName?: string
}> = ({children, className = 'overflow-scroll', layoutClassName}) => {
  const targetScrollRef = useRef<HTMLDivElement>(null)
  const targetScrollbarRef = useRef<HTMLDivElement>(null)
  const targetScrollbarContentRef = useRef<HTMLDivElement>(null)

  const [isMouseDrag, setMouseDrag] = useState<boolean>(false)
  const [mouseData, setMouseData] = useState<{offset: number; position: number}>({
    offset: 0,
    position: 0,
  })

  const updateSize = () => {
    if (targetScrollbarContentRef.current && targetScrollRef.current) {
      targetScrollbarContentRef.current.style.width = `${
        (targetScrollRef.current?.scrollWidth ?? 0) +
        (targetScrollRef?.current?.offsetHeight < targetScrollRef?.current?.scrollHeight ? 15 : 0)
      }px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  useEffect(() => {
    updateSize()
  }, [targetScrollRef.current?.scrollWidth, targetScrollRef.current?.scrollHeight])

  const handleMouseDrag = ({
    e,
    type,
  }: {
    e: React.MouseEvent<HTMLDivElement>
    type: 'drag' | 'up' | 'down'
  }) => {
    updateSize()
    if (type === 'down') {
      setMouseDrag(true)
      setMouseData({
        offset: e.pageX - (targetScrollRef.current?.offsetLeft ?? 0),
        position: targetScrollRef.current?.scrollLeft ?? 0,
      })
    } else if (type === 'up') {
      setMouseDrag(false)
    } else {
      if (isMouseDrag && targetScrollRef.current) {
        const {offset, position} = mouseData
        const x = e.pageX - (targetScrollRef.current.offsetLeft ?? 0)
        const walk = x - offset
        targetScrollRef.current.scrollLeft = position - walk
      }
    }
  }

  return (
    <div className={layoutClassName}>
      <div
        onScroll={(e) => {
          if (targetScrollRef.current) {
            targetScrollRef.current.scrollLeft = e.currentTarget.scrollLeft
          }
        }}
        ref={targetScrollbarRef}
        className='overflow-auto'
      >
        <div ref={targetScrollbarContentRef} className='h-1px'></div>
      </div>
      <div
        onMouseDown={(e) => handleMouseDrag({e, type: 'down'})}
        onMouseMove={(e) => handleMouseDrag({e, type: 'drag'})}
        onMouseUp={(e) => handleMouseDrag({e, type: 'up'})}
        onMouseLeave={(e) => handleMouseDrag({e, type: 'up'})}
        onScroll={(e) => {
          if (targetScrollbarRef.current) {
            targetScrollbarRef.current.scrollLeft = e.currentTarget.scrollLeft
          }
        }}
        ref={targetScrollRef}
        style={{cursor: isMouseDrag ? 'grab' : 'unset'}}
        className={className}
      >
        {children}
      </div>
    </div>
  )
}

export {
  CardUi,
  SelectUi,
  DropdownUiV1 as DropdownUi,
  DropdownUiV2,
  Divider,
  ButtonLoading,
  AvatarUi,
  TabsUi,
  TimelineUi,
  ListdataUi,
  XfiniteDataUi,
  ModalButtonUi,
  StateMessageUi,
  DrawerUi,
  FormPagination,
  ScrollDraggingUi,
}
