import {FC, ReactNode, useState} from 'react'
import {id} from 'date-fns/locale'
import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker.css'
import DatePicker, {ReactDatePickerProps} from 'react-datepicker'
import {getDate} from 'date-fns'
import ReactIcon from './ReactIcon'
import {formatDate} from './DateFns'
import {intlMessage} from './ReactIntl'
import {useIntl} from 'react-intl'

interface ReactDatepickerProps {
  value: string
  props?: ReactDatePickerProps
  labelType?: 'default' | 'floating'
  label?: ReactNode
  onChange: any
}

const ReactDatepicker: FC<ReactDatepickerProps> = ({props, onChange, value, labelType, label}) => {
  const intl = useIntl()
  const [startDate, setStartDate] = useState<any>(value ? new Date(value) : new Date())
  const currentYear: number = new Date().getFullYear()
  const startYear: number = 1990

  const years: number[] = Array.from(
    {length: currentYear - startYear + 1},
    (_, index) => startYear + index
  )
  const renderDayContents = (day: any, date: any) => {
    const tooltipText = `${formatDate({date: date, dateFormat: 'dd MMMM yyyy'})}`
    return (
      <span className='' title={tooltipText}>
        {getDate(date)}
      </span>
    )
  }
  const TimeInput = ({date, value, onChange}: any) => (
    <div className='p-4'>
      <input
        className='form-control form-control-sm'
        type='time'
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return (
    <DatePicker
      selected={startDate}
      fixedHeight
      customTimeInput={<TimeInput />}
      renderDayContents={renderDayContents}
      customInput={
        <div className={`${(labelType || 'floating') === 'floating' ? 'form-floating' : ''}`}>
          <div className='w-full form-control form-control-lg mb-3 mb-lg-0 text-start'>
            {value
              ? formatDate({
                  date: value,
                  dateFormat: props?.showTimeSelect
                    ? props?.showTimeSelectOnly
                      ? 'HH:mm'
                      : 'dd MMMM yyyy - HH:mm'
                    : 'dd MMMM yyyy',
                })
              : `${
                  props?.placeholderText ||
                  intlMessage('component.forminput.date.placeholder', 'Select Date', intl)
                }...`}
          </div>
          {(labelType || 'floating') === 'floating' && label}
        </div>
      }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type='button'
            className='btn btn-light p-2'
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <ReactIcon icon='RiArrowLeftSLine' />
          </button>
          <div className='input-group'>
            <select
              className='form-select form-select-sm'
              value={date.getFullYear()}
              onChange={({target: {value}}) => changeYear(parseInt(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className='form-select form-select-sm'
              value={months[date.getMonth()]}
              onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <button
            type='button'
            className='btn btn-light p-2'
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <ReactIcon icon='RiArrowRightSLine' />
          </button>
        </div>
      )}
      locale={id}
      className='w-100'
      wrapperClassName='w-100 text-start'
      {...props}
      onChange={(val) => {
        setStartDate(val)
        onChange(val)
      }}
    />
  )
}

export default ReactDatepicker
