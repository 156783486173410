/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {exportFormInputList, formInputList, importFormInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {ButtonLoading, CardUi, DropdownUiV2 as DropdownUi} from '../../../../helper/AppUi'
import {
  TableActionProps,
  TableColumn,
  TableInput,
  TableInputCheckboxDataProps,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../../helper/TableInputV2'
import {
  AlphabetIndex,
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
} from '../../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {useIntl} from 'react-intl'
import {intlMessage} from '../../../../helper/ReactIntl'
import ReactIcon from '../../../../helper/ReactIcon'
import {hasAccessRoutes, ProtectComponent} from '../../../../helper/UserRolesValidation'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../../modules/auth'
import download from 'downloadjs'
import {createWorkbook} from '../../../../helper/ReactExcelJs'
import {getData} from '../../../../helper/FormAxios'
import {RekapModalPdf} from '../../../../helper/KemenagUi'

const onChangeForm = (e: BaseInputOnChangeProps) => {
  const {clickId, type} = e
  const data = e._data.get
  const apiData = e._apiData?.get
  if (type === 'onChange') {
    if (clickId === 'pegawai_id') {
      const nip =
        (apiData?.pegawai_id_detail?.nip !== '-' && apiData?.pegawai_id_detail?.nip) || data?.nip
      const nama = apiData?.pegawai_id_detail?.nama || data?.nama
      e.resetForm({values: {...data, nip: nip, nama: nama}})
    }
  }
}

interface TableInputListTemuanProps {
  api?: string
  link?: string
  viewOnly?: boolean
}

export const TableInputListTemuan: FC<TableInputListTemuanProps> = ({api, viewOnly, link}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [modal, setModal] = useState<any>([])
  const [modalPopup, setModalPopup] = useState<boolean>(false)

  const [action, setAction] = useState<TableActionProps[]>(TableInputData.action || [])

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const getAccess = (name: string = '') => {
    const routeList: string[] =
      routeData?.route?.filter((f: string) =>
        f?.includes('pengawasan/pelayanan_skbt/list_temuan')
      ) || []
    const status =
      routeList?.filter((f: string) => f === `pengawasan/pelayanan_skbt/list_temuan${name}`)
        ?.length > 0 || roleId === 1
    return status
  }

  useEffect(() => {
    if (link) {
      const newAction = [...(TableInputData.action || [])]
      const result: TableActionProps[] = []
      newAction.forEach((l: any) => {
        result.push({
          ...l,
          route: `../../${link}/${l.route}`,
        })
      })
      setAction(result)
    }
  }, [link])
  return (
    <>
      {getAccess() && (
        <TableInput
          {...TableInputData}
          action={action}
          modal={setModal}
          checkbox={setCheckbox}
          onFeedback={(e) => {
            if (e?.idMenu === 'modal') {
              setModalPopup(true)
            }
          }}
          route={{
            url: api || InfoData.api,
          }}
          onSuccess={setData}
          sidebar={
            <>
              {!viewOnly && (
                <>
                  <ProtectComponent id='tambah'>
                    <button
                      type='button'
                      onClick={() => navigate('./tambah')}
                      className='btn btn-primary d-flex align-items-center gap-1'
                    >
                      <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                      {intlMessage(`component.tableinput.button.add`, 'Add', intl)}
                    </button>
                  </ProtectComponent>
                  <DropdownUi
                    title='Menu'
                    icon='RiSettings2Line'
                    onFeedback={(e: any) => {
                      console.log(e)
                    }}
                    iconOnly
                    submenu={[
                      {
                        title: 'Import Data',
                        route: 'import',
                        id: 'import',
                        info: {icon: 'RiUpload2Line'},
                      },
                    ]}
                  />
                </>
              )}
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'nip'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'sumber'})}
                  </TableColumn>
                  {/* <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'kota'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'provinsi'})}
              </TableColumn> */}
                  {/* <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'satuan_kerja'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'unit_kerja'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'tipologi_satker'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'nomor_lha'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'tahun_lha'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'nilai_temuan'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'nilai_saldo'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'nilai_tl'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'temuan_adm'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'temuan_kegiatan'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'tl_admin'})}
              </TableColumn> */}
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      )}
    </>
  )
}

const MainWrapper: FC = () => {
  const intl = useIntl()

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id

  const [isDownload, setIsDownload] = useState<boolean>(false)
  const [isRekapEmpty, setIsRekapEmpty] = useState<boolean>(false)
  const [rekapModal, setRekapModal] = useState<boolean>(false)

  const downloadRekapCsv = (data: any) => {
    const header = [
      'sumber',
      'nip',
      'nama',
      'satuan_kerja',
      'unit_kerja',
      'tipologi_satker',
      'no_lha',
      'tahun_lha',
      'nama_lhp',
      'tp',
      'temuan_kegiatan',
      'rincian_temuan',
      'nilai_temuan',
      'nilai_tl',
      'nilai_saldo',
      'temuan_adm',
      'tl_adm',
      'saldo_adm',
      'provinsi',
      'kota',
    ]
    const wb = createWorkbook()
    const {worksheet, cell} = wb.createWorksheet({title: 'Rekap List Temuan'})
    worksheet.addRow(header)
    data?.map((l: any, i: number) => {
      header.map((h, hi) => {
        const letter = AlphabetIndex(hi, true)
        cell({id: `${letter}${i + 2}`}).value = l[h]
      })
    })
    wb.download('rekap')
  }

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {`${intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.breadcrumb.title, intl)}`}
      </PageTitle>
      <RekapModalPdf
        title='Rekap Excel'
        view='custom'
        type='custom'
        filter={exportFormInputList}
        show={rekapModal}
        output={[
          {
            type: 'xlsx',
            label: 'Excel',
          },
        ]}
        onClose={() => setRekapModal(false)}
        onChange={(e: BaseInputOnChangeProps) => {
          const data = e._data.get
          const apiData = e._apiData?.get
          const pegawai = apiData?.pegawai_id_detail
          e.resetForm({values: {...data, pegawai: pegawai?.nip}})
          setIsRekapEmpty(false)
        }}
        onSubmit={async (e) => {
          const getQuery = () => {
            let filterQuery = {...e}
            delete filterQuery.type
            let queryList: string[] = ['size=999999']
            // if (
            //   hasAccessRoutes(
            //     routeData?.route,
            //     `pengawasan/pelayanan_skbt/list_temuan/$query=get=all`,
            //     roleId === 1,
            //     true
            //   )
            // ) {
            //   queryList.push('get=all')
            // }
            Object.keys(filterQuery).map((l, i) => {
              if (filterQuery[l] === null || filterQuery[l] === undefined) return
              queryList.push(`${l}=${filterQuery[l]}`)
            })
            return queryList.join('&')
          }
          let queryRekap = getQuery()
          try {
            setRekapModal(false)
            setIsRekapEmpty(false)
            setIsDownload(true)
            const url = RouterQueryParams(InfoData.api, {})
            const response = await getData(queryRekap, url)
            if (response?.status) {
              const data = {data: response?.data, ...e}
              if (response?.data?.length > 0) {
                downloadRekapCsv(response?.data)
              } else {
                setIsRekapEmpty(true)
              }
            }
          } catch (_) {}
          setIsDownload(false)
        }}
      >
        {isRekapEmpty && (
          <div className='alert alert-warning text-dark d-flex align-items-center gap-4'>
            <ReactIcon icon='RiErrorWarningLine' props={{style: {width: '40px', height: '40px'}}} />
            <div>
              <div className='fw-bold fs-5'>Data Rekap Kosong</div>
              <div>Mohon untuk coba dengan data lain.</div>
            </div>
          </div>
        )}
      </RekapModalPdf>
      <CardUi
        title={intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.title, intl)}
        toolbars={
          <>
            <ButtonLoading
              loading={isDownload}
              icon='RiDownload2Line'
              title={{button: 'Download Rekap PDF'}}
              props={{
                onClick: () => setRekapModal(true),
              }}
            />
          </>
        }
      >
        <TableInputListTemuan />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  const intl = useIntl()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Edit {page}`, id: `component.infopage.edit_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'List Temuan', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Add {page}`, id: `component.infopage.add_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'List Temuan', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Import Data {page}`, id: `component.infopage.import_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'List Temuan', intl),
    }
  )
  const downloadTemplateCsv = () => {
    const header = [
      'sumber',
      'nip',
      'nama',
      'satuan_kerja',
      'unit_kerja',
      'tipologi_satker',
      'no_lha',
      'tahun_lha',
      'nama_lhp',
      'tp',
      'temuan_kegiatan',
      'rincian_temuan',
      'nilai_temuan',
      'nilai_tl',
      'nilai_saldo',
      'temuan_adm',
      'tl_adm',
      'saldo_adm',
      'provinsi',
      'kota',
    ]
    const wb = createWorkbook()
    wb.createWorksheet({title: 'Template List Temuan'}).worksheet.addRow(header)
    wb.download('template')
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <div className='d-flex flex-column gap-4'>
        <CardUi title={'Template Import Data List Temuan'}>
          <button className='btn btn-primary' onClick={() => downloadTemplateCsv()}>
            Unduh Template Excel Data
          </button>
        </CardUi>
        <CardUi title={infoPage}>
          <FormInput
            i18n={{baseName: `page.${InfoData.id}`}}
            input={importFormInputList}
            route={{
              url: `${InfoData.api}-import`,
              type: 'post',
              redirect: false,
              resetOnFeedback: true,
              onFeedback({status, message, data}) {
                console.log(status, message, data)
              },
            }}
            options={{
              actionButton: {
                submit: {
                  title: 'Mulai Import',
                },
                cancel: {
                  hide: true,
                },
              },
            }}
            onFeedback={(e) => {
              console.log(e)
            }}
          />
        </CardUi>
      </div>
    </>
  )
}
const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const intl = useIntl()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  const filterInput = () => {
    const removeList = ['pegawai_id']
    const input = [...formInputList]
    const filter = input.filter((l) => !removeList.includes(l.name))
    return filter
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            {filterInput().map((l, i) => (
              <Fragment key={i}>
                <TableInputDetailColumn title={{value: l.options?.input?.props?.title}}>
                  {NullProof({input: data, params: l.name})}
                </TableInputDetailColumn>
              </Fragment>
            ))}
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper, ImportWrapper}
