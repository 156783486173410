import {divIcon, LatLngTuple} from 'leaflet'
import {MapContainer, Marker, Polygon, TileLayer} from 'react-leaflet'
import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {getData} from './FormAxios'
import Modal from './Modal'
import GeomapData from './GeomapData'
import {formatDate} from './DateFns'
import geoJsonData from '../helper/geodata/indonesia-province.json'
import ReactIcon from './ReactIcon'
import {renderToStaticMarkup} from 'react-dom/server'
import {WithChildren} from '../../_metronic/helpers'
import ReactLoading from './ReactLoading'

export interface GeomapDataProps {
  name: string
  location: any
  geodata?: any
}

interface ReactLeafletProps {
  id: string
  data?: ReactLeafletDataProps[]
  route?: {
    api: string
    query?: string
  }
  typeMap?: 'pin' | 'area' | 'both'
  geodata?: GeomapDataProps[]
  title?: string
  onComplete?: (e: {
    data?: ReactLeafletDataProps[]
    api?: any
    update: (data: ReactLeafletDataProps[]) => void
  }) => void
  onOpenDetail?: any
}

interface ReactLeafletDataProps {
  location: GeomapDataProps
  color?: string
  data: any[]
}

const ReactLeaflet: FC<ReactLeafletProps & WithChildren> = ({
  id,
  route,
  title,
  typeMap = 'both',
  data: _data,
  onComplete,
  onOpenDetail,
  children,
  geodata,
}) => {
  const [loading, setLoading] = useState<any>(true)
  const [modal, setModal] = useState<any>({})
  const [data, setData] = useState<ReactLeafletDataProps[]>([])
  const mapRef = useRef<HTMLDivElement>(null)
  const mapRefContainer = useRef(null)

  const updateData = (data: ReactLeafletDataProps[]) => {
    setData((p) => [...p, ...data])
  }

  const loadData = async () => {
    try {
      const detail: any = await getData(route?.query || '', `${route?.api}` || '')
      const gdata = loadGeodata()
      if (detail.status) {
        setLoading(false)
        onComplete &&
          onComplete({
            api: detail?.data,
            data: gdata,
            update: updateData,
          })
      } else {
        setLoading(false)
      }
    } catch (_) {}
  }

  const parseCoordinate = (arr: any) => {
    let json: any = []
    for (const l of arr) {
      let subJson = []
      const subArr = l[0]
      for (const lsub of subArr) {
        const newArr = [lsub[1], lsub[0]]
        subJson.push(newArr)
      }
      subJson = [subJson]
      json.push(subJson)
    }
    return json
  }
  const loadGeodata = () => {
    let result: ReactLeafletDataProps[] = []
    geodata?.forEach((l) => {
      result.push({
        data: [],
        location: l,
        color: '#2ecc71',
      })
    })
    return result
  }
  useEffect(() => {
    setData(loadGeodata())
    if (route?.api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])
  return (
    <>
      <Modal
        id={`modal_${id}`}
        title={`${title} - ${modal?.data}`}
        isShow={modal?.status}
        onClose={() => {
          setModal((p: any) => ({...p, status: false}))
        }}
      >
        {children}
      </Modal>
      <div className='card card-flush p-4 d-flex flex-column gap-2'>
        <div>
          <div className='fs-5 text-gray-500'>{title}</div>
        </div>
        <div className='position-relative'>
          {loading && (
            <div
              className='position-absolute d-flex align-items-center justify-content-center bg-light bg-opacity-50 left-0 top-0 w-100 h-100'
              style={{zIndex: 10}}
            >
              <ReactLoading />
            </div>
          )}
          <div className='overflow-hidden relative' ref={mapRef}>
            <MapContainer
              center={[0.859217, 123.75959]}
              zoom={5}
              maxZoom={10}
              style={{height: '400px'}}
              ref={mapRefContainer}
            >
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {data?.map((l, i) => {
                return (
                  <Fragment key={i}>
                    {(typeMap === 'both' || typeMap === 'area') && (
                      <Polygon
                        eventHandlers={{
                          click: (e) => {
                            setModal({
                              status: true,
                              data: l.location.name,
                            })
                            onOpenDetail && onOpenDetail(l.location.name)
                          },
                        }}
                        pathOptions={{
                          fillColor: l.color,
                          // fillColor:
                          //   saldo > 0 && saldo <= 1000000 * 100
                          //     ? '#f1c40f'
                          //     : saldo > 1000000 * 100 && saldo <= 1000000 * 500
                          //     ? '#f18f0f'
                          //     : saldo > 1000000 * 500 && saldo <= 1000000 * 1000
                          //     ? '#e74c3c'
                          //     : saldo > 1000000 * 1000 && saldo <= 1000000 * 5000
                          //     ? '#85170c'
                          //     : saldo > 1000000 * 5000
                          //     ? '#000000'
                          //     : '#2ecc71',
                          stroke: typeMap === 'both' || typeMap === 'area' ? true : false,
                          color: '#000000',
                          weight: 0.5,
                          fillOpacity: 0.5,
                        }}
                        positions={l.location.geodata?.coordinates}
                      />
                    )}
                    {typeMap === 'pin' && (
                      <Marker
                        eventHandlers={{
                          click: (e) => {
                            setModal({
                              status: true,
                              data: l.location.name,
                            })
                            onOpenDetail && onOpenDetail(l.location.name)
                          },
                        }}
                        position={l.location.location}
                        icon={divIcon({
                          html: renderToStaticMarkup(
                            <ReactIcon
                              icon='RiMapPin2Fill'
                              props={{
                                style: {
                                  fontSize: '30px',
                                  color: l.color,
                                  // color:
                                  //   saldo > 0 && saldo <= 1000000 * 100
                                  //     ? '#f1c40f'
                                  //     : saldo > 1000000 * 100 && saldo <= 1000000 * 500
                                  //     ? '#f18f0f'
                                  //     : saldo > 1000000 * 500 && saldo <= 1000000 * 1000
                                  //     ? '#e74c3c'
                                  //     : saldo > 1000000 * 1000 && saldo <= 1000000 * 5000
                                  //     ? '#85170c'
                                  //     : saldo > 1000000 * 5000
                                  //     ? '#000000'
                                  //     : '#2ecc71',
                                },
                              }}
                            />
                          ),
                          className:
                            'bg-transparent d-flex justify-content-center align-items-center w-50px h-50px ms-n5 mt-n5 pb-8',
                        })}
                      ></Marker>
                    )}
                  </Fragment>
                )
              })}
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReactLeaflet
