import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            header: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    name: 'tahun',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: getListYear(),
    options: {
      input: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    name: 'n',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        // isDate: true,
        props: {
          title: 'N',
          placeholder: 'N',
        },
      },
    },
  },
  {
    name: 'n1',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        // isDate: true,
        props: {
          title: 'N-1',
          placeholder: 'N-1',
        },
      },
    },
  },
  {
    name: 'n2',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        // isDate: true,
        props: {
          title: 'N-2',
          placeholder: 'N-2',
        },
      },
    },
  },
]

export {formInputList}
