import {TableInputUiConfigProps} from '../TableInputV2'

const TableInputUiConfig: TableInputUiConfigProps = {
  action: 'modal',
  limitData: ['10', '20', '50'],
  theme: {
    table: 'table-bordered gy-5 gs-5',
    actionButton: {
      icon: 'RiListUnordered',
      iconClassName: 'fs-2',
      class: 'btn-primary d-flex align-items-center',
      size: 'btn-sm',
      showLabel: true,
    },
    modalButton: {
      class: 'btn-primary',
    },
  },
}

export default TableInputUiConfig
