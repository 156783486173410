import {DataRoutesProps} from '../../../UserRolesValidation'
import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
  ImportWrapper,
  PdfWrapper,
} from '../../../../pages/pengawasan-internal/pelayanan-skbt/pengajuan/MainWrapper'

const Pengajuan: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'import',
    elements: <ImportWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'pdf/:id',
    elements: <PdfWrapper />,
    subroute: [],
  },
]

export {Pengajuan}
