import {DataRoutesProps} from '../../../UserRolesValidation'
import {
  MainWrapper,
  TambahWrapper,
  UbahWrapper,
  DetailWrapper,
} from '../../../../pages/pengawasan-internal/pelayanan-skbt/lampiran/MainWrapper'
import {
  TambahWrapper as TambahSurvey,
  UbahWrapper as UbahSurvey,
  DetailWrapper as DetailSurvey,
} from '../../../../pages/pengawasan-internal/pelayanan-skbt/survey-layanan/MainWrapper'

const LampiranSkbt: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:id',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'survey/:lampiran',
    elements: <DetailSurvey type='lampiran' />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'survey/:lampiran/tambah',
    elements: <TambahSurvey type='lampiran' />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'survey/:lampiran/ubah',
    elements: <UbahSurvey type='lampiran' />,
    subroute: [],
  },
]

export {LampiranSkbt}
