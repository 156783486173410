import {FormInputUiConfigProps} from '../FormInputV2'

const FormInputConfigUi: FormInputUiConfigProps = {
  labelType: 'default',
  component: {
    deleteButtonPosition: 'bottom',
    useAccordionTo: 'component',
  },
  upload: {
    filename: 'kemenag',
    folder: 'dokumen',
    uniqueId: 'aplikasinyakemenag',
  },
}

export default FormInputConfigUi
