import {DataRoutesProps} from '../../../UserRolesValidation'
import {MainRoutes as PermohonanCuti} from './PermohonanCuti'

const MainRoutes: DataRoutesProps[] = [
  {
    isPage: true,
    title: 'Semua',
    icon: 'element-10',
    route: 'semua',
    elements: <></>,
    subroute: PermohonanCuti(),
  },
  {
    isPage: true,
    title: 'Persetujuan',
    icon: 'element-10',
    route: 'persetujuan',
    elements: <></>,
    subroute: PermohonanCuti('persetujuan'),
  },
  {
    isPage: true,
    title: 'Ditolak',
    icon: 'element-10',
    route: 'ditolak',
    elements: <></>,
    subroute: PermohonanCuti('ditolak'),
  },
  {
    isPage: true,
    title: 'Selesai',
    icon: 'element-10',
    route: 'selesai',
    elements: <></>,
    subroute: PermohonanCuti('selesai'),
  },
]

export {MainRoutes}
