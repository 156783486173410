import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfTabCol,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {AlphabetIndex, NullProof, capitalizeEachWord} from '../../../../helper/AppFunction'
import {filterCapitalizeProvinsi, UrutkanAnggotaSt} from '../../../../helper/KemenagFunction'

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const hasTemuan = false
  return (
    <Document title='Dokumen'>
      <Page
        size={'FOLIO'}
        wrap={false}
        style={{
          ...PdfStyle.page,
          position: 'relative',
          gap: '5px',
        }}
      >
        <PdfHeaderApp data={data} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
            width: '90%',
            marginHorizontal: 'auto',
          }}
        ></View>
        <View
          style={{
            marginHorizontal: 'auto',
            width: '90%',
            justifyContent: 'center',
            letterSpacing: '0.5px',
          }}
        >
          <View
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
            >
              Surat Keterangan Bebas Temuan
            </Text>
            <Text>
              NOMOR: {NullProof({input: data.data, params: 'nomor_surat', label: 'Nomor Surat'})}
            </Text>
          </View>
          <View style={{marginVertical: '5px'}}>
            <Text>Yang bertanda tangan di bawah ini,</Text>
          </View>
          <View style={{marginVertical: '5px'}}>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Nama</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({input: data.data, params: 'penandatangan2.nama', label: 'Nama'})}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>NIP</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({input: data.data, params: 'penandatangan2.nip', label: 'NIP'})}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Pangkat/Golongan</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({
                  input: data.data,
                  params: 'penandatangan2.pangkat.golongan',
                  label: 'Pangkat/Golongan',
                })}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Jabatan</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({
                  input: data.data,
                  params: 'penandatangan2.jabatan.jabatan',
                  label: 'Jabatan',
                })}
              </Text>
            </View>
          </View>
          <View style={{marginVertical: '5px'}}>
            <Text>dengan ini menerangkan bahwa</Text>
          </View>
          <View style={{marginVertical: '5px'}}>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Nama</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({input: data.data, params: 'pengusul.nama', label: 'Nama'})}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>NIP</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({input: data.data, params: 'pengusul.nip', label: 'NIP'})}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Pangkat/Golongan</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({
                  input: data.data,
                  params: 'pengusul.pangkat.golongan',
                  label: 'Pangkat/Golongan',
                })}
              </Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{width: '30%'}}>Jabatan</Text>
              <Text style={{width: '3%'}}>:</Text>
              <Text style={{width: '67%'}}>
                {NullProof({
                  input: data.data,
                  params: 'pengusul.jabatan.jabatan',
                  label: 'Jabatan',
                })}
              </Text>
            </View>
          </View>
          <View style={{marginVertical: '5px'}}>
            <Text hyphenationCallback={(e: any) => [e]}>
              berdasarkan database pengelolaan hasil pengawasan Inspektorat Jenderal Kementerian
              Agama, update tertanggal hari dikeluarkannya surat ini, pegawai tersebut diatas{' '}
              {hasTemuan ? 'masih memiliki' : 'tidak memiliki'} tanggungan temuan atau tagihan
              tindak lanjut atas rekomendasi hasil pemeriksaan BPK, BPKP, maupun hasil pengawasan
              Itjen Kemenag. Apabila di kemudian hari ternyata terdapat kekeliruan dalam Surat
              Keterangan Bebas Temuan ini, akan dilakukan perubahan sebagaimana mestinya.
            </Text>
            <Text style={{textIndent: '20px'}} hyphenationCallback={(e: any) => [e]}>
              Demikian surat keterangan ini dibuat untuk dapat dipergunakan sebagaimana mestinya.
            </Text>
          </View>
          <View style={{}}>
            <PdfSignatureBuilder
              data={[
                {
                  line1: `${NullProof({
                    input: data.data,
                    params: 'lokasi',
                  })},`,
                  // line2: NullProof({
                  //   input: data.data,
                  //   params: 'penandatangan_2_detail.atas_nama',
                  // }),
                  line2: 'a.n Inspektur Jenderal',
                  // line3: NullProof({
                  //   input: data.data,
                  //   params: 'penandatangan_2_detail.jabatan',
                  // }),
                  line3: `${NullProof({
                    input: data.data,
                    params: 'penandatangan2.jabatan.jabatan',
                    label: 'Jabatan',
                  })},`,
                  typeSignature: 'string',
                  signature: '$',
                  // name: `${capitalizeEachWord(
                  //   NullProof({input: data.data, params: 'penandatangan_2.nama'}).toLowerCase()
                  // )}`,
                  name: NullProof({
                    input: data.data,
                    params: 'penandatangan2.nama',
                    label: 'Nama',
                  }),
                  line1Props: {
                    text: {textAlign: 'left'},
                    view: {},
                  },
                  align: 'flex-start',
                },
              ]}
              options={{
                align: 'flex-end',
                data: {
                  align: 'flex-start',
                  signature: {style: {textAlign: 'left', marginLeft: '20pt'}},
                  line2StyleView: {marginBottom: 0},
                  line2Style: {textAlign: 'left', marginLeft: '-20pt'},
                  line3Style: {textAlign: 'left'},
                },
              }}
              style={{paddingRight: '0', marginBottom: '50pt'}}
            />
          </View>
          <View style={{marginVertical: '5px'}}>
            <Text>Tembusan:</Text>
            <Text>Inspektur Jenderal Kementerian Agama</Text>
          </View>
        </View>
        <View style={{paddingBottom: '70px'}}></View>
      </Page>
    </Document>
  )
}

export default PdfLayout
