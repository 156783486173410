const formUsulanDataParent = [
  '$parent/list-usulan-pegawai',
  '$parent/list-usulan-tujuan',
  '$parent/list-usulan-tugas-tujuan',
  '$parent/list-usulan-tugas-pegawai',
  '$parent/previewst-pdf',
  '$parent/adjustment-rab',
]

const formPermohonanCuti = [
  '$parent/semua',
  '$parent/persetujuan',
  '$parent/ditolak',
  '$parent/selesai',
]

const endSubAction = (data: string[], endSub?: string) => {
  let result: string[] = []
  data.map((d: string) => {
    result.push(`${d}/${endSub}`)
  })
  return result
}

const bindRoute: {parent: string; data: string[]}[] = [
  {
    parent: 'pengawasan/persiapan/form_usulan/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/persetujuan/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/paraf/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/tanda_tangan/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/ditolak/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/selesai/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'pengawasan/persiapan/daftar_status_usulan/revisi/detail/:id',
    data: formUsulanDataParent,
  },
  {
    parent: 'kepegawaian/cuti-pegawai/permohonan-cuti',
    data: formPermohonanCuti,
  },
  {
    parent: 'kepegawaian/cuti-pegawai/permohonan-cuti/detail/:id',
    data: endSubAction(formPermohonanCuti, 'detail/:id'),
  },
  {
    parent: 'kepegawaian/cuti-pegawai/permohonan-cuti/pdf/:id',
    data: endSubAction(formPermohonanCuti, 'pdf/:id'),
  },
  {
    parent: 'kepegawaian/cuti-pegawai/permohonan-cuti/ubah/:id',
    data: endSubAction(formPermohonanCuti, 'ubah/:id'),
  },
  {
    parent: 'kepegawaian/cuti-pegawai/permohonan-cuti/tambah',
    data: endSubAction(['$parent/semua'], 'tambah'),
  },
]

const whitelistRoutes = [
  'profil/info',
  'profil/tugas',
  'profil/keuangan',
  'profil/edit',
  'pengawasan/persiapan/form_usulan/detail/:id/anggota-pdf',
]

export {whitelistRoutes, bindRoute}
