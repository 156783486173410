/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputStatus} from './InputLayout'
import {CardUi} from '../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../helper/TableInput'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  numberlistPagination,
} from '../../../helper/AppFunction'
import FormInput from '../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../helper/TableInputDetail'
import {formatDate} from '../../../helper/DateFns'
import Modal from '../../../helper/Modal'
import {ProtectComponent} from '../../../helper/UserRolesValidation'
import {PdfPageViewer} from '../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {useAuth} from '../../../modules/auth'
import {useSelector} from 'react-redux'

const onChange = (e: any) => {
  const _data = e._data.get
  const _apiData = e._apiData.get
  let tahun = formInputListSelector({formInputList, name: 'tahun'})
  if (_data.rencana_audit_jangka_menengah_id) {
    tahun.disabled = false
    let tahunList: any[] = []
    const detail = _apiData?.rencana_audit_jangka_menengah_id_detail
    for (let tahun = detail?.periode_start; tahun <= detail?.periode_end; tahun++) {
      tahunList.push({
        title: tahun.toString(),
        value: tahun,
      })
    }
    tahun.listData = tahunList
  } else {
    // tahun.disabled = true
  }
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  {/* <TableColumn label='Auditi' props={{style: {minWidth: '200px'}}}>
                    <b>Auditi</b>:{' '}
                    {NullProof({
                      input: l,
                      params: 'rencana_audit_jangka_menengah.satker.nama_satker',
                    })}{' '}
                    <br />
                    <b>Risiko</b> :{' '}
                    {NullProof({
                      input: l,
                      params: 'besaran_resiko_audit',
                    })}
                  </TableColumn> */}
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '300px'}}}>
                    <b>Tema</b> :{' '}
                    {NullProof({
                      input: l,
                      params: 'tema',
                    })}{' '}
                    <br />
                    <b>Topik</b> :{' '}
                    {NullProof({
                      input: l,
                      params: 'topik',
                    })}{' '}
                    <br />
                    <b>Kegiatan</b> :{' '}
                    {NullProof({
                      input: l,
                      params: 'nama_kegiatan',
                    })}
                  </TableColumn>
                  {/* <TableColumn label='Tanggal' props={{style: {minWidth: '300px'}}}>
                    <b>Mulai </b> :
                    {formatDate({
                      date: NullProof({input: l, params: 'tgl_mulai'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}{' '}
                    <br />
                    <b>Selesai </b> :
                    {formatDate({
                      date: NullProof({input: l, params: 'tgl_selesai'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}{' '}
                  </TableColumn> */}
                  {/* <TableColumn label='Nama Auditor' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'list_auditor', isMap: true})?.map(
                      (l1: any, i1: number) => (
                        <Fragment key={i1}>
                          <div>
                            {`${i1 + 1}. ${NullProof({
                              input: l1,
                              params: 'pegawai.nama',
                            })}`}{' '}
                            <br /> <b>Jabatan</b> :{' '}
                            {NullProof({
                              input: l1,
                              params: 'jabatan',
                            })}
                          </div>
                        </Fragment>
                      )
                    )}
                  </TableColumn> */}
                  <TableColumn label='MAK' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'kode_mak'})}
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'status'})}
                  </TableColumn>
                  <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                    <div>
                      <span>NIP: </span>
                      <span>{NullProof({input: l, params: 'pengusul.nip'})}</span>
                    </div>
                    <div>
                      <span>Nama: </span>
                      <span>{NullProof({input: l, params: 'pengusul.nama'})}</span>
                    </div>
                  </TableColumn>
                  <TableColumn label='Anggaran' props={{style: {minWidth: '200px'}}}>
                    <b>Anggaran</b> :{' '}
                    {ConvertCurrency(
                      NullProof({input: l, params: 'nilai_anggaran', isLabel: false}) || 0
                    )}{' '}
                    <br />
                    <b>Realisasi</b> :{' '}
                    {ConvertCurrency(
                      NullProof({input: l, params: 'total_realisasi', isLabel: false}) || 0
                    )}{' '}
                    <br />
                    <b>Sisa</b> :{' '}
                    {ConvertCurrency(
                      NullProof({input: l, params: 'sisa_anggaran', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableColumn label='Jenis' props={{style: {minWidth: '200px'}}}>
                    <b>Kegiatan</b> : {NullProof({input: l, params: 'jenis', isLabel: false})}{' '}
                    <br />
                    <b>Penugasan</b> : {NullProof({input: l, params: 'jenis_pkpt', isLabel: false})}
                  </TableColumn>
                  <TableColumn label='Wilayah Kerja' props={{style: {minWidth: '200px'}}}>
                    {NullProof({
                      input: l,
                      params: 'kode_satker',
                    })}
                  </TableColumn>
                  {/* <td style={{minWidth: '200px'}}>{'-'}</td> */}
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<boolean>(false)
  const [key, setKey] = useState<number>(0)

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()

  const isAdmin = auth?.data?.role_id === 1
  const listPermission: string[] = routeData?.route?.filter((e: string) =>
    e.includes('perencanaan_anggaran/upkat')
  )
  const hasUbahStatus = listPermission.some((permission) => permission.includes('ubah_status/:id'))
  return (
    <>
      <Modal
        id='modal_status'
        isShow={modal}
        onClose={() => {
          setModal(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal(false)
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tahun'}}>
            {NullProof({input: data, params: 'tahun'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis Penugasan'}}>
            {NullProof({input: data, params: 'jenis_pkpt'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tema'}}>
            {NullProof({input: data, params: 'tema'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Topik'}}>
            {NullProof({input: data, params: 'topik'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kegiatan'}}>
            {NullProof({input: data, params: 'nama_kegiatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jenis'}}>
            {NullProof({input: data, params: 'jenis'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kode MAK'}}>
            {NullProof({input: data, params: 'kode_mak'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'MAK'}}>
            {NullProof({input: data, params: 'mak'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Wilayah Kerja'}}>
            {NullProof({input: data, params: 'kode_satker'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nilai Anggaran'}}>
            {NullProof({input: data, params: 'nilai_anggaran', type: 'currency'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            <TableInputDetailColumn hideDivider title={{value: 'Dari'}}>
              {formatDate({
                date: NullProof({input: data, params: 'tgl_mulai'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Sampai'}}>
              {formatDate({
                date: NullProof({input: data, params: 'tgl_selesai'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Biaya'}}>
            {ConvertCurrency(NullProof({input: data, params: 'biaya', isLabel: false}))}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Auditor'}}>
            {NullProof({input: data, params: 'list_auditor', isMap: true})?.map(
              (l1: any, i1: number) => (
                <Fragment key={i1}>
                  <div>
                    {`${NullProof({
                      input: l1,
                      params: 'pegawai.nama',
                    })}`}{' '}
                    <br /> <b>Jabatan</b> :{' '}
                    {NullProof({
                      input: l1,
                      params: 'jabatan',
                    })}
                  </div>
                </Fragment>
              )
            )}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'LHA'}}>
            {NullProof({input: data, params: 'lha'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Unit Auditori'}}>
            {NullProof({input: data, params: 'unit_auditori'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Keterangan'}}>
            {NullProof({input: data, params: 'keterangan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status'}}>
            <div className='d-flex align-items-center gap-2'>
              <div>{NullProof({input: data, params: 'status'})}</div>
              {(hasUbahStatus || isAdmin) && (
                <div>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal(true)
                    }}
                  >
                    Ubah Status
                  </button>
                </div>
              )}
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Persetujuan Oleh'}}>
            {NullProof({input: data, params: 'persetujuan.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Persetujuan Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'persetujuan_tgl'}),
              dateFormat: 'dd MMMM yyyy, HH:mm:ss',
            })}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper, PdfWrapper}
