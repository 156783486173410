/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableColumn, TableInput, TableRow} from '../../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
import FormInput, {BaseInputOnChangeProps} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import ReactIcon from '../../../../helper/ReactIcon'
import {hasAccessRoutes, ProtectComponent} from '../../../../helper/UserRolesValidation'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../../modules/auth'

const onChange = (e: BaseInputOnChangeProps, query: any) => {
  if (e.type === 'onChange' || (e.type === 'onInit' && e?.clickId === 'api')) {
    const data = e._data.get
    const dataObj = e._data.getObject
    const apiData = e._apiData.server
    const sub_kegiatan_id = query?.sub_kegiatan
    let result = {...data, sub_kegiatan_id}
    let daftarKegiatanJumlahHarga: number = 0
    let hitunganInfoKegiatan = {}
    const loadInfoKegiatan = (l: any, i: number, name: string) => {
      let infoKegiatanJumlahHarga: number = 0
      const n_infokegiatan = `info_kegiatan`
      let detailInfoKegiatan: any = {}
      if (e.type === 'onInit' && e?.clickId === 'api') {
        try {
          detailInfoKegiatan = apiData[name][i]
          const detailKegiatanLength =
            detailInfoKegiatan?.detailkegiatan?.length ||
            detailInfoKegiatan?.detail_kegiatan?.length ||
            0
          hitunganInfoKegiatan = {
            ...hitunganInfoKegiatan,
            [`${n_infokegiatan}_nama_${i + 1}`]: detailInfoKegiatan?.nama,
            [`${n_infokegiatan}_detail_kegiatan_${i + 1}`]: detailKegiatanLength,
          }
        } catch (_) {}
      }
      const loadDetailKegiatan = (ll: any, ii: number) => {
        let jsonDetailKegiatan: any = {}
        let detailKegiatan: any = {}
        try {
          if (e.type === 'onInit' && e?.clickId === 'api') {
            try {
              detailKegiatan = detailInfoKegiatan['detailkegiatan'][ii]
            } catch (_) {}
            try {
              detailKegiatan = detailInfoKegiatan['detail_kegiatan'][ii]
            } catch (_) {}
          }
          let harga = Number(ll[`harga`] || detailKegiatan?.harga || 0)
          let volume = Number(ll['volume'] || detailKegiatan?.volume || 0)
          jsonDetailKegiatan = {
            ...jsonDetailKegiatan,
            nama: ll[`nama`] || detailKegiatan?.nama,
            volume_jenis: ll[`volume_jenis`] || detailKegiatan?.volume_jenis,
            harga: harga,
            volume: volume,
            jumlah_harga: Number(harga * volume || detailKegiatan?.jumlah_harga || 0),
          }
          const n_detailkegiatan = `${n_infokegiatan}_detail_kegiatan_${i + 1}`
          infoKegiatanJumlahHarga += Number(jsonDetailKegiatan?.jumlah_harga)
          hitunganInfoKegiatan = {
            ...hitunganInfoKegiatan,
            [`${n_detailkegiatan}_nama_${ii + 1}`]: jsonDetailKegiatan?.nama,
            [`${n_detailkegiatan}_volume_jenis_${ii + 1}`]: jsonDetailKegiatan?.volume_jenis,
            [`${n_detailkegiatan}_harga_${ii + 1}`]: jsonDetailKegiatan?.harga,
            [`${n_detailkegiatan}_volume_${ii + 1}`]: jsonDetailKegiatan?.volume,
            [`${n_detailkegiatan}_jumlah_harga_${ii + 1}`]: jsonDetailKegiatan?.jumlah_harga,
          }
        } catch (_) {}
      }
      if (e.type === 'onInit' && e?.clickId === 'api') {
        if ('detailkegiatan' in detailInfoKegiatan) {
          NullProof({input: detailInfoKegiatan, params: 'detailkegiatan', isMap: true}).map(
            (ll: any, ii: number) => {
              loadDetailKegiatan(ll, ii)
            }
          )
        } else if ('detail_kegiatan' in detailInfoKegiatan) {
          NullProof({input: detailInfoKegiatan, params: 'detail_kegiatan', isMap: true}).map(
            (ll: any, ii: number) => {
              loadDetailKegiatan(ll, ii)
            }
          )
        }
      } else {
        NullProof({input: l, params: 'detail_kegiatan', isMap: true}).map((ll: any, ii: number) => {
          loadDetailKegiatan(ll, ii)
        })
      }
      daftarKegiatanJumlahHarga += infoKegiatanJumlahHarga
      hitunganInfoKegiatan = {
        ...hitunganInfoKegiatan,
        [`${n_infokegiatan}_jumlah_harga_${i + 1}`]: infoKegiatanJumlahHarga,
      }
    }
    if (e.type === 'onInit' && e?.clickId === 'api') {
      if ('infokegiatan' in apiData) {
        NullProof({input: apiData, params: 'infokegiatan', isMap: true}).map(
          (l: any, i: number) => {
            loadInfoKegiatan(l, i, 'infokegiatan')
          }
        )
      } else if ('info_kegiatan' in apiData) {
        NullProof({input: apiData, params: 'info_kegiatan', isMap: true}).map(
          (l: any, i: number) => {
            loadInfoKegiatan(l, i, 'info_kegiatan')
          }
        )
      }
    } else {
      NullProof({input: dataObj, params: 'info_kegiatan', isMap: true}).map((l: any, i: number) => {
        loadInfoKegiatan(l, i, 'info_kegiatan')
      })
    }
    hitunganInfoKegiatan = {
      ...hitunganInfoKegiatan,
      info_kegiatan: `${apiData?.infokegiatan?.length || dataObj?.info_kegiatan?.length}`,
      [`jumlah_harga`]: daftarKegiatanJumlahHarga,
    }
    //
    e.resetForm({values: {...result, ...hitunganInfoKegiatan}})
  }
}

const MainWrapper: FC = () => {
  const query = useParams()
  console.log(query)
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id

  const checkProtect = (search: string, value: string) => {
    return hasAccessRoutes(routeData?.route, search, roleId === 1, true) ? value : ''
  }

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={setModal}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          route={{
            query: checkProtect(
              `perencanaan_anggaran/pok/kode-kegiatan/:id/program/:program/kegiatan/:kegiatan/sub-kegiatan/:sub-kegiatan/daftar-kegiatan/:daftar_kegiatan/$query=get=all`,
              'get=all'
            ),
            url: InfoData.api_index,
          }}
          onFeedback={(e) => {
            if (e?.id === 'lihat') {
              console.log(e)
            }
          }}
          sidebar={
            <>
              <ProtectComponent id='tambah'>
                <button
                  type='button'
                  onClick={() => navigate('./tambah')}
                  className='btn btn-primary d-flex align-items-center gap-1'
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                  Tambah
                </button>
              </ProtectComponent>
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn label='Kode' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'kode'})}
                  </TableColumn>
                  <TableColumn label='Nama' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                  <TableColumn label='Jumlah Harga' props={{style: {minWidth: '200px'}}}>
                    {NullProof({
                      input: l,
                      params: 'jumlah_harga',
                      isLabel: false,
                      type: 'currency',
                    }) || 0}
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:daftar_kegiatan`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={(e) => onChange(e, query)}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={(e) => onChange(e, query)}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Kode'}}>
              {NullProof({input: data, params: 'kode'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama'}}>
              {NullProof({input: data, params: 'nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jumlah Harga'}}>
              {NullProof({input: data, params: 'jumlah_harga', type: 'currency'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Info Kegiatan'}}></TableInputDetailColumn>
            {NullProof({input: data, params: 'info_kegiatan', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <TableInputDetailColumn
                    title={{value: NullProof({input: l, params: 'nama'})}}
                    hideDivider
                  ></TableInputDetailColumn>
                  <TableInputDetailColumn title={{value: 'Jumlah Harga'}} hideDivider>
                    {NullProof({input: l, params: 'jumlah_harga', type: 'currency'})}
                  </TableInputDetailColumn>
                  {NullProof({input: l, params: 'detail_kegiatan', isMap: true})?.map(
                    (ll: any, ii: number) => (
                      <Fragment key={ii}>
                        <TableInputDetailColumn
                          hideDivider
                          title={{value: ''}}
                          props={{
                            className: 'ms-4',
                          }}
                        >
                          <TableInputDetailColumn hideDivider>
                            <div className='mb-2'>
                              {ii + 1}. {NullProof({input: ll, params: 'nama'})}
                            </div>
                          </TableInputDetailColumn>
                          <TableInputDetailColumn
                            title={{
                              value: `Volume (${NullProof({input: ll, params: 'volume_jenis'})})`,
                            }}
                            hideDivider
                          >
                            {NullProof({input: ll, params: 'volume'})}
                          </TableInputDetailColumn>
                          <TableInputDetailColumn title={{value: 'Harga'}} hideDivider>
                            {NullProof({input: ll, params: 'harga', type: 'currency'})}
                          </TableInputDetailColumn>
                          <TableInputDetailColumn title={{value: 'Jumlah Harga'}}>
                            {NullProof({input: ll, params: 'jumlah_harga', type: 'currency'})}
                          </TableInputDetailColumn>
                        </TableInputDetailColumn>
                      </Fragment>
                    )
                  )}
                </Fragment>
              )
            )}
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
