import {NullProof} from '../../../../helper/AppFunction'
import {formatDate} from '../../../../helper/DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../helper/ReactPdf'

const WorksheetRinci = (wb: Workbook, data: PdfLayoutDataProps) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Judulnya'})

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }
  const borderStyle: themeStyleProps = {
    style: {},
    border: {
      borderBottom: true,
      borderLeft: true,
      borderRight: true,
      borderTop: true,
      color: '000000',
      style: 'thin',
    },
  }
  const isCheckedValue = '√'

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 14,
    C: 10,
    D: 10,
    E: 16,
    F: 15,
    G: 20,
    H: 12,
    I: 20,
  }
  setupWidth(columnWidths)

  const getTanggalCuti = (data: any[] = []) => {
    let mulai: string = ''
    let selesai: string = ''
    try {
      mulai = data[0]?.tanggal_mulai
      selesai = data[data.length - 1]?.tanggal_selesai
    } catch (_) {}
    return {mulai, selesai}
  }

  // Start Making ExcelJS
  const logo = wb.workbook.addImage({extension: 'png', base64: data.perusahaan.logo})
  worksheet.addImage(logo, {
    tl: {col: 1, row: 0},
    ext: {width: 100, height: 100},
  })
  cell({
    id: 'C1:I1',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 14, alignment: {h: 'center'}}},
  }).value = 'Kementrian Agama Republik Indonesia'.toUpperCase()
  cell({
    id: 'C2:I2',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 12, alignment: {h: 'center'}}},
  }).value = 'Inspektorat Jenderal'.toUpperCase()
  cell({
    id: 'C3:I3',
    options: {isMerged: true},
    style: {fontConfig: {size: 9, alignment: {h: 'center'}}},
  }).value = 'Jalan RS Fatmawati Nomor 33A Cipete Jakarta 12420 PO. BOX 3867'
  cell({
    id: 'C4:I4',
    options: {isMerged: true},
    style: {fontConfig: {size: 9, alignment: {h: 'center'}}},
  }).value = 'Telepon 021-75916038, 7697853, 7691849 Faksimile. 021-7692112'
  cell({
    id: 'C5:I5',
    options: {isMerged: true},
    style: {fontConfig: {size: 9, alignment: {h: 'center'}}},
  }).value = 'Website : www.itjen.kemenag.go.id'

  worksheet.getRow(6).height = 5
  worksheet.getRow(7).height = 10

  cell({
    id: 'B6:I6',
    options: {isMerged: true},
    border: {
      borderLeft: false,
      borderRight: false,
      borderTop: false,
      borderBottom: true,
      color: '000000',
      style: 'thick',
    },
  })
  // Data

  cell({
    id: 'G8',
    style: {fontConfig: {size: 12, alignment: {h: 'right'}}},
  }).value = `Jakarta`
  cell({
    id: 'H8:I8',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
  }).value = `${formatDate({
    date: NullProof({input: data, params: 'data.createdAt'}),
    dateFormat: 'dd MMMM yyyy',
  })}`

  worksheet.getRow(9).height = 10

  cell({
    id: 'G10:I10',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
  }).value = 'Kepada'
  cell({
    id: 'G11:I11',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
  }).value = `Yth. Inspektur Jenderal Kementrian Agama`
  cell({
    id: 'G12:I12',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {v: 'bottom'}}},
  }).value = `       Di`
  cell({
    id: 'G13:I13',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {v: 'bottom'}}},
  }).value = `           Tempat`

  cell({
    id: 'B14:I14',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, style: 'bold', alignment: {v: 'middle', h: 'center'}}},
  }).value = `Formulir Permintaan Dan Pemberian Cuti`.toUpperCase()
  worksheet.getRow(14).height = 30

  // Data Pegawai
  cell({
    id: 'B15:I15',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `I. Data Pegawai`.toUpperCase()

  cell({
    id: 'B16',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Nama`
  cell({
    id: 'C16:E16',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.pegawai.nama'})}`
  cell({
    id: 'F16',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `NIP`
  cell({
    id: 'G16:I16',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.pegawai.nip'})}`

  cell({
    id: 'B17',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Jabatan`
  cell({
    id: 'C17:E17',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.pegawai.jabatan.jabatan'})}`
  cell({
    id: 'F17',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Masa Kerja`
  cell({
    id: 'G17:I17',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({
    input: data,
    params: 'data.pegawai.masakerja_tahun',
  })} Tahun ${NullProof({
    input: data,
    params: 'data.pegawai.masakerja_bulan',
  })} Bulan`
  worksheet.getRow(17).height = 30

  cell({
    id: 'B18',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Unit Kerja`
  cell({
    id: 'C18:I18',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.pegawai.unit_kerja'})}`

  cell({id: 'B19:I19', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(19).height = 10

  // Jenis Cuti yang diambil
  cell({
    id: 'B20:I20',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `II. Jenis Cuti yang diambil`.toUpperCase()

  cell({
    id: 'B21:D21',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `1. Cuti Tahunan`
  cell({
    id: 'E21',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() === 'cuti tahunan'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'F21:H21',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `2. Cuti Besar`
  cell({
    id: 'I21',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() === 'cuti besar'
      ? isCheckedValue
      : ''
  }`

  cell({
    id: 'B22:D22',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `3. Cuti Sakit`
  cell({
    id: 'E22',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() === 'cuti sakit'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'F22:H22',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `4. Cuti Melahirkan`
  cell({
    id: 'I22',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() === 'cuti melahirkan'
      ? isCheckedValue
      : ''
  }`

  cell({
    id: 'B23:D23',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `5. Cuti Karena Alasan Penting`
  cell({
    id: 'E23',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
    'cuti karena alasan penting'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'F23:H23',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `6. Cuti Diluar Tanggungan Negara`
  cell({
    id: 'I23',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
    'cuti diluar tanggungan negara'
      ? isCheckedValue
      : ''
  }`

  cell({id: 'B24:I24', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(24).height = 10

  // Alasan Cuti
  cell({
    id: 'B25:I25',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `III. Alasan Cuti`.toUpperCase()
  cell({
    id: 'B26:I26',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.alasan'})}`
  worksheet.getRow(26).height = 30

  cell({id: 'B27:I27', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(27).height = 10

  // Lamanya Cuti
  cell({
    id: 'B28:I28',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `IV. Lamanya Cuti`.toUpperCase()

  cell({
    id: 'B29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Selama`
  cell({
    id: 'C29:E29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.totalHariCuti'})} Hari`
  cell({
    id: 'F29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Mulai tanggal`
  cell({
    id: 'G29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${formatDate({
    date: getTanggalCuti(data?.data?.tanggal).mulai,
    dateFormat: 'dd-MMM-yyyy',
  })}`
  cell({
    id: 'H29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `s/d`
  cell({
    id: 'I29',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${formatDate({
    date: getTanggalCuti(data?.data?.tanggal).selesai,
    dateFormat: 'dd-MMM-yyyy',
  })}`

  cell({id: 'B30:I30', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(30).height = 10

  // Catatan Cuti
  cell({
    id: 'B31:I31',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `V. Catatan Cuti`.toUpperCase()

  cell({
    id: 'B32:E32',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `1. Cuti Tahunan`.toUpperCase()
  cell({
    id: 'F32:H32',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `2. Cuti Besar`.toUpperCase()
  cell({
    id: 'I32',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisa_cuti'})}`

  cell({
    id: 'B33',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Tahun`
  cell({
    id: 'C33',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Sisa`
  cell({
    id: 'D33:E33',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Keterangan`
  cell({
    id: 'F33:H33',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `3. Cuti Sakit`.toUpperCase()
  cell({
    id: 'I33',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisa_cuti'})}`

  cell({
    id: 'B34',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `N-2`
  cell({
    id: 'C34',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisaCuti.n2'})}`
  cell({
    id: 'D34:E34',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisaCuti.n2_keterangan'})}`
  cell({
    id: 'F34:H34',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `4. Cuti Melahirkan`.toUpperCase()
  cell({
    id: 'I34',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisa_cuti'})}`

  cell({
    id: 'B35',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `N-1`
  cell({
    id: 'C35',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisaCuti.n1'})}`
  cell({
    id: 'D35:E35',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisaCuti.n1_keterangan'})}`
  cell({
    id: 'F35:H35',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `5. Cuti Karena Alasan Penting`.toUpperCase()
  cell({
    id: 'I35',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisa_cuti'})}`

  cell({
    id: 'B36',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `N`
  cell({
    id: 'C36',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'dat.sisaCutia.n'})}`
  cell({
    id: 'D36:E36',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisaCuti.n_keterangan'})}`
  cell({
    id: 'F36:H36',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `6. Cuti Di Luar Tanggungan Negara`.toUpperCase()
  cell({
    id: 'I36',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.sisa_cuti'})}`

  cell({id: 'B37:I37', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(37).height = 10

  // Alamat selama menjalankan cuti
  cell({
    id: 'B38:I38',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `VI. Alamat selama menjalankan cuti`.toUpperCase()

  cell({
    id: 'B39:F39',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.alamat_cuti'})}`
  cell({
    id: 'G39',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Telp`.toUpperCase()
  cell({
    id: 'H39:I39',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${NullProof({input: data, params: 'data.pegawai.telepon'})}`
  worksheet.getRow(39).height = 30

  cell({
    id: 'B40:F40',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = ``
  const ttd1 = cell({
    id: 'G40:I40',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  })
  ttd1.value = {
    richText: [
      {text: 'Hormat saya,\n\n\n'},
      {
        text: `(${NullProof({input: data, params: 'data.pegawai.nama'})})\n`,
        font: {underline: true},
      },
      {text: `NIP. ${NullProof({input: data, params: 'data.pegawai.nip'})}`},
    ],
  }
  ttd1.alignment = {wrapText: true, horizontal: 'center', vertical: 'justify'}
  worksheet.getRow(40).height = 90

  cell({id: 'B41:I41', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(41).height = 10

  // Pertimbangan Atasan Langsung
  cell({
    id: 'B42:I42',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `VII. Pertimbangan Atasan Langsung`.toUpperCase()

  cell({
    id: 'B43:C43',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Disetujui`.toUpperCase()
  cell({
    id: 'D43:E43',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Perubahan`.toUpperCase()
  cell({
    id: 'F43:G43',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Ditangguhkan`.toUpperCase()
  cell({
    id: 'H43:I43',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Tidak Disetujui`.toUpperCase()

  cell({
    id: 'B44:C44',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() === 'disetujui'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'D44:E44',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() === 'perubahan'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'F44:G44',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() === 'ditangguhkan'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'H44:I44',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() ===
    'tidak disetujui'
      ? isCheckedValue
      : ''
  }`
  worksheet.getRow(44).height = 30

  cell({
    id: 'B45:G45',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan1_catatan', isLabel: false})
      ? NullProof({input: data, params: 'data.penandatangan1_catatan', isLabel: false})
      : ``
  }`
  const ttd2 = cell({
    id: 'H45:I45',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  })
  ttd2.value = {
    richText: [
      {
        text: `${NullProof({input: data, params: 'data.penandatangan1.nama'})}\n`,
        font: {underline: true},
      },
      {text: `NIP. ${NullProof({input: data, params: 'data.penandatangan1.nip'})}`},
    ],
  }
  ttd2.alignment = {wrapText: true, horizontal: 'center', vertical: 'bottom'}
  worksheet.getRow(45).height = 70

  cell({id: 'B46:I46', options: {isMerged: true}, border: borderStyle.border})
  worksheet.getRow(46).height = 10

  // Keputusan Pejabat yang berwenang memberikan cuti
  cell({
    id: 'B47:I47',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `VII. Keputusan Pejabat yang berwenang memberikan cuti`.toUpperCase()

  cell({
    id: 'B48:C48',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Disetujui`.toUpperCase()
  cell({
    id: 'D48:E48',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Perubahan`.toUpperCase()
  cell({
    id: 'F48:G48',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Ditangguhkan`.toUpperCase()
  cell({
    id: 'H48:I48',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `Tidak Disetujui`.toUpperCase()

  cell({
    id: 'B49:C49',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() === 'disetujui'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'D49:E49',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() === 'perubahan'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'F49:G49',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() === 'ditangguhkan'
      ? isCheckedValue
      : ''
  }`
  cell({
    id: 'H49:I49',
    options: {isMerged: true},
    style: {fontConfig: {size: 12, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() ===
    'tidak disetujui'
      ? isCheckedValue
      : ''
  }`
  worksheet.getRow(49).height = 30

  cell({
    id: 'B50:G50',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  }).value = `${
    NullProof({input: data, params: 'data.penandatangan2_catatan', isLabel: false})
      ? NullProof({input: data, params: 'data.penandatangan2_catatan', isLabel: false})
      : ``
  }`
  const ttd3 = cell({
    id: 'H50:I50',
    options: {isMerged: true},
    style: {fontConfig: {size: 12}},
    border: borderStyle.border,
  })
  ttd3.value = {
    richText: [
      {
        text: `${NullProof({input: data, params: 'data.penandatangan2.nama'})}\n`,
        font: {underline: true},
      },
      {text: `NIP. ${NullProof({input: data, params: 'data.penandatangan2.nip'})}`},
    ],
  }
  ttd3.alignment = {wrapText: true, horizontal: 'center', vertical: 'bottom'}
  worksheet.getRow(50).height = 70

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = {...cell.font, name: 'Arial'}
    })
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const logo = async () => {
    const res = await fetch('/media/logokma.png')
    const blob = await res.blob()
    const base64: string = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
    return base64
  }
  const downloadExcel = async () => {
    const wb = createWorkbook()
    data.perusahaan.logo = await logo()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
