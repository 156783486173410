import {
    MainWrapper,
    PdfWrapper,
    DetailWrapper,
  } from '../../../../../pages/pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/export-surat-tugas/wilayah-sekretariat-anggota/MainWrapper'
  import {DataRoutesProps} from '../../../../UserRolesValidation'

  const ExportSuratTugasWilayahSekretariatAnggotaRoutes: DataRoutesProps[] = [
    {
      icon: '',
      title: '',
      route: '*',
      elements: <MainWrapper />,
      subroute: [],
    },
    {
      icon: '',
      title: '',
      route: 'detail/:id',
      elements: <DetailWrapper />,
      subroute: [],
    },
    {
        icon: '',
        title: '',
        route: 'pdf/:id',
        elements: <PdfWrapper />,
        subroute: [],
      }
  ]

  export {ExportSuratTugasWilayahSekretariatAnggotaRoutes}
