import {PageDataContent} from '../../../../helper/Model'
import {TableInputDataProps} from '../../../../helper/TableInput'
import {TableInputDetailDataProps} from '../../../../helper/TableInputDetail'

interface PageDataProps {
  semua: {
    page: PageDataContent
  }
  detail: {
    page: PageDataContent
  }
  tambah: {
    page: PageDataContent
  }
  ubah: {
    page: PageDataContent
  }
  pdf: {
    page: PageDataContent
  }
  anggota: {
    page: PageDataContent
  }
  tujuan: {
    page: PageDataContent
  }
  spd: {
    page: PageDataContent
  }
  spdpdf: {
    page: PageDataContent
  }
}

export const InfoData = {
  title: 'Form Surat Tugas',
  url: '/pengawasan/persiapan/form_st',
  api: '/pengawasan_internal/surat-tugas',
  spd_api: '/pengawasan_internal/spd/st/:id/anggota/:pegawaiid',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaksanaan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Persiapan Pelaksanaan Pegawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.url,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengusul',
    },
    {
      title: 'Kegiatan',
    },
    {
      title: 'Nomor Surat',
    },
    {
      title: 'Tanggal',
    },
    {
      title: 'Status',
    },
  ],
  link: {
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [
      {
        id: 'id',
        protect: ':id/anggota',
        title: 'Anggota',
        route: 'anggota/:id',
        icon: 'RiUserLine',
      },
      {
        id: 'id',
        protect: ':id/tujuan',
        title: 'Tujuan',
        route: 'tujuan/:id',
        icon: 'RiMapPin2Line',
      },
      {
        id: 'id',
        protect: 'detail/:id',
        title: 'Detail',
        route: 'detail/:id',
      },
      {
        id: 'usulan_id,id',
        protect: 'ubah/:id',
        title: 'Revisi',
        route: '../../form_usulan/revisi/:usulan_id',
      },
      {
        id: 'id',
        protect: 'pdf/:id',
        title: 'Lihat PDF',
        route: 'pdf/:id',
      },
      {
        id: 'id,url_pdf_tte',
        protect: 'pdf/:id',
        icon: 'RiFileList3Line',
        title: 'Download PDF TTE',
        type: 'feedback',
        route: ':url_pdf_tte',
      },
      {
        id: 'id',
        protect: 'delete',
        title: 'Hapus',
        route: '',
      },
    ],
  },
  route: {
    url: InfoData.api,
    // query: 'byuser=true',
  },
}

export const TableInputDataAnggota: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'NIP',
    },
    {
      title: 'Nama',
    },
    {
      title: 'Jabatan',
    },
    // {
    //   title: 'Tujuan',
    // },
    // {
    //   title: 'Satuan Kerja',
    // },
  ],
  link: {
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [],
  },
  route: {
    url: `${InfoData.api}/:id`,
    // query: 'byuser=true',
  },
}

export const TableInputDataTujuan: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Kota',
    },
    {
      title: 'Provinsi',
    },
    {
      title: 'Unit',
    },
    {
      title: 'Anggota',
    },
  ],
  link: {
    export: [
      // {
      //   id: 'pdf',
      //   protect: 'pdf',
      //   title: 'Export PDF',
      //   route: 'pdf/semua',
      // },
    ],
    action: [],
  },
  route: {
    url: `${InfoData.api}/:id`,
    // query: 'byuser=true',
  },
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

export const TableInputDetailDataAnggota: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

export const TableInputDetailDataTujuan: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  anggota: {
    page: {
      title: 'Anggota',
      breadcrumb: {
        title: 'Anggota',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tujuan: {
    page: {
      title: 'Tujuan',
      breadcrumb: {
        title: 'Tujuan',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  spd: {
    // route: {
    //   onInit: Routes.spd,
    //   urlParams: 'id,pegawaiid',
    //   onSubmit: {
    //     type: 'put',
    //     url: Routes.spd,
    //     redirect: true,
    //   },
    //   bindData: [
    //     {
    //       urlParams: 'id,pegawaiid',
    //       url: '#',
    //       params: '',
    //       result: 'surat_tugas_id=:id,anggota_id=:pegawaiid',
    //     },
    //   ],
    // },
    page: {
      title: `SPD`,
      breadcrumb: {
        title: `SPD`,
        data: [
          ...BaseBreadcrumb,
          ...MainBreadcrumb,
          {
            title: 'Anggota',
            path: '/pengawasan/persiapan_audit/form_st/anggota/:id',
            isSeparator: false,
            isActive: true,
          },
          {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
          },
        ],
      },
    },
  },
  spdpdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
