/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, importFormInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {CardUi, DropdownUiV2 as DropdownUi, ModalButtonUi} from '../../../../helper/AppUi'
import {
  TableActionProps,
  TableColumn,
  TableInput,
  TableInputCheckboxDataProps,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {useIntl} from 'react-intl'
import {intlMessage} from '../../../../helper/ReactIntl'
import ReactIcon from '../../../../helper/ReactIcon'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'

const onChangeForm = (e: BaseInputOnChangeProps) => {
  const {clickId, type} = e
  const data = e._data.get
  const apiData = e._apiData?.get
  if (type === 'onChange') {
    if (clickId === 'pegawai_id') {
      const nip =
        (apiData?.pegawai_id_detail?.nip !== '-' && apiData?.pegawai_id_detail?.nip) || data?.nip
      const nama = apiData?.pegawai_id_detail?.nama || data?.nama
      e.resetForm({values: {...data, nip: nip, nama: nama}})
    }
  }
}

interface TableInputListTemuanProps {
  api?: string
  link?: string
  viewOnly?: boolean
}

export const TableInputListTemuan: FC<TableInputListTemuanProps> = ({api, viewOnly, link}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [modal, setModal] = useState<any>([])
  const [modalPopup, setModalPopup] = useState<boolean>(false)

  const [action, setAction] = useState<TableActionProps[]>(TableInputData.action || [])

  useEffect(() => {
    const newAction = [...(action || [])]
    const result: TableActionProps[] = []
    newAction.forEach((l: any) => {
      if (l?.idMenu === 'survey_detail') {
        result.push({
          ...l,
          hide: !modal?.input?.survey?.id,
          protect: 'survey/:lampiran',
          route: `survey/:id`,
        })
      } else if (l?.idMenu === 'survey_ubah') {
        result.push({
          ...l,
          protect: modal?.input?.survey?.id ? 'survey/:lampiran/ubah' : 'survey/:lampiran/tambah',
          icon: modal?.input?.survey?.id ? 'RiDraftLine' : 'RiAddLine',
          title: modal?.input?.survey?.id ? 'Ubah Survey' : 'Tambah Survey',
          route: `survey/${modal?.input?.survey?.id ? `:id/ubah` : ':id/tambah'}`,
        })
      } else {
        result.push({
          ...l,
        })
      }
    })
    setAction(result)
  }, [modal])

  useEffect(() => {
    if (link) {
      const newAction = [...(action || [])]
      const result: TableActionProps[] = []
      newAction.forEach((l: any) => {
        result.push({
          ...l,
          route: `../../${link}/${l.route}`,
        })
      })
      setAction(result)
    }
  }, [link])
  return (
    <TableInput
      {...TableInputData}
      action={action}
      modal={setModal}
      checkbox={setCheckbox}
      onFeedback={(e) => {
        if (e?.idMenu === 'modal') {
          setModalPopup(true)
        }
      }}
      route={{
        url: api || InfoData.api,
      }}
      onSuccess={setData}
      sidebar={
        <>
          {!viewOnly && (
            <>
              <ProtectComponent id='tambah'>
                <button
                  type='button'
                  onClick={() => navigate('./tambah')}
                  className='btn btn-primary d-flex align-items-center gap-1'
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                  {intlMessage(`component.tableinput.button.add`, 'Add', intl)}
                </button>
              </ProtectComponent>
            </>
          )}
        </>
      }
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        return (
          <Fragment key={i}>
            <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
              <TableColumn className='min-w-200px'>
                <div>
                  <div>
                    <b>Nama</b>: {NullProof({input: l, params: 'pengusul.nama'})}
                  </div>
                  <div>
                    <b>NIP</b>: {NullProof({input: l, params: 'pengusul.nip'})}
                  </div>
                </div>
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'no_skbt'})}
              </TableColumn>
              <TableColumn className='min-w-150px'>
                {NullProof({input: l, params: 'keterangan'})}
              </TableColumn>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const MainWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {`${intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.breadcrumb.title, intl)}`}
      </PageTitle>
      <CardUi title={intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.title, intl)}>
        <TableInputListTemuan />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  const intl = useIntl()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Edit {page}`, id: `component.infopage.edit_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, InfoData.title, intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Add {page}`, id: `component.infopage.add_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, InfoData.title, intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}
const DetailWrapper: FC = () => {
  const intl = useIntl()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  const filterInput = () => {
    const removeList = ['pegawai_id']
    const input = [...formInputList]
    const filter = input.filter((l) => !removeList.includes(l.name))
    return filter
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn
              isResponsive='desktop'
              title={{value: 'Pengusul', props: {className: 'mb-2'}}}
            >
              <TableInputDetailColumn hideDivider title={{value: 'Nama'}}>
                {NullProof({input: data, params: 'pengusul.nama'})}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'NIP'}}>
                {NullProof({input: data, params: 'pengusul.nip'})}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nomor SKBT'}}>
              {NullProof({input: data, params: 'no_skbt'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Keterangan'}}>
              {NullProof({input: data, params: 'keterangan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: `Dokumen`}}>
              {NullProof({input: data, params: 'document', isLabel: false}) ? (
                <ModalButtonUi
                  options={{
                    modal: {
                      id: `modal_dokumen`,
                      title: `Dokumen`,
                    },
                    button: {title: 'Lihat Dokumen'},
                  }}
                >
                  <ReactPdfViewer
                    url={`/${NullProof({
                      input: data,
                      params: 'document',
                      isLabel: false,
                    })}`}
                  />
                </ModalButtonUi>
              ) : (
                '-'
              )}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
