import {PageDataContent} from '../../../../helper/Model'
import {TableInputDataProps} from '../../../../helper/TableInputV2'
import {TableInputDetailDataProps} from '../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  id: 'survey_layanan',
  title: 'Survey Layanan',
  path: '/pengawasan/pelayanan_skbt/survey_layanan',
  api: '/pengawasan_internal/survey-skbt',
  api_lampiran: '/pengawasan_internal/lampiran-skbt/:lampiran/survey',
}

const BaseBreadcrumb = [
  {
    title: 'Pelaksanaan Pengawasan Internal',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'BIMA (Bebas Temuan Kementerian Agama)',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  i18n: {baseName: `page.${InfoData.id}`},
  route: {
    url: InfoData.api,
  },
  header: [
    {
      title: 'No',
      props: {
        className: '',
        style: {
          minWidth: '70px',
        },
      },
    },
    {
      title: 'Nama',
      id: 'nama',
    },
    {
      title: 'Triwulan',
      id: 'bulan_layanan',
    },
    {
      title: 'Email',
      id: 'email',
    },
  ],
  action: [
    {
      id: 'id',
      idMenu: 'detail',
      protect: 'detail/:id',
      title: 'Detail',
      route: 'detail/:id',
    },
    {
      id: 'id',
      idMenu: 'ubah',
      icon: 'RiDraftLine',
      protect: 'ubah/:id',
      title: 'Ubah',
      route: 'ubah/:id',
    },
    {
      id: 'id',
      idMenu: 'pdf',
      protect: 'pdf/:id',
      title: 'View PDF',
      route: 'pdf/:id',
    },
    {
      id: 'id',
      idMenu: 'delete',
      icon: 'RiDeleteBinLine',
      protect: 'delete',
      title: 'Delete',
      route: '',
    },
  ],
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
