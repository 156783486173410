import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  FontConfig,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfSignatureBuilder,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
  PdfTableColumnBuilder,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {
  AlphabetIndex,
  capitalizeEachWord,
  ConvertCurrency,
  NullProof,
  ReactSrc,
} from '../../../../helper/AppFunction'
import {getSisaCuti} from './MainFunction'

const PoppinsConfig: FontConfig = {
  name: 'Poppins',
  id: 'poppins',
  variant: 'latin',
  weight: [400, 500, 700],
  style: ['normal', 'italic'],
}

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const isCheckedValue = '√'
  const getTanggalCuti = (data: any[] = []) => {
    let mulai: string = ''
    let selesai: string = ''
    try {
      mulai = data[0]?.tanggal_mulai
      selesai = data[data.length - 1]?.tanggal_selesai
    } catch (_) {}
    return {mulai, selesai}
  }
  const sisa = getSisaCuti(data.data, 'sisaCuti', false)
  return (
    <Document title='Dokumen'>
      <Page wrap={false} size={[700]} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
        <PdfHeaderApp data={data} />
        <View
          style={{
            height: '2px',
            backgroundColor: '#000000',
          }}
        ></View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          {/* <View style={{width: '30%'}}>
            <Text style={{marginVertical: '5px'}}>
              Jakarta,{' '}
              {formatDate({
                date: getTanggalCuti(data?.data?.tanggal).mulai,
                dateFormat: 'dd MMMM yyyy',
              })}
            </Text>
            <View style={{marginVertical: '5px'}}>
              <Text>Kepada</Text>
              <Text>Yth. {NullProof({input: data, params: 'pegawai.nama'})}</Text>
            </View>
            <Text style={{marginVertical: '5px'}}>
              Melalui {NullProof({input: data, params: 'pegawai.nama'})}
            </Text>
          </View> */}
          <View style={{width: '30%'}}>
            <Text style={{marginVertical: '5px'}}>
              Jakarta,{' '}
              {formatDate({
                date: NullProof({input: data, params: 'data.createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </Text>
            <View style={{marginVertical: '5px'}}>
              <Text style={{marginLeft: '20pt'}}>Kepada</Text>
              <Text hyphenationCallback={(e: any) => [e]}>
                Yth. Inspektur Jenderal Kementrian Agama
              </Text>
            </View>
            <View>
              <Text style={{marginLeft: '20pt'}}>Di</Text>
              <Text style={{marginLeft: '40pt'}}>Tempat</Text>
            </View>
          </View>
        </View>
        <View style={{width: '100%'}}>
          <Text style={{textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center'}}>
            Formulir Permintaan Dan Pemberian Cuti
          </Text>
        </View>
        <PdfTableColumnBuilder border wrap direction='column' style={{marginBottom: '10px'}}>
          {/* Data Pegawai */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>I. Data Pegawai</Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px'}}>Nama</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '30%'}}>
              <Text style={{padding: '5px'}}>
                {capitalizeEachWord(NullProof({input: data, params: 'data.pegawai.nama'}))}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px'}}>NIP</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '40%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.pegawai.nip'})}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px'}}>Jabatan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '30%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.pegawai.jabatan.jabatan'})}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px'}}>Masa Kerja</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '40%'}}>
              <Text style={{padding: '5px'}}>
                {`${NullProof({
                  input: data,
                  params: 'data.pegawai.masakerja_tahun',
                })} Tahun ${NullProof({
                  input: data,
                  params: 'data.pegawai.masakerja_bulan',
                })} Bulan`}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px'}}>Unit Kerja</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '40%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.pegawai.unit_kerja'})}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          {/* Jenis Cuti yang diambil */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>
              II. Jenis Cuti yang diambil
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '35%'}}>
              <Text style={{padding: '5px'}}>1. Cuti Tahunan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '10%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti tahunan' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '47%'}}>
              <Text style={{padding: '5px'}}>2. Cuti Besar</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center wrap style={{width: '8%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti besar' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '35%'}}>
              <Text style={{padding: '5px'}}>3. Cuti Sakit</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '10%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti sakit' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '47%'}}>
              <Text style={{padding: '5px'}}>4. Cuti Melahirkan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center wrap style={{width: '8%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti melahirkan' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '35%'}}>
              <Text style={{padding: '5px'}}>5. Cuti Karena Alasan Penting</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '10%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti karena alasan penting' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '47%'}}>
              <Text style={{padding: '5px'}}>6. Cuti Diluar Tanggunggan Negara</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center wrap style={{width: '8%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.jenis.jenis'})?.toLowerCase() ===
                  'cuti diluar tanggungan negara' && isCheckedValue}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          {/* Alasan Cuti */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>III. Alasan Cuti</Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px'}}>{NullProof({input: data, params: 'data.alasan'})}</Text>
          </PdfTableColumnBuilder>
          {/* Lamanya Cuti */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>IV. Lamanya Cuti</Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '10%'}}>
              <Text style={{padding: '5px'}}>Selama</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '35%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.totalHariCuti'})} Hari
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '20%'}}>
              <Text style={{padding: '5px'}}>Mulai Tanggal</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '35%'}}>
              <Text style={{padding: '5px'}}>{`${formatDate({
                date: getTanggalCuti(data?.data?.tanggal).mulai,
                dateFormat: 'dd-MMM-yyyy',
              })} s/d ${formatDate({
                date: getTanggalCuti(data?.data?.tanggal).selesai,
                dateFormat: 'dd-MMM-yyyy',
              })}`}</Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          {/* Catatan Cuti */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>V. Catatan Cuti</Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap direction='column' style={{width: '45%'}}>
              <PdfTableColumnBuilder borderBottom wrap>
                <Text style={{padding: '5px', textTransform: 'uppercase'}}>1. Cuti Tahunan</Text>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder borderBottom wrap>
                <PdfTableColumnBuilder borderRight wrap style={{width: '22%'}}>
                  <Text style={{padding: '5px'}}>Tahun</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder borderRight wrap style={{width: '18%'}}>
                  <Text style={{padding: '5px'}}>Sisa</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '60%'}}>
                  <Text style={{padding: '5px'}}>Keterangan</Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder borderBottom wrap>
                <PdfTableColumnBuilder borderRight wrap style={{width: '22%'}}>
                  <Text style={{padding: '5px'}}>N-2</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder borderRight wrap style={{width: '18%'}}>
                  <Text style={{padding: '5px'}}>{sisa.sebelum.n2}</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '60%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisaCuti.n2_keterangan'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder borderBottom wrap>
                <PdfTableColumnBuilder borderRight wrap style={{width: '22%'}}>
                  <Text style={{padding: '5px'}}>N-1</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder borderRight wrap style={{width: '18%'}}>
                  <Text style={{padding: '5px'}}>{sisa.sebelum.n1}</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '60%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisaCuti.n1_keterangan'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder wrap>
                <PdfTableColumnBuilder borderRight wrap style={{width: '22%'}}>
                  <Text style={{padding: '5px'}}>N</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder borderRight wrap style={{width: '18%'}}>
                  <Text style={{padding: '5px'}}>{sisa.sebelum.n}</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '60%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisaCuti.n_keterangan'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap direction='column' style={{width: '55%'}}>
              <PdfTableColumnBuilder
                borderBottom
                wrap
                style={{display: 'flex', alignItems: 'stretch'}}
              >
                <PdfTableColumnBuilder borderRight wrap style={{width: '85%'}}>
                  <Text style={{padding: '5px', textTransform: 'uppercase'}}>2. Cuti Besar</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '15%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisa_cuti'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder
                borderBottom
                wrap
                style={{display: 'flex', alignItems: 'stretch'}}
              >
                <PdfTableColumnBuilder borderRight wrap style={{width: '85%'}}>
                  <Text style={{padding: '5px', textTransform: 'uppercase'}}>3. Cuti Sakit</Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '15%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisa_cuti'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder
                borderBottom
                wrap
                style={{display: 'flex', alignItems: 'stretch'}}
              >
                <PdfTableColumnBuilder borderRight wrap style={{width: '85%'}}>
                  <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                    4. Cuti Melahirkan
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '15%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisa_cuti'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder
                borderBottom
                wrap
                style={{display: 'flex', alignItems: 'stretch'}}
              >
                <PdfTableColumnBuilder borderRight wrap style={{width: '85%'}}>
                  <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                    5. Cuti Karena Alasan Penting
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '15%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisa_cuti'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
              <PdfTableColumnBuilder wrap style={{display: 'flex', alignItems: 'stretch'}}>
                <PdfTableColumnBuilder borderRight wrap style={{width: '85%'}}>
                  <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                    6. Cuti Di Luar Tanggungan Negara
                  </Text>
                </PdfTableColumnBuilder>
                <PdfTableColumnBuilder wrap style={{width: '15%'}}>
                  <Text style={{padding: '5px'}}>
                    {NullProof({input: data, params: 'data.sisa_cuti'})}
                  </Text>
                </PdfTableColumnBuilder>
              </PdfTableColumnBuilder>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          {/* Alamat Selama Menjalankan Cuti */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>
              VI. Alamat Selama Menjalankan Cuti
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '60%'}}>
              <Text style={{padding: '5px'}}>
                {`${NullProof({input: data, params: 'data.alamat_cuti'})}`}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '15%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Telp</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '25%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.pegawai.telepon'})}
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '60%'}}></PdfTableColumnBuilder>
            <View style={{width: '40%'}}>
              <PdfSignatureBuilder
                data={[
                  {
                    line1: `Hormat saya,`,
                    typeSignature: 'string',
                    signature: '*',
                    name: `${capitalizeEachWord(
                      NullProof({input: data.data, params: 'pegawai.nama'})
                    )}\nNIP: ${NullProof({input: data.data, params: 'pegawai.nip'})}`,
                    nameProps: {
                      text: {textAlign: 'center'},
                      view: {marginBottom: '10pt'},
                    },
                    align: 'center',
                  },
                ]}
                options={{
                  align: 'center',
                  data: {
                    align: 'center',
                    signature: {style: {textAlign: 'center'}},
                    line1Style: {marginTop: '10pt'},
                    line2StyleView: {marginBottom: 0},
                    line2Style: {textAlign: 'center'},
                    line3Style: {textAlign: 'center'},
                  },
                }}
                style={{paddingRight: '0', marginBottom: '0', marginTop: '0'}}
              />
            </View>
            {/* <PdfTableColumnBuilder
              wrap
              direction='column'
              style={{width: '40%', justifyContent: 'center', alignItems: 'center'}}
            >
              <Text style={{padding: '5px', textTransform: 'uppercase', marginBottom: '50px'}}>
                Hormat Saya,
              </Text>
              <Text style={{paddingTop: '5px', textTransform: 'uppercase'}}>
                ({NullProof({input: data, params: 'data.pegawai.nip'})})
              </Text>
              <Text style={{paddingBottom: '5px', textTransform: 'uppercase'}}>
                NIP. {NullProof({input: data, params: 'data.pegawai.nip'})}
              </Text>
            </PdfTableColumnBuilder> */}
          </PdfTableColumnBuilder>
          {/* Pertimbangan Atasan Langsung */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>
              VII. Pertimbangan Atasan Langsung
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Disetujui</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Perubahan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Ditangguhkan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '31%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Tidak Disetujui</Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() ===
                'disetujui'
                  ? isCheckedValue
                  : ``}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() ===
                'perubahan'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() ===
                'ditangguhkan'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center wrap style={{width: '31%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan1_status'})?.toLowerCase() ===
                'tidak disetujui'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '69%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.penandatangan1_catatan', isLabel: false})
                  ? NullProof({input: data, params: 'data.penandatangan1_catatan', isLabel: false})
                  : ``}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder
              wrap
              direction='column'
              style={{width: '31%', justifyContent: 'center', alignItems: 'center'}}
            >
              <PdfSignatureBuilder
                data={[
                  {
                    line1: ' ',
                    typeSignature: 'string',
                    signature: '$',
                    name: `${capitalizeEachWord(
                      NullProof({input: data.data, params: 'penandatangan1.nama'})
                    )}\nNIP: ${NullProof({input: data.data, params: 'penandatangan1.nip'})}`,
                    nameProps: {
                      text: {textAlign: 'center'},
                      view: {marginBottom: '10pt'},
                    },
                    align: 'center',
                  },
                ]}
                options={{
                  align: 'center',
                  data: {
                    align: 'center',
                    signature: {style: {textAlign: 'center'}},
                    line1Style: {marginTop: '10pt'},
                    line2StyleView: {marginBottom: 0},
                    line2Style: {textAlign: 'center'},
                    line3Style: {textAlign: 'center'},
                  },
                }}
                style={{paddingRight: '0', marginBottom: '0', marginTop: '0'}}
              />
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          {/* Keputusan Pejabat yang Berwenang Memberikan Cuti */}
          <PdfTableColumnBuilder borderBottom wrap>
            <Text style={{padding: '5px', textTransform: 'uppercase'}}>
              VIII. Keputusan Pejabat yang Berwenang Memberikan Cuti
            </Text>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Disetujui</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Perubahan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Ditangguhkan</Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder wrap style={{width: '31%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>Tidak Disetujui</Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder borderBottom wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() ===
                'disetujui'
                  ? isCheckedValue
                  : ``}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() ===
                'perubahan'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center borderRight wrap style={{width: '23%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() ===
                'ditangguhkan'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder center wrap style={{width: '31%'}}>
              <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                {NullProof({input: data, params: 'data.penandatangan2_status'})?.toLowerCase() ===
                'tidak disetujui'
                  ? isCheckedValue
                  : ``}
                &nbsp;
              </Text>
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
          <PdfTableColumnBuilder wrap style={{display: 'flex', alignItems: 'stretch'}}>
            <PdfTableColumnBuilder borderRight wrap style={{width: '69%'}}>
              <Text style={{padding: '5px'}}>
                {NullProof({input: data, params: 'data.penandatangan2_catatan', isLabel: false})
                  ? NullProof({input: data, params: 'data.penandatangan2_catatan', isLabel: false})
                  : ``}
              </Text>
            </PdfTableColumnBuilder>
            <PdfTableColumnBuilder
              wrap
              direction='column'
              style={{width: '31%', justifyContent: 'center', alignItems: 'center'}}
            >
              <PdfSignatureBuilder
                data={[
                  {
                    line1: ' ',
                    typeSignature: 'string',
                    signature: '^',
                    name: `${capitalizeEachWord(
                      NullProof({input: data.data, params: 'penandatangan2.nama'})
                    )}\nNIP: ${NullProof({input: data.data, params: 'penandatangan2.nip'})}`,
                    nameProps: {
                      text: {textAlign: 'center'},
                      view: {marginBottom: '10pt'},
                    },
                    align: 'center',
                  },
                ]}
                options={{
                  align: 'center',
                  data: {
                    align: 'center',
                    signature: {style: {textAlign: 'center'}},
                    line1Style: {marginTop: '10pt'},
                    line2StyleView: {marginBottom: 0},
                    line2Style: {textAlign: 'center'},
                    line3Style: {textAlign: 'center'},
                  },
                }}
                style={{paddingRight: '0', marginBottom: '0', marginTop: '0'}}
              />
            </PdfTableColumnBuilder>
          </PdfTableColumnBuilder>
        </PdfTableColumnBuilder>
      </Page>
    </Document>
  )
}

export default PdfLayout
