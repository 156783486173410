import HtmlParse from 'html-react-parser'
import ReactDOMServer from 'react-dom/server'
import {
  StyleSheet,
  Font,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Text,
  TextProps,
  View,
  ViewProps,
  pdf,
} from '@react-pdf/renderer'
import {pdfjs} from 'react-pdf'
import {FC, createElement, useEffect, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import {Style} from '@react-pdf/types'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import ReactIcon from './ReactIcon'
import {Html} from 'react-pdf-html'
import {getData} from './FormAxios'
import download from 'downloadjs'
import {checkFileExtension} from './AppFunction'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const defaultFontFamily = 'Arial'
const defaultFontSize = '11pt'

export type FontConfig = {
  name: string
  id: string
  variant: string
  weight: any[]
  style: any[]
}

export const PoppinsConfig = {
  name: 'Poppins',
  id: 'poppins',
  variant: 'latin',
  weight: [400, 500, 700],
  style: ['normal', 'italic'],
}

export const ArialConfig = {
  family: 'Arial',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 400,
      src: '/fonts/arial/regular.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 400,
      src: '/fonts/arial/italic.ttf',
    },
    {
      fontStyle: 'normal',
      fontWeight: 700,
      src: '/fonts/arial/bold.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 700,
      src: '/fonts/arial/bolditalic.ttf',
    },
  ],
}

export const TimesNewRomanConfig = {
  family: 'Times New Roman',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 400,
      src: '/fonts/times/regular.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 400,
      src: '/fonts/times/italic.ttf',
    },
    {
      fontStyle: 'normal',
      fontWeight: 700,
      src: '/fonts/times/bold.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 700,
      src: '/fonts/times/bolditalic.ttf',
    },
  ],
}

export const CalibriConfig = {
  family: 'Calibri',
  fonts: [
    {
      fontStyle: 'normal',
      fontWeight: 300,
      src: '/fonts/calibri/CalibriLight.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 300,
      src: '/fonts/calibri/CalibriLightItalic.ttf',
    },
    {
      fontStyle: 'normal',
      fontWeight: 400,
      src: '/fonts/calibri/CalibriRegular.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 400,
      src: '/fonts/calibri/CalibriItalic.ttf',
    },
    {
      fontStyle: 'normal',
      fontWeight: 700,
      src: '/fonts/calibri/CalibriBold.ttf',
    },
    {
      fontStyle: 'italic',
      fontWeight: 700,
      src: '/fonts/calibri/CalibriBoldItalic.ttf',
    },
  ],
}

export const DefaultPdfLayoutData = {
  image: {},
  perusahaan: {
    logo: '/media/logokma.png',
    nama: 'KEMENTERIAN AGAMA REPUBLIK INDONESIA\nINSPEKTORAT JENDERAL',
    alamat: 'Jalan RS. Fatmawati Nomor 33A Cipete Jakarta 12420',
    telepon: '(021) 75916038-7697853-7691849',
    fax: '(021) 7692112',
    website: 'www.itjen.kemenag.go.id',
  },
  data: {},
}

export type PdfLayoutDataProps = {
  _filename?: string
  image: any
  perusahaan: {
    logo: string
    nama: string
    alamat: string
    telepon: string
    fax: string
    website: string
  }
  data: any
}

const PdfTableHeader: FC<{
  data: {title: string; style?: Style}[]
  style?: Style
  gapHorizontal?: number
  gapVertical?: number
}> = ({data, style, gapHorizontal, gapVertical}) => {
  return (
    <View style={{...style, flexDirection: 'row'}}>
      {data.map((l, i) => (
        <PdfTableColumn
          key={i}
          style={{...l.style}}
          gapHorizontal={gapHorizontal}
          gapVertical={gapVertical}
        >
          <Text>{l.title}</Text>
        </PdfTableColumn>
      ))}
    </View>
  )
}

const PdfTableColumn: FC<
  {style?: Style; gapVertical?: number; gapHorizontal?: number} & WithChildren
> = ({style, gapHorizontal = 5, gapVertical = 0, children}) => {
  return (
    <View
      style={{
        ...style,
        paddingHorizontal: `${gapHorizontal + 'px'}`,
        paddingVertical: `${gapVertical + 'px'}`,
      }}
    >
      {children}
    </View>
  )
}

const PdfViewer: FC<{props: any} & WithChildren> = ({children, props}) => {
  return (
    <PDFViewer className='border-none' width='100%' height='100%' {...props}>
      {children}
    </PDFViewer>
  )
}

export const directDownloadPdf = async ({
  data = {},
  document,
  filename = 'document',
}: {
  document: any
  data?: any
  filename?: string
}) => {
  let _data: PdfLayoutDataProps = {...DefaultPdfLayoutData, data: data}
  let _document: any = createElement(document, {data: _data})
  const blob = await pdf(_document).toBlob()
  download(blob, `${filename}.pdf`, 'application/pdf')
}

const DownloadPdf: FC<{document: any; filename: string; props?: any; label: string}> = ({
  label,
  document,
  filename,
  props,
}) => {
  return (
    <PDFDownloadLink
      className='no-underline select-none'
      document={document}
      fileName={filename + '.pdf'}
    >
      {({blob, url, loading, error}) => {
        return (
          <>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              data-kt-indicator={loading ? 'on' : 'off'}
              disabled={loading}
              {...props}
            >
              <ReactIcon icon='RiDownloadLine' props={{className: 'me-2'}} />
              <span className='indicator-label'>{label}</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </>
        )
      }}
    </PDFDownloadLink>
  )
}

function getFonts(props: {name: string; id: string; variant: string; weight: any[]; style: any[]}) {
  // Source: https://fontsource.org/docs/api/font-id
  const repo = 'https://api.fontsource.org/v1/fonts/$id/$variant-$weight-$style.ttf'
  let list: any = []
  props.style.map((lstyle) => {
    props.weight.map((lweight) => {
      const src = repo
        .replace('$id', props.id)
        .replace('$variant', props.variant)
        .replace('$weight', lweight)
        .replace('$style', lstyle)
      list.push({
        src: src,
        fontStyle: lstyle,
        fontWeight: lweight,
      })
    })
  })
  const result = {
    family: props.name,
    fonts: list,
  }
  return result
}

Font.register(ArialConfig)
Font.register(CalibriConfig)
Font.register(TimesNewRomanConfig)
Font.register(getFonts(PoppinsConfig))

interface PdfTableColumnBuilderProps {
  debug?: boolean
  wrap?: boolean
  center?: boolean
  direction?: 'row' | 'column'
  borderRight?: boolean
  borderBottom?: boolean
  border?: boolean
  style?: ViewProps['style']
}

interface PdfTableTextBuilderProps {
  align?: 'left' | 'center' | 'right' | 'justify' | undefined
  debug?: boolean
  wrap?: boolean
  style?: TextProps['style']
}

interface PdfTabColProps {
  debug?: boolean
  center?: boolean
  type?: 'view' | 'text' | 'html'
  align?: {
    justifyContent?:
      | 'flex-start'
      | 'flex-end'
      | 'center'
      | 'space-around'
      | 'space-between'
      | 'space-evenly'
    alignItems?: 'flex-start' | 'flex-end' | 'center'
  }
  direction?: 'row' | 'column'
  border?: {
    width?: number
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  style?: {
    view?: ViewProps['style']
    text?: TextProps['style']
  }
  noWrap?: boolean
}

interface PdfFontBuilderProps {
  template?: 'header' | 'title' | 'label' | 'custom'
  size?: 'exlarge' | 'large' | 'medium' | 'default' | 'small' | 'exsmall'
  style?: TextProps['style']
}

export const PdfFontBuilder = ({
  template = 'custom',
  size = 'default',
  style,
}: PdfFontBuilderProps) => {
  let result: TextProps['style'] = {
    fontFamily: defaultFontFamily,
    ...style,
  }
  if (template !== 'custom') {
    const fontSizeList: any = {
      'header.exlarge': 24,
      'title.exlarge': 20,
      'label.exlarge': 16,
      'header.large': 22,
      'title.large': 18,
      'label.large': 14,
      'header.default': 20,
      'title.default': 16,
      'label.default': 12,
      'header.small': 17,
      'title.small': 14,
      'label.small': 8,
      'header.exsmall': 14,
      'title.exsmall': 8,
      'label.exsmall': 6,
    }
    result = {...result, fontSize: `${fontSizeList[`${template}.${size}`]}pt`}
  }
  return result
}

export const PdfTabCol: FC<PdfTabColProps & WithChildren> = ({
  debug,
  center,
  type = 'view',
  align = {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  direction = 'row',
  border = {
    width: 1,
    top: 1,
    bottom: 1,
    left: 1,
    right: 1,
  },
  children,
  style,
  noWrap,
}) => {
  function decodeHtmlEntities(encodedString: any) {
    return HtmlParse(encodedString)
  }
  const _styleView: ViewProps['style'] = {
    justifyContent: center ? 'center' : align.justifyContent,
    alignItems: center ? 'center' : align.alignItems,
    flexDirection: direction,
    borderStyle: 'solid',
    borderWidth: border?.width,
    borderTopWidth: border?.top,
    borderRightWidth: border?.right,
    borderBottomWidth: border?.bottom,
    borderLeftWidth: border?.left,
    padding: type === 'text' ? '10pt' : '0',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    ...style?.view,
  }
  const _styleText: TextProps['style'] = {
    fontFamily: defaultFontFamily,
    fontSize: defaultFontSize,
  }
  let _html: any
  if (type === 'html') {
    _html = (
      <>
        {ReactDOMServer.renderToStaticMarkup(
          <html>
            <body style={{padding: 0, margin: 0}}>
              {
                <div
                  //@ts-ignore
                  style={{..._styleText, paddingRight: '30pt'}}
                  //@ts-ignore
                  dangerouslySetInnerHTML={{__html: children}}
                ></div>
              }
              {/* <div>{decodeHtmlEntities(children)}</div> */}
            </body>
          </html>
        )}
        {/* {ReactDOMServer.renderToString(
          //@ts-ignore
          <div style={{..._styleText, height: '500px'}}>{decodeHtmlEntities(children)}</div>
        )} */}
      </>
    )
  }
  return (
    <View debug={debug && type !== 'text'} style={{..._styleView}}>
      {type === 'text' ? (
        <>
          {noWrap ? (
            <Text
              debug={debug && type === 'text'}
              style={{..._styleText}}
              hyphenationCallback={(e: any) => {
                return [e]
              }}
            >
              {children}
            </Text>
          ) : (
            <Text debug={debug && type === 'text'} style={{..._styleText}}>
              {children}
            </Text>
          )}
        </>
      ) : type === 'html' ? (
        <>
          <Text style={{..._styleText}}>_</Text>
          {/* <Html>{_html}</Html> */}
        </>
      ) : (
        children
      )}
    </View>
  )
}

interface PdfSignatureBuilderProps {
  data: {
    align?: 'flex-start' | 'center' | 'flex-end'
    line1?: string
    line2?: string
    line3?: string
    signature?: string
    typeSignature?: 'img' | 'string'
    name?: string
    line1Props?: {
      text: TextProps['style']
      view: ViewProps['style']
    }
    line2Props?: {
      text: TextProps['style']
      view: ViewProps['style']
    }
    line3Props?: {
      text: TextProps['style']
      view: ViewProps['style']
    }
    nameProps?: {
      text: TextProps['style']
      view: ViewProps['style']
    }
  }[]
  options?: {
    align?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
    gap?: number
    data?: {
      align?: 'flex-start' | 'center' | 'flex-end'
      signature?: {
        scale?: number
        style?: TextProps['style']
      }
      nameStyle?: TextProps['style']
      line1Style?: TextProps['style']
      line2Style?: TextProps['style']
      line3Style?: TextProps['style']
      nameStyleView?: ViewProps['style']
      line1StyleView?: ViewProps['style']
      line2StyleView?: ViewProps['style']
      line3StyleView?: ViewProps['style']
    }
  }
  style?: ViewProps['style']
}

export const PdfSignatureBuilder = ({
  data = [],
  options = {align: 'flex-start', gap: 20, data: {align: 'flex-start'}},
  style,
}: PdfSignatureBuilderProps) => {
  return (
    <View
      style={{
        paddingRight: '30pt',
        marginTop: '20pt',
        flexDirection: 'row',
        justifyContent: options?.align || 'flex-start',
        gap: options?.gap || 20,
        rowGap: options?.gap || 20,
        ...style,
      }}
    >
      {data?.map((l, i) => (
        <View key={i}>
          {l.line1 && (
            <View
              style={{
                justifyContent: l.align,
                flexDirection: 'row',
                ...PdfFontBuilder({template: 'label'}),
                ...options.data?.line1StyleView,
                ...l.line1Props?.view,
              }}
            >
              <Text style={{...options.data?.line1Style, ...l.line1Props?.text}}>{l.line1}</Text>
            </View>
          )}
          {l.line2 && (
            <View
              style={{
                marginBottom: '10pt',
                justifyContent: l.align,
                maxWidth: '170pt',
                flexDirection: 'row',
                ...PdfFontBuilder({template: 'label'}),
                ...options.data?.line2StyleView,
                ...l.line2Props?.view,
              }}
            >
              <Text style={{...options.data?.line2Style, ...l.line2Props?.text}}>{l.line2}</Text>
            </View>
          )}
          {l.line3 && (
            <View
              style={{
                marginBottom: '10pt',
                justifyContent: l.align,
                maxWidth: '170pt',
                flexDirection: 'row',
                ...PdfFontBuilder({template: 'label'}),
                ...options.data?.line3StyleView,
                ...l.line3Props?.view,
              }}
            >
              <Text style={{...options.data?.line3Style, ...l.line3Props?.text}}>{l.line3}</Text>
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: l.align,
            }}
          >
            <View
              style={{
                height: '40pt',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {l.typeSignature === 'string' ? (
                <Text style={options.data?.signature?.style}>{l.signature}</Text>
              ) : (
                <Image src={l.signature} style={{width: '100%', aspectRatio: 16 / 9}} />
              )}
            </View>
          </View>
          <View
            style={{
              justifyContent: options?.data?.align || 'flex-start',
              flexDirection: 'row',
              ...options.data?.nameStyleView,
              ...l.nameProps?.view,
            }}
          >
            <Text
              style={{
                marginTop: '10pt',
                ...PdfFontBuilder({template: 'label'}),
                textTransform: 'capitalize',
                ...options.data?.nameStyle,
                ...l.nameProps?.text,
              }}
            >
              {l.name}
            </Text>
          </View>
        </View>
      ))}
    </View>
  )
}

export const PdfTableColumnBuilder: FC<PdfTableColumnBuilderProps & WithChildren> = ({
  debug,
  wrap,
  direction = 'row',
  center,
  borderRight,
  borderBottom,
  border,
  children,
  style,
}) => {
  const defaultValue: ViewProps['style'] = {
    justifyContent: center ? 'center' : 'flex-start',
    alignItems: center ? 'center' : 'flex-start',
    flexDirection: direction,
    borderStyle: 'solid',
    borderWidth: border ? 1 : 0,
    borderBottomWidth: borderBottom || border ? 1 : 0,
    borderRightWidth: borderRight || border ? 1 : 0,
    width: '100%',
    height: '100%',
    ...style,
  }
  if (debug) {
    console.log(defaultValue)
  }
  if (wrap) {
    delete defaultValue.height
  }
  return (
    <View debug={debug} style={defaultValue}>
      {children}
    </View>
  )
}

export const PdfTableTextBuilder: FC<PdfTableTextBuilderProps & WithChildren> = ({
  debug,
  align = 'center',
  style,
  wrap,
  children,
}) => {
  const defaultValue: TextProps['style'] = {
    padding: '10px 20px',
    textAlign: align,
    width: '100%',
    ...style,
  }
  return (
    <>
      {wrap ? (
        <Text
          debug={debug}
          style={defaultValue}
          hyphenationCallback={(e: any) => {
            return [e]
          }}
        >
          {children}
        </Text>
      ) : (
        <Text debug={debug} style={defaultValue}>
          {children}
        </Text>
      )}
    </>
  )
}

const PdfStylePallete = {
  typography: {
    headline: {
      large: '32px',
      medium: '28px',
      small: '24px',
    },
    title: {
      large: '22px',
      medium: '16px',
      small: '14px',
    },
    label: {
      large: '14px',
      medium: '12px',
      small: '11px',
      mini: '9px',
    },
  },
  color: {
    primary: '#2ecc71',
    success: '#27ae60',
  },
  style: {
    writing: {
      letterSpacing: '0.5px',
    },
  },
  signature: {
    width: '150px',
    height: '100px',
    objectFit: 'contain',
  },
  table: {
    style: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    row: {
      margin: 'auto',
      flexDirection: 'row',
    },
    col: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: '2px 4px',
    },
    cell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
    },
  },
}

const PdfStyle = StyleSheet.create({
  page: {
    fontFamily: defaultFontFamily,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '2% 6%',
    letterSpacing: '1.15',
    lineHeight: '1.5',
    fontSize: '11pt',
    flexDirection: 'column',
    gap: '10px',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
})

const PdfCurrencyFormat = (value: number) => {
  let result = new Intl.NumberFormat('id-ID', {
    maximumFractionDigits: 0,
  }).format(value)
  return result
}

interface PdfHeaderAppProps {
  data: any
  customChild?: any
  rightNote?: boolean
  style?: {
    view?: ViewProps['style']
    content?: ViewProps['style']
  }
}

const PdfHeaderApp = (props: PdfHeaderAppProps) => {
  return (
    <>
      {props.customChild ? (
        <>{props.customChild}</>
      ) : (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            lineHeight: '1',
            padding: '0 4%',
            letterSpacing: '.5',
            ...props.style?.view,
          }}
        >
          <Image
            src={props.data?.perusahaan?.logo}
            style={{
              width: '90px',
              height: '90px',
              objectFit: 'contain',
              marginRight: '10px',
            }}
          />
          <View style={{width: '100%', ...props.style?.view}}>
            <View
              style={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: PdfStylePallete.typography.title.small,
                }}
              >
                {props.data?.perusahaan?.nama}
              </Text>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: PdfStylePallete.typography.label.mini,
                }}
              >
                {props.data?.perusahaan?.alamat}
              </Text>
              <Text
                style={{
                  fontWeight: 400,
                  fontSize: PdfStylePallete.typography.label.mini,
                }}
              >
                Telepon {props.data?.perusahaan?.telepon}; Faksimili {props.data?.perusahaan?.fax}
              </Text>
              <Text
                style={{
                  fontWeight: 400,
                  fontStyle: 'italic',
                  fontSize: PdfStylePallete.typography.label.mini,
                }}
              >
                Website {props.data?.perusahaan?.website}
              </Text>
            </View>
          </View>
        </View>
      )}
    </>
  )
}

interface ReactPdfViewerProps {
  title?: string
  url: string
  options?: {
    filename?: string
    hasDownload?: boolean
    isLocalFile?: boolean
    showTitleFilename?: boolean
  }
  onFeedback?: (e?: any) => void
}

const ReactPdfViewer: FC<ReactPdfViewerProps> = ({title, url, onFeedback, options}) => {
  const _options = {hasDownload: true, ...options}
  const [isValidPdf, setIsValidPdf] = useState<boolean>(false)
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(true)
  const [fileInfo, setFileInfo] = useState<any>()
  const [fileUrl, setFileUrl] = useState<string>()
  const [blobData, setBlobData] = useState<string>()
  const [_url, _setUrl] = useState<string>()

  const getFileNameUpload = (url: string) => {
    const urlArray = url?.split('/')
    const filename = urlArray?.length > 0 ? urlArray[urlArray.length - 1] : ''
    return filename
  }

  const throwError = (error: any) => {
    setError(error)
    onFeedback && onFeedback({status: !error, data: {}})
  }

  const loadPdf = async () => {
    setIsValidPdf(false)
    setLoading(true)
    setError(false)
    try {
      if (url) {
        let response
        let urlLocal
        if (url?.toLowerCase()?.indexOf('.pdf') > -1) {
          setIsValidPdf(true)
        }
        if (_options?.isLocalFile) {
          urlLocal = `${url}`
        } else {
          response = await getData('', url, {responseType: 'blob'})
          urlLocal = URL.createObjectURL(response)
        }
        setFileInfo(checkFileExtension(url))
        setFileUrl(urlLocal)
        setBlobData(response)
        onFeedback && onFeedback({status: true, data: response})
      }
    } catch (error) {
      throwError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loading) {
      try {
        if ((url?.length || 0) > 0) {
          loadPdf()
        }
      } catch (e) {
        setLoading(false)
        throwError(true)
      }
    }
  }, [url, loading])

  function onDocumentLoadSuccess({numPages}: {numPages: number}): void {
    setNumPages(numPages)
  }

  return (
    <div>
      {loading || error ? (
        <div
          className='d-flex gap-2 align-items-center justify-content-center bg-light my-2'
          style={{height: '500px'}}
        >
          {loading ? (
            <>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
              <div>Memuat data</div>
            </>
          ) : (
            <>
              {error && (
                <div className='d-flex flex-column align-items-center gap-2'>
                  Gagal Memuat Data
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      setLoading(true)
                      setError(false)
                    }}
                  >
                    Refresh Halaman
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <div className='d-flex gap-2 justify-content-between align-items-center mb-4'>
            {(title || options?.showTitleFilename) && (
              <div className='fw-bold fs-4'>
                {title || (options?.showTitleFilename && getFileNameUpload(url))}
              </div>
            )}
            {_options?.hasDownload && fileUrl && (
              <button
                className='btn btn-primary'
                onClick={async () => {
                  download(blobData || fileUrl, `${_options?.filename || 'download'}.pdf`)
                }}
              >
                Download
              </button>
            )}
          </div>
          {['jpg', 'jpeg', 'png'].includes(fileInfo?.ext) ? (
            <img className='ratio ratio-1x1' src={`${fileUrl}`} alt={fileInfo?.name} />
          ) : (
            <>
              {fileInfo?.ext === 'pdf' ? (
                <iframe
                  width='100%'
                  height='500px'
                  src={fileUrl + '#toolbar=0'}
                  onLoad={(e: any) => {
                    if (
                      e?.target?.contentDocument?.contentType !== 'application/pdf' &&
                      !isValidPdf
                    ) {
                      throwError(true)
                    }
                  }}
                ></iframe>
              ) : (
                <div className='d-flex flex-column gap-2 align-items-center justify-content-center'>
                  <div className='h-100px d-flex align-items-center justify-content-center'>
                    File yang ditampilkan belum mendukung.
                  </div>
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      fileUrl &&
                        download(blobData || fileUrl, `${fileInfo?.name || title}.${fileInfo?.ext}`)
                    }}
                  >
                    Download Dokumen
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}

      {/* <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document> */}
      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  )
}

export {
  PdfStylePallete,
  PdfStyle,
  getFonts,
  PdfViewer,
  DownloadPdf,
  PdfHeaderApp,
  PdfCurrencyFormat,
  PdfTableHeader,
  PdfTableColumn,
  ReactPdfViewer,
}
