import {eachDayOfInterval, format} from 'date-fns'
import {AlphabetIndex, NullProof} from '../../../../helper/AppFunction'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../helper/ReactPdf'
import {getSisaCuti} from './MainFunction'

const WorksheetRinci = (wb: Workbook, data: PdfLayoutDataProps) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const year = NullProof({input: data, params: 'year', isLabel: false})
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Judulnya'})

  // Init Template Theme
  // Format Color: HEX without #
  const themeColor = {
    merah: 'f4cccc',
    hijau: 'c6e0b4',
    orange: 'f8cbad',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const borderStyle: themeStyleProps = {
    style: {},
    border: {
      borderBottom: true,
      borderLeft: true,
      borderRight: true,
      borderTop: true,
      color: '000000',
      style: 'thin',
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 20,
    C: 26,
    D: 5,
    E: 5,
    F: 5,
    G: 6,
    H: 6,
    I: 6,
    J: 6,
    K: 6,
    L: 6,
    M: 6,
    N: 6,
    O: 6,
    P: 6,
    Q: 6,
    R: 6,
    S: 6,
    T: 6,
    U: 6,
    V: 6,
    W: 6,
    X: 6,
    Y: 6,
    Z: 6,
    AA: 6,
    AB: 6,
    AC: 6,
    AD: 6,
    AE: 14,
    AF: 9,
    AG: 5,
    AH: 5,
    AI: 5,
  }
  setupWidth(columnWidths)
  worksheet.getRow(4).height = 25
  worksheet.getRow(5).height = 25

  // Start Making ExcelJS
  cell({
    id: 'A2:AF2',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
  }).value = 'Inspektorat Jenderal Kementrian Agama'.toUpperCase()
  cell({
    id: 'A3:AF3',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
  }).value = `Tahun ${year}`.toUpperCase()

  // Header
  cell({
    id: 'A4:A5',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = 'No'.toUpperCase()
  cell({
    id: 'B4:B5',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = 'NIP'.toUpperCase()
  cell({
    id: 'C4:C5',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = 'Nama'.toUpperCase()
  cell({
    id: 'D4:D5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.orange,
    },
    border: borderStyle.border,
  }).value = 'N-2'.toUpperCase()
  cell({
    id: 'E4:E5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.orange,
    },
    border: borderStyle.border,
  }).value = 'N-1'.toUpperCase()
  cell({
    id: 'F4:F5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.hijau,
    },
    border: borderStyle.border,
  }).value = 'N'.toUpperCase()
  cell({
    id: 'G4:AD4',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = 'Pengajuan Cuti'.toUpperCase()
  for (let index = 1; index <= 24; index++) {
    cell({
      id: `${AlphabetIndex(index + 5).toUpperCase()}5`,
      style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
      border: borderStyle.border,
    }).value = `${index}`.toUpperCase()
  }
  cell({
    id: 'AE4:AE5',
    options: {isMerged: true},
    style: {
      fontConfig: {wrap: true, style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.merah,
    },
    border: borderStyle.border,
  }).value = 'Total Penggunaan Hak Cuti'.toUpperCase()
  cell({
    id: 'AF4:AF5',
    options: {isMerged: true},
    style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
    border: borderStyle.border,
  }).value = 'Sisa'.toUpperCase()
  cell({
    id: 'AG4:AG5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.orange,
    },
    border: borderStyle.border,
  }).value = 'N-2'.toUpperCase()
  cell({
    id: 'AH4:AH5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.orange,
    },
    border: borderStyle.border,
  }).value = 'N-1'.toUpperCase()
  cell({
    id: 'AI4:AI5',
    options: {isMerged: true},
    style: {
      fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}},
      backgroundColor: themeColor.hijau,
    },
    border: borderStyle.border,
  }).value = 'N'.toUpperCase()

  // Data
  NullProof({input: data, params: 'data'}).map((l: any, i: number) => {
    const sisa = getSisaCuti(l)
    const startCell = i + 6
    cell({
      id: `A${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
      },
      border: borderStyle.border,
    }).value = `${i + 1}`.toUpperCase()
    cell({
      id: `B${startCell}`,
      style: {
        fontConfig: {size: 11},
      },
      border: borderStyle.border,
    }).value = `${NullProof({input: l, params: 'nip'})}`.toUpperCase()
    cell({
      id: `C${startCell}`,
      style: {
        fontConfig: {size: 11},
      },
      border: borderStyle.border,
    }).value = `${NullProof({input: l, params: 'nama'})}`.toUpperCase()
    cell({
      id: `D${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.orange,
      },
      border: borderStyle.border,
    }).value = `${sisa.sebelum.n2}`.toUpperCase()
    cell({
      id: `E${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.orange,
      },
      border: borderStyle.border,
    }).value = `${sisa.sebelum.n1}`.toUpperCase()
    cell({
      id: `F${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.hijau,
      },
      border: borderStyle.border,
    }).value = `${sisa.sebelum.n}`.toUpperCase()
    for (let index = 1; index <= 24; index++) {
      cell({
        id: `${AlphabetIndex(index + 5).toUpperCase()}${startCell}`,
        style: {fontConfig: {style: 'bold', size: 11, alignment: {h: 'center'}}},
        border: borderStyle.border,
      }).value = `${sisa.list[index - 1] || ''}`.toUpperCase()
    }
    cell({
      id: `AE${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.merah,
      },
      border: borderStyle.border,
    }).value = `${sisa.total}`.toUpperCase()
    cell({
      id: `AF${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
      },
      border: borderStyle.border,
    }).value = `${sisa.sesudah.total}`.toUpperCase()
    cell({
      id: `AG${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.orange,
      },
      border: borderStyle.border,
    }).value = `${sisa.sesudah.n2}`.toUpperCase()
    cell({
      id: `AH${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.orange,
      },
      border: borderStyle.border,
    }).value = `${sisa.sesudah.n1}`.toUpperCase()
    cell({
      id: `AI${startCell}`,
      style: {
        fontConfig: {size: 11, alignment: {h: 'center'}},
        backgroundColor: themeColor.hijau,
      },
      border: borderStyle.border,
    }).value = `${sisa.sesudah.n}`.toUpperCase()
  })

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.font = {...cell.font, name: 'Calibri'}
    })
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download()
  }
  return downloadExcel
}

export default ExcelLayout
