/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {CardUi, DropdownUiV2 as DropdownUi, FormPagination} from '../../../../helper/AppUi'
import {
  TableActionProps,
  TableColumn,
  TableInput,
  TableInputCheckboxDataProps,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../../helper/TableInputV2'
import {
  BreadcrumbQueryParams,
  formInputListSelectorV2 as formInputListSelector,
  NullProof,
  RouterQueryParams,
} from '../../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  FormControllerProps,
  FormInputProps,
  onLoadProps as onLoadPropsForm,
  useFormController,
} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {useIntl} from 'react-intl'
import {intlMessage} from '../../../../helper/ReactIntl'
import ReactIcon from '../../../../helper/ReactIcon'
// import {FormPagination} from '../../../../helper/KemenagUi'
import * as Yup from 'yup'
import {debounce, set} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {getData, postData} from '../../../../helper/FormAxios'
import Swal from 'sweetalert2'

type TypePage = 'lampiran' | 'survey'

const MainWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [modal, setModal] = useState<any>([])
  const [modalPopup, setModalPopup] = useState<boolean>(false)

  const [action, setAction] = useState<TableActionProps[]>(TableInputData.action || [])

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {`${intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.breadcrumb.title, intl)}`}
      </PageTitle>
      <CardUi title={intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.title, intl)}>
        <TableInput
          {...TableInputData}
          action={action}
          modal={setModal}
          checkbox={setCheckbox}
          onFeedback={(e) => {
            if (e?.idMenu === 'modal') {
              setModalPopup(true)
            }
          }}
          onSuccess={setData}
          sidebar={
            <>
              {/* <button
                type='button'
                onClick={() => navigate('./tambah')}
                className='btn btn-primary d-flex align-items-center gap-1'
              >
                <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                {intlMessage(`component.tableinput.button.add`, 'Add', intl)}
              </button> */}
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'nama'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'bulan_layanan'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'email'})}
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC<{type?: TypePage}> = ({type = 'survey'}) => {
  const query = useParams()
  const defaultApi =
    type === 'survey'
      ? RouterQueryParams(`${InfoData.api}/:id`, query)
      : RouterQueryParams(InfoData.api_lampiran, query)
  const intl = useIntl()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Edit {page}`, id: `component.infopage.edit_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Survey Layanan', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: defaultApi,
            type: 'put',
            redirect: true,
          }}
          onChange={(e) => onChangeForm(e, query)}
        />
      </CardUi>
    </>
  )
}

const onChangeForm = (e: BaseInputOnChangeProps, query?: any) => {
  const {resetForm, clickId, type} = e
  const _data = e?._data.get
  let pekerjaan = _data?.pekerjaan_list || _data?.pekerjaan
  let tipe_asn = _data?.tipe_asn
  const inputUnitKerja = formInputListSelector({
    formInputList,
    name: 'unit_kerja',
  })
  const inputPekerjaanList = formInputListSelector({
    formInputList,
    name: 'pekerjaan_list',
  })
  if (tipe_asn === 'ASN Kemenag') {
    inputUnitKerja.hide = false
    inputUnitKerja.validator = Yup.string().required()
  } else {
    inputUnitKerja.hide = true
    inputUnitKerja.validator = Yup.string()
  }
  const inputPekerjaan = formInputListSelector({
    formInputList,
    name: 'pekerjaan',
  })
  if (pekerjaan === 'Yang Lain') {
    inputPekerjaan.hide = false
    if (clickId === 'pekerjaan_list') {
      pekerjaan = ''
    }
  } else {
    inputPekerjaan.hide = true
  }
  if (type === 'onInit') {
    let result: any = {..._data}
    const hasPekerjaanList =
      (inputPekerjaanList?.listData?.filter((f) => f.value === _data.pekerjaan).length || 0) > 0
    if (hasPekerjaanList) {
      result = {
        ...result,
        pekerjaan_list: _data.pekerjaan,
      }
    } else {
      result = {
        ...result,
        pekerjaan_list: 'Yang Lain',
      }
    }
    resetForm({values: result})
  }
  if (clickId === 'pekerjaan_list') {
    const result: any = {
      ..._data,
      pekerjaan: pekerjaan,
    }
    resetForm({values: result})
  }
  if (type === 'onInit') {
    if (!_data?.skbt_id) {
      const result: any = {
        ..._data,
        skbt_id: query?.lampiran,
      }
      resetForm({values: result})
    }
  }
}

const PartialForm: FC<{
  loading?: boolean
  controller: FormControllerProps
  range: {start: number; end: number}
  data?: any
  onFeedback?: (e: any) => void
  onChange?: (e: BaseInputOnChangeProps) => void
}> = ({controller, loading, range, data, onFeedback, onChange}) => {
  const formInput = [...formInputList.slice(range.start, range.end)]
  return (
    <FormInput
      isLocked={loading}
      useToast={false}
      controller={controller}
      input={formInput}
      isSubmitByBlur
      route={{
        type: 'put',
        url: '#',
        data: data,
        resetOnFeedback: false,
        redirect: false,
        isFeedback: true,
        onFeedback: onFeedback,
      }}
      onChange={onChange}
      options={{
        actionButton: {
          cancel: {
            hide: true,
          },
          submit: {
            hide: true,
          },
        },
      }}
    />
  )
}

const TambahWrapper: FC<{type?: TypePage}> = ({type = 'survey'}) => {
  const query = useParams()
  const defaultApi =
    type === 'survey' ? InfoData.api : RouterQueryParams(InfoData.api_lampiran, query)
  const intl = useIntl()
  const navigate = useNavigate()
  const valueDebouce = useRef(debounce((e) => e(), 300)).current
  useEffect(() => {
    return () => {
      valueDebouce.cancel()
    }
  }, [valueDebouce])
  const infoPage = intl.formatMessage(
    {defaultMessage: `Form {page}`, id: `component.infopage.form_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Survey Layanan', intl),
    }
  )

  useEffect(() => {
    const loadData = async () => {
      const result = await getData('', defaultApi)
      if (result?.status) {
        const data = result?.data
        if (data) {
          navigate(-1)
        }
      }
    }
    loadData()
  }, [])

  const [formController, setFormController] = useState<string>('')

  const formCtrl = useFormController()
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [pageInfo, setPageInfo] = useState<any>({
    type: 'page',
    formValid: false,
  })
  const onFormFeedback = (e: any) => {
    if (e?.status) {
      setPageInfo((p: any) => ({...p, formValid: true}))
      setData((p: any) => ({...p, ...e?.data}))
    }
  }
  const onFormChange = (e: BaseInputOnChangeProps) => {
    const error = e?.error
    setPageInfo((p: any) => ({...p, formValid: !error}))
    onChangeForm(e, query)
  }

  useEffect(() => {
    if (formController) {
      valueDebouce(() => {
        setFormController('')
      })
    }
  }, [formController])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const submitForm = async () => {
    setLoading(true)
    const result = await postData(data, defaultApi)
    if (result?.status) {
      navigate(-1)
      Swal.fire({
        icon: 'success',
        title: 'Berhasil menambah data',
      })
    }
  }

  const saveForm = async () => {
    setLoading(true)
    formCtrl.post('submit')
    function delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }
    await delay(1000)
  }

  const formConfig = {
    data,
    loading,
    controller: formCtrl,
    onFeedback: onFormFeedback,
    onChange: onFormChange,
  }

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi propsBody={{className: 'd-flex flex-column gap-4'}} title={infoPage}>
        <FormPagination
          lock={{
            next: pageInfo?.type === 'form' && !pageInfo?.formValid,
          }}
          onPageChange={async (e) => {
            const {commit, page, totalPage, type, loading} = e
            if (page <= 2 && type === 'prev') {
              setPageInfo((p: any) => ({...p, type: 'page'}))
            } else {
              setPageInfo((p: any) => ({...p, type: 'form'}))
            }
            if (page >= 1 && page < totalPage) {
              if (page > 1 && page < 17 && type === 'next') {
                loading()
                await saveForm()
                pageInfo?.formValid && commit()
                setLoading(false)
              } else {
                commit()
              }
            } else if (page === totalPage) {
              if (type === 'prev') {
                commit()
              } else if (type === 'finish') {
                loading()
                await submitForm()
                pageInfo?.formValid && commit(page)
                setLoading(false)
              } else {
                console.log(e)
              }
            }
          }}
          layout={[
            {
              children: (
                <div className='fs-5 d-flex flex-column gap-2'>
                  <p>
                    Dalam rangka mengukur Indeks Persepsi Kualitas Pelayanan (IPKP) dan Indeks
                    Persepsi Anti Korupsi (IPAK) pada Inspektorat Jenderal Kementerian Agama, kami
                    akan meminta pendapat Bapak/Ibu mengenai beberapa hal terkait kualitas dan
                    integritas pemberian pelayanan pada Itjen Kemenag.
                  </p>
                  <p>
                    Berilah nilai bintang antara 1 – 6 pada setiap pernyataan, dimana semakin banyak
                    bintang menunjukkan bahwa Bapak/Ibu semakin setuju bahwa kualitas pelayanan
                    semakin baik, jika jawaban tidak bintang 6, mohon berikan alasan Bapak/Ibu tidak
                    memberi bintang 6.
                  </p>
                  <p>
                    Jawaban Bapak/Ibu secara jujur sangat berarti bagi kemajuan kita bersama. Atas
                    kesediaan Bapak/Ibu dalam survei ini kami sampaikan terima kasih.
                  </p>
                </div>
              ),
            },
            {
              children: <PartialForm {...formConfig} range={{start: 0, end: 11}} />,
            },
            {
              children: <PartialForm {...formConfig} range={{start: 11, end: 15}} />,
            },
            ...Array.from({length: 14}, (_, i) => ({
              children: <PartialForm {...formConfig} range={{start: 15 + i, end: 16 + i}} />,
            })),
          ]}
        />
        {/* <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
          <code>Data: {JSON.stringify(data)}</code>
        </pre> */}
      </CardUi>
    </>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Import Data {page}`, id: `component.infopage.import_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Survey Layanan', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: false,
            isFeedback: true,
            onFeedback({status, message, data}) {
              console.log(status, message, data)
            },
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
        />
      </CardUi>
    </>
  )
}
const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC<{type?: TypePage}> = ({type = 'survey'}) => {
  const query = useParams()
  console.log(query)
  const defaultApi =
    type === 'survey' ? InfoData.api : RouterQueryParams(InfoData.api_lampiran, query)
  const intl = useIntl()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  const filterInput = () => {
    const removeList = ['pekerjaan_list']
    const input = [...formInputList]
    const filter = input.filter((l) => !removeList.includes(l.name))
    return filter
  }
  const ratingInput = (props: FormInputProps) => {
    const name = props.name
    const ratingList = [
      'spkp_1',
      'spkp_2',
      'spkp_3',
      'spkp_4',
      'spkp_5',
      'spkp_6',
      'spkp_7',
      'spkp_8',
      'pak_1',
      'pak_2',
      'pak_3',
      'pak_4',
      'pak_5',
    ]
    if (ratingList.includes(name)) {
      const description: any = props.options?.label?.description
      return (
        <div className='d-flex flex-column gap-2'>
          <div>{'⭐'.repeat(Number(NullProof({input: data, params: name, isLabel: false})))}</div>
          <div>{description}</div>
        </div>
      )
    }
    return NullProof({input: data, params: name})
  }
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail
            onSuccess={(val: any) => setData(val)}
            {...TableInputDetailData}
            route={{url: defaultApi}}
          >
            {filterInput().map((l, i) => (
              <Fragment key={i}>
                <TableInputDetailColumn title={{value: l.options?.input?.props?.title}}>
                  {ratingInput(l)}
                </TableInputDetailColumn>
              </Fragment>
            ))}
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper, ImportWrapper}
