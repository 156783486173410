import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'

const formInputBiayaLainLain: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'deskripsi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Deskripsi wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Deskripsi',
          placeholder: 'Deskripsi',
        },
      },
    },
  },
  {
    className: 'col-lg-8',
    name: 'harga',
    type: 'input',
    value: '',
    validator: Yup.number().required('Harga wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga',
          placeholder: 'Harga',
        },
      },
    },
  },
  {
    className: 'col-lg-4',
    name: 'total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Harga',
          placeholder: 'Total Harga',
        },
      },
      currency: {
        props: {
          readOnly: true,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kwitansi',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kwitansi wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Kwitansi',
          placeholder: 'Kwitansi',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
]

const formInputTransportasi: FormInputProps[] = [
  {
    className: 'col-lg-12',
    name: 'dibayar_oleh',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Kantor',
        value: 'Kantor',
      },
      {
        title: 'Pribadi',
        value: 'Pribadi',
      },
    ],
    validator: Yup.string().required('Pembayar transportasi wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'Dibayar oleh',
          placeholder: 'Dibayar oleh',
        },
      },
    },
  },
  {
    className: 'col-lg-6',
    name: 'asal',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Asal Kota is required'),
    options: {
      input: {
        props: {
          title: 'Asal Kota',
          placeholder: 'Asal Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-lg-6',
    name: 'tujuan',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Tujuan Kota is required'),
    options: {
      input: {
        props: {
          title: 'Tujuan Kota',
          placeholder: 'Tujuan Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-lg-3',
    name: 'jenis',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'PP',
        value: 'PP',
      },
      {
        title: 'Pergi',
        value: 'Pergi',
      },
      {
        title: 'Pulang',
        value: 'Pulang',
      },
      {
        title: 'DKI',
        value: 'DKI',
      },
    ],
    validator: Yup.string().required('Jenis wajib diisi.'),
    options: {
      select: {
        props: {
          title: 'Jenis',
          placeholder: 'Jenis',
        },
      },
    },
  },
  {
    className: 'col-lg-5',
    name: 'harga',
    type: 'input',
    value: '',
    validator: Yup.number().required('Harga wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga',
          placeholder: 'Harga',
        },
      },
    },
  },
  {
    className: 'col-lg-4',
    name: 'total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Harga',
          placeholder: 'Total Harga',
        },
      },
      currency: {
        props: {
          readOnly: true,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kwitansi',
    type: 'input',
    value: '',
    validator: Yup.string().nullable(),
    options: {
      input: {
        props: {
          title: 'Kwitansi Transport',
          placeholder: 'Kwitansi Transport',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
]

const formInputUangPenginapan: FormInputProps[] = [
  {
    className: 'col-md-3',
    name: 'hari',
    type: 'input',
    value: '',
    validator: Yup.number().required('Jumlah Malam wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Jumlah Malam',
          placeholder: 'Hari',
          type: 'number',
          min: 1,
          max: 31,
        },
      },
    },
  },
  {
    className: 'col-md-4',
    name: 'harga',
    type: 'input',
    value: '',
    validator: Yup.number().required('Harga wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga Biaya Hotel',
          placeholder: 'Harga',
        },
      },
      component: {
        cloneInitData: true,
      },
    },
  },
  {
    className: 'col-md-5',
    name: 'total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Biaya Hotel',
          placeholder: 'Harga',
        },
      },
      currency: {
        props: {
          readOnly: true,
        },
      },
    },
  },
  {
    className: 'col-12 mt-4',
    name: 'tanpa_hotel',
    type: 'input',
    value: '',
    validator: Yup.boolean(),
    options: {
      input: {
        props: {
          placeholder: 'Tanpa Hotel (Total x 30%)',
          type: 'checkbox',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kwitansi',
    type: 'input',
    value: '',
    validator: Yup.string().nullable(),
    options: {
      input: {
        props: {
          title: 'Kwitansi Hotel',
          placeholder: 'Kwitansi Hotel',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
]

const formInputTanggalRealisasi: FormInputProps[] = [
  {
    className: 'col-5',
    name: 'tanggal_mulai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-5',
    name: 'tanggal_selesai',
    type: 'input',
    value: '',
    validator: Yup.string().required('Tanggal wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    className: 'col-2',
    name: 'tanggal_total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Total Hari',
          placeholder: 'Total Hari',
          type: 'number',
        },
      },
      currency: {
        props: {
          readOnly: true,
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'surat_tugas_id',
    type: 'datalist',
    value: '',
    // disabled: true,
    validator: Yup.string().required('Surat Tugas is required'),
    options: {
      input: {
        props: {
          title: 'Surat Tugas',
          placeholder: 'Surat Tugas',
        },
      },
      datalist: {
        api: '/pengawasan_internal/surat-tugas',
        query: 'byuser=true',
        params: 'nomor_surat,nama_kegiatan,tanggal_mulai,tanggal_selesai',
        result: `$nomor_surat | $nama_kegiatan | $tanggal_mulai - $tanggal_selesai`,
        id: 'id',
      },
    },
  },
  {
    className: 'col-md-12',
    name: 'lampiran_penugasan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Lampiran Penugasan wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Lampiran Penugasan',
          placeholder: 'Lampiran Surat Tugas',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'normatif_perjadin_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Normatif Perjalanan Dinas is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Normatif Perjalanan Dinas',
  //         placeholder: 'Normatif Perjalanan Dinas',
  //       },
  //     },
  //     datalist: {
  //       api: '/eval_pelaporan/data-normatif-perjadin',
  //       params: 'provinsi,kota_bandara',
  //       result: '$provinsi - $kota_bandara',
  //       id: 'id',
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'tanggal_realisasi_data',
    type: 'component',
    value: '',
    validator: Yup.number().required('Tanggal Realisasi wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Realisasi',
          placeholder: 'Tanggal Realisasi',
        },
      },
      component: {
        resultType: 'date',
      },
    },
    component: formInputTanggalRealisasi,
  },
  {
    className: 'col-md-12',
    name: 'uang_muka',
    type: 'input',
    value: '',
    validator: Yup.number().required('Pembayaran Awal / Uang Muka wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Pembayaran Awal / Uang Muka',
          placeholder: 'Total',
        },
      },
    },
  },
  {
    className: 'col-md-12',
    name: 'uang_muka_transport',
    type: 'input',
    value: '',
    removeOnSubmit: true,
    disabled: true,
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Uang Muka Transportasi',
          placeholder: 'Uang Muka Transportasi',
        },
      },
    },
  },
  {
    className: 'col-md-12',
    name: 'uang_muka_total',
    type: 'input',
    value: '',
    disabled: true,
    hide: true,
    removeOnSubmit: true,
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Pembayaran Awal / Uang Muka',
          placeholder: 'Total',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Uang Harian',
          placeholder: 'Harga',
        },
      },
      label: {
        name: 'Uang Harian',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-md-2',
    name: 'uang_harian_hari',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Jumlah Hari',
          placeholder: 'Hari',
          type: 'number',
          min: 0,
        },
      },
    },
  },
  {
    className: 'col-md-4',
    name: 'uang_harian_harga',
    type: 'input',
    value: '',
    validator: Yup.number().required('Harga wajib diisi.'),
    options: {
      input: {
        isCurrency: true,
        // bindInput: {
        //   input: 'normatif_perjadin_id',
        //   params: 'uang_harian',
        //   id: 'id',
        // },
        props: {
          title: 'Jumlah Uang Harian',
          placeholder: 'Harga',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-md-6',
    name: 'uang_harian_total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total',
          placeholder: 'Harga',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Uang Representatif',
          placeholder: 'Harga',
        },
      },
      label: {
        name: 'Uang Representatif',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-md-2',
    name: 'uang_representatif_hari',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Jumlah Hari',
          placeholder: 'Hari',
          type: 'number',
          min: 0,
        },
      },
    },
  },
  {
    className: 'col-md-4',
    name: 'uang_representatif_harga',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        // bindInput: {
        //   input: 'normatif_perjadin_id',
        //   params: 'uang_representatif',
        //   id: 'id',
        // },
        props: {
          title: 'Jumlah Uang Representatif',
          placeholder: 'Harga',
          type: 'number',
        },
      },
    },
  },
  {
    className: 'col-md-6',
    name: 'uang_representatif_total',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total',
          placeholder: 'Harga',
        },
      },
    },
  },

  {
    className: 'col-12',
    name: 'uang_penginapan_data',
    type: 'component',
    value: '',
    validator: Yup.number().required('Uang Penginapan wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Uang Penginapan',
          placeholder: 'Uang Penginapan',
        },
      },
      component: {
        resultType: 'currency',
      },
    },
    component: formInputUangPenginapan,
  },
  {
    className: 'col-12',
    name: 'transportasi_data',
    type: 'component',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Transportasi',
          placeholder: 'Transportasi',
        },
      },
      component: {
        resultType: 'currency',
      },
    },
    component: formInputTransportasi,
  },
  {
    className: 'col-md-6',
    name: 'bst_kantor',
    type: 'input',
    value: '',
    validator: Yup.number().max(512000, 'Maksimal BST Kantor 512.000'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'BST Kantor',
          placeholder: 'BST Kantor',
        },
      },
    },
  },
  {
    className: 'col-md-6',
    name: 'bst_lokasi',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'BST Lokasi',
          placeholder: 'BST Lokasi',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'bst_kantor_lampiran',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Lampiran BST Kantor',
          placeholder: 'Kwitansi',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'bst_lokasi_lampiran',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Lampiran BST Lokasi',
          placeholder: 'Lampiran BST Lokasi',
          accept: '.pdf, .jpg, .jpeg, .png',
          type: 'file',
        },
      },
      upload: {
        url: {
          folder: 'dokumen',
        },
      },
    },
  },
  {
    className: 'col-md-6',
    name: 'bst_total',
    type: 'input',
    value: '',
    disabled: true,
    hide: true,
    removeOnSubmit: true,
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Semua',
          placeholder: 'Total Semua',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kwitansi_lain_lain_list',
    type: 'component',
    value: '',
    validator: Yup.number().required('Biaya Lainnya wajib diisi.'),
    options: {
      input: {
        props: {
          title: 'Biaya Lainnya',
          placeholder: 'Biaya Lainnya',
        },
      },
      component: {
        resultType: 'currency',
      },
    },
    component: formInputBiayaLainLain,
  },
  {
    className: 'col-9 d-flex flex-column justify-content-center text-end',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Jumlah Dibayarkan',
          placeholder: 'Harga',
        },
      },
      label: {
        name: 'Jumlah Dibayarkan',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-md-3',
    name: 'jumlah_dibayarkan',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: '',
          placeholder: 'Harga',
        },
      },
    },
  },
  {
    className: 'col-9 d-flex flex-column justify-content-center text-end',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Selisih',
          placeholder: 'Harga',
        },
      },
      label: {
        name: 'Selisih',
        className: 'fs-6',
      },
    },
  },
  {
    className: 'col-md-3',
    name: 'sisa_pengembalian',
    type: 'input',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: '',
          placeholder: 'Harga',
        },
      },
    },
  },
  {
    className: 'col-12 mb-4 fs-6',
    name: 'agreement',
    type: 'input',
    validator: Yup.boolean().oneOf([false], 'Agreement is required'),
    value: '',
    options: {
      input: {
        props: {
          title: 'Agreement',
          placeholder: 'Saya Menyatakan Bahwa Data Yang Saya Isi Benar dan Sesuai.',
          type: 'checkbox',
        },
      },
    },
  },
]

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_ppk',
    type: 'select',
    value: '',
    hide: true,
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'status_bendahara',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_bendahara',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

const formInputStatusEvalap: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_evalap',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_evalap',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusketua: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_ketua',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_ketua',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusppk: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_ppk',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_ppk',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

export {
  formInputList,
  formInputStatus,
  formInputStatusEvalap,
  formInputStatusketua,
  formInputStatusppk,
}
