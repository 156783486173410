import {FC, Fragment} from 'react'
import {Document, Page, View, Text} from '@react-pdf/renderer'
import {PdfLayoutDataProps, PdfStyle, PdfTableColumnBuilder} from '../../../../../helper/ReactPdf'
import {formatDate} from '../../../../../helper/DateFns'
import {ConvertCurrency, NullProof} from '../../../../../helper/AppFunction'

const HeaderContent: FC = () => {
  return (
    <>
      <PdfTableColumnBuilder
        borderBottom
        style={{textTransform: 'uppercase', fontWeight: 'semibold'}}
      >
        <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            No.
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jenis Perjalanan Dinas
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NO ST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl ST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uraian Kegiatan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Provinsi
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jenis Satuan Kerja
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama Satuan Kerja
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            NIP
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Nama
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jabatan
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Wilayah/Sekretariat
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Mulai ST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Akhir ST
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 1
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 2
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 3
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 4
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 5
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 6
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 7
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 8
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 9
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tgl Realisasi 10
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            HP (Total HP)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            MAK
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uang Harian Dibayar Semula
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Hotel Dibayar Semula
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tiket Dibayar Semula
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Dibayar Semula (Total UM)
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uang Harian Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Hari Hotel Rill 1
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Hotel Rill 1
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Hari Hotel Rill 2
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Hotel Rill 2
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Hari Hotel Rill 3
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Hotel Rill 3
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Hari Hotel Rill 4
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Hotel Rill 4
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Hari Hotel Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Total Biaya Hotel Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Uang Representatif Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tiket PP Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tiket Berangkat Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tiket Pulang Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Tiket Kota 2 Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            BTS Kantor Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            BTS Lokasi Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Biaya Lainnya Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Jumlah Rill
          </Text>
        </PdfTableColumnBuilder>
        <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
          <Text
            style={{
              padding: '10px 20px',
              textAlign: 'center',
            }}
          >
            Pembayaran Kekuarangan/Pengembalian
          </Text>
        </PdfTableColumnBuilder>
      </PdfTableColumnBuilder>
    </>
  )
}

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const total = (data: any, param: string) => {
    const uang_muka = data ? data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0 : 0
    return Number(uang_muka)
  }
  const cekIsUangNull = (uang: any) => {
    return uang ? Number(uang) : 0
  }
  return (
    <Document title='Dokumen'>
      <Page
        size={[595.28]}
        orientation='landscape'
        wrap={false}
        style={{...PdfStyle.page, fontFamily: 'Arial', position: 'relative', gap: '5px'}}
      >
        <View style={{width: '100%'}}>
          <PdfTableColumnBuilder direction='column' border>
            {/* Header */}
            <HeaderContent />
            {/* Looping Here */}
            {NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
              let jumlahTotalDibayar = 0
              let jumlahTotalPengeluaran = 0
              let JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
              let uang_harian_dibayar_semula = 0
              let hotel_dibayar_semula = 0
              let transport_dibayar_semula = 0
              let uang_harian_rill = 0
              let uang_representatif_rill = 0
              let tiket_pp_rill = 0
              let bts_kantor_rill = 0
              let bts_lokasi_rill = 0
              let biayar_lainnya_rill = 0
              NullProof({input: l, params: 'bukti_perjalanan', isMap: true}).map(
                (item: any, i: number) => {
                  const uangTransportKantor = item.transportasi_data
                    ? item.transportasi_data.filter((l: any) => l.dibayar_oleh === 'Kantor')
                    : []
                  const totalDibayar =
                    total(item.transfer, 'uang_harian') +
                    total(item.transfer, 'hotel') +
                    total(item.transfer, 'uang_representatif') +
                    total(item.transfer, 'transport') +
                    total(uangTransportKantor, 'total') +
                    total(item.transfer, 'bst_kantor') +
                    total(item.transfer, 'bst_lokasi')
                  const totalPengeluraran =
                    cekIsUangNull(item.uang_harian_total) +
                    total(item.uang_penginapan_data, 'total') +
                    cekIsUangNull(item.uang_representatif_total) +
                    total(item.transportasi_data, 'total') +
                    cekIsUangNull(item.bst_kantor) +
                    cekIsUangNull(item.bst_lokasi) +
                    total(item.kwitansi_lain_lain_list, 'total')
                  jumlahTotalDibayar += totalDibayar
                  jumlahTotalPengeluaran += totalPengeluraran
                  JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
                  uang_harian_dibayar_semula = total(item.transfer, 'uang_harian')
                  hotel_dibayar_semula = total(item.transfer, 'hotel')
                  transport_dibayar_semula =
                    total(item.transfer, 'transport') + total(uangTransportKantor, 'total')
                  uang_harian_rill = cekIsUangNull(item.uang_harian_total)
                  uang_representatif_rill = cekIsUangNull(item.uang_representatif_total)
                  tiket_pp_rill = total(item.transportasi_data, 'total')
                  bts_kantor_rill = cekIsUangNull(item.bst_kantor)
                  bts_lokasi_rill = cekIsUangNull(item.bst_lokasi)
                  biayar_lainnya_rill = total(item.kwitansi_lain_lain_list, 'total')
                }
              )
              let tanggalRealisasiList: any = {}
              NullProof({
                input: l,
                params: 'bukti_perjalanan.tanggal_realisasi_data',
                isMap: true,
              })?.map((f: any, i: number) => {
                const tanggalResult = `${NullProof({
                  input: f,
                  params: 'tanggal_mulai',
                  type: 'date',
                })} s/d ${NullProof({input: f, params: 'tanggal_selesai', type: 'date'})}`
                tanggalRealisasiList[`tanggal_${i + 1}`] = tanggalResult
              })
              const total_HP = NullProof({
                input: l,
                params: 'bukti_perjalanan.tanggal_realisasi_data',
                isMap: true,
              })
                ?.map((item: any) => item.tanggal_total)
                .reduce((a: any, b: any) => a + b, 0)
              let dataHotelList: any = {}
              NullProof({
                input: l,
                params: 'bukti_perjalanan.uang_penginapan_data',
                isMap: true,
              })?.map((f: any, i: number) => {
                const dataHotelHari = NullProof({input: f, params: 'hari', isLabel: false}) || 0
                const dataHotelJumlah = NullProof({input: f, params: 'total', isLabel: false}) || 0
                dataHotelList[`hari_${i + 1}`] = dataHotelHari
                dataHotelList[`jumlah_${i + 1}`] = dataHotelJumlah
                dataHotelList[`hari_total`] = dataHotelList[`hari_total`] + dataHotelHari
                dataHotelList[`jumlah_total`] = dataHotelList[`jumlah_total`] + dataHotelJumlah
              })
              return (
                <Fragment key={i}>
                  <PdfTableColumnBuilder style={{display: 'flex', alignItems: 'stretch'}}>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '100px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {i + 1}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'jenis'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'nomor_surat'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'createdAt', type: 'date'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '300px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'deskripsi_kegiatan'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                          (item: any, index: number) => (
                            <Fragment key={index}>
                              {NullProof({input: item, params: 'provinsi'})}
                            </Fragment>
                          )
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                          (item: any, index: number) => (
                            <Fragment key={index}>
                              {NullProof({input: item, params: 'jenis_tujuan'})}
                            </Fragment>
                          )
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '300px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                          (item: any, index: number) => (
                            <Fragment key={index}>
                              {NullProof({input: item, params: 'satker.nama_satker'})}
                              {item.satker_id_2 &&
                                `, ${NullProof({input: item, params: 'satker_2.nama_satker'})}`}
                              {item.satker_id_3 &&
                                `, ${NullProof({input: item, params: 'satker_2.nama_satker'})}`}
                            </Fragment>
                          )
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'pengguna_anggaran.nip'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '250px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'pengguna_anggaran.nama'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '250px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'pengguna_anggaran.jabatan'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'pengguna_anggaran.satker_3'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'tanggal_mulai', type: 'date'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'tanggal_selesai', type: 'date'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_1'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_2'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_3'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_4'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_5'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_6'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_7'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_8'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_9'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: tanggalRealisasiList, params: 'tanggal_10'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {total_HP}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '250px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: l, params: 'mak'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(uang_harian_dibayar_semula)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(hotel_dibayar_semula)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(transport_dibayar_semula)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(
                          uang_harian_dibayar_semula +
                            hotel_dibayar_semula +
                            transport_dibayar_semula
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(uang_harian_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '150px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'hari_1'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'total_1', type: 'currency'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '150px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'hari_2'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'total_2', type: 'currency'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '150px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'hari_3'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'total_3', type: 'currency'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '150px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'hari_4'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'total_4', type: 'currency'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '150px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({input: dataHotelList, params: 'hari_total'})}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {NullProof({
                          input: dataHotelList,
                          params: 'jumlah_total',
                          type: 'currency',
                        })}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(uang_representatif_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(tiket_pp_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(bts_kantor_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(0)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(0)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(0)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(bts_lokasi_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(biayar_lainnya_rill)}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '200px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(
                          uang_harian_rill +
                            NullProof({
                              input: dataHotelList,
                              params: 'jumlah_total',
                              isLabel: false,
                            }) +
                            uang_representatif_rill +
                            tiket_pp_rill +
                            bts_kantor_rill +
                            bts_lokasi_rill +
                            biayar_lainnya_rill
                        )}
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder center wrap borderRight style={{width: '300px'}}>
                      <Text
                        style={{
                          padding: '10px 20px',
                          textAlign: 'center',
                        }}
                      >
                        {ConvertCurrency(JumlahTotalSelisih)}
                      </Text>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                </Fragment>
              )
            })}
          </PdfTableColumnBuilder>
        </View>
      </Page>
    </Document>
  )
}

export default PdfLayout
