import {NullProof} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../../helper/ReactPdf'

const WorksheetRinci = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({title: 'Judulnya'})

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 5,
    B: 30,
    C: 30,
    D: 30,
    E: 30,
    F: 30,
    G: 30,
    H: 30,
    I: 30,
    J: 30,
    K: 30,
    L: 30,
    M: 30,
    N: 30,
    O: 30,
    P: 30,
    Q: 30,
    R: 30,
  }
  setupWidth(columnWidths)

  // Start Making ExcelJS
  cell({id: 'A1'}).value = 'No.'
  cell({id: 'B1'}).value = 'No. Rek. Debet'
  cell({id: 'C1'}).value = 'Nama Rek. Debet'
  cell({id: 'D1'}).value = 'No. Rek Tujuan'
  cell({id: 'E1'}).value = 'Nama Rek. Tujuan'
  cell({id: 'F1'}).value = 'Jumlah'
  cell({id: 'G1'}).value = 'Notifikasi'
  cell({id: 'H1'}).value = 'Bank'
  cell({id: 'I1'}).value = 'Pelaku Perjadin'
  cell({id: 'J1'}).value = 'Tanggal Transfer'
  cell({id: 'K1'}).value = 'SPD'
  cell({id: 'L1'}).value = 'SPM'
  cell({id: 'M1'}).value = 'SPMTGL'
  cell({id: 'N1'}).value = 'UM / VF'
  cell({id: 'O1'}).value = 'Nilai'
  cell({id: 'P1'}).value = 'Kontrol'
  cell({id: 'Q1'}).value = 'Kontrol MAK'
  cell({id: 'R1'}).value = 'SPDT'

  NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
    cell({id: `A${1 + (i + 1)}`}).value = i + 1
    cell({id: `B${1 + (i + 1)}`}).value = NullProof({input: l, params: 'no_rek_debet'})
    cell({id: `C${1 + (i + 1)}`}).value = NullProof({input: l, params: 'nama_rek_debet'})
    cell({id: `D${1 + (i + 1)}`}).value = NullProof({input: l, params: 'no_rek_tujuan'})
    cell({id: `E${1 + (i + 1)}`}).value = NullProof({input: l, params: 'nama_rek_tujuan'})
    cell({id: `F${1 + (i + 1)}`}).value = NullProof({input: l, params: 'jumlah', type: 'currency'})
    cell({id: `G${1 + (i + 1)}`}).value = `${NullProof({
      input: l,
      params: 'notifikasi',
    })}`
    cell({id: `H${1 + (i + 1)}`}).value = NullProof({input: l, params: 'bank'})
    cell({id: `I${1 + (i + 1)}`}).value = NullProof({input: l, params: 'pelaku_perjadin_data.nama'})
    cell({id: `J${1 + (i + 1)}`}).value = formatDate({
      date: NullProof({input: l, params: 'tanggal_transfer'}),
      dateFormat: 'yyyyMMdd',
    })
    cell({id: `K${1 + (i + 1)}`}).value = NullProof({input: l, params: 'spd'})
    cell({id: `L${1 + (i + 1)}`}).value = NullProof({input: l, params: 'spm'})
    cell({id: `M${1 + (i + 1)}`}).value = `${NullProof({input: l, params: 'spm'})}${formatDate({
      date: NullProof({input: l, params: 'tanggal_transfer'}),
      dateFormat: 'yyyyMMdd',
    })}`
    cell({id: `N${1 + (i + 1)}`}).value = NullProof({input: l, params: 'um_uf'})
    cell({id: `O${1 + (i + 1)}`}).value = NullProof({input: l, params: 'nilai', type: 'currency'})
    cell({id: `P${1 + (i + 1)}`}).value = `${NullProof({
      input: l,
      params: 'nama_rek_tujuan',
    })}${NullProof({
      input: l,
      params: 'spd',
    })}${NullProof({input: l, params: 'um_uf'})}`
    cell({id: `Q${1 + (i + 1)}`}).value = NullProof({input: l, params: 'kontrol_mak'})
    cell({id: `R${1 + (i + 1)}`}).value = `${NullProof({
      input: l,
      params: 'nama_rek_tujuan',
    })}${NullProof({
      input: l,
      params: 'spd',
    })?.substring(0, 4)}`
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download(data._filename)
  }
  return downloadExcel
}

export default ExcelLayout
