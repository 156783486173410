import {
  MainWrapper,
  DetailWrapper,
  TambahWrapper,
  UbahWrapper,
} from '../../../../pages/keuangan/petunjuk-operasional-kegiatan-v2/kegiatan/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'
import SubKegiatanPOK from './SubKegiatan'

const KegiatanPOK: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:kegiatan',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'sub-kegiatan/:kegiatan',
    elements: <></>,
    subroute: SubKegiatanPOK,
  },
  // {
  //   icon: '',
  //   title: '',
  //   route: 'pdf/:program',
  //   elements: <PdfWrapper />,
  //   subroute: [],
  // },
  {
    icon: '',
    title: '',
    route: 'detail/:kegiatan',
    elements: <DetailWrapper />,
    subroute: [],
  },
]

export default KegiatanPOK
