import {PageDataContent} from '../../../helper/Model'
import {TableInputDataProps} from '../../../helper/TableInputV2'
import {TableInputDetailDataProps} from '../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  id: 'data-provinsi',
  title: 'Data Provinsi',
  path: '/pengaturan/data-provinsi',
  api: '/pengawasan_internal/data_provinsi',
}

const BaseBreadcrumb = [
  {
    title: 'Pengaturan',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  i18n: {baseName: `page.${InfoData.id}`},
  route: {
    url: InfoData.api,
  },
  header: [
    {
      title: 'No',
      props: {
        className: '',
        style: {
          minWidth: '70px',
        },
      },
    },
    {
      title: 'Kota',
      id: 'nama',
    },
  ],
  action: [
    {
      id: 'id',
      idMenu: 'detail',
      protect: 'detail/:id',
      title: 'Detail',
      route: 'detail/:id',
    },
    {
      id: 'id',
      idMenu: 'ubah',
      icon: 'RiDraftLine',
      protect: 'ubah/:id',
      title: 'Ubah',
      route: 'ubah/:id',
    },
    {
      id: 'id',
      idMenu: 'delete',
      icon: 'RiDeleteBinLine',
      protect: 'delete',
      title: 'Delete',
      route: '',
    },
  ],
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...MainBreadcrumb],
      },
    },
  },
}

export default PageData
