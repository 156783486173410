import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'img_profile',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        useImageCrop: true,
        props: {
          title: 'Upload Foto',
          type: 'file',
          accept: '.jpg ,.jpeg ,.png',
        },
      },
      imageCropper: {
        shape: 'round',
      },
      upload: {
        url: {
          folder: 'avatar',
          filename: 'avatar',
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'pegawai_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Pegawai is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Pegawai',
  //         placeholder: 'Pegawai',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       params: 'nama,nip',
  //       result: '$nama | $nip',
  //       id: 'id',
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'email',
    type: 'input',
    value: '',
    validator: Yup.string().email().required('Email is required'),
    options: {
      input: {
        props: {
          title: 'Email',
          placeholder: 'Email',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'fullname',
    type: 'input',
    value: '',
    validator: Yup.string().required('Fullname is required'),
    options: {
      input: {
        props: {
          title: 'Fullname',
          placeholder: 'Fullname',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'username',
    type: 'input',
    value: '',
    validator: Yup.string().required('Username is required'),
    options: {
      input: {
        props: {
          title: 'Username',
          placeholder: 'Username',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'password',
    type: 'input',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Password',
          type: 'password',
          placeholder: 'Password',
        },
      },
    },
  },
]

export {formInputList}
