import {DataRoutesProps} from '../../../UserRolesValidation'
import {LampiranSkbt} from './LampiranSkbt'
import {ListTemuan} from './ListTemuan'
import {Pengajuan} from './Pengajuan'
import {SurveyLayanan} from './SurveyLayanan'

const PelayananSkbt: DataRoutesProps = {
  title: 'BIMA (Bebas Temuan Kementerian Agama)',
  icon: 'element-10',
  route: 'pelayanan_skbt',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'List Temuan',
      icon: 'element-10',
      route: 'list_temuan',
      elements: <></>,
      subroute: ListTemuan,
    },
    {
      isPage: true,
      title: 'Lampiran',
      icon: 'element-10',
      route: 'lampiran',
      elements: <></>,
      subroute: LampiranSkbt,
    },
    {
      isPage: true,
      title: 'Pengajuan',
      icon: 'element-10',
      route: 'pengajuan',
      elements: <></>,
      subroute: Pengajuan,
    },
    {
      isPage: true,
      title: 'Survey Layanan',
      icon: 'element-10',
      route: 'survey_layanan',
      elements: <></>,
      subroute: SurveyLayanan,
    },
  ],
}

export default PelayananSkbt
