import * as Yup from 'yup'
import {FormInputProps} from '../../../../../helper/FormInput'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'

const pesanDisposisiList = [
  {title: 'Edarkan/kirimkan', value: 'Edarkan/kirimkan'},
  {title: 'Arsipkan', value: 'Arsipkan'},
  {title: 'Wakilkan', value: 'Wakilkan'},
  {title: 'Tugaskan yang berkompeten', value: 'Tugaskan yang berkompeten'},
  {title: 'Hadiri', value: 'Hadiri'},
  {title: 'Koordinasikan lebih lanjut', value: 'Koordinasikan lebih lanjut'},
  {title: 'Siapkan dokumen yang dibutuhkan', value: 'Siapkan dokumen yang dibutuhkan'},
  {
    title: 'Tindak lanjut sesuai Ketentuan yang berlaku',
    value: 'Tindak lanjut sesuai Ketentuan yang berlaku',
  },
  {title: 'Harap dipelajari', value: 'Harap dipelajari'},
  {title: 'Tanyakan unit kerja terkait', value: 'Tanyakan unit kerja terkait'},
  {title: 'Hubungi pihak terkait', value: 'Hubungi pihak terkait'},
  {title: 'Jika perlu, segera tindak lanjut', value: 'Jika perlu, segera tindak lanjut'},
  {title: 'Siapkan konsep', value: 'Siapkan konsep'},
  {title: 'Untuk perhatian', value: 'Untuk perhatian'},
  {title: 'Siapkan laporan', value: 'Siapkan laporan'},
  {title: 'Teliti Selesaikan', value: 'Teliti Selesaikan'},
  {title: 'Setuju diselesaikan', value: 'Setuju diselesaikan'},
  {title: 'Setuju', value: 'Setuju'},
  {title: 'Tolak', value: 'Tolak'},
  {title: 'Teliti & Pendapat', value: 'Teliti & Pendapat'},
  {title: 'Selesaikan', value: 'Selesaikan'},
  {title: 'Edarkan', value: 'Edarkan'},
  {title: 'Jawab', value: 'Jawab'},
  {title: 'Perbaiki', value: 'Perbaiki'},
  {title: 'Bicarakan dengan saya', value: 'Bicarakan dengan saya'},
  {title: 'Bicarakan bersama', value: 'Bicarakan bersama'},
  {title: 'Untuk diketahui', value: 'Untuk diketahui'},
  {title: 'Sesuai catatan', value: 'Sesuai catatan'},
  {title: 'Untuk perhatian', value: 'Untuk perhatian'},
  {title: 'Ingatkan', value: 'Ingatkan'},
  {title: 'Simpan', value: 'Simpan'},
  {title: 'Diarsipkan', value: 'Diarsipkan'},
  {title: 'Harap dihadiri diwakili', value: 'Harap dihadiri diwakili'},
]

const formInputList: FormInputProps[] = [
  {
    name: 'grup_disposisi',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Grup Disposisi',
          placeholder: 'Grup Disposisi',
        },
      },
      datalist: {
        api: '/tata_usaha/grup_tujuan',
        id: 'id',
        params: 'nama',
        result: '$nama',
      },
    },
  },
  // {
  //   name: 'tujuan_disposisi',
  //   type: 'multicheckbox',
  //   className: 'col-12',
  //   validator: Yup.array().required('Mohon untuk diisi.'),
  //   listData: [
  //     {
  //       title:
  //         'Sri Suryandari - Koordinator Bagian Perencanaan, Organisasi, dan Hukum - Bagian Perencanaan, Organisasi, dan Hukum Sekretariat Inspektorat Jenderal Kementerian Agama',
  //       value: 1,
  //     },
  //     {
  //       title:
  //         'Sri Suryandari - Koordinator Bagian Perencanaan, Organisasi, dan Hukum - Bagian Perencanaan, Organisasi, dan Hukum Sekretariat Inspektorat Jenderal Kementerian Agama',
  //       value: 2,
  //     },
  //     {
  //       title:
  //         'Sri Suryandari - Koordinator Bagian Perencanaan, Organisasi, dan Hukum - Bagian Perencanaan, Organisasi, dan Hukum Sekretariat Inspektorat Jenderal Kementerian Agama',
  //       value: 3,
  //     },
  //   ],
  //   options: {
  //     multicheckbox: {
  //       rowType: 'row',
  //       colClassName: 'col-12 col-md-6',
  //       props: {
  //         title: 'Tujuan Disposisi',
  //         placeholder: 'Tujuan Disposisi',
  //       },
  //     },
  //   },
  // },
  {
    name: 'tujuan_lainnya',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tujuan Lainnya',
          placeholder: 'Tujuan Lainnya',
          required: true,
        },
      },
      datalist: {
        isListpicker: true,
        api: '/tata_usaha/tujuan',
        id: 'pegawai_id',
        params: 'pegawai.nama',
        result: '$pegawai.nama',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'pegawai.nip', type: 'text'},
            {id: 'pegawai.level_jabatan', type: 'text'},
            {id: 'pegawai.satker_4', type: 'text'},
            // {id: 'totalSt', type: 'text'},
            // {id: 'jumlahST', type: 'text'},
            // {id: 'jumlahUsulan', type: 'text'},
          ],
          protect: '',
          props: {
            filter: [
              ...inputLayoutBuilder({
                input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
              }),
            ],
            filterData: {
              irjen: true,
            },
            route: {
              url: '/tata_usaha/tujuan',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
              {title: 'Level Jabatan'},
              {title: 'Satker'},
              // {title: 'Jumlah ST'},
              // {title: 'Jumlah ST (hari)'},
              // {title: 'Jumlah Usulan'},
            ],
            link: {
              // action: [
              //   {
              //     id: 'detail',
              //     protect: 'st_pegawai',
              //     title: 'Detail List ST Terbit',
              //     route: 'detail/:id',
              //     type: 'feedback',
              //     // input: inputListKategori
              //   },
              //   {
              //     id: 'detail',
              //     protect: 'usulan_pegawai',
              //     title: 'Detail List Usulan',
              //     route: 'detail/:id',
              //     type: 'feedback',
              //     // input: inputListKategori
              //   },
              // ],
            },
          },
        },
        //   api: '/kepegawaian/data-tenaga-audit',
        //   query: 'orderby=asc',
        //   params: 'nama,nip',
        //   result: (
        //     <>
        //       <div>
        //         <span className='fw-bold'>Nama : </span>
        //         <span>$nama</span>
        //       </div>
        //       <div>
        //         <span className='fw-bold'>NIP : </span>
        //         <span>$nip</span>
        //       </div>
        //     </>
        //   ),
        //   id: 'id',
        //   type: 'table',
        //   table: {
        //     data: [
        //       {id: 'nama', type: 'text'},
        //       {id: 'nip', type: 'text'},
        //       {id: 'level_jabatan', type: 'text'},
        //       {id: 'satker_4', type: 'text'},
        //       // {id: 'totalSt', type: 'text'},
        //       // {id: 'jumlahST', type: 'text'},
        //       // {id: 'jumlahUsulan', type: 'text'},
        //     ],
        //     protect: '',
        //     props: {
        //       filter: [
        //         ...inputLayoutBuilder({
        //           input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
        //         }),
        //       ],
        //       route: {
        //         url: '/kepegawaian/data-tenaga-audit',
        //       },
        //       headerData: [
        //         {title: 'No.', props: {className: 'min-w-50px'}},
        //         {title: 'Nama'},
        //         {title: 'NIP'},
        //         {title: 'Level Jabatan'},
        //         {title: 'Satker'},
        //         // {title: 'Jumlah ST'},
        //         // {title: 'Jumlah ST (hari)'},
        //         // {title: 'Jumlah Usulan'},
        //       ],
        //       link: {
        //         action: [
        //           {
        //             id: 'detail',
        //             protect: 'st_pegawai',
        //             title: 'Detail List ST Terbit',
        //             route: 'detail/:id',
        //             type: 'feedback',
        //             // input: inputListKategori
        //           },
        //           {
        //             id: 'detail',
        //             protect: 'usulan_pegawai',
        //             title: 'Detail List Usulan',
        //             route: 'detail/:id',
        //             type: 'feedback',
        //             // input: inputListKategori
        //           },
        //         ],
        //       },
        //     },
        //   },
      },
    },
  },
  {
    name: 'pesan_disposisi',
    type: 'multicheckbox',
    className: 'col-12',
    validator: Yup.array(),
    listData: pesanDisposisiList,
    options: {
      multicheckbox: {
        rowType: 'row',
        colClassName: 'col-12 col-md-6 col-lg-4',
        props: {
          title: 'Pesan Disposisi / Koordinasi / Saran',
          placeholder: 'Pesan Disposisi / Koordinasi / Saran',
        },
      },
    },
  },
  {
    name: 'pesan_tambahan',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Instruksi / Saran / Pesan Tambahan',
          placeholder: 'Instruksi / Saran / Pesan Tambahan',
        },
      },
      label: {
        description: (
          <>
            Diisi apabila dari pilihan <b>Pesan Disposisi / Koordinasi / Saran</b> diatas belum
            mencakup detail instruksi yang diinginkan
          </>
        ),
      },
    },
  },
  {
    name: 'start_date',
    type: 'input',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      input: {
        // isDate: true,
        dateOption: 'datetime',
        props: {
          title: 'Batas Waktu',
          placeholder: 'Batas Waktu',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'file_lampiran',
    type: 'input',
    className: 'col-12',
    validator: Yup.array(),
    options: {
      input: {
        props: {
          title: 'Lampiran',
          placeholder: 'Lampiran',
          type: 'file',
          multiple: true,
        },
      },
    },
  },
]

export {formInputList}
