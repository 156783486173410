import {debounce, unionBy} from 'lodash'
import {FC, Fragment, ReactNode, useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import Select, {GroupBase, Props, components} from 'react-select'
import {ButtonLoading} from './AppUi'
import {dataListTitle, NullProof, RouterQueryParams} from './AppFunction'
import {getData} from './FormAxios'

interface ListDataProps {
  label?: string
  title: string
  value: number | string
  options?: ListDataProps[]
}

interface ReactSelectProps {
  labelChild?: ReactNode
  name?: string
  data?: Props['options']
  error?: string
  options?: {
    id?: string
    isClearable?: boolean
    isSearchable?: boolean
    isMulti?: boolean
    barebone?: boolean
    labelType?: 'floating' | 'default'
    useLabelFloating?: boolean
  }
  defaultValue?: any
  noOptionMessage?: string
  placeholder?: string | number
  route?: {
    id?: string
    api?: string
    query?: string
    data?: any
    result?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    filter?: string
    params?: string
  }
  hasLoadMore?: boolean
  onChange: (value: any) => void
  onInputChange?: (input: any) => void
  onLoadMore?: ({
    page,
    commit,
  }: {
    page: number
    commit: ({data, end, error}: {data?: any; end?: boolean; error?: boolean}) => void
  }) => void
  props?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
}

const ReactSelect: FC<ReactSelectProps> = ({
  name,
  data,
  error,
  placeholder = 'Select data...',
  noOptionMessage = 'No data',
  defaultValue,
  options = {
    isClearable: true,
    isSearchable: true,
    isMulti: false,
    barebone: false,
    useLabelFloating: false,
    labelType: 'floating',
  },
  route,
  onChange = () => {},
  onInputChange,
  onLoadMore,
  hasLoadMore,
  props,
  labelChild,
}) => {
  const [idSelector, setIdSelector] = useState<string>(options?.id || route?.id || 'id')
  const isStaticData = route?.data?.length > 0
  const _hasLoadMore = route?.api ? true : hasLoadMore
  const isMultiple = options?.isMulti || props?.multiple
  const queryUrl = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [input, setInput] = useState<any>({
    search: '',
  })
  const [appData, setAppData] = useState<any>({
    data: [],
    search: [],
    dataInfo: {
      end: false,
      query: {
        page: 1,
        size: 10,
      },
    },
    searchInfo: {
      end: false,
      query: {
        page: 1,
        size: 10,
      },
    },
  })

  const resetSelect = () => {
    setAppData((p: any) => ({
      ...p,
      searchInfo: {
        end: false,
        query: {
          page: 1,
          size: 10,
        },
      },
    }))
  }

  const [loadMore, setLoadMore] = useState<{
    error?: boolean
    loading?: boolean
    end?: boolean
  }>({
    error: false,
    loading: false,
    end: false,
  })

  const valueDebouce = useRef(debounce((e) => e(), 300)).current

  useEffect(() => {
    return () => {
      valueDebouce.cancel()
    }
  }, [valueDebouce])

  const menuListRef = useRef<any>(null)
  const scrollPosition = useRef(0)
  const goLastScroll = () => {
    if (menuListRef.current) {
      menuListRef.current.scrollTop = scrollPosition.current - 20
    }
  }
  const updateDataList = (fieldsToUpdate: Partial<any>, data: any, post: any): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    post((p: any) => ({...p, ...updatedData}))
  }
  const CustomMenuList: FC<any> = ({children, ...props}) => {
    const [firstLoad, setFirstLoad] = useState<boolean>(false)
    useEffect(() => {
      goLastScroll()
      setFirstLoad(true)
    }, [])
    return (
      <components.MenuList
        {...props}
        innerRef={(el) => {
          menuListRef.current = el
          //@ts-ignore
          if (props.innerRef) props.innerRef(el)
        }}
        className={`${!firstLoad ? 'overflow-y-hidden' : ''}`}
      >
        {children}
        {!isStaticData &&
          _hasLoadMore &&
          !loadMore?.end &&
          (isSearch ? appData?.search?.length > 0 : appData?.data?.length > 0) && (
            <div className='px-5 py-2 d-flex justify-content-center'>
              <ButtonLoading
                loading={(!loadMore?.error && loadMore?.loading) || loadMore?.loading}
                title={{button: 'Load more'}}
                props={{
                  className: 'btn btn-sm btn-light',
                  onClick: () => {
                    if (!isEnd) {
                      updateDataList({loading: true}, loadMore, setLoadMore)
                      if (!isEnd && loadMore.loading) {
                        updatePage()
                        triggerLoadMore()
                      }
                      onLoadMore &&
                        onLoadMore({
                          page: input?.page,
                          commit: onLoadMoreSuccess,
                        })
                    }
                  },
                }}
                icon='RiRestartLine'
                iconClassName='fs-6'
              />
            </div>
          )}
      </components.MenuList>
    )
  }
  const [scrollTime, setScrollTime] = useState<number>(0)
  const formatGroupLabel = (data: GroupBase<typeof Option>) => (
    <div className='d-flex fs-7 justify-content-between align-items-center'>
      <span className='fw-bold'>{data.label}</span>
      <span>{data.options.length}</span>
    </div>
  )
  useEffect(() => {
    if (scrollTime > 0) {
      const timer = setInterval(() => {
        setScrollTime((p) => p - 1)
      }, 1)
      return () => {
        clearInterval(timer)
      }
    }
  }, [scrollTime])

  const [key, setKey] = useState<number>(0)

  useEffect(() => {
    setKey((p) => p + 1)
  }, [])

  const onLoadMoreSuccess = (e: {data?: any; end?: boolean; error?: boolean}) => {
    const {data: result, end, error} = e
    setLoadMore((p) => ({
      ...p,
      end: end,
      error: error,
      loading: false,
    }))
  }

  const updatePage = (reset = false) => {
    let selectedData: any = {}
    if (isSearch) {
      selectedData = {
        searchInfo: {
          ...appData?.searchInfo,
          query: {
            ...appData?.searchInfo?.query,
            page: reset ? 1 : (appData?.searchInfo?.query?.page || 1) + 1,
          },
        },
      }
    } else {
      selectedData = {
        dataInfo: {
          ...appData?.dataInfo,
          query: {
            ...appData?.dataInfo?.query,
            page: reset ? 1 : (appData?.dataInfo?.query?.page || 1) + 1,
          },
        },
      }
    }
    updateDataList(selectedData, appData, setAppData)
  }

  const isTriggerLoadMore = () => {
    if (!menuListRef.current) return false
    return menuListRef.current.scrollTop > menuListRef.current.scrollHeight - 201
  }

  const triggerLoadMore = async () => {
    updateDataList({loading: true}, loadMore, setLoadMore)
    if (loadMore.loading && !loading && !isEnd) {
      const selectedInfo = isSearch ? appData?.searchInfo : appData?.dataInfo
      let page = selectedInfo?.query?.page || 1
      const result = await loadData({})
      const isEnd = page >= result?.totalPages
      let selectedData: any = {}
      if (isSearch) {
        selectedData = {
          searchInfo: {
            ...appData?.searchInfo,
            end: isEnd,
          },
        }
      } else {
        selectedData = {
          dataInfo: {
            ...appData?.dataInfo,
            end: isEnd,
          },
        }
      }
      updateDataList(selectedData, appData, setAppData)
      updateDataList(
        {loading: false, end: page >= result?.totalPages, error: !result?.status},
        loadMore,
        setLoadMore
      )
      if (result?.status) {
        // onLoadMore &&
        //   onLoadMore({
        //     page: page,
        //     commit: onLoadMoreSuccess,
        //   })
      }
    }
    updateDataList({loading: false}, loadMore, setLoadMore)
  }

  // Custom

  const listDataConvert = (data?: ListDataProps[]) => {
    const olah = (_data?: ListDataProps[]) => {
      let _result: ListDataProps[] = []
      for (const l of _data || []) {
        let options: any = {}
        if (l?.options) {
          options = {
            options: olah(l?.options || []),
          }
        }
        _result.push({
          ...l,
          label: l.title,
          ...options,
        })
      }
      return _result
    }

    return olah(data)
  }

  const optionData = (data: any[]) => {
    let list: any[] = []
    data.forEach((l: any) => {
      if (l?.options) {
        list = [...list, ...optionData(l?.options)]
      } else {
        list.push(l)
      }
    })
    return list
  }

  const initDefaultValue = () => {
    let finalValue: any = null
    const value = defaultValue
    if (!value) {
      return finalValue
    }
    let resultListData: any[] = []
    const selectedData =
      !isStaticData && input?.search?.length > 0 ? appData?.search : appData?.data
    const listData: any[] = [...(selectedData || [])]

    resultListData = [...resultListData, ...optionData(listData)]

    if (props?.multiple) {
      let resultArray: any[] = []
      try {
        value?.forEach((l: any) => {
          let _idSelector = options?.id || route?.id || 'id'
          if (!Object.keys(l)?.includes(_idSelector)) {
            _idSelector = 'value'
          }
          const detail = resultListData?.find((f: any) => {
            return f[idSelector] === l[_idSelector]
          })
          if (detail) {
            const title = isStaticData
              ? detail?.title
              : dataListTitle(detail, {
                  filter: route?.filter,
                  params: route?.params,
                  result: route?.result,
                })
            const json = {
              value: l[_idSelector],
              title: title,
              label: title,
            }
            resultArray.push(json)
          }
        })
      } catch (_) {}
      finalValue = resultArray
    } else {
      const detail = resultListData.find((l: any) => l[idSelector] === value)
      if (detail) {
        const title = isStaticData
          ? detail?.title
          : dataListTitle(detail, {
              filter: route?.filter,
              params: route?.params,
              result: route?.result,
            })
        const json = {
          value: value,
          title: title,
          label: title,
        }
        finalValue = json
      }
    }
    return finalValue
  }

  const initOptionsData = (): Props['options'] => {
    const _data: any[] = [...((isSearch && !isStaticData ? appData?.search : appData?.data) || [])]
    let json: ListDataProps[] = []
    const isStatic = route?.data?.length > 0
    if (isStatic) {
      json = listDataConvert(_data)
    } else {
      for (let index = 0; index < _data?.length || 0; index++) {
        const e = _data[index]
        json.push({
          value: NullProof({input: e, params: route?.id || 'id'}),
          title: `${dataListTitle(e, {
            filter: route?.filter,
            params: route?.params,
            result: route?.result,
          })}`,
        })
      }
      json = listDataConvert(json)
    }
    return json
  }

  const loadData = async ({init = false}: {init?: boolean}) => {
    const _data = route?.data
    let apiData: any = []
    init && setLoading(true)
    let json: ListDataProps[] = []
    let result: any = {}
    if ((_data?.length || 0) > 0) {
      json = _data
      result = {
        status: true,
        data: _data,
        totalItems: json.length,
        totalPages: 1,
        currentPage: 1,
      }
    } else if (route?.id) {
      const selectedData = isSearch ? appData?.searchInfo?.query : appData?.dataInfo?.query

      const appQuery = Object.entries(input)
        .filter(([, value]) => value !== undefined || value !== '')
        .map(([key, value]) => `${key}=${value}`)

      const selectedQuery = Object.entries(selectedData || {})
        .filter(([, value]) => value !== undefined || value !== '')
        .map(([key, value]) => `${key}=${value}`)

      const userQuery = route?.query
        ? (route?.query || '').split('&').filter((param) => !param.startsWith('page='))
        : []

      const queryMap = new Map<string, string>(
        [...appQuery, ...selectedQuery, ...userQuery].map(
          (query) => query.split('=') as [string, string]
        )
      )
      const query = Array.from(queryMap, ([k, v]) => `${k}=${v}`).join('&')

      result = await getData(query, RouterQueryParams(route?.api, queryUrl) || '')
      if (defaultValue && init) {
        const value: any = defaultValue
        if (isMultiple) {
          for (let index = 0; index < value?.length || 0; index++) {
            const id = value[index][idSelector]
            const detail = await getData(
              '',
              `${RouterQueryParams(route?.api, queryUrl) || ''}/${id}`
            )
            if (detail?.status) {
              apiData = [...apiData, detail?.data]
            }
          }
        } else {
          const detail = await getData(
            '',
            `${RouterQueryParams(route?.api, queryUrl) || ''}/${value}`
          )
          if (detail?.status) {
            apiData = [...apiData, detail?.data]
          }
        }
      }
    }
    if (result?.status) {
      apiData = unionBy(apiData, result?.data || [], isStaticData ? undefined : idSelector)
      const oldData = isSearch ? appData?.search : appData?.data
      const resultData = init
        ? apiData
        : unionBy(oldData, apiData, isStaticData ? undefined : idSelector)
      if (resultData.length > 0 && !Object.keys(resultData[0])?.includes(idSelector)) {
        setIdSelector('value')
      }
      const jsonData = {
        ...appData,
        data: !isSearch ? resultData : appData?.data,
        search: isSearch ? resultData : appData?.search,
      }
      if (isSearch) {
        if (result?.currentPage > appData?.searchInfo?.query?.page) {
          jsonData.searchInfo = {
            ...appData?.searchInfo,
            query: {
              ...appData?.searchInfo?.query,
              page: result?.currentPage,
            },
          }
        }
      } else {
        if (result?.currentPage > appData?.dataInfo?.query?.page) {
          jsonData.dataInfo = {
            ...appData?.dataInfo,
            query: {
              ...appData?.dataInfo?.query,
              page: result?.currentPage,
            },
          }
        }
      }
      updateDataList(
        {
          ...jsonData,
        },
        appData,
        setAppData
      )
    }
    init && setKey((p) => p + 1)
    setLoading(false)
    return result
  }

  const isSearch = input?.search?.length > 0
  const isEnd = isSearch ? appData?.searchInfo?.end : appData?.dataInfo?.end

  useEffect(() => {
    if (isStaticData) return
    if (isSearch) {
      updateDataList({loading: true}, loadMore, setLoadMore)
      valueDebouce(() => {
        updateDataList(
          {
            searchInfo: {
              ...appData?.searchInfo,
              query: {
                ...appData?.searchInfo?.query,
                page: 1,
              },
            },
          },
          appData,
          setAppData
        )
        triggerLoadMore()
        onInputChange && onInputChange(input?.search)
      })
    } else {
      resetSelect()
    }
    updateDataList({end: isEnd}, loadMore, setLoadMore)
  }, [input?.search])

  useEffect(() => {
    loadData({init: true})
  }, [])

  const bareboneLayout = (children: ReactNode) => {
    return (
      <>
        {options.barebone ? (
          children
        ) : (
          <>
            <div
              className={`${options.useLabelFloating ? 'form-select' : ''} ${
                props?.disabled || loading ? 'form-select-disabled' : ''
              }`}
              style={{
                height: 'unset',
                paddingRight: '.1rem',
                backgroundImage: 'none',
              }}
            >
              {children}
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <Fragment key={key}>
      {bareboneLayout(
        <>
          <Select
            isDisabled={props?.disabled || loading}
            name={name}
            className={`react-select-styled ${options.barebone ? 'react-select-transparent' : ''}`}
            classNamePrefix='react-select'
            classNames={{
              control: () => (error ? 'border-danger' : ''),
              placeholder: () => (options.barebone ? 'mx-0' : ''),
              menu: () => `${options.barebone ? 'position-absolute mt-2 w-100' : ''} `,
            }}
            onInputChange={(e) => {
              let selectedData: any
              if (e?.length > 0) {
                selectedData = appData?.searchInfo?.end
              } else {
                selectedData = appData?.dataInfo?.end
              }
              updateDataList({search: e, page: 1}, input, setInput)
            }}
            closeMenuOnSelect={!options.isMulti}
            blurInputOnSelect={!options.isMulti}
            closeMenuOnScroll={(e) => {
              scrollPosition.current = menuListRef?.current?.scrollTop
              let status = false
              if (scrollTime < 1 && (e?.target || '') === document) {
                status = true
              }
              return status
            }}
            onMenuClose={() => {
              resetSelect()
            }}
            onMenuScrollToBottom={() => {
              if (!isStaticData && !isEnd && !loadMore.loading) {
                updatePage()
                triggerLoadMore()
              }
            }}
            onMenuOpen={() => setScrollTime(1)}
            maxMenuHeight={200}
            components={{
              IndicatorsContainer: ({children, ...props}) => (
                <components.IndicatorsContainer {...props} className='h-20px m-auto'>
                  {children}
                </components.IndicatorsContainer>
              ),
              Placeholder: ({children, ...props}) => (
                <components.Placeholder {...props} className='m-0'>
                  {children}
                </components.Placeholder>
              ),
              Option: ({children, ...props}) => (
                <components.Option {...props}>
                  <div dangerouslySetInnerHTML={{__html: props?.label || ''}}></div>
                </components.Option>
              ),
              SingleValue: ({children, data, ...props}) => (
                <components.SingleValue data={data} {...props}>
                  <></>
                  <div
                    // @ts-ignore
                    dangerouslySetInnerHTML={{__html: data?.label || ''}}
                  ></div>
                </components.SingleValue>
              ),
              MultiValue: ({children, data, ...props}) => (
                <components.MultiValue data={data} {...props}>
                  <div
                    // @ts-ignore
                    dangerouslySetInnerHTML={{__html: data?.label || ''}}
                    style={{pointerEvents: 'none'}}
                  ></div>
                </components.MultiValue>
              ),
              Menu: ({children, ...props}) => (
                <div className='position-relative' style={{margin: '0 -1rem'}}>
                  <components.Menu
                    {...props}
                    className='position-absolute top-0 mt-5 start-0 w-100'
                  >
                    {children}
                  </components.Menu>
                </div>
              ),
              NoOptionsMessage: ({children, ...props}) => (
                <>
                  <components.NoOptionsMessage {...props}>
                    {loadMore?.loading ? (
                      <>
                        <div className='d-flex fs-5 gap-4 align-items-center justify-content-center w-100'>
                          <div className='spinner-border w-20px h-20px' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                          <div>Loading...</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{noOptionMessage}</div>
                      </>
                    )}
                  </components.NoOptionsMessage>
                </>
              ),
              MenuList: CustomMenuList,
            }}
            // menuIsOpen
            options={loading ? undefined : initOptionsData()}
            defaultValue={loading ? undefined : initDefaultValue()}
            // value={initDefaultValue()}
            formatGroupLabel={formatGroupLabel}
            isClearable={options.isClearable}
            isSearchable={options.isSearchable}
            isMulti={props?.multiple || options.isMulti}
            placeholder={loading ? 'Loading...' : placeholder}
            onChange={(val) => {
              let data = [...appData?.data]
              let filter: any[] = []
              let result: any = []
              if (isMultiple) {
                filter = [...filter, ...val]
              } else {
                filter = [...filter, val]
              }
              if (isSearch) {
                const detail = appData?.search?.filter((l: any) =>
                  filter.some((f: any) => f.value === l[idSelector])
                )
                data = unionBy(data, detail, isStaticData ? undefined : idSelector)
                updateDataList(
                  {
                    data: data,
                  },
                  appData,
                  setAppData
                )
              }
              if (isStaticData) {
                result = filter
              } else {
                result = data?.filter((l: any) =>
                  filter.some((f: any) => f.value === l[idSelector])
                )
              }
              onChange && onChange(result)
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor:
                  options.barebone || options.labelType === 'floating'
                    ? 'transparent !important'
                    : '',
                backgroundImage:
                  options.barebone || options.labelType === 'floating' ? 'none !important' : '',
                minHeight: 'unset !important',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: options.barebone || options.labelType === 'floating' ? '0 !important' : '',
              }),
              input: (provided) => ({
                ...provided,
                height: '100%',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                whiteSpace: 'pre-line',
              }),
              option: (provided, state) => ({
                ...provided,
                whiteSpace: 'pre-line',
              }),
            }}
          />
          {error && <div className='text-danger pt-2'>{error}</div>}
        </>
      )}
    </Fragment>
  )
}

export default ReactSelect
