import {FC, Fragment, lazy, memo, ReactNode, Suspense, useEffect, useRef, useState} from 'react'
import {useThemeMode} from '../../_metronic/partials'
import CurrencyFormat from 'react-currency-format'
import {Link, useParams} from 'react-router-dom'
import {
  NullProof,
  RouterQueryParams,
  colorPalleteList,
  getColorDecimal,
  getColorPallete,
} from './AppFunction'
import ReactIcon from './ReactIcon'
import * as Icons from 'react-icons/ri'
import * as IconsHi2 from 'react-icons/hi2'
import ReactLoading from './ReactLoading'
import {getData} from './FormAxios'
import {WithChildren} from '../../_metronic/helpers'
const CountUpUi = lazy(() => import('./CountUp'))

type SizeComponentProps = 'col-3' | 'col-4' | 'col-6' | 'col-12'
const componentResponsiveList = (minSize: 1 | 2 | 3 = 1) => {
  const minSizeList = {
    1: 'col-12',
    2: 'col-6',
    3: 'col-4',
  }
  const responsiveList = {
    item4: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-6`,
      'col-12': `${minSizeList[minSize]} col-md-3`,
    },
    item3: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-4`,
      'col-12': `${minSizeList[minSize]} col-md-4`,
    },
    item2: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]} col-md-6`,
      'col-6': `${minSizeList[minSize]} col-md-6`,
      'col-12': `col-12 col-md-6`,
    },
    item1: {
      'col-3': `${minSizeList[minSize]}`,
      'col-4': `${minSizeList[minSize]}`,
      'col-6': `${minSizeList[minSize]}`,
      'col-12': `${minSizeList[minSize]}`,
    },
  }
  return responsiveList
}

type DashboardDataProps = {
  id?: number
  title: string
  desc?: string | number
  value?: string | number
}

type ChartCircleProps = {
  size?: SizeComponentProps
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  id: string
  title?: string
  color?: 'primary' | 'danger' | 'success' | 'warning' | 'info'
  themeConfig?: {
    theme?: 'light' | 'dark'
    color?: {
      value?: string | keyof typeof colorPalleteList
      titlePrimary?: boolean
      textInvert?: boolean
    }
  }
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  isCurrency?: boolean
  data?: {
    title: string
    value: number
    color: string
  }[]
  filter?: {
    value: string
    placeholder?: string
    data: {
      label: string
      value: string
    }[]
  }
}

const ChartCircle: FC<ChartCircleProps> = ({
  size = 'col-12',
  props,
  id = 'circlechart',
  title = 'Title',
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  isCurrency = false,
  data = [],
  filter,
  color = 'primary',
  themeConfig = {
    theme: 'dark',
    color: {
      titlePrimary: false,
      value: 'primary',
      textInvert: false,
    },
  },
}) => {
  const [datas, setDatas] = useState<any>([])
  const [totalDatas, setTotalDatas] = useState<number>(0)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) return
    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145
  ) {
    const el = document.getElementById(id)
    if (!el) return
    el.innerHTML = ''

    var options = {
      size: chartSize,
      lineWidth: chartLine,
      rotate: chartRotate,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
      // @ts-ignore
      G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, percent: number) {
      percent = Math.min(Math.max(0, percent || 1), 1)
      if (!ctx) {
        return
      }

      ctx.beginPath()
      ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
      ctx.strokeStyle = color
      ctx.lineCap = 'round' // butt, round or square
      ctx.lineWidth = lineWidth
      ctx.stroke()
    }

    let totalData: number = 0
    let totalPercent: number = 0
    for (const item of data) {
      totalData += item.value
    }
    setTotalDatas(totalData)
    const result: any[] = [...data]

    // Init 2
    data.forEach((l, i) => {
      totalPercent += (l.value / totalData) * 100
      result[i].percent = (l.value / totalData) * 100
      result[i].pie = totalPercent
    })
    result
      .sort((a, b) => b.pie - a.pie)
      .forEach((l) => {
        drawCircle(l.color, options.lineWidth, l.pie / 100)
      })
    setDatas(result)
  }
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  return (
    <div {...props} className={`${size}`}>
      <div className='card card-flush h-100'>
        <div className='d-flex flex-column gap-3'>
          <div
            className={`fs-2hx fw-bold d-flex flex-column p-4 rounded shadow`}
            style={{
              backgroundColor: appBackgroundColor,
              color: textColor,
            }}
          >
            <div className='d-flex justify-content-between'>
              <CurrencyFormat
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={isCurrency ? 'Rp. ' : ''}
                displayType='text'
                value={totalDatas}
              />
              {filter && (
                <div>
                  <select className='form-select form-select'>
                    <option value=''>{filter.placeholder || 'Pilih Data'}</option>
                    {filter.data.map((l, i) => (
                      <Fragment key={i}>
                        <option value={l.value}>{l.label}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className='fs-5'>{title}</div>
          </div>
          <div className='d-flex flex-row gap-4 p-4'>
            <div
              id={id}
              ref={chartRef}
              style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
              data-kt-size={chartSize}
              data-kt-line={chartLine}
            />
            <div className='row w-100 gx-2'>
              {datas.map((l: any, i: number) => (
                <div
                  key={i}
                  className={`d-flex align-items-center ${
                    (datas?.length || 0) % 4 === 0
                      ? componentResponsiveList(2).item4[size]
                      : (data?.length || 0) % 3 === 0
                      ? componentResponsiveList(2).item3[size]
                      : (data?.length || 0) % 2 === 0
                      ? componentResponsiveList(2).item2[size]
                      : 'col-6 col-md-12'
                  }`}
                >
                  <div className='d-flex flex-column fw-semibold my-1'>
                    <div className='d-flex align-items-center'>
                      <div
                        className='bullet w-8px h-3px rounded-2 me-3'
                        style={{backgroundColor: l.color}}
                      />
                      <div className='text-gray-500 flex-grow-1 me-4'>
                        {l.title} ({Math.round(l.percent)}%)
                      </div>
                    </div>
                    <div className=' fw-bolder text-gray-700 text-xxl'>
                      <CurrencyFormat
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={isCurrency ? 'Rp. ' : ''}
                        displayType='text'
                        value={l.value}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const dashboardThemeBuilder = ({
  themeConfig = {
    theme: 'light',
    type: 'default',
    color: {
      titlePrimary: false,
      value: '#FFFFFF',
      textInvert: false,
    },
  },
}: {
  themeConfig?: {
    theme?: 'auto' | 'light' | 'dark'
    type?: 'default' | 'stripped' | 'variant'
    color?: {
      value?: string | keyof typeof colorPalleteList
      titlePrimary?: boolean
      textInvert?: boolean
    }
  }
}) => {
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appColor =
    themeConfig.theme === 'light'
      ? themeConfig.color?.textInvert
        ? colorPallete.textColor
        : colorPallete.lightTextColor
      : themeConfig.color?.textInvert
      ? colorPallete.lightTextColor
      : colorPallete.textColor
  const appColorInvert = colorPallete.invertTextColor
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const appBackgroundColorClick =
    themeConfig.theme === 'light' ? colorPallete.lightColorClick : colorPallete.backgroundColorClick
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  const colorDecimal = getColorDecimal(textColor)
  return {
    appColor,
    appBackgroundColor,
    appBackgroundColorHover,
    appBackgroundColorClick,
    colorDecimal,
    textColor,
    appColorInvert,
  }
}

type TotalDashboardProps = {
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  type?: 'currency' | 'number'
  isPercent?: boolean
  color?: string
  icon?: keyof typeof Icons
  isIcon?: boolean
  theme?: 'light' | 'dark'
  themeConfig?: {
    theme?: 'light' | 'dark'
    type?: 'default' | 'stripped' | 'variant'
    color?: {
      value?: string | keyof typeof colorPalleteList
      titlePrimary?: boolean
      textInvert?: boolean
    }
  }
  title: string
  total: number
  isTotal?: boolean
  maxTotal?: number
  link?: string
  data?: {
    title: string
    total: number
    className?: string
    link?: string
    icon?: keyof typeof Icons | keyof typeof IconsHi2
    iconColor?: string
  }[]
  list?: {
    show?: boolean
    data: DashboardDataProps[]
    valueType?: 'text' | 'number' | 'date' | 'currency'
    descType?: 'text' | 'number' | 'date' | 'currency'
    slug?: {
      data?: string
      id?: string
      external?: boolean
      label?: string
    }
    config?: {
      id: string
      className?: string
      label?: string
      labelClassName?: string
    }[]
  }
  api?: {
    query?: string
    url?: string
  }
  onComplete?: any
}

export interface TotalDashboardV2DataProps {
  /**
   * @param type - typeof data
   */
  type?: 'default' | 'currency' | 'group' | 'percent' | 'button'
  /**
   * @param name - label for data
   */
  name?: string
  /**
   * @param desc - label for desc
   */
  desc?: string
  /**
   * @param link - make clickable and linkable
   */
  link?: string
  /**
   * @param value - valueof data / column name after hit api (return number)
   */
  value?: number | string
  /**
   * @param icon - show icon
   */
  icon?: keyof typeof Icons
  /**
   * @param children - ReactNode
   */
  children?: ReactNode
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  nameProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  descProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  valueProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

interface TotalDashboardV2Props {
  /**
   * Data Dashboard.
   *
   * @param type - typeof data
   * @param name - label for data
   * @param link - make clickable and linkable
   * @param value - valueof data / column name after hit api
   * @param icon - show icon
   * @param props - div parameter for layouting (className, onClick, etc)
   *
   */
  data: TotalDashboardV2DataProps[]
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  propsTitle?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * @param props - div parameter for layouting (className, onClick, etc)
   */
  propsDesc?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * Option Dashboard.
   *
   * @param layout - Option for Dashboard Layout
   * @param themeConfig - Option for Theme Dashboard
   *
   */
  options?: {
    /**
     * Layout Dashboard
     *
     * @param banner - Header Component
     * @param subbanner - Footer Component
     *
     */
    layout?: {
      /**
       * @param banner - List Theme Header
       */
      banner?: 'default' | 'default-vertical' | 'reverse' | 'reverse-vertical'
      /**
       * @param subbanner - List Theme Footer
       */
      subbanner?: 'default' | 'column' | 'column-reverse'
      desc?: 'default' | 'below' | 'left' | 'right'
    }
    /**
     * Theme Dashboard
     *
     * @param theme - Theme Dark / Light
     * @param type - Variant Theme
     * @param color - Color Theme
     */
    themeConfig?: {
      theme?: 'auto' | 'light' | 'dark'
      type?: 'default' | 'stripped' | 'variant'
      color?: {
        value?: string | keyof typeof colorPalleteList
        titlePrimary?: boolean
        textInvert?: boolean
      }
    }
  }
  /**
   * API Options
   *
   * @param query - Query API
   * @param url - URL API
   *
   */
  api?: {
    /**
     *
     * @param query - Query API
     */
    query?: string
    /**
     *
     * @param url - URL API
     */
    url?: string
  }
  /**
   * Button Options
   *
   * @param link - URL Button
   * @param name - Button Name
   *
   */
  button?: {
    /**
     *
     * @param link - URL Button
     */
    link?: string
    /**
     *
     * @param name - Button Name
     */
    name?: string
  }
  /**
   * onComplete return API Response
   *
   */
  onComplete?: (e: {data: any; update: (data: any) => void}) => void
}

const LinkUi: FC<WithChildren & {slug?: string}> = ({children, slug}) => {
  const query = useParams()
  return slug ? (
    <div className='d-flex'>
      <Link className='w-100' style={{color: 'inherit'}} to={RouterQueryParams(slug, query)}>
        {children}
      </Link>
    </div>
  ) : (
    <>{children}</>
  )
}

const TotalDashboardV2: FC<TotalDashboardV2Props> = ({
  api,
  props,
  propsTitle,
  propsDesc,
  data,
  options,
  button,
  onComplete,
}) => {
  const bannerLayout = options?.layout?.banner || 'default'
  const subbannerLayout = options?.layout?.subbanner || 'default'
  const descLayout = options?.layout?.desc || 'default'
  const {
    appBackgroundColor,
    appBackgroundColorHover,
    appBackgroundColorClick,
    appColor,
    appColorInvert,
    colorDecimal,
    textColor,
  } = dashboardThemeBuilder({themeConfig: options?.themeConfig})
  const theme = options?.themeConfig?.type || 'default'

  const query = useParams()

  const [_data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  const loadData = async () => {
    setLoading(true)
    let result: {status?: boolean; data?: any} = {status: false, data: {}}
    result = await getData(api?.query || '', api?.url || '')
    if (result?.status) {
      setData(result?.data)
      onComplete &&
        onComplete({
          data: result?.data,
          update: (updateData) => {
            setData(() => ({...result?.data, ...updateData}))
          },
        })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  const olahAngka = (value: any) => {
    let number = 0
    if (typeof value === 'string') {
      number = NullProof({input: _data, params: value, isLabel: false}) || 0
    } else if (typeof value === 'number') {
      number = value
    }
    return number
  }

  const autoFontSize = (value: number) => {
    let result = [
      {
        condition: 0,
        value: 'fs-3x',
      },
      {
        condition: 10,
        value: 'fs-2qx',
      },
      {
        condition: 12,
        value: 'fs-2x',
      },
      {
        condition: 15,
        value: 'fs-1',
      },
    ]
    return result.reduce(
      (acc, item) => (value >= item.condition ? item.value : acc),
      result[0].value
    )
  }

  return (
    <div {...props}>
      <div
        className={`card card-flush shadow-sm d-flex flex-column justify-content-evenly p-0`}
        style={{
          background:
            theme === 'default'
              ? `linear-gradient(90deg, ${appBackgroundColorClick} 50%, ${appBackgroundColor} 150%)`
              : undefined,
          color: appColor,
        }}
      >
        {data.length > 0 && (
          <LinkUi slug={data[0].link}>
            <div
              className='rounded position-relative overflow-hidden'
              style={{
                padding: '1rem',
                background: `linear-gradient(90deg, ${appBackgroundColor} 50%, ${appBackgroundColorClick} 150%)`,
              }}
              onClick={data[0].props?.onClick}
            >
              <div
                className={`d-flex ${
                  bannerLayout === 'default-vertical' || bannerLayout === 'reverse-vertical'
                    ? 'flex-column-reverse'
                    : 'flex-column'
                }`}
              >
                {data[0].children}
                {data[0].name && (
                  <div
                    className={`${propsTitle || 'fs-4 fw-semibold'} ${
                      bannerLayout === 'reverse' || bannerLayout === 'reverse-vertical'
                        ? 'text-end'
                        : ''
                    }`}
                    {...data[0].nameProps}
                  >
                    {data[0].name}
                  </div>
                )}
                {(data[0].value !== '#' || data[0].desc) && (
                  <>
                    <div
                      className={`d-flex ${
                        descLayout === 'default'
                          ? 'flex-column'
                          : descLayout === 'left'
                          ? 'gap-4 justify-content-between align-items-center'
                          : descLayout === 'right'
                          ? 'gap-4 justify-content-between align-items-center flex-row-reverse'
                          : 'flex-column-reverse'
                      }`}
                    >
                      {data[0].desc && (
                        <div className={`text-break ${propsDesc || 'fs-6'}`} {...data[0].descProps}>
                          {data[0].desc}
                        </div>
                      )}
                      {data[0].value !== '#' && (
                        <div
                          className={`d-flex align-items-center gap-2 ${autoFontSize(
                            olahAngka(data[0].value).toString().length
                          )} fw-bold ${
                            bannerLayout === 'reverse' || bannerLayout === 'reverse-vertical'
                              ? 'justify-content-end'
                              : ''
                          }`}
                          {...data[0].valueProps}
                        >
                          <ReactLoading
                            loading={loading}
                            loadingProps={{
                              className: 'd-flex align-items-center mt-3 fs-6 gap-2',
                              style: {
                                color: textColor,
                              },
                            }}
                          >
                            {data[0].type === 'button' ? (
                              <div className='fs-6'>{data[0].value}</div>
                            ) : (
                              <Fragment key={data[0].value}>
                                <CountUpUi
                                  value={olahAngka(data[0].value)}
                                  type={data[0].type !== 'percent' ? data[0].type : 'default'}
                                />
                                {data[0].type === 'percent' && <div className='fs-4'>%</div>}
                              </Fragment>
                            )}
                          </ReactLoading>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div
                className='position-absolute opacity-25'
                style={{
                  right:
                    bannerLayout === 'reverse' || bannerLayout === 'reverse-vertical'
                      ? 'unset'
                      : '10px',
                  bottom: bannerLayout === 'default-vertical' ? 'unset' : '-20px',
                  top: bannerLayout === 'default' ? 'unset' : '-20px',
                }}
              >
                {data[0].icon && (
                  <ReactIcon
                    icon={data[0].icon}
                    props={{
                      style: {
                        width: '100px',
                        height: '100px',
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </LinkUi>
        )}
        {data.length > 1 && (
          <div
            className={`row gx-4 ${subbannerLayout === 'default' ? 'g-1' : 'g-4'}`}
            style={{
              padding: '1rem',
              color: theme === 'default' ? appColor : appColorInvert,
            }}
          >
            {data.slice(1).map((l, i) => (
              <Fragment key={i}>
                <div className={`col-12`} {...l.props}>
                  <LinkUi slug={l?.link}>
                    <div
                      className={`d-flex justify-content-between ${
                        l.type === 'button' ? 'cursor-pointer' : ''
                      } ${
                        subbannerLayout === 'default'
                          ? 'flex-row align-items-center gap-4'
                          : `${
                              subbannerLayout === 'column' ? 'flex-column' : 'flex-column-reverse'
                            } gap-1`
                      }`}
                    >
                      <div className={`d-flex align-items-center gap-1`}>
                        {l.icon && subbannerLayout === 'default' && (
                          <ReactIcon
                            icon={l.icon}
                            props={{
                              style: {
                                width: '20px',
                                height: '20px',
                              },
                            }}
                          />
                        )}
                        <div className='d-flex flex-column'>
                          <div className={`text-break ${l.desc ? 'fw-bold' : ''}`}>{l.name}</div>
                          {l.desc &&
                            (descLayout === 'default' || subbannerLayout === 'default') && (
                              <div className={`text-break ${propsDesc || 'fs-6'}`}>{l.desc}</div>
                            )}
                        </div>
                      </div>
                      <div
                        className={`fw-bold d-flex align-items-center gap-2 ${
                          subbannerLayout === 'default' ? 'fs-4 justify-content-end' : 'h1'
                        }`}
                        style={{minWidth: '50px', color: 'inherit'}}
                      >
                        <ReactLoading
                          loading={loading}
                          loadingProps={{
                            className: `d-flex align-items-center fs-6 gap-2 ${
                              subbannerLayout === 'default' ? 'justify-content-end' : ''
                            }`,
                            style: {
                              color: theme === 'default' ? appColor : appColorInvert,
                            },
                          }}
                          hideTitle
                        >
                          {l.icon && subbannerLayout !== 'default' && (
                            <ReactIcon
                              icon={l.icon}
                              props={{
                                style: {
                                  width: '20px',
                                  height: '20px',
                                },
                              }}
                            />
                          )}
                          {l.type === 'button' ? (
                            <div className='fs-6'>{l.value}</div>
                          ) : (
                            <Fragment key={data[0].value}>
                              <CountUpUi
                                value={olahAngka(l.value)}
                                type={l.type !== 'percent' ? l.type : 'default'}
                              />
                              {l.type === 'percent' && <div className='fs-6'>%</div>}
                            </Fragment>
                          )}
                        </ReactLoading>
                      </div>
                      {l.desc && descLayout === 'below' && subbannerLayout !== 'default' && (
                        <div className={`text-break ${propsDesc || 'fs-6'}`}>{l.desc}</div>
                      )}
                    </div>
                  </LinkUi>
                </div>
              </Fragment>
            ))}
            {button && (
              <div className='mt-4 w-100'>
                <LinkUi slug={button?.link}>
                  <button
                    type='button'
                    className='btn btn-primary w-100'
                    style={{
                      //@ts-ignore
                      '--bs-primary': appBackgroundColor,
                      '--bs-primary-active': appBackgroundColorHover,
                    }}
                  >
                    {button?.name || 'Lihat Semua'}
                  </button>
                </LinkUi>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const TotalDashboard: FC<TotalDashboardProps> = ({
  props,
  title,
  link,
  total = 0,
  maxTotal = 1000,
  data = [],
  list,
  icon = 'RiDashboardLine',
  themeConfig = {
    theme: 'light',
    type: 'default',
    color: {
      titlePrimary: false,
      value: '#FFFFFF',
      textInvert: false,
    },
  },
  type = 'number',
  isPercent = false,
  api,
  onComplete,
}) => {
  const query = useParams()
  const [loading, setLoading] = useState(true)
  const checkPercent = Math.min(Math.round((total / (maxTotal || total)) * 100), 100)
  const percent = checkPercent
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appColor =
    themeConfig.theme === 'light'
      ? themeConfig.color?.textInvert
        ? colorPallete.textColor
        : colorPallete.lightTextColor
      : themeConfig.color?.textInvert
      ? colorPallete.lightTextColor
      : colorPallete.textColor
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  const colorDecimal = getColorDecimal(textColor)
  const SlugReplacer = (data: any, config: any) => {
    const id = config?.id?.split(',')
    let slug = config?.data
    for (const l of id) {
      slug = slug.replaceAll(`:${l}`, `${data[l]}`)
    }
    return slug
  }

  const reactLoadingConfig = {
    loadingProps: {
      style: {
        color: textColor,
      },
    },
  }

  const loadData = async () => {
    setLoading(true)
    let result: {status?: boolean; data?: any} = {status: false, data: {}}
    result = await getData(api?.query || '', api?.url || '')
    if (result?.status) {
      onComplete && onComplete(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  const LinkUi: FC<WithChildren & {slug?: string}> = ({children, slug}) => {
    return slug ? (
      <div className='d-flex'>
        <Link className='w-100' to={RouterQueryParams(slug, query)}>
          {children}
        </Link>
      </div>
    ) : (
      <>{children}</>
    )
  }

  return (
    <div {...props}>
      <div
        className={`card card-flush d-flex flex-column justify-content-evenly pb-0 rounded-bottom-0 ${
          list?.data && list.show ? 'text-center' : ''
        }`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
        }}
      >
        <ReactLoading loading={loading} {...reactLoadingConfig}>
          <div className='fs-2hx fw-bold d-flex flex-column'>
            {!isPercent && (
              <>
                <ReactIcon icon={icon} props={{className: 'mb-2', style: {color: appColor}}} />
                <LinkUi slug={link}>
                  <CountUpUi
                    props={{style: {color: appColor}}}
                    value={total}
                    type={type === 'currency' ? 'currency' : 'default'}
                  />
                </LinkUi>
              </>
            )}
            <LinkUi slug={link}>
              <div className='fs-5 mb-4' style={{color: appColor}}>
                {title}
              </div>
            </LinkUi>
            {isPercent && (
              <>
                <div style={{color: appColor}}>
                  <div className='d-flex align-items-end gap-2'>
                    <div className='fs-2'>
                      <LinkUi slug={link}>
                        <CountUpUi
                          props={{style: {color: appColor}}}
                          value={total}
                          type={type === 'currency' ? 'currency' : 'default'}
                        />
                      </LinkUi>
                    </div>
                    <LinkUi slug={link}>
                      <div className='fs-7 opacity-75' style={{color: appColor}}>
                        {title}
                      </div>
                    </LinkUi>
                  </div>
                  <div className='d-flex gap-2 align-items-center' style={{color: appColor}}>
                    <div className='w-100'>
                      <div
                        className='h-8px w-100 rounded-pill'
                        style={{
                          backgroundColor: `rgba(${colorDecimal.red},${colorDecimal.green},${colorDecimal.blue}, 0.5)`,
                        }}
                      >
                        <div
                          className={`rounded h-8px`}
                          role='progressbar'
                          style={{width: `${percent}%`, backgroundColor: appColor}}
                        />
                      </div>
                    </div>
                    <div className='fs-6 fw-normal'>{percent}%</div>
                  </div>
                  <div className='fs-6 d-flex gap-2' style={{color: appColor}}>
                    <div>Dari</div>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={maxTotal}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactLoading>
      </div>
      <div
        className={`card card-flush d-flex flex-column rounded-top-0 ${
          list?.data && list.show ? 'text-center' : ''
        }`}
        style={{
          backgroundColor: list?.data && list.show ? appBackgroundColor : appBackgroundColorHover,
          padding: '1rem',
          paddingBottom: list?.data && list?.show ? '70px' : '1rem',
        }}
      >
        <div className='row g-2'>
          {data?.map((l, i) => (
            <div
              className={`${
                l.className
                  ? l.className
                  : `${
                      (data?.length || 0) % 3 === 0
                        ? 'col-4'
                        : (data?.length || 0) % 2 === 1
                        ? 'col-4'
                        : (data?.length || 0) % 2 === 0
                        ? 'col-6'
                        : 'col-12'
                    }`
              } fs-3 fw-bold d-flex flex-column`}
              style={{color: appColor}}
              key={i}
            >
              <ReactLoading hideTitle loading={loading} {...reactLoadingConfig}>
                <LinkUi slug={l.link}>
                  <CountUpUi
                    props={{style: {color: appColor}}}
                    value={l.total}
                    type={type === 'currency' ? 'currency' : 'default'}
                  />
                </LinkUi>
              </ReactLoading>
              <LinkUi slug={l.link}>
                <div className='fs-6' style={{color: appColor}}>
                  {l.title}
                </div>
              </LinkUi>
            </div>
          ))}
        </div>
      </div>
      {!loading && list?.show && (
        <div className='bg-body pb-3 card-rounded'>
          <div
            className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
            style={{marginTop: data ? '-50px' : '0'}}
          >
            {list?.data.map((item, index) => (
              <div key={index} className='d-flex align-items-center mb-6'>
                <div className='row align-items-center w-100 g-2'>
                  <div className='mb-1 pe-3 col'>
                    <span className='fs-5 text-gray-800 fw-bold'>{item.title}</span>
                    <div className='text-gray-400 fw-semibold fs-7'>{item.desc}</div>
                  </div>
                  {list?.slug ? (
                    <div className='col-auto'>
                      <div>
                        {list.slug.external ? (
                          <a href={SlugReplacer(item, list.slug)}>
                            <button
                              type='button'
                              className={`btn btn-primary px-8 py-2`}
                              style={{
                                //@ts-ignore
                                '--bs-primary': appBackgroundColor,
                                '--bs-primary-active': appBackgroundColorHover,
                              }}
                            >
                              {list.slug.label || 'Detail'}
                            </button>
                          </a>
                        ) : (
                          <Link to={SlugReplacer(item, list.slug)}>
                            <button
                              type='button'
                              className={`btn btn-primary px-8 py-2`}
                              style={{
                                //@ts-ignore
                                '--bs-primary': appBackgroundColor,
                                '--bs-primary-active': appBackgroundColorHover,
                              }}
                            >
                              {list.slug.label || 'Detail'}
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='col-auto'>
                      <div className='fw-bold fs-5'>
                        {typeof item.value === 'string' ? (
                          item.value
                        ) : (
                          <CountUpUi
                            props={{style: {color: appColor}}}
                            value={item?.value || 0}
                            type={type === 'currency' ? 'currency' : 'default'}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {list.show && (
              <div className='d-flex align-items-center'>
                <button
                  type='button'
                  className={`btn btn-primary w-100`}
                  style={{
                    //@ts-ignore
                    '--bs-primary': appBackgroundColor,
                    '--bs-primary-active': appBackgroundColorHover,
                  }}
                >
                  Lihat Semua
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const dashboardDataBuilder = ({
  input = [],
  limit = 5,
  title = 'Data',
  params,
}: {
  input: any
  limit: number
  title: string
  params?: {title: string; desc?: string; value?: string; id?: string}
}) => {
  let result: any = []
  const data = {
    data: input || [],
  }
  input?.slice(0, limit).map((l: any, i: number) => {
    const _id = NullProof({input: l, params: params?.value || 'id'})
    const _title = params?.title
      ? NullProof({input: l, params: params?.title})
      : `${title} ${i + 1}`
    const _desc = params?.desc ? NullProof({input: l, params: params?.desc}) : null
    const _value = params?.value ? NullProof({input: l, params: params?.value}) : null
    result.push({
      id: _id,
      title: _title,
      desc: _desc,
      value: _value,
    })
  })
  return result
}

const TotalDashboardSmall: FC<TotalDashboardProps> = ({
  props,
  title,
  link,
  total = 0,
  isTotal = true,
  isIcon = true,
  maxTotal = 1000,
  data = [],
  icon = 'RiDashboardLine',
  themeConfig = {
    theme: 'light',
    type: 'default',
    color: {
      titlePrimary: false,
      value: '#FFFFFF',
      textInvert: false,
    },
  },
  type = 'number',
  isPercent = false,
  api,
  onComplete,
}) => {
  const query = useParams()
  const [loading, setLoading] = useState(true)
  const checkPercent = Math.min(Math.round((total / (maxTotal || total)) * 100), 100)
  const percent = checkPercent
  const baseColor = themeConfig.color?.value || '#FFFFFF'
  const colorPallete = getColorPallete(baseColor, 0.8)
  const appColor =
    themeConfig.theme === 'light'
      ? themeConfig.color?.textInvert
        ? colorPallete.textColor
        : colorPallete.lightTextColor
      : themeConfig.color?.textInvert
      ? colorPallete.lightTextColor
      : colorPallete.textColor
  const appBackgroundColor =
    themeConfig.theme === 'light' ? colorPallete.lightColor : colorPallete.backgroundColor
  const appBackgroundColorHover =
    themeConfig.theme === 'light' ? colorPallete.lightColorHover : colorPallete.backgroundColorHover
  const textColor =
    themeConfig.theme === 'light' ? colorPallete.lightTextColor : colorPallete.textColor
  const colorDecimal = getColorDecimal(textColor)
  const SlugReplacer = (data: any, config: any) => {
    const id = config?.id?.split(',')
    let slug = config?.data
    for (const l of id) {
      slug = slug.replaceAll(`:${l}`, `${data[l]}`)
    }
    return slug
  }

  const reactLoadingConfig = {
    loadingProps: {
      style: {
        color: textColor,
      },
    },
  }

  const loadData = async () => {
    setLoading(true)
    let result: {status?: boolean; data?: any} = {status: false, data: {}}
    result = await getData(api?.query || '', api?.url || '')
    if (result?.status) {
      onComplete && onComplete(result?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (api) {
      loadData()
    } else {
      setLoading(false)
    }
  }, [])

  const LinkUi: FC<WithChildren & {slug?: string}> = ({children, slug}) => {
    return slug ? (
      <div className='d-flex'>
        <Link to={RouterQueryParams(slug, query)}>{children}</Link>
      </div>
    ) : (
      <>{children}</>
    )
  }

  return (
    <div {...props} style={{backgroundColor: '#fff'}}>
      <div
        className={`d-flex flex-column justify-content-evenly pb-0 rounded-bottom-0`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
        }}
      >
        <ReactLoading loading={loading} {...reactLoadingConfig}>
          <div className='fs-2hx fw-bold d-flex flex-column'>
            {!isPercent && (
              <>
                {isTotal && (
                  <LinkUi slug={link}>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={total}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </LinkUi>
                )}
              </>
            )}
            <LinkUi slug={link}>
              <div className='fs-5 mb-4 pt-5 pb-3' style={{color: appColor}}>
                {title}
              </div>
            </LinkUi>
            {isPercent && (
              <>
                <div style={{color: appColor}}>
                  <div className='d-flex align-items-end gap-2'>
                    <div className='fs-2'>
                      <LinkUi slug={link}>
                        <CurrencyFormat
                          thousandSeparator={'.'}
                          decimalSeparator={','}
                          prefix={type === 'currency' ? 'Rp. ' : ''}
                          displayType='text'
                          value={total}
                          style={{color: appColor}}
                        />
                      </LinkUi>
                    </div>
                    <LinkUi slug={link}>
                      <div className='fs-7 opacity-75' style={{color: appColor}}>
                        {title}
                      </div>
                    </LinkUi>
                  </div>
                  <div className='d-flex gap-2 align-items-center' style={{color: appColor}}>
                    <div className='w-100'>
                      <div
                        className='h-8px w-100 rounded-pill'
                        style={{
                          backgroundColor: `rgba(${colorDecimal.red},${colorDecimal.green},${colorDecimal.blue}, 0.5)`,
                        }}
                      >
                        <div
                          className={`rounded h-8px`}
                          role='progressbar'
                          style={{width: `${percent}%`, backgroundColor: appColor}}
                        />
                      </div>
                    </div>
                    <div className='fs-6 fw-normal'>{percent}%</div>
                  </div>
                  <div className='fs-6 d-flex gap-2' style={{color: appColor}}>
                    <div>Dari</div>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={maxTotal || 0}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </ReactLoading>
      </div>
      <div
        className={`d-flex flex-column rounded-top-0`}
        style={{
          backgroundColor: appBackgroundColor,
          padding: '1rem',
          paddingBottom: '1rem',
          maxHeight: 'max-content',
        }}
      >
        <div className={`${data.length > 1 ? 'row g-2' : 'd-flex justify-content-center '}`}>
          {data?.map((l, i) => {
            const iconColor = l.iconColor
              ? getColorPallete(l.iconColor, 0.8).backgroundColor
              : '#000'
            return (
              <div
                className={`${
                  l.className
                    ? l.className
                    : `${
                        (data?.length || 0) % 3 === 0
                          ? 'col-4'
                          : (data?.length || 0) % 2 === 0
                          ? 'col-6'
                          : 'col-12'
                      }`
                } fs-3 fw-bold d-flex flex-column align-items-center`}
                style={{color: appColor}}
                key={i}
              >
                {l.icon && (
                  <LinkUi slug={l.link}>
                    <ReactIcon
                      icon={l.icon}
                      props={{className: 'mb-2', style: {color: iconColor, fontSize: '20px'}}}
                    />
                  </LinkUi>
                )}
                <ReactLoading hideTitle loading={loading} {...reactLoadingConfig}>
                  <LinkUi slug={l.link}>
                    <CountUpUi
                      props={{style: {color: appColor}}}
                      value={l.total}
                      type={type === 'currency' ? 'currency' : 'default'}
                    />
                  </LinkUi>
                </ReactLoading>
                <LinkUi slug={l.link}>
                  <div className='fs-6' style={{color: appColor}}>
                    {l.title}
                  </div>
                </LinkUi>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export {ChartCircle, TotalDashboard, TotalDashboardSmall, TotalDashboardV2, dashboardDataBuilder}
