import {NullProof} from '../../../../helper/AppFunction'
import {BaseInputOnChangeProps, FormInputProps} from '../../../../helper/FormInput'
import {filterCapitalizeProvinsi} from '../../../../helper/KemenagFunction'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'
import * as Yup from 'yup'

const filterInput: FormInputProps[] = [
  {
    name: 'start_date',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'end_date',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  // ...inputLayoutBuilder({
  //   input: [
  //     {template: 'status_simple', name: 'status'},
  //     {template: 'um_uf', name: 'um_uf'},
  //   ],
  // }),
]

const onChange = (e: BaseInputOnChangeProps) => {
  const data = e._data.get
  const dataObj = e._data.getObject
  const serverData = e._apiData.server
  const calcWatchId = 'uang_harian,hotel,transport'.split(',')
  const jumlahServerData = Number(serverData?.jumlah || 0)
  let jumlah: number = Number(data?.jumlah || 0)
  e.resetForm({values: {...data, jumlah_sebelum: jumlahServerData}})
  if (
    !(Number(serverData?.jumlah || 0) > 0) ||
    (e.type === 'onChange' && calcWatchId.includes(e.clickId || ''))
  ) {
    const uang_harian = Number(dataObj?.uang_harian || 0)
    const hotel = Number(dataObj?.hotel || 0)
    const transport = Number(dataObj?.transport || 0)
    jumlah = uang_harian + hotel + transport

    const result = {...data, jumlah: jumlah, nilai: jumlah}
    e.resetForm({values: result})
  }
  if (e.clickId === 'notifikasi' && e.type === 'onClick') {
    const spd: string = ((dataObj?.spd || '').split('/')[0] || '_').replaceAll('-', '')
    const um_uf = dataObj?.um_uf || '_'
    const lokasiMap = NullProof({input: serverData, params: 'surat_tugas.tujuan', isMap: true})
    const lokasiMap2 = NullProof({
      input: e._apiData.get,
      params: 'surat_tugas_id_detail.tujuan',
      isMap: true,
    })
    const lokasi = filterCapitalizeProvinsi(
      lokasiMap[0]?.provinsi || lokasiMap2[0]?.provinsi || '_'
    )
    const notifikasi = `SPD ${spd} ${lokasi} ${um_uf}`
    const result = {...data, notifikasi: notifikasi}
    e.resetForm({values: result})
  }
  if (e.clickId === 'tanggal_transfer' && e.type === 'onClick') {
    const result = {...data, tanggal_transfer: null}
    e.resetForm({values: result})
  }
  if (jumlah === 0 && jumlahServerData > 0 && e.clickId !== 'jumlah') {
    e.resetForm({values: {...data, jumlah: jumlahServerData}})
  }
}

export {onChange, filterInput}
