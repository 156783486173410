import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'lokasi',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Lokasi',
          placeholder: 'Lokasi',
        },
      },
    },
  },
  {
    name: 'kode',
    type: 'input',
    className: 'col-12 col-md-3',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Program',
          placeholder: 'Kode Program',
        },
      },
    },
  },
  {
    name: 'nama',
    type: 'input',
    className: 'col-12 col-md-9',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Program',
          placeholder: 'Nama Program',
        },
      },
    },
  },
  {
    hide: true,
    name: 'detail_pok_id',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'ID',
          placeholder: 'ID',
        },
      },
    },
  },
  {
    name: 'jumlah_harga',
    type: 'input',
    disabled: true,
    className: 'col-12',
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Harga',
          placeholder: 'Jumlah Harga',
        },
      },
    },
  },
]

export {formInputList}
