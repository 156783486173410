import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import ReactIcon from './ReactIcon'
import {ProtectComponent, protectSomething} from './UserRolesValidation'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {deleteData, getData} from './FormAxios'
import Modal from './Modal'
import {
  NullProof,
  PaginationStaticDataProps,
  PopupConfirm,
  RouterQueryParams,
  getPaginationStaticData,
  numberlistPagination,
  queryFilterBuilder,
} from './AppFunction'
import Pagination from './Pagination'
import * as Icons from 'react-icons/ri'
import {
  DropdownUiV2 as DropdownUi,
  DropdownUiMenuPropsV2 as DropdownUiMenuProps,
  SelectUi,
} from './AppUi'
import {useDispatch, useSelector} from 'react-redux'
import {post as postTable, clear as clearTable} from '../../app/redux/tableSlice'
import {intlMessage} from './ReactIntl'
import {useIntl} from 'react-intl'
import TableInputUiConfig from './config/TableInputConfig'
import {useAuth} from '../modules/auth'

export interface TableInputUiConfigProps {
  action: 'modal' | 'dropdown'
  limitData: string[]
  theme: {
    table: string
    actionButton: {
      icon?: keyof typeof Icons
      iconClassName?: string
      class: string
      size?: string
      showLabel?: boolean
    }
    modalButton?: {
      class: string
      size?: string
    }
  }
}

type ModeResponsiveProps = 'table' | 'card' | 'auto'

interface onLoadDataProps {
  result?: any
  query?: any
}

export interface onLoadProps {
  initLoading: () => void
  query?: {
    get?: any
    post: (query: any) => void
  }
  load: ({result, query}: onLoadDataProps) => void
}

interface i18nProps {
  baseName?: string
  inputName?: string
}

export interface TableActionProps {
  id: string
  i18n?: string
  idMenu?: string
  className?: string
  bindId?: string
  title: string
  icon?: keyof typeof Icons
  route?: string
  protect: string
  disabledProtect?: boolean
  hide?: boolean
  type?: 'link' | 'header' | 'feedback'
  input?: any
}

export interface TableDataBuilderProps {
  input: {data: any; index: number; id?: string}
}

interface TableRowNumberingProps {
  n: number
  p?: number
  t?: number
}

interface TableModalProps {
  data: any
  post: any
}

export interface TableInputCheckboxDataProps {
  id?: number[]
  data?: any[]
  page?: {
    [key: string]: number[]
  }
}

export interface TableInputDataProps {
  filter?: any
  data?: any[]
  i18n?: i18nProps
  route: TableInputRouteProps
  header?: TableInputHeaderProps[]
  action?: TableActionProps[]
  options?: TableInputOptionsProps
}
export interface TableInputBaseProps extends TableInputDataProps {
  className?: string
  dummyOptions?: {
    props?: {
      [key: string]: PaginationStaticDataProps
    }
  }
  modal?: Dispatch<any> | undefined
  checkbox?: (val: TableInputCheckboxDataProps) => void
  onFeedback?: (data?: TableActionProps) => void
  onSuccess?: (val: any) => void
  onLoad?: (e: onLoadProps) => void
  toolbar?: ReactNode
  sidebar?: ReactNode
}

const toggleTableModal = (fieldsToUpdate: Partial<any>, modal: TableModalProps) => {
  const updatedData = Object.assign(modal.data, fieldsToUpdate)
  modal.post((p: any) => ({...p, ...updatedData}))
}

const listDefaultIcon: {title: string; idMenu: string; icon: keyof typeof Icons}[] = [
  {
    title: 'Detail',
    icon: 'RiInformationLine',
    idMenu: 'detail',
  },
  {
    title: 'Edit',
    icon: 'RiEditLine',
    idMenu: 'edit',
  },
  {
    title: 'View PDF',
    icon: 'RiFileList3Line',
    idMenu: 'pdf',
  },
  {
    title: 'Delete',
    icon: 'RiDeleteBinLine',
    idMenu: 'delete',
  },
]

const RoutesParams = (params: any, json: any, query: any) => {
  let listId: any = params?.id?.split(',') || []
  let route: string = params?.route
  if (!route) return route
  listId?.forEach((l: any) => {
    try {
      if (json[l]) {
        route = route?.replaceAll(`:${l}`, json[l])
        route = route?.replaceAll(`$${l}`, query[l] || `$${l}`)
      }
    } catch (error) {}
  })
  return route
}

const TableHead: FC<
  WithChildren & {
    modeResponsive?: ModeResponsiveProps
    props?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
  }
> = ({children, props, modeResponsive = 'auto'}) => {
  return (
    <thead
      {...props}
      className={`fw-bold fs-6 text-gray-800 align-middle position-sticky top-0 ${
        props?.className || ''
      }`}
      style={{
        backgroundColor: 'var(--bs-body-bg)',
      }}
    >
      {children}
    </thead>
  )
}

const TableBody: FC<
  WithChildren & {
    props?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
  }
> = ({children, props}) => {
  return (
    <tbody {...props} className={`fw-semibold text-gray-600 ${props?.className || ''}`}>
      {children}
    </tbody>
  )
}

interface TableInputHeaderProps {
  id?: string
  title: string
  props?: React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  >
}

interface TableInputRouteProps {
  url?: string
  query?: string
  singleLoad?: boolean
}

interface TableInputServerDataProps {
  data: any[]
  page?: string | number
  size?: string | number
}

// TableInputProvider
interface TableInputContextModel {
  data: TableInputServerDataProps
  setData: (val: TableInputServerDataProps) => void
  filterData: any
  setFilterData: (val: any) => void
  dataDummy?: any
  setDataDummy: (val: any) => void
  route?: TableInputRouteProps
  setRoute: (val: TableInputRouteProps) => void
  modal: any
  setModal: (val: any) => void
  options?: TableInputOptionsProps
  setOptions: (val: TableInputOptionsProps) => void
  header?: TableInputHeaderProps[]
  setHeader: (val: TableInputHeaderProps[]) => void
  action?: TableActionProps[]
  setAction: (val: TableActionProps[]) => void
  checkbox?: TableInputCheckboxDataProps
  setCheckbox: (val: TableInputCheckboxDataProps) => void
  feedback?: any
  setFeedback: (val: any) => void
  i18n?: i18nProps
  setI18n: (val: i18nProps) => void
  refresh?: boolean
  setRefresh: (val: boolean) => void
}

const TableInputContext = createContext<TableInputContextModel>({
  modal: {data: {}, post: () => {}},
  data: {data: [], page: 1, size: 10},
  filterData: {},
  setFilterData(value: any) {},
  setData(value: TableInputServerDataProps) {},
  setDataDummy(value: any) {},
  setRoute(value: TableInputRouteProps) {},
  setModal(value: any) {},
  setOptions(value: TableInputOptionsProps) {},
  setHeader(value: TableInputHeaderProps[]) {},
  setAction(value: TableActionProps[]) {},
  setCheckbox(value: TableInputCheckboxDataProps) {},
  setFeedback(value: any) {},
  setI18n(value: i18nProps) {},
  setRefresh(value: boolean) {},
})

const TableInputProvider: FC<WithChildren> = ({children}) => {
  const [data, setData] = useState<TableInputServerDataProps>({data: [], page: 1, size: 10})
  const [dataDummy, setDataDummy] = useState<any>([])
  const [filterData, setFilterData] = useState<any>({})
  const [modal, setModal] = useState<any>({})
  const [route, setRoute] = useState<TableInputRouteProps>()
  const [feedback, setFeedback] = useState<any>()
  const [options, setOptions] = useState<TableInputOptionsProps>({
    action: {type: TableInputUiConfig.action || 'dropdown'},
    hasSearch: true,
    id: 'id',
    modeResponsive: 'table',
    tableId: 'global',
  })
  const [header, setHeader] = useState<TableInputHeaderProps[]>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [action, setAction] = useState<TableActionProps[]>([])
  const [i18n, setI18n] = useState<i18nProps>({baseName: 'page.global', inputName: 'table'})

  const [refresh, setRefresh] = useState<boolean>(false)
  const value: TableInputContextModel = {
    data,
    setData,
    filterData,
    setFilterData,
    dataDummy,
    setDataDummy,
    route,
    setRoute,
    header,
    setHeader,
    modal,
    setModal,
    options,
    setOptions,
    action,
    setAction,
    checkbox,
    setCheckbox,
    feedback,
    setFeedback,
    i18n,
    setI18n,
    refresh,
    setRefresh,
  }

  return <TableInputContext.Provider value={value}>{children}</TableInputContext.Provider>
}

const useTableInput = () => {
  return useContext(TableInputContext)
}

interface TableInputOptionsProps {
  action?: {
    type: 'modal' | 'dropdown'
  }
  protect?: string
  isDummy?: boolean
  hasCheckbox?: boolean
  hasSearch?: boolean
  hasReconfirm?: boolean
  modeResponsive?: ModeResponsiveProps
  isModal?: boolean
  id?: string
  tableId?: string
  limitData?: string[]
  theme?: {
    button?: string
  }
}

interface TableInputConfigProps extends TableInputDataProps {
  modal?: Dispatch<any>
  onFeedback?: (val: TableActionProps) => void
  onSuccess?: (val: any) => void
}

export const TableInputConfig: FC<TableInputConfigProps> = ({
  data,
  i18n,
  route: _route,
  action,
  header,
  modal: _modal,
  options,
  onFeedback,
  onSuccess,
  filter,
}) => {
  const {
    modal,
    route,
    setRoute,
    setAction,
    setHeader,
    setModal,
    setOptions,
    setI18n,
    setDataDummy,
    setFilterData,
    feedback,
    data: _data,
  } = useTableInput()

  const useEffectBuilder = ({
    get,
    post,
    defaultValue,
    feedback,
  }: {
    get: any
    post: any
    defaultValue?: any
    feedback?: boolean
  }) => {
    useEffect(() => {
      if (!feedback) {
        if (get) {
          post(get)
        }
      } else {
        if (post && get) {
          post(get)
        }
      }
      if (!feedback && defaultValue) {
        return () => {
          post(defaultValue)
        }
      }
    }, [get])
  }

  useEffectBuilder({
    get: _route.url,
    post: () => {
      setRoute(_route)
    },
    defaultValue: [],
  })
  useEffectBuilder({get: data, post: setDataDummy, defaultValue: []})
  useEffectBuilder({get: filter, post: setFilterData, defaultValue: {}})
  useEffectBuilder({get: action, post: setAction, defaultValue: []})
  useEffectBuilder({get: header, post: setHeader, defaultValue: []})
  useEffectBuilder({get: modal, post: _modal, feedback: true})
  useEffectBuilder({get: options, post: setOptions, defaultValue: {}})
  useEffectBuilder({get: feedback, post: onFeedback, feedback: true})
  useEffectBuilder({get: _data, post: onSuccess, feedback: true})
  useEffectBuilder({
    get: i18n,
    post: setI18n,
    defaultValue: {baseName: 'page.global', inputName: 'table'},
  })

  return <></>
}

const ComponentProvider: FC<WithChildren & {provider: boolean}> = ({children, provider}) => {
  if (provider) {
    return <TableInputProvider>{children}</TableInputProvider>
  } else {
    return <>{children}</>
  }
}

const TableInput: FC<TableInputBaseProps & WithChildren & {withProvider?: boolean}> = ({
  checkbox,
  header,
  route,
  action,
  data,
  i18n,
  modal,
  options,
  sidebar,
  toolbar,
  className,
  dummyOptions,
  children,
  onSuccess,
  onFeedback,
  onLoad,
  withProvider = true,
  filter,
}) => {
  const props = {
    checkbox,
    header,
    route,
    sidebar,
    toolbar,
    className,
    dummyOptions,
    children,
    onSuccess,
    onFeedback,
    onLoad,
    filter,
    options,
  }
  const config: TableInputDataProps = {
    route,
    action,
    data,
    header,
    i18n,
    options,
    filter,
  }
  return (
    <ComponentProvider provider={withProvider}>
      <TableInputConfig {...config} modal={modal} />
      <TableInputBase {...props}>{children}</TableInputBase>
    </ComponentProvider>
  )
}

const TableColumn: FC<
  WithChildren & {
    label?: string
    className?: string
    classNameResponsive?: {
      desktop?: string
      mobile?: string
    }
    modeResponsive?: ModeResponsiveProps
    props?: React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
    childProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  }
> = ({
  props,
  label = '',
  className = '',
  classNameResponsive = {
    desktop: '',
    mobile: '',
  },
  modeResponsive = 'table',
  children,
  childProps,
}) => {
  return (
    <td
      {...props}
      className={`${className} ${
        modeResponsive === 'table'
          ? `${classNameResponsive.desktop}`
          : `d-flex flex-column gap-2 
          ${
            modeResponsive === 'auto'
              ? `d-lg-table-cell ${classNameResponsive.desktop}`
              : `${classNameResponsive.mobile}`
          }`
      }`}
    >
      {label && (modeResponsive === 'auto' || modeResponsive === 'card') && (
        <span className={`fw-bold ${modeResponsive === 'auto' ? 'd-lg-none' : ''}`}>{label}</span>
      )}
      <div {...childProps}>{children}</div>
    </td>
  )
}

const TableRow: FC<
  WithChildren &
    TableDataBuilderProps & {
      numbering?: TableRowNumberingProps
      props?: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLTableRowElement>,
        HTMLTableRowElement
      >
      onClick?: () => void
    }
> = ({numbering, children, input, props, onClick}) => {
  const intl = useIntl()
  const queryUrl = useParams()
  const {
    action,
    modal,
    options,
    data,
    route,
    setModal,
    setRefresh,
    setCheckbox,
    setFeedback,
    i18n,
  } = useTableInput()
  const location = useLocation()
  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data?.role_id
  const i18nConfig: string = `${i18n?.baseName || 'page.global'}.${i18n?.inputName || 'table'}`
  const tableId = options?.tableId || 'global'
  const actionType = options?.action?.type || TableInputUiConfig.action || 'modal'
  const [isMouseMove, setMouseMove] = useState<boolean>(false)
  const [isStrictClick, setStrictClick] = useState<boolean>(false)
  // const [action, setAction] = useState<TableActionProps[]>(_action || [])
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxDispatch = useDispatch()
  const id = NullProof({input: input.data, params: input?.id || 'id'})
  const toggleCheckbox = () => {
    let _reduxTableId: any = [
      ...NullProof({input: reduxTableData, params: `${tableId}.checkbox.id`, isMap: true}),
    ]
    let _reduxTableData: any = [
      ...NullProof({input: reduxTableData, params: `${tableId}.checkbox.data`, isMap: true}),
    ]
    let _reduxTablePageId: any = [
      ...NullProof({
        input: reduxTableData,
        params: `${tableId}.checkbox.page.page${reduxTableData?.page || 1}`,
        isMap: true,
      }),
    ]
    const index = _reduxTableId?.indexOf(id)
    const indexPage = _reduxTablePageId?.indexOf(id)
    if (index > -1) {
      _reduxTableId.splice(index, 1)
      _reduxTableData.splice(index, 1)
    } else {
      _reduxTableId.push(id)
      _reduxTableData.push(input.data)
    }
    if (indexPage > -1) {
      _reduxTablePageId.splice(indexPage, 1)
    } else {
      _reduxTablePageId.push(id)
    }
    const result = {
      ...reduxTableData[tableId]?.checkbox,
      id: _reduxTableId,
      data: _reduxTableData,
      page: {
        ...reduxTableData[tableId]?.checkbox?.page,
        [`page${reduxTableData[tableId]?.page || 1}`]: _reduxTablePageId,
      },
    }
    reduxDispatch(
      postTable({
        ...reduxTableData,
        [tableId]: {
          ...reduxTableData[tableId],
          checkbox: result,
        },
      })
    )
    setCheckbox(result)
  }
  const parseActionDataToDropdown = () => {
    let actionList: DropdownUiMenuProps[] = []
    for (const l of action || []) {
      const checkIcon = listDefaultIcon.filter(
        (lb) => l.idMenu === lb.idMenu || l.protect === lb.idMenu
      )
      const defaultIcon = checkIcon.length > 0 ? checkIcon[0].icon : l.icon
      if (!l.hide) {
        actionList.push({
          ...l,
          title: intlMessage(`${i18nConfig}.${l.i18n || l.title}`, l.title, intl),
          className:
            l.idMenu === 'delete' || l.protect === 'delete' ? 'text-danger' : l.className || '',
          type: l.idMenu === 'delete' || l.protect === 'delete' ? 'feedback' : l.type,
          route: RoutesParams(l, input.data || {}, queryUrl),
          info: {
            icon: l.icon || defaultIcon,
          },
        })
      }
    }
    return actionList
  }
  return (
    <>
      <tr
        {...props}
        className={(action?.length || 0) > 0 ? 'cursor-pointer' : ''}
        onMouseMove={() => {
          setMouseMove(true)
        }}
        onMouseLeave={() => {
          setMouseMove(false)
        }}
        onMouseDown={() => {
          setMouseMove(false)
        }}
        onClick={(e) => {
          if (!isMouseMove && !isStrictClick) {
            if (reduxTableData[tableId]?.checkbox?.status) {
              toggleCheckbox()
            } else {
              if (onClick) {
                onClick()
              } else {
                if ((action?.length || 0) > 0) {
                  if (actionType === 'modal') {
                    const protectModal = protectSomething({
                      value: routeData?.route,
                      target: `${
                        (action?.length || 0) > 0
                          ? `${action?.map((l) => {
                              return `${location.pathname.substring(1)}/${l.protect.toString()}`
                            })}`
                          : ''
                      }`.split(','),
                    })
                    if (protectModal.status || roleId === 1) {
                      toggleTableModal(
                        {status: true, input: input.data, index: input.index},
                        {data: modal, post: setModal}
                      )
                    } else {
                      return
                    }
                  } else if (actionType === 'dropdown') {
                    return
                  }
                }
              }
            }
          }
        }}
      >
        {options?.hasCheckbox && (
          <td
            className='w-50px'
            onMouseEnter={() => {
              setStrictClick(true)
            }}
            onMouseLeave={() => {
              setStrictClick(false)
            }}
          >
            <div className='form-check d-flex justify-content-center'>
              <input
                type='checkbox'
                checked={reduxTableData[tableId]?.checkbox?.id?.includes(id) || false}
                onChange={() => {
                  toggleCheckbox()
                }}
                className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
              />
            </div>
          </td>
        )}
        {numbering && (
          <TableColumn
            className='text-center'
            props={{
              style: {minWidth: '70px'},
            }}
          >
            {numberlistPagination({n: numbering.n, p: Number(data?.page), t: Number(data?.size)})}
          </TableColumn>
        )}
        {children}
        {(action?.length || 0) > 0 && (
          <ProtectComponent
            id={`${
              (action?.length || 0) > 0
                ? `${action?.map((l) => {
                    return l.protect.toString()
                  })}`
                : ''
            }`}
            route={options?.protect}
          >
            <td>
              <div className='fw-normal d-flex align-items-center justify-content-end w-auto'>
                {actionType === 'modal' ? (
                  <button
                    onMouseEnter={() => {
                      setStrictClick(true)
                    }}
                    onMouseLeave={() => {
                      setStrictClick(false)
                    }}
                    type='button'
                    className={`btn ${
                      [
                        TableInputUiConfig.theme.actionButton.class,
                        TableInputUiConfig.theme.actionButton.size,
                      ].join(' ') || 'btn-sm btn-light btn-active-light-primary'
                    } w-100 my-1 d-flex align-items-center justify-content-center`}
                    onClick={() => {
                      toggleTableModal(
                        {status: true, input: input.data, index: input.index},
                        {data: modal, post: setModal}
                      )
                    }}
                  >
                    {intlMessage('component.tableinput.button.action', 'Action', intl)}
                    <ReactIcon icon='RiArrowDownSLine' props={{className: 'ms-2 fs-3'}} />
                  </button>
                ) : (
                  <DropdownUi
                    onMenuLoad={() => {
                      toggleTableModal(
                        {status: false, input: input.data, index: input.index},
                        {data: modal, post: setModal}
                      )
                    }}
                    props={{
                      onMouseEnter: () => {
                        setStrictClick(true)
                      },
                      onMouseLeave: () => {
                        setStrictClick(false)
                      },
                    }}
                    buttonProps={{
                      className: [
                        TableInputUiConfig.theme.actionButton.class,
                        TableInputUiConfig.theme.actionButton.size,
                      ].join(' '),
                    }}
                    icon={TableInputUiConfig.theme.actionButton.icon}
                    iconClassName={TableInputUiConfig.theme.actionButton.iconClassName}
                    iconOnly={!TableInputUiConfig.theme.actionButton.showLabel}
                    title={intlMessage('component.tableinput.button.action', 'Action', intl)}
                    submenu={parseActionDataToDropdown()}
                    onFeedback={async (e) => {
                      const props = e.props
                      if (props.protect === 'delete') {
                        const result = await PopupConfirm({validated: options?.hasReconfirm})
                        if (result) {
                          await deleteData(
                            input.data[props.id || 'id'],
                            props.route ? props.route : RouterQueryParams(route?.url, queryUrl)
                          )
                          setRefresh(true)
                        }
                      } else {
                        setFeedback({...props, data: input.data})
                      }
                    }}
                  />
                )}
              </div>
            </td>
          </ProtectComponent>
        )}
      </tr>
    </>
  )
}

const TableInputBase: FC<TableInputBaseProps & WithChildren> = ({
  checkbox,
  header,
  route,
  sidebar,
  toolbar,
  className,
  dummyOptions,
  children,
  onSuccess,
  onFeedback,
  onLoad,
  filter,
  options,
}) => {
  const intl = useIntl()
  const {
    i18n: tableI18n,
    action: tableAction,
    modal: tableModal,
    setModal: setTableModal,
    feedback: tableFeedback,
    data: tableData,
    setFeedback: setTableFeedback,
    setData: setTableData,
    filterData: tableFilterData,
    setFilterData: setTableFilterData,
    dataDummy: tableDataDummy,
    route: tableRoute,
    setRoute: setTableRoute,
    header: tableHeader,
    refresh: tableRefresh,
    setRefresh: setTableRefresh,
    setHeader: setTableHeader,
    checkbox: tableCheckbox,
    setCheckbox: setTableCheckbox,
  } = useTableInput()
  const location = useLocation()
  const navigate = useNavigate()
  const queryUrl = useParams()
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxDispatch = useDispatch()
  const [queryParameters, setQueryParameters] = useSearchParams()

  const listLimitData: string[] = options?.limitData ||
    TableInputUiConfig.limitData || ['10', '20', '50']
  const _tableData = tableData?.data || []
  const _route = tableRoute || route
  const _isDummy = options?.isDummy || false
  const _modeResponsive = options?.modeResponsive || 'table'
  const _hasSearch = options?.hasSearch || true
  const _hasCheckbox = options?.hasCheckbox || false
  const _tableId = options?.tableId || 'global'
  const _id = options?.id || 'id'
  const _hasReconfirm = options?.hasReconfirm || false
  const _isModal = options?.isModal || false
  const tableScrollRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const scrollbarTopRef = useRef<HTMLDivElement>(null)
  const scrollbarTopContentRef = useRef<HTMLDivElement>(null)
  const [isMouseDrag, setMouseDrag] = useState<boolean>(false)
  const [mouseData, setMouseData] = useState<{offset: number; position: number}>({
    offset: 0,
    position: 0,
  })
  const i18nConfig: string = `${tableI18n?.baseName || 'page.global'}.${
    tableI18n?.inputName || 'table'
  }`
  const [singleData, setSingleData] = useState<any[]>([])
  const [limitData, setLimitData] = useState<string | number>(
    listLimitData.includes(queryParameters.get('size') || '10')
      ? queryParameters.get('size') || '10'
      : 10
  )
  const routeData = useSelector((state: any) => state.route.value)
  const currentMenu = location.pathname.replaceAll('/', '-').substring(1)
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const filterSortBy = () => {
    let result: any = {
      status: false,
      data: {},
    }
    const listQuery: string[] = queryParameters.get('sb')?.split('-') || []
    if (queryParameters.get('sb')) {
      const sort = listQuery[0]
      const name = listQuery.slice(1).join('-')
      let listName: string[] = []
      tableHeader?.forEach((l: any) => {
        l.id && listName.push(l.id)
      })
      if (listName.includes(name) && (sort === 'asc' || sort === 'desc')) {
        result = {
          status: true,
          data: {
            name: name,
            sort: sort,
          },
        }
      }
    }
    return result
  }
  const [input, setInput] = useState<any>({
    filter: queryFilterBuilder(queryParameters.get('filter')),
    sortby: filterSortBy().data,
    lastcari: '',
    cari: '',
    currentPage: 1,
    totalPages: 1,
    currentTotalData: 0,
    totalData: 0,
    query: {},
  })

  const getListLimitData = () => {
    let result: {title: string; value: string | number}[] = []
    listLimitData.map((l) => {
      if (typeof l === 'string' && isNaN(Number(l))) {
        result.push({
          title: intlMessage('component.tableinput.button.limitdata', 'All', intl),
          value: l,
        })
      } else {
        result.push({
          title: l,
          value: Number(l),
        })
      }
    })
    return result
  }

  const useEffectLoader = ({
    get,
    post,
    trigger,
    defaultValue,
  }: {
    get: any
    post: any
    trigger?: 'get' | 'post'
    defaultValue?: any
  }) => {
    useEffect(() => {
      if (trigger === 'post') {
        if (get && post) {
          post(get)
        }
      } else {
        if (get) {
          post(get)
        }
      }
    }, [get])
  }

  useEffectLoader({get: header, post: setTableHeader})
  useEffectLoader({get: tableFeedback, post: onFeedback, trigger: 'post', defaultValue: {}})
  useEffectLoader({get: tableData, post: onSuccess, trigger: 'post'})
  useEffectLoader({get: tableCheckbox, post: checkbox, trigger: 'post'})

  const updateSize = () => {
    if (tableRef.current) {
      if (scrollbarTopContentRef.current) {
        scrollbarTopContentRef.current.style.width = `${tableRef.current?.clientWidth}px`
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])
  const handleMouseDrag = ({
    e,
    type,
  }: {
    e: React.MouseEvent<HTMLDivElement>
    type: 'drag' | 'up' | 'down'
  }) => {
    updateSize()
    if (type === 'down') {
      setMouseDrag(true)
      setMouseData({
        offset: e.pageX - (tableScrollRef.current?.offsetLeft ?? 0),
        position: tableScrollRef.current?.scrollLeft ?? 0,
      })
    } else if (type === 'up') {
      setMouseDrag(false)
    } else {
      if (isMouseDrag && tableScrollRef.current) {
        const {offset, position} = mouseData
        const x = e.pageX - (tableScrollRef.current.offsetLeft ?? 0)
        const walk = x - offset
        tableScrollRef.current.scrollLeft = position - walk
      }
    }
  }
  const updateInput = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(input, fieldsToUpdate)
    setInput((p: any) => ({...p, ...updatedData}))
  }
  const clearTableCheckbox = () => {
    const tableData = {...reduxTableData}
    delete tableData[_tableId]?.checkbox
    reduxDispatch(postTable(tableData))
  }
  useEffect(() => {
    clearTableCheckbox()
    setTableCheckbox({})
  }, [input?.lastcari])
  const queryBuilder = () => {
    let query: any = {
      page: input?.currentPage || 1,
      search: input?.cari || '',
      size: limitData,
    }
    _route?.query?.split('&').map((l) => {
      const result = l.split('=')
      if (result[0] !== 'page') {
        query[result[0]] = result[1]?.toLowerCase()
      }
    })
    return query
  }
  const loadData = async (init = false) => {
    delete input.filter.search
    let query: any = queryBuilder()
    setError(false)
    setLoading(true)
    updateInput({lastcari: query?.search, query: query})
    let arrQuery: string[] = []
    let arrPath: string[] = []
    let arrFilter: string[] = []
    Object.keys(query).map((l) => {
      const result = `${l}=${query[l]}`.toLowerCase()
      query[l] && arrQuery.push(result)
      if (l !== 'size') {
        query[l] && arrPath.push(result)
      }
    })
    if (init) {
      if (queryParameters.get('filter')) {
        let result: string[] = []
        const listFilterQuery: string[] = queryParameters.get('filter')?.split(',') || []
        listFilterQuery.forEach((l) => {
          let json = NullProof({input: l, isLabel: false})
          if (json) {
            json = json.replace('-', '=')
          }
          result.push(json)
        })
        arrQuery.push(...result)
        arrFilter.push(...result)
      } else {
        Object.keys(input?.filter || {}).map((l) => {
          const result = `${l}=${input?.filter[l]}`
          input?.filter[l] && arrQuery.push(result.toLowerCase())
          input?.filter[l] && arrFilter.push(result)
        })
      }
    } else {
      Object.keys(input?.filter || {}).map((l) => {
        const result = `${l}=${input?.filter[l]}`
        input?.filter[l] && arrQuery.push(result.toLowerCase())
        input?.filter[l] && arrFilter.push(result)
      })
    }
    if (queryParameters.get('sb') || input?.sortby?.name) {
      let result: any = {}
      if (input?.sortby?.name) {
        result = {status: true, data: input.sortby}
      } else {
        result = filterSortBy()
        updateInput({sortby: result?.data})
      }
      if (result?.status) {
        arrQuery.push('sb=' + `${result?.data?.sort}-${result?.data?.name}`)
        arrPath.push('sb=' + `${result?.data?.sort}-${result?.data?.name}`)
      }
    }
    if (arrFilter.length > 0) {
      arrPath.push(`filter=${(arrFilter || [])?.join(',').replaceAll('=', '-')}`)
    }
    if (routeData) {
      const filterMenuRolesQuery: string[] = routeData.menu?.filter((l: string) =>
        l.includes(`${currentMenu}-$query`)
      )
      for (const l of filterMenuRolesQuery) {
        arrQuery.push(l.replaceAll(`${currentMenu}-$query=`, ''))
      }
    }
    if (listLimitData.includes(query?.size) && query?.size !== 10) {
      arrPath.push(`size=${limitData}`)
    }
    if (!_isModal) {
      window.history.replaceState(null, '', `${location.pathname}?${arrPath.join('&')}`)
      let updateParams: any = {}
      arrPath.forEach((l) => {
        const result = l.split('=')
        updateParams[result[0]] = result[1]
      })
      setQueryParameters(updateParams)
    }
    try {
      let result: any
      if (tableDataDummy.length > 0) {
        result = getPaginationStaticData({
          data: tableDataDummy,
          size: Number(limitData),
          page: input?.currentPage,
          search: {
            value: input?.cari,
            options: dummyOptions?.props,
          },
        })
      } else {
        if ((_route?.singleLoad && !(singleData.length > 0)) || !_route?.singleLoad) {
          result = await getData(arrQuery.join('&'), RouterQueryParams(_route?.url, queryUrl))
          _route.singleLoad && setSingleData(result?.data || [])
        }
        if (_route.singleLoad) {
          result = getPaginationStaticData({
            data: singleData.length > 0 ? singleData : result?.data || [],
            size: Number(limitData),
            page: input?.currentPage,
            search: {
              value: input?.cari,
              options: dummyOptions?.props,
            },
          })
        }
      }
      if (result.status) {
        setTableData({data: result?.data, page: input?.currentPage, size: limitData})
        updateInput({
          currentTotalData: result?.data?.length || 0,
          totalData: result?.totalItems || 0,
          totalPages: result?.totalPages || 1,
        })
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }
  const onReload = (init = false) => {
    const onPostQuery = (query: any) => {
      let arrQuery: string[] = []
      let baseQuery = {...query, ...query?.filter}
      delete baseQuery?.filter
      for (const l of Object.keys(baseQuery)) {
        if (baseQuery[l]) {
          arrQuery.push(`${l}=${baseQuery[l]}`)
        }
      }
      return arrQuery.join('&')
    }
    const onLoadData = ({result, query = {}}: onLoadDataProps) => {
      if (result?.status) {
        let _query = {...query}
        delete _query?.filter
        setTableData({data: result?.data, page: input?.currentPage})
        updateInput({
          lastcari: _query?.search,
          query: _query,
          currentTotalData: result?.data?.length || 0,
          totalData: result?.totalItems || 0,
          totalPages: result?.totalPages || 1,
        })
        setError(false)
      } else {
        setError(true)
      }
      setLoading(false)
    }
    toggleTableModal({status: false}, {data: tableModal, post: setTableModal})
    if (onLoad && !tableDataDummy) {
      onLoad({
        initLoading: () => {
          setLoading(true)
          setError(false)
        },
        query: {
          get: {...queryBuilder(), filter: queryFilterBuilder(queryParameters.get('filter'))},
          post: onPostQuery,
        },
        load: onLoadData,
      })
    } else {
      loadData(init)
    }
    setTableRefresh(false)
  }
  const toggleAllCheckbox = () => {
    let _reduxTableId: any = [
      ...NullProof({
        input: reduxTableData,
        params: `${_tableId}.checkbox.id`,
        isMap: true,
      }),
    ]
    let _reduxTableData: any = [
      ...NullProof({
        input: reduxTableData,
        params: `${_tableId}.checkbox.data`,
        isMap: true,
      }),
    ]
    let _reduxTablePageId: any = [
      ...NullProof({
        input: reduxTableData,
        params: `${_tableId}.checkbox.page.page${reduxTableData?.page || 1}`,
        isMap: true,
      }),
    ]
    let mode = 'select-all'
    if (_reduxTablePageId?.length === _tableData?.length) {
      mode = 'deselect-all'
    } else {
      mode = 'select-all'
    }
    for (const l of _tableData) {
      let id = NullProof({input: l, params: _id})
      const index = _reduxTableId?.indexOf(id)
      const indexPage = _reduxTablePageId?.indexOf(id)
      if (mode === 'select-all') {
        if (!(index > -1)) {
          _reduxTableId.push(NullProof({input: l, params: _id}))
          _reduxTableData.push(l)
        }
        if (!(indexPage > -1)) {
          _reduxTablePageId.push(id)
        }
      } else {
        if (index > -1) {
          _reduxTableId.splice(index, 1)
          _reduxTableData.splice(index, 1)
        }
        if (indexPage > -1) {
          _reduxTablePageId.splice(indexPage, 1)
        }
      }
    }
    const result = {
      ...reduxTableData[_tableId]?.checkbox,
      id: _reduxTableId,
      data: _reduxTableData,
      page: {
        ...reduxTableData[_tableId]?.checkbox?.page,
        [`page${reduxTableData[_tableId]?.page || 1}`]: _reduxTablePageId,
      },
    }
    reduxDispatch(
      postTable({
        ...reduxTableData,
        [_tableId]: {
          ...reduxTableData[_tableId],
          checkbox: result,
        },
      })
    )
    setTableCheckbox(result)
  }
  const initData = (init = true) => {
    if ((routeData?.menu?.length || 0) > 0) {
      if (!_isModal) {
        const search = queryParameters.get('search')
        const page: number = parseInt(queryParameters.get('page') || '1')
        if (search) {
          updateInput({lastcari: search, cari: search})
        }
        if (page > 0) {
          updateInput({currentPage: page})
        }
      }
      if (_route?.url) {
        if (reduxTableData?.refresh) {
          reduxDispatch(postTable({...reduxTableData, refresh: false}))
        }
        if (_route?.url) onReload(init)
      } else {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (reduxTableData?.refresh) {
      setTableRefresh(reduxTableData?.refresh)
    }
  }, [reduxTableData?.refresh])
  useEffect(() => {
    if (!loading) {
      setTableRefresh(true)
    }
  }, [tableRoute])
  useEffect(() => {
    initData()
  }, [limitData, routeData])
  useEffect(() => {
    if (tableRefresh) {
      initData()
    }
  }, [tableRefresh])
  useEffect(() => {
    reduxDispatch(clearTable())
  }, [])
  useEffect(() => {
    if (Object.keys(tableFilterData)?.length > 0) {
      updateInput({filter: tableFilterData})
      initData(false)
    }
  }, [tableFilterData])
  return (
    <div className='position-relative'>
      <Modal
        id={'modal'}
        title={intlMessage('component.tableinput.modal.action.title', 'Select Action', intl)}
        isShow={tableModal?.status}
        onClose={() => {
          toggleTableModal({status: false}, {data: tableModal, post: setTableModal})
        }}
        className='mw-500px'
      >
        <div className='row gx-2 scroll-y mh-300px'>
          {tableAction
            ?.filter((fl) => !fl.hide)
            .map((lb, ib) => {
              const checkIcon = listDefaultIcon.filter(
                (l) => l.idMenu === lb.idMenu || l.idMenu === lb.protect
              )
              const defaultIcon = checkIcon.length > 0 ? checkIcon[0].icon : null
              return (
                <Fragment key={ib}>
                  <ProtectComponent
                    route={options?.protect}
                    id={lb.protect}
                    disabled={lb.disabledProtect}
                  >
                    {lb.type === 'feedback' || lb.protect === 'delete' ? (
                      <div className='col-12 col-md-6'>
                        <button
                          className={`btn fs-6 d-flex justify-content-center align-items-center w-100 my-1 ${
                            lb.protect !== 'delete'
                              ? `${
                                  [
                                    TableInputUiConfig.theme.modalButton?.class,
                                    TableInputUiConfig.theme.modalButton?.size,
                                  ].join(' ') ||
                                  lb.className ||
                                  'btn-light btn-active-light-primary'
                                }`
                              : `${TableInputUiConfig.theme.modalButton?.size || ''} btn-danger`
                          }`}
                          onClick={async () => {
                            if (lb.protect === 'delete') {
                              const result = await PopupConfirm({validated: _hasReconfirm})
                              if (result) {
                                await deleteData(
                                  tableModal?.input[lb.id],
                                  lb.route ? lb.route : RouterQueryParams(_route?.url, queryUrl)
                                )
                                onReload()
                              }
                            }
                            setTableFeedback({
                              ...lb,
                              data: tableModal?.input,
                            })
                            toggleTableModal(
                              {status: false},
                              {data: tableModal, post: setTableModal}
                            )
                          }}
                        >
                          <ReactIcon
                            icon={
                              lb.protect !== 'delete'
                                ? lb.icon || defaultIcon || 'RiArrowRightSLine'
                                : 'RiDeleteBinLine'
                            }
                            props={{className: 'me-2 fs-3'}}
                          />
                          {intlMessage(
                            `${i18nConfig}.action.${lb.i18n || lb.title}`,
                            lb.title,
                            intl
                          )}
                        </button>
                      </div>
                    ) : (
                      <>
                        <div
                          className='col-12 col-md-6'
                          onClick={() => {
                            setTableFeedback({
                              id: lb.id,
                              input: lb.input,
                              protect: lb.protect,
                              data: tableModal?.input,
                            })
                            navigate(`${RoutesParams(lb, tableModal?.input || {}, queryUrl)}`)
                          }}
                        >
                          <button
                            className={`btn fs-6 d-flex justify-content-center align-items-center w-100 my-1 ${
                              [
                                TableInputUiConfig.theme.modalButton?.class,
                                TableInputUiConfig.theme.modalButton?.size,
                              ].join(' ') ||
                              lb.className ||
                              'btn-light btn-active-light-primary'
                            }`}
                          >
                            <ReactIcon
                              icon={lb.icon || defaultIcon || 'RiArrowRightSLine'}
                              props={{className: 'me-2 fs-3'}}
                            />
                            {(lb.bindId || '').length > 0 &&
                              (NullProof({
                                isBool: true,
                                input: tableModal?.input,
                                params: lb.bindId || '',
                              })
                                ? intlMessage(`global.create`, 'Create', intl)
                                : intlMessage(`global.edit`, 'Edit', intl))}{' '}
                            {intlMessage(
                              `${i18nConfig}.action.${lb.i18n || lb.title}`,
                              lb.title,
                              intl
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </ProtectComponent>
                </Fragment>
              )
            })}
        </div>
      </Modal>
      {_hasSearch && (
        <div className='row mx-0 mb-4 d-flex gap-2 align-items-center justify-content-center'>
          <div className='col-16 col-md px-0 order-3 order-md-1'>
            <div className='input-group input-group-solid'>
              <input
                type='text'
                className='form-control'
                placeholder={`${intlMessage('global.search', 'Search', intl)}...`}
                value={input?.cari}
                onChange={(e) => {
                  updateInput({cari: e.target.value})
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (input?.cari === input?.lastcari) {
                    } else {
                      updateInput({currentPage: 1})
                      onReload()
                    }
                  }
                }}
              />
              <div
                className={`btn ${
                  (input?.cari === input?.lastcari && input?.lastcari?.length > 0) ||
                  (!input?.cari && input?.lastcari?.length > 0)
                    ? 'btn-danger'
                    : 'btn-primary'
                } d-flex align-items-center gap-1`}
                onClick={() => {
                  if (input?.cari === input?.lastcari && input?.lastcari?.length > 0) {
                    updateInput({cari: '', lastcari: ''})
                  }
                  onReload()
                }}
              >
                {(input?.cari === input?.lastcari && input?.lastcari?.length > 0) ||
                (!input?.cari && input?.lastcari?.length > 0) ? (
                  <>
                    <ReactIcon icon='RiCloseLine' props={{className: 'fs-3'}} />
                  </>
                ) : (
                  <>
                    <ReactIcon icon='RiSearchLine' props={{className: 'fs-3'}} />
                    {intlMessage('global.search', 'Search', intl)}
                  </>
                )}
              </div>
            </div>
          </div>
          {sidebar && (
            <div className='col-16 col-md-auto px-0 d-flex justify-content-end gap-2 order-2 flex-wrap'>
              {sidebar}
            </div>
          )}
        </div>
      )}
      <div>{toolbar}</div>
      {(reduxTableData[_tableId]?.checkbox?.data?.length || 0) > 0 && (
        <div className='d-flex justify-content-between align-items-center gap-2 my-2'>
          <div>
            <div>{reduxTableData[_tableId]?.checkbox?.data?.length || 0} Data terpilih</div>
          </div>
          <div className='d-flex gap-2'>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => {
                clearTableCheckbox()
              }}
            >
              <ReactIcon icon='RiCloseLine' /> Batal
            </button>
          </div>
        </div>
      )}
      {input?.lastcari && !loading && (input?.totalData || 0) > 0 && (
        <div className='mb-2 fs-7'>
          {intl.formatMessage(
            {
              defaultMessage: 'Searching {data} found total {total} data',
              id: 'global.search_info'.toUpperCase(),
            },
            {
              data: <b>"{input?.lastcari}"</b>,
              total: input?.totalData || 0,
            }
          )}
        </div>
      )}
      {!error ? (
        <>
          <>
            <div
              onScroll={(e) => {
                if (tableScrollRef.current) {
                  tableScrollRef.current.scrollLeft = e.currentTarget.scrollLeft
                }
              }}
              ref={scrollbarTopRef}
              className='overflow-auto'
            >
              <div ref={scrollbarTopContentRef} className='h-1px'></div>
            </div>
            <div
              onMouseDown={(e) => handleMouseDrag({e, type: 'down'})}
              onMouseMove={(e) => handleMouseDrag({e, type: 'drag'})}
              onMouseUp={(e) => handleMouseDrag({e, type: 'up'})}
              onMouseLeave={(e) => handleMouseDrag({e, type: 'up'})}
              onScroll={(e) => {
                if (scrollbarTopRef.current) {
                  scrollbarTopRef.current.scrollLeft = e.currentTarget.scrollLeft
                }
              }}
              style={{cursor: isMouseDrag ? 'grab' : 'unset'}}
              ref={tableScrollRef}
              className={`table-responsive user-select-none cursor-hand mh-500px ${
                _modeResponsive !== 'table' ? 'd-lg-block' : ''
              }`}
            >
              {loading && (
                <div
                  className='position-absolute w-100 h-100 top-0 start-0 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
                  style={{
                    zIndex: 5,
                  }}
                >
                  <div>
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </div>
              )}
              <table
                ref={tableRef}
                className={`table ${
                  _modeResponsive !== 'table'
                    ? _modeResponsive === 'auto'
                      ? 'table-card-mobile'
                      : 'table-card-default'
                    : ''
                } table-hover table-rounded align-middle ${
                  className || `${TableInputUiConfig.theme.table || 'table-row-bordered gy-5 gs-5'}`
                }`}
              >
                {(_tableData?.length > 0 || _isDummy) && (
                  <TableHead>
                    <tr>
                      {_hasCheckbox && (
                        <th className='w-50px'>
                          <div className='form-check d-flex justify-content-center'>
                            <input
                              type='checkbox'
                              checked={
                                NullProof({
                                  input: reduxTableData[_tableId]?.checkbox?.page,
                                  params: `page${reduxTableData[_tableId]?.page || 1}`,
                                  isMap: true,
                                })?.length === _tableData?.length || false
                              }
                              onChange={() => {}}
                              className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
                              onClick={() => toggleAllCheckbox()}
                            />
                          </div>
                        </th>
                      )}
                      {tableHeader?.map((l, i) => (
                        <Fragment key={i}>
                          <th
                            className={`min-w-150px ${l?.id ? 'cursor-pointer' : ''}`}
                            {...l.props}
                            onClick={() => {
                              if (l?.id) {
                                updateInput({
                                  sortby: {
                                    name: l.id,
                                    sort:
                                      input?.sortby?.name === l.id && input?.sortby?.sort === 'asc'
                                        ? 'desc'
                                        : 'asc',
                                  },
                                })
                                setTableRefresh(true)
                              }
                            }}
                          >
                            <div className='d-flex gap-2 align-items-center'>
                              {l.id === input?.sortby?.name && input?.sortby?.name && (
                                <ReactIcon
                                  icon={`${
                                    input?.sortby?.sort === 'asc'
                                      ? 'RiArrowUpLine'
                                      : 'RiArrowDownLine'
                                  }`}
                                />
                              )}
                              {intlMessage(`${i18nConfig}.header.${l.title}`, l.title, intl)}
                            </div>
                          </th>
                        </Fragment>
                      ))}
                      {(tableAction?.length || 0) > 0 && (
                        <>
                          <ProtectComponent
                            id={`${
                              (tableAction?.length || 0) > 0
                                ? `${tableAction?.map((l) => {
                                    return l.protect.toString()
                                  })}`
                                : ''
                            }`}
                            route={options?.protect}
                          >
                            <th className='text-end w-150px'>
                              {intlMessage('component.tableinput.header.action', 'Action', intl)}
                            </th>
                          </ProtectComponent>
                        </>
                      )}
                    </tr>
                  </TableHead>
                )}
                <TableBody>
                  {_tableData?.length > 0 || _isDummy ? (
                    children
                  ) : (
                    <>
                      {_tableData?.length < 1 && (
                        <tr>
                          <td colSpan={(tableHeader?.length || 0) + 1}>
                            <div
                              className='p-4 d-flex flex-column justify-content-center align-items-center'
                              style={{
                                minHeight: '200px',
                              }}
                            >
                              <div className='fs-3 fw-bold'>
                                {loading
                                  ? `${intlMessage(
                                      'component.tableinput.loading.title',
                                      'Loading',
                                      intl
                                    )}.`
                                  : `${intlMessage('global.no_data', 'No data', intl)}.`}
                              </div>
                              <div>
                                {input?.lastcari
                                  ? `${intlMessage(
                                      'global.search_empty',
                                      'Data you are search was not found',
                                      intl
                                    )}.`
                                  : loading
                                  ? `${intlMessage(
                                      'component.tableinput.loading.description',
                                      'Please wait',
                                      intl
                                    )}.`
                                  : `${intlMessage(
                                      'global.no_data_description',
                                      'Still no data',
                                      intl
                                    )}.`}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </TableBody>
              </table>
            </div>
          </>

          {_tableData?.length > 0 && (
            <div className='mt-2'>
              <div className='d-flex gap-2 align-items-center justify-content-between'>
                <div className='fs-7'>
                  {intl.formatMessage(
                    {
                      id: 'global.search_showing'.toUpperCase(),
                      defaultMessage: `Showing {data} data each page`,
                    },
                    {
                      data: limitData,
                    }
                  )}
                </div>
                <div className=''>
                  <SelectUi
                    title={intlMessage(
                      'component.tableinput.button.show_total_data',
                      'Total Data',
                      intl
                    )}
                    data={getListLimitData()}
                    props={{
                      className: 'form-select-sm',
                      defaultValue: limitData,
                      onChange: (e) => {
                        const result = e.target.value
                        if (listLimitData.includes(result)) {
                          setLimitData(e.target.value)
                          updateInput({currentPage: 1})
                        }
                      },
                    }}
                  />
                </div>
              </div>
              <Pagination
                currentPage={input?.currentPage}
                maxButtons={5}
                totalPages={input?.totalPages}
                onPageChange={(e) => {
                  if (input?.currentPage !== e) {
                    updateInput({currentPage: e})
                    reduxDispatch(
                      postTable({
                        ...reduxTableData,
                        page: e,
                      })
                    )
                    onReload()
                  }
                }}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className='p-4 d-flex gap-2 flex-column justify-content-center align-items-center'
            style={{
              minHeight: '200px',
            }}
          >
            <div className='text-center'>
              <div className='fs-3 fw-bold'>Gagal memuat data</div>
              <div>Mohon cek koneksi anda</div>
            </div>
            <button type='button' className='btn btn-sm btn-primary' onClick={() => onReload(true)}>
              Muat Ulang
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export {TableInput, TableRow, TableColumn, toggleTableModal, TableInputProvider, useTableInput}
