import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'

const importFormInputList: FormInputProps[] = [
  {
    name: 'csv',
    type: 'csv',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'File CSV',
          placeholder: 'File CSV',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  // {
  //   className: 'col-12',
  //   name: 'pengusul_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Pegawai',
  //         placeholder: 'Pegawai',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       query: 'orderby=asc',
  //       params: 'nama,nip',
  //       result: (
  //         <>
  //           <div>
  //             <span className='fw-bold'>Nama : </span>
  //             <span>$nama</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>NIP : </span>
  //             <span>$nip</span>
  //           </div>
  //         </>
  //       ),
  //       id: 'id',
  //       type: 'table',
  //       table: {
  //         data: [
  //           {id: 'nama', type: 'text'},
  //           {id: 'nip', type: 'text'},
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           header: [
  //             {title: 'No.', props: {className: 'min-w-50px'}},
  //             {title: 'Nama'},
  //             {title: 'NIP'},
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  {
    name: 'document',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Dokumen',
          placeholder: 'Dokumen',
          type: 'file',
        },
      },
    },
  },
  {
    name: 'keterangan',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Keterangan',
          placeholder: 'Keterangan',
        },
      },
    },
  },
]

export {formInputList, importFormInputList}
