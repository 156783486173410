import {eachDayOfInterval, format} from 'date-fns'
import {NullProof} from '../../../../helper/AppFunction'
const pengajuanCutiData = (data: any[]): string[] => {
  const listData: any[] = data || []
  const listTanggal: any[] = []

  listData?.map((l: any) => {
    const dates = eachDayOfInterval({
      start: new Date(l?.tanggal_mulai),
      end: new Date(l?.tanggal_selesai),
    })
    const eachDates = dates.map((d: any) => format(d, 'dd/MM'))
    listTanggal.push(...eachDates)
  })
  return listTanggal
}
const distributeNValue = (total: number): {n: number; n1: number; n2: number} => {
  const maxN: number = 12
  const maxN1: number = 6
  const maxN2: number = 6

  let n: number = Math.min(maxN, total)
  total -= n

  let n1: number = Math.min(maxN1, total)
  total -= n1

  let n2: number = Math.min(maxN2, total)

  return {n, n1, n2}
}

const getSisaCuti = (l: any, params: string = 'sisa_cuti', isRekap: boolean = true) => {
  let n, n1, n2
  if (isRekap) {
    n2 = NullProof({input: l, params: params, isMap: true})[0]?.n2 || 0
    n1 = NullProof({input: l, params: params, isMap: true})[0]?.n1 || 0
    n = NullProof({input: l, params: params, isMap: true})[0]?.n || 0
  } else {
    n2 = NullProof({input: l, params: `${params}.n2`, isLabel: false}) || 0
    n1 = NullProof({input: l, params: `${params}.n1`, isLabel: false}) || 0
    n = NullProof({input: l, params: `${params}.n`, isLabel: false}) || 0
  }
  let tanggalList: any = []
  if (isRekap) {
    l?.permohonan_cuti?.map((l: any) => {
      tanggalList = [...tanggalList, ...l?.tanggal]
    })
  } else {
    tanggalList = [...tanggalList, ...l?.tanggal]
  }
  const list = pengajuanCutiData(tanggalList)
  let totalN = n2 + n1 + n
  return {
    sesudah: {
      n2,
      n1,
      n,
      total: totalN,
    },
    sebelum: {...distributeNValue(totalN + (list?.length || 0))},
    list,
    total: list?.length || 0,
  }
}

export {getSisaCuti}
