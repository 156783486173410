import {NullProof} from '../../../../../helper/AppFunction'
import {formatDate} from '../../../../../helper/DateFns'
import {BorderStyle, CellStyle, Workbook, createWorkbook} from '../../../../../helper/ReactExcelJs'
import {PdfLayoutDataProps} from '../../../../../helper/ReactPdf'
import {ConvertCurrency} from '../../../../../helper/AppFunction'

const WorksheetRinci = (wb: Workbook, data: any) => {
  // More Info: https://www.npmjs.com/package/exceljs
  // Init ExcelJS
  const {worksheet, setupWidth, column, cell} = wb.createWorksheet({
    title: 'Detail per nama',
    options: {views: [{showGridLines: false}]},
  })

  // Init Template Theme
  const themeColor = {
    ungu: '60497A', // Format Color: HEX without #
    kuning: 'ffff00',
  }
  type themeStyleProps = {
    style: CellStyle
    border: BorderStyle
  }
  const themeStyle: themeStyleProps = {
    style: {
      backgroundColor: themeColor.ungu,
      color: themeColor.kuning,
      fontConfig: {
        wrap: true,
        alignment: {
          h: 'center',
        },
      },
    },
    border: {
      color: themeColor.kuning,
    },
  }

  // Init Column Width
  const columnWidths: any = {
    A: 10,
    B: 30,
    C: 20,
    D: 20,
    E: 40,
    F: 30,
    G: 25,
    H: 30,
    I: 30,
    J: 30,
    K: 30,
    L: 20,
    M: 25,
    N: 25,
    O: 30,
    P: 30,
    Q: 30,
    R: 30,
    S: 30,
    T: 30,
    U: 30,
    V: 30,
    W: 30,
    X: 30,
    Y: 15,
    Z: 30,
    AA: 25,
    AB: 25,
    AC: 25,
    AD: 25,
    AE: 25,
    AF: 15,
    AG: 25,
    AH: 15,
    AI: 25,
    AJ: 15,
    AK: 25,
    AL: 15,
    AM: 25,
    AN: 15,
    AO: 25,
    AP: 25,
    AQ: 25,
    AR: 25,
    AS: 25,
    AT: 25,
    AU: 25,
    AV: 25,
    AW: 25,
    AX: 25,
    AY: 30,
  }
  const styleCell = ({
    size = 10,
    isMerge = false,
    textCenter = false,
    isBold = false,
    backgroundColor = 'ffffff',
    isBorder = true,
  }: {
    size?: number
    isMerge?: boolean
    textCenter?: boolean
    isBold?: boolean
    backgroundColor?: string
    isBorder?: boolean
  }) => {
    const initialStyle: {style: CellStyle; options?: {isMerged: boolean}; border?: BorderStyle} = {
      style: {
        backgroundColor: backgroundColor,
        fontConfig: {size: size, name: 'Arial', style: !isBold ? 'normal' : 'bold'},
      },
    }
    if (textCenter)
      initialStyle.style.fontConfig = {
        alignment: {h: 'center', v: 'middle'},
        size: size,
        name: 'Arial',
        style: !isBold ? 'normal' : 'bold',
      }
    if (isMerge) initialStyle.options = {isMerged: true}
    if (isBorder)
      initialStyle.border = {
        color: '000000',
        style: 'thin',
        borderTop: true,
        borderBottom: true,
        borderLeft: true,
        borderRight: true,
      }

    return initialStyle
  }
  setupWidth(columnWidths)

  // Start Making ExcelJS
  cell({id: 'A1', ...styleCell({isBold: true, textCenter: true})}).value = 'No.'
  cell({id: 'B1', ...styleCell({isBold: true, textCenter: true})}).value = 'jenis Perjalanan Dinas'
  cell({id: 'C1', ...styleCell({isBold: true, textCenter: true})}).value = 'No ST'
  cell({id: 'D1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl ST'
  cell({id: 'E1', ...styleCell({isBold: true, textCenter: true})}).value = 'Uraian Kegiatan'
  cell({id: 'F1', ...styleCell({isBold: true, textCenter: true})}).value = 'Provinsi'
  cell({id: 'G1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jenis Satuan Kerja'
  cell({id: 'H1', ...styleCell({isBold: true, textCenter: true})}).value = 'Nama Satuan Kerja'
  cell({id: 'I1', ...styleCell({isBold: true, textCenter: true})}).value = 'NIP'
  cell({id: 'J1', ...styleCell({isBold: true, textCenter: true})}).value = 'Nama'
  cell({id: 'K1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jabatan'
  cell({id: 'L1', ...styleCell({isBold: true, textCenter: true})}).value = 'Wilayah/Sekretariat'
  cell({id: 'M1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl  Mulai ST'
  cell({id: 'N1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Akhir ST'
  cell({id: 'O1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 1'
  cell({id: 'P1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 2'
  cell({id: 'Q1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 3'
  cell({id: 'R1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 4'
  cell({id: 'S1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 5'
  cell({id: 'T1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 6'
  cell({id: 'U1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 7'
  cell({id: 'V1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 8'
  cell({id: 'W1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 9'
  cell({id: 'X1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tgl Realisasi 10'
  cell({id: 'Y1', ...styleCell({isBold: true, textCenter: true})}).value = 'HP (total HP)'
  cell({id: 'Z1', ...styleCell({isBold: true, textCenter: true})}).value = 'MAK'
  cell({id: 'AA1', ...styleCell({isBold: true, textCenter: true})}).value =
    'Uang Harian Dibayar Semula'
  cell({id: 'AB1', ...styleCell({isBold: true, textCenter: true})}).value = 'Hotel Dibayar Semula'
  cell({id: 'AC1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tiket Dibayar Semula'
  cell({id: 'AD1', ...styleCell({isBold: true, textCenter: true})}).value =
    'Jumlah Dibayar Semula (Total atau sama dengan AA+AB+AC)'
  cell({id: 'AE1', ...styleCell({isBold: true, textCenter: true})}).value = 'Uang Harian Rill'
  cell({id: 'AF1', ...styleCell({isBold: true, textCenter: true})}).value = 'Hari Hotel Rill 1'
  cell({id: 'AG1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jumlah Hotel Rill 1'
  cell({id: 'AH1', ...styleCell({isBold: true, textCenter: true})}).value = 'Hari Hotel Rill 2'
  cell({id: 'AI1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jumlah Hotel Rill 2'
  cell({id: 'AJ1', ...styleCell({isBold: true, textCenter: true})}).value = 'Hari Hotel Rill 3'
  cell({id: 'AK1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jumlah Hotel Rill 3'
  cell({id: 'AL1', ...styleCell({isBold: true, textCenter: true})}).value = 'Hari Hotel Rill 4'
  cell({id: 'AM1', ...styleCell({isBold: true, textCenter: true})}).value = 'Jumlah Hotel Rill 4'
  cell({id: 'AN1', ...styleCell({isBold: true, textCenter: true})}).value =
    'Total Hari Hotel Rill (AF+AH+AJ+AL)'
  cell({id: 'AO1', ...styleCell({isBold: true, textCenter: true})}).value =
    'Total Biaya Hotel Rill (AG+AI+AK+AM)'
  cell({id: 'AP1', ...styleCell({isBold: true, textCenter: true})}).value =
    'Uang Representatif Rill'
  cell({id: 'AQ1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tiket PP Rill'
  cell({id: 'AR1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tiket Berangkat Rill'
  cell({id: 'AS1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tiket Pulang Rill'
  cell({id: 'AT1', ...styleCell({isBold: true, textCenter: true})}).value = 'Tiket Kota 2 Rill'
  cell({id: 'AU1', ...styleCell({isBold: true, textCenter: true})}).value = 'BTS Kantor Rill'
  cell({id: 'AV1', ...styleCell({isBold: true, textCenter: true})}).value = 'BTS Lokasi Rill'
  cell({id: 'AW1', ...styleCell({isBold: true, textCenter: true})}).value = 'Biaya Lainnya Rill'
  cell({id: 'AX1', ...styleCell({isBold: true})}).value =
    'Jumlah Rill (AE+AO+AP+AQ+AR+AS+AT+AU+AV+AW)'
  cell({id: 'AY1', ...styleCell({isBold: true})}).value =
    'Pembayaran Kekurangan/pengembalian (AD-AX)'

  NullProof({input: data, params: 'data', isMap: true})?.map((l: any, i: number) => {
    let jumlahTotalDibayar = 0
    let jumlahTotalPengeluaran = 0
    let JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
    let uang_harian_dibayar_semula = 0
    let hotel_dibayar_semula = 0
    let transport_dibayar_semula = 0
    let uang_harian_rill = 0
    let uang_representatif_rill = 0
    let tiket_pp_rill = 0
    let bts_kantor_rill = 0
    let bts_lokasi_rill = 0
    let biayar_lainnya_rill = 0
    const total = (data: any, param: string) => {
      const uang_muka = data ? data.reduce((a: any, b: any) => a + Number(b[param]), 0) || 0 : 0
      return Number(uang_muka)
    }
    const cekIsUangNull = (uang: any) => {
      return uang ? Number(uang) : 0
    }
    NullProof({input: l, params: 'bukti_perjalanan', isMap: true}).map((item: any, i: number) => {
      const uangTransportKantor = item.transportasi_data
        ? item.transportasi_data.filter((l: any) => l.dibayar_oleh === 'Kantor')
        : []
      const totalDibayar =
        total(item.transfer, 'uang_harian') +
        total(item.transfer, 'hotel') +
        total(item.transfer, 'uang_representatif') +
        total(item.transfer, 'transport') +
        total(uangTransportKantor, 'total') +
        total(item.transfer, 'bst_kantor') +
        total(item.transfer, 'bst_lokasi')
      const totalPengeluraran =
        cekIsUangNull(item.uang_harian_total) +
        total(item.uang_penginapan_data, 'total') +
        cekIsUangNull(item.uang_representatif_total) +
        total(item.transportasi_data, 'total') +
        cekIsUangNull(item.bst_kantor) +
        cekIsUangNull(item.bst_lokasi) +
        total(item.kwitansi_lain_lain_list, 'total')
      jumlahTotalDibayar += totalDibayar
      jumlahTotalPengeluaran += totalPengeluraran
      JumlahTotalSelisih = jumlahTotalDibayar - jumlahTotalPengeluaran
      uang_harian_dibayar_semula = total(item.transfer, 'uang_harian')
      hotel_dibayar_semula = total(item.transfer, 'hotel')
      transport_dibayar_semula =
        total(item.transfer, 'transport') + total(uangTransportKantor, 'total')
      uang_harian_rill = cekIsUangNull(item.uang_harian_total)
      uang_representatif_rill = cekIsUangNull(item.uang_representatif_total)
      tiket_pp_rill = total(item.transportasi_data, 'total')
      bts_kantor_rill = cekIsUangNull(item.bst_kantor)
      bts_lokasi_rill = cekIsUangNull(item.bst_lokasi)
      biayar_lainnya_rill = total(item.kwitansi_lain_lain_list, 'total')
    })
    const tanggal_realisasi_1 = l.bukti_perjalanan.tanggal_realisasi_data?.[0]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[0].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[0].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_2 = l.bukti_perjalanan.tanggal_realisasi_data?.[1]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[1].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[1].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_3 = l.bukti_perjalanan.tanggal_realisasi_data?.[2]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[2].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[2].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_4 = l.bukti_perjalanan.tanggal_realisasi_data?.[3]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[3].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[3].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_5 = l.bukti_perjalanan.tanggal_realisasi_data?.[4]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[4].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[4].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_6 = l.bukti_perjalanan.tanggal_realisasi_data?.[5]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[5].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[5].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_7 = l.bukti_perjalanan.tanggal_realisasi_data?.[6]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[6].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[6].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_8 = l.bukti_perjalanan.tanggal_realisasi_data?.[7]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[7].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[7].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_9 = l.bukti_perjalanan.tanggal_realisasi_data?.[8]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[8].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[8].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const tanggal_realisasi_10 = l.bukti_perjalanan.tanggal_realisasi_data?.[9]
      ? `${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[9].tanggal_mulai,
          dateFormat: 'dd MMMM yyyy',
        })} sd ${formatDate({
          date: NullProof({
            input: l,
            params: 'bukti_perjalanan.tanggal_realisasi_data',
            isMap: true,
          })[9].tanggal_selesai,
          dateFormat: 'dd MMMM yyyy',
        })}`
      : ''
    const total_HP = NullProof({
      input: l,
      params: 'bukti_perjalanan.tanggal_realisasi_data',
      isMap: true,
    })
      ?.map((item: any) => item.tanggal_total)
      .reduce((a: any, b: any) => a + b, 0)
    const hari_hotel_1 = l.bukti_perjalanan.uang_penginapan_data?.[0]
      ? l.bukti_perjalanan.uang_penginapan_data?.[0].hari
      : 0
    const hari_hotel_2 = l.bukti_perjalanan.uang_penginapan_data?.[1]
      ? l.bukti_perjalanan.uang_penginapan_data?.[1].hari
      : 0
    const hari_hotel_3 = l.bukti_perjalanan.uang_penginapan_data?.[2]
      ? l.bukti_perjalanan.uang_penginapan_data?.[2].hari
      : 0
    const hari_hotel_4 = l.bukti_perjalanan.uang_penginapan_data?.[3]
      ? l.bukti_perjalanan.uang_penginapan_data?.[3].hari
      : 0
    const jumlah_hotel_1 = l.bukti_perjalanan.uang_penginapan_data?.[0]
      ? l.bukti_perjalanan.uang_penginapan_data?.[0].total
      : 0
    const jumlah_hotel_2 = l.bukti_perjalanan.uang_penginapan_data?.[1]
      ? l.bukti_perjalanan.uang_penginapan_data?.[1].total
      : 0
    const jumlah_hotel_3 = l.bukti_perjalanan.uang_penginapan_data?.[2]
      ? l.bukti_perjalanan.uang_penginapan_data?.[2].total
      : 0
    const jumlah_hotel_4 = l.bukti_perjalanan.uang_penginapan_data?.[3]
      ? l.bukti_perjalanan.uang_penginapan_data?.[3].total
      : 0

    cell({id: `A${1 + (i + 1)}`, ...styleCell({textCenter: true})}).value = i + 1
    cell({id: `B${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({input: l, params: 'jenis'})
    cell({id: `C${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'nomor_surat',
    })
    cell({id: `D${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'createdAt',
      type: 'date',
    })
    cell({id: `E${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'deskripsi_kegiatan',
    })
    cell({id: `F${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'tujuan',
      isMap: true,
    })
      ?.map(
        (item: any, index: number) =>
          `${
            index === 0
              ? ' '
              : index === l.tujuan.length - 1
              ? `${index !== 1 ? ',' : ''} dan `
              : ', '
          }${NullProof({input: item, params: 'provinsi'})}`
      )
      .join('')
    cell({id: `G${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'tujuan',
      isMap: true,
    })
      ?.map(
        (item: any, index: number) =>
          `${
            index === 0
              ? ' '
              : index === l.tujuan.length - 1
              ? `${index !== 1 ? ',' : ''} dan `
              : ', '
          }${NullProof({input: item, params: 'jenis_tujuan'})}`
      )
      .join('')
    cell({id: `H${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'tujuan',
      isMap: true,
    })
      ?.map(
        (item: any, index: number) =>
          `${
            index === 0
              ? ' '
              : index === l.tujuan.length - 1
              ? `${index !== 1 ? ',' : ''} dan `
              : ', '
          }${NullProof({input: item, params: 'satker.nama_satker'})}${
            item.satker_id_2 ? `, ${NullProof({input: item, params: 'satker_2.nama_satker'})}` : ''
          }${
            item.satker_id_3 ? `, ${NullProof({input: item, params: 'satker_3.nama_satker'})}` : ''
          }`
      )
      .join('')
    cell({id: `I${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'pengguna_anggaran.nip',
    })
    cell({id: `J${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'pengguna_anggaran.nama',
    })
    cell({id: `K${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'pengguna_anggaran.jabatan',
    })
    cell({id: `L${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'pengguna_anggaran.satker_3',
    })
    cell({id: `M${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'tanggal_mulai',
      type: 'date',
    })
    cell({id: `N${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({
      input: l,
      params: 'tanggal_selesai',
      type: 'date',
    })
    cell({id: `O${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_1
    cell({id: `P${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_2
    cell({id: `Q${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_3
    cell({id: `R${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_4
    cell({id: `S${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_5
    cell({id: `T${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_6
    cell({id: `U${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_7
    cell({id: `V${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_8
    cell({id: `W${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_9
    cell({id: `X${1 + (i + 1)}`, ...styleCell({})}).value = tanggal_realisasi_10
    cell({id: `Y${1 + (i + 1)}`, ...styleCell({})}).value = total_HP
    cell({id: `Z${1 + (i + 1)}`, ...styleCell({})}).value = NullProof({input: l, params: 'mak'})
    cell({id: `AA${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(
      uang_harian_dibayar_semula
    )
    cell({id: `AB${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(hotel_dibayar_semula)
    cell({id: `AC${1 + (i + 1)}`, ...styleCell({})}).value =
      ConvertCurrency(transport_dibayar_semula)
    cell({id: `AD${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(
      uang_harian_dibayar_semula + hotel_dibayar_semula + transport_dibayar_semula
    )
    cell({id: `AE${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(uang_harian_rill)
    cell({id: `AF${1 + (i + 1)}`, ...styleCell({})}).value = hari_hotel_1
    cell({id: `AG${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(jumlah_hotel_1)
    cell({id: `AH${1 + (i + 1)}`, ...styleCell({})}).value = hari_hotel_2
    cell({id: `AI${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(jumlah_hotel_2)
    cell({id: `AJ${1 + (i + 1)}`, ...styleCell({})}).value = hari_hotel_3
    cell({id: `AK${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(jumlah_hotel_3)
    cell({id: `AL${1 + (i + 1)}`, ...styleCell({})}).value = hari_hotel_4
    cell({id: `AM${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(jumlah_hotel_4)
    cell({id: `AN${1 + (i + 1)}`, ...styleCell({})}).value =
      hari_hotel_1 + hari_hotel_2 + hari_hotel_3 + hari_hotel_4
    cell({id: `AO${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(
      jumlah_hotel_1 + jumlah_hotel_2 + jumlah_hotel_3 + jumlah_hotel_4
    )
    cell({id: `AP${1 + (i + 1)}`, ...styleCell({})}).value =
      ConvertCurrency(uang_representatif_rill)
    cell({id: `AQ${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(tiket_pp_rill)
    cell({id: `AR${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(0)
    cell({id: `AS${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(0)
    cell({id: `AT${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(0)
    cell({id: `AU${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(bts_kantor_rill)
    cell({id: `AV${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(bts_lokasi_rill)
    cell({id: `AW${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(biayar_lainnya_rill)
    cell({id: `AX${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(
      uang_harian_rill +
        jumlah_hotel_1 +
        jumlah_hotel_2 +
        jumlah_hotel_3 +
        jumlah_hotel_4 +
        uang_representatif_rill +
        tiket_pp_rill +
        bts_kantor_rill +
        bts_lokasi_rill +
        biayar_lainnya_rill
    )
    cell({id: `AY${1 + (i + 1)}`, ...styleCell({})}).value = ConvertCurrency(JumlahTotalSelisih)
  })
}

const ExcelLayout = (data: PdfLayoutDataProps) => {
  const downloadExcel = async () => {
    const wb = createWorkbook()
    WorksheetRinci(wb, data)
    wb.download(data._filename)
  }
  return downloadExcel
}

export default ExcelLayout
