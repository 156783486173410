import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInput'
import {getListYear} from '../../../helper/DateFns'
import {formInputList as inputListKategori} from '../../pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/data-tambahan/kategori-kegiatan/InputLayout'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-12',
    name: 'jenis_pkpt',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'PKPT',
        value: 'PKPT',
      },
      // {
      //   title: 'Non SPD',
      //   value: 'Non SPD',
      // },
      {
        title: 'Lainnya',
        value: 'Lainnya',
      },
    ],
    validator: Yup.string().required('Jenis wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Jenis Penugasan',
          placeholder: 'Jenis Penugasan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tema',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tema',
          placeholder: 'Tema',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'topik',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Topik',
          placeholder: 'Topik',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode_satker',
    type: 'datalist',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Wilayah Kerja',
          placeholder: 'Wilayah Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/unit-kerja',
        params: 'wilayah',
        result: '$wilayah',
        id: 'wilayah',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'jenis',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'SPD',
        value: 'SPD',
      },
      // {
      //   title: 'Non SPD',
      //   value: 'Non SPD',
      // },
      {
        title: 'SPD DKI Jakarta',
        value: 'SPD DKI Jakarta',
      },
    ],
    validator: Yup.string().required('Jenis wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Jenis',
          placeholder: 'Jenis',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kategori_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kategori is required'),
    options: {
      input: {
        props: {
          title: 'Kategori',
          placeholder: 'Kategori',
        },
      },
      datalist: {
        id: 'id',
        api: '/pengawasan_internal/kategori-kegiatan',
        params: 'kategori',
        result: `$kategori`,
        type: 'table',
        table: {
          protect: '/pengawasan/persiapan/data_tambahan/kategori_kegiatan',
          data: [
            {
              id: 'kategori',
              type: 'text',
            },
            {
              id: 'sub_kategori',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/pengawasan_internal/kategori-kegiatan',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kategori',
              },
              {
                title: 'Sub Kategori',
              },
            ],
            link: {
              tambah: {
                id: 'tambah',
                protect: 'tambah',
                route: '',
                title: 'Tambah',
                type: 'feedback',
                input: inputListKategori,
              },
              action: [
                {
                  id: 'ubah',
                  protect: 'ubah/:id',
                  title: 'Ubah',
                  route: 'ubah/:id',
                  type: 'feedback',
                  input: inputListKategori,
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'pok_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'POK',
          placeholder: 'POK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok',
        useOnLoad: true,
        query: 'order_by=asc',
        params:
          'tahun,kode_program,nama_program,alokasi_dana,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$tahun</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$kode_program - $nama_program</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$alokasi_dana</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'tahun',
              type: 'text',
            },
            {
              id: 'kode_program',
              type: 'text',
            },
            {
              id: 'nama_program',
              type: 'text',
            },
            {
              id: 'alokasi_dana',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok',
              query: 'order_by=asc',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Tahun',
              },
              {
                title: 'Kode Program',
              },
              {
                title: 'Nama Program',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_mak',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kode MAK is required'),
    options: {
      input: {
        props: {
          title: 'Kode MAK',
          placeholder: 'Kode MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: 'listkegiatan=true',
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'listkegiatan=true',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'pok_id',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'mak',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('MAK is required'),
    options: {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: 'kodekegiatan=$kode_mak',
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'kodekegiatan=$kode_mak',
              singleLoad: true,
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'pok_id,kode_mak',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'ppk_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pejabat Pembuat Komitmen',
          placeholder: 'Pejabat Pembuat Komitmen',
        },
      },
      datalist: {
        api: '/pengawasan_internal/list-pegawai-ppk',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'pegawai_id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/list-pegawai-ppk',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nilai_anggaran',
    type: 'input',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nilai Anggaran',
          placeholder: 'Nilai Anggaran',
        },
      },
    },
  },
]

export {formInputList}
