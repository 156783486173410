/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDataAnggota,
  TableInputDataTujuan,
  TableInputDetailData,
} from './PageData'
import {
  formInputList,
  formInputListSpd,
  formInputStatus2,
  formInputStatusIrwil,
  formInputStatusppk,
  formInputStatus,
} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {
  BreadcrumbQueryParams,
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  ConvertCurrency,
  numberlistPagination,
} from '../../../../helper/AppFunction'
import {CardUi} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import FormInput from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {formatDate} from '../../../../helper/DateFns'
import {useAuth} from '../../../../modules/auth'
import Modal from '../../../../helper/Modal'
import {UrutkanAnggotaSt} from '../../../../helper/KemenagFunction'
import {ButtonPembatalanST} from './TableWrapper'
import {getData} from '../../../../helper/FormAxios'
import download from 'downloadjs'

const API_URL = process.env.REACT_APP_API_URL

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const [actionMenu, setActionMenu] = useState([...(TableInputData.link?.action || [])])
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  useEffect(() => {
    let defaultValue = [...actionMenu]
    if (isDownload) {
      defaultValue[5].loading = true
    } else {
      defaultValue[5].loading = false
    }
    if (NullProof({input: modal, params: 'input.url_pdf_tte', isLabel: false})?.length > 0) {
      defaultValue[5].hide = false
    } else {
      defaultValue[5].hide = true
    }
    setActionMenu(defaultValue)
  }, [isDownload, modal])

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          onFeedback={async (e) => {
            if (e?.id === 'id,url_pdf_tte') {
              const url = `/tte/signed${NullProof({
                input: e,
                params: 'data.url_pdf_tte',
              })}`
              const nomor_st = NullProof({
                input: e,
                params: 'data.nomor_surat',
              })
              setIsDownload(true)
              try {
                const response = await getData('', url, {responseType: 'blob'})
                if ((response?.size || 0) > 0) {
                  download(response, `tte_${nomor_st}.pdf`, 'application/pdf')
                  setModal((p: any) => ({...p, status: false}))
                }
              } catch (_) {}
              setIsDownload(false)
            }
          }}
          {...TableInputData}
          link={{...TableInputData.link, action: actionMenu}}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: actionMenu,
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {minWidth: '100px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pengusul.nama'})}
                  </TableColumn>
                  <TableColumn label='Kegiatan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nama_kegiatan'})}
                  </TableColumn>
                  <TableColumn label='No Surat' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nomor_surat'})}
                  </TableColumn>
                  <TableColumn label='Tanggal' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'tanggal', type: 'date'})}
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'status'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    status_irwil: false,
    status_2: false,
    ppk: false,
  })
  const [key, setKey] = useState<number>(0)
  const {auth} = useAuth()
  const idPegawai = NullProof({input: auth?.data, params: 'pegawai_id'})
  const idUser = NullProof({input: auth?.data, params: 'id'})
  return (
    <>
      <Modal
        id='modal_status'
        isShow={modal?.status_2}
        onClose={() => {
          setModal((p: any) => ({...p, status_2: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus2}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, status_2: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_irwil'
        isShow={modal?.status_irwil}
        onClose={() => {
          setModal((p: any) => ({...p, status_irwil: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusIrwil}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-irwil`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, status_irwil: false}))
            },
          }}
          // onChange={onChange}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_ppk'
        isShow={modal?.ppk}
        onClose={() => {
          setModal((p: any) => ({...p, ppk: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusppk}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-ppk`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, ppk: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Pengusul'}}>
              {NullProof({input: data, params: 'usulan.pengusul.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Roles Pengusul'}}>
              {NullProof({input: data, params: 'usulan.pengusul.user.roles.name'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nomor Surat'}}>
              {NullProof({input: data, params: 'nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jenis'}}>
              <span className='text-uppercase'>{NullProof({input: data, params: 'jenis'})}</span>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Kegiatan'}}>
              {NullProof({input: data, params: 'nama_kegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Deskripsi Kegiatan'}}>
              {NullProof({input: data, params: 'deskripsi_kegiatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Surat'}}>
              {NullProof({input: data, params: 'tanggal', type: 'date'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Dasar'}}>
              {NullProof({input: data, params: 'dasarnya', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <div>{`${i + 1}. ${NullProof({input: l, params: 'detail'})}`}</div>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Usulan Tim'}}
            >
              {NullProof({input: data, params: 'usulan_tim', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn
                      title={{
                        value: `${i + 1}. ${NullProof({
                          input: l,
                          params: 'provinsi',
                        })} - ${NullProof({
                          input: l,
                          params: 'kota',
                        })}`.toLowerCase(),
                        props: {
                          className: 'text-capitalize',
                        },
                      }}
                    >
                      <div className='text-capitalize'>
                        <div>
                          <div>Anggota</div>
                          <div>
                            {NullProof({input: l, params: 'kepada', isMap: true})?.map(
                              (ll: any, ii: number) => (
                                <Fragment key={ii}>
                                  <div>
                                    {`${ii + 1}. ${NullProof({
                                      input: ll,
                                      params: 'pegawai.nip',
                                    })} - ${NullProof({
                                      input: ll,
                                      params: 'pegawai.nama',
                                    })}`.toLowerCase()}
                                  </div>
                                </Fragment>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Anggaran Tim'}}>
              {ConvertCurrency(NullProof({input: data, params: 'totalRAB'}))}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'MAK'}}>
              {NullProof({input: data, params: 'mak'})}
            </TableInputDetailColumn>
          </TableInputDetail>
          <TableInputDetailColumn title={{value: 'Status Reviu 1'}}>
            <div className='d-flex align-items-center gap-2'>
              <div>{NullProof({input: data, params: 'status'})}</div>
              <div>
                {(idPegawai === NullProof({input: data, params: 'penandatangan_st'}) ||
                  idUser === 1) && (
                  // <ProtectComponentRoles
                  //   id={NullProof({input: data, params: 'pengusul.user.roles.name'})}
                  // >
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal((p: any) => ({...p, status_irwil: true}))
                    }}
                  >
                    Ubah Status
                  </button>
                  //</ProtectComponentRoles>
                )}
              </div>
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'PenandaTangan Reviu 1'}}>
            {NullProof({input: data, params: 'penandatangan.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Reviu 1'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tgl_ttd'}),
              dateFormat: 'dd MMMM yyyy, HH:mm:ss',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Catatan Reviu 1'}}>
            {NullProof({input: data, params: 'catatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status Reviu 2'}}>
            <div className='d-flex align-items-center gap-2'>
              <div>{NullProof({input: data, params: 'status_2'})}</div>
              {(idPegawai === NullProof({input: data, params: 'penandatangan_st_2'}) ||
                idUser === 1) &&
                NullProof({input: data, params: 'status', isLabel: false}) && (
                  <div>
                    {/* <ProtectComponentRoles2
                    id={NullProof({input: data, params: 'pengusul.user.roles.name'})}
                  > */}
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, status_2: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                    {/* </ProtectComponentRoles2> */}
                  </div>
                )}
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'PenandaTangan Reviu 2'}}>
            {NullProof({input: data, params: 'penandatangan_2.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan Reviu 2'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tgl_ttd_2'}),
              dateFormat: 'dd MMMM yyyy, HH:mm:ss',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Catatan Reviu 2'}}>
            {NullProof({input: data, params: 'catatan_2'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Status ppk'}}>
            <div className='d-flex align-items-center gap-2'>
              <div>{NullProof({input: data, params: 'status_ppk'})}</div>
              {(idPegawai === NullProof({input: data, params: 'ppk_id'}) || idUser === 1) &&
                NullProof({input: data, params: 'status'}) === 'Disetujui' &&
                NullProof({input: data, params: 'status_2'}) === 'Disetujui' && (
                  <div>
                    {/* <ProtectComponent id='ubah_status_ppk/:id' route={InfoData.url}> */}
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, ppk: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                    {/* </ProtectComponent> */}
                  </div>
                )}
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'ppk'}}>
            {NullProof({input: data, params: 'ppk.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Catatan ppk'}}>
            {NullProof({input: data, params: 'catatan_ppk'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  PPK'}}>
            {NullProof({
              input: data,
              params: 'status_ppk_tgl',
              type: 'date',
              option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
            })}
          </TableInputDetailColumn>
          {NullProof({input: data, params: 'penandatangan_st_2'}) === idPegawai &&
            NullProof({input: data, params: 'status'}) === 'Disetujui' && (
              <TableInputDetailColumn title={{value: 'Pembatalan ST'}}>
                <ButtonPembatalanST onRefresh={() => setKey((p) => p + 1)} />
              </TableInputDetailColumn>
            )}
        </CardUi>
      </Fragment>
    </>
  )
}

const AnggotaWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.anggota.page.breadcrumb.data}>
        {PageData.anggota.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.anggota.page.title}>
        <TableInput
          isDummy
          hasSearch={false}
          modal={modalConfig}
          onSuccess={(val: any) => {
            const kepada = UrutkanAnggotaSt(val?.data.kepada)
            const result = {...val, data: {...data.data, usulan_tim: {kepada: kepada}}}
            setData(result)
          }}
          {...TableInputDataAnggota}
        >
          {NullProof({input: data, params: 'data.usulan_tim', isMap: true})?.map(
            (l: any, i: number) => (
              <Fragment key={i}>
                {NullProof({input: l, params: 'kepada', isMap: true}).map((ll: any, ii: number) => {
                  const tableDataBuilder = {
                    modal: modalConfig,
                    input: {data: ll, index: ii},
                    action: TableInputDataAnggota.link?.action || [],
                  }
                  return (
                    <Fragment key={ii}>
                      <TableRow {...tableDataBuilder}>
                        <td className='w-100px'>{ii + 1}</td>
                        <td className='min-w-150px'>
                          {NullProof({input: ll, params: 'pegawai.nip'})}
                        </td>
                        <td className='min-w-150px'>
                          {NullProof({input: ll, params: 'pegawai.nama'})}
                        </td>
                        <td className='min-w-150px'>{NullProof({input: ll, params: 'jabatan'})}</td>
                        <TableAction {...tableDataBuilder} />
                      </TableRow>
                    </Fragment>
                  )
                })}
              </Fragment>
            )
          )}
        </TableInput>
      </CardUi>
    </>
  )
}

const TujuanWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  return (
    <>
      <PageTitle breadcrumbs={PageData.tujuan.page.breadcrumb.data}>
        {PageData.tujuan.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tujuan.page.title}>
        <TableInput
          isDummy
          hasSearch={false}
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputDataTujuan}
        >
          {NullProof({input: data, params: 'data.tujuan', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: {...l, id: i}, index: i},
              action: TableInputDataTujuan.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <td className='w-100px'>{i + 1}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'kota'})}</td>
                  <td className='min-w-150px'>{NullProof({input: l, params: 'provinsi'})}</td>
                  <td className='min-w-150px'>
                    {NullProof({input: l, params: 'satker.nama_satker'})}
                  </td>
                  <td className='min-w-150px'>
                    {NullProof({input: data, params: 'data.kepada', isMap: true})?.map(
                      (ll: any, ii: number) => (
                        <Fragment key={ii}>
                          <div>
                            <div className='fw-bold'>{`${ii + 1}. ${NullProof({
                              input: ll,
                              params: 'jabatan',
                            })}`}</div>
                            <div className='text-capitalize'>
                              {`${NullProof({input: ll, params: 'pegawai.nip'})} - ${NullProof({
                                input: ll,
                                params: 'pegawai.nama',
                              })}`.toLowerCase()}
                            </div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </td>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const SpdWrapper: FC = () => {
  formInputListSelector({formInputList: formInputListSpd, name: 'kode_no'}).disabled = true
  formInputListSelector({formInputList: formInputListSpd, name: 'nomor_surat'}).disabled = true
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.spd.page.breadcrumb.data)}>
        {PageData.spd.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.spd.page.title}>
        <FormInput
          input={formInputListSpd}
          route={{
            url: InfoData.spd_api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const SpdPdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.spd_api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

export {
  MainWrapper,
  UbahWrapper,
  TambahWrapper,
  PdfWrapper,
  DetailWrapper,
  AnggotaWrapper,
  TujuanWrapper,
  SpdWrapper,
  SpdPdfWrapper,
}
