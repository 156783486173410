import {DataRoutesProps} from '../../UserRolesValidation'
import PetunjukOperasionalKegiatanOld from '../pengelolaan-keuangan/PetunjukOperasionalKegiatan'
import PetunjukOperasionalKegiatan from './petunjuk-operasional-kegiatan/PetunjukOperasionalKegiatan'
import {PerencanaanPetugasAuditRoutes} from './PerencanaanPetugasAudit'
import {PetaApipRoutes} from './PetaApip'
import {PkptRoutes} from './Pkpt'
import {RencanaAuditDilihatDariObjekRoutes} from './RencanaAuditDilihatDariObjek'
import {RencanaAuditJangkaMenengahRoutes} from './RencanaAuditJangkaMenengah'
import {RencanaStrategisRoutes} from './RencanaStrategis'
import {RisikoPengawasanInvestigasiRoutes} from './RisikoPengawasanInvestigasi'
import {RisikoPengawasanWilayahRoutes} from './RisikoPengawasanWilayah'
import {UpkatRoutes} from './Upkat'
import FormPKPT from './form-pkpt/FormPKPT'

const PerencanaanAuditApip: DataRoutesProps = {
  title: 'Perencanaan Audit APIP',
  icon: 'element-10',
  route: 'perencanaan_apip',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Peta Audit APIP',
      icon: 'element-10',
      route: 'peta',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Rencana Audit Jangka Menengah',
      icon: 'element-10',
      route: 'rencana_jangka_menengah',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'UPKAT',
      icon: 'element-10',
      route: 'upkat',
      elements: <></>,
      subroute: [],
    },
    // {
    //   isPage: true,
    //   title: 'Program Kerja Audit Tahunan',
    //   icon: 'element-10',
    //   route: 'progja_tahunan',
    //   elements: <></>,
    //   subroute: [],
    // },
  ],
}

// const MainRoutes: DataRoutesProps = {
//   title: 'Perencanaan Anggaran',
//   icon: 'element-10',
//   route: 'perencanaan_anggaran',
//   elements: <></>,
//   subroute: [{...PenyusunanRencanaProgja}, {...PerencanaanAuditApip}],
// }
const MainRoutes: DataRoutesProps = {
  title: 'Perencanaan Anggaran',
  icon: 'element-10',
  route: 'perencanaan_anggaran',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Petunjuk Operasional Kegiatan',
      icon: 'element-10',
      route: 'pok',
      elements: <></>,
      subroute: PetunjukOperasionalKegiatan,
    },
    {
      isPage: true,
      title: 'Penyusunan Rencana Strategis',
      icon: 'element-10',
      route: 'rencana_strategis',
      elements: <></>,
      subroute: RencanaStrategisRoutes,
    },
    {
      isPage: true,
      title: 'Peta Audit APIP',
      icon: 'element-10',
      route: 'peta_apip',
      elements: <></>,
      subroute: PetaApipRoutes,
    },
    {
      isPage: true,
      title: 'Risiko Pengawasan Inspektorat Wilayah',
      icon: 'element-10',
      route: 'risiko_pengawasan_wilayah',
      elements: <></>,
      subroute: RisikoPengawasanWilayahRoutes,
    },
    {
      isPage: true,
      title: 'Risiko Pengawasan Inspektorat Investigasi',
      icon: 'element-10',
      route: 'risiko_pengawasan_investigasi',
      elements: <></>,
      subroute: RisikoPengawasanInvestigasiRoutes,
    },
    {
      isPage: true,
      title: 'Rencana Audit Jangka Menengah',
      icon: 'element-10',
      route: 'rencana_audit_jangka_menengah',
      elements: <></>,
      subroute: RencanaAuditJangkaMenengahRoutes,
    },
    {
      isPage: true,
      title: 'Penyusunan Rencana Kinerja Tahunan',
      icon: 'element-10',
      route: 'penyusunan_recana_kinerja_tahunan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Perjanjian Kinerja',
      icon: 'element-10',
      route: 'penyusunan_perjanjian_kinerja',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Usulan Program Kerja Audit Tahunan',
      icon: 'element-10',
      route: 'upkat',
      elements: <></>,
      subroute: UpkatRoutes,
    },
    {
      isPage: true,
      title: 'Penyusunan Program Kerja Pengawasan Tahunan (PKPT)',
      icon: 'element-10',
      route: 'penyusunan_program_kerja_pengawasan_tahunan',
      elements: <></>,
      subroute: PkptRoutes,
    },
    {
      isPage: true,
      title: 'Form PKPT',
      icon: 'element-10',
      route: 'form-pkpt',
      elements: <></>,
      subroute: FormPKPT,
    },
    {
      isPage: true,
      title: 'Perencanaan Petugas Audit',
      icon: 'element-10',
      route: 'perencanaan_petugas_audit',
      elements: <></>,
      subroute: PerencanaanPetugasAuditRoutes,
    },
    {
      isPage: true,
      title: 'Rencana Audit Dilihat Dari Objek Audit',
      icon: 'element-10',
      route: 'rencana_audit_dilihat_dari_objek_audit',
      elements: <></>,
      subroute: RencanaAuditDilihatDariObjekRoutes,
    },
    {
      isPage: true,
      title: 'Penyusunan Kalender Kegiatan',
      icon: 'element-10',
      route: 'penyusunan_kalender_kegiatan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Pagu Indikatif',
      icon: 'element-10',
      route: 'penyusunan_pagu_indikatif',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Pagu Anggaran',
      icon: 'element-10',
      route: 'penyusunan_pagu_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Alokasi Anggaran',
      icon: 'element-10',
      route: 'penyusunan_alokasi_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Petunjuk Operasional Kegiatan',
      icon: 'element-10',
      route: 'penyusunan_petujuk_operasional_kegiatan',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Penyusunan Standar Biaya Keluaran',
      icon: 'element-10',
      route: 'penyusunan_standar_biaya_keluaran',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default MainRoutes
