import {DataRoutesProps} from '../../../UserRolesValidation'
import {FormSuratPerjalananDinasRoutes} from './FormSuratPerjalananDinas'
import {FormSuratTugasRoutes} from './FormSuratTugas'
import {FormUsulanRoutes} from './FormUsulan'
import {DasarUsulanRoutes} from './data-tambahan/DasarUsulan'
import {KategoriKegiatanRoutes} from './data-tambahan/KategoriKegiatan'
import {SumberPenugasanRoutes} from './data-tambahan/SumberPenugasan'
import {MenimbangUsulanRoutes} from './data-tambahan/MenimbangUsulan'
import {JabatanRoutes} from './data-tambahan/Jabatan'
import {PersetujuanRoutes} from './daftar-status-usulan/Persetujuan'
import {ParafRoutes} from './daftar-status-usulan/Paraf'
import {DitolakRoutes} from './daftar-status-usulan/Ditolak'
import {SelesaiRoutes} from './daftar-status-usulan/Selesai'
import {TandaTanganRoutes} from './daftar-status-usulan/TandaTangan'
import {SuratTugasTerbitRoutes} from './daftar-status-surat-tugas/Terbit'
import {SuratTugasBelumTerbitRoutes} from './daftar-status-surat-tugas/BelumTerbit'
import {RevisiRoutes} from './daftar-status-usulan/Revisi'
import {ExportSuratTugasWilayah1Routes} from './export-surat-tugas/Wilayah1'
import {ExportSuratTugasWilayah2Routes} from './export-surat-tugas/Wilayah2'
import {ExportSuratTugasWilayah3Routes} from './export-surat-tugas/Wilayah3'
import {ExportSuratTugasWilayah4Routes} from './export-surat-tugas/Wilayah4'
import {ExportSuratTugasWilayah1AnggotaRoutes} from './export-surat-tugas/Wilayah1Anggota'
import {ExportSuratTugasWilayah2AnggotaRoutes} from './export-surat-tugas/Wilayah2Anggota'
import {ExportSuratTugasWilayah3AnggotaRoutes} from './export-surat-tugas/Wilayah3Anggota'
import {ExportSuratTugasWilayah4AnggotaRoutes} from './export-surat-tugas/Wilayah4Anggota'
import {ExportSuratTugasPegawaiRoutes} from './export-surat-tugas/Pegawai'
import {ExportSuratTugasAnggaranPerwilayahRoutes} from './export-surat-tugas/AnggaranPerwilayah'
import {ExportSuratTugasWilayahInvesRoutes} from './export-surat-tugas/WilayahInves'
import {ExportSuratTugasWilayahInvesAnggotaRoutes} from './export-surat-tugas/WilayahInvesAnggota'
import {ExportSuratTugasWilayahSekretariatRoutes} from './export-surat-tugas/WilayahSekretariat'
import {ExportSuratTugasWilayahSekretariatAnggotaRoutes} from './export-surat-tugas/WilayahSekretariatAnggota'
import {KategoriPenugasanRoutes} from './data-tambahan/KategoriPenugasan'

const DataTambahan: DataRoutesProps = {
  title: 'Data Tambahan',
  icon: 'element-10',
  route: 'data_tambahan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Dasar Usulan',
      icon: 'element-10',
      route: 'dasar_usulan',
      elements: <></>,
      subroute: DasarUsulanRoutes,
    },
    {
      isPage: true,
      title: 'Menimbang Usulan',
      icon: 'element-10',
      route: 'menimbang',
      elements: <></>,
      subroute: MenimbangUsulanRoutes,
    },
    {
      isPage: true,
      title: 'Kategori Kegiatan',
      icon: 'element-10',
      route: 'kategori_kegiatan',
      elements: <></>,
      subroute: KategoriKegiatanRoutes,
    },
    {
      isPage: true,
      title: 'Kategori Penugasan',
      icon: 'element-10',
      route: 'kategori_penugasan',
      elements: <></>,
      subroute: KategoriPenugasanRoutes,
    },
    {
      isPage: true,
      title: 'Sumber Penugasan',
      icon: 'element-10',
      route: 'sumber_penugasan',
      elements: <></>,
      subroute: SumberPenugasanRoutes,
    },
    {
      isPage: true,
      title: 'Jabatan',
      icon: 'element-10',
      route: 'jabatan',
      elements: <></>,
      subroute: JabatanRoutes,
    },
  ],
}

const DaftarStatusUsulan: DataRoutesProps = {
  title: 'Daftar Status Usulan',
  icon: 'element-10',
  route: 'daftar_status_usulan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Persetujuan',
      icon: 'element-10',
      route: 'persetujuan',
      elements: <></>,
      subroute: PersetujuanRoutes,
    },
    {
      isPage: true,
      title: 'Paraf',
      icon: 'element-10',
      route: 'paraf',
      elements: <></>,
      subroute: ParafRoutes,
    },
    {
      isPage: true,
      title: 'Tanda Tangan',
      icon: 'element-10',
      route: 'tanda_tangan',
      elements: <></>,
      subroute: TandaTanganRoutes,
    },
    {
      isPage: true,
      title: 'Ditolak',
      icon: 'element-10',
      route: 'ditolak',
      elements: <></>,
      subroute: DitolakRoutes,
    },
    {
      isPage: true,
      title: 'Selesai',
      icon: 'element-10',
      route: 'selesai',
      elements: <></>,
      subroute: SelesaiRoutes,
    },
    {
      isPage: true,
      title: 'Revisi',
      icon: 'element-10',
      route: 'revisi',
      elements: <></>,
      subroute: RevisiRoutes,
    },
  ],
}

const DaftarStatusSuratTugas: DataRoutesProps = {
  title: 'Daftar Status Surat Tugas',
  icon: 'element-10',
  route: 'daftar_status_surat_tugas',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Belum Terbit SPD',
      icon: 'element-10',
      route: 'belum_terbit',
      elements: <></>,
      subroute: SuratTugasBelumTerbitRoutes,
    },
    {
      isPage: true,
      title: 'Sudah Terbit SPD',
      icon: 'element-10',
      route: 'terbit',
      elements: <></>,
      subroute: SuratTugasTerbitRoutes,
    },
  ],
}

const ExportSuratTugas: DataRoutesProps = {
  title: 'Export Surat Tugas',
  icon: 'element-10',
  route: 'export_surat_tugas',
  elements: <></>,
  subroute: [
    {
      title: 'Wilayah 1',
      icon: 'element-10',
      route: 'wilayah_1',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_1_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayah1Routes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_1_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayah1AnggotaRoutes,
        },
      ],
    },
    {
      title: 'Wilayah 2',
      icon: 'element-10',
      route: 'wilayah_2',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_2_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayah2Routes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_2_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayah2AnggotaRoutes,
        },
      ],
    },
    {
      title: 'Wilayah 3',
      icon: 'element-10',
      route: 'wilayah_3',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_3_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayah3Routes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_3_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayah3AnggotaRoutes,
        },
      ],
    },
    {
      title: 'Wilayah 4',
      icon: 'element-10',
      route: 'wilayah_4',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_4_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayah4Routes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_4_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayah4AnggotaRoutes,
        },
      ],
    },
    {
      title: 'Wilayah Investigasi',
      icon: 'element-10',
      route: 'wilayah_inves',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_inves_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayahInvesRoutes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_inves_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayahInvesAnggotaRoutes,
        },
      ],
    },
    {
      title: 'Wilayah sekretariat',
      icon: 'element-10',
      route: 'wilayah_sekretariat',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Surat Tugas',
          icon: 'element-10',
          route: 'wilayah_sekretariat_ST',
          elements: <></>,
          subroute: ExportSuratTugasWilayahSekretariatRoutes,
        },
        {
          isPage: true,
          title: 'Anggota',
          icon: 'element-10',
          route: 'wilayah_sekretariat_anggota',
          elements: <></>,
          subroute: ExportSuratTugasWilayahSekretariatAnggotaRoutes,
        },
      ],
    },
    {
      isPage: true,
      title: 'Pegawai',
      icon: 'element-10',
      route: 'ST-pegawai',
      elements: <></>,
      subroute: ExportSuratTugasPegawaiRoutes,
    },
    {
      isPage: true,
      title: 'Anggaran Perwilayah',
      icon: 'element-10',
      route: 'anggaran-perwilayah',
      elements: <></>,
      subroute: ExportSuratTugasAnggaranPerwilayahRoutes,
    },
  ],
}

const PersiapanPelaksanaanPengawasanInternal: DataRoutesProps = {
  title: 'Persiapan Pelaksanaan Pengawasan Internal',
  icon: 'element-10',
  route: 'persiapan',
  elements: <></>,
  subroute: [
    {
      isPage: true,
      title: 'Form Usulan',
      icon: 'element-10',
      route: 'form_usulan',
      elements: <></>,
      subroute: FormUsulanRoutes,
    },
    {...DaftarStatusUsulan},
    {
      isPage: true,
      title: 'Form Surat Tugas',
      icon: 'element-10',
      route: 'form_st',
      elements: <></>,
      subroute: FormSuratTugasRoutes,
    },
    {...DaftarStatusSuratTugas},
    {
      isPage: true,
      title: 'Form Surat Perjalanan Dinas',
      icon: 'element-10',
      route: 'form_spd',
      elements: <></>,
      subroute: FormSuratPerjalananDinasRoutes,
    },
    {...ExportSuratTugas},
    {...DataTambahan},
  ],
}

export default PersiapanPelaksanaanPengawasanInternal
