/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDetailData,
  TableInputDetailDprData,
} from './PageData'
import {
  formInputList,
  formInputStatus,
  formInputStatusEvalap,
  formInputStatusketua,
  formInputStatusppk,
  tableInputFilter,
} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayout, PdfLayout2} from './PdfLayout'
import {ButtonLoading, CardUi} from '../../../../helper/AppUi'
import FormInput, {BaseInputOnChangeProps} from '../../../../helper/FormInput'
import {
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  formInputListSelector,
  getStatusColor,
  numberlistPagination,
  objectListSelector,
} from '../../../../helper/AppFunction'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {formatDate} from '../../../../helper/DateFns'
import {format} from 'date-fns'
import {
  TableInputDetail,
  TableInputDetailColumn,
  TableInputDetailFile,
} from '../../../../helper/TableInputDetail'
import {ProtectComponent, hasAccessRoutes} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'
import {useAuth} from '../../../../modules/auth'
import * as Yup from 'yup'
import {useSelector} from 'react-redux'

const dynamicInputList = (data?: any, param?: string, id?: number) => {
  if (param === 'transportasi_data') {
    data?.forEach((l: any, i: number) => {
      let _inputListTransportasi: any = [...formInputList].filter(
        (l: any) => l?.name == 'transportasi_data'
      )
      const inputKwitansi = formInputListSelector({
        formInputList: _inputListTransportasi[0].component,
        name: 'kwitansi',
      })
      if (l?.jenis === 'DKI' || l?.dibayar_oleh === 'Kantor' || l.dibayar_oleh == null) {
        inputKwitansi.validator = Yup.string().nullable()
      } else {
        inputKwitansi.validator = Yup.string().required('Kwitansi wajib diisi.')
      }
    })
  }
}

const onChange = (e: BaseInputOnChangeProps, role?: string, pegawai_id?: number) => {
  const data = e?._data.get
  const dataObj = e._data.getObject
  const serverData = e._apiData.server
  const _inputList = [...formInputList]
  // const listRolesName = ['Verifikasi dan Pelaporan', 'Super Admin']
  let result = {...data}
  if (
    // role &&
    pegawai_id &&
    serverData?.status_ketua === 'Disetujui' &&
    // !listRolesName.some((e) => e.toLowerCase() === role?.toLowerCase())
    serverData?.pegawai_id === pegawai_id
  ) {
    for (const l of _inputList) {
      l.disabled = true
    }
  }
  if (dataObj.transportasi_data.length) {
    dataObj.transportasi_data?.map((l: any, i: number) => {
      dynamicInputList(dataObj.transportasi_data, 'transportasi_data')
      if (
        dataObj.transportasi_data[i]?.dibayar_oleh == null ||
        !dataObj.transportasi_data[i]?.dibayar_oleh
      ) {
        result = {
          ...data,
          [`transportasi_data_dibayar_oleh_${i + 1}`]: 'Kantor',
        }
      }
    })
  }
  if (e.type === 'onChange' || e.type === 'onInit') {
    // Uang Muka
    const uang_muka = Number(data?.uang_muka || 0)
    // Tanggal Realisasi
    const tglSuratTugas = {
      mulai: e?._apiData?.get?.surat_tugas_id_detail?.tanggal_mulai,
      selesai: e?._apiData?.get?.surat_tugas_id_detail?.tanggal_selesai,
    }
    let Hp = 0
    if (e.type === 'onChange') {
      // const ketuaId = e._apiData.server?.ketua_id || e._apiData.server?.pegawai_id || 0
      const ketuaId = e._apiData.server?.pegawai_id || 0
      const kepadaList = NullProof({
        input: e._apiData.get,
        params: 'surat_tugas_id_detail.kepada',
        isMap: true,
      })?.filter((l: any) => l?.pegawai_id === ketuaId)
      if (kepadaList?.length > 0) {
        Hp = kepadaList[0]?.hari
      }
    }
    let tanggal_realisasi_total: number = 0
    const ubahTanggal = (value: Date) => {
      return formatDate({date: value?.toString(), dateFormat: 'dd MMMM yyyy'})
    }
    const tanggalBuilder = ({index, l}: {index: number; l: any}) => {
      let json: any = {}
      const parent = 'tanggal_realisasi_data'
      const mulai = l?.tanggal_mulai
      const selesai = l?.tanggal_selesai
      const mulaiProps = `${parent}_tanggal_mulai_${index}`
      const selesaiProps = `${parent}_tanggal_selesai_${index}`
      const total = formatDate({date: mulai, targetDate: selesai, dateFormat: 'range'}) + 1 || 1
      return {json: json, parent, mulai, selesai, mulaiProps, selesaiProps, total}
    }
    const changeDate = (date: string) => {
      return format(new Date(date), 'yyyy-MM-dd')
    }
    const tanggalSkrg = format(new Date(), 'yyyy-MM-dd')
    if (tglSuratTugas?.mulai || tglSuratTugas?.selesai) {
      NullProof({input: dataObj, params: 'tanggal_realisasi_data', isMap: true})?.map(
        (l: any, i: number) => {
          let json: any = {}
          let index = i + 1
          let response = tanggalBuilder({index: i + 1, l: l})
          json[`${response.parent}_tanggal_total_${index}`] = response.total
          tanggal_realisasi_total += response.total
          try {
            if (
              changeDate(response.mulai) < tglSuratTugas.mulai ||
              changeDate(response.mulai) > tglSuratTugas.selesai ||
              changeDate(response.selesai) < tglSuratTugas.mulai ||
              changeDate(response.selesai) > tglSuratTugas.selesai
            ) {
              e.setError({
                field: response.mulaiProps,
                message: `Jadwal #${index} tidak sesuai dengan Surat Tugas`,
              })
              e.setError({
                field: response.selesaiProps,
                message: `Jadwal #${index} tidak sesuai dengan Surat Tugas`,
              })
            } else if (response.mulai > response.selesai) {
              e.setError({
                field: response.mulaiProps,
                message: 'Tanggal mulai tidak boleh lebih dari tanggal selesai',
              })
              e.setError({
                field: response.selesaiProps,
                message: 'Tanggal selesai tidak boleh lebih dari tanggal mulai',
              })
              } else if (
                changeDate(response.mulai) > tanggalSkrg ||
                changeDate(response.selesai) > tanggalSkrg
              ) {
                e.setError({
                  field: response.mulaiProps,
                  message: 'Tanggal mulai tidak boleh lebih dari hari ini',
                })
                e.setError({
                  field: response.selesaiProps,
                  message: 'Tanggal selesai tidak boleh lebih dari hari ini',
                })
            } else {
              e.setError({
                field: response.mulaiProps,
                message: '',
              })
              e.setError({
                field: response.selesaiProps,
                message: '',
              })
            }
          } catch (error) {}
          NullProof({input: dataObj, params: 'tanggal_realisasi_data', isMap: true})?.map(
            (l1: any, i1: number) => {
              let indexTarget = i1 + 1
              if (index !== indexTarget) {
                let responseTarget = tanggalBuilder({index: indexTarget, l: l1})
                if (
                  ubahTanggal(response.mulai) === ubahTanggal(responseTarget.mulai) ||
                  ubahTanggal(response.mulai) === ubahTanggal(responseTarget.selesai) ||
                  !(
                    response.mulai < responseTarget.mulai || response.mulai > responseTarget.selesai
                  )
                ) {
                  e.setError({
                    field: response.mulaiProps,
                    message: `Jadwal #${index} bentrok dengan Jadwal #${indexTarget}`,
                  })
                } else if (
                  ubahTanggal(response.selesai) === ubahTanggal(responseTarget.mulai) ||
                  ubahTanggal(response.selesai) === ubahTanggal(responseTarget.selesai) ||
                  !(
                    response.selesai < responseTarget.mulai ||
                    response.selesai > responseTarget.selesai
                  )
                ) {
                  e.setError({
                    field: response.selesaiProps,
                    message: `Jadwal #${index} bentrok dengan Jadwal #${indexTarget}`,
                  })
                }
              }
            }
          )
          result = {...result, ...json}
        }
      )
    }

    // Uang Harian
    const uang_harian_harga = Number(data?.uang_harian_harga || 0)
    const uang_harian_hari = Number(tanggal_realisasi_total)
    const uang_harian_total = Number(uang_harian_hari * uang_harian_harga)
    const uang_representatif_harga = Number(data?.uang_representatif_harga || 0)
    const uang_representatif_hari = Number(tanggal_realisasi_total)
    const uang_representatif_total = Number(uang_representatif_hari * uang_representatif_harga)
    // Uang Penginapan
    let uang_penginapan_total: number = 0
    let uang_penginapan_total_hari: number = 0
    NullProof({input: dataObj, params: 'uang_penginapan_data', isMap: true})?.map(
      (l: any, i: number) => {
        const parent = 'uang_penginapan_data'
        let json: any = {}
        const harga = Number(l?.harga || 0)
        const hari = Number(l?.hari || 0)
        const tanpa_hotel = l?.tanpa_hotel
        const total = Number(tanpa_hotel ? harga * hari * (30 / 100) : harga * hari)
        json[`${parent}_harga_${i + 1}`] = harga
        json[`${parent}_hari_${i + 1}`] = hari
        json[`${parent}_total_${i + 1}`] = total
        uang_penginapan_total += total
        uang_penginapan_total_hari += hari
        result = {...result, ...json}
      }
    )
    // Transportasi
    let transportasi_total: number = 0
    let total_transportasi_kantor: number = 0
    for (let i = 1; i <= data?.transportasi_data || 0; i++) {
      const parent = 'transportasi_data'
      let json: any = {}
      const harga = Number(data[`${parent}_harga_${i}`] || 0)
      const total = Number(harga)
      json[`${parent}_harga_${i}`] = harga
      json[`${parent}_total_${i}`] = total
      transportasi_total += total
      if (data[`${parent}_dibayar_oleh_${i}`] === 'Kantor') {
        total_transportasi_kantor += total
      }
      result = {...result, ...json}
    }
    result = {...result, uang_muka_transport: total_transportasi_kantor}
    // Biaya Lainnya
    let biaya_lainnya_total: number = 0
    for (let i = 1; i <= data?.kwitansi_lain_lain_list || 0; i++) {
      const parent = 'kwitansi_lain_lain_list'
      let json: any = {}
      const harga = Number(data[`${parent}_harga_${i}`] || 0)
      const total = Number(harga)
      json[`${parent}_harga_${i}`] = harga
      json[`${parent}_total_${i}`] = total
      biaya_lainnya_total += total
      result = {...result, ...json}
    }
    // BST Kantor + Lokasi
    const bst_kantor = Number(data?.bst_kantor || 0)
    const bst_lokasi = Number(data?.bst_lokasi || 0)
    const bst_total = bst_kantor + bst_lokasi
    const jumlah_dibayarkan =
      uang_harian_total +
      uang_representatif_total +
      uang_penginapan_total +
      transportasi_total +
      biaya_lainnya_total +
      bst_total
    const sisa_pengembalian = jumlah_dibayarkan - (uang_muka + total_transportasi_kantor)
    result = {
      ...result,
      uang_muka,
      jumlah_dibayarkan,
      sisa_pengembalian,
      uang_harian_total,
      uang_harian_hari,
      uang_representatif_hari,
      uang_representatif_total,
    }
    //
    if (tanggal_realisasi_total > Hp && Hp !== 0) {
      e.setError({
        field: 'tanggal_realisasi_data',
        message: `Tanggal tidak sesuai dengan HP (${Hp})`,
      })
    } else {
      e.setError({field: 'tanggal_realisasi_data', message: ''})
    }
    const hari_penginapan =
      dataObj.uang_penginapan_data?.reduce((acc: number, curr: any) => acc + curr.hari, 0) || 0
    if (hari_penginapan > Hp && Hp !== 0) {
      e.setError({
        field: 'uang_penginapan_data',
        message: `Jumlah total hari penginapan tidak sesuai dengan HP (${Hp})`,
      })
    } else if (uang_penginapan_total_hari > tanggal_realisasi_total) {
      e.setError({
        field: 'uang_penginapan_data',
        message: 'Total jumlah malam tidak boleh lebih dari total hari realisasi',
      })
    } else {
      e.setError({field: 'uang_penginapan_data', message: ''})
    }
    e._dataIndicator.post({
      tanggal_realisasi_data: tanggal_realisasi_total,
      uang_penginapan_data: uang_penginapan_total,
      transportasi_data: transportasi_total,
      kwitansi_lain_lain_list: biaya_lainnya_total,
    })
  }
  e?.resetForm({values: result})
}

const isSuperAdmin = (data: any) => {
  return data?.roles?.id === 1
}

const getRolesPermissionPage = (data: any) => {
  const listPermission: string[] = data?.menu?.filter(
    (e: string) => e.indexOf('form_bukti_perjalanan') !== -1
  )
  return listPermission
}

const cekRolesName = (dataUser: any) => {
  const listRolesName = ['Verifikasi dan Pelaporan', 'Keuangan']
  const rolesName = dataUser?.roles?.name
  const kondisi1 =
    rolesName && listRolesName.some((e) => e.toLowerCase().includes(rolesName.toLowerCase()))
  return kondisi1
}
const cekSemuaId = (dataUser: any, data: any) => {
  const idPegawai = dataUser?.pegawai?.id
  const kondisi1 = idPegawai === data?.pegawai_id
  const kondisi2 = idPegawai === data?.ketua?.id
  const kondisi3 = idPegawai === data?.evalap?.id
  const kondisi4 = idPegawai === data?.ppk?.id
  const kondisi5 = idPegawai === data?.bendahara?.pegawai?.id
  return idPegawai && (kondisi1 || kondisi2 || kondisi3 || kondisi4 || kondisi5)
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const {auth} = useAuth()
  const query = useParams()
  const _route = useLocation()
  const routeData: any = useSelector((state: any) => state.route.value)
  const dataUser: any = auth?.data
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const [actionMenu, setActionMenu] = useState([...(TableInputData.link?.action || [])])
  const cekRouteAccess = (access: string) => {
    let hasAccess: boolean = false
    const currentRoute = _route.pathname
    const permissionRequest = `${currentRoute}/${access}`
    hasAccess = hasAccessRoutes(
      routeData?.route,
      permissionRequest.substring(1),
      dataUser?.role_id === 1,
      true,
      query
    )
    return hasAccess
  }
  useEffect(() => {
    let defaultValue = [...actionMenu]
    if (
      !(
        cekRolesName(dataUser) ||
        cekSemuaId(dataUser, modal?.input) ||
        (cekSemuaId(dataUser, modal?.input) && cekRouteAccess('ubah/:id')) ||
        isSuperAdmin(dataUser)
      )
    ) {
      defaultValue[2].hide = true
    } else {
      defaultValue[2].hide = false
    }
    setActionMenu(defaultValue)
  }, [dataUser, modal?.input])
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          link={{...TableInputData.link, action: actionMenu}}
          filter={tableInputFilter}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nama Kegiatan' props={{style: {minWidth: '300px'}}}>
                    {NullProof({
                      input: l,
                      params: 'surat_tugas.nama_kegiatan',
                      option: {text: {limit: 200}},
                    })}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '200px'}}}>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_ketua', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Ketua Tim</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_evalap', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Evalap</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_ppk', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>PPK</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_bendahara', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Bendahara</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_vf', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Status Transfer</div>
                    </div>
                  </TableColumn>
                  <TableColumn label='Jumlah Dibayarkan' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(
                      NullProof({input: l, params: 'jumlah_dibayarkan', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableColumn label='Dibuat' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  const {auth} = useAuth()
  const navigate = useNavigate()
  const dataUser: any = auth?.data
  const _route = useLocation()
  const routeData: any = useSelector((state: any) => state.route.value)
  const listRolesName = ['Verifikasi dan Pelaporan', 'tim kerja keuangan umvf', 'Super Admin']
  const inputUangMuka = formInputListSelector({formInputList: formInputList, name: 'uang_muka'})
  const inputST = formInputListSelector({formInputList: formInputList, name: 'surat_tugas_id'})

  const cekRouteAccess = (access: string) => {
    let hasAccess: boolean = false
    const currentRoute = _route.pathname
    const permissionRequest = `${currentRoute}`
    hasAccess = hasAccessRoutes(
      routeData?.route,
      permissionRequest.substring(1),
      dataUser?.role_id === 1,
      true,
      query
    )
    return hasAccess
  }

  const pegawaiId = dataUser?.pegawai?.id
  if (
    listRolesName.some(
      (e) => e.toLowerCase() === NullProof({input: dataUser, params: 'roles.name'})?.toLowerCase()
    )
  ) {
    inputUangMuka.disabled = false
    inputST.disabled = false
  } else {
    inputUangMuka.disabled = true
    inputST.disabled = true
  }

  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
            useServerMessage: true,
          }}
          onChange={(e: BaseInputOnChangeProps) => {
            if (e.type === 'onInit') {
              if (
                !(
                  cekRolesName(dataUser) ||
                  cekSemuaId(dataUser, e._apiData.server) ||
                  (cekSemuaId(dataUser, e._apiData.server) && cekRouteAccess('ubah/:id')) ||
                  isSuperAdmin(dataUser)
                )
              ) {
                navigate('..', {replace: true})
              }
            }
            onChange(e, NullProof({input: dataUser, params: 'roles.name'}), pegawaiId)
          }}
        />
      </CardUi>
    </>
  )
}

const RevisiWrapper: FC = () => {
  const query = useParams()
  const {auth} = useAuth()
  const dataUser: any = auth?.data
  // const inputUangMuka = formInputListSelector({formInputList: formInputList, name: 'uang_muka'})
  // // const listRolesName = ['Verifikasi dan Pelaporan', 'Super Admin']
  const pegawaiId = dataUser?.pegawai?.id
  // if (
  //   // listRolesName.some(
  //   //   (e) => e.toLowerCase() === NullProof({input: dataUser, params: 'roles.name'})?.toLowerCase()
  //   // )
  //   pegawaiId === formInputListSelector({formInputList: formInputList, name: 'pegawai_id'})
  // ) {
  //   inputUangMuka.disabled = false
  // } else {
  //   inputUangMuka.disabled = true
  // }

  return (
    <>
      <PageTitle breadcrumbs={PageData.revisi.page.breadcrumb.data}>
        {PageData.revisi.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.revisi.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/revisi`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={(e: BaseInputOnChangeProps) => {
            onChange(e, NullProof({input: dataUser, params: 'roles.name'}), pegawaiId)
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const inputUangMuka = formInputListSelector({formInputList: formInputList, name: 'uang_muka'})
  const inputST = formInputListSelector({formInputList: formInputList, name: 'surat_tugas_id'})
  inputUangMuka.disabled = false
  inputST.disabled = false
  const {auth} = useAuth()
  const dataUser: any = auth?.data

  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
            useServerMessage: true,
          }}
          onChange={(e: BaseInputOnChangeProps) => {
            e._apiData.server.pegawai_id = dataUser?.pegawai_id || 0
            onChange(e)
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout2}
        justPreview={true}
      />
      <PdfPageViewer
        route={`${InfoData.api}/${query.id}`}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}

const DetailWrapper: FC = () => {
  const routeData = useSelector((state: any) => state.route.value)
  const navigate = useNavigate()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    bendahara: false,
    evalap: false,
    ketua: false,
    ppk: false,
  })
  const [key, setKey] = useState<number>(0)
  const sisa_pengembalian = NullProof({
    input: data,
    params: 'sisa_pengembalian',
    isLabel: false,
  })
  const {auth} = useAuth()
  const dataUser: any = auth?.data
  const protectPage = () => {
    const search = ['detail-id']
    const listPermission: string[] = getRolesPermissionPage(routeData)
    const listAccess: string[] = listPermission?.filter((e: string) =>
      search.some((l) => e.indexOf(l) !== -1)
    )
    return (
      isSuperAdmin(dataUser) ||
      cekRolesName(dataUser) ||
      cekSemuaId(dataUser, data) ||
      listAccess.length > 0
    )
  }
  const getPermissionUbahStatus = (search: string[] = []) => {
    const listPermission: string[] = getRolesPermissionPage(routeData)
    const listUbahStatus: string[] = listPermission?.filter(
      (e: string) => e.indexOf('ubah_status') !== -1
    )
    const listAccess: string[] = listUbahStatus?.filter((e: string) =>
      search.some((l) => e.indexOf(l) !== -1)
    )
    return listAccess.length > 0
  }
  const protectStatusKetua = () => {
    let boleh = false
    const jabatanList = [
      'Penanggung Jawab',
      'Wakil Penanggung Jawab',
      'Pengendali Mutu',
      'Pengendali Teknis',
      'Ketua Tim',
    ]
    const getListAnggotaSt = (): {id: number; jabatan: string}[] => {
      let listId: {id: number; jabatan: string}[] = []
      const kepada = NullProof({input: data, params: 'surat_tugas.kepada', isMap: true})
      for (const l of kepada) {
        const kepadaId = NullProof({input: l, params: 'pegawai.id', isLabel: false})
        const kepadaJabatan = NullProof({input: l, params: 'jabatan', isLabel: false})
        if (kepadaId) {
          listId.push({
            id: kepadaId,
            jabatan: kepadaJabatan,
          })
        }
      }
      return listId
    }
    const kondisi1 = isSuperAdmin(dataUser)
    const kondisi2_1 =
      // NullProof({input: data, params: 'status_ketua'}) !== 'Disetujui' ||
      NullProof({input: data, params: 'status_evalap'}) !== 'Disetujui'
    const kondisi2_2 = dataUser?.pegawai?.id && dataUser?.pegawai?.id === data?.ketua?.id
    const kondisi3 = getPermissionUbahStatus(['ketua'])
    const kondisi4 = getListAnggotaSt()?.some(
      (e) => e.id === dataUser?.pegawai?.id && jabatanList.includes(e.jabatan)
    )
    const kondisi5 = getListAnggotaSt()?.some(
      (e) => e.id === dataUser?.pegawai?.id && !e.jabatan.includes('Anggota')
    )
    if (kondisi1) {
      boleh = true
    } else if (kondisi2_1 && kondisi2_2) {
      boleh = true
    } else if (kondisi2_1 && kondisi3 && kondisi4) {
      boleh = true
    }
    // else if (kondisi2_1 && kondisi4) {
    //   boleh = true
    // }
    else if (kondisi2_1 && kondisi5) {
      boleh = true
    }
    return boleh
  }
  const protectStatusEvalap = () => {
    let boleh = false
    const kondisi1 = isSuperAdmin(dataUser)
    const kondisi2_1 =
      // NullProof({input: data, params: 'status_evalap'}) !== 'Disetujui' ||
      NullProof({input: data, params: 'status_ppk'}) !== 'Disetujui'
    const kondisi2_2 = dataUser?.pegawai?.id && dataUser?.pegawai?.id === data?.evalap?.id
    const kondisi3 = NullProof({input: data, params: 'status_ketua'}) === 'Disetujui'
    const kondisi4 = getPermissionUbahStatus(['evalap'])
    const kondisi5 = cekRolesName(dataUser)
    if (kondisi1) {
      boleh = true
    } else if (kondisi2_1 && kondisi2_2 && kondisi3) {
      boleh = true
    } else if (kondisi2_1 && kondisi3 && kondisi4) {
      boleh = true
    } else if (kondisi2_1 && kondisi3 && kondisi5) {
      boleh = true
    }
    return boleh
  }
  const protectStatusPpk = () => {
    let boleh = false
    const kondisi1 = isSuperAdmin(dataUser)
    const kondisi2_1 =
      // NullProof({input: data, params: 'status_ppk'}) !== 'Disetujui' ||
      NullProof({input: data, params: 'status_bendahara'}) !== 'Disetujui'
    const kondisi2_2 = dataUser?.pegawai?.id && dataUser?.pegawai?.id === data?.ppk?.id
    const kondisi3 = NullProof({input: data, params: 'status_evalap'}) === 'Disetujui'
    const kondisi4 = getPermissionUbahStatus(['ppk'])
    if (kondisi1) {
      boleh = true
    } else if (kondisi2_1 && kondisi2_2 && kondisi3) {
      boleh = true
    } else if (kondisi2_1 && kondisi3 && kondisi4) {
      boleh = true
    }
    return boleh
  }
  const protectStatusBendahara = () => {
    let boleh = false
    const kondisi1 = isSuperAdmin(dataUser)
    // const kondisi2_1 = NullProof({input: data, params: 'status_bendahara'}) !== 'Disetujui'
    const kondisi2_2 =
      dataUser?.pegawai?.id && dataUser?.pegawai?.id === data?.bendahara?.pegawai?.id
    const kondisi3 = NullProof({input: data, params: 'status_ppk'}) === 'Disetujui'
    const kondisi4 = getPermissionUbahStatus(['bendahara'])
    if (kondisi1) {
      boleh = true
    } else if (kondisi2_2 && kondisi3) {
      boleh = true
    } else if (kondisi3 && kondisi4) {
      boleh = true
    }
    return boleh
  }
  useEffect(() => {
    if (!protectPage()) {
      navigate(InfoData.path, {
        replace: true,
      })
    }
  }, [])
  return (
    <>
      {(protectStatusKetua() ||
        protectStatusEvalap() ||
        protectStatusPpk() ||
        protectStatusBendahara()) && (
        <>
          <Modal
            id='modal_status_ketua'
            isShow={modal?.ketua}
            onClose={() => {
              setModal((p: any) => ({...p, ketua: false}))
            }}
            title='Ubah Status'
          >
            <FormInput
              input={formInputStatusketua}
              route={{
                url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-ketua`, query),
                type: 'put',
                onFeedback: (e: any) => {
                  if (e?.status) {
                    setKey((p) => p + 1)
                  }
                  setModal((p: any) => ({...p, ketua: false}))
                },
              }}
              isModal
            />
          </Modal>
          <Modal
            id='modal_status_bendahara'
            isShow={modal?.bendahara}
            onClose={() => {
              setModal((p: any) => ({...p, bendahara: false}))
            }}
            title='Ubah Status'
          >
            <FormInput
              input={formInputStatus}
              route={{
                url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-bendahara`, query),
                type: 'put',
                onFeedback: (e: any) => {
                  if (e?.status) {
                    setKey((p) => p + 1)
                  }
                  setModal((p: any) => ({...p, bendahara: false}))
                },
              }}
              isModal
            />
          </Modal>
          <Modal
            id='modal_status_evalap'
            isShow={modal?.evalap}
            onClose={() => {
              setModal((p: any) => ({...p, evalap: false}))
            }}
            title='Ubah Status'
          >
            <FormInput
              input={formInputStatusEvalap}
              route={{
                url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-evalap`, query),
                type: 'put',
                onFeedback: (e: any) => {
                  if (e?.status) {
                    setKey((p) => p + 1)
                  }
                  setModal((p: any) => ({...p, evalap: false}))
                },
              }}
              isModal
            />
          </Modal>
          <Modal
            id='modal_status_ppk'
            isShow={modal?.ppk}
            onClose={() => {
              setModal((p: any) => ({...p, ppk: false}))
            }}
            title='Ubah Status'
          >
            <FormInput
              input={formInputStatusppk}
              route={{
                url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-ppk`, query),
                type: 'put',
                onFeedback: (e: any) => {
                  if (e?.status) {
                    setKey((p) => p + 1)
                  }
                  setModal((p: any) => ({...p, ppk: false}))
                },
              }}
              isModal
            />
          </Modal>
        </>
      )}
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        {/* Data Perjadin */}
        <TableInputDetail
          onSuccess={(val: any) => {
            setData(val)
          }}
          {...TableInputDetailData}
        >
          <CardUi title={PageData.detail?.page.breadcrumb.title}>
            <TableInputDetailColumn title={{value: 'Dibuat'}}>
              {formatDate({
                date: NullProof({input: data, params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
              {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'lampiran_penugasan', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Tanggal Realisasi'}}
            >
              {NullProof({input: data, params: 'tanggal_realisasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                        {`${formatDate({
                          date: NullProof({input: l, params: 'tanggal_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} - ${formatDate({
                          date: NullProof({input: l, params: 'tanggal_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} (${
                          NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                        } Hari)`}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Pembayaran Awal / Uang Muka'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                {ConvertCurrency(
                  parseInt(
                    NullProof({
                      input: data,
                      params: 'uang_muka',
                      isLabel: false,
                    }) || 0
                  ) +
                    NullProof({
                      input: data,
                      params: 'uang_muka_transportasi',
                      isLabel: false,
                    }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_harian_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Representatif'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({
                  input: data,
                  params: 'uang_representatif_hari',
                })} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Uang Penginapan'}}
            >
              {NullProof({input: data, params: 'uang_penginapan_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                        {`${NullProof({
                          input: l,
                          params: 'hari',
                        })} Hari x ${ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}`}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Transportasi'}}
            >
              {NullProof({input: data, params: 'transportasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                        {NullProof({input: l, params: 'dibayar_oleh'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                        {NullProof({input: l, params: 'asal'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_kantor',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'bst_kantor_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_lokasi',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
              <TableInputDetailFile
                data={NullProof({input: data, params: 'bst_lokasi_lampiran', isLabel: false})}
              />
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Biaya Lainnya'}}
            >
              {NullProof({input: data, params: 'kwitansi_lain_lain_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                        {NullProof({input: l, params: 'deskripsi'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'jumlah_dibayarkan',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: sisa_pengembalian < 0 ? 'Nominal Total Pengembalian' : 'Nominal Total Sisa',
              }}
            >
              {ConvertCurrency(sisa_pengembalian < 0 ? sisa_pengembalian * -1 : sisa_pengembalian)}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Ketua Tim'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_ketua', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_ketua'})}</div>
                {/* {idPegawai ===
                  objectListSelector({
                    data: NullProof({input: data, params: 'surat_tugas.kepada', isMap: true}),
                    name: 'jabatan',
                    search: 'Ketua Tim',
                  })?.pegawai_id && ( */}
                <div>
                  {/* <ProtectComponent id='ubah_status_ketua/:id' route={InfoData.path}> */}
                  {protectStatusKetua() && (
                    <>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setModal((p: any) => ({...p, ketua: true}))
                        }}
                      >
                        Ubah Status
                      </button>
                    </>
                  )}
                  {/* </ProtectComponent> */}
                </div>
                {/* )} */}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Ketua Tim'}}>
              {NullProof({input: data, params: 'ketua.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Ketua Tim'}}>
              {NullProof({input: data, params: 'catatan_ketua'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan Ketua Tim'}}>
              {NullProof({
                input: data,
                params: 'status_ketua_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Evalap'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_evalap', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_evalap'})}</div>
                {protectStatusEvalap() && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, evalap: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Evalap'}}>
              {NullProof({input: data, params: 'evalap.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Evalap'}}>
              {NullProof({input: data, params: 'catatan_evalap'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Evalap'}}>
              {NullProof({
                input: data,
                params: 'status_evalap_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status ppk'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_ppk', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_ppk'})}</div>
                {protectStatusPpk() && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, ppk: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'ppk'}}>
              {NullProof({input: data, params: 'ppk.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan ppk'}}>
              {NullProof({input: data, params: 'catatan_ppk'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  PPK'}}>
              {NullProof({
                input: data,
                params: 'status_ppk_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Bendahara'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_bendahara', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_bendahara'})}</div>
                {protectStatusBendahara() && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal((p: any) => ({...p, bendahara: true}))
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Bendahara'}}>
              {NullProof({input: data, params: 'bendahara.pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Catatan Bendahara'}}>
              {NullProof({input: data, params: 'catatan_bendahara'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Tanda Tangan  Bendahara'}}>
              {NullProof({
                input: data,
                params: 'status_bendahara_tgl',
                type: 'date',
                option: {date: {format: 'dd MMMM yyyy, HH:mm:ss'}},
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Status Transfer'}}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='rounded-circle'
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    backgroundColor: getStatusColor(
                      NullProof({input: data, params: 'status_vf', isLabel: false})
                    ),
                  }}
                ></div>
                <div>{NullProof({input: data, params: 'status_vf'})}</div>
              </div>
            </TableInputDetailColumn>
          </CardUi>

          {/* Data Pengusul */}
          {data?.revisi && (
            <>
              <CardUi title='Data Pengusul' props={{className: 'mt-5'}}>
                <TableInputDetailColumn title={{value: 'Dibuat'}}>
                  {formatDate({
                    date: NullProof({
                      input: data.revisi?.old,

                      params: 'createdAt',
                    }),
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'surat_tugas.nomor_surat',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.old,

                      params: 'lampiran_penugasan',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Pegawai'}}>
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'pegawai.nama',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Tanggal Realisasi'}}
                >
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'tanggal_realisasi_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                          {`${formatDate({
                            date: NullProof({input: l, params: 'tanggal_mulai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })} - ${formatDate({
                            date: NullProof({input: l, params: 'tanggal_selesai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })} (${
                            NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                          } Hari)`}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
                  <TableInputDetailColumn
                    hideDivider
                    title={{value: 'Pembayaran Awal / Uang Muka'}}
                  >
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.old,

                        params: 'uang_muka',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.old,

                        params: 'uang_muka_transportasi',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                    {ConvertCurrency(
                      parseInt(
                        NullProof({
                          input: data.revisi?.old,

                          params: 'uang_muka',
                          isLabel: false,
                        }) || 0
                      ) +
                        NullProof({
                          input: data.revisi?.old,

                          params: 'uang_muka_transportasi',
                          isLabel: false,
                        }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
                  <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                    {`${NullProof({
                      input: data.revisi?.old,

                      params: 'uang_harian_hari',
                    })} Hari x ${ConvertCurrency(
                      NullProof({
                        input: data.revisi?.old,

                        params: 'uang_harian_harga',
                        isLabel: false,
                      }) || 0
                    )}`}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.old,

                        params: 'uang_harian_total',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  title={{value: 'Uang Representatif'}}
                >
                  <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                    {`${NullProof({
                      input: data,
                      params: 'uang_representatif_hari',
                    })} Hari x ${ConvertCurrency(
                      NullProof({
                        input: data,
                        params: 'uang_representatif_harga',
                        isLabel: false,
                      }) || 0
                    )}`}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data,
                        params: 'uang_representatif_total',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Uang Penginapan'}}
                >
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'uang_penginapan_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                          {`${NullProof({
                            input: l,
                            params: 'hari',
                          })} Hari x ${ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}`}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Transportasi'}}
                >
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'transportasi_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                          {NullProof({input: l, params: 'dibayar_oleh'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                          {NullProof({input: l, params: 'asal'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                          {NullProof({input: l, params: 'tujuan'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                          {NullProof({input: l, params: 'jenis'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.old,

                      params: 'bst_kantor',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.old,

                      params: 'bst_kantor_lampiran',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.old,

                      params: 'bst_lokasi',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.old,

                      params: 'bst_lokasi_lampiran',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Biaya Lainnya'}}
                >
                  {NullProof({
                    input: data.revisi?.old,

                    params: 'kwitansi_lain_lain_list',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                          {NullProof({input: l, params: 'deskripsi'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.old,

                      params: 'jumlah_dibayarkan',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  title={{
                    value:
                      NullProof({
                        input: data.revisi?.old,
                        params: 'sisa_pengembalian',
                        isLabel: false,
                      }) < 0
                        ? 'Nominal Total Pengembalian'
                        : 'Nominal Total Sisa',
                  }}
                >
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.old,
                      params: 'sisa_pengembalian',
                      isLabel: false,
                    }) < 0
                      ? NullProof({
                          input: data.revisi?.old,
                          params: 'sisa_pengembalian',
                          isLabel: false,
                        }) * -1
                      : NullProof({
                          input: data.revisi?.old,
                          params: 'sisa_pengembalian',
                          isLabel: false,
                        })
                  )}
                </TableInputDetailColumn>
              </CardUi>
              <CardUi title='Data Revisi Evalap' props={{className: 'mt-5'}}>
                <TableInputDetailColumn title={{value: 'Dibuat'}}>
                  {formatDate({
                    date: NullProof({
                      input: data.revisi?.updated,

                      params: 'createdAt',
                    }),
                    dateFormat: 'dd MMMM yyyy',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'Surat Tugas'}}>
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'surat_tugas.nomor_surat',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Lampiran Penugasan'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.updated,

                      params: 'lampiran_penugasan',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Pegawai'}}>
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'pegawai.nama',
                  })}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Tanggal Realisasi'}}
                >
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'tanggal_realisasi_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                          {`${formatDate({
                            date: NullProof({input: l, params: 'tanggal_mulai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })} - ${formatDate({
                            date: NullProof({input: l, params: 'tanggal_selesai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })} (${
                            NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                          } Hari)`}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
                  <TableInputDetailColumn
                    hideDivider
                    title={{value: 'Pembayaran Awal / Uang Muka'}}
                  >
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.updated,

                        params: 'uang_muka',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.updated,

                        params: 'uang_muka_transportasi',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                    {ConvertCurrency(
                      parseInt(
                        NullProof({
                          input: data.revisi?.updated,

                          params: 'uang_muka',
                          isLabel: false,
                        }) || 0
                      ) +
                        NullProof({
                          input: data.revisi?.updated,

                          params: 'uang_muka_transportasi',
                          isLabel: false,
                        }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
                  <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                    {`${NullProof({
                      input: data.revisi?.updated,

                      params: 'uang_harian_hari',
                    })} Hari x ${ConvertCurrency(
                      NullProof({
                        input: data.revisi?.updated,

                        params: 'uang_harian_harga',
                        isLabel: false,
                      }) || 0
                    )}`}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data.revisi?.updated,

                        params: 'uang_harian_total',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  isResponsive='desktop'
                  title={{value: 'Uang Representatif'}}
                >
                  <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                    {`${NullProof({
                      input: data,
                      params: 'uang_representatif_hari',
                    })} Hari x ${ConvertCurrency(
                      NullProof({
                        input: data,
                        params: 'uang_representatif_harga',
                        isLabel: false,
                      }) || 0
                    )}`}
                  </TableInputDetailColumn>
                  <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                    {ConvertCurrency(
                      NullProof({
                        input: data,
                        params: 'uang_representatif_total',
                        isLabel: false,
                      }) || 0
                    )}
                  </TableInputDetailColumn>
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Uang Penginapan'}}
                >
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'uang_penginapan_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                          {`${NullProof({
                            input: l,
                            params: 'hari',
                          })} Hari x ${ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}`}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Transportasi'}}
                >
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'transportasi_data',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                          {NullProof({input: l, params: 'dibayar_oleh'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                          {NullProof({input: l, params: 'asal'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                          {NullProof({input: l, params: 'tujuan'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                          {NullProof({input: l, params: 'jenis'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'BST Kantor'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.updated,

                      params: 'bst_kantor',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kwitansi BST Kantor'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.updated,

                      params: 'bst_kantor_lampiran',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'BST Lokasi'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.updated,

                      params: 'bst_lokasi',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn title={{value: 'Kwitansi BST Lokasi'}}>
                  <TableInputDetailFile
                    data={NullProof({
                      input: data.revisi?.updated,

                      params: 'bst_lokasi_lampiran',
                      isLabel: false,
                    })}
                  />
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Biaya Lainnya'}}
                >
                  {NullProof({
                    input: data.revisi?.updated,

                    params: 'kwitansi_lain_lain_list',
                    isMap: true,
                  })?.map((l: any, i: number) => (
                    <Fragment key={i}>
                      <TableInputDetailColumn isResponsive='desktop'>
                        <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                          {NullProof({input: l, params: 'deskripsi'})}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'harga',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                          <TableInputDetailFile
                            data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                          />
                        </TableInputDetailColumn>
                        <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                          {ConvertCurrency(
                            NullProof({
                              input: l,
                              params: 'total',
                              isLabel: false,
                            }) || 0
                          )}
                        </TableInputDetailColumn>
                      </TableInputDetailColumn>
                    </Fragment>
                  ))}
                </TableInputDetailColumn>
                <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.updated,

                      params: 'jumlah_dibayarkan',
                      isLabel: false,
                    }) || 0
                  )}
                </TableInputDetailColumn>
                <TableInputDetailColumn
                  title={{
                    value:
                      NullProof({
                        input: data.revisi?.updated,
                        params: 'sisa_pengembalian',
                        isLabel: false,
                      }) < 0
                        ? 'Nominal Total Pengembalian'
                        : 'Nominal Total Sisa',
                  }}
                >
                  {ConvertCurrency(
                    NullProof({
                      input: data.revisi?.updated,
                      params: 'sisa_pengembalian',
                      isLabel: false,
                    }) < 0
                      ? NullProof({
                          input: data.revisi?.updated,
                          params: 'sisa_pengembalian',
                          isLabel: false,
                        }) * -1
                      : NullProof({
                          input: data.revisi?.updated,
                          params: 'sisa_pengembalian',
                          isLabel: false,
                        })
                  )}
                </TableInputDetailColumn>
              </CardUi>
            </>
          )}
        </TableInputDetail>
      </Fragment>
    </>
  )
}

const DetailDprWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [dataDpr, setDataDpr] = useState<any>({})
  const [modal, setModal] = useState<any>({
    bendahara: false,
    evalap: false,
  })
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <Modal
        id='modal_status_bendahara'
        isShow={modal?.bendahara}
        onClose={() => {
          setModal((p: any) => ({...p, bendahara: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-bendahara`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, bendahara: false}))
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_evalap'
        isShow={modal?.evalap}
        onClose={() => {
          setModal((p: any) => ({...p, evalap: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatusEvalap}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubahstatus-evalap`, query),
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal((p: any) => ({...p, evalap: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {`${PageData.detail?.page.breadcrumb.title} DPR`}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={`${PageData.detail?.page.breadcrumb.title} DPR`}>
          {/* Data Perjadin */}
          <TableInputDetail
            onSuccess={(val: any) => {
              setData(val?.bukti_perjalanan)
              setDataDpr(val?.form_dpr)
            }}
            {...TableInputDetailDprData}
          >
            <TableInputDetailColumn
              title={{value: 'Form Perjadin', props: {className: 'fs-4'}}}
            ></TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Dibuat'}}>
              {formatDate({
                date: NullProof({input: data, params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Surat Tugas'}}>
              {NullProof({input: data, params: 'surat_tugas.nomor_surat'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              {NullProof({input: data, params: 'pegawai.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Tanggal Realisasi'}}
            >
              {NullProof({input: data, params: 'tanggal_realisasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: `${i + 1}. Tanggal`}}>
                        {`${formatDate({
                          date: NullProof({input: l, params: 'tanggal_mulai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} - ${formatDate({
                          date: NullProof({input: l, params: 'tanggal_selesai'}),
                          dateFormat: 'dd MMMM yyyy',
                        })} (${
                          NullProof({input: l, params: 'tanggal_total', isLabel: false}) || 0
                        } Hari)`}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Muka'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Pembayaran Awal / Uang Muka'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Uang Muka Transportasi'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_muka_transportasi',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total Uang Muka'}}>
                {ConvertCurrency(
                  parseInt(
                    NullProof({
                      input: data,
                      params: 'uang_muka',
                      isLabel: false,
                    }) || 0
                  ) +
                    NullProof({
                      input: data,
                      params: 'uang_muka_transportasi',
                      isLabel: false,
                    }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Harian'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({input: data, params: 'uang_harian_hari'})} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_harian_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn isResponsive='desktop' title={{value: 'Uang Representatif'}}>
              <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                {`${NullProof({
                  input: data,
                  params: 'uang_representatif_hari',
                })} Hari x ${ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_harga',
                    isLabel: false,
                  }) || 0
                )}`}
              </TableInputDetailColumn>
              <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                {ConvertCurrency(
                  NullProof({
                    input: data,
                    params: 'uang_representatif_total',
                    isLabel: false,
                  }) || 0
                )}
              </TableInputDetailColumn>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Uang Penginapan'}}
            >
              {NullProof({input: data, params: 'uang_penginapan_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Biaya'}}>
                        {`${NullProof({
                          input: l,
                          params: 'hari',
                        })} Hari x ${ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}`}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Transportasi'}}
            >
              {NullProof({input: data, params: 'transportasi_data', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Dibayar oleh'}}>
                        {NullProof({input: l, params: 'dibayar_oleh'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Asal'}}>
                        {NullProof({input: l, params: 'asal'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Tujuan'}}>
                        {NullProof({input: l, params: 'tujuan'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jenis'}}>
                        {NullProof({input: l, params: 'jenis'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'BST Kantor'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_kantor',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'BST Lokasi'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'bst_lokasi',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'Biaya Lainnya'}}
            >
              {NullProof({input: data, params: 'kwitansi_lain_lain_list', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Deskripsi'}}>
                        {NullProof({input: l, params: 'deskripsi'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Harga'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'harga',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Kwitansi'}}>
                        <TableInputDetailFile
                          data={NullProof({input: l, params: 'kwitansi', isLabel: false})}
                        />
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Total'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'total',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn hideDivider title={{value: 'Nominal Total'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'jumlah_dibayarkan',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nominal Total Sisa / Pengembalian'}}>
              {ConvertCurrency(
                NullProof({
                  input: data,
                  params: 'sisa_pengembalian',
                  isLabel: false,
                }) || 0
              )}
            </TableInputDetailColumn>
            <br />
            <br />
            {/* Form DPR */}
            <TableInputDetailColumn
              title={{value: 'Form DPR', props: {className: 'fs-4'}}}
            ></TableInputDetailColumn>
            {NullProof({input: dataDpr, params: 'id', isLabel: false}) && (
              <div>
                <Link
                  to={`/evaluasi_pelaporan/verifikasi_perjalanan_dinas/form_dpr/pdf/${dataDpr.id}`}
                >
                  <ButtonLoading loading={false} title={{button: 'Form DPR'}} />
                </Link>
              </div>
            )}

            <TableInputDetailColumn title={{value: 'Pembuat Komitmen'}}>
              {NullProof({input: dataDpr, params: 'pembuat_komitmen.nama'})}
            </TableInputDetailColumn>

            <TableInputDetailColumn
              hideDivider
              isResponsive='desktop'
              title={{value: 'List Pengeluaran'}}
            >
              {NullProof({input: dataDpr, params: 'list_pengeluaran', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <TableInputDetailColumn isResponsive='desktop'>
                      <TableInputDetailColumn hideDivider title={{value: 'Uraian'}}>
                        {l.uraian
                          ? NullProof({input: l, params: 'uraian'})
                          : NullProof({input: l, params: 'Uraian'})}
                      </TableInputDetailColumn>
                      <TableInputDetailColumn hideDivider title={{value: 'Jumlah'}}>
                        {ConvertCurrency(
                          NullProof({
                            input: l,
                            params: 'jumlah',
                            isLabel: false,
                          }) || 0
                        )}
                      </TableInputDetailColumn>
                    </TableInputDetailColumn>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {
  MainWrapper,
  UbahWrapper,
  TambahWrapper,
  PdfWrapper,
  DetailWrapper,
  DetailDprWrapper,
  RevisiWrapper,
}
