/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputStatus} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import {
  ButtonLoading,
  CardUi,
  DropdownUiV2 as DropdownUi,
  ModalButtonUi,
} from '../../../../helper/AppUi'
import {
  TableActionProps,
  TableColumn,
  TableInput,
  TableInputCheckboxDataProps,
  TableRow,
  onLoadProps as onLoadPropsTable,
} from '../../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
import FormInput, {
  BaseInputOnChangeProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {useIntl} from 'react-intl'
import {intlMessage} from '../../../../helper/ReactIntl'
import ReactIcon from '../../../../helper/ReactIcon'
import {TableInputListTemuan} from '../list-temuan/MainWrapper'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'
import {useSelector} from 'react-redux'
import {useAuth} from '../../../../modules/auth'
import Modal from '../../../../helper/Modal'
import {putData} from '../../../../helper/FormAxios'
import Swal from 'sweetalert2'

const MainWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [checkbox, setCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [modal, setModal] = useState<any>([])
  const [modalPopup, setModalPopup] = useState<boolean>(false)

  const [action, setAction] = useState<TableActionProps[]>(TableInputData.action || [])

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {`${intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.breadcrumb.title, intl)}`}
      </PageTitle>
      <CardUi title={intlMessage(`page.${InfoData.id}.title`, PageData.semua.page.title, intl)}>
        <TableInput
          {...TableInputData}
          action={action}
          modal={setModal}
          checkbox={setCheckbox}
          onFeedback={(e) => {
            if (e?.idMenu === 'modal') {
              setModalPopup(true)
            }
          }}
          onSuccess={setData}
          sidebar={
            <>
              <ProtectComponent id='tambah'>
                <button
                  type='button'
                  onClick={() => navigate('./tambah')}
                  className='btn btn-primary d-flex align-items-center gap-1'
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                  {intlMessage(`component.tableinput.button.add`, 'Add', intl)}
                </button>
              </ProtectComponent>
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'lampiran.no_skbt'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    <div>
                      <span>Nama: </span>
                      <span>{NullProof({input: l, params: 'pegawai.nama'})}</span>
                    </div>
                    <div>
                      <span>NIP: </span>
                      <span>{NullProof({input: l, params: 'pegawai.nip'})}</span>
                    </div>
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    <div>
                      <span>Nama: </span>
                      <span>{NullProof({input: l, params: 'pengusul.nama'})}</span>
                    </div>
                    <div>
                      <span>NIP: </span>
                      <span>{NullProof({input: l, params: 'pengusul.nip'})}</span>
                    </div>
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'status'})}
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const onChangeForm = (e: BaseInputOnChangeProps) => {
  const {resetForm, clickId, type} = e
  const data = e?._data.get
  const apiData = e?._apiData?.get
  if (clickId === 'pegawai_id') {
    const nip = apiData?.pegawai_id_detail?.nip
    resetForm({values: {...data, nip: nip}})
  }
}

const UbahWrapper: FC = () => {
  const query = useParams()
  const intl = useIntl()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Edit {page}`, id: `component.infopage.edit_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Pengajuan SKBT', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Add {page}`, id: `component.infopage.add_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Pengajuan SKBT', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
          onChange={onChangeForm}
        />
      </CardUi>
    </>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const infoPage = intl.formatMessage(
    {defaultMessage: `Import Data {page}`, id: `component.infopage.import_page`.toUpperCase()},
    {
      page: intlMessage(`page.${InfoData.id}.title`, 'Pengajuan SKBT', intl),
    }
  )
  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {infoPage}
      </PageTitle>
      <CardUi title={infoPage}>
        <FormInput
          i18n={{baseName: `page.${InfoData.id}`}}
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: false,
            isFeedback: true,
            onFeedback({status, message, data}) {
              console.log(status, message, data)
            },
          }}
          onFeedback={(e) => {
            console.log(e)
          }}
        />
      </CardUi>
    </>
  )
}
const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const intl = useIntl()
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)

  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [modalPenandatangan1, setModalPenandatangan1] = useState<boolean>(false)
  const [modalPenandatangan2, setModalPenandatangan2] = useState<boolean>(false)

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const getAccess = (name: string = '') => {
    const routeList: string[] =
      routeData?.route?.filter((f: string) => f?.includes('pengawasan/pelayanan_skbt/pengajuan')) ||
      []
    const status =
      routeList?.filter((f: string) => f === `pengawasan/pelayanan_skbt/pengajuan${name}`)?.length >
        0 || roleId === 1

    return status
  }
  useEffect(() => {
    getAccess()
  })
  const conditionProtect = (data?: any, user?: any) => {
    const isAdmin = user?.role_id === 1
    const isTerbit = data?.status?.toLowerCase() === 'terbit'
    const isPengusul =
      data?.pengusul_id === user?.pegawai_id || data?.pengusul?.id === user?.pegawai_id
    const isPenandatanganan1 =
      data?.penandatangan1_id === user?.pegawai_id ||
      data?.penandatangan1?.id === user?.pegawai_id ||
      getAccess('/detail/:id')
    const isPenandatanganan1Acc = data?.penandatangan1_status?.toLowerCase() === 'disetujui'
    const isPenandatanganan2 =
      data?.penandatangan2_id === user?.pegawai_id ||
      data?.penandatangan2?.id === user?.pegawai_id ||
      getAccess('/detail/:id')
    const isPenandatanganan2Acc = data?.penandatangan_status?.toLowerCase() === 'disetujui'
    return {
      isAdmin,
      isTerbit,
      isPengusul,
      isPenandatanganan1,
      isPenandatanganan1Acc,
      isPenandatanganan2,
      isPenandatanganan2Acc,
    }
  }

  const {
    isAdmin,
    isTerbit,
    isPengusul,
    isPenandatanganan1,
    isPenandatanganan1Acc,
    isPenandatanganan2,
    isPenandatanganan2Acc,
  } = conditionProtect(data, auth?.data)

  const [loadingPengajuan, setLoadingPengajuan] = useState<boolean>(false)

  const kirimPengajuan = async () => {
    setLoadingPengajuan(true)
    const url = RouterQueryParams(`${InfoData.api}/:id/ubah-status-terbit`, query)
    const result = await putData({status: 'terbit'}, url)
    if (result?.status) {
      setKey((p) => (p += 1))
      Swal.fire({
        icon: 'success',
        title: 'Berhasil mengirim pengajuan.',
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali koneksi anda.',
      })
    }
    setLoadingPengajuan(false)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(PageData.detail?.page.breadcrumb.data, {
          id: InfoData.id,
          intl: intl,
        })}
      >
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <Modal
          id='modal_status'
          isShow={modalStatus}
          onClose={() => {
            setModalStatus(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={[...formInputStatus.slice(0, 1)]}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-terbit`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModalStatus(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_penandatangan1'
          isShow={modalPenandatangan1}
          onClose={() => {
            setModalPenandatangan1(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputStatus}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModalPenandatangan1(false)
              },
            }}
            isModal
          />
        </Modal>
        <Modal
          id='modal_penandatangan2'
          isShow={modalPenandatangan2}
          onClose={() => {
            setModalPenandatangan2(false)
          }}
          title='Ubah Status'
        >
          <FormInput
            input={formInputStatus}
            route={{
              url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
              type: 'put',
              useServerMessage: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  setKey((p) => p + 1)
                }
                setModalPenandatangan1(false)
              },
            }}
            isModal
          />
        </Modal>
        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          toolbars={
            <>
              {(NullProof({input: data, params: 'status'})?.toLowerCase() === 'draft' ||
                NullProof({input: data, params: 'status'}) === '_') && (
                <>
                  <ButtonLoading
                    loading={loadingPengajuan}
                    icon='RiSendPlaneLine'
                    title={{button: 'Kirim Pengajuan SKBT'}}
                    props={{
                      onClick: () => kirimPengajuan(),
                    }}
                  ></ButtonLoading>
                </>
              )}
            </>
          }
        >
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Status'}}>
              <div className='d-flex align-items-center gap-2'>
                {NullProof({input: data, params: 'status'})}
                {/* {((!isTerbit && isPengusul) || isAdmin) && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModalStatus(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )} */}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pengusul'}}>
              {NullProof({input: data, params: 'fullname'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'NIP'}}>
              {NullProof({input: data, params: 'nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Unit Kerja'}}>
              {NullProof({input: data, params: 'unit_kerja'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Provinsi'}}>
              {NullProof({input: data, params: 'provinsi'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Kota'}}>
              {NullProof({input: data, params: 'kota'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Keperluan'}}>
              {NullProof({input: data, params: 'keperluan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nomor Lampiran'}}>
              {NullProof({input: data, params: 'lampiran.no_skbt'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: `Dokumen`}}>
              {NullProof({input: data, params: 'lampiran.document', isLabel: false}) ? (
                <ModalButtonUi
                  options={{
                    modal: {
                      id: `modal_dokumen`,
                      title: `Dokumen`,
                    },
                    button: {title: 'Lihat Dokumen'},
                  }}
                >
                  <ReactPdfViewer
                    url={`/${NullProof({
                      input: data,
                      params: 'lampiran.document',
                      isLabel: false,
                    })}`}
                  />
                </ModalButtonUi>
              ) : (
                '-'
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatangan 1'}}>
              {NullProof({input: data, params: 'penandatangan1.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatanganan 1 Status'}}>
              <div className='d-flex align-items-center gap-2'>
                {NullProof({input: data, params: 'penandatangan1_status'})}
                {((isTerbit && isPenandatanganan1) || isAdmin) && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModalPenandatangan1(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatanganan 1 Catatan'}}>
              {NullProof({input: data, params: 'penandatangan1_catatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatangan 2'}}>
              {NullProof({input: data, params: 'penandatangan2.nama'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatanganan 2 Status'}}>
              <div className='d-flex align-items-center gap-2'>
                {NullProof({input: data, params: 'penandatangan2_status'})}
                {((isTerbit && isPenandatanganan2 && isPenandatanganan1Acc) || isAdmin) && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModalPenandatangan2(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatanganan 2 Catatan'}}>
              {NullProof({input: data, params: 'penandatangan2_catatan'})}
            </TableInputDetailColumn>
          </TableInputDetail>
          <TableInputDetailColumn
            title={{value: 'List Temuan'}}
            hideDivider
          ></TableInputDetailColumn>
          {NullProof({
            input: data,
            params: 'nip',
            isLabel: false,
          }) && (
            <div className='my-4'>
              <TableInputListTemuan
                viewOnly
                link='list_temuan'
                api={`${InfoData.api_temuan_nip}/${NullProof({
                  input: data,
                  params: 'nip',
                  isLabel: false,
                })}`}
              />
            </div>
          )}
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper, ImportWrapper}
