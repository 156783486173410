import {IntlShape, useIntl} from 'react-intl'
import slugify from 'slugify'

const intlMessage = (id: string, defaultValue: string, intl: IntlShape) => {
  const _id = slugify((id || '').toUpperCase(), {
    replacement: '_',
  })
  return intl.formatMessage({id: _id, defaultMessage: defaultValue})
}

export {intlMessage}
