import {createContext, FC, Fragment, ReactNode, useContext, useEffect, useState} from 'react'
import FormInput, {FormInputProps} from './FormInputV2'
import Modal from './Modal'
import * as Yup from 'yup'
import {getListYear} from './DateFns'
import {NullProof} from './AppFunction'
import {WithChildren} from '../../_metronic/helpers'

const RekapModalPdf: FC<
  {
    title?: string
    show: boolean
    view?: 'all' | 'year' | 'month' | 'custom'
    type?: 'all' | 'pdf' | 'excel' | 'custom'
    output?: {
      type: string
      label: string
    }[]
    filter?: FormInputProps[]
    onClose?: () => void
    onSubmit?: (data: any) => void
    onChange?: (e: any) => void
  } & WithChildren
> = ({
  title = 'Rekap PDF',
  show,
  view = 'all',
  type = 'pdf',
  onClose,
  onSubmit,
  onChange,
  children,
  filter = [],
  output = [],
}) => {
  const [modalFile, setModalFile] = useState<{status: boolean; data: any}>({
    data: {},
    status: false,
  })
  const buttonLabel = {
    pdf: 'Download PDF',
    excel: 'Download Excel',
    all: 'Download',
    custom: '',
  }
  const downloadFile = (data: any) => {
    onSubmit && onSubmit(data)
    setModalFile({data: {}, status: false})
  }
  return (
    <>
      <Modal
        className='mw-400px'
        id='modal_rekap_file'
        isShow={modalFile.status}
        onClose={() => {
          setModalFile({data: {}, status: false})
        }}
        title='Pilih File'
      >
        <div className='d-flex gap-4 justify-content-center'>
          {output ? (
            output.map((l, i) => (
              <button
                key={i}
                className='btn btn-primary'
                onClick={() => downloadFile({...modalFile.data, type: l.type})}
              >
                {l.label}
              </button>
            ))
          ) : (
            <>
              <button
                className='btn btn-primary'
                onClick={() => downloadFile({...modalFile.data, type: 'pdf'})}
              >
                Download PDF
              </button>
              <button
                className='btn btn-primary'
                onClick={() => downloadFile({...modalFile.data, type: 'excel'})}
              >
                Download Excel
              </button>
            </>
          )}
        </div>
      </Modal>
      <Modal id='modal_rekap' isShow={show} onClose={onClose} title={title}>
        <div className='mx-5 my-4'>{children}</div>
        <div className='d-flex flex-column gap-4'>
          {view === 'custom' && (
            <div>
              <FormInput
                useToast={false}
                onChange={onChange}
                input={filter}
                route={{
                  url: '',
                  type: 'post',
                  isFeedback: true,
                  onFeedback: (e: any) => {
                    if (e?.status) {
                      let data = {...e?.data, type}
                      if (type !== 'all') {
                        downloadFile(data)
                      } else {
                        if (output) {
                          if (output.length > 1) {
                            setModalFile({data: data, status: true})
                          } else {
                            data = {...data, type: output[0].type}
                            downloadFile(data)
                          }
                        } else {
                          setModalFile({data: data, status: true})
                        }
                      }
                    }
                  },
                  resetOnFeedback: false,
                }}
                options={{
                  actionButton: {cancel: {hide: true}, submit: {title: buttonLabel[type]}},
                }}
                isModal
              />
            </div>
          )}
          {(view === 'all' || view === 'year') && (
            <div>
              <div className='fs-5 fw-bold mx-5'>Rekap Tahunan</div>
              <FormInput
                useToast={false}
                onChange={onChange}
                input={[
                  {
                    name: 'year',
                    type: 'select',
                    className: 'col-12',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    listData: getListYear(),
                    options: {
                      input: {
                        props: {
                          title: 'Tahun',
                          placeholder: 'Tahun',
                        },
                      },
                    },
                  },
                ]}
                route={{
                  url: '',
                  type: 'post',
                  isFeedback: true,
                  onFeedback: (e: any) => {
                    if (e?.status) {
                      const data = {...e?.data, type}
                      if (type !== 'all') {
                        downloadFile(data)
                      } else {
                        setModalFile({data: data, status: true})
                      }
                    }
                  },
                  resetOnFeedback: false,
                }}
                options={{
                  actionButton: {cancel: {hide: true}, submit: {title: buttonLabel[type]}},
                }}
                isModal
              />
            </div>
          )}
          {(view === 'all' || view === 'month') && (
            <div>
              <div className='fs-5 fw-bold mx-5'>Rekap Bulanan</div>
              <FormInput
                useToast={false}
                onChange={(e) => {
                  onChange && onChange(e)
                  const start = new Date(NullProof({input: e?._data.get, params: 'start_date'}))
                  const end = new Date(NullProof({input: e?._data.get, params: 'end_date'}))
                  if (start > end) {
                    e.setError({
                      field: 'end_date',
                      message: 'Tanggal Selesai harus diatas Tanggal Mulai',
                    })
                  } else {
                    e.setError({field: 'end_date', message: ''})
                  }
                }}
                input={[
                  {
                    name: 'start_date',
                    type: 'input',
                    className: 'col-12 col-md-6',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        isDate: true,
                        props: {
                          title: 'Tanggal Mulai',
                          placeholder: 'Tanggal Mulai',
                          type: 'date',
                        },
                      },
                    },
                  },
                  {
                    name: 'end_date',
                    type: 'input',
                    className: 'col-12 col-md-6',
                    validator: Yup.string().required('Mohon untuk diisi.'),
                    options: {
                      input: {
                        isDate: true,
                        props: {
                          title: 'Tanggal Selesai',
                          placeholder: 'Tanggal Selesai',
                          type: 'date',
                        },
                      },
                    },
                  },
                ]}
                route={{
                  url: '',
                  type: 'post',
                  isFeedback: true,
                  onFeedback: (e: any) => {
                    if (e?.status) {
                      const data = {...e?.data, type}
                      if (type !== 'all') {
                        downloadFile(data)
                      } else {
                        setModalFile({data: data, status: true})
                      }
                    }
                  },
                  resetOnFeedback: false,
                }}
                options={{
                  actionButton: {cancel: {hide: true}, submit: {title: buttonLabel[type]}},
                }}
                isModal
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

interface FormPaginationProps {
  page?: number
  layout?: {
    children: ReactNode
  }[]
  onPageChange?: ({
    page,
    commit,
  }: {
    type: 'prev' | 'next'
    page: number
    commit: (page?: number) => void
  }) => void
}

interface FormPaginationContextModel {
  page?: number
  setPage: (page: number) => void
  commit: (page?: number) => void
}

const FormPaginationContext = createContext<FormPaginationContextModel>({
  commit(page?: number) {},
  setPage(page: number) {},
})

const FormPaginationProvider: FC<WithChildren> = ({children}) => {
  const [page, setPage] = useState(1)
  const commit = (e?: number) => {
    setPage(e || page)
  }
  return (
    <FormPaginationContext.Provider value={{page, setPage, commit}}>
      {children}
    </FormPaginationContext.Provider>
  )
}

export const useFormPagination = () => {
  return useContext(FormPaginationContext)
}

const FormPagination: FC<FormPaginationProps> = ({layout, onPageChange, page}) => {
  const [infoPage, setInfoPage] = useState<any>({
    page: 1,
    total: 1,
  })
  useEffect(() => {
    setInfoPage((p: any) => ({
      ...p,
      total: layout?.length || 1,
    }))
  }, [layout])

  useEffect(() => {
    setInfoPage((p: any) => ({
      ...p,
      page: page || 1,
    }))
  }, [page])
  return (
    <>
      {layout?.map((l: any, i: number) => (
        <Fragment key={i}>{i === infoPage.page - 1 && <div>{l.children}</div>}</Fragment>
      ))}
      <div className='d-flex justify-content-end gap-4 align-items-center justify-content-center mt-4'>
        <button
          className='btn btn-primary'
          type='button'
          disabled={infoPage.page === 1}
          onClick={() => {
            const commit = (e?: number) => {
              setInfoPage((p: any) => ({...p, page: e || p.page - 1}))
            }
            if (onPageChange) {
              onPageChange({
                type: 'prev',
                page: infoPage.page,
                commit: commit,
              })
            } else {
              commit()
            }
          }}
        >
          Sebelumnya
        </button>
        <div className='w-100 d-flex flex-column gap-2 align-items-center'>
          <div>
            Halaman {infoPage.page} dari {infoPage.total}
          </div>
          <div className='w-100'>
            <div
              className='progress h-10px'
              role='progressbar'
              aria-label='Basic example'
              aria-valuenow={0}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <div
                className='progress-bar'
                style={{width: `${Math.round((infoPage.page / infoPage.total) * 100)}%`}}
              ></div>
            </div>
          </div>
        </div>
        <button
          className='btn btn-primary'
          type='button'
          onClick={() => {
            const commit = (e?: number) => {
              setInfoPage((p: any) => ({...p, page: e || p.page + 1}))
            }
            if (infoPage.page === infoPage.total) {
              if (onPageChange) {
                onPageChange({
                  type: 'next',
                  page: infoPage.page,
                  commit: commit,
                })
              }
            } else {
              if (onPageChange) {
                onPageChange({
                  type: 'next',
                  page: infoPage.page,
                  commit: commit,
                })
              } else {
                commit()
              }
            }
          }}
        >
          {infoPage.page === infoPage.total ? 'Selesai' : 'Berikutnya'}
        </button>
      </div>
    </>
  )
}

export {RekapModalPdf, FormPagination}
