import {isWithinInterval, parseISO} from 'date-fns'
import {NullProof, capitalizeEachWord} from './AppFunction'
import {getData} from './FormAxios'
import {BaseInputOnChangeProps} from './FormInput'
import {hasAccessRoutes} from './UserRolesValidation'

export const groupNaskah = ({
  e,
  groupId,
  valueId,
}: {
  e: BaseInputOnChangeProps
  groupId: string
  valueId: string
}) => {
  if (e.type === 'onChange' && e.clickId === groupId) {
    const api = e._apiData.get
    const grup = NullProof({input: api, params: `${groupId}_detail`})
    const listTujuanList: any[] = NullProof({
      input: grup,
      params: 'list_tujuan',
      isMap: true,
    })

    const getTujuan = async () => {
      const status: boolean[] = []
      const list: any[] = []
      const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms))
      const loadTujuan = async (id: string) => {
        const result = await getData('', `/kepegawaian/data-tenaga-audit/${id}`)
        if (result?.status) {
          list.push({
            pegawai: {
              nama: result?.data?.nama,
            },
            pegawai_id: result?.data?.id,
          })
          status.push(true)
        }
      }
      if (listTujuanList?.length > 0) {
        listTujuanList.forEach((l) => {
          loadTujuan(l?.pegawai_id)
        })
      }
      while (listTujuanList.length !== list.length) {
        await delay(1000)
      }
      if (status.every((e) => e === true)) {
        const json: any = {[valueId]: list}
        e.resetForm({values: json})
      }
    }
    getTujuan()
  }
}

const filterJabatanAnggota = ({
  data,
  jabatan,
  anggota,
}: {
  jabatan: string[]
  data: any[]
  anggota: any
}) => {
  let infoAnggota: any = {}
  let ketuaList: any[] = []
  let anggotaList: any[] = []
  let posisiSemuaKetua: any[] = []
  let adaPilihKetua: boolean = false
  const result = [...data]
  result?.map((l, i) => {
    if (l?.pegawai_id === anggota?.pegawai_id) {
      infoAnggota = {
        index: i,
        data: l,
      }
    }
    if (l?.jabatan?.includes('Ketua')) {
      posisiSemuaKetua.push({
        index: i,
        data: l,
      })
    }
  })
  if (anggota?.jabatan === 'Anggota') {
    let pilihKetua = {}
    posisiSemuaKetua.map((l, i) => {
      if (l?.data?.jabatan === 'Ketua Tim' && l?.index < infoAnggota?.index) {
        pilihKetua = l?.data
        adaPilihKetua = true
      }
    })
    if (adaPilihKetua) {
      ketuaList = [...ketuaList, pilihKetua]
    }
    // } else if (anggota?.jabatan === 'Sub Anggota') {
    //   let pilihKetua = {}
    //   posisiSemuaKetua.map((l, i) => {
    //     if (l?.data?.jabatan === 'Ketua Sub Tim' && l?.index < infoAnggota?.index) {
    //       pilihKetua = l?.data
    //     }
    //   })
    //   ketuaList = [...ketuaList, pilihKetua]
  }
  if (!adaPilihKetua) {
    ketuaList = [
      ...ketuaList,
      ...result.filter(
        (l, i) => jabatan.includes(l?.jabatan) && l?.pegawai_id === anggota?.pegawai_id
      ),
    ]
    if (ketuaList.length === 0) {
      ketuaList = [...ketuaList, infoAnggota?.data]
    }
  }
  let ketuaTim = result.filter((l, i) => jabatan.includes(l?.jabatan))
  anggotaList = result.filter(
    // (item1) => !ketuaList.some((item2) => item1?.jabatan === item2?.jabatan)
    (item1) => !jabatan.includes(item1?.jabatan)
  )
  // console.log(ketuaList)
  // console.log(anggotaList)
  // console.log(ketuaTim)
  return {
    anggota: anggotaList,
    ketua: ketuaList,
    ketuaTim: ketuaTim,
  }
}

const UrutkanAnggotaSt = (data: any[]) => {
  let init = [...data]
  let listJabatan = [
    'Penanggung Jawab',
    'Wakil Penanggung Jawab',
    'Supervisor',
    'Pengendali Mutu',
    'Pengendali Teknis',
    'Ketua Tim',
  ]
  init.sort((a: any, b: any) => {
    const jabatanA: string = a?.jabatan || ''
    const jabatanB: string = b?.jabatan || ''
    const urutanA: number = a?.urutan || 0
    const urutanB: number = b?.urutan || 0

    // Sort by listJabatan order first
    const indexA = listJabatan.indexOf(jabatanA)
    const indexB = listJabatan.indexOf(jabatanB)

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }
    if (indexA !== -1) return -1
    if (indexB !== -1) return 1

    // Place 'Anggota' immediately after listJabatan
    if (jabatanA === 'Anggota' && jabatanB !== 'Anggota') return -1
    if (jabatanB === 'Anggota' && jabatanA !== 'Anggota') return 1

    // Sort 'Ketua Sub Tim' and 'Anggota Sub Tim' by urutan, with 'Ketua Sub Tim' before 'Anggota Sub Tim' for same urutan
    if (jabatanA === 'Ketua Sub Tim' && jabatanB === 'Anggota Sub Tim' && urutanA === urutanB)
      return -1
    if (jabatanB === 'Ketua Sub Tim' && jabatanA === 'Anggota Sub Tim' && urutanA === urutanB)
      return 1

    if (jabatanA === 'Ketua Sub Tim' || jabatanA === 'Anggota Sub Tim') {
      if (jabatanB === 'Ketua Sub Tim' || jabatanB === 'Anggota Sub Tim') {
        return urutanA - urutanB // Sort by urutan within 'Ketua Sub Tim' and 'Anggota Sub Tim'
      }
      return -1
    }

    if (jabatanB === 'Ketua Sub Tim' || jabatanB === 'Anggota Sub Tim') return 1

    // Default sorting by urutan for 'Anggota'
    return 1
  })

  return init
}

function getObjectDifferences(original?: any, revisi?: any): any[] {
  const differingProperties: any[] = []

  const listObject = {...original}
  delete listObject.list_revisi

  for (const l of Object.keys(listObject)) {
    const pushData = () => {
      differingProperties.push({id: l, original: original[l], revisi: revisi[l]})
    }
    if (NullProof({input: original, params: l}) !== NullProof({input: revisi, params: l})) {
      if (Array.isArray(original[l]) && typeof original[l] === 'object') {
        if (original[l]?.length !== revisi[l]?.length) {
          pushData()
        }
      } else if (
        !Array.isArray(original[l]) &&
        typeof original[l] === 'object' &&
        original[l] !== null
      ) {
        const result = getObjectDifferences(original[l] || {}, revisi[l] || {})
        result.length > 0 && pushData()
      } else if (original[l] === null) {
        pushData()
      } else {
        pushData()
      }
    }
  }

  return differingProperties
}

const statusKepegawaianListData = [
  {
    title: 'PNS',
    value: 'PNS',
  },
  {
    title: 'PPPK',
    value: 'PPPK',
  },
  {
    title: 'PPNPN',
    value: 'PPNPN',
  },
  {
    title: 'CPNS',
    value: 'CPNS',
  },
]

const filterCapitalizeProvinsi = (provinsi: string) => {
  const listProvinsi = ['DKI', 'D.I', 'UIN']
  if (listProvinsi.some((e) => provinsi.toLowerCase().indexOf(e.toLowerCase()) > -1)) {
    const words = provinsi.split(' ')
    words[1] = capitalizeEachWord(words[1])
    return words.join(' ')
  } else {
    return capitalizeEachWord(provinsi)
  }
}

const checkProtect = ({
  search,
  value,
  data,
  isSuperAdmin,
}: {
  data: any[]
  search: string
  value: string
  isSuperAdmin?: boolean
}) => {
  return hasAccessRoutes(data, search, isSuperAdmin, true) ? value : ''
}

const checkDateRanges = ({
  data,
  end = 'end',
  start = 'start',
}: {
  data: any[]
  start?: string
  end?: string
}) => {
  let status = false
  for (let i = 0; i < data?.length; i++) {
    const range1Start = parseISO(data[i][start])
    const range1End = parseISO(data[i][end])
    for (let j = i + 1; j < data.length; j++) {
      const range2Start = parseISO(data[j][start])
      const range2End = parseISO(data[j][end])
      if (
        isWithinInterval(range1Start, {start: range2Start, end: range2End}) ||
        isWithinInterval(range1End, {start: range2Start, end: range2End}) ||
        isWithinInterval(range2Start, {start: range1Start, end: range1End}) ||
        isWithinInterval(range2End, {start: range1Start, end: range1End})
      ) {
        status = true
      }
    }
  }
  return status
}

export {
  UrutkanAnggotaSt,
  getObjectDifferences,
  statusKepegawaianListData,
  filterJabatanAnggota,
  filterCapitalizeProvinsi,
  checkProtect,
  checkDateRanges,
}
