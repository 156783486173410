import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInput'
import {inputList as inputListDasar} from '../data-tambahan/dasar-usulan/InputLayout'
import {formInputList as inputListKategori} from '../data-tambahan/kategori-kegiatan/InputLayout'
import inputLayoutBuilder from '../../../../helper/template/FormInputTemplate'
// import { UsulanWrapper } from './MainWrapper'

const filterInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'status'}],
  }),
]

const inputListJabatan: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'jabatan',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Jabatan is required'),
    options: {
      input: {
        props: {
          title: 'Jabatan',
          placeholder: 'Jabatan',
        },
      },
      datalist: {
        api: '/kepegawaian/data-jabatan',
        params: 'fungsional',
        result: '$fungsional',
        id: 'fungsional',
      },
    },
  },
]

const inputListSatker: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'kode',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode is required'),
    options: {
      input: {
        props: {
          title: 'Kode',
          placeholder: 'Kode',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'kode_atasan',
    type: 'input',
    value: '',
    validator: Yup.string().required('Kode Atasan is required'),
    options: {
      input: {
        props: {
          title: 'Kode Atasan',
          placeholder: 'Kode Atasan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'nama_satker',
    type: 'input',
    value: '',
    validator: Yup.string().required('Nama Satker is required'),
    options: {
      input: {
        props: {
          title: 'Nama Satker',
          placeholder: 'Nama Satker',
        },
      },
    },
  },
]

const inputListAdjusment: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'usulan_tim',
    type: 'component',
    value: '',
    validator: Yup.number().min(0, 'Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Usulan Tim',
          placeholder: 'Usulan Tim',
        },
      },
    },
    lockData: true,
    component: [
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_mulai',
        type: 'input',
        value: '',
        hide: true,
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Mulai',
              type: 'date',
              placeholder: 'Tanggal Mulai',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_selesai',
        type: 'input',
        value: '',
        hide: true,
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Selesai',
              type: 'date',
              placeholder: 'Tanggal Selesai',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'kepada',
        type: 'component',
        value: '',
        hide: true,
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Anggota Tim',
              placeholder: 'Anggota Tim',
            },
          },
        },
        component: [
          {
            className: 'col-12',
            name: 'pegawai_id',
            type: 'datalist',
            value: '',
            hide: true,
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Pegawai',
                  placeholder: 'Nama Pegawai',
                },
              },
              datalist: {
                api: '/kepegawaian/data-tenaga-audit',
                query: 'orderby=asc',
                params: 'nama,nip',
                result: (
                  <>
                    <div>
                      <span className='fw-bold'>Nama : </span>
                      <span>$nama</span>
                    </div>
                    <div>
                      <span className='fw-bold'>NIP : </span>
                      <span>$nip</span>
                    </div>
                  </>
                ),
                id: 'id',
                type: 'table',
                table: {
                  data: [
                    {id: 'nama', type: 'text'},
                    {id: 'nip', type: 'text'},
                    {id: 'level_jabatan', type: 'text'},
                    {id: 'satker_4', type: 'text'},
                    {id: 'totalSt', type: 'text'},
                    {id: 'jumlahST', type: 'text'},
                    {id: 'jumlahUsulan', type: 'text'},
                  ],
                  protect: '',
                  props: {
                    filter: [
                      ...inputLayoutBuilder({
                        input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
                      }),
                    ],
                    filterData: {
                      irjen: true,
                    },
                    route: {
                      url: '/kepegawaian/data-tenaga-audit',
                    },
                    headerData: [
                      {title: 'No.', props: {className: 'min-w-50px'}},
                      {title: 'Nama'},
                      {title: 'NIP'},
                      {title: 'Level Jabatan'},
                      {title: 'Satker'},
                      {title: 'Jumlah ST'},
                      {title: 'Jumlah ST (hari)'},
                      {title: 'Jumlah Usulan'},
                    ],
                    link: {
                      action: [
                        {
                          id: 'detail',
                          protect: 'st_pegawai',
                          title: 'Detail List ST Terbit',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                        {
                          id: 'detail',
                          protect: 'usulan_pegawai',
                          title: 'Detail List Usulan',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'jabatan',
            type: 'datalist',
            value: '',
            hide: true,
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Jabatan',
                  placeholder: 'Jabatan',
                },
              },
              datalist: {
                id: 'jabatan',
                api: '/pengawasan_internal/jabatan',
                params: 'jabatan',
                result: `$jabatan`,
                type: 'table',
                table: {
                  protect: '/pengawasan/persiapan/data_tambahan/jabatan',
                  data: [
                    {
                      id: 'jabatan',
                      type: 'text',
                    },
                  ],
                  props: {
                    route: {
                      url: '/pengawasan_internal/jabatan',
                    },
                    headerData: [
                      {
                        title: 'No.',
                        props: {className: 'min-w-50px'},
                      },
                      {
                        title: 'Jabatan',
                      },
                    ],
                    link: {
                      tambah: {
                        id: 'tambah',
                        protect: 'tambah',
                        route: '',
                        title: 'Tambah',
                        type: 'feedback',
                        input: inputListJabatan,
                      },
                      action: [
                        {
                          id: 'ubah',
                          protect: 'ubah/:id',
                          title: 'Ubah',
                          route: 'ubah/:id',
                          type: 'feedback',
                          input: inputListJabatan,
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'hari',
            type: 'input',
            value: '0',
            hide: true,
            validator: Yup.number().required('Mohon untuk diisi.').min(0, 'Minimal 0 Hari'),
            options: {
              input: {
                props: {
                  title: 'Hari',
                  placeholder: 'Hari',
                  min: 0,
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'urutan',
            type: 'select',
            listData: [
              {title: '1', value: 1},
              {title: '2', value: 2},
              {title: '3', value: 3},
              {title: '4', value: 4},
              {title: '5', value: 5},
              {title: '6', value: 6},
              {title: '7', value: 7},
              {title: '8', value: 8},
              {title: '9', value: 9},
              {title: '10', value: 10},
              {title: '11', value: 11},
              {title: '12', value: 12},
              {title: '13', value: 13},
              {title: '14', value: 14},
              {title: '15', value: 15},
              {title: '16', value: 16},
              {title: '17', value: 17},
              {title: '18', value: 18},
              {title: '19', value: 19},
              {title: '20', value: 20},
              {title: '21', value: 21},
              {title: '22', value: 22},
              {title: '23', value: 23},
              {title: '24', value: 24},
              {title: '25', value: 25},
              {title: '26', value: 26},
              {title: '27', value: 27},
              {title: '28', value: 28},
              {title: '29', value: 29},
              {title: '30', value: 30},
            ],
            hide: true,
            validator: Yup.number(),
            options: {
              input: {
                props: {
                  title: 'Urutan',
                  placeholder: 'Urutan',
                  type: 'number',
                  min: 1,
                },
              },
              label: {
                description: '*Khusus Sub Tim',
              },
            },
          },
          {
            className: 'col-12',
            name: 'nama_pegawai',
            disabled: true,
            type: 'input',
            value: '',
            validator: Yup.string(),
            removeOnSubmit: true,
            options: {
              input: {
                props: {
                  title: 'Nama Pegawai',
                  placeholder: 'Nama Pegawai',
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'uang_harian',
            type: 'input',
            value: '',
            validator: Yup.number().min(0, 'Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Uang Harian',
                  placeholder: 'Uang Harian',
                  type: 'number',
                  min: 0,
                },
                isCurrency: true,
              },
            },
          },
          {
            className: 'col-12',
            name: 'uang_transportasi',
            type: 'input',
            value: '',
            validator: Yup.number().min(0, 'Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Uang Transport',
                  placeholder: 'Uang Transport',
                  type: 'number',
                  min: 0,
                },
                isCurrency: true,
              },
            },
          },
          {
            className: 'col-12',
            name: 'uang_penginapan',
            type: 'input',
            value: '',
            validator: Yup.number().min(0, 'Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Uang Penginapan',
                  placeholder: 'Uang Penginapan',
                  type: 'number',
                  min: 0,
                },
                isCurrency: true,
              },
            },
          },
          {
            className: 'col-12',
            name: 'bst_full',
            type: 'input',
            value: '',
            validator: Yup.number().min(0, 'Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'BST PP',
                  placeholder: 'BST PP',
                  type: 'number',
                  min: 0,
                },
                isCurrency: true,
              },
            },
          },
        ],
      },
      {
        className: 'col-12',
        name: 'text',
        type: 'label',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          label: {
            name: 'Tujuan',
            labelType: 'both',
          },
        },
      },
      {
        className: 'col-12',
        name: 'tipe_tujuan',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Dalam negeri',
            value: 'Dalam negeri',
          },
          {
            title: 'Luar negeri',
            value: 'Luar negeri',
          },
        ],
        hide: true,
        validator: Yup.string().required('Tipe Tujuan wajib diisi'),
        options: {
          select: {
            props: {
              title: 'Tipe Tujuan',
              placeholder: 'Tipe Tujuan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'provinsi',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string().required('Provinsi is required'),
        options: {
          input: {
            props: {
              title: 'Provinsi',
              placeholder: 'Provinsi',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_provinsi',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_provinsi',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                  ],
                },
              },
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        hide: true,
        validator: Yup.string().required('Jenis Tujuan wajib diisi'),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan',
              placeholder: 'Jenis Tujuan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string().required('Kota is required'),
        options: {
          input: {
            props: {
              title: 'Kota',
              placeholder: 'Kota',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            query: 'provinsi=$provinsi',
            useOnLoad: true,
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'negara',
        type: 'input',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Negara',
              placeholder: 'Negara',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string().required('Unit is required'),
        options: {
          input: {
            props: {
              title: 'Unit',
              placeholder: 'Unit',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan=$jenis_tujuan',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'ubah',
                      protect: 'ubah/:id',
                      title: 'Ubah',
                      route: 'ubah/:id',
                      type: 'feedback',
                      input: inputListSatker,
                    },
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan_2',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        hide: true,
        validator: Yup.string(),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan 2',
              placeholder: 'Jenis Tujuan 2',
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota2',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Kota 2',
              placeholder: 'Kota 2',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            useOnLoad: true,
            query: 'provinsi=$provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id_2',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Unit 2',
              placeholder: 'Unit 2',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan_2=$jenis_tujuan_2',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan_2',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan_3',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        hide: true,
        validator: Yup.string(),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan 3',
              placeholder: 'Jenis Tujuan 3',
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota3',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Kota 3',
              placeholder: 'Kota 3',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            useOnLoad: true,
            query: 'provinsi=$provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id_3',
        type: 'datalist',
        value: '',
        hide: true,
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Unit 3',
              placeholder: 'Unit 3',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan_3=$jenis_tujuan_3',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan_3',
          },
        },
      },
    ],
  },
]

const inputListEnd: FormInputProps[] = [
  // {
  //   className: 'col-12',
  //   name: 'file_pendukung',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'File Pendukung',
  //         placeholder: 'File Pendukung',
  //         accept: '.pdf, .jpg, .jpeg, .png',
  //         type: 'file',
  //       },
  //     },
  //     upload: {
  //       url: {
  //         folder: 'dokumen',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'file_pendukung_keterangan',
  //   type: 'textarea',
  //   value: '',
  //   validator: Yup.string(),
  //   options: {
  //     textarea: {
  //       props: {
  //         title: 'Keterangan File Pendukung',
  //         placeholder: 'Keterangan File Pendukung',
  //         rows: 3,
  //       },
  //     },
  //   },
  // },
  {
    className: 'col-12',
    name: 'file_pendukung_json',
    type: 'component',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'File Pendukung',
          placeholder: 'File Pendukung',
        },
      },
      component: {},
    },
    component: [
      {
        className: 'col-12',
        name: 'file_pendukung',
        type: 'input',
        value: '',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'File Pendukung',
              placeholder: 'File Pendukung',
              accept: '.pdf, .jpg, .jpeg, .png',
              type: 'file',
            },
          },
          upload: {
            url: {
              folder: 'dokumen',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'file_pendukung_keterangan',
        type: 'textarea',
        value: '',
        validator: Yup.string(),
        options: {
          textarea: {
            props: {
              title: 'Keterangan File Pendukung',
              placeholder: 'Keterangan File Pendukung',
              rows: 3,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'nama_kegiatan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Kegiatan',
          placeholder: 'Kegiatan',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'deskripsi_kegiatan',
    type: 'textarea',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      textarea: {
        props: {
          title: 'Deskripsi Kegiatan',
          placeholder: 'Deskripsi Kegiatan',
          rows: 3,
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'menimbang',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Menimbang',
          placeholder: 'Menimbang',
        },
      },
      component: {},
    },
    component: [
      {
        className: 'col-12',
        name: 'detail_base',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
            },
          },
          datalist: {
            id: 'id',
            api: '/pengawasan_internal/menimbang',
            params: 'detail',
            result: `$detail`,
            type: 'table',
            table: {
              protect: '/pengawasan/persiapan/data_tambahan/menimbang',
              data: [
                {
                  id: 'detail',
                  type: 'text',
                },
              ],
              props: {
                route: {
                  url: '/pengawasan_internal/menimbang',
                },
                headerData: [
                  {
                    title: 'No.',
                    props: {className: 'min-w-50px'},
                  },
                  {
                    title: 'Detail',
                  },
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListDasar,
                  },
                  action: [
                    {
                      id: 'ubah',
                      protect: 'ubah/:id',
                      title: 'Ubah',
                      route: 'ubah/:id',
                      type: 'feedback',
                      input: inputListDasar,
                    },
                  ],
                },
              },
            },
          },
          label: {
            hideLabel: true,
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'dasar_base',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Dasar',
          placeholder: 'Dasar',
        },
      },
      component: {
        // useOrderNumber: true,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'detail_base',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
            },
          },
          datalist: {
            id: 'id',
            api: '/pengawasan_internal/dasar-usulan',
            params: 'detail',
            result: `$detail`,
            type: 'table',
            table: {
              protect: '/pengawasan/persiapan/data_tambahan/dasar_usulan',
              data: [
                {
                  id: 'detail',
                  type: 'text',
                },
              ],
              props: {
                route: {
                  url: '/pengawasan_internal/dasar-usulan',
                },
                headerData: [
                  {
                    title: 'No.',
                    props: {className: 'min-w-50px'},
                  },
                  {
                    title: 'Detail',
                  },
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListDasar,
                  },
                  action: [
                    {
                      id: 'ubah',
                      protect: 'ubah/:id',
                      title: 'Ubah',
                      route: 'ubah/:id',
                      type: 'feedback',
                      input: inputListDasar,
                    },
                  ],
                },
              },
            },
          },
          label: {
            hideLabel: true,
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'usulan_tim',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Usulan Tim',
          placeholder: 'Usulan Tim',
        },
      },
    },
    component: [
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_mulai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Mulai',
              type: 'date',
              placeholder: 'Tanggal Mulai',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'tanggal_selesai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Selesai',
              type: 'date',
              placeholder: 'Tanggal Selesai',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'kepada',
        type: 'component',
        value: '',
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Anggota Tim',
              placeholder: 'Anggota Tim',
            },
          },
        },
        component: [
          {
            className: 'col-12',
            name: 'pegawai_id',
            type: 'datalist',
            value: '',
            validator: Yup.number().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama Pegawai',
                  placeholder: 'Nama Pegawai',
                },
              },
              datalist: {
                api: '/kepegawaian/data-tenaga-audit',
                query: 'orderby=asc',
                params: 'nama,nip',
                result: (
                  <>
                    <div>
                      <span className='fw-bold'>Nama : </span>
                      <span>$nama</span>
                    </div>
                    <div>
                      <span className='fw-bold'>NIP : </span>
                      <span>$nip</span>
                    </div>
                  </>
                ),
                id: 'id',
                type: 'table',
                table: {
                  data: [
                    {id: 'nama', type: 'text'},
                    {id: 'nip', type: 'text'},
                    {id: 'level_jabatan', type: 'text'},
                    {id: 'satker_4', type: 'text'},
                    {id: 'totalSt', type: 'text'},
                    {id: 'jumlahST', type: 'text'},
                    {id: 'jumlahUsulan', type: 'text'},
                  ],
                  protect: '',
                  props: {
                    filter: [
                      ...inputLayoutBuilder({
                        input: [{template: 'checkbox', name: 'irjen', title: 'Pegawai Itjen'}],
                      }),
                    ],
                    filterData: {
                      irjen: true,
                    },
                    route: {
                      url: '/kepegawaian/data-tenaga-audit',
                    },
                    headerData: [
                      {title: 'No.', props: {className: 'min-w-50px'}},
                      {title: 'Nama'},
                      {title: 'NIP'},
                      {title: 'Level Jabatan'},
                      {title: 'Satker'},
                      {title: 'Jumlah ST'},
                      {title: 'Jumlah ST (hari)'},
                      {title: 'Jumlah Usulan'},
                    ],
                    link: {
                      action: [
                        {
                          id: 'detail',
                          protect: 'st_pegawai',
                          title: 'Detail List ST Terbit',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                        {
                          id: 'detail',
                          protect: 'usulan_pegawai',
                          title: 'Detail List Usulan',
                          route: 'detail/:id',
                          type: 'feedback',
                          // input: inputListKategori
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'jabatan',
            type: 'datalist',
            value: '',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Jabatan',
                  placeholder: 'Jabatan',
                },
              },
              datalist: {
                id: 'jabatan',
                api: '/pengawasan_internal/jabatan',
                params: 'jabatan',
                result: `$jabatan`,
                type: 'table',
                table: {
                  protect: '/pengawasan/persiapan/data_tambahan/jabatan',
                  data: [
                    {
                      id: 'jabatan',
                      type: 'text',
                    },
                  ],
                  props: {
                    route: {
                      url: '/pengawasan_internal/jabatan',
                    },
                    headerData: [
                      {
                        title: 'No.',
                        props: {className: 'min-w-50px'},
                      },
                      {
                        title: 'Jabatan',
                      },
                    ],
                    link: {
                      tambah: {
                        id: 'tambah',
                        protect: 'tambah',
                        route: '',
                        title: 'Tambah',
                        type: 'feedback',
                        input: inputListJabatan,
                      },
                      action: [
                        {
                          id: 'ubah',
                          protect: 'ubah/:id',
                          title: 'Ubah',
                          route: 'ubah/:id',
                          type: 'feedback',
                          input: inputListJabatan,
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'hari',
            type: 'input',
            value: '0',
            validator: Yup.number().required('Mohon untuk diisi.').min(0, 'Minimal 0 Hari'),
            options: {
              input: {
                props: {
                  title: 'Hari',
                  placeholder: 'Hari',
                  min: 0,
                },
              },
            },
          },
          {
            className: 'col-12',
            name: 'urutan',
            type: 'select',
            listData: [
              {title: '1', value: 1},
              {title: '2', value: 2},
              {title: '3', value: 3},
              {title: '4', value: 4},
              {title: '5', value: 5},
              {title: '6', value: 6},
              {title: '7', value: 7},
              {title: '8', value: 8},
              {title: '9', value: 9},
              {title: '10', value: 10},
              {title: '11', value: 11},
              {title: '12', value: 12},
              {title: '13', value: 13},
              {title: '14', value: 14},
              {title: '15', value: 15},
              {title: '16', value: 16},
              {title: '17', value: 17},
              {title: '18', value: 18},
              {title: '19', value: 19},
              {title: '20', value: 20},
              {title: '21', value: 21},
              {title: '22', value: 22},
              {title: '23', value: 23},
              {title: '24', value: 24},
              {title: '25', value: 25},
              {title: '26', value: 26},
              {title: '27', value: 27},
              {title: '28', value: 28},
              {title: '29', value: 29},
              {title: '30', value: 30},
            ],
            validator: Yup.number(),
            options: {
              input: {
                props: {
                  title: 'Urutan',
                  placeholder: 'Urutan',
                  type: 'number',
                  min: 1,
                },
              },
              label: {
                description: '*Khusus Sub Tim',
              },
            },
          },
        ],
      },
      {
        className: 'col-12',
        name: 'text',
        type: 'label',
        value: '',
        validator: Yup.string(),
        options: {
          label: {
            name: 'Tujuan',
            labelType: 'both',
          },
        },
      },
      {
        className: 'col-12',
        name: 'tipe_tujuan',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Dalam negeri',
            value: 'Dalam negeri',
          },
          {
            title: 'Luar negeri',
            value: 'Luar negeri',
          },
        ],
        validator: Yup.string().required('Tipe Tujuan wajib diisi'),
        options: {
          select: {
            props: {
              title: 'Tipe Tujuan',
              placeholder: 'Tipe Tujuan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'provinsi',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Provinsi is required'),
        options: {
          input: {
            props: {
              title: 'Provinsi',
              placeholder: 'Provinsi',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_provinsi',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_provinsi',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                  ],
                },
              },
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        validator: Yup.string().required('Jenis Tujuan wajib diisi'),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan',
              placeholder: 'Jenis Tujuan',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Kota is required'),
        options: {
          input: {
            props: {
              title: 'Kota',
              placeholder: 'Kota',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            query: 'provinsi=$provinsi',
            useOnLoad: true,
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                      // input: inputListKategori
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'negara',
        type: 'input',
        value: '',
        validator: Yup.string(),
        hide: true,
        options: {
          input: {
            props: {
              title: 'Negara',
              placeholder: 'Negara',
            },
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id',
        type: 'datalist',
        value: '',
        validator: Yup.string().required('Unit is required'),
        options: {
          input: {
            props: {
              title: 'Unit',
              placeholder: 'Unit',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan=$jenis_tujuan',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'ubah',
                      protect: 'ubah/:id',
                      title: 'Ubah',
                      route: 'ubah/:id',
                      type: 'feedback',
                      input: inputListSatker,
                    },
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan_2',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        validator: Yup.string(),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan 2',
              placeholder: 'Jenis Tujuan 2',
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota2',
        type: 'datalist',
        value: '',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Kota 2',
              placeholder: 'Kota 2',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            useOnLoad: true,
            query: 'provinsi=$provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id_2',
        type: 'datalist',
        value: '',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Unit 2',
              placeholder: 'Unit 2',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan_2=$jenis_tujuan_2',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan_2',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'jenis_tujuan_3',
        type: 'select',
        value: '',
        listData: [
          {
            title: 'Unit Eselon Pusat',
            value: 'Unit Eselon Pusat',
          },
          {
            title: 'Kanwil dan Kemenag',
            value: 'Kanwil dan Kemenag',
          },
          {
            title: 'PTKN',
            value: 'PTKN',
          },
          {
            title: 'UPT',
            value: 'UPT',
          },
          {
            title: 'K/L/D/I lainnya',
            value: 'K/L/D/I lainnya',
          },
        ],
        validator: Yup.string(),
        options: {
          select: {
            props: {
              title: 'Jenis Tujuan 3',
              placeholder: 'Jenis Tujuan 3',
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'kota3',
        type: 'datalist',
        value: '',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Kota 3',
              placeholder: 'Kota 3',
            },
          },
          datalist: {
            api: '/pengawasan_internal/data-kota',
            useOnLoad: true,
            query: 'provinsi=$provinsi',
            params: 'nama',
            result: '$nama',
            id: 'nama',
            type: 'table',
            table: {
              data: [
                {id: 'nama', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Nama'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_kota',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_kota',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi',
          },
        },
      },
      {
        className: 'col-12 col-md-6',
        name: 'satker_id_3',
        type: 'datalist',
        value: '',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Unit 3',
              placeholder: 'Unit 3',
            },
          },
          datalist: {
            api: '/kepegawaian/data-satker-unit',
            useOnLoad: true,
            query: 'provinsi=$provinsi&kota=$kota&jenis_tujuan_3=$jenis_tujuan_3',
            params: 'kode,nama_satker',
            result: '$kode - $nama_satker',
            id: 'kode',
            type: 'table',
            table: {
              data: [
                {id: 'kode', type: 'text'},
                {id: 'nama_satker', type: 'text'},
                {id: 'countST', type: 'text'},
                {id: 'countUsulan', type: 'text'},
              ],
              protect: '',
              props: {
                route: {
                  url: '/kepegawaian/data-satker-unit',
                },
                headerData: [
                  {title: 'No.', props: {className: 'min-w-50px'}},
                  {title: 'Kode'},
                  {title: 'Nama Satker'},
                  {title: 'Jumlah ST Terbit'},
                  {title: 'Jumlah Usulan'},
                ],
                link: {
                  tambah: {
                    id: 'tambah',
                    protect: 'tambah',
                    route: '',
                    title: 'Tambah',
                    type: 'feedback',
                    input: inputListSatker,
                  },
                  action: [
                    {
                      id: 'detail',
                      protect: 'st_satker',
                      title: 'Detail List ST Terbit',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                    {
                      id: 'detail',
                      protect: 'usulan_satker',
                      title: 'Detail List Usulan',
                      route: 'detail/:id',
                      type: 'feedback',
                    },
                  ],
                },
              },
            },
          },
          watchInput: {
            id: 'provinsi,kota,jenis_tujuan_3',
          },
        },
      },
    ],
  },
  {
    className: 'col-12 col-md-6',
    name: 'jenis',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'SPD',
        value: 'SPD',
      },
      {
        title: 'Non SPD',
        value: 'Non SPD',
      },
      {
        title: 'SPD DKI Jakarta',
        value: 'SPD DKI Jakarta',
      },
    ],
    validator: Yup.string().required('Jenis wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Jenis',
          placeholder: 'Jenis',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kategori_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kategori is required'),
    options: {
      input: {
        props: {
          title: 'Kategori',
          placeholder: 'Kategori',
        },
      },
      datalist: {
        id: 'id',
        api: '/pengawasan_internal/kategori-penugasan',
        params: 'kategori',
        result: `$kategori`,
        type: 'table',
        table: {
          protect: '/pengawasan/persiapan/data_tambahan/kategori_penugasan',
          data: [
            {
              id: 'kategori',
              type: 'text',
            },
            {
              id: 'sub_kategori',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/pengawasan_internal/kategori-penugasan',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kategori',
              },
              {
                title: 'Sub Kategori',
              },
            ],
            // link: {
            //   tambah: {
            //     id: 'tambah',
            //     protect: 'tambah',
            //     route: '',
            //     title: 'Tambah',
            //     type: 'feedback',
            //     input: inputListKategori,
            //   },
            //   action: [
            //     {
            //       id: 'ubah',
            //       protect: 'ubah/:id',
            //       title: 'Ubah',
            //       route: 'ubah/:id',
            //       type: 'feedback',
            //       input: inputListKategori,
            //     },
            //   ],
            // },
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'pok_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'POK',
          placeholder: 'POK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok',
        useOnLoad: true,
        query: 'order_by=asc',
        params:
          'tahun,kode_program,nama_program,alokasi_dana,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$tahun</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$kode_program - $nama_program</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$alokasi_dana</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'tahun',
              type: 'text',
            },
            {
              id: 'kode_program',
              type: 'text',
            },
            {
              id: 'nama_program',
              type: 'text',
            },
            {
              id: 'alokasi_dana',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok',
              query: 'order_by=asc',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Tahun',
              },
              {
                title: 'Kode Program',
              },
              {
                title: 'Nama Program',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'kode_mak',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kode MAK is required'),
    options: {
      input: {
        props: {
          title: 'Kode MAK',
          placeholder: 'Kode MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: 'listkegiatan=true&usulan_tim=$usulan_tim',
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'listkegiatan=true&usulan_tim=$usulan_tim',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'pok_id,usulan_tim',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'mak',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('MAK is required'),
    options: {
      input: {
        props: {
          title: 'MAK',
          placeholder: 'MAK',
        },
      },
      datalist: {
        api: '/keuangan/form-pok/$pok_id',
        noApiDetailId: true,
        useOnLoad: true,
        query: 'kodekegiatan=$kode_mak&usulan_tim=$usulan_tim&jenis_tujuan=$jenis_tujuan',
        params: 'nama,kode,anggaran,totalRAB_ST,totalRAB_Usulan,sisaAnggaran',
        filter: 'currency',
        result: (
          <>
            <div>
              <span>$kode</span>
            </div>
            <div>
              <span className='fw-bold'>Kegiatan : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>Anggaran : </span>
              <span>currency=$anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB ST : </span>
              <span>currency=$totalRAB_ST</span>
            </div>
            <div>
              <span className='fw-bold'>Total RAB Usulan : </span>
              <span>currency=$totalRAB_Usulan</span>
            </div>
            <div>
              <span className='fw-bold'>Sisa Anggaran : </span>
              <span>currency=$sisaAnggaran</span>
            </div>
          </>
        ),
        id: 'kode',
        type: 'table',
        table: {
          protect: '/pengelolaan_keuangan/pok',
          data: [
            {
              id: 'kode',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
            {
              id: 'anggaran',
              type: 'currency',
            },
            {
              id: 'totalRAB_ST',
              type: 'currency',
            },
            {
              id: 'totalRAB_Usulan',
              type: 'currency',
            },
            {
              id: 'sisaAnggaran',
              type: 'currency',
            },
          ],
          props: {
            route: {
              url: '/keuangan/form-pok/$pok_id',
              query: 'kodekegiatan=$kode_mak&usulan_tim=$usulan_tim',
              singleLoad: true,
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Kode',
              },
              {
                title: 'Nama Kegiatan',
              },
              {
                title: 'Alokasi Dana',
              },
              {
                title: 'Total RAB ST',
              },
              {
                title: 'Total RAB Usulan',
              },
              {
                title: 'Sisa Anggaran',
              },
            ],
          },
        },
      },
      watchInput: {
        id: 'usulan_tim,pok_id,kode_mak,usulan_tim',
      },
    },
  },

  {
    className: 'col-12 col-md-6',
    name: 'ppk_id',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pejabat Pembuat Komitmen',
          placeholder: 'Pejabat Pembuat Komitmen',
        },
      },
      datalist: {
        api: '/pengawasan_internal/list-pegawai-ppk',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
          </>
        ),
        id: 'pegawai_id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/list-pegawai-ppk',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tembusan',
    type: 'component',
    value: '',
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Tembusan',
          placeholder: 'Tembusan',
        },
      },
      component: {
        // useOrderNumber: true,
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'detail',
        type: 'textarea',
        value: '',
        validator: Yup.string(),
        options: {
          textarea: {
            props: {
              title: 'Detail',
              placeholder: 'Detail',
              rows: 3,
            },
          },
          label: {
            hideLabel: true,
          },
        },
      },
    ],
  },
  {
    className: 'col-12',
    name: 'text',
    type: 'label',
    value: '',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Reviewer & Penandatangan',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'irwil_id',
    type: 'datalist',
    value: '',
    protect: ['pengawasan/persiapan/form_usulan/usulan_auditor'],
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Pilih Irwil',
          placeholder: 'Pilih Irwil',
        },
      },
      datalist: {
        name: 'penandatangan',
        api: '/pengawasan_internal/penandatangan-st',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/penandatangan-st',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'disposisi_id',
    type: 'datalist',
    value: '',
    protect: ['pengawasan/persiapan/form_usulan/usulan_auditor'],
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Pilih Irjen',
          placeholder: 'Pilih Irjen',
        },
      },
      datalist: {
        name: 'penandatangan',
        api: '/pengawasan_internal/penandatangan-st',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/penandatangan-st',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'penandatangan_st',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Reviewer 1',
          placeholder: 'Reviewer 1',
        },
      },
      datalist: {
        name: 'penandatangan',
        api: '/pengawasan_internal/penandatangan-st',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/penandatangan-st',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'penandatangan_st_2',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Reviewer 2',
          placeholder: 'Reviewer 2',
        },
      },
      datalist: {
        name: 'penandatangan_2',
        api: '/pengawasan_internal/penandatangan-st',
        query: 'orderby=asc',
        params: 'pegawai.nama,keterangan',
        // result: '$pegawai.nama | $keterangan',
        result: (
          <>
            <div>
              <span>$pegawai.nama</span>
            </div>
            <div>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'pegawai.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          protect: '',
          props: {
            route: {
              url: '/pengawasan_internal/penandatangan-st',
            },
            headerData: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Pegawai'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jabatan_penandatangan',
    type: 'input',
    value: '',
    hide: true,
    disabled: true,
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Jabatan Penandatangan',
          placeholder: 'Jabatan Penandatangan',
          disabled: true,
        },
      },
    },
  },
]

const inputListSp: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'sumber_penugasan',
    type: 'select',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [
      // {title: 'Form APP', value: 'Form APP'},
      {title: 'Form PKPT', value: 'Form PKPT'},
      {title: 'Non SPD', value: 'Lainnya'},
    ],
    options: {
      input: {
        props: {
          title: 'Sumber Penugasan',
          placeholder: 'Sumber Penugasan',
        },
      },
    },
  },
  {
    className: '',
    name: 'tipe_usulan',
    type: 'input',
    value: 'Draft',
    validator: Yup.string(),
    disabled: true,
    hide: true,
  },
]

const inputListFormApp: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'form_app_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Form APP',
          placeholder: 'Form APP',
        },
      },
      datalist: {
        api: '/pengawasan_internal/form-app',
        query: 'order_by=asc&optionList=true',
        params: 'nama',
        filter: 'formatDate',
        result: '$nama',
        id: 'id',
        type: 'table',
        table: {
          protect: '/perencanaan_anggaran/penyusunan_program_kerja_pengawasan_tahunan',
          data: [
            {
              id: 'tahun',
              type: 'text',
            },
            {
              id: 'nama',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/pengawasan_internal/form-app',
              query: 'order_by=asc&optionList=true',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Tahun',
              },
              {
                title: 'Judul',
              },
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'form_app_detail',
    type: 'select',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [],
    options: {
      input: {
        props: {
          title: 'Form APP Detail',
          placeholder: 'Form APP Detail',
        },
      },
      watchInput: {
        id: 'form_app_id',
      },
    },
  },
]

const inputListPkpt: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'pkpt_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'PKPT',
          placeholder: 'PKPT',
        },
      },
      datalist: {
        api: '/perencanaan_anggaran/program-kerja-pengawasan-tahunan',
        // api: '/perencanaan_anggaran/form-pkpt',
        query: 'status=Disetujui',
        params:
          'tema,topik,nama_kegiatan,kode_satker,nilai_anggaran,total_RAB_usulan,jenis_pkpt,jenis,tahun,sisa_anggaran,total_realisasi',
        filter: 'formatDate,currency',
        result: (
          <>
            <div>
              <span className='fw-bold'>Tema: </span>
              <span>$tema</span>
            </div>
            <div>
              <span className='fw-bold'>Topik: </span>
              <span>$topik</span>
            </div>
            <div>
              <span className='fw-bold'>Nama Kegiatan: </span>
              <span>$nama_kegiatan</span>
            </div>
            <div>
              <span className='fw-bold'>Jenis Penugasan: </span>
              <span>$jenis_pkpt</span>
            </div>
            <div>
              <span className='fw-bold'>Wilayah Kerja: </span>
              <span>$kode_satker</span>
            </div>
            <div>
              <span className='fw-bold'>Jenis: </span>
              <span>$jenis</span>
            </div>
            <div>
              <span className='fw-bold'>Nilai Anggaran: </span>
              <span>currency=$nilai_anggaran</span>
            </div>
            <div>
              <span className='fw-bold'>Total Realisasi: </span>
              <span>currency=$total_realisasi</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          protect: '/perencanaan_anggaran/penyusunan_program_kerja_pengawasan_tahunan',
          data: [
            {
              id: 'tema',
              type: 'text',
            },
            {
              id: 'topik',
              type: 'text',
            },
            {
              id: 'nama_kegiatan',
              type: 'text',
            },
            {
              id: 'nilai_anggaran',
              type: 'currency',
            },
            {
              id: 'total_realisasi',
              type: 'currency',
            },
            {
              id: 'sisa_anggaran',
              type: 'currency',
            },
            {
              id: 'jenis_pkpt',
              type: 'text',
            },
            {
              id: 'kode_satker',
              type: 'text',
            },
            {
              id: 'jenis',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/perencanaan_anggaran/program-kerja-pengawasan-tahunan',
              // url: '/perencanaan_anggaran/form-pkpt',
              query: 'status=Disetujui',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Tema',
              },
              {
                title: 'Topik',
              },
              {
                title: 'Kegiatan',
              },
              {
                title: 'Nilai Anggaran',
              },
              {
                title: 'Total Realisasi',
              },
              {
                title: 'Sisa Anggaran',
              },
              {
                title: 'Jenis Penugasan',
              },
              {
                title: 'Wilayah Kerja',
              },
              {
                title: 'Jenis',
              },
            ],
          },
        },
      },
    },
  },
]

const inputListLainnya: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'lainnya',
    type: 'input',
    value: '',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Non SPD',
          placeholder: 'Non SPD',
        },
      },
    },
  },
]

const inputListAll: FormInputProps[] = [
  ...inputListSp,
  ...inputListFormApp,
  ...inputListPkpt,
  ...inputListLainnya,
  ...inputListEnd,
]

const formInputStatusIrwil: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_irwil',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_irwil',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusdisposisi: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_disposisi',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_disposisi',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
]

const formInputStatusPass: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  // {
  //   className: 'col-12 col-md-6',
  //   name: 'passphrase',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Passphrase is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Passphrase',
  //         placeholder: 'Passphrase',
  //         type: 'password',
  //       },
  //     },
  //   },
  // },
]

const formInputStatus2: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status_2',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Ditolak',
        value: 'Ditolak',
      },
      {
        title: 'Menunggu',
        value: 'Menunggu',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan_2',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  // {
  //   className: 'col-12 col-md-6',
  //   name: 'passphrase',
  //   type: 'input',
  //   value: '',
  //   validator: Yup.string().required('Passphrase is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Passphrase',
  //         placeholder: 'Passphrase',
  //         type: 'password',
  //       },
  //     },
  //   },
  // },
]

const formInputPass: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

export {
  inputListAll,
  inputListEnd,
  inputListFormApp,
  inputListPkpt,
  inputListLainnya,
  inputListAdjusment,
  filterInputList,
  formInputStatus,
  formInputStatus2,
  formInputStatusIrwil,
  formInputStatusdisposisi,
  formInputStatusPass,
  formInputPass,
}
