import {
  MainWrapper,
  DetailWrapper,
  TambahWrapper,
  UbahWrapper,
} from '../../../../pages/keuangan/petunjuk-operasional-kegiatan-v2/kode-kegiatan/MainWrapper'
import {DataRoutesProps} from '../../../UserRolesValidation'
import KodeProgramPOK from './KodeProgramPOK'

const KodeKegiatanPOK: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'tambah',
    elements: <TambahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'ubah/:kode_kegiatan',
    elements: <UbahWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'program/:kode_kegiatan',
    elements: <></>,
    subroute: KodeProgramPOK,
  },
  // {
  //   icon: '',
  //   title: '',
  //   route: 'pdf/:kode_kegiatan',
  //   elements: <PdfWrapper />,
  //   subroute: [],
  // },
  {
    icon: '',
    title: '',
    route: 'detail/:kode_kegiatan',
    elements: <DetailWrapper />,
    subroute: [],
  },
]

export default KodeKegiatanPOK
