import {useFormik, FormikConfig, FieldInputProps} from 'formik'
import {
  FC,
  Fragment,
  ReactNode,
  createContext,
  createElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  ConvertCurrency,
  DynamicUrl,
  ListBindDataByUrl,
  NullProof,
  PaginationStaticDataProps,
  PopupConfirm,
  RouterQueryParams,
  checkFileExtension,
  filenameGenerator,
  getPaginationStaticData,
  numberlistPagination,
  updateFields,
} from './AppFunction'
import Swal from 'sweetalert2'
import {getData, postData, postGenerateUploadUrl, putData} from './FormAxios'
import {formatDate} from './DateFns'
import ReactDatepicker from './ReactDatepicker'
import CurrencyFormat from 'react-currency-format'
import Modal from './Modal'
import {ReactPdfViewer} from './ReactPdf'
import ReactIcon from './ReactIcon'
import Pagination from './Pagination'
import ReactDOMServer from 'react-dom/server'
import {WithChildren} from '../../_metronic/helpers'
import Accordion from './Accordion'
import {
  TableInput,
  TableInputBaseProps,
  TableInputCheckboxDataProps,
  TableInputConfig,
  TableRow,
} from './TableInputV2'
import {ButtonLoading} from './AppUi'
import {Crop} from 'react-image-crop'
import ReactCropper from './ReactCropper'
import ReactTiptap from './ReactTiptap'
import {useDispatch, useSelector} from 'react-redux'
import {post as postTable, clear as clearTable} from '../../app/redux/tableSlice'
import {csvToObject, xlsxToObj} from './XlsxToCsv'
import {intlMessage} from './ReactIntl'
import {useIntl} from 'react-intl'
import ReactSelect from './ReactSelect'
import {debounce, isEqual} from 'lodash'
import FormInputConfigUi from './config/FormInputConfig'

const isDebugging = process.env.REACT_APP_COMPONENT_DEBUGGING || false

const API_URL = process.env.REACT_APP_API_URL

// Function Props

const gapClass = 'g-6'
type typeCallProps =
  | 'onInit'
  | 'onLoad'
  | 'onChange'
  | 'onBlur'
  | 'onReload'
  | 'onClick'
  | 'onReset'

// FormInputProvider

export interface FormInputUiConfigProps {
  labelType?: 'floating' | 'default'
  component?: {
    deleteButtonPosition?: 'top' | 'bottom' | 'end'
    useAccordionTo?: 'data' | 'component'
  }
  upload?: {
    uniqueId?: string
    filename?: string
    folder?: string
  }
}

interface FormInputContextModel {
  data?: any
  setData: (val: any) => void
  formikData?: any
  setFormikData: (val: any) => void
  input?: FormInputProps[]
  setInput: (val: FormInputProps[]) => void
  utils?: any
  setUtils: (val: any) => void
  options?: any
  setOptions: (val: any) => void
}

const FormInputContext = createContext<FormInputContextModel>({
  setData(value: any) {},
  setFormikData(value: any) {},
  setInput(value: FormInputProps[]) {},
  setUtils(value: any) {},
  setOptions(value: any) {},
})

const FormInputProvider: FC<WithChildren> = ({children}) => {
  const [data, setData] = useState<any>({})
  const [formikData, setFormikData] = useState<any>({})
  const [input, setInput] = useState<FormInputProps[]>([])
  const [utils, setUtils] = useState<any>({})
  const [options, setOptions] = useState<any>({})
  const value: FormInputContextModel = {
    data,
    setData,
    utils,
    setUtils,
    formikData,
    setFormikData,
    input,
    setInput,
    options,
    setOptions,
  }
  return <FormInputContext.Provider value={value}>{children}</FormInputContext.Provider>
}

export const useFormInput = () => {
  return useContext(FormInputContext)
}

const FormInputConfig: FC<BaseInputProps> = (props) => {
  const {setInput, setUtils, setData, setOptions} = useFormInput()
  const useEffectBuilder = ({
    get,
    post,
    defaultValue,
    feedback,
  }: {
    get: any
    post: any
    defaultValue?: any
    feedback?: boolean
  }) => {
    useEffect(() => {
      if (!feedback) {
        if (get) {
          post(get)
        }
      } else {
        if (post && get) {
          post(get)
        }
      }
      if (!feedback && defaultValue) {
        return () => {
          post(defaultValue)
        }
      }
    }, [get])
  }
  useEffectBuilder({get: props.input, post: setInput, defaultValue: []})
  useEffectBuilder({get: props.utils, post: setUtils, defaultValue: {}})
  useEffectBuilder({get: props.options, post: setOptions, defaultValue: {}})
  return <></>
}

const FormInput: FC<BaseInputProps> = (props) => {
  return (
    <>
      <FormInputProvider>
        <FormInputConfig {...props} />
        <FormInputBase {...props} />
      </FormInputProvider>
    </>
  )
}

export interface InputListTableInputPluginProps {
  data?: any
  props?: TableInputBaseProps
  update: (val: TableInputBaseProps) => void
  refresh: () => void
  feedback?: any
  clearFeedback: () => void
}

interface _updateDataProps {
  fieldsToUpdate: Partial<any>
  options?: {
    refresh?: boolean
    type?: typeCallProps
  }
  id?: string
  index?: number
}

interface OptionInputProps {
  input?: {
    useImageCrop?: boolean
    dateOption?: 'date' | 'datetime' | 'time'
    isCurrency?: boolean
    isDate?: boolean
    labelType?: 'default' | 'floating'
    bindInput?: {
      id?: string
      type?: 'bind' | 'fill'
      input: string
      params: string
      result?: string[]
    }
    props?: React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & {
      type?:
        | React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
          >['type']
        | 'switch'
    }
  }
  imageCropper?: {
    aspect?: number
    shape?: 'rect' | 'round'
  }
  textarea?: {
    useTextEditor?: boolean
    props?: React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
  }
  select?: {
    useSelect2?: boolean
    props?: React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >
  }
  multicheckbox?: {
    rowType?: 'wrap' | 'row'
    rowClassName?: string
    colClassName?: string
    props?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  }
  currency?: {
    useCurrency?: boolean
    props?: CurrencyFormat.Props
  }
  upload?: {
    size?: number
    title?: string
    url?: {
      path?: string
      folder?: string
      filename?: string
      uniqueId?: string
    }
  }
  label?: {
    name?: string
    description?:
      | string
      | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    className?: string
    lineClassName?: string
    labelType?: 'label' | 'line' | 'both'
    labelPosition?: 'top' | 'bottom' | 'top_input'
    hideLabel?: boolean
  }
  i18nDictionary?: {
    id: string
    defaultValue: string
  }[]
  datalist?: {
    title?: string
    name?: string
    id: string
    api: string
    apiPut?: string
    apiInput?: string
    apiInputData?: {
      id?: string
      input?: string
      params: string
    }
    noApiDetailId?: boolean
    data?: any
    query?: string
    result?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    filter?: string
    params?: string
    paramValue?: string
    autoFillInput?: string
    type?: 'datalist' | 'table'
    table?: {
      plugin?: (val: InputListTableInputPluginProps) => void
      data: TableDatalistDataProps[]
      props: TableInputBaseProps
    }
    isListpicker?: boolean
    useOnLoad?: boolean
    apiSingleLoad?: boolean
    apiSingleLoadOptions?: {
      props?: {
        [key: string]: PaginationStaticDataProps
      }
    }
  }
  watchInput?: {
    name?: string
    type?: 'watch-only' | 'watch-reset'
    scope?: 'component' | 'global'
    index?: number
    id: string
  }
  parent?: {
    name?: string
    index?: number
  }
  component?: {
    type?: 'list' | 'group' | 'object'
    useAccordion?: boolean
    useAccordionTo?: 'data' | 'component'
    useGuide?: boolean
    useOrderNumber?: boolean
    useGapHairline?: boolean
    useHeaderToolbar?: boolean
    deleteButtonPosition?: 'top' | 'bottom' | 'end'
    cloneInitData?: boolean
    resultType?: {
      type: 'number' | 'date' | 'currency'
      labelStart?: string
      labelEnd?: string
    }
    layout?: 'component' | 'card' | 'dropdown'
  }
  group?: {
    layout?: 'card' | 'dropdown'
  }
  sidebutton?: {
    iconOnly?: boolean
    icon?: string
    title?: string
    props?: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  }
  validator?: {
    useReconfirmDelete?: boolean
  }
}

interface TableDatalistDataProps {
  id: string
  className?: string
  type: 'text' | 'currency' | 'date' | 'map'
  params?: string
  filter?: string
  result?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  map?: TableDatalistDataProps[]
}

type FormInputTypeProps =
  | 'input'
  | 'select'
  | 'multicheckbox'
  | 'datalist'
  | 'textarea'
  | 'label'
  | 'component'
  | 'csv'
  | 'group'

interface ListDataProps {
  label?: string
  title: string
  value: number | string
  options?: ListDataProps[]
}
interface InputListProps {
  className?: string
  name: string
  protect?: string[]
  type: FormInputTypeProps
  hide?: boolean
  removeOnSubmit?: boolean
  disabled?: boolean
  lockData?: boolean
  validator: any
  value?: string | number
  listData?: ListDataProps[]
  options?: OptionInputProps
}

interface ComponentInputProps {
  name: string
  data: FormInputProps & InputListOptionProps
  component: FormInputProps[]
}

interface InputListOptionProps {
  i18n?: i18nProps
  formName?: string
  onLoad?: (e: onLoadProps) => void
  onFeedback?: (e: any) => void
  parentName?: string
  componentIndex?: number
  route?: RouteOptionProps
  core: {
    _data: {
      post({fieldsToUpdate, options}: _updateDataProps): void
      value: any
    }
    _dataIndicator: {
      post({fieldsToUpdate, options}: _updateDataProps): void
      value: any
    }
    _apiData: {
      value: any
      post(fieldsToUpdate: Partial<any>): any
      server: any
    }
    input: {
      value: any
    }
    formik: any
    formError: {
      value: boolean
      post(value: boolean): void
    }
    formLock: boolean
    errorMessage: any
    setError: ({field, message}: {field: string; message: string}) => void
    refreshData: {
      value: any
      post(fieldsToUpdate: Partial<any>): any
    }
    type?: typeCallProps
  }
}

export interface FormInputProps extends InputListProps {
  component?: FormInputProps[]
}

export interface FormInputValidatorProps extends FormInputProps {
  parentId?: string
  parentName?: string
  parentData?: any
  index?: number
}

export interface RouteOptionProps {
  url: string
  urlSubmit?: string
  redirect?: boolean
  useServerMessage?: boolean
  type: 'post' | 'put'
  query?: string
  data?: any
  bindData?: BindDataProps[]
  isFeedback?: boolean
  resetOnFeedback?: boolean
  onFeedback?: ({status, message, data}: {status?: boolean; message?: string; data?: any}) => void
  onFeedbackSuccessMessage?: string
}

export interface BaseInputOnChangeProps {
  error?: boolean
  _dataIndicator: {
    get: any
    post: any
  }
  _data: {
    getObject: any
    get: any
    post: any
  }
  _apiData: {
    get: any
    post: any
    server: any
  }
  setError: ({field, message}: {field: string; message: string}) => void
  refreshInput: (fieldsToUpdate: Partial<any>) => void
  resetForm: ({values}: {values: any}) => void
  lockForm: (val: boolean) => void
  type?: typeCallProps
  clickId?: string
  clickIndex?: number
}

interface onLoadDatasProps {
  _dataIndicator: {
    get: any
  }
  _data: {
    getObject: any
    get: any
  }
  _apiData: {
    get: any
    server: any
  }
}

interface onLoadDataProps {
  result?: any
  query?: any
}

export interface onLoadProps {
  id: string
  initLoading: () => void
  query?: {
    get?: any
    post: (query: any) => void
  }
  data?: onLoadDatasProps
  load: ({query, result}: onLoadDataProps) => void
}

export interface onBeforeSubmitProps {
  data?: onLoadDatasProps
  submit: ({values}: {values: any}) => void
}

interface i18nProps {
  baseName?: string
  inputName?: string
}

interface BaseInputProps {
  controller?: {
    get: string
    post: (e: any) => void
    status: any
    setStatus: (e: any) => void
  }
  utils?: any
  i18n?: i18nProps
  name?: string
  isLocked?: boolean
  isModal?: boolean
  isSubmitByBlur?: boolean
  useToast?: boolean
  input: FormInputProps[]
  route: RouteOptionProps
  isLoading?: boolean
  isError?: boolean
  className?: string
  onLoad?: (e: onLoadProps) => void
  onChange?: (e: BaseInputOnChangeProps) => void
  onFeedback?: (e: any) => Promise<void> | void
  onBeforeSubmit?: (e: onBeforeSubmitProps) => Promise<void>
  options?: {
    actionButton?: {
      submit?: {
        hide?: boolean
        title?: string
      }
      cancel?: {
        hide?: boolean
        title?: string
        confirm?: {
          enabled?: boolean
          title?: string
          success?: string
        }
      }
      align?: 'start' | 'end'
    }
    config?: FormInputUiConfigProps
  }
}

interface JsonDataBuilderProps {
  data: FormInputProps[]
  _data: any
  value: any
  isSubmit?: boolean
}

export type BindDataProps = {
  urlParams?: string /** URL Params to variable */
  url: string /** API Data URL */
  params: string /** Pick variable data API to result variable */
  result: string /** Split each variable to input data, use :data for url, $data from api */
  bindToInput?: boolean /** Enable Bind Data to Input */
}

const listDataConvert = (data?: ListDataProps[]) => {
  const olah = (_data?: ListDataProps[]) => {
    let _result: ListDataProps[] = []
    for (const l of _data || []) {
      let options: any = {}
      if (l?.options) {
        options = {
          options: olah(l?.options || []),
        }
      }
      _result.push({
        ...l,
        label: l.title,
        ...options,
      })
    }
    return _result
  }

  return olah(data)
}

const ErrorUi: FC<InputListOptionProps & {name: string}> = ({core, name}) => {
  const intl = useIntl()
  const formikErrorMessage = core.formik.errors[name]
    ? intlMessage(`component.formik.${core.formik.errors[name]}`, core.formik.errors[name], intl)
    : ''
  const customErrorMessage = core.errorMessage[name]
    ? intlMessage(
        `component.formik.${core.errorMessage[name]}`,
        core.formik.errors[name] ? formikErrorMessage : core.errorMessage[name],
        intl
      )
    : formikErrorMessage
  return (
    <>
      {((core.formError.value && core.formik.errors[name]) || core.errorMessage[name]) && (
        <div className='fv-plugins-message-container mx-2 mt-2 px-4 py-2 border border-danger rounded bg-danger bg-opacity-10'>
          <div className='fv-help-block d-flex align-items-center gap-2'>
            <ReactIcon icon='RiErrorWarningLine' props={{className: 'fs-2'}} />
            <div className='fw-semibold'>{customErrorMessage}</div>
          </div>
        </div>
      )}
    </>
  )
}

const getInitialValues = (props: {
  data: FormInputValidatorProps[]
  serverData?: any
  isTemp?: boolean
}) => {
  var init: any = {}
  const DropdownValue = (l: FormInputValidatorProps) => {
    const totalData: number =
      l.parentData && l.parentName
        ? l.parentData[l.parentName]?.length || l.value || 0
        : props.serverData
        ? props.serverData[l.name]?.length || l.value || 0
        : l.value || 0
    init[l.name] = totalData
    try {
      for (let index = 0; index < totalData; index++) {
        const component: FormInputValidatorProps[] = l?.component || []
        component.map((lsub: FormInputValidatorProps, isub) => {
          const SingleValueDropdownValue = (lsub: FormInputValidatorProps) => {
            const isObject = l.options?.component?.type === 'object'
            const name = isObject
              ? `${l.name}_${component[isub].name}`
              : `${l.name}_${component[isub].name}_${index + 1}`
            const childData =
              l.parentData && l.parentName
                ? isObject
                  ? NullProof({input: l.parentData, params: l.parentName})
                  : NullProof({input: l.parentData, params: l.parentName, isMap: true})[index]
                : props.serverData
                ? isObject
                  ? NullProof({input: props.serverData, params: l.name})
                  : NullProof({input: props.serverData, params: l.name, isMap: true})[index]
                : {}
            if ((component[isub].component?.length || 0) > 0) {
              DropdownValue({
                ...lsub,
                name: name,
                parentName: component[isub].name,
                parentData: props.serverData ? childData : undefined,
                parentId: l.name,
              })
            } else if (
              component[isub].type === 'input' &&
              component[isub].options?.input?.props?.type === 'file'
            ) {
              init[name] =
                props.isTemp || component[isub].options?.input?.props?.multiple
                  ? (childData && childData[component[isub].name]) || ''
                  : ''
            } else {
              if (
                component[isub].type === 'input' &&
                component[isub].options?.input?.props?.type === 'date'
              ) {
                init[name] =
                  formatDate({
                    date: ((childData && childData[component[isub].name]) || '')?.toString(),
                    dateFormat:
                      component[isub].options?.input?.dateOption === 'datetime'
                        ? 'yyyy-MM-dd HH:mm'
                        : component[isub].options?.input?.dateOption === 'time'
                        ? 'HH:mm'
                        : 'yyyy-MM-dd',
                  }) || ''
              } else {
                init[name] =
                  (childData && childData[component[isub].name]) ||
                  (component[isub].type === 'input' &&
                  component[isub].options?.input?.props?.type === 'checkbox'
                    ? false
                    : lsub.value || '')
              }
            }
          }
          if (
            (component[isub].component?.length || 0) > 0 &&
            component[isub].options?.component?.type === 'group'
          ) {
            for (const lsuper of l.component || []) {
              SingleValueDropdownValue(lsuper)
            }
          } else {
            SingleValueDropdownValue(lsub)
          }
        })
      }
    } catch (_) {}
  }
  const SingleValue = (l: FormInputValidatorProps) => {
    if (props.serverData) {
      if ((l.component?.length || 0) > 0) {
        DropdownValue(l)
      } else if (l.type === 'label') {
      } else if (l.type === 'select') {
        if (props.serverData[l.name]?.id) {
          init[l.name] = props.serverData[l.name]?.id || ''
        } else {
          init[l.name] = props.serverData[l.name] || ''
        }
      } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
        init[l.name] =
          props.isTemp || l.options?.input?.props?.multiple ? props.serverData[l.name] || '' : ''
      } else {
        if (l.options?.input?.props?.type === 'date') {
          init[l.name] =
            formatDate({
              date: props.serverData[l.name]?.toString(),
              dateFormat: 'yyyy-MM-dd',
            }) || ''
        } else {
          init[l.name] =
            props.serverData[l.name] || (l.options?.input?.props?.type === 'checkbox' ? false : '')
        }
      }
    } else {
      if ((l.component?.length || 0) > 0 && (l.options?.component?.type || 'list') === 'list') {
        DropdownValue(l)
      } else {
        if (l.options?.input?.props?.type === 'date') {
          init[l.name] =
            formatDate({
              date: l.value?.toString() || '',
              dateFormat: 'yyyy-MM-dd',
            }) || ''
        } else {
          init[l.name] =
            l.value || (l.options?.input?.props?.type === 'checkbox' ? false : l.value || '')
        }
      }
    }
  }
  props.data.map((l) => {
    if ((l.component?.length || 0) > 0 && l.options?.component?.type === 'group') {
      for (const lsub of l.component || []) {
        SingleValue(lsub)
      }
    } else {
      SingleValue(l)
    }
  })
  return init
}

const uploadBuilder = async (props: {
  file: File
  folder?: string
  path?: string
  limitSize?: number
  config?: FormInputUiConfigProps
}) => {
  let result = {
    status: false,
    error: false,
    message: '',
    data: '',
  }
  try {
    // Upload Function Here
    let formData = new FormData()
    const fileSize: number = parseFloat((props.file.size / (1024 * 1024)).toFixed(1))
    if (fileSize > (props?.limitSize || 2)) {
      result.message = `Ukuran file tidak boleh lebih dari ${props?.limitSize || 2}MB`
      result.error = true
    } else {
      const fileInfo = checkFileExtension(props.file.name)
      const renamedFile = new File(
        [props.file],
        `${filenameGenerator({
          initName: props.config?.upload?.filename || 'untitled',
          uniqueId: props.config?.upload?.uniqueId || 'untitled',
        })}.${fileInfo.ext}`,
        {
          type: props.file.type,
        }
      )
      formData.append('files', renamedFile)
      formData.append('folder', props.folder || props.config?.upload?.folder || 'dokumen')
      const response: {status: boolean; data: string} = await postGenerateUploadUrl(
        formData,
        props?.path || '/upload/uploadfile'
      )
      if (response?.status) {
        result.status = true
        result.data = response.data
      }
    }
  } catch (e) {}
  return result
}

function InputListValidator(props: {data: FormInputValidatorProps[]; serverData?: any}) {
  var builder: any = {}
  const DropdownValidator = (l: FormInputValidatorProps) => {
    const isObject = l.options?.component?.type === 'object'
    const totalData = isObject
      ? 1
      : props.serverData
      ? l.parentData && l.parentName
        ? l.parentData[l.parentName]?.length || 0
        : props.serverData[l.name]?.length || 0
      : 0
    builder[l.name] = l.validator
    try {
      for (let index = 0; index < totalData; index++) {
        l?.component?.map((lsub, isub) => {
          const SingleLoadDropdownValidator = (lsub: FormInputValidatorProps) => {
            const name: string = isObject
              ? `${l.name}_${lsub.name}`
              : `${l.name}_${lsub.name}_${index + 1}`
            const serverData =
              l.parentData && l.parentName
                ? isObject
                  ? NullProof({input: l.parentData, params: l.parentName})
                  : NullProof({input: l.parentData, params: l.parentName, isMap: true})[index]
                : isObject
                ? NullProof({input: props.serverData, params: l.name})
                : NullProof({input: props.serverData, params: l.name, isMap: true})[index]
            if ((lsub.component?.length || 0) > 0) {
              DropdownValidator({
                ...lsub,
                name: name,
                parentId: l.name,
                parentName: lsub.name,
                parentData: serverData,
              })
            } else if (lsub.type === 'input' && lsub.options?.input?.props?.type === 'file') {
              builder[name] = props.serverData
                ? serverData && serverData[lsub.name] && !lsub.options?.input?.props?.multiple
                  ? Yup.string()
                  : lsub.validator
                : lsub.validator
            } else {
              builder[name] = lsub.validator
            }
          }
          if ((lsub.component?.length || 0) > 0 && lsub.options?.component?.type === 'group') {
            for (const lsuper of lsub.component || []) {
              SingleLoadDropdownValidator(lsuper)
            }
          } else {
            SingleLoadDropdownValidator(lsub)
          }
        })
      }
    } catch (_) {}
  }
  const SingleLoad = (l: FormInputValidatorProps) => {
    if ((l.component?.length || 0) > 0) {
      DropdownValidator(l)
    } else if (l.type === 'label') {
    } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
      let serverData
      if (l.parentId) {
        const splitData = l.name.replaceAll(`${l.parentId}_`, '').split('_')
        try {
          serverData = props.serverData
            ? props.serverData[l.parentId][parseInt(splitData[1]) - 1][splitData[0]]
            : ''
        } catch (_) {}
      }
      builder[l.name] = props.serverData
        ? (l.parentId ? serverData : props.serverData[l.name]) && !l.options?.input?.props?.multiple
          ? Yup.string()
          : l.validator
        : l.validator
    } else {
      builder[l.name] = l.validator
    }
  }
  props.data.map((l) => {
    if ((l.component?.length || 0) > 0 && l.options?.component?.type === 'group') {
      for (const lsub of l.component || []) {
        SingleLoad(lsub)
      }
    } else {
      SingleLoad(l)
    }
  })
  return builder
}

const InputList: FC<InputListProps & InputListOptionProps> = (props) => {
  const {
    i18n,
    formName,
    name,
    parentName,
    componentIndex,
    className,
    hide,
    removeOnSubmit,
    lockData,
    type,
    route,
    disabled,
    options,
    listData,
    validator,
    onFeedback,
    onLoad,
    core,
  } = props
  const {options: formInputOptions} = useFormInput()
  const _config: FormInputUiConfigProps = {...(formInputOptions?.config || FormInputConfigUi)}
  const intl = useIntl()
  const queryUrl = useParams()
  const i18nConfig: string = `${i18n?.baseName || 'page.global'}.${i18n?.inputName || 'input'}`
  const formikProps: FieldInputProps<any[]> = core.formik.getFieldProps(name)
  const formikValue = NullProof({input: formikProps, params: 'value', isLabel: false})
  const [defaultValue, setDefaultValue] = useState<any>()
  const [inputKey, setInputKey] = useState<number>(0)
  const [inputUploadKey, setInputUploadKey] = useState<number>(0)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [fileListUpload, setFileListUpload] = useState<{
    files: {
      file: File
      name: string
      status: 'queue' | 'progress' | 'success' | 'error'
    }[]
    uploading: boolean
    queue: number
  }>({files: [], queue: 0, uploading: false})
  const [inputError, setInputError] = useState(false)
  const [modal, setModal] = useState<any>({
    status: false,
  })
  const [modalCropper, setModalCropper] = useState<{
    status?: boolean
    file?: File
    crop?: Crop
  }>()
  const [tableDatalist, setTableDatalist] = useState<any>([])
  const [uploadData, setUploadData] = useState({
    status: false,
    title: '',
  })
  const [datalistKey, setDatalistKey] = useState<number>(0)
  const [datalist, setDataList] = useState<any>({
    search: '',
    title: '',
    data: [],
    error: false,
    loading: true,
    total: 0,
    totalData: 0,
    currentPage: 1,
    totalPages: 1,
  })
  const [datalistSingleData, setDatalistSingleData] = useState<any[]>([])
  const [datalistCache, setDatalistCache] = useState<any[]>([])
  const [datalistQ, setDataListQ] = useState<any>({
    page: 1,
    search: '',
    size: 10,
  })
  const [pluginDataTableInput, setPluginDataTableInput] = useState<TableInputBaseProps>({route: {}})
  const [tableInputModal, setTableInputModal] = useState<any>({})
  const [tableInputCheckbox, setTableInputCheckbox] = useState<TableInputCheckboxDataProps>({})
  const [tableInputFeedback, setTableInputFeedback] = useState<any>({})
  useEffect(() => {
    if (options?.datalist?.table?.plugin) {
      options?.datalist?.table?.plugin({
        data: {
          modal: tableInputModal,
          checkbox: tableInputCheckbox,
        },
        props: {
          ...options?.datalist?.table?.props,
          modal: setTableInputModal,
          checkbox: setTableInputCheckbox,
        },
        update: (e) => {
          setPluginDataTableInput(e)
        },
        refresh: () => {
          setDatalistKey((p) => p + 1)
        },
        feedback: tableInputFeedback,
        clearFeedback: () => setTableInputFeedback({}),
      })
    }
  }, [
    options?.datalist?.table?.plugin,
    tableInputCheckbox,
    tableInputModal,
    tableInputFeedback?.data,
  ])

  const datalistToListData = (data?: any) => {
    let result: ListDataProps[] = []
    if (!data?.loading && !data?.error) {
      for (let index = 0; index < data?.data?.length || 0; index++) {
        const e = data?.data[index]
        result.push({
          value: NullProof({input: e, params: options?.datalist?.id || 'id'}),
          title: dataListTitle(e),
        })
      }
    }
    return listDataConvert(result)
  }

  const updateDatalistCache = ({
    id = 'id',
    data,
    value,
  }: {
    id?: string
    data?: any
    value?: any
  }) => {
    if (value) {
      let dataCollection = [...datalistCache]
      let valueCollection = [...NullProof({input: formikProps, params: 'value', isMap: true})]
      let index = valueCollection.findIndex((e) => e[id] === value)
      if (index !== -1) {
        dataCollection = dataCollection.filter(
          (l) => NullProof({input: l, params: `data.${id}`}) !== value
        )
        valueCollection.splice(index, 1)
      } else {
        let _value: any = {}
        let dlProps: any[] = options?.datalist?.params?.split(',') || []
        !dlProps.includes(options?.datalist?.id) && dlProps.push(options?.datalist?.id)
        dlProps.map((l) => {
          const listAttr: string[] = l.split('/')
          for (const paramsName of listAttr) {
            let result: any = NullProof({input: data, params: paramsName})
            let attr: string[] = paramsName.split('.')
            const attrName: string = attr[0]
            if (attr.length > 1) {
              attr = attr.slice(1)
              for (let i = attr.length - 1; i >= 0; i--) {
                result = {
                  [attr[i]]: result,
                }
              }
            }
            _value[attrName] = result
          }
        })
        dataCollection = [...dataCollection, {status: 'done', data: _value}]
        valueCollection = [...valueCollection, _value]
      }
      setDatalistCache(dataCollection)
      core.formik.setFieldValue(name, valueCollection)
      core._data.post({
        fieldsToUpdate: {
          [name]: valueCollection,
        },
        index: componentIndex,
      })
    }
  }
  const updateDataList = (fieldsToUpdate: Partial<any>, data: any, post: any): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    post((p: any) => ({...p, ...updatedData}))
  }
  const parseReactNodeDescription = (
    rd: any = <></>,
    i18n: {
      id: string
      defaultValue: string
    }[] = []
  ) => {
    let rdRender: any = ReactDOMServer.renderToString(rd)
    for (const l of i18n) {
      const label = intlMessage(`${i18nConfig}.label.${name}.${l.id}`, l.defaultValue, intl)
      rdRender = rdRender.replaceAll(`[${l.id}]`, label)
    }
    return rdRender
  }
  const dataListTitle = (data: any, datalist?: any) => {
    const _datalist = datalist || options?.datalist
    let dlTitle: any = ReactDOMServer.renderToString(<>{_datalist?.result}</>)
    const dlProps: any[] = _datalist?.params?.split(',') || []
    const filter: any[] = _datalist?.filter?.split(',') || []
    dlProps.forEach((l: string) => {
      const listAttr: string[] = l.split('/')
      const nameAttr: string = listAttr[0] || l
      let currentIndex: number = 0
      for (const paramsName of listAttr) {
        const attr: string[] = paramsName.split('.')
        let result: any = data
        let isNull = false
        const applyFilter = () => {
          filter.forEach((lf) => {
            try {
              if (lf === 'formatDate') {
                dlTitle = dlTitle?.replaceAll(
                  `${lf}=$${nameAttr}`,
                  formatDate({date: result, dateFormat: 'dd MMMM yyyy'})
                )
              } else if (lf === 'currency') {
                dlTitle = dlTitle?.replaceAll(`${lf}=$${nameAttr}`, ConvertCurrency(result))
              } else if (lf === 'icon') {
                dlTitle = dlTitle?.replaceAll(
                  `${lf}=$${nameAttr}`,
                  ReactDOMServer.renderToString(
                    <ReactIcon icon={result} props={{className: 'fs-1'}} />
                  )
                )
              }
            } catch (_) {}
            dlTitle = dlTitle?.replaceAll(`${lf}=$${nameAttr}`, '-')
          })
        }
        for (const attrs of attr) {
          if (result == null) {
            isNull = true
            break
          }
          result = result[attrs]
        }
        if (isNull || result == null) {
          if (currentIndex === listAttr.length - 1) {
            applyFilter()
            dlTitle = dlTitle?.replaceAll(`$${nameAttr}`, '-')
          }
        } else {
          applyFilter()
          dlTitle = dlTitle?.replaceAll(`$${nameAttr}`, result)
          break
        }
        currentIndex++
      }
    })
    return dlTitle
  }
  useEffect(() => {
    if (!formikProps.value) {
      setDataList((p: any) => ({...p, title: ''}))
    }
  }, [formikProps.value])
  const checkWatchProps = () => {
    let pass = true
    try {
      const watchId = options?.watchInput?.id.split(',') || []
      watchId?.forEach((l) => {
        const watchData =
          core._data.value[
            options?.watchInput?.scope === 'global'
              ? `${l}`
              : `${parentName ? `${parentName}_` : ''}${l}${
                  componentIndex ? `_${componentIndex}` : ''
                }`
          ]
        if (!watchData) {
          pass = false
        }
      })
    } catch (_) {
      pass = false
    }
    return pass
  }
  const queryBuilder = ({api, type = 'query'}: {api?: string; type?: 'api' | 'query'}) => {
    let _api: string = api || ''
    let query: any[] = []
    Object.keys(datalistQ).map((l) => {
      const result = `${l}=${datalistQ[l]}`
      datalistQ[l] && query.push(result)
    })
    const watchId = options?.watchInput?.id.split(',') || []
    let watchQuery = options?.datalist?.query || ''
    watchId?.forEach((l) => {
      const targetName =
        options?.watchInput?.scope === 'global'
          ? `${l}`
          : `${parentName ? `${parentName}_` : ''}${l}${componentIndex ? `_${componentIndex}` : ''}`
      const watchData = core._data.value[targetName]
      watchQuery = watchQuery.replaceAll(`$${l}`, watchData)
      _api = _api.replaceAll(`$${l}`, watchData)
    })
    if (watchId.length > 0 || watchQuery) {
      query.push(...watchQuery.split(','))
    }
    if (type === 'api') {
      return [_api]
    } else {
      return query
    }
  }
  const queryObjectBuilder = () => {
    let result: any = {}
    const query: any[] = [...queryBuilder({})]
    for (const l of query) {
      let dataQuery = l?.split('=')
      result[dataQuery[0]] = dataQuery[1]
    }
    return result
  }
  const onPostQuery = (query: any) => {
    let arrQuery: string[] = []
    let baseQuery = {...query}
    delete baseQuery?.filter
    for (const l of Object.keys(baseQuery)) {
      if (baseQuery[l]) {
        arrQuery.push(`${l}=${baseQuery[l]}`)
      }
    }
    return arrQuery.join('&')
  }
  const returnDataBuilder = async () => {
    return {
      _data: {
        get: core._data.value,
        getObject: await JsonDataBuilder({
          data: core.input.value,
          _data: core._data.value,
          value: core._data.value,
        }),
      },
      _dataIndicator: {
        get: core._dataIndicator.value,
      },
      _apiData: {
        get: core._apiData.value,
        server: core._apiData.server,
      },
    }
  }
  const loadDataList = async () => {
    setTimeout(() => {
      document.getElementById(`${name}_search`)?.focus()
    }, 1)
    if (options?.datalist?.useOnLoad && onLoad) {
      const onLoadData = ({query, result}: onLoadDataProps) => {
        if (result?.status) {
          let _result: any[] = []
          if (options?.datalist?.paramValue) {
            _result = NullProof({
              input: result?.data,
              params: options.datalist.paramValue,
            })
          } else {
            _result = result?.data
          }
          core._apiData.post({
            [name]: _result,
          })
          updateDataList(
            {
              search: datalistQ.search,
              data: _result,
              totalData: result.totalItems ?? _result.length,
              currentPage: result.currentPage ?? 1,
              totalPages: result.totalPages ?? 1,
              total: _result.length,
              loading: false,
              error: false,
            },
            datalist,
            setDataList
          )
        } else {
          updateDataList(
            {
              loading: false,
              error: true,
            },
            datalist,
            setDataList
          )
        }
        updateDataList(
          {
            loading: false,
          },
          datalist,
          setDataList
        )
      }
      onLoad({
        id: name,
        data: await returnDataBuilder(),
        query: {
          get: queryObjectBuilder(),
          post: onPostQuery,
        },
        initLoading: () => {
          updateDataList(
            {
              loading: true,
              error: false,
            },
            datalist,
            setDataList
          )
        },
        load: onLoadData,
      })
    } else {
      updateDataList(
        {
          loading: true,
          error: false,
        },
        datalist,
        setDataList
      )
      let query: any[] = queryBuilder({})
      let result: any = {}
      const api = queryBuilder({api: options?.datalist?.api, type: 'api'})[0]
      if (options?.datalist?.api) {
        if (
          (options.datalist.apiSingleLoad && !(datalistSingleData.length > 0)) ||
          !options.datalist.apiSingleLoad
        ) {
          result = await getData(query.join('&'), RouterQueryParams(api, queryUrl) || '')
          options.datalist.apiSingleLoad && setDatalistSingleData(result?.data || [])
        }
        if (options.datalist.apiSingleLoad) {
          result = getPaginationStaticData({
            data: datalistSingleData.length > 0 ? datalistSingleData : result?.data || [],
            size: Number(datalistQ?.size || 10),
            page: datalistQ.page,
            search: {
              value: datalistQ.search,
              options: options?.datalist?.apiSingleLoadOptions?.props,
            },
          })
        }
      } else {
        const name = `${parentName ? `${parentName}_` : ``}${
          options?.datalist?.apiInputData?.input
        }${componentIndex ? `_${componentIndex}` : ``}`
        const apiInput = options?.datalist?.apiInput ? core._data.value[name] : null
        let apiData = options?.datalist?.apiInputData ? core._apiData.value[name] : null
        apiData = apiData?.filter(
          (fl: any) =>
            fl[options?.datalist?.apiInputData?.id || 'id'].toString() ===
            core._data.value[name].toString()
        )[0]
        apiData = NullProof({
          input: apiData,
          params: options?.datalist?.apiInputData?.params || '',
          isMap: true,
        })
        const sourceData = options?.datalist?.data || apiInput || apiData || []
        const resultData = datalistQ.search
          ? sourceData?.filter((l: any) => {
              for (const ls of Object.keys(l)) {
                return l[ls].toLowerCase().includes(datalistQ.search.toLowerCase())
              }
            })
          : sourceData || []
        result = {
          status: true,
          data: resultData?.slice(((datalistQ.page || 1) - 1) * 10, (datalistQ.page || 1) * 10),
          currentPage: datalistQ.page,
          totalPages: Math.ceil((resultData?.length || 0) / 10),
          totalData: resultData?.length || 0,
          total: resultData?.length || 0,
        }
      }
      if (result.status) {
        let _result: any[] = []
        if (options?.datalist?.paramValue) {
          _result = NullProof({
            input: result?.data,
            params: options.datalist.paramValue,
          })
        } else {
          _result = result?.data
        }
        core._apiData.post({
          [name]: _result,
        })
        updateDataList(
          {
            search: datalistQ.search,
            data: _result,
            totalData: result.totalItems ?? _result.length,
            currentPage: result.currentPage ?? 1,
            totalPages: result.totalPages ?? 1,
            total: _result.length,
            loading: false,
            error: false,
          },
          datalist,
          setDataList
        )
      } else {
        updateDataList(
          {
            loading: false,
            error: true,
          },
          datalist,
          setDataList
        )
      }
      updateDataList(
        {
          loading: false,
        },
        datalist,
        setDataList
      )
    }
  }
  const initDatalist = async (_type: typeCallProps = 'onLoad') => {
    if (!options?.datalist?.isListpicker) {
      setInputError(false)
      setLoading(true)
      let query: any[] = queryBuilder({})
      const value = formikValue || core._data.value[name]
      if (value && options?.datalist?.api) {
        const api = RouterQueryParams(
          queryBuilder({api: options?.datalist?.api, type: 'api'})[0],
          queryUrl
        )
        let result: any = {status: false, data: {}}
        let detail: any = {status: false, data: {}}
        //
        try {
          result = await getData(query.join('&'), `${api}` || '')
          if (options?.datalist?.noApiDetailId && result?.status) {
            detail = {...result}
          } else {
            if (!options.select?.props?.multiple) {
              detail = await getData('', `${api}/${value}` || '')
            } else {
              detail = {...result}
            }
          }
        } catch (_) {}
        if (detail?.status) {
          if (!detail?.data && options.datalist.name) {
            detail = {
              ...detail,
              data: NullProof({
                input: core._apiData.server,
                params: options.datalist.name,
              }),
            }
          }
          let sourceDetailData = {}
          if (options?.datalist?.noApiDetailId) {
            const searchDetail: any[] = detail?.data?.filter(
              (l: any) => l[options.datalist?.id || ''] === value
            )
            sourceDetailData = searchDetail.length > 0 ? searchDetail[0] : {}
          } else {
            sourceDetailData = detail?.data
          }
          let title: any = {}
          title = sourceDetailData
          core._apiData.post({
            [`${name}_detail`]: sourceDetailData,
          })
          updateDataList(
            {
              title: dataListTitle(title),
            },
            datalist,
            setDataList
          )
        } else {
          if (result?.status) {
            const filteredData = result?.data?.filter(
              (l: any) => l[options.datalist?.id || 'id'] === value
            )
            if (filteredData?.length > 0) {
              let title: any = {}
              title = filteredData[0]
              core._apiData.post({
                [`${name}_detail`]: filteredData[0],
              })
              updateDataList(
                {
                  title: dataListTitle(title),
                },
                datalist,
                setDataList
              )
            }
          } else {
            setInputError(true)
          }
        }
        if (result?.status) {
          core._apiData.post({
            [name]: result?.data,
          })
        }
      } else if (value && !options?.datalist?.api) {
        const sourceData = options?.datalist?.data || []
        const resultId = options?.datalist?.id || ''
        const result = sourceData?.filter((l: any) => l[resultId] === value)[0]
        updateDataList(
          {
            title: sourceData?.length > 0 ? dataListTitle(result) : value,
          },
          datalist,
          setDataList
        )
      }
      core._data.post({fieldsToUpdate: {}, options: {type: _type}})
    } else {
      if (formikProps.value) {
        let _datalistCache = []
        for (const l of NullProof({
          input: formikProps,
          params: 'value',
          isMap: true,
        })) {
          _datalistCache.push({status: 'done', data: l})
        }
        setDatalistCache(_datalistCache)
      }
    }
    setLoading(false)
  }
  const initReactSelectData = () => {
    return (listData?.length || 0) > 0
      ? listDataConvert(listData)
      : options?.datalist?.id
      ? datalistToListData(datalist)
      : []
  }
  const initReactSelectDefaultValue = () => {
    let finalValue: any = null
    const value = NullProof({
      input: formikProps,
      params: 'value',
      isLabel: false,
    })
    if (!value) {
      return null
    }
    let resultListData: any[] = []
    const listData: any[] = initReactSelectData()
    const optionData = (data: any[]) => {
      data.forEach((l: any) => {
        if (l?.options) {
          optionData(l?.options)
        } else {
          resultListData.push(l)
        }
      })
    }
    optionData(listData)
    if (options?.select?.props?.multiple) {
      let resultArray: any[] = []
      try {
        value?.forEach((l: any) => {
          const detail = resultListData?.find((f: any) => f?.value === l?.id)
          if (detail) {
            resultArray.push(detail)
          }
        })
      } catch (_) {}
      finalValue = resultArray
    } else {
      const detail = resultListData.find((l: any) => l?.value === value)
      if (detail) {
        finalValue = detail
      }
    }
    return finalValue
  }

  const valueDebouce = useRef(debounce((e) => e(), 300)).current

  const updateValue = ({value, debouce = false}: {value: any; debouce?: boolean}) => {
    const updated = () => {
      core._data.post({
        fieldsToUpdate: {
          [name]: NullProof({input: value, isLabel: false}),
        },
        index: componentIndex,
      })
      core.formik.setFieldValue(name, NullProof({input: value, isLabel: false}))
    }
    if (debouce) {
      valueDebouce(updated)
    } else {
      updated()
    }
  }

  useEffect(() => {
    return () => {
      valueDebouce.cancel()
    }
  }, [valueDebouce])

  useEffect(() => {
    if (type === 'datalist') {
      initDatalist()
      // if (type === 'select') loadDataList()
    } else {
      setInputError(false)
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    const _value =
      (!formikProps.value || formikProps.value === null || formikProps.value === undefined) &&
      formikProps.value !== 0 &&
      !core._data.value[name]
    if (_value) {
      setInputUploadKey((p) => (p += 1))
      setInputKey((p) => (p += 1))
      setUploadData({
        status: false,
        title: '',
      })
      core.formik.unregisterField(name)
      core.formik.setFieldValue(name, undefined)
      core._data.post({
        fieldsToUpdate: {
          [name]: undefined,
        },
        options: {
          type: 'onReset',
        },
      })
      delete core._data.value[name]
    }
  }, [formikProps.value])
  useEffect(() => {
    let result = {...core.refreshData.value}
    if (result[name]) {
      setInputKey((p) => p + 1)
      if (type === 'datalist') {
        initDatalist('onReload')
      }
      delete result[name]
      core.refreshData.post(result)
    }
  }, [core.refreshData.value, formikValue])
  const toggleModal = (fieldsToUpdate: Partial<any>) => {
    const updatedData = Object.assign(modal, fieldsToUpdate)
    setModal((p: any) => ({...p, ...updatedData}))
  }
  const checkUploadFileType = (url: string) => {
    let type: string = 'image'
    if (url?.toLowerCase()?.indexOf('.pdf') > -1) {
      type = 'pdf'
    }
    return type
  }
  const addQueueUpload = async (files: FileList) => {
    const setup: any[] = []
    for (const l of files) {
      setup.push({
        file: l,
        name: l.name,
        status: 'queue',
      })
    }
    setFileListUpload((p) => ({...p, files: [...p.files, ...setup]}))
  }
  useEffect(() => {
    if (options?.input?.props?.type == 'file') {
      let queueData = {...fileListUpload}
      let queueFiles = queueData.files
      const startUpload = async () => {
        setLoading(true)
        const currentQueue = queueFiles[queueData.queue]
        const file = currentQueue.file
        const result = await uploadBuilder({
          file: file,
          folder: options?.upload?.url?.folder,
          path: options?.upload?.url?.path,
          limitSize: options?.upload?.size,
          config: _config,
        })
        if (result?.status) {
          currentQueue.status = 'success'
          let dataQueue
          if (options?.input?.props?.multiple) {
            dataQueue = [
              ...NullProof({
                input: formikProps,
                params: 'value',
                isMap: true,
              }),
              {name: currentQueue.name, file: result.data},
            ]
            core.formik.setFieldValue(name, dataQueue)
            core._data.post({
              fieldsToUpdate: {
                [name]: dataQueue,
              },
              index: componentIndex,
            })
          } else {
            core.formik.setFieldValue(name, result)
            core._data.post({
              fieldsToUpdate: {
                [name]: result,
              },
              index: componentIndex,
            })
          }
          queueFiles.splice(queueData.queue, 1)
        } else {
          currentQueue.status = 'error'
        }
        queueData.uploading = false
        setFileListUpload(queueData)
      }
      const startEntries = () => {
        for (const [i, l] of queueFiles.entries()) {
          if (l?.status === 'queue') {
            l.status = 'progress'
            queueData.queue = i
            queueData.uploading = true
            break
          }
        }
        setFileListUpload(queueData)
      }
      if (!queueData.uploading) {
        startEntries()
      } else {
        startUpload()
      }
      if (queueFiles.every((p) => p.status === 'success')) {
        setLoading(false)
      }
    }
  }, [fileListUpload.files, fileListUpload.uploading])
  const uploadProgress = async (file: File) => {
    setLoading(true)
    const result = await uploadBuilder({
      file: file,
      folder: options?.upload?.url?.folder,
      path: options?.upload?.url?.path,
      limitSize: options?.upload?.size,
      config: _config,
    })
    if (result?.status) {
      setUploadData({
        status: true,
        title: file.name,
      })
      core._data.post({
        fieldsToUpdate: {
          [name]: result.data,
        },
        index: componentIndex,
      })
      core.formik.setFieldValue(name, file.name)
    }
    core.setError({field: name, message: result.message})
    setLoading(false)
  }
  const titleUiValue = (props: any, defaultValue?: string) => {
    const _props = props || options?.input
    const _defaultValue = defaultValue || _props?.props?.title || _props?.props?.placeholder
    return intlMessage(
      `${i18nConfig}.${name}`,
      intlMessage(`${i18nConfig}.${_props?.props?.title}`, _defaultValue, intl),
      intl
    )
  }
  const titleUi = ({props}: {props: any}) => {
    const isRequired =
      validator?.tests?.some((e: any) => e?.OPTIONS?.name === 'required') ||
      options?.input?.props?.required
    const _props = props || options?.input
    return (
      <>
        {!options?.label?.hideLabel && (_props?.props?.title || options?.input?.props?.title) && (
          <>
            {isLabelType === 'floating' ? (
              <label className='mw-100' htmlFor={name}>
                <span className='text-muted'>
                  {titleUiValue(_props, `${_props?.props?.title || options?.input?.props?.title}`)}
                </span>
                <span className='text-danger fw-bold'>{`${isRequired ? '*' : ''}`}</span>
              </label>
            ) : (
              <div className='mb-2 d-flex gap-1'>
                <div>
                  {titleUiValue(_props, `${_props?.props?.title || options?.input?.props?.title}`)}
                </div>
                <div className='text-danger fw-bold fs-4'>{`${isRequired ? '*' : ''}`}</div>
              </div>
            )}
          </>
        )}
      </>
    )
  }
  const noteUi = (props: any) => {
    const _props = props || {}
    return (
      <>
        {(_props?.props?.title ||
          _props?.props?.description ||
          _props?.title ||
          _props?.description) && (
          <div className='my-2'>
            {_props?.props?.title ||
              _props?.props?.description ||
              _props?.title ||
              _props?.description}
          </div>
        )}
      </>
    )
  }
  const baseInputProps = (props: any) => {
    let _title
    let _placeholder
    const defaultTitle = props?.title || options?.input?.props?.title
    const defaultPlaceholder =
      props?.placeholder ||
      props?.title ||
      options?.input?.props?.placeholder ||
      options?.input?.props?.title
    _title = intlMessage(
      `${i18nConfig}.${name}`,
      intlMessage(`${i18nConfig}.${defaultTitle}`, defaultTitle, intl),
      intl
    )
    _placeholder = intlMessage(
      `${i18nConfig}.${name}.placeholder`,
      intlMessage(`${i18nConfig}.${defaultPlaceholder}.placeholder`, _title, intl),
      intl
    )
    let _props = {
      ...props,
      title: _title,
      placeholder: _placeholder,
      disabled: core?.formLock || props?.disabled || !checkWatchProps() || disabled,
    }
    if (!props) {
      _title = intlMessage(
        `${i18nConfig}.${name}`,
        intlMessage(
          `${i18nConfig}.${options?.input?.props?.title}`,
          options?.input?.props?.title || '',
          intl
        ),
        intl
      )
      _placeholder = intlMessage(
        `${i18nConfig}.${name}.placeholder`,
        intlMessage(
          `${i18nConfig}.${options?.input?.props?.placeholder}.placeholder`,
          _title,
          intl
        ),
        intl
      )
      _props = {
        ..._props,
        title: _title,
        placeholder: _placeholder,
        disabled:
          core?.formLock || options?.input?.props?.disabled || !checkWatchProps() || disabled,
      }
    }
    return _props
  }
  const debugUi = () => {
    return (
      <>
        {isDebugging && (
          <>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Name: {JSON.stringify(name)}</code>
            </pre>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Data: {JSON.stringify(core._data.value[name])}</code>
            </pre>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>Formik: {JSON.stringify(formikProps.value)}</code>
            </pre>
          </>
        )}
      </>
    )
  }
  const updateBindInput = () => {
    const input: FormInputProps[] = core.input.value
    const DropdownInput = (l: FormInputValidatorProps) => {
      if (l.options?.input?.bindInput) {
        const targetApi = l.options?.input?.bindInput.input
        const targetName = l?.parentId ? `${l?.parentId}_${targetApi}_${l.index}` : targetApi
        if (targetName === name) {
          if (core._apiData.value[targetApi]) {
            try {
              const dataInputApi = core._apiData.value[targetApi]?.filter(
                (ls: any) =>
                  ls[l.options?.input?.bindInput?.id || 'id'] === core._data.value[targetName]
              )[0]
              const paramsList = l.options.input.bindInput.params?.split(',')
              let paramsIndex = 0
              for (const params of paramsList) {
                let dataApi = dataInputApi[params] || ''
                if (!dataApi) {
                  paramsIndex++
                  continue
                }
                if (l.options?.input.bindInput.result) {
                  dataApi = l.options?.input.bindInput.result[paramsIndex]?.replaceAll(
                    `$${params}`,
                    dataApi
                  )
                }
                core._data.post({
                  fieldsToUpdate: {[l.name]: dataApi},
                  options: {
                    refresh: false,
                  },
                  index: componentIndex,
                })
                core.formik.setFieldValue(l.name, dataApi)
              }
            } catch (_) {}
          }
        }
      }
      if ((l.component?.length || 0) > 0) {
        l.component?.map((lsub, isub) => {
          const name = `${l.name}_${lsub.name}_${isub + 1}`
          DropdownInput({
            ...lsub,
            name: name,
            parentName: lsub.name,
            parentId: l.name,
            index: isub,
          })
        })
      }
    }
    input.map((l, i) => {
      DropdownInput(l)
    })
  }
  if (hide) {
    return <></>
  }
  const InputSidebutton = ({children}: WithChildren) => (
    <>
      {!options?.sidebutton?.title || options?.sidebutton?.iconOnly ? (
        <>{children}</>
      ) : (
        <>
          <div className={`mx-0 row g-2`}>
            <div className='col'>{children}</div>
            <div className='col-auto d-flex align-items-center'>
              <ButtonLoading
                // @ts-ignore
                icon={options?.sidebutton?.icon || 'RiInformationLine'}
                title={{
                  button: options?.sidebutton?.title || 'Button',
                }}
                props={{
                  onClick: () => {
                    core._data.post({
                      fieldsToUpdate: {},
                      options: {refresh: true, type: 'onClick'},
                      id: name,
                      index: componentIndex,
                    })
                  },
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
  let DatalistCacheUi = () => (
    <>
      {options?.datalist?.isListpicker && datalistCache.length > 0 && (
        <div className='border rounded p-4 mb-2'>
          <div className='d-flex justify-content-between align-items-center gap-2 mb-2'>
            <div className='fw-bold fs-4'>
              {intlMessage('component.forminput.datalist.checked.title', 'Selected data', intl)}
            </div>
            <button
              type='button'
              className='btn btn-danger'
              onClick={async () => {
                if (
                  await PopupConfirm({
                    intl: intl,
                    validated: options?.validator?.useReconfirmDelete,
                    hasConfirm: false,
                  })
                ) {
                  setDatalistCache([])
                  core.formik.setFieldValue(name, [])
                }
              }}
            >
              {intlMessage('component.forminput.datalist.checked.deleteall', 'Delete All', intl)}
            </button>
          </div>
          <div className='overflow-y-auto' style={{maxHeight: '200px'}}>
            <div className='d-flex flex-wrap gap-4'>
              {datalistCache?.map((l: any, i: number) => (
                <Fragment key={i}>
                  <div className='bg-light py-2 px-4 border rounded d-flex gap-4 align-items-center'>
                    {Object.keys(l?.data || {}).length > 0 ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: dataListTitle(l?.data || {}) || '',
                          }}
                        ></div>
                        <div
                          className='fs-2 cursor-pointer text-danger'
                          onClick={() => {
                            updateDatalistCache({
                              id: options?.datalist?.id,
                              data: {},
                              value: NullProof({
                                input: l,
                                params: `data.${options?.datalist?.id}`,
                                isLabel: true,
                              }),
                            })
                          }}
                        >
                          <ReactIcon icon='RiDeleteBinLine' />
                        </div>
                      </>
                    ) : (
                      <div className='d-flex gap-2 align-items-center'>
                        {NullProof({input: l, params: 'status'}) === 'pending' && (
                          <>
                            <div>
                              {intlMessage(
                                'component.forminput.datalist.loading',
                                'Loading Data',
                                intl
                              )}
                              ...
                            </div>
                            <div className='spinner-border spinner-border-sm' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </>
                        )}
                        {NullProof({input: l, params: 'status'}) === 'error' && (
                          <>
                            <div>
                              {intlMessage(
                                'component.forminput.datalist.error',
                                'Loading Data',
                                intl
                              )}
                            </div>
                            <div
                              className='fs-2 cursor-pointer'
                              onClick={() => {
                                const result = [...datalistCache]
                                const target = result[i]
                                target.status = 'pending'
                                setDatalistCache((p) => [...result])
                              }}
                            >
                              <ReactIcon icon='RiRestartLine' />
                            </div>
                            <div
                              className='fs-2 cursor-pointer text-danger'
                              onClick={() => {
                                updateDatalistCache({
                                  id: options?.datalist?.id,
                                  data: {},
                                  value: NullProof({
                                    input: l,
                                    params: `data.${options?.datalist?.id}`,
                                    isLabel: true,
                                  }),
                                })
                              }}
                            >
                              <ReactIcon icon='RiDeleteBinLine' />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
  const titleUiData: any =
    type === 'input'
      ? options?.input
      : type === 'textarea'
      ? options?.textarea
      : type === 'select'
      ? options?.select
      : type === 'label'
      ? options?.label
      : options?.input
  const isLabelType = _config.labelType || options?.input?.labelType || 'floating'
  const useLabelFloatingClass =
    isLabelType === 'floating' &&
    !options?.label?.hideLabel &&
    (titleUiData?.props?.title || options?.input?.props?.title)
  const _labelType = options?.label?.labelType || 'label'
  const _labelPosition = options?.label?.labelPosition || 'top'
  return (
    <div className={`${className || 'col-12'} ${hide ? 'd-none' : ''}`}>
      {type !== 'label' && isLabelType === 'default' && titleUi({props: titleUiData})}
      {type !== 'label' && _labelPosition === 'top_input' && noteUi(options?.label)}
      {type === 'datalist' && (
        <>
          <Modal
            id={`${formName ? `${formName}_` : ``}${name}`}
            title={`${intlMessage(
              'component.forminput.select.placeholder_leading',
              'Select',
              intl
            )} ${
              options?.datalist?.title ||
              options?.input?.props?.title ||
              options?.input?.props?.placeholder ||
              intlMessage('component.forminput.datalist.title', 'List Data', intl)
            }`}
            isShow={modal.status}
            onClose={() => {
              toggleModal({status: false})
            }}
          >
            {<DatalistCacheUi />}
            <div className='d-flex flex-column gap-2'>
              {options?.datalist?.type === 'table' ? (
                <Fragment key={datalistKey}>
                  <TableInput
                    {...options.datalist?.table?.props}
                    onSuccess={(val: any) => setTableDatalist(val)}
                    sidebar={pluginDataTableInput?.sidebar}
                    toolbar={pluginDataTableInput?.toolbar}
                    modal={pluginDataTableInput?.modal}
                    checkbox={pluginDataTableInput?.checkbox}
                    options={{
                      isModal: true,
                    }}
                    route={{
                      url:
                        RouterQueryParams(
                          queryBuilder({
                            api:
                              options?.datalist?.table?.props?.route?.url || options?.datalist?.api,
                            type: 'api',
                          })[0],
                          queryUrl
                        ) || '',
                      query: options?.datalist?.table?.props?.route?.query,
                      singleLoad:
                        options?.datalist?.apiSingleLoad ||
                        options?.datalist?.table?.props?.route?.singleLoad,
                    }}
                    onLoad={
                      options?.datalist?.useOnLoad && onLoad
                        ? async (e) => {
                            onLoad({
                              id: name,
                              data: await returnDataBuilder(),
                              query: e.query,
                              initLoading: e.initLoading,
                              load: e.load,
                            })
                          }
                        : undefined
                    }
                    onFeedback={(val: any) => {
                      setTableInputFeedback({
                        type: 'table',
                        data: val,
                      })
                      onFeedback &&
                        onFeedback({
                          type: 'table',
                          data: val,
                        })
                    }}
                  >
                    {tableDatalist?.data?.map((l: any, i: number) => {
                      const _id: string = options?.datalist?.id || ''
                      const selectData = () => {
                        if (!options?.datalist?.isListpicker) {
                          updateDataList(
                            {
                              title: dataListTitle(l),
                            },
                            datalist,
                            setDataList
                          )
                          core._apiData.post({
                            [`${name}_detail`]: l,
                          })
                          core._data.post({
                            fieldsToUpdate: {
                              [name]: l[_id],
                            },
                            index: componentIndex,
                          })
                          core.formik.setFieldValue(name, l[_id])
                          const autoFillList = options?.datalist?.autoFillInput?.split(',') || []
                          for (const lsub of autoFillList) {
                            const lValue = lsub.split('=')
                            core.formik.setFieldValue(lValue[0], l[lValue[1]] || '')
                          }
                          updateBindInput()
                          toggleModal({status: false})
                        } else {
                          updateDatalistCache({
                            data: l,
                            value: l[_id],
                            id: _id,
                          })
                        }
                      }
                      return (
                        <Fragment key={i}>
                          <TableRow
                            onClick={selectData}
                            input={{data: l, index: i}}
                            numbering={{
                              n: i,
                              p: tableDatalist?.page,
                              t: tableDatalist?.size,
                            }}
                            props={{
                              className: `cursor-pointer ${
                                options?.datalist?.isListpicker &&
                                NullProof({
                                  input: formikProps,
                                  params: 'value',
                                  isMap: true,
                                })?.some((e: any) => e[_id] === l[_id])
                                  ? 'table-primary'
                                  : ''
                              }`,
                            }}
                          >
                            {options?.datalist?.table?.data.map((ltd, itd) => {
                              return (
                                <Fragment key={itd}>
                                  <td className={`${ltd.className}`}>
                                    {ltd.type !== 'map' ? (
                                      <>
                                        {ltd.result ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: dataListTitle(l, {
                                                ...ltd,
                                              }),
                                            }}
                                          ></div>
                                        ) : (
                                          NullProof({input: l, params: ltd.id, type: ltd.type})
                                        )}
                                      </>
                                    ) : (
                                      NullProof({
                                        input: l,
                                        params: ltd.id,
                                        isMap: true,
                                      })?.map((ldt: any, idt: number) => {
                                        const idParams = ltd?.map || []
                                        return (
                                          <Fragment key={idt}>
                                            <div>
                                              {idt + 1}.{' '}
                                              {NullProof({
                                                input: ldt,
                                                params: idParams[idt].id || '',
                                                type: ltd.type,
                                              })}
                                            </div>
                                          </Fragment>
                                        )
                                      })
                                    )}
                                  </td>
                                </Fragment>
                              )
                            })}
                          </TableRow>
                        </Fragment>
                      )
                    })}
                  </TableInput>
                  {options?.datalist?.isListpicker && (
                    <div className='d-flex justify-content-end gap-2'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => toggleModal({status: false})}
                      >
                        Simpan
                      </button>
                    </div>
                  )}
                </Fragment>
              ) : (
                <>
                  <div className='input-group input-group-solid mb-2'>
                    <input
                      id={`${formName ? `${formName}_` : ``}${name}_search`}
                      disabled={datalist?.error}
                      type='text'
                      className='form-control'
                      placeholder={`${intlMessage('global.search', 'Search', intl)}...`}
                      value={datalistQ?.search}
                      onChange={(e) => {
                        updateDataList({search: e.target.value}, datalistQ, setDataListQ)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          loadDataList()
                        }
                      }}
                    />
                    <button
                      disabled={datalist?.error}
                      type='button'
                      className={`btn ${
                        (datalistQ?.search === datalist?.search && datalist?.search?.length > 0) ||
                        (!datalistQ?.search && datalist?.search?.length > 0)
                          ? 'btn-danger'
                          : 'btn-primary'
                      } d-flex align-items-center gap-1`}
                      onClick={() => {
                        if (
                          datalistQ?.search === datalist?.search &&
                          datalist?.search?.length > 0
                        ) {
                          updateDataList({search: ''}, datalistQ, setDataListQ)
                        }
                        loadDataList()
                      }}
                    >
                      {(datalistQ?.search === datalist?.search && datalist?.search?.length > 0) ||
                      (!datalistQ?.search && datalist?.search?.length > 0) ? (
                        <>
                          <ReactIcon icon='RiCloseLine' props={{className: 'fs-3'}} />
                        </>
                      ) : (
                        <>
                          <ReactIcon icon='RiSearchLine' props={{className: 'fs-3'}} />
                          {`${intlMessage('global.search', 'Search', intl)}`}
                        </>
                      )}
                    </button>
                  </div>
                  <div
                    className='d-flex flex-column gap-2 overflow-y-auto position-relative'
                    style={{
                      height: '200px',
                    }}
                  >
                    {datalist?.loading && (
                      <div
                        className='position-absolute w-100 h-100 top-0 left-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center'
                        style={{
                          zIndex: 5,
                        }}
                      >
                        <div>
                          <div className='spinner-border' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {!datalist?.loading &&
                      datalist?.data?.map((l: any, i: number) => {
                        const _id: string = options?.datalist?.id || ''
                        return (
                          <button
                            key={i}
                            type='button'
                            className={`btn ${
                              options?.datalist?.isListpicker &&
                              NullProof({
                                input: formikProps,
                                params: 'value',
                                isMap: true,
                              })?.some((e: any) => e[_id] === l[_id])
                                ? 'btn-primary'
                                : 'btn-light'
                            } text-start`}
                            onClick={() => {
                              if (!options?.datalist?.isListpicker) {
                                updateDataList(
                                  {
                                    title: dataListTitle(l),
                                  },
                                  datalist,
                                  setDataList
                                )
                                core._apiData.post({
                                  [`${name}_detail`]: l,
                                })
                                core._data.post({
                                  fieldsToUpdate: {
                                    [name]: l[_id],
                                  },
                                  index: componentIndex,
                                })
                                const autoFillList =
                                  options?.datalist?.autoFillInput?.split(',') || []
                                for (const lsub of autoFillList) {
                                  const lValue = lsub.split('=')
                                  core.formik.setFieldValue(lValue[0], l[lValue[1]] || '')
                                }
                                core.formik.setFieldValue(name, l[_id])
                                updateBindInput()
                                toggleModal({status: false})
                              } else {
                                updateDatalistCache({
                                  data: l,
                                  value: l[_id],
                                  id: _id,
                                })
                              }
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: dataListTitle(l) || '',
                              }}
                            ></div>
                          </button>
                        )
                      })}
                    {!datalist?.loading && (
                      <>
                        {datalist?.data?.length > 0 ? (
                          <></>
                        ) : (
                          <>
                            <div
                              className='p-4 d-flex flex-column justify-content-center align-items-center'
                              style={{
                                minHeight: '200px',
                              }}
                            >
                              {datalist?.error ? (
                                <div className='d-flex flex-column align-items-center gap-2'>
                                  <div className='text-center'>
                                    <div className='fs-3 fw-bold'>
                                      {intlMessage(
                                        `component.forminput.datalist.modal.error_server`,
                                        'Failed to connecting server',
                                        intl
                                      )}
                                    </div>
                                    <div>
                                      {intlMessage(
                                        `component.forminput.datalist.modal.error_server.description`,
                                        'Please try again later',
                                        intl
                                      )}
                                    </div>
                                  </div>
                                  <ButtonLoading
                                    icon='RiRefreshLine'
                                    title={{
                                      button: intlMessage(
                                        `component.forminput.datalist.button.refresh`,
                                        'Refresh',
                                        intl
                                      ),
                                    }}
                                    props={{
                                      onClick: () => loadDataList(),
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className='fs-3 fw-bold'>Belum ada data</div>
                                  <div>Data tidak ditemukan</div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className='mt-4'>
                    <Pagination
                      disabled={datalist?.error}
                      currentPage={datalist?.currentPage}
                      maxButtons={3}
                      totalPages={datalist?.totalPages || 1}
                      onPageChange={(e) => {
                        if (datalist?.currentPage !== e) {
                          updateDataList({page: e}, datalistQ, setDataListQ)
                          loadDataList()
                        }
                      }}
                    />
                  </div>
                  {options?.datalist?.isListpicker && (
                    <div className='d-flex justify-content-end gap-2'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => toggleModal({status: false})}
                      >
                        Simpan
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal>
        </>
      )}
      {InputSidebutton({
        children: (
          <>
            {type === 'input' && (
              <>
                {options?.input?.props?.type === 'date' ? (
                  <ReactDatepicker
                    label={titleUi({props: titleUiData})}
                    value={NullProof({
                      input: formikProps,
                      params: 'value',
                      isLabel: false,
                    })}
                    props={{
                      ...baseInputProps(options?.input?.props),
                      ...{
                        showTimeSelect:
                          options?.input?.dateOption === 'time' ||
                          options?.input?.dateOption === 'datetime',
                      },
                      ...{
                        showTimeSelectOnly: options?.input?.dateOption === 'time',
                      },
                    }}
                    labelType={isLabelType}
                    onChange={(val: any) => updateValue({value: val?.toJSON()})}
                  />
                ) : (
                  <>
                    {options?.input?.props?.type === 'checkbox' ||
                    options?.input?.props?.type === 'switch' ? (
                      <div
                        className={`form-check ${
                          options?.input?.props?.type === 'switch' ? 'form-switch' : ''
                        } d-flex align-items-center gap-2`}
                      >
                        <input
                          key={inputKey}
                          id={`${formName ? `${formName}_` : ``}${name}`}
                          type='checkbox'
                          className='form-check-input form-check-input-lg cursor-pointer'
                          {...baseInputProps({...options?.input?.props, type: 'checkbox'})}
                          defaultChecked={NullProof({
                            input: formikProps,
                            params: 'value',
                            isLabel: false,
                          })}
                          onChange={(val) =>
                            updateValue({value: val.target.checked, debouce: true})
                          }
                        />
                        <label
                          className='form-check-label text-dark cursor-pointer user-select-none'
                          htmlFor={name}
                        >
                          {titleUiValue(titleUiData)}
                        </label>
                      </div>
                    ) : (
                      <>
                        <div
                          className={`${useLabelFloatingClass ? 'form-floating' : ''} d-flex gap-4`}
                        >
                          {options?.input?.isCurrency ||
                          options?.input?.isDate ||
                          options?.currency?.useCurrency ? (
                            <CurrencyFormat
                              className={`form-control form-control-lg ${
                                formikValue ? '' : 'text-muted'
                              }`}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              prefix={
                                options?.currency?.props?.prefix
                                  ? options?.currency?.props?.prefix
                                  : options?.input?.isCurrency
                                  ? options?.currency?.props?.prefix || 'Rp. '
                                  : ''
                              }
                              suffix={
                                options?.currency?.props?.suffix
                                  ? options?.currency?.props?.suffix
                                  : options?.input?.isDate
                                  ? ` ${intlMessage(
                                      'component.forminput.number.day.placeholder',
                                      'Day',
                                      intl
                                    )}`
                                  : ''
                              }
                              min={0}
                              {...baseInputProps(options?.currency?.props)}
                              displayType={
                                options.input?.bindInput
                                  ? 'text'
                                  : options?.currency?.props?.displayType || 'input'
                              }
                              value={formikValue || ''}
                              onChange={() => {}}
                              onValueChange={(val) =>
                                updateValue({value: Number(val.value), debouce: true})
                              }
                            />
                          ) : (
                            <>
                              {(options?.input?.props?.type || 'text') !== 'file' && (
                                <>
                                  <input
                                    key={inputKey}
                                    className='form-control form-control-lg'
                                    {...baseInputProps(options?.input?.props)}
                                    type={showPassword ? 'text' : options?.input?.props?.type}
                                    defaultValue={formikValue}
                                    onChange={(val) => {
                                      if (options?.input?.props?.type !== 'text') {
                                        updateValue({value: val.target.value, debouce: true})
                                      }
                                    }}
                                  />
                                  {options?.sidebutton?.iconOnly && (
                                    <div className='position-absolute h-100 w-50px end-0 d-flex align-items-center justify-content-center'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-active-color-primary'
                                        onClick={() => {
                                          if (options.input?.props?.type === 'password') {
                                            setShowPassword((p) => !p)
                                          } else {
                                            core._data.post({
                                              fieldsToUpdate: {},
                                              options: {refresh: true, type: 'onClick'},
                                              id: name,
                                              index: componentIndex,
                                            })
                                          }
                                        }}
                                      >
                                        <ReactIcon
                                          // @ts-ignore
                                          icon={
                                            options.input?.props?.type === 'password'
                                              ? showPassword
                                                ? 'RiEyeLine'
                                                : 'RiEyeCloseLine'
                                              : options?.sidebutton?.icon
                                          }
                                          props={{className: 'w-20px h-20px'}}
                                        />
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {options?.input?.props?.type === 'file' && (
                            <>
                              <Modal
                                id={`${formName ? `${formName}_` : ``}${name}-cropper`}
                                title={'Crop Foto'}
                                isShow={modalCropper?.status}
                                onClose={() => {
                                  setModalCropper((p) => ({
                                    ...p,
                                    status: false,
                                  }))
                                }}
                              >
                                <ReactCropper
                                  data={modalCropper?.file}
                                  onSave={async (file) => {
                                    uploadProgress(file)
                                    setModalCropper((p) => ({
                                      ...p,
                                      status: false,
                                    }))
                                  }}
                                  options={{
                                    filename: filenameGenerator({
                                      initName:
                                        options?.upload?.url?.filename ||
                                        _config.upload?.filename ||
                                        'untitled',
                                      uniqueId:
                                        options?.upload?.url?.uniqueId ||
                                        _config.upload?.uniqueId ||
                                        'untitled',
                                    }),
                                    aspect: options.imageCropper?.aspect,
                                    shape: options.imageCropper?.shape,
                                  }}
                                />
                              </Modal>
                              <Modal
                                id={`${formName ? `${formName}_` : ``}${name}`}
                                title={
                                  options?.upload?.title ||
                                  options?.input?.props?.placeholder ||
                                  options?.input?.props?.title
                                }
                                isShow={modal.status && modal?.data}
                                onClose={() => {
                                  toggleModal({status: false})
                                }}
                              >
                                {checkUploadFileType(modal?.data) === 'pdf' ? (
                                  <>
                                    <ReactPdfViewer url={`/${modal?.data}`} />
                                  </>
                                ) : (
                                  <img
                                    className='ratio ratio-1x1'
                                    src={`${API_URL}/${modal?.data}`}
                                    alt={name}
                                  />
                                )}
                              </Modal>
                              <input
                                key={`upload-${inputUploadKey}`}
                                id={`${formName ? `${formName}_` : ``}${name}`}
                                name={name}
                                type='file'
                                className={`form-control form-control-lg d-none`}
                                {...baseInputProps(options?.input?.props)}
                                accept={
                                  options?.input?.props?.accept
                                    ? options?.input?.props?.accept
                                    : '.pdf, .jpg, .jpeg, .png'
                                }
                                onChange={async (val) => {
                                  try {
                                    if (val.target.files) {
                                      setDefaultValue(core._data.value[name])
                                      const files: FileList = val.target.files
                                      const file: File = files[0]
                                      if (
                                        file.type.includes('image') &&
                                        file &&
                                        options?.input?.useImageCrop &&
                                        !options.input.props?.multiple
                                      ) {
                                        setModalCropper((p) => ({
                                          ...p,
                                          file: file,
                                          status: true,
                                        }))
                                      } else {
                                        if (options.input?.props?.multiple) {
                                          addQueueUpload(files)
                                        } else {
                                          uploadProgress(file)
                                        }
                                      }
                                      setInputUploadKey((p) => (p += 1))
                                    }
                                  } catch (_) {}
                                }}
                              />
                              <div
                                className={`form-control form-control-lg ${
                                  loading || core?.formLock || !checkWatchProps() || disabled
                                    ? 'bg-light'
                                    : ''
                                }`}
                                style={{gap: '10px', height: 'unset'}}
                              >
                                {options?.input?.props?.type === 'file' &&
                                  options?.input?.props?.multiple &&
                                  ((NullProof({
                                    input: fileListUpload,
                                    params: 'files',
                                    isMap: true,
                                  })?.length || 0) > 0 ||
                                    (NullProof({
                                      input: formikProps,
                                      params: 'value',
                                      isMap: true,
                                    })?.length || 0) > 0) && (
                                    <div>
                                      <div className='d-flex flex-wrap gap-2'>
                                        {NullProof({
                                          input: fileListUpload,
                                          params: 'files',
                                          isMap: true,
                                        })?.map((l: any, i: number) => (
                                          <Fragment key={0}>
                                            <div
                                              className={`${
                                                l?.status === 'error'
                                                  ? 'bg-danger text-white'
                                                  : 'bg-light'
                                              } px-4 py-2 d-flex align-items-center gap-2`}
                                            >
                                              <div>
                                                {NullProof({
                                                  input: l,
                                                  params: 'name',
                                                })}
                                              </div>
                                              <div className='d-flex align-items-center gap-2'>
                                                {NullProof({
                                                  input: l,
                                                  params: 'status',
                                                }) === 'queue' && (
                                                  <>
                                                    <div
                                                      className='fs-2 cursor-pointer'
                                                      onClick={() => {
                                                        const result = [...fileListUpload.files]
                                                        result.splice(i, 1)
                                                        setFileListUpload((p) => ({
                                                          ...p,
                                                          files: result,
                                                        }))
                                                      }}
                                                    >
                                                      <ReactIcon icon='RiCloseLine' />
                                                    </div>
                                                  </>
                                                )}
                                                {NullProof({
                                                  input: l,
                                                  params: 'status',
                                                }) === 'progress' && (
                                                  <>
                                                    <div
                                                      className='spinner-border spinner-border-sm'
                                                      role='status'
                                                    >
                                                      <span className='sr-only'>Loading...</span>
                                                    </div>
                                                  </>
                                                )}
                                                {NullProof({
                                                  input: l,
                                                  params: 'status',
                                                }) === 'error' && (
                                                  <>
                                                    <div
                                                      className='fs-2 cursor-pointer'
                                                      onClick={() => {
                                                        const result = [...fileListUpload.files]
                                                        const target = result[i]
                                                        target.status = 'queue'
                                                        setFileListUpload((p) => ({
                                                          ...p,
                                                          files: result,
                                                        }))
                                                      }}
                                                    >
                                                      <ReactIcon icon='RiRestartLine' />
                                                    </div>
                                                    <div
                                                      className='fs-2 cursor-pointer'
                                                      onClick={() => {
                                                        const result = [...fileListUpload.files]
                                                        result.splice(i, 1)
                                                        setFileListUpload((p) => ({
                                                          ...p,
                                                          files: result,
                                                        }))
                                                      }}
                                                    >
                                                      <ReactIcon icon='RiCloseLine' />
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </Fragment>
                                        ))}
                                        {NullProof({
                                          input: formikProps,
                                          params: 'value',
                                          isMap: true,
                                        })?.map((l: any, i: number) => (
                                          <Fragment key={i}>
                                            <div className='bg-light px-4 py-2 d-flex align-items-center gap-2'>
                                              <div>
                                                {NullProof({
                                                  input: l,
                                                  params: 'name',
                                                })}
                                              </div>
                                              <div className='d-flex align-items-center gap-2'>
                                                <div
                                                  className='fs-2 cursor-pointer'
                                                  onClick={() => {
                                                    toggleModal({
                                                      status: true,
                                                      data: l?.file,
                                                    })
                                                  }}
                                                >
                                                  <ReactIcon icon='RiEyeLine' />
                                                </div>
                                                <div
                                                  className='fs-2 cursor-pointer text-danger'
                                                  onClick={async () => {
                                                    if (
                                                      await PopupConfirm({
                                                        intl: intl,
                                                        validated:
                                                          options?.validator?.useReconfirmDelete,
                                                        hasConfirm: false,
                                                      })
                                                    ) {
                                                      const result = [
                                                        ...NullProof({
                                                          input: formikProps,
                                                          params: 'value',
                                                          isMap: true,
                                                        }),
                                                      ]
                                                      result.splice(i, 1)
                                                      core.formik.setFieldValue(name, result)
                                                      core._data.post({
                                                        fieldsToUpdate: {
                                                          [name]: result,
                                                        },
                                                        index: componentIndex,
                                                      })
                                                    }
                                                  }}
                                                >
                                                  <ReactIcon icon='RiDeleteBinLine' />
                                                </div>
                                              </div>
                                            </div>
                                          </Fragment>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                <div className='row d-flex flex-column flex-md-row align-items-center mx-auto'>
                                  <div
                                    className={`col text-truncate text-start ps-0 ${
                                      !uploadData.title ? 'text-muted' : ''
                                    } ${
                                      !(loading || core?.formLock || !checkWatchProps() || disabled)
                                        ? 'cursor-pointer'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      if (
                                        !uploadData.title &&
                                        !(
                                          loading ||
                                          core?.formLock ||
                                          !checkWatchProps() ||
                                          disabled
                                        )
                                      ) {
                                        document
                                          .getElementById(
                                            `${formName ? `${formName}_` : ``}${name}`
                                          )
                                          ?.click()
                                      } else if (
                                        !options?.input?.props?.multiple &&
                                        core._data.value[name] &&
                                        'pdf,jpg,jpeg,png'
                                          .split(',')
                                          .includes(core._data.value[name].split('.').pop())
                                      ) {
                                        toggleModal({
                                          status: true,
                                          data: core._data.value[name],
                                        })
                                      }
                                    }}
                                  >
                                    {options?.input?.props?.multiple ? (
                                      <>
                                        {NullProof({
                                          input: formikProps,
                                          params: 'value',
                                          isMap: true,
                                        })?.length > 0
                                          ? `${intlMessage(
                                              'component.forminput.input.file.placeholder',
                                              'Select File',
                                              intl
                                            )}...`
                                          : `${intlMessage(
                                              'component.forminput.input.file.placeholder',
                                              'Select File',
                                              intl
                                            )}...`}
                                      </>
                                    ) : (
                                      <>
                                        {uploadData.title ||
                                          (formikValue || core._data.value[name]
                                            ? checkFileExtension(
                                                formikValue || core._data.value[name]
                                              ).name
                                            : `${intlMessage(
                                                'component.forminput.input.file.placeholder',
                                                'Select File',
                                                intl
                                              )}...`)}
                                      </>
                                    )}
                                  </div>
                                  <div className='col-auto d-flex gap-2'>
                                    {!options?.input?.props?.multiple &&
                                      core._data.value[name] &&
                                      typeof core._data.value[name] === 'string' &&
                                      'pdf,jpg,jpeg,png'
                                        .split(',')
                                        .includes(
                                          core._data.value[name]?.split('.')?.pop() || ''
                                        ) && (
                                        <button
                                          type='button'
                                          className='btn btn-sm btn-primary'
                                          disabled={
                                            loading ||
                                            core?.formLock ||
                                            !checkWatchProps() ||
                                            disabled
                                          }
                                          onClick={() => {
                                            toggleModal({
                                              status: true,
                                              data: core._data.value[name],
                                            })
                                          }}
                                        >
                                          Preview
                                        </button>
                                      )}
                                    <button
                                      type='button'
                                      className={`btn btn-sm ${
                                        uploadData.status ? 'btn-danger' : 'btn-primary'
                                      }`}
                                      disabled={
                                        loading || core?.formLock || !checkWatchProps() || disabled
                                      }
                                      data-kt-indicator={loading ? 'on' : 'off'}
                                      onClick={async () => {
                                        if (uploadData.status) {
                                          if (
                                            await PopupConfirm({
                                              intl: intl,
                                              validated: options?.validator?.useReconfirmDelete,
                                              hasConfirm: false,
                                            })
                                          ) {
                                            setUploadData({
                                              status: false,
                                              title: '',
                                            })
                                            core.formik?.setFieldValue(
                                              name,
                                              defaultValue ? defaultValue : null
                                            )
                                            core._data.post({
                                              fieldsToUpdate: {
                                                [name]: defaultValue ? defaultValue : null,
                                              },
                                              index: componentIndex,
                                            })
                                            setInputUploadKey((p) => (p += 1))
                                          }
                                        } else {
                                          document
                                            .getElementById(
                                              `${formName ? `${formName}_` : ``}${name}`
                                            )
                                            ?.click()
                                        }
                                      }}
                                    >
                                      <span className='indicator-label'>
                                        {uploadData.status ? 'Hapus' : 'Upload'}
                                      </span>
                                      <span className='indicator-progress'>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {isLabelType === 'floating' && titleUi({props: titleUiData})}
                        </div>
                        {options?.input?.props?.type === 'password' &&
                          !options?.sidebutton?.iconOnly && (
                            <div className='form-check d-flex align-items-center gap-2 mt-4'>
                              <input
                                key={inputKey}
                                id={`${formName ? `${formName}_` : ``}check-${name}`}
                                type='checkbox'
                                className='form-check-input form-check-input-lg cursor-pointer min-h-25px min-w-25px h-25px w-25px'
                                onChange={(val) => {
                                  setShowPassword((p) => !p)
                                }}
                              />
                              <label
                                className='form-check-label text-dark cursor-pointer user-select-none'
                                htmlFor={`check-${name}`}
                              >
                                {intlMessage('global.view', 'Show', intl)}{' '}
                                {options?.input?.props?.placeholder}
                              </label>
                            </div>
                          )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {type === 'textarea' && (
              <>
                {options?.textarea?.useTextEditor ? (
                  <>
                    <ReactTiptap
                      value={NullProof({
                        input: formikProps,
                        params: 'value',
                        isLabel: false,
                      })}
                      onChange={(val) => updateValue({value: val.html, debouce: true})}
                      options={{
                        props: baseInputProps(options?.textarea?.props),
                      }}
                    />
                  </>
                ) : (
                  <div className={`${useLabelFloatingClass ? 'form-floating' : ''}`}>
                    <textarea
                      type='text'
                      className='form-control form-control-lg overflow-y-auto'
                      {...baseInputProps(options?.textarea?.props)}
                      onChange={(val) => updateValue({value: val.target.value, debouce: true})}
                      defaultValue={formikValue}
                      onInput={(e: any) => {
                        if (e.target) {
                          const target = e.target
                          target.style.height = '5px'
                          target.style.height = target.scrollHeight + 'px'
                        }
                      }}
                    ></textarea>
                    {isLabelType === 'floating' && titleUi({props: titleUiData})}
                  </div>
                )}
              </>
            )}
            {type === 'select' && (
              <div
                className={`${useLabelFloatingClass ? 'form-floating' : ''} ${
                  baseInputProps(options?.input?.props)?.disabled ? 'disabled' : ''
                }`}
              >
                {loading ? (
                  <>
                    <button
                      disabled={loading || core?.formLock || !checkWatchProps() || disabled}
                      type='button'
                      className='form-control form-control-sm fs-6 text-start'
                      style={{height: 'unset'}}
                      onClick={() => {
                        if (inputError) {
                          initDatalist()
                        } else {
                          loadDataList()
                        }
                      }}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        Loading...
                        <div className='fs-3'>
                          <ReactIcon icon='RiArrowDownSLine' />
                        </div>
                      </div>
                    </button>
                    {isLabelType === 'floating' && titleUi({props: titleUiData})}
                  </>
                ) : (
                  <>
                    {options?.select?.useSelect2 ? (
                      <>
                        <ReactSelect
                          props={{
                            ...baseInputProps(options?.select?.props),
                          }}
                          name={name}
                          defaultValue={formikValue}
                          route={{
                            ...options?.datalist,
                            data: options?.datalist?.data || listData || [],
                            api: queryBuilder({api: options?.datalist?.api, type: 'api'})[0],
                          }}
                          placeholder={`${intlMessage(
                            'component.forminput.select.placeholder_leading',
                            'Select',
                            intl
                          )} ${titleUiValue(
                            titleUiData,
                            intlMessage('component.forminput.select.placeholder_end', 'Data', intl)
                          )}`}
                          onChange={(val) => {
                            let _value
                            try {
                              if (!!val) {
                                let idSelector = options?.datalist?.id || 'id'
                                if (!Object.keys(val[0])?.includes(idSelector)) {
                                  idSelector = 'value'
                                }
                                if (options.select?.props?.multiple) {
                                  let result: any[] = []
                                  const paramsList = options.datalist?.params?.split(',')
                                  val?.forEach((l: any) => {
                                    let resultVal: any = {}
                                    const id = l[idSelector]
                                    paramsList?.map((p: any) => {
                                      const paramsValue = NullProof({
                                        input: l,
                                        params: p,
                                        isLabel: false,
                                      })
                                      resultVal[p] = paramsValue
                                    })
                                    result.push({
                                      id: id,
                                      ...resultVal,
                                    })
                                  })
                                  _value = result
                                } else {
                                  _value = val[0][idSelector]
                                }
                              }
                            } catch (_) {}
                            updateValue({value: _value})
                          }}
                          options={{
                            isClearable: true,
                            isSearchable: true,
                            isMulti: options?.select?.props?.multiple,
                            barebone: false,
                            labelType: isLabelType,
                            useLabelFloating: useLabelFloatingClass,
                          }}
                        />
                        {isLabelType === 'floating' && titleUi({props: titleUiData})}
                      </>
                    ) : (
                      <>
                        <select
                          className={`form-select ${formikProps.value ? '' : 'text-muted'}`}
                          style={{
                            height: 'unset',
                          }}
                          aria-label={options?.select?.props?.title}
                          defaultValue={formikValue}
                          {...baseInputProps(options?.select?.props)}
                          onChange={(val) => updateValue({value: val.target.value})}
                        >
                          <option value=''>
                            {`${intlMessage(
                              'component.forminput.select.placeholder_leading',
                              'Select',
                              intl
                            )} ${titleUiValue(
                              titleUiData,
                              intlMessage(
                                'component.forminput.select.placeholder_end',
                                'Data',
                                intl
                              )
                            )}`}
                          </option>
                          {initReactSelectData()?.map((l, i) => (
                            <option className='text-dark' key={i} value={l.value}>
                              {l.title}
                            </option>
                          ))}
                        </select>
                        {isLabelType === 'floating' && titleUi({props: titleUiData})}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {type === 'multicheckbox' && (
              <>
                <div
                  className={`${
                    options?.multicheckbox?.rowType === 'row'
                      ? `mx-0 row g-2`
                      : 'd-flex flex-wrap gap-2'
                  } ${options?.multicheckbox?.rowClassName || ''}`}
                >
                  {listDataConvert(listData)?.map((l, i) => {
                    const selectProps = titleUiData?.props
                    const i18nTitle = intlMessage(
                      `${i18nConfig}.${name}.${l?.value}`,
                      intlMessage(
                        `${i18nConfig}.${selectProps?.title}.${l?.title}`,
                        l?.title,
                        intl
                      ),
                      intl
                    )
                    const i18nValue = intlMessage(
                      `${i18nConfig}.${name}.${l?.value}`,
                      intlMessage(
                        `${i18nConfig}.${selectProps?.title}.${l?.title}`,
                        i18nTitle,
                        intl
                      ),
                      intl
                    )
                    return (
                      <Fragment key={i}>
                        <div
                          className={`${options?.multicheckbox?.rowType === 'row' ? 'col' : ''} ${
                            options?.multicheckbox?.colClassName || ''
                          } form-check d-flex align-items-center gap-2`}
                        >
                          <input
                            key={inputKey}
                            id={`${formName ? `${formName}_` : ``}${name}_${i + 1}`}
                            name={`${name}_${i + 1}`}
                            type='checkbox'
                            className='form-check-input form-check-input-lg cursor-pointer min-h-25px min-w-25px h-25px w-25px'
                            {...baseInputProps(options?.input?.props)}
                            defaultChecked={
                              NullProof({
                                input: formikProps,
                                params: 'value',
                                isMap: true,
                              })?.includes(l?.value || '') || false
                            }
                            onChange={(val) => {
                              let dataCollection = [
                                ...NullProof({
                                  input: formikProps,
                                  params: 'value',
                                  isMap: true,
                                }),
                              ]
                              let index = dataCollection.indexOf(l?.value)
                              if (index !== -1) {
                                dataCollection.splice(index, 1)
                              } else {
                                dataCollection = [...dataCollection, l?.value]
                              }
                              updateValue({value: dataCollection})
                            }}
                          />
                          <label
                            className='form-check-label text-dark cursor-pointer user-select-none'
                            htmlFor={`${name}_${i + 1}`}
                          >
                            {i18nValue}
                          </label>
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </>
            )}
            {type === 'label' && (
              <>
                <div className='fw-bold fs-5 py-2'>
                  {(_labelType === 'line' || _labelType === 'both') && _labelPosition === 'top' && (
                    <div
                      className={`w-100 rounded-circle ${
                        options?.label?.lineClassName || 'bg-dark opacity-10'
                      }`}
                      style={{height: '2px'}}
                    ></div>
                  )}
                  {(_labelType === 'label' || _labelType === 'both') && (
                    <div
                      className={` ${
                        options?.label?.className ||
                        `${_labelPosition === 'bottom' ? 'mb-2' : 'mt-2'} `
                      }`}
                    >
                      {intlMessage(
                        `${i18nConfig}.LABEL.${name}`,
                        options?.label?.name || 'Label',
                        intl
                      )}
                    </div>
                  )}
                  {(_labelType === 'line' || _labelType === 'both') &&
                    _labelPosition === 'bottom' && (
                      <div
                        className={`w-100 rounded-circle ${
                          options?.label?.lineClassName || 'bg-dark opacity-10'
                        }`}
                        style={{height: '2px'}}
                      ></div>
                    )}
                  {options?.label?.description && (
                    <div
                      className='fw-normal fs-6 mt-2'
                      dangerouslySetInnerHTML={{
                        __html: parseReactNodeDescription(
                          options?.label?.description,
                          options?.i18nDictionary
                        ),
                      }}
                    ></div>
                  )}
                </div>
              </>
            )}
            {type === 'datalist' && (
              <>
                <div className={`${useLabelFloatingClass ? 'form-floating' : ''}`}>
                  <button
                    disabled={loading || core?.formLock || !checkWatchProps() || disabled}
                    type='button'
                    className='form-control form-control-sm fs-6 text-start'
                    style={{height: 'unset'}}
                    onClick={() => {
                      if (inputError) {
                        initDatalist()
                      } else {
                        toggleModal({status: true})
                        if (options?.datalist?.type !== 'table') {
                          loadDataList()
                        }
                      }
                    }}
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      {loading ? (
                        <span>
                          {intlMessage(
                            'component.forminput.datalist.loading',
                            'Loading Data',
                            intl
                          )}
                          ...
                        </span>
                      ) : (
                        <>
                          {inputError ? (
                            <span>
                              {intlMessage(
                                'component.forminput.select.error_server',
                                'Failed to Load Data - Click to Refresh',
                                intl
                              )}
                            </span>
                          ) : (
                            <>
                              {datalist?.title ? (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: datalist?.title,
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  {options?.datalist?.isListpicker && datalistCache.length > 0 ? (
                                    <>
                                      <div className='d-flex flex-wrap gap-2'>
                                        {datalistCache?.map((l: any, i: number) => (
                                          <Fragment key={i}>
                                            <div className='bg-light py-2 px-4 border rounded d-flex gap-4 align-items-center'>
                                              {Object.keys(l?.data || {}).length > 0 ? (
                                                <>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: dataListTitle(l?.data || {}) || '',
                                                    }}
                                                  ></div>
                                                  <div
                                                    className='fs-2 cursor-pointer text-danger'
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      updateDatalistCache({
                                                        id: options?.datalist?.id,
                                                        data: {},
                                                        value: NullProof({
                                                          input: l,
                                                          params: `data.${options?.datalist?.id}`,
                                                          isLabel: true,
                                                        }),
                                                      })
                                                    }}
                                                  >
                                                    <ReactIcon icon='RiDeleteBinLine' />
                                                  </div>
                                                </>
                                              ) : (
                                                <div className='d-flex gap-2 align-items-center'>
                                                  {NullProof({input: l, params: 'status'}) ===
                                                    'pending' && (
                                                    <>
                                                      <div>
                                                        {intlMessage(
                                                          'component.forminput.datalist.loading',
                                                          'Loading Data',
                                                          intl
                                                        )}
                                                        ...
                                                      </div>
                                                      <div
                                                        className='spinner-border spinner-border-sm'
                                                        role='status'
                                                      >
                                                        <span className='sr-only'>Loading...</span>
                                                      </div>
                                                    </>
                                                  )}
                                                  {NullProof({input: l, params: 'status'}) ===
                                                    'error' && (
                                                    <>
                                                      <div>Gagal memuat data</div>
                                                      <div
                                                        className='fs-2 cursor-pointer'
                                                        onClick={() => {
                                                          const result = [...datalistCache]
                                                          const target = result[i]
                                                          target.status = 'pending'
                                                          setDatalistCache((p) => [...result])
                                                        }}
                                                      >
                                                        <ReactIcon icon='RiRestartLine' />
                                                      </div>
                                                      <div
                                                        className='fs-2 cursor-pointer text-danger'
                                                        onClick={() => {
                                                          updateDatalistCache({
                                                            id: options?.datalist?.id,
                                                            data: {},
                                                            value: NullProof({
                                                              input: l,
                                                              params: `data.${options?.datalist?.id}`,
                                                              isLabel: true,
                                                            }),
                                                          })
                                                        }}
                                                      >
                                                        <ReactIcon icon='RiDeleteBinLine' />
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </Fragment>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <span className='text-muted'>
                                        {`${intlMessage(
                                          'component.forminput.select.placeholder_leading',
                                          'Select',
                                          intl
                                        )} ${titleUiValue(titleUiData)}`}
                                        ...
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      <div className='fs-3'>
                        <ReactIcon icon='RiArrowDownSLine' />
                      </div>
                    </div>
                  </button>
                  {isLabelType === 'floating' && titleUi({props: titleUiData})}
                </div>
              </>
            )}
            {type === 'csv' && (
              <>
                <div className='d-flex flex-column gap-4'>
                  <input
                    key={`upload-${inputUploadKey}`}
                    id={`${formName ? `${formName}_` : ``}${name}`}
                    name={name}
                    type='file'
                    className={`form-control form-control-lg d-none`}
                    {...baseInputProps(options?.input?.props)}
                    accept={
                      options?.input?.props?.accept ? options?.input?.props?.accept : '.csv, .xlsx'
                    }
                    onChange={async (val) => {
                      try {
                        if (val.target.files) {
                          const files: FileList = val.target.files
                          const file: File = files[0]
                          const infoFile = checkFileExtension(file.name)
                          let data: any
                          if (infoFile.ext === 'xlsx') {
                            data = await xlsxToObj(file)
                          } else if (infoFile.ext === 'csv') {
                            data = await csvToObject(new Blob([file], {type: file.type}), {
                              delimiter: ';',
                            })
                          }
                          if (data) {
                            updateValue({value: data})
                            setUploadData({
                              status: true,
                              title: file.name,
                            })
                          }
                        }
                      } catch (_) {}
                    }}
                  />
                  <div
                    key={`upload-${inputKey}-button`}
                    className='form-control form-control-lg d-flex flex-column flex-md-row align-items-center row mx-auto'
                    style={{gap: '10px'}}
                  >
                    <div
                      className='col text-truncate ps-0 cursor-pointer'
                      onClick={() => {
                        document.getElementById(`${formName ? `${formName}_` : ``}${name}`)?.click()
                      }}
                    >
                      {uploadData.title ||
                        `${intlMessage(
                          'component.forminput.input.file.placeholder',
                          'Select File',
                          intl
                        )}...`}
                    </div>
                    <div className='col-auto d-flex gap-2'>
                      <button
                        type='button'
                        className={`btn btn-sm ${uploadData.status ? 'btn-danger' : 'btn-primary'}`}
                        disabled={loading}
                        data-kt-indicator={loading ? 'on' : 'off'}
                        onClick={async () => {
                          if (uploadData.status) {
                            if (
                              await PopupConfirm({
                                intl: intl,
                                validated: options?.validator?.useReconfirmDelete,
                                hasConfirm: false,
                              })
                            ) {
                              setUploadData({
                                status: false,
                                title: '',
                              })
                              core.formik?.setFieldValue(
                                name,
                                core._data.value[name] ? core._data.value[name] : null
                              )
                              setInputUploadKey((p) => (p += 1))
                            }
                          } else {
                            document
                              .getElementById(`${formName ? `${formName}_` : ``}${name}`)
                              ?.click()
                          }
                        }}
                      >
                        <span className='indicator-label'>
                          {uploadData.status ? 'Hapus' : 'Upload'}
                        </span>
                        <span className='indicator-progress'>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ),
      })}
      {type !== 'label' && _labelPosition !== 'top_input' && noteUi(options?.label)}
      <ErrorUi core={core} name={name} />
      {debugUi()}
    </div>
  )
}

const ComponentInput: FC<ComponentInputProps & WithChildren> = ({
  name,
  data,
  component,
  children,
}) => {
  const intl = useIntl()
  const [lastTotalComponent, setLastTotalComponent] = useState<number>(0)
  const [listComponent, setListComponent] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const useAccordionTo =
    FormInputConfigUi?.component?.useAccordionTo ||
    data?.options?.component?.useAccordionTo ||
    'data'
  const deleteButtonPosition =
    FormInputConfigUi?.component?.deleteButtonPosition ||
    data.options?.component?.deleteButtonPosition ||
    'end'

  const addComponent = () => {
    const componentLength = listComponent.length || 0
    const json = {
      data: '',
    }
    setListComponent((p: any[]) => [...p, {...json}])
    if (!loading) {
      component.map((l, i) => {
        const componentName = `${name}_${l.name}_${componentLength + 1}`
        let initData = data.core._data.value[componentName]
          ? data.core._data.value[componentName]
          : (l.component?.length || 0) > 0
          ? 0
          : l.type === 'input'
          ? l.options?.input?.props?.type === 'checkbox'
            ? false
            : ''
          : ''
        if (
          (data.options?.component?.cloneInitData || l.options?.component?.cloneInitData) &&
          i > 0
        ) {
          initData = data.core._data.value[`${name}_${l.name}_${componentLength}`]
        }
        data.core.formik.setFieldValue(componentName, initData)
        data.core._data.post({
          fieldsToUpdate: {
            [componentName]: initData,
          },
          options: {
            type: 'onLoad',
          },
          id: name,
          index: i,
        })
      })
      data.core._data.post({
        fieldsToUpdate: {
          [name]: componentLength + 1,
        },
        options: {
          type: 'onLoad',
        },
        id: name,
        index: componentLength + 1,
      })
      data.core.formik.setFieldValue(name, componentLength + 1)
    }
  }
  const removeComponent = async (index: number, force: boolean = false) => {
    let confirm: any = false
    if (!force) {
      confirm = await PopupConfirm({
        intl: intl,
        validated: data.options?.validator?.useReconfirmDelete,
        hasConfirm: false,
      })
    }
    if (confirm || force) {
      const migrateDataComponent = ({
        component,
        parent,
        index,
      }: {
        component: FormInputProps[]
        parent?: string
        index: string
      }) => {
        let result: any = {}
        component.map((l, i) => {
          const childName = `${parent ? `${parent}_` : `${name}_`}${l.name}${
            parent ? '' : `_${index}`
          }`
          try {
            Object.keys(data.core._data.value).forEach((l) => {
              if (l.includes(childName)) {
                result[l] = data.core._data.value[l]
              }
            })
          } catch (_) {}
        })
        return result
      }
      const oldData = migrateDataComponent({component: component, index: `${index + 1}`})
      Object.keys(oldData).map((l) => {
        data.core.formik.setFieldValue(l, undefined)
        data.core.formik.unregisterField(l)
        data.core._data.post({
          fieldsToUpdate: {
            [l]: undefined,
          },
          options: {
            type: 'onChange',
          },
        })
        delete data.core._data.value[l]
      })
      for (let i = index + 1; i < listComponent.length; i++) {
        const targetData = migrateDataComponent({component: component, index: `${i + 1}`})
        Object.keys(targetData).map((l) => {
          const targetName = `${name}${l.replace(name, '').replace(`${i + 1}`, `${i}`)}`
          data.core.formik.setFieldValue(targetName, targetData[l])
          data.core._data.post({
            fieldsToUpdate: {
              [targetName]: targetData[l],
            },
            options: {
              type: 'onChange',
            },
          })
          if (i === listComponent.length - 1) {
            data.core.formik.setFieldValue(l, undefined)
            data.core.formik.unregisterField(l)
            data.core._data.post({
              fieldsToUpdate: {
                [l]: undefined,
              },
              options: {
                type: 'onChange',
              },
            })
            delete data.core._data.value[l]
          }
        })
      }
      const componentLength = data.core._data.value[name] || 0
      setListComponent((p: any[]) => p.slice(0, p.length - 1))
      data.core._data.post({
        fieldsToUpdate: {
          [name]: componentLength - 1,
        },
        options: {
          type: 'onChange',
        },
      })
      data.core.formik.setFieldValue(name, componentLength - 1)
      setLoading(true)
    }
  }
  const checkComponentError = (i: number) => {
    let error = false
    error = data.core.formik.errors[name]
    for (let index = 0; index < component.length; index++) {
      const componentName = `${name}_${component[index].name}_${i + 1}`
      error = data.core.formik.errors[componentName]
      if (error) {
        break
      }
    }
    return error
  }
  useEffect(() => {
    if (data.core._data.value[name]) {
      if (lastTotalComponent !== data.core._data.value[name] || 0) {
        setLastTotalComponent(data.core._data.value[name] || 0)
        setLoading(true)
      }
      if (loading) {
        const totalComponent = data.core._data.value[name] || 0
        if (totalComponent > 0) {
          if (listComponent.length < totalComponent) {
            addComponent()
          } else if (totalComponent < listComponent.length) {
            setListComponent((p: any[]) => p.slice(0, p.length - 1))
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      }
    } else {
      setLoading(false)
    }
  }, [loading, data.core._data.value[name], listComponent.length])

  const deleteButton = (i: number, position: string = 'end') => {
    return (
      <>
        {deleteButtonPosition === position &&
          !(
            data.options?.component?.type === 'object' || data.options?.component?.type === 'group'
          ) &&
          !data.disabled &&
          !data.lockData && (
            <div>
              <button
                type='button'
                className='btn btn-sm btn-danger d-flex justify-content-center gap-2 p-3'
                onClick={() => removeComponent(i)}
              >
                <ReactIcon icon='RiDeleteBinLine' props={{className: 'fs-4'}} />
                <span className='d-none d-md-block'>
                  {intlMessage('global.delete', 'Delete', intl)}{' '}
                  {deleteButtonPosition !== 'end' &&
                    (data?.options?.input?.props?.title ||
                      data?.options?.input?.props?.placeholder)}
                </span>
              </button>
            </div>
          )}{' '}
      </>
    )
  }

  return (
    <div className={data.className}>
      <Accordion
        title={data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
        enabled={data?.options?.component?.useAccordion && useAccordionTo === 'component'}
        props={{
          body: {
            className: 'p-0 border-bottom',
          },
        }}
      >
        {!(
          data.options?.component?.type === 'object' || data.options?.component?.type === 'group'
        ) &&
          useAccordionTo === 'component' &&
          data.options?.component?.resultType && (
            <>
              <div
                className='d-flex align-items-center justify-content-between mt-6 mb-2'
                style={{
                  gap: '5px',
                }}
              >
                <div className='fs-4 fw-bold'>
                  {data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
                </div>
                <div className='d-flex gap-2 align-items-center fs-6'>
                  {data.options?.component?.resultType && (
                    <CurrencyFormat
                      className='fs-6 fw-semibold'
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={
                        data.options?.component?.resultType?.type === 'currency' ? 'Rp. ' : ''
                      }
                      suffix={data.options?.component?.resultType?.type === 'date' ? ' Hari' : ''}
                      displayType='text'
                      value={data.core._dataIndicator.value[name] || 0}
                    />
                  )}
                  {data.options?.component?.resultType?.labelEnd && (
                    <>
                      <div>{data.options?.component?.resultType?.labelEnd}</div>
                    </>
                  )}
                </div>
              </div>
              <ErrorUi core={data.core} name={name} />
              <div className='w-full h-1px bg-dark bg-opacity-10 my-4'></div>
            </>
          )}
        <div className='position-relative px-0 d-flex flex-column mt-0 gap-4'>
          {listComponent.map((l, i) => (
            <Fragment key={i}>
              <Accordion
                title={`${data.options?.component?.type === 'object' ? '' : `${i + 1}. `}${
                  data?.options?.input?.props?.title ||
                  data?.options?.input?.props?.placeholder ||
                  ''
                }`}
                enabled={data?.options?.component?.useAccordion && useAccordionTo === 'data'}
                error={checkComponentError(i) && data.core.formError.value}
              >
                {data.options?.component?.useHeaderToolbar &&
                  (data?.options?.input?.props?.title ||
                    data?.options?.input?.props?.placeholder ||
                    !(
                      data.options?.component?.type === 'object' ||
                      data.options?.component?.type === 'group'
                    )) && (
                    <div className='d-flex align-items-center justify-content-between gap-2'>
                      {(data?.options?.input?.props?.title ||
                        data?.options?.input?.props?.placeholder) && (
                        <div
                          className={`${
                            data.options?.component?.type === 'object' ? 'fs-5 fw-bold' : 'fs-6'
                          }`}
                        >
                          {`${data.options?.component?.type === 'object' ? '' : `${i + 1}. `}${
                            data?.options?.input?.props?.title ||
                            data?.options?.input?.props?.placeholder ||
                            ''
                          }`}
                        </div>
                      )}
                      {deleteButton(i, 'top')}
                    </div>
                  )}
                {data.options?.label?.description && (
                  <>{createElement('div', {children: data.options?.label?.description})}</>
                )}

                <div className={`row w-100 g-6 my-0 position-relative mx-0`}>
                  {(data?.options?.component?.useOrderNumber ||
                    data?.options?.component?.useGuide) && (
                    <div
                      className='col-auto mt-0 fs-3 fw-bold d-flex align-items-center justify-content-center position-relative'
                      style={{minWidth: '20px'}}
                    >
                      {data?.options?.component?.useGuide && (
                        <>
                          <div
                            className='position-absolute rounded-circle bg-primary'
                            style={{
                              left: '11px',
                              top: '16px',
                              width: '10px',
                              height: '10px',
                            }}
                          ></div>
                          <div
                            className={`position-absolute ${
                              data?.options?.component?.useGuide
                                ? 'border-start mt-0 border-primary w-5px h-100 pe-none'
                                : ''
                            }`}
                            style={{left: '15px', top: 0}}
                          ></div>
                        </>
                      )}
                      {data?.options?.component?.useOrderNumber && <span>{i + 1}.</span>}
                    </div>
                  )}
                  <div className={`col mt-0 px-0`}>
                    <div
                      className={`row ${
                        data.options?.component?.type === 'object' ? 'px-0' : ''
                      } ${gapClass}`}
                    >
                      {component?.map((lsub, isub) => {
                        const _core: FormInputProps & InputListOptionProps = {
                          i18n: data.i18n,
                          formName: data.formName,
                          parentName: name,
                          name:
                            data.options?.component?.type === 'object'
                              ? `${name}_${lsub.name}`
                              : `${name}_${lsub.name}_${i + 1}`,
                          disabled: data.core?.formLock || data.disabled || lsub.disabled,
                          componentIndex: i + 1,
                          className: lsub.className,
                          hide: lsub.hide,
                          removeOnSubmit: lsub.removeOnSubmit,
                          lockData: lsub.lockData || data.lockData,
                          type: lsub.type,
                          options: lsub.options,
                          value: lsub.value,
                          listData: lsub.listData,
                          validator: lsub.validator,
                          component: lsub.component,
                          onFeedback: data.onFeedback,
                          onLoad: data.onLoad,
                          route: data.route,
                          core: data.core,
                        }
                        return (
                          <Fragment key={isub}>
                            {(lsub.component?.length || 0) > 0 ? (
                              <ComponentInput
                                name={_core.name}
                                data={_core}
                                component={lsub.component || []}
                              ></ComponentInput>
                            ) : (
                              <InputList {..._core} />
                            )}
                          </Fragment>
                        )
                      })}
                    </div>
                  </div>
                  <div className='col-auto mt-2'>{deleteButton(i, 'end')}</div>
                </div>
                {data.options?.component?.useGapHairline &&
                  ((!(
                    data.options?.component?.type === 'object' ||
                    data.options?.component?.type === 'group'
                  ) &&
                    i !== listComponent.length - 1) ||
                    data.core?.formLock ||
                    data.disabled) && (
                    <div className='w-full h-1px bg-dark bg-opacity-10 my-4'></div>
                  )}
                {deleteButton(i, 'bottom')}
              </Accordion>
            </Fragment>
          ))}
        </div>

        {!(
          data.options?.component?.type === 'object' || data.options?.component?.type === 'group'
        ) &&
          !data.core?.formLock &&
          !data.disabled &&
          !data.lockData && (
            <>
              <div
                className='d-flex align-items-center justify-content-start mt-4 mb-2'
                style={{
                  gap: '5px',
                }}
              >
                <button
                  type='button'
                  className='btn btn-sm btn-primary d-flex justify-content-center gap-2 p-3 w-100 w-md-auto'
                  onClick={addComponent}
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-4'}} />
                  <span>
                    {intlMessage('global.add', 'Add', intl)}{' '}
                    {data?.options?.input?.props?.title || data?.options?.input?.props?.placeholder}
                  </span>
                </button>
              </div>
            </>
          )}
        <ErrorUi core={data.core} name={name} />
        {isDebugging && (
          <>
            <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
              <code>
                {JSON.stringify(
                  listComponent?.map((l, i) => {
                    return component?.map((lsub, isub) => {
                      return `${lsub.name}:${
                        data.core.formik.values[`${name}_${lsub.name}_${i + 1}`] || null
                      }`
                    })
                  })
                )}
              </code>
            </pre>
          </>
        )}
      </Accordion>
    </div>
  )
}

const JsonDataBuilder = async ({data, _data, value, isSubmit}: JsonDataBuilderProps) => {
  let json: any = {}
  // Generate Upload URL
  const DropdownValue = async (l: FormInputProps) => {
    const isObject = l.options?.component?.type === 'object'
    let _json: any = []
    const totalData = value[l.name] || 0
    for (let index = 0; index < totalData; index++) {
      let subData: any = {}
      l.component?.map(async (lsub: FormInputProps, isub) => {
        const SingleValueDropdownValue = async (lsub: FormInputProps) => {
          if (!lsub.removeOnSubmit) {
            const name: string = isObject
              ? `${l.name}_${lsub.name}`
              : `${l.name}_${lsub.name}_${index + 1}`
            if ((lsub.component?.length || 0) > 0) {
              subData[lsub.name] = await DropdownValue({
                ...lsub,
                name: name,
              })
            } else if (lsub.type === 'input' && lsub.options?.input?.props?.type === 'file') {
              const _value = _data[name] === '' || _data[name] === 0 ? null : _data[name]
              subData[lsub.name] = NullProof({input: _value, isLabel: false})
            } else if (
              (lsub.type === 'input' &&
                (lsub.options?.input?.isCurrency || lsub.options?.input?.isDate)) ||
              lsub.options?.input?.props?.type === 'number'
            ) {
              const _value = value[name]
              if (lsub.options?.input?.isDate) {
                subData[lsub.name] = NullProof({input: _value, isLabel: false})
              } else {
                subData[lsub.name] = Number(NullProof({input: _value, isLabel: false}) || 0)
              }
            } else if (lsub.type === 'label') {
            } else {
              const typeProps = typeof value[name]
              if (typeProps === 'string' || typeProps === 'number') {
                const _value = value[name] === '' || value[name] === 0 ? null : value[name]
                if (typeProps === 'string') {
                  _value?.replace(/\n$/, '')
                }
                subData[lsub.name] = NullProof({input: _value, isLabel: false})
              } else {
                const _value = value[name]
                subData[lsub.name] = NullProof({input: _value, isLabel: false})
              }
            }
          }
        }
        if ((lsub.component?.length || 0) > 0 && lsub.options?.component?.type === 'group') {
          for (const lsuper of lsub.component || []) {
            SingleValueDropdownValue(lsuper)
          }
        } else {
          SingleValueDropdownValue(lsub)
        }
      })
      _json.push(subData)
    }
    return _json
  }
  const SingleValue = async (l: FormInputProps) => {
    if (!l.removeOnSubmit) {
      if ((l.component?.length || 0) > 0) {
        if (l.options?.component?.type === 'object') {
          json[l.name] = (await DropdownValue(l))[0]
        } else {
          json[l.name] = await DropdownValue(l)
        }
      } else if (l.type === 'input' && l.options?.input?.props?.type === 'file') {
        const _value = _data[l.name] === '' || _data[l.name] === 0 ? null : _data[l.name]
        json[l.name] = NullProof({input: _value, isLabel: false})
      } else if (
        (l.type === 'input' && (l.options?.input?.isCurrency || l.options?.input?.isDate)) ||
        l.options?.input?.props?.type === 'number'
      ) {
        const _value = value[l.name]
        if (l.options?.input?.isDate) {
          json[l.name] = NullProof({input: _value, isLabel: false})
        } else {
          json[l.name] = Number(NullProof({input: _value, isLabel: false}) || 0)
        }
      } else if (l.type === 'label') {
      } else {
        const typeProps = typeof value[l.name]
        if (typeProps === 'string' || typeProps === 'number') {
          const _value = value[l.name] === '' || value[l.name] === 0 ? null : value[l.name]
          if (typeProps === 'string') {
            _value?.replace(/\n$/, '')
          }
          json[l.name] = NullProof({input: _value, isLabel: false})
        } else {
          const _value = value[l.name]
          json[l.name] = NullProof({input: _value, isLabel: false})
        }
      }
    }
  }
  data.map(async (l, i) => {
    if ((l.component?.length || 0) > 0 && l.options?.component?.type === 'group') {
      for (const lsub of l.component || []) {
        SingleValue(lsub)
      }
    } else {
      SingleValue(l)
    }
  })
  return json
}

export interface FormControllerProps {
  get: string
  post: (e: any) => void
  status: any
  setStatus: (e: any) => void
}

export const useFormController = (): FormControllerProps => {
  const [controller, setController] = useState<string>('')
  const [status, setStatus] = useState<any>({})

  return {
    get: controller,
    post: setController,
    status: status,
    setStatus: setStatus,
  }
}

const FormInputBase: FC<BaseInputProps> = ({
  i18n,
  name,
  isLocked = false,
  isModal,
  isSubmitByBlur,
  useToast = true,
  input,
  route,
  className,
  isLoading,
  isError,
  onLoad,
  onChange,
  onFeedback,
  onBeforeSubmit,
  options,
  controller,
}) => {
  const intl = useIntl()
  const reduxDispatch = useDispatch()
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxRouteData = useSelector((state: any) => state.route.value)

  const protectInput = (): FormInputProps[] => {
    let result = [...input]
    result = result.filter((l, i) => {
      let hasAccess = false
      if ((l.protect?.length || 0) > 0) {
        for (const lf of reduxRouteData?.route) {
          if (l.protect?.some((e) => e === lf)) {
            hasAccess = true
            break
          }
        }
      } else {
        hasAccess = true
      }
      return hasAccess
    })
    return result
  }

  const listComponentInput: any = ({
    l,
    _core,
  }: {
    l: FormInputProps
    _core: FormInputProps & InputListOptionProps
  }) => {
    return (
      <>
        {(l.component?.length || 0) > 0 ? (
          <>
            {(l.options?.component?.type || 'list') === 'list' ||
            (l.options?.component?.type || 'list') === 'object' ? (
              <>
                <ComponentInput
                  name={_core.name}
                  data={_core}
                  component={l.component || []}
                ></ComponentInput>
              </>
            ) : (
              <>
                <Accordion
                  title={l?.options?.input?.props?.title || l?.options?.input?.props?.placeholder}
                  enabled={l?.options?.component?.layout === 'dropdown'}
                  props={{
                    body: {
                      className: 'p-0 border-bottom',
                    },
                  }}
                >
                  <div className={`row pt-6 ${gapClass}`}>
                    {l.component?.map((lsub, isub) => {
                      const _groupCore: FormInputProps & InputListOptionProps = {
                        i18n: i18n,
                        formName: name,
                        name: lsub.name,
                        disabled: lsub.disabled,
                        className: lsub.className,
                        hide: lsub.hide,
                        removeOnSubmit: lsub.removeOnSubmit,
                        lockData: lsub.lockData,
                        type: lsub.type,
                        options: lsub.options,
                        value: lsub.value,
                        listData: lsub.listData,
                        route: route,
                        validator: lsub.validator,
                        component: lsub.component,
                        onFeedback: onFeedback,
                        onLoad: onLoad,
                        core: _core.core,
                      }
                      return (
                        <Fragment key={isub}>
                          {listComponentInput({l: lsub, _core: _groupCore})}
                        </Fragment>
                      )
                    })}
                  </div>
                </Accordion>
              </>
            )}
          </>
        ) : (
          <InputList {..._core} />
        )}
      </>
    )
  }

  const query = useParams()
  const navigate = useNavigate()
  const initialValues = getInitialValues({data: protectInput()})
  const [validationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape(InputListValidator({data: protectInput()}))
  )
  const [_data, _setData] = useState<any>(initialValues)
  const [_dataIndicator, _setDataIndicator] = useState<any>({})
  const [_apiData, _setApiData] = useState<any>({})
  const [serverData, setServerData] = useState<any>({})
  const [staticData, setStaticData] = useState<any>({})

  const [lockForm, setLockForm] = useState<boolean>(isLocked)
  const [inputErrorMessage, setInputErrorMessage] = useState<any>({})
  const [inputRefreshData, setInputRefreshData] = useState<any>({})
  const [inputKey, setInputKey] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [formError, setFormError] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  useEffect(() => {
    setLockForm(isLocked)
  }, [isLocked])

  const _updateApiData = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(_apiData, fieldsToUpdate)
    _setApiData(updatedData)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await onSubmit(values, setSubmitting)
    },
  })

  const {validateForm, resetForm} = formik

  const checkError = async (value?: any) => {
    const listError = value ? await formik.validateForm(value) : formik.errors
    const isError =
      (value ? false : !formik.isValid) ||
      Object.keys(listError).length > 0 ||
      Object.keys(inputErrorMessage).length > 0
    return isError
  }

  const updateBindInputApi = () => {
    const DropdownInput = (l: FormInputValidatorProps) => {
      if (l.options?.input?.bindInput && l.options.input.bindInput.type !== 'fill') {
        const targetApi = l.options?.input?.bindInput.input
        const targetName = l?.parentId ? `${l?.parentId}_${targetApi}_${l.index}` : targetApi

        if (_apiData[targetApi]) {
          try {
            const dataInputApi = _apiData[targetApi]?.filter(
              (ls: any) => ls[l.options?.input?.bindInput?.id || 'id'] === _data[targetName]
            )[0]
            const paramsList = l.options.input.bindInput.params?.split(',')
            for (const params of paramsList) {
              const dataApi = dataInputApi[params] || ''
              if (!dataApi) {
                continue
              }
              _updateData({
                fieldsToUpdate: {[l.name]: dataApi},
                options: {
                  refresh: false,
                },
                id: targetName,
                index: l.index,
              })
              formik.setFieldValue(l.name, dataApi)
            }
          } catch (_) {}
        }
      }
      if ((l.component?.length || 0) > 0) {
        l.component?.map((lsub, isub) => {
          if (l.options?.component?.type === 'object' || l.options?.component?.type === 'group') {
            DropdownInput(lsub)
          } else {
            const name = `${l.name}_${lsub.name}_${isub + 1}`
            DropdownInput({
              ...lsub,
              name: name,
              parentName: lsub.name,
              parentId: l.name,
              index: isub,
            })
          }
        })
      }
    }
    protectInput().map((l, i) => {
      DropdownInput(l)
    })
  }

  const RebuildValidator = (updatedData: any) => {
    let builder: FormInputValidatorProps[] = [...protectInput()]
    const DropdownValidator = (l: FormInputValidatorProps) => {
      const totalComponent = updatedData[l.name]
      const component = [...(l.component || [])]
      component?.forEach((lsub: FormInputValidatorProps, isub) => {
        for (let index = 1; index <= totalComponent; index++) {
          const name = `${l.name}_${lsub.name}_${index}`
          if ((lsub.component?.length || 0) > 0) {
            builder.push({
              ...lsub,
              name: name,
              parentId: lsub.name,
            })
            DropdownValidator({
              ...lsub,
              name: name,
              parentId: lsub.name,
            })
          } else {
            builder.push({
              ...lsub,
              name: name,
              parentId: l.name,
            })
          }
        }
      })
    }
    builder.forEach((l) => {
      if ((l.component?.length || 0) > 0) {
        if (l.options?.component?.type === 'object' || l.options?.component?.type === 'group') {
          for (const lsub of l.component || []) {
            DropdownValidator(lsub)
          }
        } else {
          DropdownValidator(l)
        }
      }
    })
    const initValidationSchema = Yup.object().shape(InputListValidator({data: builder}))
    setValidationSchema(initValidationSchema)
  }
  const updateInputRefreshData = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(inputRefreshData, fieldsToUpdate)
    setInputRefreshData(updatedData)
  }
  const _updateDataIndicator = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(_dataIndicator, fieldsToUpdate)
    _setDataIndicator(updatedData)
  }
  const errorForm = async ({field, message}: {field: string; message: string}) => {
    let result = Object.assign(inputErrorMessage, {[field]: message})
    for (const l of Object.keys(result)) {
      result[l].length < 1 && delete result[l]
    }
    setInputErrorMessage({...result})
  }

  const valueDebouce = useRef(debounce((e) => e(), 300)).current

  useEffect(() => {
    return () => {
      valueDebouce.cancel()
    }
  }, [valueDebouce])

  const updateForm = ({e, id}: {e: any; id: string}) => {
    let listKey: any = {}
    for (const l of Object.keys(e?.values || {})) {
      if (e?.values[l] !== _data[l]) {
        listKey[l] = (inputKey[l] || 0) + 1
      }
    }
    let listRefreshData: any = {}
    Object.keys(e.values).map((l, i) => {
      if (!isEqual(e.values[l], _data[l]) && l !== id) {
        listRefreshData[l] = true
      }
    })
    updateInputRefreshData(listRefreshData)
    setInputKey({...inputKey, ...listKey})
    updateFields({..._data, ...e.values}, _data, _setData)
    valueDebouce(() => {
      resetForm({values: {..._data, ...e.values}})
    })
  }

  const _updateData = async ({
    fieldsToUpdate,
    options = {
      refresh: true,
      type: 'onChange',
    },
    id,
    index,
  }: _updateDataProps) => {
    const updatedData = Object.assign(_data, fieldsToUpdate)
    const listUpdatedData = Object.keys(fieldsToUpdate)
    const _id = id || (listUpdatedData.length > 0 ? listUpdatedData[0] : '')
    if (options.type === 'onChange') {
      const resetInputWatchInput = (input: FormInputProps[], parentName?: string) => {
        let listData: {id: string; name: string; parentName?: string}[] = []
        for (const l of input) {
          if (
            l.options?.watchInput &&
            (l.options?.watchInput?.type || 'watch-reset') === 'watch-reset'
          ) {
            listData.push({
              id: l.options?.watchInput?.id || '',
              name: l.name,
              parentName: parentName,
            })
          } else if (l.component) {
            listData = [...listData, ...resetInputWatchInput(l.component, l.name)]
          }
        }
        return listData
      }
      let listDataReset: {id: string; name: string; parentName?: string}[] = resetInputWatchInput(
        protectInput()
      )
      for (const l of listDataReset) {
        const propsName: string = `${l.parentName ? `${l.parentName}_` : ''}${l.name}${
          index ? `_${index}` : ''
        }`
        const watchId = l.id.split(',')
        for (const l1 of watchId) {
          const inputName: string = `${l.parentName ? `${l.parentName}_` : ''}${l1}${
            index ? `_${index}` : ''
          }`
          if (listUpdatedData.includes(inputName)) {
            formik.setFieldValue(propsName, '')
            updatedData[propsName] = ''
          }
        }
      }
    }
    RebuildValidator(updatedData)
    _setData(updatedData)
    onChange &&
      onChange({
        error: await checkError(updatedData),
        _data: {
          getObject: await JsonDataBuilder({
            data: protectInput(),
            _data: _data,
            value: _data,
          }),
          get: _data,
          post: _setData,
        },
        _dataIndicator: {
          get: _dataIndicator,
          post: _setDataIndicator,
        },
        _apiData: {
          get: _apiData,
          post: _apiData,
          server: serverData,
        },
        setError: errorForm,
        resetForm: (e) => updateForm({e, id: _id}),
        type: options.type,
        lockForm: setLockForm,
        refreshInput: updateInputRefreshData,
        clickId: _id,
        clickIndex: index,
      })
    if (isSubmitByBlur) {
      if (!(formError || loading || isLoading)) {
        const isError =
          !formik.isValid ||
          Object.keys(formik.errors).length > 0 ||
          Object.keys(inputErrorMessage).length > 0
        setFormSubmitError(isError)
        valueDebouce(() => {
          formik.handleSubmit()
        })
      }
    }
    if (options.refresh) {
      updateBindInputApi()
    }
  }

  const loadData = async () => {
    setLoading(true)
    if (route?.url) {
      try {
        let result: {status: boolean; data: any} = {
          status: false,
          data: {},
        }
        if (route?.data) {
          result = {status: true, data: route.data}
        } else {
          if (route.url !== '#') {
            result = await getData('', DynamicUrl({input: route.url, params: '', query: query}))
          }
          const bindDataList = await ListBindDataByUrl({
            data: route.bindData || [],
            query: query,
          })
          result = {
            ...result,
            data: {
              ...result.data,
              ...bindDataList.propsList,
            },
          }
          setStaticData(bindDataList.dataList)
        }
        setServerData(result.status ? result.data : {})
        setFormError(!result.status)
        const initData = getInitialValues({
          data: protectInput(),
          serverData: result.data,
        })
        const tempData = getInitialValues({
          data: protectInput(),
          serverData: result.data,
          isTemp: true,
        })
        const initValidationSchema = Yup.object().shape(
          InputListValidator({data: protectInput(), serverData: result.data})
        )
        formik.resetForm({values: initData})
        setValidationSchema(initValidationSchema)
        _updateData({
          fieldsToUpdate: tempData,
          options: {
            type: 'onInit',
          },
        })
        const returnDataBuilder = async () => {
          return {
            _data: {
              get: tempData,
              getObject: await JsonDataBuilder({
                data: protectInput(),
                _data: tempData,
                value: tempData,
              }),
              post: _setData,
            },
            _dataIndicator: {
              get: _dataIndicator,
              post: _setDataIndicator,
            },
            _apiData: {
              get: result.data,
              post: _apiData,
              server: result.data,
            },
          }
        }
        onLoad &&
          onLoad({
            id: 'api',
            data: await returnDataBuilder(),
            initLoading: () => {
              setLoading(true)
              setFormError(false)
            },
            load: ({query, result}) => {
              formik.resetForm({values: result})
              _updateData({
                fieldsToUpdate: result,
                options: {
                  type: 'onInit',
                },
              })
            },
          })
        onChange &&
          onChange({
            error: await checkError(_data),
            ...(await returnDataBuilder()),
            setError: errorForm,
            resetForm: (e) => updateForm({e, id: 'api'}),
            type: 'onInit',
            lockForm: setLockForm,
            refreshInput: updateInputRefreshData,
            clickId: 'api',
            clickIndex: 0,
          })
      } catch (_) {}
      setLoading(false)
    }
  }

  const handleScroll = () => {
    const position = window.scrollY
    if (position > 100) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll) // Cleanup listener on component unmount
    }
  }, [])

  const onSubmit = async (values: any, setSubmitting: any) => {
    setFormSubmitError(false)
    setSubmitting(true)
    try {
      let result = {status: false, data: {}, message: ''}
      let json: any = await JsonDataBuilder({
        data: protectInput(),
        _data: _data,
        value: values,
        isSubmit: route.type === 'put',
      })
      const url = DynamicUrl({
        input: route?.urlSubmit || route.url,
        params: '',
        query: query,
      })
      if (route.isFeedback) {
        result = {status: true, data: json, message: ''}
        route.onFeedback && route.onFeedback(result)
        useToast &&
          Swal.fire({
            icon: 'success',
            title: route.onFeedbackSuccessMessage || 'Berhasil',
          })
      } else if (route.type === 'put') {
        result = await putData(json, url)
        route.onFeedback && route.onFeedback({status: result?.status, data: json})
        useToast &&
          Swal.fire({
            icon: 'success',
            title: 'Berhasil mengubah data',
          })
      } else {
        result = await postData(json, url)
        route.onFeedback && route.onFeedback({status: result?.status, data: json})
        useToast &&
          Swal.fire({
            icon: 'success',
            title: 'Berhasil menambah data',
          })
      }
      const resetOnFeedback = route.resetOnFeedback === undefined ? true : route.resetOnFeedback
      if (resetOnFeedback) {
        formik.resetForm({
          values: getInitialValues({data: protectInput()}),
        })
        _setData(getInitialValues({data: protectInput()}))
      }
      if (result?.status) {
        if (route.redirect) {
          navigate(-1)
          reduxDispatch(postTable({...reduxTableData, refresh: true}))
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: route.useServerMessage
            ? result?.message || 'Mohon cek kembali data anda.'
            : 'Mohon cek kembali data anda.',
        })
      }
    } catch (ex) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    validateForm()
    updateBindInputApi()
  }, [validationSchema])

  useEffect(() => {
    if (
      (route?.url && route?.type === 'put') ||
      (route?.url && Object.keys(route?.data || {}).length > 0)
    ) {
      loadData()
    } else {
      const initOnLoad = async () => {
        const result = {status: true, data: route.data}
        const tempData = getInitialValues({
          data: protectInput(),
          serverData: result.data,
          isTemp: true,
        })
        const returnDataBuilder = async () => {
          return {
            _data: {
              get: tempData,
              getObject: await JsonDataBuilder({
                data: protectInput(),
                _data: tempData,
                value: tempData,
              }),
            },
            _dataIndicator: {
              get: _dataIndicator,
            },
            _apiData: {
              get: result.data,
              server: result.data,
            },
          }
        }
        onLoad &&
          onLoad({
            id: 'init',
            data: await returnDataBuilder(),
            initLoading: () => {
              setLoading(true)
              setFormError(false)
            },
            load: ({query, result}) => {
              formik.resetForm({values: result})
              _updateData({
                fieldsToUpdate: result,
                options: {
                  type: 'onInit',
                },
              })
            },
          })
      }
      onLoad && initOnLoad()
      if (isLoading) {
        setLoading(isLoading)
      } else {
        setLoading(false)
      }
    }
  }, [route?.url, isLoading])

  const onFormSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    let beforeErrorList: any = {}
    if (onBeforeSubmit) {
      await onBeforeSubmit({
        data: {
          _data: {
            get: _data,
            getObject: await JsonDataBuilder({
              data: protectInput(),
              _data: _data,
              value: _data,
            }),
          },
          _dataIndicator: {
            get: _dataIndicator,
          },
          _apiData: {
            get: _apiData,
            server: serverData,
          },
        },
        submit: async ({values}) => {
          let listKey: any = {}
          for (const l of Object.keys(values || {})) {
            if (values[l] !== _data[l]) {
              listKey[l] = (inputKey[l] || 0) + 1
            }
          }
          setInputKey({...inputKey, ...listKey})
          _setData(values)
          resetForm({values: values})
          beforeErrorList = await formik.validateForm(values)
        },
      })
    }
    let isError: boolean
    const beforeError = Object.keys(beforeErrorList).length > 0
    if (onBeforeSubmit) {
      isError = beforeError
    } else {
      isError = await checkError()
    }
    setFormSubmitError(isError)
    if (isError) {
      e?.preventDefault()
    } else {
      formik.handleSubmit(e)
    }
  }

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    let status = false
    if (controller) {
      if (controller.get === 'submit') {
        if (formRef.current) {
          const event = new Event('submit', {bubbles: true, cancelable: true})
          formRef.current.dispatchEvent(event)
          status = true
        }
      }
      controller?.setStatus({
        status: status,
        error: formSubmitError || Object.keys(formik.errors).length > 0,
      })
      valueDebouce(() => {
        controller?.post('')
      })
    }
  }, [controller?.get])

  return (
    <form
      ref={formRef}
      id={name || 'forminput'}
      onSubmit={onFormSubmit}
      method='post'
      className={`form container position-relative ${className ? className : ''} ${
        isModal ? '' : 'min-h-100px'
      }`}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
        }
      }}
    >
      {formError || isError || loading || isLoading ? (
        <div
          className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-light bg-opacity-75'
          style={{
            zIndex: 1,
          }}
        >
          <div className='d-flex flex-column align-items-center gap-4'>
            {(formError || isError) && (
              <>
                {intlMessage('component.forminput.datalist.loading', 'Failed loading Data', intl)}
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={async () => {
                    onFeedback && (await onFeedback({type: 'refresh'}))
                    loadData()
                  }}
                >
                  {intlMessage('component.forminput.datalist.button.refresh', 'Refresh', intl)}
                </button>
              </>
            )}
            {(loading || isLoading) && (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className='p-2'>
            <div className={`row ${gapClass}`}>
              {protectInput().map((l, i) => {
                const _core: FormInputProps & InputListOptionProps = {
                  i18n: i18n,
                  formName: name,
                  name: l.name,
                  disabled: l.disabled,
                  className: l.className,
                  hide: l.hide,
                  removeOnSubmit: l.removeOnSubmit,
                  lockData: l.lockData,
                  type: l.type,
                  options: l.options,
                  value: l.value,
                  listData: l.listData,
                  route: route,
                  validator: l.validator,
                  component: l.component,
                  onFeedback: onFeedback,
                  onLoad: onLoad,
                  core: {
                    _data: {
                      value: _data,
                      post: _updateData,
                    },
                    _dataIndicator: {
                      value: _dataIndicator,
                      post: _updateDataIndicator,
                    },
                    _apiData: {
                      value: _apiData,
                      post: _updateApiData,
                      server: serverData,
                    },
                    input: {
                      value: protectInput(),
                    },
                    formik: formik,
                    formError: {
                      value: formSubmitError,
                      post: setFormSubmitError,
                    },
                    setError: errorForm,
                    errorMessage: inputErrorMessage,
                    refreshData: {
                      value: inputRefreshData,
                      post: updateInputRefreshData,
                    },
                    formLock: lockForm,
                  },
                }
                return <Fragment key={i}>{listComponentInput({l: l, _core: _core})}</Fragment>
              })}
            </div>
            {(formError || isError || formSubmitError) && (
              <div className='fv-plugins-message-container mx-2 mt-2 px-4 py-2 border border-danger rounded bg-danger bg-opacity-10'>
                <div className='fv-help-block d-flex align-items-center gap-2'>
                  <ReactIcon icon='RiErrorWarningLine' props={{className: 'fs-2'}} />
                  <div className='fw-semibold'>
                    {intlMessage(
                      'component.formik.form_error',
                      'Please check again your form.',
                      intl
                    )}
                  </div>
                </div>
              </div>
            )}
            {(!options?.actionButton?.submit?.hide || !options?.actionButton?.cancel?.hide) && (
              <div
                className={`mt-4 d-flex ${
                  options?.actionButton?.align === 'end' ? 'justify-content-end' : ''
                } gap-2`}
              >
                {!options?.actionButton?.submit?.hide && (
                  <>
                    {!lockForm && (
                      <ButtonLoading
                        icon='RiSaveLine'
                        title={{
                          button: options?.actionButton?.submit?.title || 'Simpan',
                        }}
                        loading={loading || formik.isSubmitting}
                        props={{type: 'submit'}}
                      />
                    )}
                  </>
                )}
                {!options?.actionButton?.cancel?.hide && (
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={async () => {
                      let result: {status: boolean; type: string} = {
                        status: false,
                        type: 'cancel',
                      }
                      if (options?.actionButton?.cancel?.confirm?.enabled) {
                        const json: any = await PopupConfirm({
                          typeData: 'object',
                          hasConfirm: false,
                          confirmText: {
                            title:
                              options?.actionButton?.cancel?.confirm?.title ||
                              intlMessage(
                                `component.swal2.text.unsaved_confirm`,
                                'Data has not been saved, do you want to save?',
                                intl
                              ),
                            description: '',
                          },
                          successText: {
                            title:
                              options?.actionButton?.cancel?.confirm?.success ||
                              intlMessage(
                                `component.swal2.text.unsaved_success`,
                                'Successfully deleted data',
                                intl
                              ),
                          },
                        })
                        result = {
                          status:
                            (json?.swal?.isDismissed && json?.swal?.dismiss === 'cancel') ||
                            json?.status,
                          type: json?.swal?.dismiss || 'confirm',
                        }
                      } else {
                        result = {
                          status: true,
                          type: 'cancel',
                        }
                      }
                      if (result.status) {
                        if (isModal) {
                          if (result.type === 'cancel') {
                            route.onFeedback && route.onFeedback({status: false})
                          }
                        } else {
                          if (result.type === 'confirm') {
                            onFormSubmit()
                          } else {
                            navigate(-1)
                          }
                        }
                      }
                    }}
                  >
                    <span className='indicator-label'>
                      {options?.actionButton?.cancel?.title || 'Batal'}
                    </span>
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {isDebugging && (
        <>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Data: {JSON.stringify(_data)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Error: {JSON.stringify(inputErrorMessage)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Formik: {JSON.stringify(formik.values)}</code>
          </pre>
          <pre style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
            <code>Formik Error: {JSON.stringify(formik.errors)}</code>
          </pre>
        </>
      )}
    </form>
  )
}

export default FormInput
