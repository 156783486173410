/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import PageData, {
  InfoData,
  TableInputData,
  TableInputDataBalas,
  TableInputDataDisposisi,
  TableInputDataHistoryNaskah,
  TableInputDetailData,
} from './PageData'
import {formInputList} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import {PdfLayoutRange, PdfLayoutTahunan} from './PdfLayout'
import {
  AvatarUi,
  ButtonLoading,
  CardUi,
  ListdataUi,
  TabsUi,
  TimelineUi,
} from '../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../helper/TableInput'
import {NullProof, RouterQueryParams, numberlistPagination} from '../../../../helper/AppFunction'
import FormInput from '../../../../helper/FormInput'
import {formatDate} from '../../../../helper/DateFns'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import {ProtectComponentRoles} from '../../../../helper/UserRolesValidation'
import Modal from '../../../../helper/Modal'
import {
  TableRiwayatPerubahanMetadata,
  TableDaftarPenandatanganan,
  TableDaftarVerifikator,
  TableLampiran,
  TableNaskahDinas,
  TablePenandatanganan,
  TableStatusKirim,
  TableTujuan,
  TableVerifikator,
  TableInputNaskahDinas,
  TableInputLampiran,
  TableInputTujuan,
  TableInputVerifikator,
  TableInputPenandatanganan,
  TableInputStatusKirim,
  LampiranViewer,
} from './TableWrapper'
import Accordion from '../../../../helper/Accordion'
import ReactIcon from '../../../../helper/ReactIcon'
import Swal from 'sweetalert2'
import {getData, postData, putData} from '../../../../helper/FormAxios'
import {ReactPdfViewer, directDownloadPdf} from '../../../../helper/ReactPdf'
import {RekapModalPdf} from '../../../../helper/KemenagUi'
import {groupNaskah} from '../../../../helper/KemenagFunction'

// const dummyDataDisposisi = [
//   {
//     tanggal: '2024-01-18T11:02:59Z',
//     batas_waktu: '2024-01-18T11:02:59Z',
//     koordinasi: 'koordinasinya',
//     instruksi: 'instruksinya',
//     status: 'Belum dibaca',
//     dilakukan_oleh: {
//       nama: 'Miftahul Huda',
//       jabatan: 'Auditor Muda Inspektorat Wilayah III',
//     },
//     kepada: {
//       nama: 'Miftahul Huda',
//       jabatan: 'Auditor Muda Inspektorat Wilayah III',
//     },
//   },
// ]

// const dummyDataBalas = [
//   {
//     tanggal: '2024-01-18T11:02:59Z',
//     dilakukan_oleh: {
//       nama: 'Miftahul Huda',
//       jabatan: 'Auditor Muda Inspektorat Wilayah III',
//     },
//     kepada: {
//       nama: 'Miftahul Huda',
//       jabatan: 'Auditor Muda Inspektorat Wilayah III',
//     },
//   },
// ]

// const dummyDataHistoryNaskah = [
//   {
//     tanggal: '2024-01-18T11:02:59Z',
//     pengirim: 'Kastolan',
//     pegawai: {
//       nama: 'Kastolan - Plt. Sekretaris Inspektorat Jenderal',
//       jabatan: 'Sekretariat Inspektorat Jenderal Kementerian Agama - Kementerian Agama',
//       foto: 'files/avatar/avatar_12122023204007_nemeka.jpg',
//     },
//     jenis_naskah: 'Nota Dinas',
//     hal: 'coba tanda tangan konvensional',
//     penerima: {
//       utama: [
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//       ],
//       tebusan: [
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//       ],
//     },
//   },
// ]

// const dummyData = [
//   {
//     id: 1,
//     tanggal_naskah: '2024-01-18T11:02:59Z',
//     nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
//     hal: 'coba tanda tangan konvensional',
//     asal_naskah: 'Inspektorat Wilayah IV',
//     tingkat_urgensi: 'Biasa',
//     status_verifikator: 'Pending',
//     status_penandatangan: 'Pending',
//     status_kirim: 'Pending',
//     status_berkas: 'Pending',
//     nomor_referensi: 'Tidak ada',
//     isi: 'mencoba testing aplikasi tanda tangan konvensional',
//     jenis_naskah: 'Surat Dinas Eselon I dan II',
//     sifat_naskah: 'Biasa',
//     klasifikasi: 'KS.01.3 - Mesin Kantor/Alat Elektronik',
//     tipe_tandatangan: 'TTE',
//     tanggal_regis_pada: '2024-01-18T11:02:59Z',
//     diregis_oleh:
//       'Analis Sistem Informasi - Tim Kerja Sistem Informasi Inspektorat Jenderal Kementerian Agama',
//     dikirim_melalui: 'Sekretariat Inspektorat Jenderal Kementerian Agama',
//     tujuan: {
//       utama: [
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//       ],
//       tebusan: [
//         {
//           status_baca: 'Belum dibaca',
//           pegawai: {
//             nama: 'Miftahul Huda',
//             jabatan: 'Auditor Muda Inspektorat Wilayah III',
//           },
//         },
//       ],
//     },
//     linimasa: [
//       {
//         tanggal: '2024-01-18T11:02:59Z',
//         nama: 'Disetujui oleh Penandatanganan 1',
//         pegawai: {
//           nama: 'Miftahul Huda',
//           jabatan: 'Auditor Muda Inspektorat Wilayah III',
//         },
//       },
//       {
//         tanggal: '2024-01-18T11:02:59Z',
//         nama: 'Disetujui oleh Penandatanganan 1',
//         pegawai: {
//           nama: 'Miftahul Huda',
//           jabatan: 'Auditor Muda Inspektorat Wilayah III',
//         },
//       },
//       {
//         tanggal: '2024-01-18T11:02:59Z',
//         nama: 'Disetujui oleh Penandatanganan 1',
//         pegawai: {
//           nama: 'Miftahul Huda',
//           jabatan: 'Auditor Muda Inspektorat Wilayah III',
//         },
//       },
//       {
//         tanggal: '2024-01-18T11:02:59Z',
//         nama: 'Disetujui oleh Penandatanganan 1',
//         pegawai: {
//           nama: 'Miftahul Huda',
//           jabatan: 'Auditor Muda Inspektorat Wilayah III',
//         },
//       },
//     ],
//   },
//   {
//     id: 2,
//     tanggal_naskah: '2024-01-18T11:02:59Z',
//     nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
//     hal: 'coba tanda tangan konvensional',
//     asal_naskah: 'Inspektorat Wilayah IV',
//     tingkat_urgensi: 'Biasa',
//     status_verifikator: 'Pending',
//     status_penandatangan: 'Pending',
//     status_kirim: 'Pending',
//     status_berkas: 'Pending',
//   },
//   {
//     id: 3,
//     tanggal_naskah: '2024-01-18T11:02:59Z',
//     nomor_naskah: '	B-71/Set.IJ/KS.01.3/01/2024',
//     hal: 'coba tanda tangan konvensional',
//     asal_naskah: 'Inspektorat Wilayah IV',
//     tingkat_urgensi: 'Biasa',
//     status_verifikator: 'Pending',
//     status_penandatangan: 'Pending',
//     status_kirim: 'Pending',
//     status_berkas: 'Pending',
//   },
// ]

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const [isDownload, setIsDownload] = useState<boolean>(false)
  const [isRekapEmpty, setIsRekapEmpty] = useState<boolean>(false)
  const [rekapModal, setRekapModal] = useState<boolean>(false)

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <RekapModalPdf
        show={rekapModal}
        onClose={() => setRekapModal(false)}
        onChange={() => setIsRekapEmpty(false)}
        onSubmit={async (e) => {
          let type
          let queryRekap = ''
          let rekapPdfLayout: any
          if (e?.year) {
            queryRekap = `size=all&type=tahun&tahun=${e?.year}`
            rekapPdfLayout = PdfLayoutTahunan
            type = 'year'
          } else {
            queryRekap = `size=all&type=date&start_date=${e?.start_date}&end_date=${e?.end_date}`
            // rekapPdfLayout = PdfLayoutRange
            rekapPdfLayout = PdfLayoutTahunan
            type = 'range'
          }
          try {
            setRekapModal(false)
            setIsRekapEmpty(false)
            setIsDownload(true)
            const url = InfoData.api
            const response = await getData(queryRekap, url)
            if (response?.status) {
              const data = {data: response?.data, ...e, type}
              if (response?.data?.length > 0) {
                directDownloadPdf({document: rekapPdfLayout, data: data})
              } else {
                setIsRekapEmpty(true)
              }
            }
          } catch (_) {}
          setIsDownload(false)
        }}
      >
        {isRekapEmpty && (
          <div className='alert alert-warning text-dark d-flex align-items-center gap-4'>
            <ReactIcon icon='RiErrorWarningLine' props={{style: {width: '40px', height: '40px'}}} />
            <div>
              <div className='fw-bold fs-5'>Data Rekap Kosong</div>
              <div>Mohon untuk coba dengan data lain.</div>
            </div>
          </div>
        )}
      </RekapModalPdf>
      <CardUi
        title={PageData.semua.page.title}
        // toolbars={
        //   <>
        //     <ButtonLoading
        //       loading={isDownload}
        //       icon='RiDownload2Line'
        //       title={{button: 'Download Rekap PDF'}}
        //       props={{
        //         onClick: () => setRekapModal(true),
        //       }}
        //     />
        //   </>
        // }
      >
        <TableInput modal={modalConfig} onSuccess={(val: any) => setData(val)} {...TableInputData}>
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' className='min-w-100px'>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Tanggal Naskah' className='min-w-150px'>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='Nomor Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'nomor_naskah'})}
                  </TableColumn>
                  <TableColumn label='Hal' className='min-w-250px'>
                    {NullProof({input: l, params: 'hal'})}
                  </TableColumn>
                  <TableColumn label='Asal Naskah' className='min-w-150px'>
                    {NullProof({input: l, params: 'dikirimkan_melalui_data.nama_satker'})}
                  </TableColumn>
                  <TableColumn label='Tingkat Urgensi' className='min-w-150px'>
                    {NullProof({input: l, params: 'tingkat_urgensi_data.text'})}
                  </TableColumn>
                  <TableColumn label='Status Kirim' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_kirim'})}
                  </TableColumn>
                  <TableColumn label='Status Berkas' className='min-w-150px'>
                    {NullProof({input: l, params: 'status_berkas'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={PageData.ubah.page.breadcrumb.data}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={(e) => {
            groupNaskah({e, groupId: 'grup_tujuan', valueId: 'utama'})
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={(e) => {
            groupNaskah({e, groupId: 'grup_tujuan', valueId: 'utama'})
          }}
        />
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()

  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayoutRange}
      />
    </>
  )
}
const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const [key, setKey] = useState<number>(0)
  // Disposisi
  const [dataDisposisi, setDataDisposisi] = useState<any>([])
  const [modalDisposisi, setModalDisposisi] = useState<any>([])
  const modalDisposisiConfig = {
    data: modalDisposisi,
    post: setModalDisposisi,
  }
  // Balas
  const [dataBalas, setDataBalas] = useState<any>([])
  const [modalBalas, setModalBalas] = useState<any>([])
  const modalBalasConfig = {
    data: modalBalas,
    post: setModalBalas,
  }
  // History Naskah
  const [dataHistory, setDataHistory] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalHistoryConfig = {
    data: modalHistory,
    post: setModalHistory,
  }
  return (
    <>
      <Modal
        id='modal_statusubah'
        isShow={modal?.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            isFeedback: true,
            type: 'put',
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              // console.log(e)
              setModal((p: any) => ({...p, statusUbah: false}))
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi
          props={{
            className: `text-white ${data.status_kirim ? 'bg-success' : 'bg-dark'} mb-5`,
          }}
        >
          <div className='d-flex justify-content-between align-items-center'>
            <ReactIcon
              icon={`${data.status_kirim ? 'RiCheckLine' : 'RiQuestionMark'}`}
              props={{style: {fontSize: '2.5rem', fontWeight: 'bold'}}}
            />
            <div className='d-flex flex-column text-end'>
              <div className='fs-4 fw-bold'>
                Naskah {data.status_kirim ? 'Telah' : 'Belum'} Dikirim
              </div>
              <div>
                {data.status_kirim
                  ? `Pada ${formatDate({
                      date:
                        NullProof({input: data, params: 'tanggal_dikirim', isLabel: false}) || '',
                      dateFormat: 'dd MMMM yyyy HH:mm:ss',
                    })}`
                  : 'pastikan Meta Data dan Korespondensi naskah ini sudah sesuai'}
              </div>
            </div>
          </div>
        </CardUi>
        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          toolbars={data.status_kirim ? null : <ButtonKirimNaskah data={data} />}
        >
          <TableInputDetail
            // isDummy
            onSuccess={(val: any) => setData(val)}
            {...TableInputDetailData}
          >
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Referensi'}}
                >
                  {NullProof({input: data, params: 'nomor_ref_id'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Nomor Naskah'}}
                >
                  {NullProof({input: data, params: 'nomor_naskah'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn
                  hideDivider
                  isResponsive='desktop'
                  title={{value: 'Tanggal Naskah'}}
                >
                  {NullProof({input: data, params: 'createdAt', type: 'date'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Hal'}}>
                  {NullProof({input: data, params: 'hal'})}
                </TableInputDetailColumn>
              </div>
              <div className='col-12 col-md-4'>
                <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Isi'}}>
                  {NullProof({input: data, params: 'isi_ringkas'})}
                </TableInputDetailColumn>
              </div>
            </div>
            <div className='my-4 d-flex flex-column gap-2'>
              <Accordion
                title='Detail Naskah'
                enabled
                props={{body: {className: 'p-4 border-bottom'}}}
              >
                <div className='row'>
                  <div className='row col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Jenis Naskah'}}
                    >
                      {NullProof({input: data, params: 'JenisNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Sifat Naskah'}}
                    >
                      {NullProof({input: data, params: 'SifatNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Tingkat Urgensi'}}
                    >
                      {NullProof({input: data, params: 'tingkat_urgensi_data.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Klasifikasi'}}
                    >
                      {NullProof({input: data, params: 'KlasifikasiNaskah.text'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Tipe Tandatangan'}}
                    >
                      {NullProof({input: data, params: 'tipe_tandatangan'})}
                    </TableInputDetailColumn>
                  </div>
                  <div className='row col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row gy-1'}}
                      hideDivider
                      title={{value: 'Berkas'}}
                    >
                      {NullProof({input: data, params: 'status_berkas'}) == 1 ? 'Sudah' : 'Belum'}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Diregistrasikan Pada'}}
                    >
                      {NullProof({input: data, params: 'createdAt', type: 'date'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Diregistrasikan Oleh'}}
                    >
                      {NullProof({input: data, params: 'pegawai.nama'})}
                    </TableInputDetailColumn>
                    <TableInputDetailColumn
                      props={{className: 'row gy-2'}}
                      hideDivider
                      title={{value: 'Dikirim Melalui'}}
                    >
                      {NullProof({input: data, params: 'dikirimkan.nama_satker'})}
                    </TableInputDetailColumn>
                  </div>
                </div>
              </Accordion>
              <Accordion title='Tujuan' enabled props={{body: {className: 'p-4 border-bottom'}}}>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row mb-4'}}
                      hideDivider
                      title={{value: 'Utama:'}}
                    ></TableInputDetailColumn>
                    {NullProof({input: data, params: 'utama', isMap: true})?.map(
                      (l: any, i: number) => (
                        <Fragment key={i}>
                          <TableInputDetailColumn
                            props={{className: 'row mb-2'}}
                            hideDivider
                            title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                          >
                            {NullProof({input: l, params: 'pegawai.jabatan.tampil_jabatan'})}
                          </TableInputDetailColumn>
                        </Fragment>
                      )
                    )}
                  </div>
                  <div className='col-12 col-md-6'>
                    <TableInputDetailColumn
                      props={{className: 'row mb-4'}}
                      hideDivider
                      title={{value: 'Tembusan:'}}
                    ></TableInputDetailColumn>
                    {NullProof({input: data, params: 'tembusan', isMap: true})?.map(
                      (l: any, i: number) => (
                        <Fragment key={i}>
                          <TableInputDetailColumn
                            props={{className: 'row mb-2'}}
                            hideDivider
                            title={{value: NullProof({input: l, params: 'pegawai.nama'})}}
                          >
                            {NullProof({input: l, params: 'pegawai.tampil_jabatan'})}
                          </TableInputDetailColumn>
                        </Fragment>
                      )
                    )}
                  </div>
                </div>
              </Accordion>
            </div>
          </TableInputDetail>
          {/* {data.file_naskah && <ReactPdfViewer url={`/${data.file_naskah}`} />} */}
        </CardUi>
        <CardUi props={{className: 'my-6'}}>
          <ReactPdfViewer
            url={
              data.surat_tugas_id != null &&
              NullProof({input: data, params: 'file_naskah', isLabel: false}) !== ''
                ? `/tte/signed${NullProof({input: data, params: 'file_naskah', isLabel: false})}`
                : NullProof({input: data, params: 'file_naskah', isLabel: false}) !== ''
                ? `/${NullProof({input: data, params: 'file_naskah', isLabel: false})}`
                : ''
            }
          />
        </CardUi>
        <CardUi title='Lampiran' props={{className: 'my-6'}}>
          {NullProof({input: data, params: 'lampiran_naskah', isMap: true})?.length > 0 ? (
            <ListdataUi
              id='lampiran'
              title='Lampiran'
              data={NullProof({input: data, params: 'lampiran_naskah', isMap: true})}
            />
          ) : (
            'Tidak ada lampiran'
          )}
        </CardUi>
        <CardUi title='Daftar Verifikator' props={{className: 'my-6'}}>
          <TableDaftarVerifikator
            dataInput={NullProof({input: data, params: 'verifikator', isMap: true})}
          />
        </CardUi>
        <CardUi title='Daftar Penandatanganan' props={{className: 'my-6'}}>
          <TableDaftarPenandatanganan
            dataInput={NullProof({input: data, params: 'penandatangan', isMap: true})}
          />
        </CardUi>
        <TabsUi
          title='Daftar Riwayat Perubahan Metadata'
          tabs={[
            {
              id: 'naskah_dinas',
              title: 'Naskah Dinas',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputNaskahDinas}
                  title={'Naskah Dinas'}
                />
              ),
            },
            {
              id: 'lampiran',
              title: 'Lampiran',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputLampiran} title={'Lampiran'} />
              ),
            },
            {
              id: 'tujuan',
              title: 'Tujuan',
              children: (
                <TableRiwayatPerubahanMetadata dataInput={TableInputTujuan} title={'Tujuan'} />
              ),
            },
            {
              id: 'verifikator',
              title: 'Verifikator',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputVerifikator}
                  title={'Verifikator'}
                />
              ),
            },
            {
              id: 'penandatanganan',
              title: 'Penandatanganan',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputPenandatanganan}
                  title={'Penandatanganan'}
                />
              ),
            },
            {
              id: 'status_kirim',
              title: 'Status Kirim',
              children: (
                <TableRiwayatPerubahanMetadata
                  dataInput={TableInputStatusKirim}
                  title={'Status Kirim'}
                />
              ),
            },
          ]}
        />
        <CardUi title='Linimasa'>
          {NullProof({input: data, params: 'linimasa', isMap: true})?.map((l: any, i: number) => (
            <Fragment key={i}>
              <TimelineUi
                isEnd={i + 1 === NullProof({input: data, params: 'linimasa', isMap: true})?.length}
                title={NullProof({input: l, params: 'perubahan'})}
                date={formatDate({
                  date: NullProof({input: l, params: 'createdAt', isLabel: false}) || '',
                  dateFormat: 'dd MMMM yyyy HH:mm:ss',
                })}
              >
                <div>{NullProof({input: l, params: 'pegawai.nama'})}</div>
                <div>{NullProof({input: l, params: 'pegawai.tampil_jabatan'})}</div>
              </TimelineUi>
            </Fragment>
          ))}
        </CardUi>
      </Fragment>
    </>
  )
}

const ButtonKirimNaskah: FC<{data: any}> = ({data}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const query = useParams()
  const TableInputData = TableInputDataDisposisi
  const kirimNaskah = async () => {
    try {
      const result: any = await putData({}, `/tata_usaha/naskah_keluar/${query.id}/kirim`)
      if (result?.status === true) {
        setModal((p: any) => ({...p, statusUbah: false}))
        Swal.fire({
          icon: 'success',
          title: 'Naskah berhasil dikirim.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    }
  }

  return (
    <>
      <Modal
        id='modal_kirim_naskah'
        isShow={modal.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Kirim Naskah'
      >
        <div>
          Anda akan mengirim naskah dengan nomor{' '}
          <span className='fw-bold'>{NullProof({input: data, params: 'nomor_naskah'})}</span> yang
          akan diterima oleh:
          {/* <TableInputDetailColumn isResponsive='desktop' title={{value: 'Verifikator'}}>
            UTAMA
          </TableInputDetailColumn> */}
          <TableInputDetailColumn hideDivider isResponsive='desktop' title={{value: 'Verifikator'}}>
            {NullProof({input: data, params: 'verifikator', isMap: true})?.map(
              (l: any, i: number) => (
                <Fragment key={i}>
                  <div>{NullProof({input: l, params: 'pegawai.nama'})}</div>
                </Fragment>
              )
            )}
          </TableInputDetailColumn>
        </div>
        <div className='d-flex flex-row-reverse gap-2 mt-10'>
          <button
            className='btn btn-primary d-flex gap-1 align-items-center'
            onClick={() => {
              kirimNaskah()
            }}
          >
            {'Kirim'}
          </button>
          <button
            className='btn btn-light text-primary border border-primary d-flex gap-1 align-items-center'
            onClick={() => {
              setModal((p: any) => ({...p, statusUbah: false}))
            }}
          >
            {'Batal'}
          </button>
        </div>
      </Modal>
      <div className=' d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-primary d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            })
          }}
        >
          <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3'}} />
          {'Kirim Naskah'}
        </button>
      </div>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
