import {FC, Fragment, useState} from 'react'
import {
  TableAction,
  TableColumn,
  TableInput,
  TableInputDataProps,
  TableRow,
} from '../../../../helper/TableInput'
import {useParams} from 'react-router-dom'
import {NullProof, getStatusColor, numberlistPagination} from '../../../../helper/AppFunction'
// import {TableInputDataHistoryNaskah} from './PageData'
import Modal from '../../../../helper/Modal'
import {ReactPdfViewer} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {postData, putData} from '../../../../helper/FormAxios'
import Swal from 'sweetalert2'
import {getData} from '../../../../helper/FormAxios'
import ReactIcon from '../../../../helper/ReactIcon'
import {InfoData} from './PageData'

const RouteList = {
  filterUsulanPegawai: '/pengawasan_internal/list-usulan-date-based',
  filterUsulanTujuanSatker: '/pengawasan_internal/list-tujuan-satker',
  filterStPegawai: '/pengawasan_internal/list-st-date-based',
  filterStTujuanSatker: '/pengawasan_internal/list-st-tujuan-satker',
}

const TableInputUsulanByDate: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengusul',
    },
    {
      title: 'Tanggal Usulan',
    },
    {
      title: 'Deskripsi',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Status Usulan',
    },
    {
      title: 'Tujuan',
    },

    {
      title: 'Anggota',
      props: {
        className: '',
        style: {
          minWidth: '400px',
        },
      },
    },
  ],
  route: {
    url: RouteList.filterUsulanPegawai,
    query: '',
    // query: 'byuser=true',
  },
}

const TableInputTujuanSatker: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Pengusul',
    },
    {
      title: 'Tanggal Usulan',
    },
    {
      title: 'Deskripsi',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Status Usulan',
    },
    {
      title: 'Tujuan',
    },

    {
      title: 'Anggota',
      props: {
        className: '',
        style: {
          minWidth: '400px',
        },
      },
    },
  ],
  route: {
    url: RouteList.filterUsulanTujuanSatker,
    query: '',
    // query: 'byuser=true',
  },
}

const TableInputSTByDate: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Nomor ST',
    },
    {
      title: 'Tanggal ST',
    },
    {
      title: 'Kegiatan Tugas',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Tujuan',
    },

    {
      title: 'Anggota',
      props: {
        className: '',
        style: {
          minWidth: '400px',
        },
      },
    },
  ],
  route: {
    url: RouteList.filterStPegawai,
    query: 'type=penandatangan',
    // query: 'byuser=true',
  },
}

const TableInputStTujuanSatker: TableInputDataProps = {
  headerData: [
    {
      title: 'No',
      props: {
        className: 'mw-100px',
      },
    },
    {
      title: 'Nomor ST',
    },
    {
      title: 'Tanggal ST',
    },
    {
      title: 'Kegiatan Tugas',
    },
    {
      title: 'Tanggal Pelaksanaan',
    },
    {
      title: 'Tujuan',
    },
    {
      title: 'Anggota',
      props: {
        className: '',
        style: {
          minWidth: '400px',
        },
      },
    },
  ],
  route: {
    url: RouteList.filterStTujuanSatker,
    query: 'type=penandatangan',
    // query: 'byuser=true',
  },
}

const TableListUsulanByDate: FC<{dataInput: any; pegawaiId: any; indexs?: any}> = ({
  dataInput,
  pegawaiId,
  indexs,
}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }

  const TableInputData = TableInputUsulanByDate
  TableInputData.route.url = `${TableInputData.route.url}`
  TableInputData.route.query = `tanggal_mulai=${dataInput.tanggal_mulai}&tanggal_selesai=${dataInput.tanggal_selesai}&pegawaiId=${pegawaiId}&index=${indexs}`

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => {
        setData(val)
      }}
      isModal
      {...TableInputData}
      // isDummy
      // staticData={dataInput !== '_' ? dataInput : null}
      // headerData={TableInputData.headerData}
      // link={TableInputData.link}
      route={TableInputData.route}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <TableColumn label='No.' props={{style: {width: '50px'}}}>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </TableColumn>
              <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'pegawai.nama'})}
              </TableColumn>
              <TableColumn label='Tanggal Usulan' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'dd MMMM yyyy',
                })}{' '}
                <br />
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'HH:mm:ss',
                })}
              </TableColumn>
              <TableColumn label='Deskripsi' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'deskripsi_kegiatan'})}
              </TableColumn>
              <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                          -{' '}
                          {formatDate({
                            date: NullProof({input: ll, params: 'tanggal_mulai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })}
                          {' s/d '}
                          {formatDate({
                            date: NullProof({input: ll, params: 'tanggal_selesai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Status Usulan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                  <div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_irwil', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-50'>
                        Riviu irwil :{' '}
                        <span
                          style={{
                            color: getStatusColor(NullProof({input: l, params: 'status_irwil'})),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'status_irwil'})}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_disposisi', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-50'>
                        Riviu disposisi :{' '}
                        <span
                          style={{
                            color: getStatusColor(
                              NullProof({input: l, params: 'status_disposisi'})
                            ),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                )}
                <div className='d-flex justify-content-center gap-2 mb-2'>
                  <div
                    className='rounded-circle'
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      backgroundColor: getStatusColor(
                        NullProof({input: l, params: 'status', isLabel: false})
                      ),
                    }}
                  ></div>
                  <div className='w-50'>
                    Riviu 1 :{' '}
                    <span style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}>
                      {'  '} {NullProof({input: l, params: 'status'})}
                    </span>
                    <br />
                  </div>
                </div>
                <div className='d-flex justify-content-center gap-2 mb-2'>
                  <div
                    className='rounded-circle'
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      backgroundColor: getStatusColor(
                        NullProof({input: l, params: 'status_2', isLabel: false})
                      ),
                    }}
                  ></div>
                  <div className='w-50'>
                    Riviu 2 :{' '}
                    <span
                      style={{color: getStatusColor(NullProof({input: l, params: 'status_2'}))}}
                    >
                      {'  '} {NullProof({input: l, params: 'status_2'})}
                    </span>
                    <br />
                  </div>
                </div>
              </TableColumn>
              <TableColumn label='Tujuan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                          - {NullProof({input: ll, params: 'satker.nama_satker'})} <br />
                          {ll.satker_2 && NullProof({input: ll, params: 'satker2.nama_satker'})} 
                          {ll.satker_3 && NullProof({input: ll, params: 'satker3.nama_satker'})}
                          
                          Provinsi {NullProof({input: ll, params: 'provinsi'})}{' '}
                          {NullProof({input: ll, params: 'kota'})}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Anggota' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        {NullProof({input: ll, params: 'kepada', isMap: true})?.map(
                          (lll: any, iii: number) => (
                            <Fragment key={iii}>
                              <div>
                                {iii + 1}. {NullProof({input: lll, params: 'pegawai.nama'})}
                              </div>
                            </Fragment>
                          )
                        )}
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableAction {...tableDataBuilder} />
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableListTujuanSatker: FC<{dataInput: any; pegawaiId?: any; indexs?: any}> = ({
  dataInput,
  pegawaiId,
  indexs,
}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }

  const TableInputData = TableInputTujuanSatker
  TableInputData.route.url = `${TableInputData.route.url}`
  TableInputData.route.query = `tanggal_mulai=${dataInput.tanggal_mulai}&tanggal_selesai=${dataInput.tanggal_selesai}&satkerId=${dataInput.satker_id}&index=${indexs}`
  // TableInputData.route.url = `${TableInputData.route.url}/${RouterQueryParams({query})}`

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => {
        setData(val)
      }}
      isModal
      {...TableInputData}
      // isDummy
      // staticData={dataInput !== '_' ? dataInput : null}
      // headerData={TableInputData.headerData}
      // link={TableInputData.link}
      route={TableInputData.route}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <TableColumn label='No.' props={{style: {width: '50px'}}}>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </TableColumn>
              <TableColumn label='Pengusul' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'pegawai.nama'})}
              </TableColumn>
              <TableColumn label='Tanggal Usulan' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'dd MMMM yyyy',
                })}{' '}
                <br />
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'HH:mm:ss',
                })}
              </TableColumn>
              <TableColumn label='Deskripsi' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'deskripsi_kegiatan'})}
              </TableColumn>
              <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                          -{' '}
                          {formatDate({
                            date: NullProof({input: ll, params: 'tanggal_mulai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })}
                          {' s/d '}
                          {formatDate({
                            date: NullProof({input: ll, params: 'tanggal_selesai'}),
                            dateFormat: 'dd MMMM yyyy',
                          })}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Status Usulan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'type_pengusul'}) === 'auditor' && (
                  <div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_irwil', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-50'>
                        Riviu irwil :{' '}
                        <span
                          style={{
                            color: getStatusColor(NullProof({input: l, params: 'status_irwil'})),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'status_irwil'})}
                        </span>
                        <br />
                      </div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_disposisi', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-50'>
                        Riviu disposisi :{' '}
                        <span
                          style={{
                            color: getStatusColor(
                              NullProof({input: l, params: 'status_disposisi'})
                            ),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'status_disposisi'})}
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                )}
                <div className='d-flex justify-content-center gap-2 mb-2'>
                  <div
                    className='rounded-circle'
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      backgroundColor: getStatusColor(
                        NullProof({input: l, params: 'status', isLabel: false})
                      ),
                    }}
                  ></div>
                  <div className='w-50'>
                    Riviu 1 :{' '}
                    <span style={{color: getStatusColor(NullProof({input: l, params: 'status'}))}}>
                      {'  '} {NullProof({input: l, params: 'status'})}
                    </span>
                    <br />
                  </div>
                </div>
                <div className='d-flex justify-content-center gap-2 mb-2'>
                  <div
                    className='rounded-circle'
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      backgroundColor: getStatusColor(
                        NullProof({input: l, params: 'status_2', isLabel: false})
                      ),
                    }}
                  ></div>
                  <div className='w-50'>
                    Riviu 2 :{' '}
                    <span
                      style={{color: getStatusColor(NullProof({input: l, params: 'status_2'}))}}
                    >
                      {'  '} {NullProof({input: l, params: 'status_2'})}
                    </span>
                    <br />
                  </div>
                </div>
              </TableColumn>
              <TableColumn label='Tujuan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                        - {NullProof({input: ll, params: 'satker.nama_satker'})} <br />
                          {ll.satker_2 && NullProof({input: ll, params: 'satker2.nama_satker'})} 
                          {ll.satker_3 && NullProof({input: ll, params: 'satker3.nama_satker'})}
                          
                          Provinsi {NullProof({input: ll, params: 'provinsi'})}{' '}
                          {NullProof({input: ll, params: 'kota'})}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Anggota' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'usulan_tim', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        {NullProof({input: ll, params: 'kepada', isMap: true})?.map(
                          (lll: any, iii: number) => (
                            <Fragment key={iii}>
                              <div>
                                {iii + 1}. {NullProof({input: lll, params: 'pegawai.nama'})}
                              </div>
                            </Fragment>
                          )
                        )}
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const TableListSTByDate: FC<{dataInput: any; pegawaiId?: any; indexs?: any}> = ({
  dataInput,
  pegawaiId,
  indexs,
}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }

  const TableInputData = TableInputSTByDate
  TableInputData.route.query = `tanggal_mulai=${dataInput.tanggal_mulai}&tanggal_selesai=${dataInput.tanggal_selesai}&pegawaiId=${pegawaiId}&index=${indexs}`

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => {
        setData(val)
      }}
      isModal
      {...TableInputData}
      // isDummy
      // staticData={dataInput !== '_' ? dataInput : null}
      // headerData={TableInputData.headerData}
      // link={TableInputData.link}
      route={TableInputData.route}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <TableColumn label='No.' props={{style: {width: '50px'}}}>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </TableColumn>
              <TableColumn label='Nomor ST' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'nomor_surat'})}
              </TableColumn>
              <TableColumn label='Tanggal ST' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'dd MMMM yyyy',
                })}{' '}
                <br />
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'HH:mm:ss',
                })}
              </TableColumn>
              <TableColumn label='Kegiatan Tugas' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'nama_kegiatan'})}
              </TableColumn>
              <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'tanggal_mulai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
                {' s/d '}
                {formatDate({
                  date: NullProof({input: l, params: 'tanggal_selesai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
              </TableColumn>
              <TableColumn label='Tujuan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                          - {NullProof({input: ll, params: 'provinsi'})}{' '}
                          {NullProof({input: ll, params: 'kota'})}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Anggota' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'kepada', isMap: true})?.map(
                  (ll: any, ii: number) => (
                    <Fragment key={ii}>
                      <div>
                        {ii + 1}. {NullProof({input: ll, params: 'pegawai.nama'})}
                      </div>
                    </Fragment>
                  )
                )}
              </TableColumn>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}
const TableListStTujuanSatker: FC<{dataInput: any; pegawaiId?: any; indexs?: any}> = ({
  dataInput,
  pegawaiId,
  indexs,
}) => {
  const query = useParams()
  const [data, setData] = useState<any>([])
  const [modalHistory, setModalHistory] = useState<any>([])
  const modalConfig = {
    data: modalHistory,
    post: setModalHistory,
  }

  const TableInputData = TableInputStTujuanSatker
  TableInputData.route.query = `tanggal_mulai=${dataInput.tanggal_mulai}&tanggal_selesai=${dataInput.tanggal_selesai}&satkerId=${dataInput.satker_id}&index=${indexs}`

  return (
    <TableInput
      modal={modalConfig}
      onSuccess={(val: any) => {
        setData(val)
      }}
      isModal
      {...TableInputData}
      // isDummy
      // staticData={dataInput !== '_' ? dataInput : null}
      // headerData={TableInputData.headerData}
      // link={TableInputData.link}
      route={TableInputData.route}
    >
      {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
        const tableDataBuilder = {
          modal: modalConfig,
          input: {data: l, index: i},
          action: TableInputData.link?.action || [],
        }
        return (
          <Fragment key={i}>
            <TableRow {...tableDataBuilder}>
              <TableColumn label='No.' props={{style: {width: '50px'}}}>
                {numberlistPagination({n: i, p: data?.page, t: data?.size})}
              </TableColumn>
              <TableColumn label='Nomor ST' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'nomor_surat'})}
              </TableColumn>
              <TableColumn label='Tanggal ST' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'dd MMMM yyyy',
                })}{' '}
                <br />
                {formatDate({
                  date: NullProof({input: l, params: 'createdAt'}),
                  dateFormat: 'HH:mm:ss',
                })}
              </TableColumn>
              <TableColumn label='Kegiatan Tugas' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'nama_kegiatan'})}
              </TableColumn>
              <TableColumn label='Tanggal Pelaksanaan' props={{style: {minWidth: '200px'}}}>
                {formatDate({
                  date: NullProof({input: l, params: 'tanggal_mulai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
                {' s/d '}
                {formatDate({
                  date: NullProof({input: l, params: 'tanggal_selesai'}),
                  dateFormat: 'dd MMMM yyyy',
                })}
              </TableColumn>
              <TableColumn label='Tujuan' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'tujuan', isMap: true})?.map(
                  (ll: any, ii: number) => {
                    return (
                      <Fragment key={ii}>
                        <div>
                          - {NullProof({input: ll, params: 'provinsi'})}{' '}
                          {NullProof({input: ll, params: 'kota'})}
                        </div>
                        {ii !==
                          NullProof({input: l, params: 'usulan_tim', isMap: true})?.length - 1 && (
                          <div
                            className='bg-gray-200 my-2'
                            style={{width: '100%', height: '1px'}}
                          ></div>
                        )}
                      </Fragment>
                    )
                  }
                )}
              </TableColumn>
              <TableColumn label='Anggota' props={{style: {minWidth: '200px'}}}>
                {NullProof({input: l, params: 'kepada', isMap: true})?.map(
                  (ll: any, ii: number) => (
                    <Fragment key={ii}>
                      <div>
                        {ii + 1}. {NullProof({input: ll, params: 'pegawai.nama'})}
                      </div>
                    </Fragment>
                  )
                )}
              </TableColumn>
            </TableRow>
          </Fragment>
        )
      })}
    </TableInput>
  )
}

const ButtonKirimNaskah: FC<{data: any; onRefresh?: any}> = ({data, onRefresh}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const query = useParams()
  const body = {
    ...data,
    tipe_usulan: 'Terbit',
  }
  const TableInputData = null
  const updateUsulan = async () => {
    try {
      const result: any = await putData(body, `${InfoData.api}/${query.id}`)
      // const result:any = false
      if (result?.status === true) {
        setModal((p: any) => ({...p, statusUbah: false}))
        onRefresh && onRefresh()
        Swal.fire({
          icon: 'success',
          title: 'Usulan berhasil diubah menjadi Terbit.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    }
  }

  return (
    <>
      <Modal
        id='modal_kirim_naskah'
        isShow={modal.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Update Usulan'
      >
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-center'>
            <h4>Apakah anda yakin ingin mengubah usulan ini menjadi 'Terbit' ?</h4>
          </div>
          <div></div>
        </div>
        <div className='d-flex flex-row-reverse gap-2 mt-10'>
          <button
            className='btn btn-primary d-flex gap-1 align-items-center'
            onClick={() => {
              updateUsulan()
            }}
          >
            {'Update'}
          </button>
          <button
            className='btn btn-light text-primary border border-primary d-flex gap-1 align-items-center'
            onClick={() => {
              setModal((p: any) => ({...p, statusUbah: false}))
            }}
          >
            {'Batal'}
          </button>
        </div>
      </Modal>
      <div className=' d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-primary d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            })
          }}
        >
          <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3'}} />
          {'Kirim Usulan'}
        </button>
      </div>
    </>
  )
}

export {
  TableListUsulanByDate,
  TableListTujuanSatker,
  TableListSTByDate,
  TableListStTujuanSatker,
  ButtonKirimNaskah,
}
