import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {
  NullProof,
  getApiPath,
  getRandomColor,
  letterAvatar,
} from '../../../../app/helper/AppFunction'
import {NotifikasiButton} from '../../../../app/pages/notifikasi/NotifikasiButton'
import ReactIcon from '../../../../app/helper/ReactIcon'
import {useState} from 'react'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const {config} = useLayout()
  const {auth} = useAuth()
  const userData = auth?.data
  const nameLetter = letterAvatar(userData?.fullname || '')
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      <NotifikasiButton className={{button: btnClass, item: itemClass}} />

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
          onClick={() => {
            console.log('!')
          }}
        >
          {totalNotifikasi > 0 && (
            <div className='position-absolute top-0 end-0 w-20px h-20px bg-danger rounded-circle text-white'>
              {totalNotifikasi}
            </div>
          )}
          <ReactIcon icon='RiNotification3Line' props={{className: 'fs-2'}} />
        </div>
        
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div
            className='overflow-hidden w-40px h-40px rounded-circle fs-2 fw-bold text-uppercase d-flex justify-content-center align-items-center'
            style={{
              backgroundColor: getRandomColor(nameLetter).backgroundColor,
              color: getRandomColor(nameLetter).textColor,
            }}
          >
            {NullProof({input: userData, params: 'img_profile', isLabel: false}) ? (
              <img
                className='w-100 h-100 object-cover'
                src={`${getApiPath()}/${NullProof({input: userData, params: 'img_profile'})}`}
              />
            ) : (
              <>{nameLetter}</>
            )}
          </div>
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
