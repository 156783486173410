import {MainWrapper} from '../../../../../pages/pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/daftar-status-usulan/revisi/MainWrapper'
import {DataRoutesProps} from '../../../../UserRolesValidation'
import {
  PdfWrapper,
  PdfWrapperPreviewST,
  DetailWrapper,
  AdjustmentRabWrapper,
  MainWrapperListUsulan,
} from '../../../../../pages/pengawasan-internal/persiapan-pelaksanaan-pengawasan-internal/form-usulan/MainWrapper'

const RevisiRoutes: DataRoutesProps[] = [
  {
    icon: '',
    title: '',
    route: '*',
    elements: <MainWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id',
    elements: <DetailWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/anggota-pdf',
    elements: <PdfWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/adjustment-rab',
    elements: <AdjustmentRabWrapper />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/previewst-pdf',
    elements: <PdfWrapperPreviewST />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/list-usulan-pegawai',
    elements: <MainWrapperListUsulan type='pegawai' />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/list-usulan-tujuan',
    elements: <MainWrapperListUsulan type='tujuan' />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/list-usulan-tugas-pegawai',
    elements: <MainWrapperListUsulan type='tugas-pegawai' />,
    subroute: [],
  },
  {
    icon: '',
    title: '',
    route: 'detail/:id/list-usulan-tugas-tujuan',
    elements: <MainWrapperListUsulan type='tugas-tujuan' />,
    subroute: [],
  },
]

export {RevisiRoutes}
