import {FC, Fragment, useEffect, useRef, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import ReactIcon from './ReactIcon'
import {ProtectComponent, hasAccessRoutes} from './UserRolesValidation'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {deleteData, getData, putData, postData} from './FormAxios'
import Modal from './Modal'
import {
  NullProof,
  PaginationStaticDataProps,
  PopupConfirm,
  RouterQueryParams,
  getPaginationStaticData,
} from './AppFunction'
import Pagination from './Pagination'
import * as Icons from 'react-icons/ri'
import {
  ButtonLoading,
  Divider,
  DropdownUi,
  DropdownUiMenuPropsV1 as DropdownUiMenuProps,
  SelectUi,
} from './AppUi'
import {useDispatch, useSelector} from 'react-redux'
import FormInput, {FormInputProps} from './FormInput'
import {post as postTable, clear as clearTable} from '../../app/redux/tableSlice'
import {useAuth} from '../modules/auth'
import {lb} from 'date-fns/locale'
import {AxiosRequestConfig} from 'axios'

type ModeResponsiveProps = 'table' | 'card' | 'auto'

interface onLoadDataProps {
  result?: any
  query?: any
}

export interface onLoadProps {
  initLoading: () => void
  query?: {
    get?: any
    post: (query: any) => void
  }
  load: ({result, query}: onLoadDataProps) => void
}

interface TableInputProps extends TableInputDataProps {
  hasSearch?: boolean
  isModal?: boolean
  idName?: string
  protect?: string
  className?: string
  isDummy?: boolean
  staticData?: any[]
  staticDataOptions?: {
    props?: {
      [key: string]: PaginationStaticDataProps
    }
  }
  modal: TableModalProps
  hasReconfirm?: boolean
  onSuccess: any
  onFeedback?: (data: any) => void
  onLoad?: (e: onLoadProps) => void
}

interface TableActionProps {
  id: string
  bindId?: string
  title: string
  icon?: keyof typeof Icons
  route: string
  protect: string
  disabledProtect?: boolean
  hide?: boolean
  loading?: boolean
  type?: 'route' | 'feedback' | 'display'
  input?: any
}

interface TableActionSubmenuProps extends TableActionProps {
  submenu?: TableActionSubmenuProps[]
}

interface TableModalProps {
  data: any
  post: any
}

export interface TableInputDataProps {
  popupFilter?: boolean
  modeResponsive?: ModeResponsiveProps
  headerData?: {
    title: string
    props?: React.DetailedHTMLProps<
      React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
      HTMLTableHeaderCellElement
    >
  }[]
  route: {
    url: string
    query?: string
    singleLoad?: boolean
    options?: AxiosRequestConfig
  }
  link?: {
    tambah?: TableActionProps
    export?: TableActionProps[]
    action?: TableActionProps[]
    submenu?: TableActionSubmenuProps[]
  }
  filter?: FormInputProps[]
  filterData?: any
  options?: {
    checkbox?: {
      title?: string
      name: string
      protect?: string
      loading?: boolean
      icon?: keyof typeof Icons
      type: 'put' | 'delete' | 'link' | 'feedback'
      url?: string
      api: string
      input?: FormInputProps[]
    }[]
  }
}

const toggleTableModal = (fieldsToUpdate: Partial<any>, modal: TableModalProps) => {
  const updatedData = Object.assign(modal.data, fieldsToUpdate)
  modal.post((p: any) => ({...p, ...updatedData}))
}

const TableHead: FC<
  WithChildren & {
    modeResponsive?: ModeResponsiveProps
    props?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
  }
> = ({children, props, modeResponsive = 'auto'}) => {
  return (
    <thead
      {...props}
      className={`fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200 text-center position-sticky top-0 ${
        props?.className || ''
      }`}
      style={{
        backgroundColor: 'var(--bs-body-bg)',
      }}
    >
      {children}
    </thead>
  )
}

const TableBody: FC<
  WithChildren & {
    props?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
  }
> = ({children, props}) => {
  return (
    <tbody {...props} className={`text-center ${props?.className || ''}`}>
      {children}
    </tbody>
  )
}

const TableAction: FC<{
  action: TableActionProps[]
  modal: TableModalProps
  input: {data: any; index: number}
  route?: string
}> = ({action, modal, input, route}) => {
  return (
    <>
      {(action?.length || 0) > 0 && (
        <ProtectComponent
          route={route}
          id={`${
            (action?.length || 0) > 0
              ? `,${action?.map((l) => {
                  return l.protect.toString()
                })}`
              : ''
          }`}
        >
          <th className='min-w-150px fw-normal'>
            <button
              className='btn btn-sm btn-primary w-100 my-1'
              onClick={() => {
                toggleTableModal({status: true, input: input.data, index: input.index}, modal)
              }}
            >
              <ReactIcon icon='RiListCheck' props={{className: 'me-2 fs-3'}} />
              Menu
            </button>
          </th>
        </ProtectComponent>
      )}
    </>
  )
}

const TableColumn: FC<
  WithChildren & {
    label?: string
    className?: string
    modeResponsive?: ModeResponsiveProps
    props?: React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
    childProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  }
> = ({props, label = '', className = '', modeResponsive = 'auto', children, childProps}) => {
  return (
    <td
      {...props}
      className={`${
        modeResponsive === 'table'
          ? `${className}`
          : `d-flex flex-column gap-2 px-4 py-2 ${
              modeResponsive === 'auto' ? 'd-lg-table-cell px-lg-6 py-lg-4' : ''
            } min-w-100px`
      }`}
    >
      {label && (modeResponsive === 'auto' || modeResponsive === 'card') && (
        <span className={`fw-bold ${modeResponsive === 'auto' ? 'd-lg-none' : ''}`}>{label}</span>
      )}
      <div {...childProps}>{children}</div>
    </td>
  )
}

const TableRow: FC<
  WithChildren & {
    action?: TableActionProps[]
    modal: TableModalProps
    input: {data: any; index: number; id?: string}
    props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>
  }
> = ({children, modal, input, props, action = []}) => {
  const [isMouseMove, setMouseMove] = useState<boolean>(false)
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxDispatch = useDispatch()
  const id = NullProof({input: input.data, params: input.id || 'id'})
  return (
    <tr
      {...props}
      className={action?.length > 0 ? 'cursor-pointer' : ''}
      onMouseMove={() => {
        setMouseMove(true)
      }}
      onMouseLeave={() => {
        setMouseMove(false)
      }}
      onMouseDown={() => {
        setMouseMove(false)
      }}
      onClick={() => {
        if (!isMouseMove) {
          if (reduxTableData?.checkbox?.status) {
            let _reduxTableId: any = [
              ...NullProof({input: reduxTableData, params: 'checkbox.id', isMap: true}),
            ]
            let _reduxTableData: any = [
              ...NullProof({input: reduxTableData, params: 'checkbox.data', isMap: true}),
            ]
            let _reduxTablePageId: any = [
              ...NullProof({
                input: reduxTableData,
                params: `checkbox.page.page${reduxTableData?.page || 1}`,
                isMap: true,
              }),
            ]
            const index = _reduxTableId?.indexOf(id)
            const indexPage = _reduxTablePageId?.indexOf(id)
            if (index > -1) {
              _reduxTableId.splice(index, 1)
              _reduxTableData.splice(index, 1)
            } else {
              _reduxTableId.push(id)
              _reduxTableData.push(input.data)
            }
            if (indexPage > -1) {
              _reduxTablePageId.splice(indexPage, 1)
            } else {
              _reduxTablePageId.push(id)
            }
            reduxDispatch(
              postTable({
                ...reduxTableData,
                checkbox: {
                  ...reduxTableData?.checkbox,
                  id: _reduxTableId,
                  data: _reduxTableData,
                  page: {
                    ...reduxTableData?.checkbox?.page,
                    [`page${reduxTableData?.page || 1}`]: _reduxTablePageId,
                  },
                },
              })
            )
          } else {
            action.length > 0 &&
              toggleTableModal({status: true, input: input.data, index: input.index}, modal)
          }
        }
      }}
    >
      {reduxTableData?.checkbox?.status && (
        <td className='w-50px'>
          <div className='form-check d-flex justify-content-center'>
            <input
              type='checkbox'
              checked={reduxTableData?.checkbox?.id?.includes(id) || false}
              onChange={() => {}}
              className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
            />
          </div>
        </td>
      )}
      {children}
    </tr>
  )
}

const TableInput: FC<TableInputProps & WithChildren> = ({
  hasReconfirm,
  hasSearch = true,
  modeResponsive = 'auto',
  idName = 'id',
  isModal,
  popupFilter,
  protect,
  className,
  headerData,
  route,
  link,
  modal,
  isDummy,
  staticData = [],
  staticDataOptions,
  onSuccess,
  onFeedback,
  children,
  filter = [],
  filterData,
  options,
  onLoad,
}) => {
  const listLimitData: string[] = ['all', '10', '20', '50', '100', '200', '500']
  const location = useLocation()
  const navigate = useNavigate()
  const queryUrl = useParams()
  const reduxTableData = useSelector((state: any) => state.table.data)
  const reduxDispatch = useDispatch()
  const {auth} = useAuth()
  const roleId = auth?.data.role_id
  const [filterKey, setFilterKey] = useState<number>(0)
  const [queryParameters] = useSearchParams()
  const [data, setData] = useState([])
  const [singleData, setSingleData] = useState<any[]>([])
  const [limitData, setLimitData] = useState<string | number>(
    listLimitData.includes(queryParameters.get('size') || '10')
      ? queryParameters.get('size') || '10'
      : 10
  )
  const routeData = useSelector((state: any) => state.route.value)
  const currentMenu = location.pathname.replaceAll('/', '-').substring(1)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [modalFilter, setModalFilter] = useState<boolean>(false)
  const [modalUbah, setModalUbah] = useState<{
    status: boolean
    type: string
  }>({
    status: false,
    type: '',
  })
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const tableScrollRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const scrollbarTopRef = useRef<HTMLDivElement>(null)
  const scrollbarTopContentRef = useRef<HTMLDivElement>(null)
  const [isMouseDrag, setMouseDrag] = useState<boolean>(false)
  const [mouseData, setMouseData] = useState<{offset: number; position: number}>({
    offset: 0,
    position: 0,
  })
  const updateSize = () => {
    if (tableRef.current) {
      if (scrollbarTopContentRef.current) {
        scrollbarTopContentRef.current.style.width = `${tableRef.current?.clientWidth}px`
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [tableRef])
  const handleMouseDrag = ({
    e,
    type,
  }: {
    e: React.MouseEvent<HTMLDivElement>
    type: 'drag' | 'up' | 'down'
  }) => {
    updateSize()
    if (type === 'down') {
      setMouseDrag(true)
      setMouseData({
        offset: e.pageX - (tableScrollRef.current?.offsetLeft ?? 0),
        position: tableScrollRef.current?.scrollLeft ?? 0,
      })
    } else if (type === 'up') {
      setMouseDrag(false)
    } else {
      if (isMouseDrag && tableScrollRef.current) {
        const {offset, position} = mouseData
        const x = e.pageX - (tableScrollRef.current.offsetLeft ?? 0)
        const walk = x - offset
        tableScrollRef.current.scrollLeft = position - walk
      }
    }
  }

  const queryFilterBuilder = () => {
    const queryFilterData = queryParameters.get('filter')?.replaceAll('-', '=')?.split(',')
    let result: any = {}
    queryFilterData?.map((l: any) => {
      const queryString = l.split('=')
      result[queryString[0]] = queryString[1]
    })
    return result
  }
  const [input, setInput] = useState<any>({
    filter: {...filterData, ...queryFilterBuilder()},
    lastcari: '',
    cari: '',
    currentPage: 1,
    totalPages: 1,
    currentTotalData: 0,
    totalData: 0,
    query: {},
  })
  const dropdownSubmenuBuilder = (data?: TableActionSubmenuProps[]): DropdownUiMenuProps[] => {
    let result: DropdownUiMenuProps[] = []
    data?.map((l, i) => {
      const dataRoute = `${location.pathname}/${l.route}`
      if (hasAccessRoutes(routeData?.route, dataRoute, roleId === 1)) {
        result.push({
          id: l.id,
          title: l.title,
          link: dataRoute,
          isFeedback: l.type === 'feedback',
          submenu: dropdownSubmenuBuilder(l.submenu || []),
        })
      }
    })
    return result.length > 0 ? result : []
  }
  const dropdownExportDataBuilder = () => {
    let result: any = []
    link?.export?.map((l, i) => {
      const dataRoute = `${location.pathname}/${l.route}`
      if (hasAccessRoutes(routeData?.route, dataRoute, roleId === 1)) {
        result.push({
          title: l.title,
          link: dataRoute,
        })
      }
    })
    return result.length > 0 ? [{title: 'Export Menu', submenu: result}] : []
  }
  const onGo = (url: string) => {
    navigate(url)
    updateInput({cari: ''})
  }
  const updateInput = (fieldsToUpdate: Partial<any>): void => {
    const updatedData = Object.assign(input, fieldsToUpdate)
    // console.log(updatedData, 'updatedData');
    setInput((p: any) => ({...p, ...updatedData}))
  }
  const userRolesValidation = (name: string, input: any) => {
    const attr: any[] = input?.split(',') || []
    const result = attr.includes(name)
    return result
  }
  const RoutesParams = (params: any, json: any) => {
    let listId: any = params?.id?.split(',') || []
    let route: string = params?.route
    listId?.forEach((l: any) => {
      if (json[l]) {
        route = route.replaceAll(`:${l}`, json[l])
        route = route.replaceAll(`$${l}`, queryUrl[l] || `$${l}`)
      }
    })
    return route
  }
  useEffect(() => {
    reduxDispatch(clearTable())
  }, [])
  const queryBuilder = () => {
    let query: any = {
      page: input?.currentPage || 1,
      search: input?.cari || '',
      size: limitData,
    }
    route.query?.split('&').map((l) => {
      const result = l.split('=')
      if (result[0] !== 'page') {
        query[result[0]] = result[1]?.toLowerCase()
      }
    })
    return query
  }
  const loadData = async (init = false) => {
    delete input.filter.search
    let query: any = queryBuilder()
    setError(false)
    setLoading(true)
    updateInput({lastcari: query?.search, query: query})
    let arrQuery: string[] = []
    let arrPath: string[] = []
    let arrFilter: string[] = []
    Object.keys(query).map((l) => {
      const result = `${l}=${query[l]}`.toLowerCase()
      query[l] && arrQuery.push(result)
      if (l !== 'size') {
        query[l] && arrPath.push(result)
      }
    })
    Object.keys(input?.filter || {}).map((l) => {
      const result = `${l}=${input?.filter[l]}`
      input?.filter[l] && arrQuery.push(result.toLowerCase())
      input?.filter[l] && arrFilter.push(result)
    })
    if (init) {
      if (queryParameters.get('filter')) {
        const listFilterQuery = queryParameters.get('filter')?.replaceAll('-', '=')?.split(',')
        arrQuery.push(...(listFilterQuery || []))
        arrFilter.push(...(listFilterQuery || []))
      }
    }
    if (arrFilter.length > 0) {
      arrPath.push(`filter=${(arrFilter || [])?.join(',').replaceAll('=', '-')}`)
    }
    if (routeData) {
      const filterMenuRolesQuery: string[] = routeData.menu?.filter((l: string) =>
        l.includes(`${currentMenu}-$query`)
      )
      for (const l of filterMenuRolesQuery) {
        const rolesQuery = l.replaceAll(`${currentMenu}-$query=`, '')
        if (!arrQuery.some((p) => p.includes('byuser=true')) && rolesQuery === 'get=all') {
          arrQuery.push(rolesQuery)
        }
      }
    }
    if (listLimitData.includes(query?.size) && query?.size !== 10) {
      arrPath.push(`size=${limitData}`)
    }
    !isModal && window.history.replaceState(null, '', `${location.pathname}?${arrPath.join('&')}`)
    try {
      let result: any
      if (staticData.length > 0) {
        result = getPaginationStaticData({
          data: staticData,
          size: Number(limitData),
          page: input?.currentPage,
          search: {
            value: input?.cari,
            options: staticDataOptions?.props,
          },
        })
      } else {
        if ((route.singleLoad && !(singleData.length > 0)) || !route.singleLoad) {
          result = await getData(
            arrQuery.join('&'),
            RouterQueryParams(route.url, queryUrl),
            route.options
          )
          route.singleLoad && setSingleData(result?.data || [])
        }
        if (route.singleLoad) {
          result = getPaginationStaticData({
            data: singleData.length > 0 ? singleData : result?.data || [],
            size: Number(limitData),
            page: input?.currentPage,
            search: {
              value: input?.cari,
              options: staticDataOptions?.props,
            },
          })
        }
      }
      if (result.status) {
        setData(result?.data)
        onSuccess({data: result?.data, page: input?.currentPage, size: limitData})
        updateInput({
          currentTotalData: result?.data?.length || 0,
          totalData: result?.totalItems || 0,
          totalPages: result?.totalPages || 1,
        })
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }
  const onReload = (init = false) => {
    const onPostQuery = (query: any) => {
      let arrQuery: string[] = []
      let baseQuery = {...query, ...query?.filter}
      delete baseQuery?.filter
      for (const l of Object.keys(baseQuery)) {
        if (baseQuery[l]) {
          arrQuery.push(`${l}=${baseQuery[l]}`)
        }
      }
      return arrQuery.join('&')
    }
    const onLoadData = ({result, query = {}}: onLoadDataProps) => {
      if (result?.status) {
        let _query = {...query}
        delete _query?.filter
        setData(result?.data)
        onSuccess({data: result?.data, page: input?.currentPage})
        updateInput({
          lastcari: _query?.search,
          query: _query,
          currentTotalData: result?.data?.length || 0,
          totalData: result?.totalItems || 0,
          totalPages: result?.totalPages || 1,
        })
        setError(false)
      } else {
        setError(true)
      }
      setLoading(false)
    }
    toggleTableModal({status: false}, modal)
    if (onLoad) {
      onLoad({
        initLoading: () => {
          setLoading(true)
          setError(false)
        },
        query: {
          get: {...queryBuilder(), filter: queryFilterBuilder()},
          post: onPostQuery,
        },
        load: onLoadData,
      })
    } else {
      loadData(init)
    }
    setRefresh(false)
  }
  useEffect(() => {
    if ((routeData?.menu?.length || 0) > 0) {
      if (!isModal) {
        const search = queryParameters.get('search')
        const page: number = parseInt(queryParameters.get('page') || '1')
        if (search) {
          updateInput({lastcari: search, cari: search})
        }
        if (page > 0) {
          updateInput({currentPage: page})
        }
      }
      if (route.url || refresh || reduxTableData?.refresh) {
        if (reduxTableData?.refresh) {
          reduxDispatch(postTable({...reduxTableData, refresh: false}))
        }
        onReload(true)
      } else {
        setLoading(false)
      }
    }
  }, [route.url, refresh, limitData, routeData, reduxTableData?.refresh])
  return (
    <div className='position-relative'>
      {!isModal && (
        <Modal
          id='modal-filter'
          title='Filter Data'
          isShow={modalFilter && popupFilter}
          onClose={() => {
            setModalFilter(false)
          }}
        >
          <FormInput
            input={filter}
            useToast={false}
            route={{
              data: {search: input?.cari || '', ...input?.filter},
              url: '#',
              type: 'put',
              isFeedback: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  const queryData = e.data || {}
                  for (const l of Object.keys(queryData)) {
                    if (queryData[l]?.length < 1 || !queryData[l]) {
                      delete queryData[l]
                    }
                  }
                  updateInput({cari: queryData?.search || '', currentPage: 1, filter: queryData})
                  onReload()
                }
                setModalFilter(false)
              },
            }}
            isModal
          />
        </Modal>
      )}
      {!isModal && (
        <Modal
          id='modal-edit'
          title='Ubah Data'
          isShow={modalUbah.status}
          onClose={() => {
            setModalUbah({type: '', status: false})
          }}
        >
          {modalUbah.type && (
            <FormInput
              input={
                (options?.checkbox || [])?.filter((l) => l.name === modalUbah.type)[0].input || []
              }
              route={{
                data: {},
                url: RouterQueryParams(route.url, queryUrl),
                onFeedbackSuccessMessage: 'Berhasil mengubah data',
                type: 'put',
                isFeedback: true,
                onFeedback: async (e: any) => {
                  if (e?.status) {
                    const idArray = reduxTableData?.checkbox?.data || []
                    const resultData = e?.data || {}
                    const json = {
                      id: idArray,
                      ...resultData,
                    }
                    const result = await putData(
                      json,
                      (options?.checkbox || [])?.filter((l) => l.name === modalUbah.type)[0].api ||
                        ''
                    )
                    reduxDispatch(clearTable())
                    onReload()
                  }
                  setModalUbah({type: '', status: false})
                },
              }}
              isModal
            />
          )}
        </Modal>
      )}
      <Modal
        id={'modal'}
        title={'Pilih Tindakan'}
        isShow={modal.data?.status}
        onClose={() => {
          toggleTableModal({status: false}, modal)
        }}
        className='mw-500px'
      >
        <div className='row gx-2 scroll-y mh-300px'>
          {link?.action
            ?.filter((fl) => !fl.hide)
            .map((lb, ib) => {
              const listDefaultIcon: {title: string; icon: keyof typeof Icons}[] = [
                {
                  title: 'Detail',
                  icon: 'RiInformationLine',
                },
                {
                  title: 'Ubah',
                  icon: 'RiDraftLine',
                },
                {
                  title: 'Lihat PDF',
                  icon: 'RiFileList3Line',
                },
                {
                  title: 'Hapus',
                  icon: 'RiDeleteBinLine',
                },
                {
                  title: 'Revisi Evalap',
                  icon: 'RiEdit2Line',
                },
              ]
              const checkIcon = listDefaultIcon.filter((l) => l.title === lb.title)
              const defaultIcon = checkIcon.length > 0 ? checkIcon[0].icon : null
              return (
                <Fragment key={ib}>
                  <ProtectComponent route={protect} id={lb.protect} disabled={lb.disabledProtect}>
                    {lb.type === 'feedback' || lb.protect === 'delete' ? (
                      <div className='col-12 col-md-6'>
                        <ButtonLoading
                          title={{
                            button: `${
                              (lb.bindId || '').length > 0
                                ? NullProof({
                                    isBool: true,
                                    input: modal.data.input,
                                    params: lb.bindId || '',
                                  })
                                  ? 'Buat '
                                  : 'Ubah '
                                : ''
                            }${lb.title}`,
                          }}
                          loading={lb.loading}
                          icon={
                            lb.protect !== 'delete'
                              ? lb.icon || defaultIcon || 'RiArrowRightSLine'
                              : 'RiDeleteBinLine'
                          }
                          props={{
                            className: `${`btn fs-6 d-flex justify-content-center align-items-center w-100 my-1 ${
                              lb.protect !== 'delete' ? 'btn-primary' : 'btn-danger'
                            }`}`,
                            onClick: async () => {
                              if (lb.protect === 'delete') {
                                const result = await PopupConfirm({validated: hasReconfirm})
                                if (result) {
                                  await deleteData(
                                    modal.data?.input[lb.id],
                                    lb.route ? lb.route : RouterQueryParams(route.url, queryUrl)
                                  )
                                  onReload()
                                }
                              }
                              onFeedback &&
                                onFeedback({
                                  id: lb.id,
                                  input: lb.input,
                                  protect: lb.protect,
                                  data: modal.data?.input,
                                })
                            },
                          }}
                        />
                      </div>
                    ) : lb.type === 'display' ? (
                      <>
                        {(lb.bindId || '').length > 0 &&
                          !NullProof({
                            isBool: true,
                            input: modal.data.input,
                            params: lb.bindId || '',
                          }) && (
                            <div
                              className='col-12 col-md-6'
                              onClick={() => {
                                onFeedback &&
                                  onFeedback({
                                    id: lb.id,
                                    input: lb.input,
                                    protect: lb.protect,
                                    data: modal.data?.input,
                                  })
                                onGo(RoutesParams(lb, modal.data.input || {}))
                              }}
                            >
                              <ButtonLoading
                                title={{
                                  button: `${lb.title}`,
                                }}
                                loading={lb.loading}
                                icon={lb.icon || defaultIcon || 'RiArrowRightSLine'}
                                props={{
                                  className:
                                    'btn fs-6 d-flex justify-content-center align-items-center btn-primary w-100 my-1',
                                }}
                              />
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        <div
                          className='col-12 col-md-6'
                          onClick={() => {
                            onFeedback &&
                              onFeedback({
                                id: lb.id,
                                input: lb.input,
                                protect: lb.protect,
                                data: modal.data?.input,
                              })
                            onGo(
                              `${protect ? `${protect}/` : ''}${RoutesParams(
                                lb,
                                modal.data.input || {}
                              )}`
                            )
                          }}
                        >
                          <ButtonLoading
                            title={{
                              button: `${
                                (lb.bindId || '').length > 0
                                  ? NullProof({
                                      isBool: true,
                                      input: modal.data.input,
                                      params: lb.bindId || '',
                                    })
                                    ? 'Buat '
                                    : 'Ubah '
                                  : ''
                              }${lb.title}`,
                            }}
                            loading={lb.loading}
                            icon={lb.icon || defaultIcon || 'RiArrowRightSLine'}
                            props={{
                              className:
                                'btn fs-6 d-flex justify-content-center align-items-center btn-primary w-100 my-1',
                            }}
                          />
                        </div>
                      </>
                    )}
                  </ProtectComponent>
                </Fragment>
              )
            })}
        </div>
      </Modal>
      {hasSearch && (
        <div className='row mx-0 mb-4 d-flex gap-2 align-items-center justify-content-center'>
          <div className='col-16 col-md px-0 order-3 order-md-1'>
            <div className='input-group input-group-solid'>
              <input
                type='text'
                className='form-control'
                placeholder='Cari...'
                value={input?.cari}
                onChange={(e) => {
                  updateInput({cari: e.target.value})
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (input?.cari === input?.lastcari) {
                    } else {
                      updateInput({currentPage: 1})
                      onReload()
                    }
                  }
                }}
              />
              <div
                className={`btn ${
                  (input?.cari === input?.lastcari && input?.lastcari?.length > 0) ||
                  (!input?.cari && input?.lastcari?.length > 0)
                    ? 'btn-danger'
                    : 'btn-primary'
                } d-flex align-items-center gap-1`}
                onClick={() => {
                  if (input?.cari === input?.lastcari && input?.lastcari?.length > 0) {
                    updateInput({cari: '', lastcari: ''})
                  }
                  onReload()
                }}
              >
                {(input?.cari === input?.lastcari && input?.lastcari?.length > 0) ||
                (!input?.cari && input?.lastcari?.length > 0) ? (
                  <>
                    <ReactIcon icon='RiCloseLine' props={{className: 'fs-3'}} />
                  </>
                ) : (
                  <>
                    <ReactIcon icon='RiSearchLine' props={{className: 'fs-3'}} />
                    Cari
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='col-16 col-md-auto px-0 d-flex justify-content-end gap-2 order-2 flex-wrap'>
            {link?.tambah && (
              <ProtectComponent id={link?.tambah?.protect || 'tambah'} route={protect}>
                {link?.tambah?.type === 'feedback' ? (
                  <button
                    className='btn btn-primary d-flex align-items-center gap-1'
                    onClick={() => {
                      onFeedback &&
                        onFeedback({id: link?.tambah?.id || 'tambah', input: link?.tambah?.input})
                    }}
                  >
                    <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                    {link?.tambah?.title || 'Tambah'}
                  </button>
                ) : (
                  <div
                    className='btn btn-primary d-flex align-items-center gap-1'
                    onClick={() => {
                      onGo(
                        `${
                          protect ? `${protect}${queryUrl['id'] ? `/${queryUrl['id']}` : ''}/` : ''
                        }${link?.tambah?.route || 'tambah'}`
                      )
                    }}
                  >
                    <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                    {link?.tambah?.title || 'Tambah'}
                  </div>
                )}
              </ProtectComponent>
            )}
            {/* <ProtectComponent
            id={`${
              (link?.export?.length || 0) > 0
                ? `,${link?.export?.map((l) => {
                    return l.protect.toString()
                  })}`
                : ''
            }`}
          > */}
            <DropdownUi
              iconOnly
              icon='RiSettings3Line'
              title='Pengaturan'
              onFeedback={(e: any) => {
                const id = e?.props?.id
                if (id === 'selectall') {
                  reduxDispatch(
                    postTable({
                      ...reduxTableData,
                      checkbox: {...reduxTableData?.checkbox, status: true},
                    })
                  )
                }
                onFeedback && onFeedback({id: `config.${id}`})
              }}
              submenu={[
                ...((options?.checkbox?.length || 0) > 0
                  ? [
                      {
                        id: 'selectall',
                        title: 'Pilih Data',
                        isFeedback: true,
                      },
                    ]
                  : []),
                ...dropdownExportDataBuilder(),
                ...dropdownSubmenuBuilder(link?.submenu),
              ]}
            />
            {/* </ProtectComponent> */}
            {filter.length > 0 && (
              <button
                className='btn btn-icon fs-4'
                onClick={() => {
                  setModalFilter(!modalFilter)
                }}
              >
                <ReactIcon icon='RiFilter2Line' props={{className: 'fs-4'}}></ReactIcon>
              </button>
            )}
          </div>
        </div>
      )}

      {reduxTableData?.checkbox?.status && (
        <div className='d-flex justify-content-between align-items-center gap-2 my-2'>
          <div style={{minWidth: '100px'}}>
            <div>{reduxTableData?.checkbox?.data?.length || 0} Data terpilih</div>
          </div>
          <div className='d-flex flex-wrap gap-2'>
            {/* <button
              className='btn btn-danger btn-sm'
              disabled={!(reduxTableData?.checkbox?.data?.length > 0)}
              onClick={() => {
              }}
            >
              Hapus
            </button> */}
            {options?.checkbox?.map((l, i) => (
              <Fragment key={i}>
                <ProtectComponent route={protect} id={l.protect} disabled={!l.protect}>
                  <ButtonLoading
                    loading={l.loading}
                    disabled={!(reduxTableData?.checkbox?.id?.length > 0)}
                    icon={l.icon || 'RiEdit2Line'}
                    title={{button: l.title}}
                    props={{
                      className: 'btn btn-primary btn-sm',
                      onClick: () => {
                        if (l.type === 'link') {
                          l?.url &&
                            navigate(
                              `${l.url.replaceAll(
                                ':id',
                                `${reduxTableData?.checkbox?.id?.join(',')}`
                              )}`
                            )
                        } else if (l.type === 'feedback') {
                          onFeedback &&
                            onFeedback({
                              id: l.name,
                              input: l.input,
                              protect: l.protect,
                              data: reduxTableData?.checkbox?.data,
                            })
                        } else {
                          setModalUbah({type: l.name, status: true})
                        }
                      },
                    }}
                  />
                </ProtectComponent>
              </Fragment>
            ))}
            <ButtonLoading
              title={{button: 'Batal'}}
              icon='RiCloseLine'
              props={{
                className: 'btn btn-danger btn-sm',
                onClick: () => {
                  reduxDispatch(clearTable())
                },
              }}
            />
          </div>
        </div>
      )}
      {((isModal && modalFilter) || (!popupFilter && modalFilter)) && (
        <Fragment key={filterKey}>
          <div className='container mb-2 fs-4 fw-bold'>Filter Data</div>
          <FormInput
            input={filter}
            useToast={false}
            isSubmitByBlur={popupFilter || isModal}
            route={{
              data: {search: input?.cari || '', ...input?.filter},
              url: '#',
              type: 'put',
              isFeedback: true,
              onFeedback: (e: any) => {
                if (e?.status) {
                  const queryData = e.data || {}
                  for (const l of Object.keys(queryData)) {
                    if (queryData[l]?.length < 1 || !queryData[l]) {
                      delete queryData[l]
                    }
                  }
                  updateInput({cari: queryData?.search || '', currentPage: 1, filter: queryData})
                  onReload()
                  setFilterKey(filterKey + 1)
                } else {
                  updateInput({cari: '', currentPage: 1, filter: {}})
                  onReload()
                  setFilterKey(filterKey + 1)
                }
              },
            }}
            options={{
              actionButton: {
                submit: {
                  hide: popupFilter || isModal,
                  title: 'Terapkan',
                },
                cancel: {
                  hide: true,
                  title: 'Reset',
                },
              },
            }}
            isModal
          />
          <div className='mb-6'>
            <Divider />
          </div>
        </Fragment>
      )}
      {input?.lastcari && !loading && (input?.totalData || 0) > 0 && (
        <div className='mb-2 fs-7'>
          Pencarian "<span className='fw-bold'>{input?.lastcari}</span>" ditemukan total{' '}
          {input?.totalData || 0} data
        </div>
      )}
      {!error ? (
        <>
          <>
            <div
              onScroll={(e) => {
                if (tableScrollRef.current) {
                  tableScrollRef.current.scrollLeft = e.currentTarget.scrollLeft
                }
              }}
              ref={scrollbarTopRef}
              className='overflow-auto'
            >
              <div ref={scrollbarTopContentRef} className='h-1px'></div>
            </div>
            <div
              onMouseDown={(e) => handleMouseDrag({e, type: 'down'})}
              onMouseMove={(e) => handleMouseDrag({e, type: 'drag'})}
              onMouseUp={(e) => handleMouseDrag({e, type: 'up'})}
              onMouseLeave={(e) => handleMouseDrag({e, type: 'up'})}
              onScroll={(e) => {
                if (scrollbarTopRef.current) {
                  scrollbarTopRef.current.scrollLeft = e.currentTarget.scrollLeft
                }
              }}
              style={{cursor: isMouseDrag ? 'grab' : 'unset'}}
              ref={tableScrollRef}
              className={`table-responsive user-select-none cursor-hand mh-500px ${
                modeResponsive !== 'table' ? 'd-lg-block' : ''
              }`}
            >
              <table
                ref={tableRef}
                className={`table ${
                  modeResponsive !== 'table'
                    ? modeResponsive === 'auto'
                      ? 'table-card-mobile'
                      : 'table-card-default'
                    : ''
                } table-hover table-rounded table-bordered border gy-7 gs-7 ${className || ''}`}
              >
                {(data.length > 0 || isDummy) && (
                  <TableHead>
                    <tr>
                      {reduxTableData?.checkbox?.status && (
                        <th className='w-50px'>
                          <div className='form-check d-flex justify-content-center'>
                            <input
                              type='checkbox'
                              checked={
                                NullProof({
                                  input: reduxTableData?.checkbox?.page,
                                  params: `page${reduxTableData?.page || 1}`,
                                  isMap: true,
                                })?.length === data.length || false
                              }
                              onChange={() => {}}
                              className='form-check-input form-check-input-lg cursor-pointer h-25px w-25px'
                              onClick={() => {
                                let _reduxTableId: any = [
                                  ...NullProof({
                                    input: reduxTableData,
                                    params: 'checkbox.id',
                                    isMap: true,
                                  }),
                                ]
                                let _reduxTableData: any = [
                                  ...NullProof({
                                    input: reduxTableData,
                                    params: 'checkbox.data',
                                    isMap: true,
                                  }),
                                ]
                                let _reduxTablePageId: any = [
                                  ...NullProof({
                                    input: reduxTableData,
                                    params: `checkbox.page.page${reduxTableData?.page || 1}`,
                                    isMap: true,
                                  }),
                                ]
                                let mode = 'select-all'
                                if (_reduxTablePageId?.length === data.length) {
                                  mode = 'deselect-all'
                                } else {
                                  mode = 'select-all'
                                }
                                for (const l of data) {
                                  let id = NullProof({input: l, params: idName})
                                  const index = _reduxTableId?.indexOf(id)
                                  const indexPage = _reduxTablePageId?.indexOf(id)
                                  if (mode === 'select-all') {
                                    if (!(index > -1)) {
                                      _reduxTableId.push(NullProof({input: l, params: idName}))
                                      _reduxTableData.push(l)
                                    }
                                    if (!(indexPage > -1)) {
                                      _reduxTablePageId.push(id)
                                    }
                                  } else {
                                    if (index > -1) {
                                      _reduxTableId.splice(index, 1)
                                      _reduxTableData.splice(index, 1)
                                    }
                                    if (indexPage > -1) {
                                      _reduxTablePageId.splice(indexPage, 1)
                                    }
                                  }
                                }
                                reduxDispatch(
                                  postTable({
                                    ...reduxTableData,
                                    checkbox: {
                                      ...reduxTableData?.checkbox,
                                      id: _reduxTableId,
                                      data: _reduxTableData,
                                      page: {
                                        ...reduxTableData?.checkbox?.page,
                                        [`page${reduxTableData?.page || 1}`]: _reduxTablePageId,
                                      },
                                    },
                                  })
                                )
                              }}
                            />
                          </div>
                        </th>
                      )}
                      {headerData?.map((l, i) => (
                        <Fragment key={i}>
                          <th className='min-w-150px' {...l.props}>
                            {l.title}
                          </th>
                        </Fragment>
                      ))}
                      {(link?.action?.length || 0) > 0 && (
                        <>
                          <ProtectComponent
                            id={`${
                              (link?.action?.length || 0) > 0
                                ? `,${link?.action?.map((l) => {
                                    return l.protect.toString()
                                  })}`
                                : ''
                            }`}
                            route={protect}
                          >
                            <th className='w-150px'>Tindakan</th>
                          </ProtectComponent>
                        </>
                      )}
                    </tr>
                  </TableHead>
                )}
                <TableBody>
                  {loading && (
                    <tr>
                      <td colSpan={(headerData?.length || 0) + 1}>
                        <div
                          className='position-absolute w-100 h-100 top-0 start-0 bg-white bg-opacity-50 d-flex justify-content-center align-items-center'
                          style={{
                            zIndex: 5,
                          }}
                        >
                          <div>
                            <div className='spinner-border' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {data.length > 0 || isDummy ? (
                    children
                  ) : (
                    <>
                      {data.length < 1 && (
                        <tr>
                          <td colSpan={(headerData?.length || 0) + 1}>
                            <div
                              className='p-4 d-flex flex-column justify-content-center align-items-center'
                              style={{
                                minHeight: '200px',
                              }}
                            >
                              <div className='fs-3 fw-bold'>
                                {loading ? 'Memuat data' : 'Belum ada data'}
                              </div>
                              <div>
                                {input?.lastcari
                                  ? 'Data yang anda cari tidak ditemukan'
                                  : loading
                                  ? 'Mohon tunggu'
                                  : 'Data masih kosong'}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </TableBody>
              </table>
            </div>
          </>

          {data.length > 0 && (
            <div className='mt-2'>
              <div className='d-flex gap-2 align-items-center justify-content-between'>
                <div>
                  <div className='fs-7'>Menampilkan {limitData} data per halaman</div>
                  <div className='fs-7'>
                    {`Ditemukan${NullProof({
                      input: input,
                      params: 'totalData',
                      type: 'currency',
                    })?.replaceAll('Rp', '')} data`}
                  </div>
                </div>
                <div className=''>
                  <SelectUi
                    title='Jumlah Data'
                    data={[
                      // {title: 'Semua', value: 'all'},
                      {title: '10', value: 10},
                      {title: '20', value: 20},
                      {title: '50', value: 50},
                      {title: '100', value: 100},
                      {title: '200', value: 200},
                      {title: '500', value: 500},
                    ]}
                    props={{
                      className: 'form-select-sm',
                      defaultValue: limitData,
                      onChange: (e) => {
                        const result = e.target.value
                        if (listLimitData.includes(result)) {
                          setLimitData(e.target.value)
                          updateInput({currentPage: 1})
                        }
                      },
                    }}
                  />
                </div>
              </div>
              <Pagination
                currentPage={input?.currentPage}
                maxButtons={5}
                totalPages={input?.totalPages}
                onPageChange={(e) => {
                  if (input?.currentPage !== e) {
                    updateInput({currentPage: e})
                    reduxDispatch(
                      postTable({
                        ...reduxTableData,
                        page: e,
                      })
                    )
                    onReload()
                  }
                }}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className='p-4 d-flex gap-2 flex-column justify-content-center align-items-center'
            style={{
              minHeight: '200px',
            }}
          >
            <div className='text-center'>
              <div className='fs-3 fw-bold'>Gagal memuat data</div>
              <div>Mohon cek koneksi anda</div>
            </div>
            <button type='button' className='btn btn-sm btn-primary' onClick={() => onReload(true)}>
              Muat Ulang
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export {TableInput, TableRow, TableColumn, toggleTableModal, TableAction}
