import {FC} from 'react'
import {formInputList} from './InputLayout'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {CardUi} from '../../../../../helper/AppUi'
import PageData, {InfoData} from './PageData'
import FormInput, {BaseInputOnChangeProps} from '../../../../../helper/FormInput'
import {BreadcrumbQueryParams, NullProof} from '../../../../../helper/AppFunction'
import {useParams} from 'react-router-dom'
import {groupNaskah} from '../../../../../helper/KemenagFunction'

const onChange = (e: BaseInputOnChangeProps) => {
  if (e.type === 'onChange') {
    const data = e._data.get
    const pesanDisposisi: string[] = data?.pesan_disposisi || []
    const pesanTambahan: string = data?.pesan_tambahan
    if (pesanDisposisi.length > 0 || pesanTambahan) {
      e.setError({field: 'pesan_disposisi', message: ''})
      e.setError({field: 'pesan_tambahan', message: ''})
    } else {
      e.setError({
        field: 'pesan_disposisi',
        message: 'Mohon untuk mengisi Pesan Disposisi / Pesan Tambahan',
      })
      e.setError({
        field: 'pesan_tambahan',
        message: 'Mohon untuk mengisi Pesan Disposisi / Pesan Tambahan',
      })
    }
    groupNaskah({e, groupId: 'grup_disposisi', valueId: 'tujuan_lainnya'})
  }
}

const TambahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

export {TambahWrapper}
