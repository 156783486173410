/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList} from './InputLayout'
import {CardUi} from '../../../../helper/AppUi'
import {TableColumn, TableInput, TableRow} from '../../../../helper/TableInputV2'
import {BreadcrumbQueryParams, NullProof, RouterQueryParams} from '../../../../helper/AppFunction'
// import FormInput from '../../../../helper/FormInputV2'
import FormInput, {
  BaseInputOnChangeProps,
  FormInputProps,
  onLoadProps as onLoadPropsForm,
} from '../../../../helper/FormInput'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import ReactIcon from '../../../../helper/ReactIcon'
import {ProtectComponent} from '../../../../helper/UserRolesValidation'

const MainWrapper: FC = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])

  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.semua.page.breadcrumb.data)}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={setModal}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          onFeedback={(e) => {
            if (e?.id === 'lihat') {
              console.log(e)
            }
          }}
          sidebar={
            <>
              <ProtectComponent id='tambah'>
                <button
                  type='button'
                  onClick={() => navigate('./tambah')}
                  className='btn btn-primary d-flex align-items-center gap-1'
                >
                  <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                  Tambah
                </button>
              </ProtectComponent>
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'jabatan'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    {NullProof({input: l, params: 'unit_kerja'})}
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    <div>NIP: {NullProof({input: l, params: 'penandatangan1.nip'})}</div>
                    <div>Nama: {NullProof({input: l, params: 'penandatangan1.nama'})}</div>
                  </TableColumn>
                  <TableColumn className='min-w-150px'>
                    <div>NIP: {NullProof({input: l, params: 'penandatangan2.nip'})}</div>
                    <div>Nama: {NullProof({input: l, params: 'penandatangan2.nama'})}</div>
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.tambah.page.breadcrumb.data)}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <Fragment key={key}>
        <CardUi title={PageData.detail?.page.breadcrumb.title}>
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <TableInputDetailColumn title={{value: 'Jabatan'}}>
              {NullProof({input: data, params: 'jabatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Unit Kerja'}}>
              {NullProof({input: data, params: 'unit_kerja'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatangan 1'}}>
              {NullProof({input: data, params: 'penandatangan1.nama'})} <br />
              {NullProof({input: data, params: 'penandatangan1.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Penandatangan 2'}}>
              {NullProof({input: data, params: 'penandatangan2.nama'})} <br />
              {NullProof({input: data, params: 'penandatangan2.nip'})}
            </TableInputDetailColumn>
          </TableInputDetail>
        </CardUi>
      </Fragment>
    </>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, DetailWrapper}
