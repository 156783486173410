import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  FontConfig,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
  PdfTableColumnBuilder,
  PdfTableTextBuilder,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {
  AlphabetIndex,
  ConvertCurrency,
  NullProof,
  ReactSrc,
  splitArray,
} from '../../../../helper/AppFunction'

const DummyData = [
  {
    no_agenda: '1',
    tanggal_agenda: '2024-06-04T18:41:59Z',
    perihal: 'Permohonan Audit',
    asal: 'Direktorat Jenderal Bimas Kristen',
    tujuan: 'Inspektur Jenderal Kemenag',
    no_surat: 'R-15/DJ.IV/PS.00/12/2022',
    pengirim: 'Direktur Jenderal',
    jenis: 'Surat Dinas',
  },
  {
    no_agenda: '2',
    tanggal_agenda: '2024-06-04T18:41:59Z',
    perihal: 'Permohonan Audit',
    asal: 'Direktorat Jenderal Bimas Kristen',
    tujuan: 'Inspektur Jenderal Kemenag',
    no_surat: 'R-15/DJ.IV/PS.00/12/2022',
    pengirim: 'Direktur Jenderal',
    jenis: 'Surat Dinas',
  },
  {
    no_agenda: '3',
    tanggal_agenda: '2024-06-04T18:41:59Z',
    perihal: 'Permohonan Audit',
    asal: 'Direktorat Jenderal Bimas Kristen',
    tujuan: 'Inspektur Jenderal Kemenag',
    no_surat: 'R-15/DJ.IV/PS.00/12/2022',
    pengirim: 'Direktur Jenderal',
    jenis: 'Surat Dinas',
  },
]

const DummyDataRange = [
  {
    tanggal_naskah: '2024-06-04T18:41:59Z',
    no_naskah: 'B-73/Stb.02/OT.01.3/01/2024',
    hal: 'Konsultasi terkait Perencanaan Pembangunan Hibah Tanah',
    asal: 'Hesti Sadtyadi\nKetua\nSTABN Raden Wijaya Wonogiri - Kementerian Agama',
    sifat: 'Biasa',
  },
  {
    tanggal_naskah: '2024-06-04T18:41:59Z',
    no_naskah: 'B-73/Stb.02/OT.01.3/01/2024',
    hal: 'Konsultasi terkait Perencanaan Pembangunan Hibah Tanah',
    asal: 'Hesti Sadtyadi\nKetua\nSTABN Raden Wijaya Wonogiri - Kementerian Agama',
    sifat: 'Biasa',
  },
  {
    tanggal_naskah: '2024-06-04T18:41:59Z',
    no_naskah: 'B-73/Stb.02/OT.01.3/01/2024',
    hal: 'Konsultasi terkait Perencanaan Pembangunan Hibah Tanah',
    asal: 'Hesti Sadtyadi\nKetua\nSTABN Raden Wijaya Wonogiri - Kementerian Agama',
    sifat: 'Biasa',
  },
]

const PdfLayoutTahunan: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const dataPerPage = 10
  const dataArray = splitArray(
    NullProof({input: data, params: 'data.data', isMap: true}),
    dataPerPage
  )
  let startDate = NullProof({
    input: data,
    params: 'data.start_date',
    type: 'date',
    option: {
      date: {format: 'yyyy'},
    },
  })
  let endDate = NullProof({
    input: data,
    params: 'data.end_date',
    type: 'date',
    option: {
      date: {format: 'yyyy'},
    },
  })
  let year =
    NullProof({input: data, params: 'data.year', isLabel: false}) || startDate === endDate
      ? startDate
      : `${startDate} - ${endDate}`
  return (
    <Document title='Dokumen'>
      {dataArray?.map((lpage: any, ipage: number) => (
        <Fragment key={ipage}>
          <Page size={{width: 1700}} wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
            <View style={{width: '100%', marginVertical: '20px'}}>
              {ipage === 0 && (
                <View
                  style={{
                    marginVertical: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    fontWeight: 'bold',
                    alignItems: 'center',
                  }}
                >
                  <Text>DAFTAR NASKAH DINAS MASUK TAHUN {year}</Text>
                  <Text>TIM KERJA TATA USAHA</Text>
                </View>
              )}
              <PdfTableColumnBuilder direction='column' wrap border>
                <PdfTableColumnBuilder
                  borderBottom
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 'semibold',
                    height: '70px',
                    backgroundColor: '#a6a6a6',
                  }}
                >
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <PdfTableTextBuilder>No.</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <PdfTableTextBuilder>No Agenda</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '150px'}}>
                    <PdfTableTextBuilder>Tgl Agenda</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <PdfTableTextBuilder>Perihal</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <PdfTableTextBuilder>Asal Surat</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <PdfTableTextBuilder>Tujuan</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
                    <PdfTableTextBuilder>Nomor Surat</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <PdfTableTextBuilder>Pengirim</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center style={{width: '150px'}}>
                    <PdfTableTextBuilder>Jenis</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
                {lpage?.map((l: any, i: number) => (
                  <Fragment key={i}>
                    <PdfTableColumnBuilder wrap borderBottom={i !== lpage?.length - 1}>
                      <PdfTableColumnBuilder borderRight style={{width: '100px'}}>
                        <PdfTableTextBuilder>{dataPerPage * ipage + (i + 1)}</PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '150px'}}>
                        <PdfTableTextBuilder>
                          {NullProof({input: l, params: 'no_agenda'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '150px'}}>
                        <PdfTableTextBuilder>
                          {NullProof({input: l, params: 'tanggal_naskah', type: 'date'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '300px'}}>
                        <PdfTableTextBuilder wrap align='left'>
                          {NullProof({input: l, params: 'hal'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '200px'}}>
                        <PdfTableTextBuilder wrap align='left'>
                          {NullProof({input: l, params: 'instansi_pengirim'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '200px'}}>
                        <PdfTableTextBuilder wrap align='left'>
                          {NullProof({input: l, params: 'tujuan'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '250px'}}>
                        <PdfTableTextBuilder align='left'>
                          {NullProof({input: l, params: 'nomor_naskah'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder borderRight style={{width: '200px'}}>
                        <PdfTableTextBuilder wrap align='left'>
                          {NullProof({input: l, params: 'nama_pengirim'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder style={{width: '150px'}}>
                        <PdfTableTextBuilder wrap align='left'>
                          {NullProof({input: l, params: 'JenisNaskah.text'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </Fragment>
                ))}
              </PdfTableColumnBuilder>
            </View>
          </Page>
        </Fragment>
      ))}
    </Document>
  )
}

const PdfLayoutRange: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  data.data.lokasi = 'Jakarta'
  const dataPerPage = 10
  const dataArray = splitArray(
    NullProof({input: data, params: 'data.data', isMap: true}),
    dataPerPage
  )
  return (
    <Document title='Dokumen'>
      {dataArray?.map((lpage: any, ipage: number) => (
        <Fragment key={ipage}>
          <Page size={{width: 1250}} wrap={false} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
            <View style={{width: '100%', marginVertical: '20px'}}>
              {ipage === 0 && (
                <View
                  style={{
                    marginVertical: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    fontWeight: 'bold',
                    alignItems: 'flex-start',
                  }}
                >
                  <Text>
                    Laporan Naskah Masuk (
                    {NullProof({input: data, params: 'data.start_date', type: 'date'})} -{' '}
                    {NullProof({input: data, params: 'data.end_date', type: 'date'})})
                  </Text>
                </View>
              )}
              <PdfTableColumnBuilder direction='column' wrap border>
                <PdfTableColumnBuilder
                  borderBottom
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 'semibold',
                    height: '70px',
                    backgroundColor: '#a6a6a6',
                  }}
                >
                  <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                    <PdfTableTextBuilder>No.</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <PdfTableTextBuilder>Tanggal Naskah</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <PdfTableTextBuilder>Nomor Naskah</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                    <PdfTableTextBuilder>Hal</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
                    <PdfTableTextBuilder>Asal Naskah</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                    <PdfTableTextBuilder>Sifat Naskah</PdfTableTextBuilder>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
                {lpage?.map((l: any, i: number) => (
                  <Fragment key={i}>
                    <PdfTableColumnBuilder wrap borderBottom={i !== l?.length - 1}>
                      <PdfTableColumnBuilder center borderRight style={{width: '100px'}}>
                        <PdfTableTextBuilder>{dataPerPage * ipage + (i + 1)}</PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight style={{width: '200px'}}>
                        <PdfTableTextBuilder>
                          {NullProof({input: l, params: 'tanggal_naskah', type: 'date'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                        <PdfTableTextBuilder>
                          {NullProof({input: l, params: 'nomor_naskah'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight style={{width: '300px'}}>
                        <PdfTableTextBuilder wrap>
                          {NullProof({input: l, params: 'hal'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight style={{width: '250px'}}>
                        <PdfTableTextBuilder wrap>
                          {NullProof({input: l, params: 'nama_pengirim'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center style={{width: '200px'}}>
                        <PdfTableTextBuilder wrap>
                          {NullProof({input: l, params: 'SifatNaskah.text'})}
                        </PdfTableTextBuilder>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </Fragment>
                ))}
              </PdfTableColumnBuilder>
            </View>
          </Page>
        </Fragment>
      ))}
    </Document>
  )
}

export {PdfLayoutTahunan, PdfLayoutRange}
