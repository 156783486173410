import * as Yup from 'yup'
import {FormInputProps} from '../FormInput'
import inputLayoutBuilder from '../template/FormInputTemplate'
import {getListYear} from '../DateFns'

const filterFormInputList: FormInputProps[] = [
  ...inputLayoutBuilder({
    input: [{template: 'search'}, {template: 'status'}],
  }),
]

const basicInput: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    className: 'col-12',
    options: {
      label: {
        name: 'Basic Input',
        labelType: 'both',
      },
    },
  },
  {
    name: 'name',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    name: 'description',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Deskripsi',
          placeholder: 'Deskripsi',
        },
      },
    },
  },
  {
    name: 'kategori',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [
      {
        title: 'Makanan',
        value: 'makanan',
      },
      {
        title: 'Minuman',
        value: 'minuman',
      },
      {
        title: 'Lainnya',
        value: 'lainnya',
      },
    ],
    options: {
      input: {
        props: {
          title: 'Kategori',
          placeholder: 'Kategori',
        },
      },
    },
  },
  {
    name: 'year',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: getListYear(),
    options: {
      input: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    name: 'price',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.number().min(1, 'Minimal 1').required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga',
          placeholder: 'Harga',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'days',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Hari',
          placeholder: 'Hari',
        },
      },
    },
  },
  {
    name: 'pilihan',
    type: 'multicheckbox',
    className: 'col-12',
    validator: Yup.array().required('Mohon untuk diisi.'),
    listData: [
      {
        title: 'Makanan',
        value: 'makanan',
      },
      {
        title: 'Minuman',
        value: 'minuman',
      },
      {
        title: 'Lainnya',
        value: 'lainnya',
      },
    ],
    options: {
      input: {
        props: {
          title: 'Pilihan',
          placeholder: 'Pilihan',
        },
      },
      multicheckbox: {
        rowType: 'row',
        colClassName: 'col-12 col-md-6',
        props: {
          title: 'Pilihan',
          placeholder: 'Pilihan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'aktif',
    type: 'input',
    value: '',
    validator: Yup.boolean(),
    options: {
      input: {
        props: {
          type: 'checkbox',
          title: 'Aktif',
          placeholder: 'Aktif',
        },
      },
    },
  },
  {
    name: 'single_lampiran',
    type: 'input',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Lampiran',
          placeholder: 'Lampiran',
          type: 'file',
        },
      },
    },
  },
  {
    name: 'file_lampiran',
    type: 'input',
    className: 'col-12',
    validator: Yup.array(),
    options: {
      input: {
        props: {
          title: 'Multi Lampiran',
          placeholder: 'Multi Lampiran',
          type: 'file',
          multiple: true,
        },
      },
    },
  },
]

const advancedInput: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    className: 'col-12',
    options: {
      label: {
        name: 'Linked Input',
        labelType: 'both',
      },
    },
  },
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Tujuan Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Tujuan Provinsi',
          placeholder: 'Tujuan Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama,kode_satker',
        result: '$nama - $kode_satker',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Tujuan Kota is required'),
    options: {
      input: {
        props: {
          title: 'Tujuan Kota',
          placeholder: 'Tujuan Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'unit',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit is required'),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker2',
        query: 'provinsi=$provinsi&kota=$kota',
        params: 'singkatan,nama_lengkap',
        result: '$singkatan - $nama_lengkap',
        id: 'id',
      },
      watchInput: {
        id: 'provinsi,kota',
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Jangka Waktu',
        labelType: 'both',
      },
    },
  },
  {
    name: 'start_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'end_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'total_date',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    disabled: true,
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Total Hari',
          placeholder: 'Total Hari',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'label',
    type: 'label',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Hitungan',
        labelType: 'both',
      },
    },
  },
  {
    name: 'jumlah_item',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jumlah',
          placeholder: 'Jumlah',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'harga_item',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Harga',
          placeholder: 'Harga',
          type: 'number',
        },
      },
    },
  },
  {
    name: 'totalharga_item',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    disabled: true,
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Total Harga',
          placeholder: 'Total Harga',
          type: 'number',
        },
      },
    },
  },
]

const datalistInput: FormInputProps[] = [
  {
    name: 'link_member',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Link Member',
          placeholder: 'Link Member',
        },
      },
      datalist: {
        api: '/account/users',
        id: 'id',
        params: 'fullname,email',
        result: (
          <div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$fullname</span>
            </div>
            <div>
              <span className='fw-bold'>Email: </span>
              <span>$email</span>
            </div>
          </div>
        ),
      },
    },
  },
  {
    name: 'link_member2',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Link Member',
          placeholder: 'Link Member',
        },
      },
      datalist: {
        api: '/account/users',
        id: 'id',
        params: 'fullname,email',
        type: 'table',
        result: (
          <div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$fullname</span>
            </div>
            <div>
              <span className='fw-bold'>Email: </span>
              <span>$email</span>
            </div>
          </div>
        ),
        table: {
          protect: '/sample/sample',
          data: [
            {
              id: 'fullname',
              type: 'text',
            },
            {
              id: 'email',
              type: 'text',
            },
          ],
          props: {
            route: {
              url: '/acccount/users',
            },
            headerData: [
              {
                title: 'No.',
                props: {className: 'min-w-50px'},
              },
              {
                title: 'Nama',
              },
              {
                title: 'Email',
              },
            ],
          },
        },
      },
    },
  },
]

const componentlistInput: FormInputProps[] = [
  {
    name: 'name',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    name: 'email',
    type: 'input',
    className: 'col-12 col-md-6',
    validator: Yup.string().required('Mohon untuk diisi.').email('Format harus Email.'),
    options: {
      input: {
        props: {
          title: 'Email',
          placeholder: 'Email',
        },
      },
    },
  },
  ...datalistInput,
]

const componentInput: FormInputProps[] = [
  {
    name: 'components',
    type: 'component',
    className: 'col-12',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Anggota',
          placeholder: 'Anggota',
        },
      },
    },
    component: componentlistInput,
  },
]

const customApiWithOnLoadInput: FormInputProps[] = [
  {
    name: 'custom_api_onload',
    type: 'datalist',
    className: 'col-12',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Custom API On Load',
          placeholder: 'Custom API On Load',
        },
      },
      datalist: {
        useOnLoad: true,
        api: '/account/users',
        id: 'id',
        params: 'fullname,email',
        result: (
          <div>
            <div>
              <span className='fw-bold'>Nama: </span>
              <span>$fullname</span>
            </div>
            <div>
              <span className='fw-bold'>Email: </span>
              <span>$email</span>
            </div>
          </div>
        ),
      },
    },
  },
  {
    name: 'custom_api_components',
    type: 'component',
    className: 'col-12',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Custom API Component',
          placeholder: 'Custom API Component',
        },
      },
    },
    component: [
      {
        name: 'custom_api_onload',
        type: 'datalist',
        className: 'col-12',
        validator: Yup.number().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Custom API On Load',
              placeholder: 'Custom API On Load',
            },
          },
          datalist: {
            useOnLoad: true,
            api: '/account/users',
            id: 'id',
            params: 'fullname,email',
            result: (
              <div>
                <div>
                  <span className='fw-bold'>Nama: </span>
                  <span>$fullname</span>
                </div>
                <div>
                  <span className='fw-bold'>Email: </span>
                  <span>$email</span>
                </div>
              </div>
            ),
          },
        },
      },
    ],
  },
]

const testingInputList: FormInputProps[] = [
  {
    name: 'usulan_tim',
    type: 'component',
    className: 'col-12',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Usulan Tim',
          placeholder: 'Usulan Tim',
        },
      },
    },
    component: [
      {
        name: 'nama_tim',
        type: 'input',
        className: 'col-12',
        validator: Yup.string(),
        options: {
          input: {
            props: {
              title: 'Nama',
              placeholder: 'Nama',
            },
          },
        },
      },
      {
        name: 'anggota',
        type: 'component',
        className: 'col-12',
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Anggota',
              placeholder: 'Anggota',
            },
          },
        },
        component: [
          {
            name: 'nama',
            type: 'input',
            className: 'col-12',
            validator: Yup.string(),
            options: {
              input: {
                props: {
                  title: 'Nama',
                  placeholder: 'Nama',
                },
              },
            },
          },
          {
            name: 'tugas',
            type: 'component',
            className: 'col-12',
            validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Tugas',
                  placeholder: 'Tugas',
                },
              },
            },
            component: [
              {
                name: 'nama',
                type: 'input',
                className: 'col-12',
                validator: Yup.string(),
                options: {
                  input: {
                    props: {
                      title: 'Nama',
                      placeholder: 'Nama',
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
]

const formInputList: FormInputProps[] = [
  ...basicInput,
  ...advancedInput,
  ...componentInput,
  ...datalistInput,
  ...customApiWithOnLoadInput,
  // ...testingInputList,
]

export {formInputList, filterFormInputList}
