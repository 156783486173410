/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import PageData, {TableInputData} from './PageData'
import {CardUi} from '../../../../../helper/AppUi'
import {
  ConvertCurrency,
  NullProof,
  getStatusColor,
  numberlistPagination,
} from '../../../../../helper/AppFunction'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../../helper/TableInput'
import {formatDate} from '../../../../../helper/DateFns'
import {useAuth} from '../../../../../modules/auth'

const isSuperAdmin = (data: any) => {
  return data?.roles?.id === 1
}

const cekRolesName = (dataUser: any) => {
  const listRolesName = ['Verifikasi dan Pelaporan']
  const rolesName = dataUser?.roles?.name
  const kondisi1 =
    rolesName && listRolesName.some((e) => e.toLowerCase().includes(rolesName.toLowerCase()))
  return kondisi1
}
const cekSemuaId = (dataUser: any, data: any) => {
  const idPegawai = dataUser?.pegawai?.id
  const kondisi1 = idPegawai === data?.pegawai_id
  const kondisi2 = idPegawai === data?.ketua?.id
  const kondisi3 = idPegawai === data?.evalap?.id
  const kondisi4 = idPegawai === data?.ppk?.id
  const kondisi5 = idPegawai === data?.bendahara?.pegawai?.id
  return idPegawai && (kondisi1 || kondisi2 || kondisi3 || kondisi4 || kondisi5)
}

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const {auth} = useAuth()
  const dataUser: any = auth?.data
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const [actionMenu, setActionMenu] = useState([...(TableInputData.link?.action || [])])
  useEffect(() => {
    let defaultValue = [...actionMenu]
    if (!(cekRolesName(dataUser) || cekSemuaId(dataUser, modal?.input) || isSuperAdmin(dataUser))) {
      defaultValue[2].hide = true
    } else {
      defaultValue[2].hide = false
    }
    setActionMenu(defaultValue)
  }, [dataUser, modal?.input])
  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          link={{...TableInputData.link, action: actionMenu}}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Nama Kegiatan' props={{style: {minWidth: '300px'}}}>
                    {NullProof({
                      input: l,
                      params: 'surat_tugas.nama_kegiatan',
                      option: {text: {limit: 200}},
                    })}
                  </TableColumn>
                  <TableColumn label='Surat Tugas' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'surat_tugas.nomor_surat'})}
                  </TableColumn>
                  <TableColumn label='Pegawai' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '200px'}}}>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_ketua', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Ketua Tim</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_evalap', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Evalap</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_ppk', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>PPK</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_bendahara', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Bendahara</div>
                    </div>
                    <div className='d-flex justify-content-center gap-2 mb-2'>
                      <div
                        className='rounded-circle'
                        style={{
                          width: '20px',
                          height: '20px',
                          minWidth: '20px',
                          backgroundColor: getStatusColor(
                            NullProof({input: l, params: 'status_vf', isLabel: false})
                          ),
                        }}
                      ></div>
                      <div className='w-100'>Status Transfer</div>
                    </div>
                  </TableColumn>
                  <TableColumn label='Jumlah Dibayarkan' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(
                      NullProof({input: l, params: 'jumlah_dibayarkan', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableColumn label='Dibuat' props={{style: {minWidth: '200px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'createdAt'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

export {MainWrapper}
