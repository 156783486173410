import {DataRoutesProps} from '../../../UserRolesValidation'
import {MainRoutes as PermohonanCuti} from './DaftarStatusPermohonanCuti'
import {MainRoutes as SisaCuti} from './SisaCuti'
import {MainRoutes as JenisCuti} from './JenisCuti'

const MainRoutes: DataRoutesProps[] = [
  {
    title: 'Permohonan Cuti',
    icon: 'element-10',
    route: 'permohonan-cuti',
    elements: <></>,
    subroute: PermohonanCuti,
  },
  {
    isPage: true,
    title: 'Sisa Cuti',
    icon: 'element-10',
    route: 'sisa-cuti',
    elements: <></>,
    subroute: SisaCuti,
  },
  {
    isPage: true,
    title: 'Jenis Cuti',
    icon: 'element-10',
    route: 'jenis-cuti',
    elements: <></>,
    subroute: JenisCuti,
  },
]

export {MainRoutes}
