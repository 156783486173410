import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'alasan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Alasan',
          placeholder: 'Alasan',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'alamat_cuti',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Alamat Selama Cuti',
          placeholder: 'Alamat Selama Cuti',
          type: 'textarea',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'tanggal',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tanggal Cuti',
          placeholder: 'Tanggal Cuti',
        },
      },
      component: {
        resultType: {
          type: 'date',
        },
        // useAccordion: true,
        useAccordionTo: 'component',
      },
    },
    component: [
      {
        className: 'col-5',
        name: 'tanggal_mulai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal wajib diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Mulai',
              placeholder: 'Tanggal Mulai',
              type: 'date',
            },
          },
        },
      },
      {
        className: 'col-5',
        name: 'tanggal_selesai',
        type: 'input',
        value: '',
        validator: Yup.string().required('Tanggal wajib diisi.'),
        options: {
          input: {
            props: {
              title: 'Tanggal Selesai',
              placeholder: 'Tanggal Selesai',
              type: 'date',
            },
          },
        },
      },
      {
        className: 'col-2',
        name: 'tanggal_total',
        type: 'input',
        value: '',
        validator: Yup.number(),
        options: {
          input: {
            isDate: true,
            props: {
              title: 'Total Hari',
              placeholder: 'Total Hari',
              type: 'number',
            },
          },
          currency: {
            props: {
              readOnly: true,
            },
          },
        },
      },
    ],
  },
  {
    className: 'col-12 col-md-6',
    name: 'jenis_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis',
          placeholder: 'Jenis',
        },
      },
      datalist: {
        api: '/kepegawaian/jenis-cuti',
        query: 'orderby=asc',
        params: 'nama,jenis',
        result: `$jenis`,
        id: 'id',
        type: 'table',
        table: {
          data: [{id: 'jenis', type: 'text'}],
          props: {
            route: {
              url: '/kepegawaian/jenis-cuti',
            },
            header: [{title: 'No.', props: {className: 'max-w-50px'}}, {title: 'Jenis'}],
          },
        },
      },
    },
  },
]

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Perubahan',
        value: 'Perubahan',
      },
      {
        title: 'Ditangguhkan',
        value: 'Ditangguhkan',
      },
      {
        title: 'Tidak Disetujui',
        value: 'Tidak Disetujui',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

const formInputPass: FormInputProps[] = [
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

export {formInputList, formInputStatus, formInputPass}
