import {FC, Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {InfoData, PageData, TableInputData, TableInputDetailData} from './PageData'
import {
  BreadcrumbQueryParams,
  ConvertCurrency,
  NullProof,
  RouterQueryParams,
  numberlistPagination,
} from '../../../../../helper/AppFunction'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {CardUi} from '../../../../../helper/AppUi'
import {TableAction, TableInput, TableRow, TableColumn} from '../../../../../helper/TableInput'
import FormInput from '../../../../../helper/FormInput'
import {formInputImportList, formInputList, formUpdateList} from './InputLayout'
import {TableInputDetail, TableInputDetailColumn} from '../../../../../helper/TableInputDetail'
import {formatDate} from '../../../../../helper/DateFns'
import {PdfPageViewer} from '../../../../../helper/PdfPageViewer'
import {PdfLayoutSemua} from './PdfLayout'
import inputLayoutBuilder from '../../../../../helper/template/FormInputTemplate'
import ExcelLayout from './ExcelLayout'
import {filterInput, onChange} from '../MainFunction'
import {DefaultPdfLayoutData, directDownloadPdf} from '../../../../../helper/ReactPdf'

const MainWrapper: FC = () => {
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])
  const modalConfig = {
    data: modal,
    post: setModal,
  }
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  return (
    <>
      <PageTitle breadcrumbs={PageData.semua.page.breadcrumb.data}>
        {PageData.semua.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.semua.page.title}>
        <TableInput
          {...TableInputData}
          modal={modalConfig}
          onSuccess={(val: any) => setData(val)}
          onFeedback={async (e) => {
            if (e?.id === 'download-pdf') {
              setIsDownloading(true)
              await directDownloadPdf({
                document: PdfLayoutSemua,
                data: e?.data,
                filename: PageData.semua.page.breadcrumb.title,
              })
            } else if (e?.id === 'download-excel') {
              setIsDownloading(true)
              ExcelLayout({
                ...DefaultPdfLayoutData,
                data: e?.data,
                _filename: PageData.semua.page.breadcrumb.title,
              })()
            }
            setIsDownloading(false)
          }}
          filter={filterInput}
          options={{
            checkbox: [
              {
                input: inputLayoutBuilder({input: [{template: 'status_simple', name: 'status'}]}),
                title: 'Ubah Status',
                name: 'ubah-status',
                icon: 'RiEdit2Line',
                type: 'put',
                api: '/keuangan/transfer-ubahstatus-all',
              },
              {
                title: 'Download PDF',
                name: 'download-pdf',
                icon: 'RiFilePdfLine',
                type: 'feedback',
                loading: isDownloading,
                url: '',
                api: '',
              },
              {
                title: 'Download Excel',
                name: 'download-excel',
                icon: 'RiFileExcelLine',
                type: 'feedback',
                loading: isDownloading,
                url: '',
                api: '',
              },
            ],
          }}
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            const tableDataBuilder = {
              modal: modalConfig,
              input: {data: l, index: i},
              action: TableInputData.link?.action || [],
            }
            return (
              <Fragment key={i}>
                <TableRow {...tableDataBuilder}>
                  <TableColumn label='No.' props={{style: {width: '50px'}}}>
                    {numberlistPagination({n: i, p: data?.page, t: data?.size})}
                  </TableColumn>
                  <TableColumn label='Pelaku Perjadin' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'pelaku_perjadin'})}
                  </TableColumn>
                  <TableColumn label='No. ST' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'no_surat_tugas'})}
                  </TableColumn>
                  <TableColumn label='No. SPD' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'spd'})}
                  </TableColumn>
                  <TableColumn label='Tanggal Transfer' props={{style: {minWidth: '150px'}}}>
                    {formatDate({
                      date: NullProof({input: l, params: 'tanggal_transfer'}),
                      dateFormat: 'dd MMMM yyyy',
                    })}
                  </TableColumn>
                  <TableColumn label='No Rek. Debet' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'no_rek_debet'})}
                  </TableColumn>
                  <TableColumn label='Nama Rek. Debet' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nama_rek_debet'})}
                  </TableColumn>
                  <TableColumn label='No Rek. Tujuan' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'no_rek_tujuan'})}
                  </TableColumn>
                  <TableColumn label='Nama Rek. Tujuan' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'nama_rek_tujuan'})}
                  </TableColumn>
                  <TableColumn label='UM/VF' props={{style: {minWidth: '150px'}}}>
                    {NullProof({input: l, params: 'um_uf'})}
                  </TableColumn>
                  <TableColumn label='Jumlah' props={{style: {minWidth: '200px'}}}>
                    {ConvertCurrency(NullProof({input: l, params: 'jumlah', isLabel: false}) || 0)}
                  </TableColumn>
                  <TableColumn
                    label='Transport Dibayar Kantor'
                    props={{style: {minWidth: '200px'}}}
                  >
                    {ConvertCurrency(
                      NullProof({input: l, params: 'transport_dibayar_kantor', isLabel: false}) || 0
                    )}
                  </TableColumn>
                  <TableColumn label='Status' props={{style: {minWidth: '200px'}}}>
                    {NullProof({input: l, params: 'status'})}
                  </TableColumn>
                  <TableAction {...tableDataBuilder} />
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </>
  )
}

const TambahWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={PageData.tambah.page.breadcrumb.data}>
        {PageData.tambah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const UbahWrapper: FC = () => {
  const query = useParams()
  return (
    <>
      <PageTitle breadcrumbs={BreadcrumbQueryParams(PageData.ubah.page.breadcrumb.data)}>
        {PageData.ubah.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <FormInput
          input={formUpdateList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </>
  )
}

const DetailWrapper: FC = () => {
  const [data, setData] = useState<any>({})
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>
        {PageData.detail?.page.breadcrumb.title}
      </PageTitle>
      <CardUi title={PageData.detail?.page.breadcrumb.title}>
        <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
          <TableInputDetailColumn title={{value: 'Tanggal'}}>
            {formatDate({
              date: NullProof({input: data, params: 'tanggal_transfer'}),
              dateFormat: 'dd MMMM yyyy',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            hideDivider
            title={{value: 'Informasi Debet', props: {className: 'fs-5'}}}
          >
            <TableInputDetailColumn title={{value: 'Nomor Rekening Debet'}}>
              {NullProof({input: data, params: 'no_rek_debet'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Rekening Debet'}}>
              {NullProof({input: data, params: 'nama_rek_debet'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            isResponsive='desktop'
            hideDivider
            title={{value: 'Informasi Tujuan', props: {className: 'fs-5'}}}
          >
            <TableInputDetailColumn title={{value: 'Nomor Rekening Tujuan'}}>
              {NullProof({input: data, params: 'no_rek_tujuan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Nama Rekening Tujuan'}}>
              {NullProof({input: data, params: 'nama_rek_tujuan'})}
            </TableInputDetailColumn>
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Jumlah'}}>
            {isNaN(NullProof({input: data, params: 'jumlah'}))
              ? ConvertCurrency(0)
              : ConvertCurrency(NullProof({input: data, params: 'jumlah'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Uang Harian'}}>
            {isNaN(NullProof({input: data, params: 'uang_harian'}))
              ? ConvertCurrency(0)
              : ConvertCurrency(NullProof({input: data, params: 'uang_harian'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Hotel'}}>
            {isNaN(NullProof({input: data, params: 'hotel'}))
              ? ConvertCurrency(0)
              : ConvertCurrency(NullProof({input: data, params: 'hotel'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Transport'}}>
            {isNaN(NullProof({input: data, params: 'transport'}))
              ? ConvertCurrency(0)
              : ConvertCurrency(NullProof({input: data, params: 'transport'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Transport Dibayar Kantor'}}>
            {isNaN(NullProof({input: data, params: 'transport_dibayar_kantor'}))
              ? ConvertCurrency(0)
              : ConvertCurrency(NullProof({input: data, params: 'transport_dibayar_kantor'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Notifikasi'}}>
            {`${NullProof({
              input: data,
              params: 'notifikasi',
            })}`}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Bank'}}>
            {NullProof({input: data, params: 'bank'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Pelaku Perjadin'}}>
            {NullProof({input: data, params: 'pelaku_perjadin'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPD'}}>
            {NullProof({input: data, params: 'spd'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPM'}}>
            {NullProof({input: data, params: 'spm'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPMTGL'}}>
            {`${NullProof({input: data, params: 'spm'})}${formatDate({
              date: NullProof({input: data, params: 'tanggal_transfer'}),
              dateFormat: 'yyyyMMdd',
            })}`}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'UM / VF'}}>
            {NullProof({input: data, params: 'um_uf'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Nilai'}}>
            {ConvertCurrency(NullProof({input: data, params: 'nilai'}) || 0)}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kontrol'}}>
            {`${NullProof({input: data, params: 'nama_rek_tujuan'})}${NullProof({
              input: data,
              params: 'spd',
            })}${NullProof({input: data, params: 'um_uf'})}`}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'Kontrol MAK'}}>
            {NullProof({input: data, params: 'kontrol_mak'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn title={{value: 'SPDT'}}>
            {`${NullProof({input: data, params: 'nama_rek_tujuan'})}${NullProof({
              input: data,
              params: 'spd',
            })?.substring(0, 4)}`}
          </TableInputDetailColumn>
        </TableInputDetail>
      </CardUi>
    </>
  )
}

const PdfWrapper: FC = () => {
  const query = useParams()
  let InitPdfLayout = PdfLayoutSemua
  return (
    <>
      <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}`, query)}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={InitPdfLayout}
        ExcelLayout={ExcelLayout}
        options={{
          isRedux: query?.id === 'pilihan',
        }}
      />
    </>
  )
}

const ImportWrapper: FC = () => {
  const titlePage = 'Import Data Excel'
  return (
    <>
      <PageTitle breadcrumbs={PageData.detail?.page.breadcrumb.data}>{titlePage}</PageTitle>
      <CardUi title={titlePage}>
        <FormInput
          input={formInputImportList}
          route={{
            url: InfoData.api + '-import',
            type: 'post',
            isFeedback: false,
            onFeedback: (e: any) => {
              console.log(e)
            },
          }}
        />
      </CardUi>
    </>
  )
}

export {MainWrapper, TambahWrapper, UbahWrapper, DetailWrapper, PdfWrapper, ImportWrapper}
