import {FC, Fragment} from 'react'
import {Document, Page, View, Text, Image} from '@react-pdf/renderer'
import {
  FontConfig,
  PdfHeaderApp,
  PdfLayoutDataProps,
  PdfStyle,
  PdfStylePallete,
  PdfTabCol,
  PdfTableColumnBuilder,
} from '../../../../helper/ReactPdf'
import {formatDate} from '../../../../helper/DateFns'
import {
  AlphabetIndex,
  ConvertCurrency,
  getPaginationStaticData,
  NullProof,
  ReactSrc,
  splitArray,
} from '../../../../helper/AppFunction'
import {eachDayOfInterval, format} from 'date-fns'
import {getSisaCuti} from './MainFunction'

const PoppinsConfig: FontConfig = {
  name: 'Poppins',
  id: 'poppins',
  variant: 'latin',
  weight: [400, 500, 700],
  style: ['normal', 'italic'],
}

const API_URL = process.env.REACT_APP_API_URL

const PdfLayout: FC<{data: PdfLayoutDataProps}> = ({data}) => {
  const size = 20
  const dataArray = splitArray(NullProof({input: data, params: 'data.data', isMap: true}), size)
  const colorOrange = '#f8cbad'
  const colorGreen = '#c6e0b4'
  const colorRed = '#f4cccc'
  return (
    <Document title='Dokumen'>
      {dataArray?.map((lpage: any, ipage: number) => (
        <Fragment key={ipage}>
          <Page wrap={false} size={[2500]} style={{...PdfStyle.page, fontFamily: 'Arial'}}>
            <View style={{marginTop: '20px'}}></View>
            {ipage === 0 && (
              <View style={{width: '100%'}}>
                <Text style={{textTransform: 'uppercase', textAlign: 'center'}}>
                  Inspektorat Jenderal Kementrian Agama
                </Text>
                <Text style={{textTransform: 'uppercase', textAlign: 'center'}}>
                  Tahun {NullProof({input: data, params: 'data.year'})}
                </Text>
              </View>
            )}
            <View>
              <PdfTableColumnBuilder border wrap direction='column'>
                <PdfTableColumnBuilder borderBottom wrap style={{alignItems: 'stretch'}}>
                  <PdfTableColumnBuilder center borderRight wrap style={{width: '3%'}}>
                    <Text style={{padding: '5px'}}>No</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight wrap style={{width: '8%'}}>
                    <Text style={{padding: '5px'}}>NIP</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight wrap style={{width: '12%'}}>
                    <Text style={{padding: '5px'}}>Nama</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '3%', backgroundColor: colorOrange}}
                  >
                    <Text style={{padding: '5px'}}>N-2</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '3%', backgroundColor: colorOrange}}
                  >
                    <Text style={{padding: '5px'}}>N-1</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '3%', backgroundColor: colorGreen}}
                  >
                    <Text style={{padding: '5px'}}>N</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    direction='column'
                    center
                    borderRight
                    style={{width: '50%', alignItems: 'stretch'}}
                  >
                    <PdfTableColumnBuilder center borderBottom>
                      <Text
                        style={{padding: '5px', textTransform: 'uppercase', textAlign: 'center'}}
                      >
                        Pengajuan Cuti
                      </Text>
                    </PdfTableColumnBuilder>
                    <PdfTableColumnBuilder style={{alignItems: 'stretch'}}>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>1</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>2</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>3</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>4</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>5</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>6</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>7</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>8</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>9</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>10</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>11</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>12</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>13</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>14</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>15</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>16</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>17</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>18</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>19</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>20</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>21</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>22</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center borderRight wrap>
                        <Text style={{padding: '5px'}}>23</Text>
                      </PdfTableColumnBuilder>
                      <PdfTableColumnBuilder center wrap>
                        <Text style={{padding: '5px'}}>24</Text>
                      </PdfTableColumnBuilder>
                    </PdfTableColumnBuilder>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '6%', backgroundColor: colorRed}}
                  >
                    <Text
                      style={{padding: '5px', textTransform: 'uppercase', textAlign: 'center'}}
                      hyphenationCallback={(e) => {
                        return [e]
                      }}
                    >
                      Total Penggunaan Hak Cuti
                    </Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder center borderRight wrap style={{width: '3%'}}>
                    <Text style={{padding: '5px', textTransform: 'uppercase'}}>Sisa</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '3%', backgroundColor: colorOrange}}
                  >
                    <Text style={{padding: '5px', textTransform: 'uppercase'}}>N-2</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    borderRight
                    wrap
                    style={{width: '3%', backgroundColor: colorOrange}}
                  >
                    <Text style={{padding: '5px', textTransform: 'uppercase'}}>N-1</Text>
                  </PdfTableColumnBuilder>
                  <PdfTableColumnBuilder
                    center
                    wrap
                    style={{width: '3%', backgroundColor: colorGreen}}
                  >
                    <Text style={{padding: '5px', textTransform: 'uppercase'}}>N</Text>
                  </PdfTableColumnBuilder>
                </PdfTableColumnBuilder>
                {lpage?.map((l: any, i: number) => {
                  const sisa = getSisaCuti(l)
                  return (
                    <Fragment key={i}>
                      <PdfTableColumnBuilder
                        borderBottom={i !== lpage.length - 1}
                        wrap
                        style={{alignItems: 'stretch'}}
                      >
                        <PdfTableColumnBuilder center borderRight wrap style={{width: '3%'}}>
                          <Text style={{padding: '5px'}}>{ipage * size + i + 1}</Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder borderRight wrap style={{width: '8%'}}>
                          <Text style={{padding: '5px'}}>
                            {NullProof({input: l, params: 'nip'})}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder borderRight wrap style={{width: '12%'}}>
                          <Text style={{padding: '5px'}}>
                            {NullProof({input: l, params: 'nama'})}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '3%', backgroundColor: colorOrange}}
                        >
                          <Text style={{padding: '5px'}}>{sisa.sebelum.n2}</Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '3%', backgroundColor: colorOrange}}
                        >
                          <Text style={{padding: '5px'}}>{sisa.sebelum.n1}</Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '3%', backgroundColor: colorGreen}}
                        >
                          <Text style={{padding: '5px'}}>{sisa.sebelum.n}</Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          direction='column'
                          center
                          borderRight
                          style={{width: '50%', alignItems: 'stretch'}}
                        >
                          <PdfTableColumnBuilder style={{alignItems: 'stretch'}}>
                            {[...Array(24).keys()].map((n) => (
                              <PdfTableColumnBuilder key={n} center borderRight={n !== 23} wrap>
                                <Text style={{padding: '5px'}}>{sisa.list[n] || ''}</Text>
                              </PdfTableColumnBuilder>
                            ))}
                          </PdfTableColumnBuilder>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '6%', backgroundColor: colorRed}}
                        >
                          <Text
                            style={{
                              padding: '5px',
                              textTransform: 'uppercase',
                              textAlign: 'center',
                            }}
                            hyphenationCallback={(e) => {
                              return [e]
                            }}
                          >
                            {sisa.total}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder center borderRight wrap style={{width: '3%'}}>
                          <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                            {sisa.sesudah.total}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '3%', backgroundColor: colorOrange}}
                        >
                          <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                            {sisa.sesudah.n2}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          borderRight
                          wrap
                          style={{width: '3%', backgroundColor: colorOrange}}
                        >
                          <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                            {sisa.sesudah.n1}
                          </Text>
                        </PdfTableColumnBuilder>
                        <PdfTableColumnBuilder
                          center
                          wrap
                          style={{width: '3%', backgroundColor: colorGreen}}
                        >
                          <Text style={{padding: '5px', textTransform: 'uppercase'}}>
                            {sisa.sesudah.n}
                          </Text>
                        </PdfTableColumnBuilder>
                      </PdfTableColumnBuilder>
                    </Fragment>
                  )
                })}
              </PdfTableColumnBuilder>
            </View>
            <View style={{marginBottom: '20px'}}></View>
          </Page>
        </Fragment>
      ))}
    </Document>
  )
}

export default PdfLayout
