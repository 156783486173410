import {DataRoutesProps} from '../../UserRolesValidation'
import Pengembalian from './Pengembalian'
import RekapSp2d from './RekapSp2d'
import LsBendahara from './ls-bendahara/LsBendahara'

const PengelolaanKeuangan: DataRoutesProps = {
  title: 'Pengelolaan Keuangan',
  icon: 'element-10',
  route: 'pengelolaan_keuangan',
  elements: <></>,
  subroute: [
    {
      title: 'Pelayanan Penerbitan SPP & SPM',
      icon: 'element-10',
      route: 'pelayanan_penerbitan_spp_spm',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pelaksanaan Pembayaran & Pengembalian Belanja',
      icon: 'element-10',
      route: 'pelaksanaan_pembayaran_pengembalian_belanja',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pemungutan dan Penyetoran Pajak',
      icon: 'element-10',
      route: 'pemungutan_penyetoran_pajak',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan Buku Kas Umum',
      icon: 'element-10',
      route: 'penyusunan_buku_kas_umum',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Pengarsipan Kwitansi dan Bukti Keuangan',
      icon: 'element-10',
      route: 'pengarsipan_kwitansi_bukti_keuangan',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Penyusunan Laporan Pertanggungjawaban Bendahara',
      icon: 'element-10',
      route: 'penyusunan_laporan_pertanggungjawaban_bendahara',
      elements: <></>,
      subroute: [],
    },
    {
      title: 'Realisasi Anggaran',
      icon: 'element-10',
      route: 'realisasi_anggaran',
      elements: <></>,
      subroute: [],
    },
    {
      isPage: true,
      title: 'Rekap SP2D',
      icon: 'element-10',
      route: 'rekap_sp2d',
      elements: <></>,
      subroute: RekapSp2d,
    },
    ...LsBendahara,
    {
      title: 'GUP/TUP',
      icon: 'element-10',
      route: 'gup_tup',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Rekapitulasi UP/GUP',
          icon: 'element-10',
          route: 'rekapitulasi_up_gup',
          elements: <></>,
          subroute: [],
        },
        {
          isPage: true,
          title: 'Transfer',
          icon: 'element-10',
          route: 'transfer',
          elements: <></>,
          subroute: [],
        },
        {
          isPage: true,
          title: 'Rekapitulasi Pajak',
          icon: 'element-10',
          route: 'rekapitulasi_pajak',
          elements: <></>,
          subroute: [],
        },
      ],
    },
    {
      title: 'Penghasilan Pegawai',
      icon: 'element-10',
      route: 'penghasilan_pegawai',
      elements: <></>,
      subroute: [
        {
          isPage: true,
          title: 'Catat Gaji',
          icon: 'element-10',
          route: 'catat_gaji',
          elements: <></>,
          subroute: [],
        },
        {
          isPage: true,
          title: 'Catat Uang Makan',
          icon: 'element-10',
          route: 'catat_uang_makan',
          elements: <></>,
          subroute: [],
        },
        {
          isPage: true,
          title: 'Hitung Tunjangan Kerja',
          icon: 'element-10',
          route: 'hitung_tunjangan_kerja',
          elements: <></>,
          subroute: [],
        },
        {
          isPage: true,
          title: 'Catat Penghasilan Lain',
          icon: 'element-10',
          route: 'catat_penghasilan_lain',
          elements: <></>,
          subroute: [],
        },
      ],
    },
    {
      isPage: true,
      title: 'Pengembalian',
      icon: 'element-10',
      route: 'pengembalian',
      elements: <></>,
      subroute: Pengembalian,
    },
    {
      title: 'Kontrol IKPA',
      icon: 'element-10',
      route: 'kontrol_ikpa',
      elements: <></>,
      subroute: [],
    },
  ],
}

export default PengelolaanKeuangan
