import * as Yup from 'yup'
import {FormInputProps} from '../../../helper/FormInputV2'
import {getListYear} from '../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'tahun',
    type: 'select',
    value: '',
    listData: getListYear(),
    validator: Yup.string().required('Tahun wajib diisi'),
    options: {
      select: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'kode_program',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Program',
          placeholder: 'Kode Program',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-9',
    name: 'nama_program',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Program',
          placeholder: 'Nama Program',
        },
      },
    },
  },
]

export {formInputList}
