/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import PageData, {InfoData, TableInputData, TableInputDetailData} from './PageData'
import {formInputList, formInputPass, formInputStatus} from './InputLayout'
import {PdfPageViewer} from '../../../../helper/PdfPageViewer'
import PdfLayout from './PdfLayout'
import RekapPdfLayout from './RekapPdfLayout'
import {ButtonLoading, CardUi} from '../../../../helper/AppUi'
import {TableActionProps, TableColumn, TableInput, TableRow} from '../../../../helper/TableInputV2'
import {
  BreadcrumbQueryParams,
  getStatusColor,
  NullProof,
  RouterQueryParams,
} from '../../../../helper/AppFunction'
import FormInput, {BaseInputOnChangeProps} from '../../../../helper/FormInputV2'
import {TableInputDetail, TableInputDetailColumn} from '../../../../helper/TableInputDetail'
import ExcelLayout from './ExcelLayout'
import RekapExcelLayout from './RekapExcelLayout'
import ReactIcon from '../../../../helper/ReactIcon'
import {formatDate} from '../../../../helper/DateFns'
import {RekapModalPdf} from '../../../../helper/KemenagUi'
import {directDownloadPdf} from '../../../../helper/ReactPdf'
import {getData, putData} from '../../../../helper/FormAxios'
import Modal from '../../../../helper/Modal'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../modules/auth'
import {Alert} from 'react-bootstrap'
import moment from 'moment'
import {hasAccessRoutes, ProtectComponent} from '../../../../helper/UserRolesValidation'
import {useSelector} from 'react-redux'
import {differenceInDays, min, parseISO} from 'date-fns'
import {checkDateRanges} from '../../../../helper/KemenagFunction'

const InformasiSisaCuti: FC = () => {
  const [data, setData] = useState<any>({
    n: '-',
    n1: '-',
    n2: '-',
  })

  const loadData = async () => {
    const result = await getData('', '/kepegawaian/sisa-cuti')
    if (result?.status) {
      if (result?.data?.length > 0) {
        setData((p: any) => ({...p, ...result?.data[0]}))
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className='mx-5'>
      <div className='fs-6'>Informasi Sisa Cuti</div>
      <div className='row g-4 mt-2 mb-4'>
        <div className='col-12 col-md-4'>
          <div className='mb-2 d-flex gap-1'>N</div>
          <input className='form-control form-control-lg' disabled value={data?.n} />
        </div>
        <div className='col-12 col-md-4'>
          <div className='mb-2 d-flex gap-1'>N-1</div>
          <input className='form-control form-control-lg' disabled value={data?.n1} />
        </div>
        <div className='col-12 col-md-4'>
          <div className='mb-2 d-flex gap-1'>N-2</div>
          <input className='form-control form-control-lg' disabled value={data?.n2} />
        </div>
      </div>
    </div>
  )
}

const onChange = (e: BaseInputOnChangeProps) => {
  const dataObj = e._data.getObject
  const data = e._data.get
  let result = {...data}
  let tanggal_total: number = 0
  let tanggalPertama: string = ''
  const tanggalBuilder = ({index, l}: {index: number; l: any}) => {
    let json: any = {}
    const parent = 'tanggal'
    const mulai = l?.tanggal_mulai
    const selesai = l?.tanggal_selesai
    const mulaiProps = `${parent}_tanggal_mulai_${index}`
    const selesaiProps = `${parent}_tanggal_selesai_${index}`
    let total = formatDate({date: mulai, targetDate: selesai, dateFormat: 'range'}) + 1 || 1
    if (total < 0) {
      total = 0
    }
    if (selesai < mulai) {
      total = 0
      e.setError({
        field: `tanggal`,
        message: 'Tanggal cuti selesai tidak valid.',
      })
    } else {
      e.setError({field: `tanggal`, message: ''})
    }
    return {json: json, parent, mulai, selesai, mulaiProps, selesaiProps, total}
  }
  let isToleransi = false
  if (NullProof({input: dataObj, params: 'tanggal', isMap: true})?.length > 0) {
    try {
      const firstDate = min(
        NullProof({input: dataObj, params: 'tanggal', isMap: true})?.map((l: any) =>
          parseISO(l?.tanggal_mulai)
        )
      )?.toISOString()
      isToleransi = differenceInDays(new Date(), parseISO(firstDate)) > 9
    } catch (_) {}
  }
  NullProof({input: dataObj, params: 'tanggal', isMap: true})?.map((l: any, i: number) => {
    let json: any = {}
    let index = i + 1
    let response = tanggalBuilder({index: i + 1, l: l})
    json[`${response.parent}_tanggal_total_${index}`] = response.total
    if (isToleransi) {
      e.setError({
        field: `tanggal`,
        message: 'Tanggal cuti toleransi hanya H+10.',
      })
    } else if (
      checkDateRanges({
        data: NullProof({input: dataObj, params: 'tanggal', isMap: true}),
        end: 'tanggal_selesai',
        start: 'tanggal_mulai',
      })
    ) {
      e.setError({
        field: `tanggal`,
        message: 'Tanggal cuti tidak valid.',
      })
    } else if (response.total > 0) {
      e.setError({field: `${response.parent}_tanggal_total_${index}`, message: ''})
    } else {
      e.setError({
        field: `tanggal`,
        message: 'Tanggal cuti tidak valid.',
      })
    }
    tanggal_total += response.total
    result = {...result, ...json}
  })
  result.tanggal_total = tanggal_total
  e.resetForm({values: result})
  e._dataIndicator.post({
    tanggal: tanggal_total,
  })
}

interface PageProps {
  type: 'semua' | 'persetujuan' | 'ditolak' | 'selesai'
}

const titleList: any = {
  semua: 'Semua',
  persetujuan: 'Persetujuan',
  ditolak: 'Ditolak',
  selesai: 'Selesai',
}

const titlePageList: any = {
  detail: 'Detail',
  ubah: 'Ubah',
  tambah: 'Tambah',
  pdf: 'Preview PDF',
}

const typeBreadcrumb = (type: string, breadcrumb: PageLink[], page?: string) => {
  let _breadcrumb = [...breadcrumb]

  if (page) {
    _breadcrumb.push(
      {
        title: titleList[type],
        path: `${InfoData.path}/${type}`,
        isSeparator: false,
        isActive: true,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      }
    )
  }
  return _breadcrumb
}

const conditionProtect = (data?: any, user?: any) => {
  const isTerbit = data?.status?.toLowerCase() === 'terbit'
  const isPegawai = data?.pegawai_id === user?.pegawai_id
  const isAdmin = user?.role_id === 1
  const isPemohon = data?.tte_pemohon?.toLowerCase() === 'sudah'
  const isReviu1 =
    data?.penandatangan1_id === user?.pegawai_id || data?.penandatangan1?.id === user?.pegawai_id
  const isReviu1Acc = data?.penandatangan1_status?.toLowerCase() === 'disetujui'
  const isReviu2 =
    data?.penandatangan2_id === user?.pegawai_id || data?.penandatangan2?.id === user?.pegawai_id
  const isReviu2Acc = data?.penandatangan2_status?.toLowerCase() === 'disetujui'
  const isAdminAcc = data?.admin_status?.toLowerCase() === 'disetujui'
  const isReviuDitolak =
    data?.penandatangan1_status?.toLowerCase() === 'tidak disetujui' ||
    data?.penandatangan2_status?.toLowerCase() === 'tidak disetujui'
  return {
    isTerbit,
    isPegawai,
    isAdmin,
    isAdminAcc,
    isPemohon,
    isReviu1,
    isReviu2,
    isReviu1Acc,
    isReviu2Acc,
    isReviuDitolak,
  }
}

const protectPegawai = (data?: any, user?: any) => {
  let locked = false
  const {isTerbit, isPegawai, isAdmin} = conditionProtect(data, user)
  if ((isTerbit && isPegawai) || isAdmin) {
    locked = true
  }
  return locked
}

const protectUbah = (data?: any, user?: any) => {
  let locked = false
  const {isTerbit, isPegawai, isAdmin} = conditionProtect(data, user)
  if ((isTerbit && !isPegawai && !isAdmin) || (!isAdmin && !isPegawai)) {
    locked = true
  }
  return locked
}

const MainWrapper: FC<PageProps> = ({type = 'semua'}) => {
  const query = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>([])
  const [modal, setModal] = useState<any>([])

  const routeData = useSelector((state: any) => state.route.value)
  const {auth} = useAuth()
  const roleId = auth?.data.role_id

  const [isDownload, setIsDownload] = useState<boolean>(false)
  const [isRekapEmpty, setIsRekapEmpty] = useState<boolean>(false)
  const [rekapModal, setRekapModal] = useState<boolean>(false)
  const [action, setAction] = useState<TableActionProps[]>(TableInputData?.action || [])

  const getQuery = () => {
    let queryList: string[] = [`status=${type}`]
    if (
      hasAccessRoutes(
        routeData?.route,
        `kepegawaian/cuti-pegawai/permohonan-cuti/${type}/$query=get=all`,
        roleId === 1,
        true
      )
    ) {
      // queryList.push('get=all')
    }
    return queryList.join('&')
  }
  const updateAction = () => {
    const data = modal?.input
    const list = [...(TableInputData?.action || [])]
    if (data?.status?.toLowerCase() === 'terbit') {
      protectUbah(data, auth?.data) && delete list[1]
    }
    return list
  }

  useEffect(() => {
    setAction(updateAction())
  }, [modal])

  return (
    <Fragment key={`${type}`}>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          typeBreadcrumb(type, PageData.semua.page.breadcrumb.data)
        )}
      >
        {titleList[type]}
      </PageTitle>
      <RekapModalPdf
        view='year'
        type='all'
        show={rekapModal}
        onClose={() => setRekapModal(false)}
        onChange={() => setIsRekapEmpty(false)}
        onSubmit={async (e) => {
          let type = 'year'
          let queryRekap = getQuery()
          try {
            setRekapModal(false)
            setIsRekapEmpty(false)
            setIsDownload(true)
            const url = RouterQueryParams(InfoData.rekap, {id: e?.year})
            const response = await getData(queryRekap, url)
            if (response?.status) {
              const data = {data: response?.data, ...e, type}
              if (response?.data?.length > 0) {
                console.log(data)
                e?.type === 'pdf' &&
                  (await directDownloadPdf({document: RekapPdfLayout, data: data}))
                e?.type === 'excel' && (await RekapExcelLayout(data)())
              } else {
                setIsRekapEmpty(true)
              }
            }
          } catch (_) {}
          setIsDownload(false)
        }}
      >
        {isRekapEmpty && (
          <div className='alert alert-warning text-dark d-flex align-items-center gap-4'>
            <ReactIcon icon='RiErrorWarningLine' props={{style: {width: '40px', height: '40px'}}} />
            <div>
              <div className='fw-bold fs-5'>Data Rekap Kosong</div>
              <div>Mohon untuk coba dengan data lain.</div>
            </div>
          </div>
        )}
      </RekapModalPdf>
      {/* <PdfPageViewer
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        data={{}}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={RekapPdfLayout}
        ExcelLayout={RekapExcelLayout}
      /> */}
      <CardUi
        title={PageData.semua.page.title}
        toolbars={
          <>
            {type === 'semua' && (
              <ButtonLoading
                loading={isDownload}
                icon='RiDownload2Line'
                title={{button: 'Download Rekap PDF'}}
                props={{
                  onClick: () => setRekapModal(true),
                }}
              />
            )}
          </>
        }
      >
        <TableInput
          modal={setModal}
          onSuccess={(val: any) => setData(val)}
          {...TableInputData}
          action={action}
          route={{
            url: TableInputData.route.url,
            query: getQuery(),
          }}
          sidebar={
            <>
              {type === 'semua' && (
                <ProtectComponent id='tambah'>
                  <button
                    type='button'
                    onClick={() => navigate('./tambah')}
                    className='btn btn-primary d-flex align-items-center gap-1'
                  >
                    <ReactIcon icon='RiAddLine' props={{className: 'fs-3'}} />
                    Tambah
                  </button>
                </ProtectComponent>
              )}
            </>
          }
        >
          {NullProof({input: data, params: 'data', isMap: true}).map((l: any, i: number) => {
            return (
              <Fragment key={i}>
                <TableRow input={{data: l, index: i}} numbering={{n: i, p: data?.page}}>
                  <TableColumn label='Pegawai' className='min-w-150px'>
                    {NullProof({input: l, params: 'pegawai.nama'})}
                    <br />
                    {NullProof({input: l, params: 'pegawai.nip'})}
                  </TableColumn>
                  <TableColumn label='Alasan' className='min-w-150px'>
                    {NullProof({input: l, params: 'alasan'})}
                  </TableColumn>
                  <TableColumn label='Status' className='min-w-150px'>
                    <div className='d-flex flex-column gap-2'>
                      <div className='d-flex gap-2'>
                        <span>Status:</span>
                        <span
                          style={{
                            color: getStatusColor(NullProof({input: l, params: 'status'})),
                          }}
                        >
                          {'  '}{' '}
                          {NullProof({input: l, params: 'status'}) == '_'
                            ? 'Belum Terbit'
                            : NullProof({input: l, params: 'status'})}
                        </span>
                      </div>
                      <div className='d-flex gap-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'penandatangan1_status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <span>Penandatangan 1:</span>
                        <span
                          style={{
                            color: getStatusColor(
                              NullProof({input: l, params: 'penandatangan1_status'})
                            ),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'penandatangan1_status'})}
                        </span>
                      </div>
                      <div className='d-flex gap-2'>
                        <div
                          className='rounded-circle'
                          style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            backgroundColor: getStatusColor(
                              NullProof({input: l, params: 'penandatangan2_status', isLabel: false})
                            ),
                          }}
                        ></div>
                        <span>Penandatangan 2:</span>
                        <span
                          style={{
                            color: getStatusColor(
                              NullProof({input: l, params: 'penandatangan2_status'})
                            ),
                          }}
                        >
                          {'  '} {NullProof({input: l, params: 'penandatangan2_status'})}
                        </span>
                      </div>
                    </div>
                  </TableColumn>
                </TableRow>
              </Fragment>
            )
          })}
        </TableInput>
      </CardUi>
    </Fragment>
  )
}

const UbahWrapper: FC<PageProps> = ({type = 'semua'}) => {
  const query = useParams()
  const navigate = useNavigate()
  const {auth} = useAuth()

  return (
    <Fragment key={`${type}`}>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          typeBreadcrumb(type, PageData.ubah.page.breadcrumb.data, 'ubah')
        )}
      >
        {titlePageList['ubah']}
      </PageTitle>
      <CardUi title={PageData.ubah.page.title}>
        <InformasiSisaCuti />
        <FormInput
          input={formInputList}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id`, query),
            type: 'put',
            redirect: true,
          }}
          onLoad={(e) => {
            protectUbah(e.data?._apiData.server, auth?.data) &&
              navigate(`${InfoData.path}/${type}`, {replace: true})
          }}
          onFeedback={async () => {}}
          onChange={onChange}
        />
      </CardUi>
    </Fragment>
  )
}

const TambahWrapper: FC<PageProps> = ({type = 'semua'}) => {
  return (
    <Fragment key={`${type}`}>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          typeBreadcrumb(type, PageData.tambah.page.breadcrumb.data, 'tammbah')
        )}
      >
        {titlePageList['tambah']}
      </PageTitle>
      <CardUi title={PageData.tambah.page.title}>
        <InformasiSisaCuti />
        <FormInput
          input={formInputList}
          route={{
            url: InfoData.api,
            type: 'post',
            redirect: true,
          }}
          onChange={onChange}
        />
      </CardUi>
    </Fragment>
  )
}

const PdfWrapper: FC<PageProps> = ({type = 'semua'}) => {
  const query = useParams()

  return (
    <Fragment key={`${type}`}>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          typeBreadcrumb(type, PageData.pdf.page.breadcrumb.data, 'pdf')
        )}
      >
        {titlePageList['pdf']}
      </PageTitle>
      <PdfPageViewer
        options={{
          hideBreadcrumb: true,
        }}
        route={RouterQueryParams(`${InfoData.api}/:id`, query)}
        // data={{}}
        PageData={{
          breadcrumb: PageData.pdf?.page.breadcrumb.data || [],
          title: PageData.pdf?.page.breadcrumb.title || '',
        }}
        PdfLayout={PdfLayout}
        ExcelLayout={ExcelLayout}
        tte={{
          errorMessage: 'Tidak ada TTE Karena Permohonan Cuti Ditolak',
        }}
      />
    </Fragment>
  )
}

const ButtonKirimNaskah: FC<{data: any; onRefresh?: any}> = ({data, onRefresh}) => {
  const [modal, setModal] = useState<any>({
    statusUbah: false,
  })
  const query = useParams()
  const body = {
    ...data,
    status: 'Terbit',
  }
  const TableInputData = null
  const updateUsulan = async () => {
    try {
      const result: any = await putData(body, `${InfoData.api}/${query.id}`)
      // const result:any = false
      if (result?.status === true) {
        setModal((p: any) => ({...p, statusUbah: false}))
        onRefresh && onRefresh()
        Swal.fire({
          icon: 'success',
          title: 'Usulan berhasil diubah menjadi Terbit.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    }
  }

  return (
    <>
      <Modal
        id='modal_kirim_naskah'
        isShow={modal.statusUbah}
        onClose={() => {
          setModal((p: any) => ({...p, statusUbah: false}))
        }}
        title='Update Permohonan Cuti'
      >
        <div className='d-flex flex-column gap-2'>
          <div className='d-flex justify-content-center'>
            <h4>Apakah anda yakin ingin mengubah permohonan cuti ini menjadi 'Terbit' ?</h4>
          </div>
          <div></div>
        </div>
        <div className='d-flex flex-row-reverse gap-2 mt-10'>
          <button
            className='btn btn-primary d-flex gap-1 align-items-center'
            onClick={() => {
              updateUsulan()
            }}
          >
            {'Update'}
          </button>
          <button
            className='btn btn-light text-primary border border-primary d-flex gap-1 align-items-center'
            onClick={() => {
              setModal((p: any) => ({...p, statusUbah: false}))
            }}
          >
            {'Batal'}
          </button>
        </div>
      </Modal>
      <div className=' d-flex flex-row-reverse mb-3'>
        <button
          className='btn btn-primary d-flex gap-1 align-items-center'
          onClick={() => {
            setModal({
              statusUbah: true,
            })
          }}
        >
          <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3'}} />
          {'Kirim Permohonan Cuti'}
        </button>
      </div>
    </>
  )
}

const DetailWrapper: FC<PageProps> = ({type}) => {
  const query = useParams()
  const [data, setData] = useState<any>({})
  const [key, setKey] = useState<number>(0)
  const [modal_status, setModal_status] = useState<boolean>(false)
  const [modal_status_2, setModal_status_2] = useState<boolean>(false)
  const [modal_admin, setModal_admin] = useState<boolean>(false)
  const [modal_status_draft, setModal_status_draft] = useState<boolean>(false)
  const [modal_pass, setModal_pass] = useState<boolean>(false)
  const [modal_pass_tte, setModal_pass_tte] = useState<boolean>(false)
  const [modal_cancel, setModal_cancel] = useState<boolean>(false)

  const routeData = useSelector((state: any) => state.route.value)

  const {auth} = useAuth()
  const idPegawai = NullProof({input: auth?.data, params: 'pegawai_id'})
  const idRole = NullProof({input: auth?.data, params: 'role_id'})

  const {
    isAdmin,
    isPegawai,
    isPemohon,
    isReviu1,
    isReviu2,
    isReviu1Acc,
    isAdminAcc,
    isReviu2Acc,
    isTerbit,
    isReviuDitolak,
  } = conditionProtect(data, auth?.data)

  const isPermission = () => {
    const result = hasAccessRoutes(
      routeData?.route,
      `kepegawaian/cuti-pegawai/permohonan-cuti/${type}/detail/:id/approval-admin`,
      isAdmin,
      true,
      query
    )
    return result
  }

  const getTanggalCuti = (data: any[] = []) => {
    let mulai: string = ''
    let selesai: string = ''
    try {
      mulai = data[0]?.tanggal_mulai
      selesai = data[data.length - 1]?.tanggal_selesai
    } catch (_) {}
    return {mulai, selesai}
  }

  const cekTanggal = () => {
    const result =
      moment(getTanggalCuti(data?.tanggal).mulai).diff(moment(data?.createdAt), 'days') < 2
    return result
  }
  const updateUsulan = async () => {
    try {
      const body = {
        status: 'Cancel',
      }
      const result: any = await putData(body, `${InfoData.api}/${query.id}/cancel`)
      if (result?.status) {
        setModal_cancel(false)
        setKey((p) => p + 1)
        Swal.fire({
          icon: 'success',
          title: 'Berhasil membatalkan permohonan cuti.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Mohon cek kembali data anda.',
        })
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Mohon cek kembali data anda.',
      })
    }
  }
  return (
    <Fragment key={`${type}-${key}`}>
      {protectPegawai(data, auth?.data) && (
        <Modal
          id='modal_kirim_naskah'
          isShow={modal_cancel}
          onClose={() => {
            setModal_cancel(false)
          }}
          title='Batalkan Permohonan Cuti'
        >
          <div className='d-flex flex-column gap-2'>
            <div className='d-flex justify-content-center'>
              <h4>Apakah anda yakin ingin membatalkan permohonan cuti ini?</h4>
            </div>
            <div></div>
          </div>
          <div className='d-flex flex-row-reverse gap-2 mt-10'>
            <button
              className='btn btn-danger d-flex gap-1 align-items-center'
              onClick={() => {
                updateUsulan()
              }}
            >
              {'Batalkan'}
            </button>
            <button
              className='btn btn-light text-primary border border-primary d-flex gap-1 align-items-center'
              onClick={() => {
                setModal_cancel(false)
              }}
            >
              {'Batal'}
            </button>
          </div>
        </Modal>
      )}
      <Modal
        id='modal_status_draft'
        isShow={modal_status_draft}
        onClose={() => {
          setModal_status_draft(false)
        }}
        title='Status Penandatanganan Pemohon'
      >
        <FormInput
          input={formInputPass}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-terbit`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_status_draft(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status'
        isShow={modal_status}
        onClose={() => {
          setModal_status(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_status(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_status_2'
        isShow={modal_status_2}
        onClose={() => {
          setModal_status_2(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/ubah-status-2`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_status_2(false)
            },
          }}
          isModal
        />
      </Modal>
      <Modal
        id='modal_admin'
        isShow={modal_admin}
        onClose={() => {
          setModal_admin(false)
        }}
        title='Ubah Status'
      >
        <FormInput
          input={formInputStatus}
          route={{
            url: RouterQueryParams(`${InfoData.api}/:id/status-admin`, query),
            type: 'put',
            useServerMessage: true,
            onFeedback: (e: any) => {
              if (e?.status) {
                setKey((p) => p + 1)
              }
              setModal_admin(false)
            },
          }}
          isModal
        />
      </Modal>
      <PageTitle
        breadcrumbs={BreadcrumbQueryParams(
          typeBreadcrumb(type, PageData.detail.page.breadcrumb.data, 'detail')
        )}
      >
        {titlePageList['detail']}
      </PageTitle>
      <Fragment key={key}>
        <CardUi
          title={PageData.detail?.page.breadcrumb.title}
          toolbars={
            <>
              {NullProof({input: data, params: 'status'}) === 'Draft' ||
              NullProof({input: data, params: 'status'}) === '_' ? (
                // <ButtonKirimNaskah data={data} />

                <button
                  className='btn btn-primary d-flex gap-1 align-items-center'
                  onClick={() => {
                    setModal_status_draft(true)
                  }}
                >
                  <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3 me-2'}} />
                  Kirim Permohonan Cuti
                </button>
              ) : (isTerbit && isPegawai) || (isAdmin && isTerbit) ? (
                <>
                  <button
                    className='btn btn-danger d-flex gap-1 align-items-center'
                    onClick={() => {
                      setModal_cancel(true)
                    }}
                  >
                    <ReactIcon icon='RiCloseCircleLine' props={{className: 'fs-3 me-2'}} />
                    Batalkan Permohonan Cuti
                  </button>
                </>
              ) : (
                <>
                  {((isAdminAcc &&
                    NullProof({input: data, params: 'status'})?.toLowerCase() === 'cancel' &&
                    isPegawai) ||
                    (NullProof({input: data, params: 'status'})?.toLowerCase() === 'cancel' &&
                      isAdmin)) && (
                    <button
                      className='btn btn-primary d-flex gap-1 align-items-center'
                      onClick={() => {
                        setModal_status_draft(true)
                      }}
                    >
                      <ReactIcon icon='RiSendPlaneLine' props={{className: 'fs-3 me-2'}} />
                      Kirim Permohonan Cuti
                    </button>
                  )}
                </>
              )}
            </>
          }
        >
          <TableInputDetail onSuccess={(val: any) => setData(val)} {...TableInputDetailData}>
            <div>
              {cekTanggal() && (
                <Alert variant='danger' className='mt-2'>
                  Pengajuan cuti dilakukan melebihi batas waktu yang disyaratkan
                </Alert>
              )}
              {NullProof({input: data, params: 'status'})?.toLowerCase() === 'cancel' && (
                <Alert variant='danger' className='mt-2'>
                  Penerbitan ulang cuti dapat dilakukan setelah disetujui Admin
                </Alert>
              )}
            </div>
            <TableInputDetailColumn title={{value: 'Status Pengajuan'}}>
              {NullProof({input: data, params: 'status', isLabel: false}) || 'Belum Terbit'}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Pegawai'}}>
              Nama : {NullProof({input: data, params: 'pegawai.nama'})}
              <br />
              NIP : {NullProof({input: data, params: 'pegawai.nip'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal Pengajuan'}}>
              {formatDate({
                date: NullProof({input: data, params: 'createdAt'}),
                dateFormat: 'dd MMMM yyyy, HH:mm:ss',
              })}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Jenis Cuti'}}>
              {NullProof({input: data, params: 'jenis.jenis'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Alasan Cuti'}}>
              {NullProof({input: data, params: 'alasan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Tanggal'}}>
              {NullProof({input: data, params: 'tanggal', isMap: true})?.map(
                (l: any, i: number) => (
                  <Fragment key={i}>
                    <li>
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_mulai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}
                      {' s/d '}
                      {formatDate({
                        date: NullProof({input: l, params: 'tanggal_selesai'}),
                        dateFormat: 'dd MMMM yyyy',
                      })}
                      {' ('}
                      {NullProof({input: l, params: 'tanggal_total'})}
                      {' Hari) '}
                    </li>
                  </Fragment>
                )
              )}
            </TableInputDetailColumn>
            <TableInputDetailColumn title={{value: 'Total Hari Cuti'}}>
              {NullProof({input: data, params: 'totalHariCuti'})}
            </TableInputDetailColumn>
          </TableInputDetail>
          <TableInputDetailColumn
            title={{
              value: 'Status Penandatanganan Pemohon',
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              {NullProof({input: data, params: 'tte_pemohon'})}
              <div>
                {((isTerbit && !isPemohon && isPegawai) || isAdmin) && (
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal_status_draft(true)
                    }}
                  >
                    Ubah Status
                  </button>
                )}
              </div>
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'PenandaTangan Reviu 1',
            }}
          >
            {NullProof({input: data, params: 'penandatangan1.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Status Reviu 1',
            }}
          >
            <div className='d-flex align-items-center gap-2'>
              {NullProof({input: data, params: 'penandatangan1_status'})}
              <div>
                {((isTerbit && isPemohon && isReviu1) || isAdmin) && (
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal_status(true)
                    }}
                  >
                    Ubah Status
                  </button>
                )}
              </div>
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Catatan Reviu',
            }}
          >
            {NullProof({input: data, params: 'penandatangan1_catatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Tanggal Tanda Tangan Reviu 1',
            }}
          >
            {formatDate({
              date: NullProof({input: data, params: 'penandatangan1_tanggal'}),
              dateFormat: 'dd MMMM yyyy, HH:mm:ss',
            })}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'PenandaTangan Reviu 2',
            }}
          >
            {NullProof({input: data, params: 'penandatangan2.nama'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Status Reviu 2',
            }}
          >
            {NullProof({input: data, params: 'penandatangan2_status'})}
            <div className='d-flex align-items-center gap-2'>
              {((isTerbit && isPemohon && isReviu2 && isReviu1Acc) || isAdmin) && (
                <div>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      setModal_status_2(true)
                    }}
                  >
                    Ubah Status
                  </button>
                </div>
              )}
            </div>
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Catatan Reviu 2',
            }}
          >
            {NullProof({input: data, params: 'penandatangan2_catatan'})}
          </TableInputDetailColumn>
          <TableInputDetailColumn
            title={{
              value: 'Tanggal Tanda Tangan Reviu 2',
            }}
          >
            {formatDate({
              date: NullProof({input: data, params: 'penandatangan2_tanggal'}),
              dateFormat: 'dd MMMM yyyy, HH:mm:ss',
            })}
          </TableInputDetailColumn>
          {/* {(NullProof({input: data, params: 'status'})?.toLowerCase() === 'cancel' ||
            isReviuDitolak) && ( */}
          <>
            <TableInputDetailColumn
              title={{
                value: 'Status Reviu Admin',
              }}
            >
              {NullProof({input: data, params: 'admin_status'})}
              <div className='d-flex align-items-center gap-2'>
                {((isPemohon && isPermission()) || isAdmin) && (
                  <div>
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        setModal_admin(true)
                      }}
                    >
                      Ubah Status
                    </button>
                  </div>
                )}
              </div>
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: 'Catatan Reviu Admin',
              }}
            >
              {NullProof({input: data, params: 'admin_catatan'})}
            </TableInputDetailColumn>
            <TableInputDetailColumn
              title={{
                value: 'Tanggal Tanda Tangan Admin',
              }}
            >
              {formatDate({
                date: NullProof({input: data, params: 'admin_tanggal'}),
                dateFormat: 'dd MMMM yyyy, HH:mm:ss',
              })}
            </TableInputDetailColumn>
          </>
          {/* )} */}
        </CardUi>
      </Fragment>
    </Fragment>
  )
}

export {MainWrapper, UbahWrapper, TambahWrapper, PdfWrapper, DetailWrapper}
