import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'

const formInputStatus: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'status',
    type: 'select',
    value: '',
    listData: [
      {
        title: 'Disetujui',
        value: 'Disetujui',
      },
      {
        title: 'Perubahan',
        value: 'Perubahan',
      },
      {
        title: 'Ditangguhkan',
        value: 'Ditangguhkan',
      },
      {
        title: 'Tidak Disetujui',
        value: 'Tidak Disetujui',
      },
    ],
    validator: Yup.string(),
    options: {
      select: {
        props: {
          title: 'Status',
          placeholder: 'Status',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'catatan',
    type: 'textarea',
    value: '',
    validator: Yup.string(),
    options: {
      textarea: {
        props: {
          title: 'Catatan',
          placeholder: 'Catatan',
          rows: 3,
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    className: 'col-12 col-md-6',
    name: 'passphrase',
    type: 'input',
    value: '',
    validator: Yup.string().required('Passphrase is required'),
    options: {
      input: {
        props: {
          title: 'Passphrase',
          placeholder: 'Passphrase',
          type: 'password',
        },
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'lampiran_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Lampiran',
          placeholder: 'Lampiran',
        },
      },
      datalist: {
        api: '/pengawasan_internal/lampiran-skbt',
        query: 'orderby=asc',
        params: 'pengusul.nama,no_skbt,keterangan',
        result: (
          <>
            <div>
              <span className='fw-bold'>No SKBT : </span>
              <span>$no_skbt</span>
            </div>
            <div>
              <span className='fw-bold'>Pengusul : </span>
              <span>$pengusul.nama</span>
            </div>
            <div>
              <span className='fw-bold'>Keterangan : </span>
              <span>$keterangan</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'no_skbt', type: 'text'},
            {id: 'pengusul.nama', type: 'text'},
            {id: 'keterangan', type: 'text'},
          ],
          props: {
            route: {
              url: '/pengawasan_internal/lampiran-skbt',
            },
            header: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'No SKBT'},
              {title: 'Pengusul'},
              {title: 'Keterangan'},
            ],
          },
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            header: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  // {
  //   className: 'col-12',
  //   name: 'pengusul_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Pengusul',
  //         placeholder: 'Pengusul',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       query: 'orderby=asc',
  //       params: 'nama,nip',
  //       result: (
  //         <>
  //           <div>
  //             <span className='fw-bold'>Nama : </span>
  //             <span>$nama</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>NIP : </span>
  //             <span>$nip</span>
  //           </div>
  //         </>
  //       ),
  //       id: 'id',
  //       type: 'table',
  //       table: {
  //         data: [
  //           {id: 'nama', type: 'text'},
  //           {id: 'nip', type: 'text'},
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           header: [
  //             {title: 'No.', props: {className: 'min-w-50px'}},
  //             {title: 'Nama'},
  //             {title: 'NIP'},
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  // {
  //   name: 'nip',
  //   type: 'input',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'NIP',
  //         placeholder: 'NIP',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'provinsi',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Provinsi is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Provinsi',
  //         placeholder: 'Provinsi',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/data-provinsi',
  //       params: 'nama',
  //       result: '$nama',
  //       id: 'nama',
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'kota',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Kabupaten/Kota is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Kabupaten/Kota',
  //         placeholder: 'Kabupaten/Kota',
  //       },
  //     },
  //     datalist: {
  //       api: '/pengawasan_internal/data-kota',
  //       query: 'provinsi=$provinsi',
  //       params: 'nama',
  //       result: '$nama',
  //       id: 'nama',
  //     },
  //     watchInput: {
  //       id: 'provinsi',
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'unit_kerja',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.string().required('Unit Kerja is required'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Unit Kerja',
  //         placeholder: 'Unit Kerja',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-satker2',
  //       query: 'provinsi=$provinsi&kota=$kota',
  //       params: 'singkatan,nama_lengkap',
  //       result: '$singkatan - $nama_lengkap',
  //       id: 'id',
  //     },
  //     watchInput: {
  //       id: 'provinsi,kota',
  //     },
  //   },
  // },
  {
    name: 'keperluan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Keperluan',
          placeholder: 'Keperluan',
        },
      },
    },
  },
  // {
  //   name: 'nomor_surat',
  //   type: 'input',
  //   validator: Yup.string().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Nomor Surat',
  //         placeholder: 'Nomor Surat',
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'penandatanganan1_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Penandatanganan 1',
  //         placeholder: 'Penandatanganan 1',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       query: 'orderby=asc',
  //       params: 'nama,nip',
  //       result: (
  //         <>
  //           <div>
  //             <span className='fw-bold'>Nama : </span>
  //             <span>$nama</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>NIP : </span>
  //             <span>$nip</span>
  //           </div>
  //         </>
  //       ),
  //       id: 'id',
  //       type: 'table',
  //       table: {
  //         data: [
  //           {id: 'nama', type: 'text'},
  //           {id: 'nip', type: 'text'},
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           header: [
  //             {title: 'No.', props: {className: 'min-w-50px'}},
  //             {title: 'Nama'},
  //             {title: 'NIP'},
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
  // {
  //   className: 'col-12',
  //   name: 'penandatanganan2_id',
  //   type: 'datalist',
  //   value: '',
  //   validator: Yup.number().required('Mohon untuk diisi.'),
  //   options: {
  //     input: {
  //       props: {
  //         title: 'Penandatanganan 2',
  //         placeholder: 'Penandatanganan 2',
  //       },
  //     },
  //     datalist: {
  //       api: '/kepegawaian/data-tenaga-audit',
  //       query: 'orderby=asc',
  //       params: 'nama,nip',
  //       result: (
  //         <>
  //           <div>
  //             <span className='fw-bold'>Nama : </span>
  //             <span>$nama</span>
  //           </div>
  //           <div>
  //             <span className='fw-bold'>NIP : </span>
  //             <span>$nip</span>
  //           </div>
  //         </>
  //       ),
  //       id: 'id',
  //       type: 'table',
  //       table: {
  //         data: [
  //           {id: 'nama', type: 'text'},
  //           {id: 'nip', type: 'text'},
  //         ],
  //         props: {
  //           route: {
  //             url: '/kepegawaian/data-tenaga-audit',
  //           },
  //           header: [
  //             {title: 'No.', props: {className: 'min-w-50px'}},
  //             {title: 'Nama'},
  //             {title: 'NIP'},
  //           ],
  //         },
  //       },
  //     },
  //   },
  // },
]

export {formInputList, formInputStatus}
