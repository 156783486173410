import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12 col-md-3',
    name: 'kode_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Kegiatan',
          placeholder: 'Kode Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-9',
    name: 'nama_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-3',
    name: 'kode_komponen',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Komponen',
          placeholder: 'Kode Komponen',
        },
      },
    },
  },
  {
    className: 'col-12 col-md-9',
    name: 'nama_komponen',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Komponen',
          placeholder: 'Nama Komponen',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'jumlah_harga',
    type: 'input',
    disabled: true,
    validator: Yup.number(),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Biaya',
          placeholder: 'Jumlah Biaya',
        },
      },
    },
  },
]

export {formInputList}
