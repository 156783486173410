import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {ReactNode} from 'react'

const ratingValue = [
  {
    title: '⭐⭐⭐⭐⭐⭐',
    value: 6,
  },
  {
    title: '⭐⭐⭐⭐⭐',
    value: 5,
  },
  {
    title: '⭐⭐⭐⭐',
    value: 4,
  },
  {
    title: '⭐⭐⭐',
    value: 3,
  },
  {
    title: '⭐⭐',
    value: 2,
  },
  {
    title: '⭐',
    value: 1,
  },
]

const surveyInputGenerator = ({
  data,
}: {
  data?: {
    name: string
    title: string
    description: ReactNode
  }[]
}) => {
  let result: FormInputProps[] = []
  data?.map((l) => {
    const template: FormInputProps = {
      name: l.name,
      type: 'select',
      validator: Yup.string().required('Mohon untuk diisi.'),
      listData: ratingValue,
      options: {
        input: {
          props: {
            title: l.title,
            placeholder: l.title,
          },
        },
        label: {
          labelType: 'both',
          description: <>{l.description}</>,
        },
      },
    }
    result.push(template)
  })
  return result
}

const formInputList: FormInputProps[] = [
  {
    name: 'skbt_id',
    type: 'input',
    hide: true,
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'SKBT',
          placeholder: 'SKBT',
        },
      },
    },
  },
  {
    name: 'bulan_layanan',
    type: 'select',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    listData: [
      {
        title: 'Triwulan I (Januari - Maret)',
        value: 'Triwulan I',
      },
      {
        title: 'Triwulan II (April - Juni)',
        value: 'Triwulan II',
      },
      {
        title: 'Triwulan III (Juli - September)',
        value: 'Triwulan III',
      },
    ],
    options: {
      input: {
        props: {
          title: 'Bulan diterimanya layanan',
          placeholder: 'Bulan diterimanya layanan',
        },
      },
    },
  },
  {
    name: 'nama',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    name: 'nip',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'NIP',
          placeholder: 'NIP',
        },
      },
    },
  },
  {
    name: 'no_hp',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor HP',
          placeholder: 'Nomor HP',
        },
      },
    },
  },
  {
    name: 'email',
    type: 'input',
    validator: Yup.string().email('Format harus Email.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Email',
          placeholder: 'Email',
        },
      },
    },
  },
  {
    name: 'jenis_kelamin',
    type: 'select',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis Kelamin',
          placeholder: 'Jenis Kelamin',
        },
      },
    },
    listData: [
      {
        title: 'Laki-Laki',
        value: 'Laki-Laki',
      },
      {
        title: 'Perempuan',
        value: 'Perempuan',
      },
    ],
  },
  {
    name: 'umur',
    type: 'select',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Umur',
          placeholder: 'Umur',
        },
      },
    },
    listData: [
      {
        title: '16 - 25 th',
        value: '16 - 25 th',
      },
      {
        title: '26 - 35 th',
        value: '26 - 35 th',
      },
      {
        title: '36 - 45 th',
        value: '36 - 45 th',
      },
      {
        title: '46 - 55 th',
        value: '46 - 55 th',
      },
      {
        title: '> 56 th',
        value: '> 56 th',
      },
    ],
  },
  {
    name: 'pendidikan',
    type: 'select',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pendidikan',
          placeholder: 'Pendidikan',
        },
      },
    },
    listData: [
      {
        title: 'SD',
        value: 'SD',
      },
      {
        title: 'SMP',
        value: 'SMP',
      },
      {
        title: 'SMA',
        value: 'SMA',
      },
      {
        title: 'D1-D2-D3-D4',
        value: 'D1-D2-D3-D4',
      },
      {
        title: 'S-1',
        value: 'S-1',
      },
      {
        title: 'S-2',
        value: 'S-2',
      },
      {
        title: 'S-3',
        value: 'S-3',
      },
    ],
  },
  {
    name: 'pekerjaan_list',
    type: 'select',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pekerjaan',
          placeholder: 'Pekerjaan',
        },
      },
    },
    listData: [
      {
        title: 'ASN/TNI/Polri',
        value: 'ASN/TNI/Polri',
      },
      {
        title: 'Pegawai Swasta',
        value: 'Pegawai Swasta',
      },
      {
        title: 'Pelajar/Mahasiswa',
        value: 'Pelajar/Mahasiswa',
      },
      {
        title: 'Wiraswasta/Wirausaha',
        value: 'Wiraswasta/Wirausaha',
      },
      {
        title: 'Mengurus Rumah Tangga',
        value: 'Mengurus Rumah Tangga',
      },
      {
        title: 'Yang Lain',
        value: 'Yang Lain',
      },
    ],
  },
  {
    name: 'pekerjaan',
    type: 'input',
    hide: true,
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Pekerjaan',
          placeholder: 'Pekerjaan',
        },
      },
      label: {
        hideLabel: true,
      },
    },
  },
  {
    name: 'tipe_asn',
    type: 'select',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tipe ASN',
          placeholder: 'Tipe ASN',
        },
      },
    },
    listData: [
      {
        title: 'ASN Kemenag',
        value: 'ASN Kemenag',
      },
      {
        title: 'Non ASN Kemenag',
        value: 'Non ASN Kemenag',
      },
    ],
  },
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kabupaten/Kota is required'),
    options: {
      input: {
        props: {
          title: 'Kabupaten/Kota',
          placeholder: 'Kabupaten/Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'unit_kerja',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit Kerja is required'),
    options: {
      input: {
        props: {
          title: 'Unit Kerja',
          placeholder: 'Unit Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker2',
        query: 'provinsi=$provinsi&kota=$kota',
        params: 'singkatan,nama_lengkap',
        result: '$singkatan - $nama_lengkap',
        id: 'id',
      },
      watchInput: {
        id: 'provinsi,kota',
      },
    },
  },
  ...surveyInputGenerator({
    data: [
      {
        name: 'spkp_1',
        title: 'Survei Persepsi Kualitas Pelayanan',
        description: (
          <>
            <div>
              Informasi pelayanan pada unit layanan ini tersedia melalui media elektronik maupun non
              elektronik
            </div>
          </>
        ),
      },
      {
        name: 'spkp_2',
        title: 'Pernyataan SPKP 2',
        description: (
          <>
            <div>
              Persyaratan pelayanan yang diinformasikan sesuai dengan persyaratan yang ditetapkan
              unit layanan ini
            </div>
          </>
        ),
      },
      {
        name: 'spkp_3',
        title: 'Pernyataan SPKP 3',
        description: (
          <>
            <div>
              Prosedur/alur pelayanan yang ditetapkan unit layanan ini mudah diikuti/dilakukan
            </div>
          </>
        ),
      },
      {
        name: 'spkp_4',
        title: 'Pernyataan SPKP 4',
        description: (
          <>
            <div>
              Jangka waktu penyelesaian pelayanan yang diterima Bapak/Ibu sesuai dengan yang
              ditetapkan unit layanan ini
            </div>
          </>
        ),
      },
      {
        name: 'spkp_5',
        title: 'Pernyataan SPKP 5',
        description: (
          <>
            <div>Pelayanan diberikan secara gratis</div>
          </>
        ),
      },
      {
        name: 'spkp_6',
        title: 'Pernyataan SPKP 6',
        description: (
          <>
            <div>
              Sarana prasarana pendukung pelayanan/sistem pelayanan online yang disediakan unit
              layanan ini memberikan kenyamanan/mudah digunakan
            </div>
          </>
        ),
      },
      {
        name: 'spkp_7',
        title: 'Pernyataan SPKP 7',
        description: (
          <>
            <div>
              Petugas pelayanan/sistem pelayanan online pada unit layanan ini merespon keperluan
              Bapak/Ibu dengan cepat
            </div>
          </>
        ),
      },
      {
        name: 'spkp_8',
        title: 'Pernyataan SPKP 8',
        description: (
          <>
            <div>
              Layanan konsultasi dan pengaduan yang disediakan unit layanan ini mudah
              digunakan/diakses
            </div>
          </>
        ),
      },
      {
        name: 'pak_1',
        title: 'Survei Persepsi Anti Korupsi',
        description: (
          <>
            <div>Tidak ada diskriminasi pelayanan pada unit layanan ini</div>
          </>
        ),
      },
      {
        name: 'pak_2',
        title: 'Pernyataan PAK 2',
        description: (
          <>
            <div>
              Tidak ada pelayanan di luar prosedur/kecurangan pelayanan pada unit layanan ini
            </div>
          </>
        ),
      },
      {
        name: 'pak_3',
        title: 'Pernyataan PAK 3',
        description: (
          <>
            <div>
              Tidak ada penerimaan imbalan uang/barang/fasilitas di luar ketentuan yang berlaku pada
              unit layanan ini
            </div>
          </>
        ),
      },
      {
        name: 'pak_4',
        title: 'Pernyataan PAK 4',
        description: (
          <>
            <div>Tidak ada pungutan liar (pungli) pada unit layanan ini</div>
          </>
        ),
      },
      {
        name: 'pak_5',
        title: 'Pernyataan PAK 5',
        description: (
          <>
            <div>Tidak ada percaloan/perantara tidak resmi pada unit layanan ini</div>
          </>
        ),
      },
    ],
  }),
  {
    name: 'saran',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Saran',
          placeholder: 'Saran',
        },
      },
      label: {
        labelType: 'both',
        description: <div>Saran untuk peningkatan layanan</div>,
      },
    },
  },
]

export {formInputList}
