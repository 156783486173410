import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'

const yearArray: any = Array.from({length: new Date().getFullYear() - 1990 + 1}, (_, i) => ({
  title: 1990 + i,
  value: 1990 + i,
}))

const importFormInputList: FormInputProps[] = [
  {
    name: 'label',
    type: 'label',
    className: 'col-12',
    validator: Yup.string(),
    options: {
      label: {
        name: 'Panduan untuk Import',
        description: (
          <div className='fw-normal'>
            <ol>
              <li>Unduh Template Excel Data nya diatas.</li>
              <li>Buka melalui aplikasi Excel pada komputer.</li>
              <li>Ubah data sesuai update terbaru dari anda.</li>
              <li>Upload file yang sudah diubah pada komputer anda dibawah.</li>
            </ol>
          </div>
        ),
      },
    },
  },
  {
    name: 'csv',
    type: 'csv',
    validator: Yup.array().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'File CSV',
          placeholder: 'File CSV',
        },
      },
    },
  },
]

const exportFormInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    removeOnSubmit: true,
    validator: Yup.number(),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            header: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    name: 'pegawai',
    type: 'input',
    hide: true,
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
    },
  },
  {
    name: 'sumber',
    type: 'input',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Sumber',
          placeholder: 'Sumber',
        },
      },
    },
  },
  {
    name: 'tanggal_mulai',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Mulai',
          placeholder: 'Tanggal Mulai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'tanggal_selesai',
    type: 'input',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    options: {
      input: {
        isDate: true,
        props: {
          title: 'Tanggal Selesai',
          placeholder: 'Tanggal Selesai',
          type: 'date',
        },
      },
    },
  },
  {
    name: 'tahun',
    type: 'select',
    className: 'col-12 col-md-4',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Tahun',
          placeholder: 'Tahun',
        },
      },
    },
    listData: [...yearArray],
  },
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Kota',
          placeholder: 'Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'satuan_kerja',
    type: 'datalist',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Unit',
          placeholder: 'Unit',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker2',
        query: 'provinsi=$provinsi&kota=$kota',
        params: 'singkatan,nama_lengkap',
        result: '$singkatan - $nama_lengkap',
        id: 'id',
      },
      watchInput: {
        id: 'provinsi,kota',
      },
    },
  },
]

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'pegawai_id',
    type: 'datalist',
    value: '',
    validator: Yup.string(),
    options: {
      input: {
        props: {
          title: 'Pegawai',
          placeholder: 'Pegawai',
        },
      },
      datalist: {
        api: '/kepegawaian/data-tenaga-audit',
        query: 'orderby=asc',
        params: 'nama,nip',
        result: (
          <>
            <div>
              <span className='fw-bold'>Nama : </span>
              <span>$nama</span>
            </div>
            <div>
              <span className='fw-bold'>NIP : </span>
              <span>$nip</span>
            </div>
          </>
        ),
        id: 'id',
        type: 'table',
        table: {
          data: [
            {id: 'nama', type: 'text'},
            {id: 'nip', type: 'text'},
          ],
          props: {
            route: {
              url: '/kepegawaian/data-tenaga-audit',
            },
            header: [
              {title: 'No.', props: {className: 'min-w-50px'}},
              {title: 'Nama'},
              {title: 'NIP'},
            ],
          },
        },
      },
    },
  },
  {
    name: 'nip',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'NIP',
          placeholder: 'NIP',
        },
      },
    },
  },
  {
    name: 'nama',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama',
          placeholder: 'Nama',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'provinsi',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Provinsi is required'),
    options: {
      input: {
        props: {
          title: 'Provinsi',
          placeholder: 'Provinsi',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
    },
  },
  {
    className: 'col-12',
    name: 'kota',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Kabupaten/Kota is required'),
    options: {
      input: {
        props: {
          title: 'Kabupaten/Kota',
          placeholder: 'Kabupaten/Kota',
        },
      },
      datalist: {
        api: '/pengawasan_internal/data-kota',
        query: 'provinsi=$provinsi',
        params: 'nama',
        result: '$nama',
        id: 'nama',
      },
      watchInput: {
        id: 'provinsi',
      },
    },
  },
  {
    className: 'col-12',
    name: 'unit_kerja',
    type: 'datalist',
    value: '',
    validator: Yup.string().required('Unit Kerja is required'),
    options: {
      input: {
        props: {
          title: 'Unit Kerja',
          placeholder: 'Unit Kerja',
        },
      },
      datalist: {
        api: '/kepegawaian/data-satker2',
        query: 'provinsi=$provinsi&kota=$kota',
        params: 'singkatan,nama_lengkap',
        result: '$singkatan - $nama_lengkap',
        id: 'id',
      },
      watchInput: {
        id: 'provinsi,kota',
      },
    },
  },
  {
    name: 'satuan_kerja',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Satuan Kerja',
          placeholder: 'Satuan Kerja',
        },
      },
    },
  },
  {
    name: 'sumber',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Sumber',
          placeholder: 'Sumber',
        },
      },
    },
  },
  {
    name: 'tipologi_satker',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tipologi Satuan Kerja',
          placeholder: 'Tipologi Satuan Kerja',
        },
      },
    },
  },
  {
    name: 'no_lha',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nomor LHA',
          placeholder: 'Nomor LHA',
        },
      },
    },
  },
  {
    name: 'tahun_lha',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Tahun LHA',
          placeholder: 'Tahun LHA',
        },
      },
    },
  },
  {
    name: 'nama_lhp',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama LHP',
          placeholder: 'Nama LHP',
        },
      },
    },
  },
  {
    name: 'tp',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'TP',
          placeholder: 'TP',
        },
      },
    },
  },
  {
    name: 'temuan_kegiatan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Temuan Kegiatan',
          placeholder: 'Temuan Kegiatan',
        },
      },
    },
  },
  {
    name: 'rincian_temuan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Rincian Temuan',
          placeholder: 'Rincian Temuan',
        },
      },
    },
  },
  {
    name: 'nilai_temuan',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nilai Temuan',
          placeholder: 'Nilai Temuan',
        },
      },
    },
  },
  {
    name: 'nilai_tl',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nilai TL',
          placeholder: 'Nilai TL',
        },
      },
    },
  },
  {
    name: 'nilai_saldo',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nilai Saldo',
          placeholder: 'Nilai Saldo',
        },
      },
    },
  },
  {
    name: 'temuan_adm',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Temuan Admin',
          placeholder: 'Temuan Admin',
        },
      },
    },
  },
  {
    name: 'tl_adm',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'TL Admin',
          placeholder: 'TL Admin',
        },
      },
    },
  },
  {
    name: 'saldo_adm',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Saldo Admin',
          placeholder: 'Saldo Admin',
        },
      },
    },
  },
]

export {formInputList, importFormInputList, exportFormInputList}
