import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'

const formInputList: FormInputProps[] = [
  {
    className: 'col-12',
    name: 'jenis',
    type: 'input',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Jenis',
          placeholder: 'Jenis',
        },
      },
    },
  },
]

export {formInputList}
