import {PageDataContent} from '../../../../helper/Model'
import {TableInputDataProps} from '../../../../helper/TableInputV2'
import {TableInputDetailDataProps} from '../../../../helper/TableInputDetail'

interface PageDataProps {
  [key: string]: {
    page: PageDataContent
  }
}

export const InfoData = {
  id: 'tandatangan_pegawai',
  title: 'Tandatangan Pegawai',
  path: '/kepegawaian/cuti-pegawai/tandatangan-pegawai',
  api: '/kepegawaian/pegawai-tandatangan',
}

const BaseBreadcrumb = [
  {
    title: 'Kepegawaian',
    path: '#',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MainBreadcrumb = [
  {
    title: InfoData.title,
    path: InfoData.path,
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const TableInputData: TableInputDataProps = {
  route: {
    url: InfoData.api,
    // query: 'byuser=true',
  },
  header: [
    {
      title: 'No',
      props: {
        className: 'mw-100px text-center',
      },
    },
    {
      title: 'Jabatan',
    },
    {
      title: 'Unit Kerja',
    },
    {
      title: 'Penandatangan 1',
      props: {
        className: 'text-center min-w-200px',
      },
    },
    {
      title: 'Penandatangan 2',
      props: {
        className: 'text-center min-w-200px',
      },
    },
  ],
  action: [
    {
      id: 'id',
      idMenu: 'detail',
      protect: 'detail/:id',
      title: 'Detail',
      route: 'detail/:id',
    },
    {
      id: 'id',
      idMenu: 'ubah',
      icon: 'RiDraftLine',
      protect: 'ubah/:id',
      title: 'Ubah',
      route: 'ubah/:id',
    },
    // {
    //   id: 'id',
    //   idMenu: 'pdf',
    //   protect: 'pdf/:id',
    //   title: 'View PDF',
    //   route: 'pdf/:id',
    // },
    {
      id: 'id',
      idMenu: 'delete',
      icon: 'RiDeleteBinLine',
      protect: 'delete',
      title: 'Delete',
      route: '',
    },
  ],
}

export const TableInputDetailData: TableInputDetailDataProps = {
  route: {
    url: `${InfoData.api}/:id`,
  },
}

const PageData: PageDataProps = {
  semua: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: `${InfoData.title}`,
        data: BaseBreadcrumb,
      },
    },
  },
  detail: {
    page: {
      title: `${InfoData.title}`,
      breadcrumb: {
        title: 'Detail',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  tambah: {
    page: {
      title: `Tambah ${InfoData.title}`,
      breadcrumb: {
        title: `Tambah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  ubah: {
    page: {
      title: `Ubah ${InfoData.title}`,
      breadcrumb: {
        title: `Ubah ${InfoData.title}`,
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
  pdf: {
    page: {
      title: 'Preview Pdf',
      breadcrumb: {
        title: 'Preview Pdf',
        data: [...BaseBreadcrumb, ...MainBreadcrumb],
      },
    },
  },
}

export default PageData
