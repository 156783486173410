import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    name: 'kode',
    type: 'input',
    className: 'col-12 col-md-3',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Kegiatan',
          placeholder: 'Kode Kegiatan',
        },
      },
    },
  },
  {
    name: 'nama',
    type: 'input',
    className: 'col-12 col-md-9',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Kegiatan',
          placeholder: 'Nama Kegiatan',
        },
      },
    },
  },
  {
    hide: true,
    name: 'program_id',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'ID',
          placeholder: 'ID',
        },
      },
    },
  },
]

export {formInputList}
