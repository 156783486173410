import * as Yup from 'yup'
import {FormInputProps} from '../../../../helper/FormInputV2'
import {getListYear} from '../../../../helper/DateFns'

const formInputList: FormInputProps[] = [
  {
    name: 'kode',
    type: 'input',
    className: 'col-12 col-md-3',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Kode Daftar Kegiatan',
          placeholder: 'Kode Daftar Kegiatan',
        },
      },
    },
  },
  {
    name: 'nama',
    type: 'input',
    className: 'col-12 col-md-9',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Nama Daftar Kegiatan',
          placeholder: 'Nama Daftar Kegiatan',
        },
      },
    },
  },
  {
    name: 'keterangan',
    type: 'textarea',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Keterangan Daftar Kegiatan',
          placeholder: 'Keterangan Daftar Kegiatan',
        },
      },
    },
  },
  {
    hide: true,
    name: 'sub_kegiatan_id',
    type: 'input',
    className: 'col-12',
    validator: Yup.string().required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'ID',
          placeholder: 'ID',
        },
      },
    },
  },
  {
    name: 'jumlah_harga',
    type: 'input',
    className: 'col-12',
    disabled: true,
    validator: Yup.number().required('Mohon untuk diisi.'),
    options: {
      input: {
        isCurrency: true,
        props: {
          title: 'Jumlah Harga',
          placeholder: 'Jumlah Harga',
        },
      },
    },
  },
  {
    className: 'col-12',
    name: 'info_kegiatan',
    type: 'component',
    value: '',
    validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
    options: {
      input: {
        props: {
          title: 'Info Kegiatan',
          placeholder: 'Info Kegiatan',
        },
      },
    },
    component: [
      {
        className: 'col-12',
        name: 'nama',
        type: 'input',
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Info Kegiatan',
              placeholder: 'Info Kegiatan',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'jumlah_harga',
        type: 'input',
        disabled: true,
        validator: Yup.string().required('Mohon untuk diisi.'),
        options: {
          input: {
            isCurrency: true,
            props: {
              title: 'Jumlah Biaya',
              placeholder: 'Jumlah Biaya',
            },
          },
        },
      },
      {
        className: 'col-12',
        name: 'detail_kegiatan',
        type: 'component',
        value: '',
        validator: Yup.number().min(1, 'Mohon untuk diisi.').required('Mohon untuk diisi.'),
        options: {
          input: {
            props: {
              title: 'Detail Kegiatan',
              placeholder: 'Detail Kegiatan',
            },
          },
        },
        component: [
          {
            className: 'col-12',
            name: 'nama',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Nama',
                  placeholder: 'Nama',
                },
              },
            },
          },
          {
            className: 'col-12 col-md-8',
            name: 'volume',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Volume',
                  placeholder: 'Volume',
                },
              },
            },
          },
          {
            className: 'col-12 col-md-4',
            name: 'volume_jenis',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                props: {
                  title: 'Jenis Volume',
                  placeholder: 'Jenis Volume',
                },
              },
            },
          },
          {
            className: 'col-12 col-md-8',
            name: 'harga',
            type: 'input',
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                isCurrency: true,
                props: {
                  title: 'Harga Satuan',
                  placeholder: 'Harga Satuan',
                },
              },
            },
          },
          {
            className: 'col-12 col-md-4',
            name: 'jumlah_harga',
            type: 'input',
            disabled: true,
            validator: Yup.string().required('Mohon untuk diisi.'),
            options: {
              input: {
                isCurrency: true,
                props: {
                  title: 'Jumlah Biaya',
                  placeholder: 'Jumlah Biaya',
                },
              },
            },
          },
        ],
      },
    ],
  },
]

export {formInputList}
